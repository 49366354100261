import { type ActionMapper } from '@data-driven-forms/react-form-renderer';
import { FORM_ACTION_TYPE } from '../contracts';

export const FORM_ACTION_MAPPER: ActionMapper = {
  [FORM_ACTION_TYPE.handleParentAccountField]: () => (_props: any, _field: any, formOptions: any) => {
    const values = formOptions.getState().values;

    return {
      isDisabled: !values.parentAccountType || values.parentAccountType === 'entity'
    };
  },
  [FORM_ACTION_TYPE.handleParentAccountTypeField]: () => (_props: any, _field: any, formOptions: any) => {
    const values = formOptions.getState().values;

    if (values.parentAccountType === 'entity') {
      values.parent = null;
    }
  }
};
