import { date, dateTimeUi, time, time12 } from '@oms/shared/util-date';
import { FormatLargeNumber } from '../number/number.util';
import { convertStringToNumber, convertStringToNumberAndCheckDecimalPlaces } from '../strings';
import { getNumberOfDecimalPlaces } from './utils';
import type { FormatterFunction } from './types';

export const formatPrice: FormatterFunction = ({
  value,
  thousandDelimiter,
  decimalDelimiter,
  decimalPlaces,
  trimTrailingZeros,
  negativeOneAsEmpty
}) => {
  const decimals = decimalPlaces || getNumberOfDecimalPlaces(value, trimTrailingZeros, decimalDelimiter);
  const v = convertStringToNumber(value, { thousandDelimiter, decimalDelimiter });
  if (negativeOneAsEmpty && v === -1) return '';
  return FormatLargeNumber(v, decimals <= 2 ? decimals : 2);
};

export const formatTradePrice: FormatterFunction = ({
  value,
  thousandDelimiter,
  decimalDelimiter,
  trimTrailingZeros
}) => {
  const v = convertStringToNumber(value, { thousandDelimiter, decimalDelimiter });
  return FormatLargeNumber(v, Math.abs(v) < 1 ? 6 : 4, trimTrailingZeros);
};

export const formatPriceChange: FormatterFunction = ({
  value,
  thousandDelimiter,
  decimalDelimiter,
  trimTrailingZeros
}) => {
  const v = convertStringToNumber(value, {
    thousandDelimiter,
    decimalDelimiter
  });

  // default case if v === 0
  let positivitySign = '';

  if (v > 0) {
    positivitySign = '+';
  }

  if (v < 0) {
    positivitySign = '-';
  }

  return `${positivitySign}${FormatLargeNumber(v, 2, trimTrailingZeros)}`;
};

export const formatOrderPrice: FormatterFunction = ({
  value,
  thousandDelimiter,
  decimalDelimiter,
  decimalPlaces,
  trimTrailingZeros
}) => {
  const v = convertStringToNumber(value, { thousandDelimiter, decimalDelimiter });
  return FormatLargeNumber(
    v,
    decimalPlaces || getNumberOfDecimalPlaces(value, trimTrailingZeros, decimalDelimiter)
  );
};

export const formatNumber: FormatterFunction = ({
  value,
  thousandDelimiter,
  decimalDelimiter,
  decimalPlaces = 2,
  negativeOneAsEmpty,
  trimTrailingZeros = true,
  hasDelimiters
}) => {
  const v = convertStringToNumber(value, { thousandDelimiter, decimalDelimiter });
  if (negativeOneAsEmpty && v === -1) return '';
  return FormatLargeNumber(v, decimalPlaces, trimTrailingZeros, false, hasDelimiters);
};

// Creating new position number formatter to add fixed 2 decimal places, for things like average price.
// It appears positions needs this format going off the e2e tests
export const formatPositionPrice: FormatterFunction = ({ value, thousandDelimiter, decimalDelimiter }) => {
  const v = convertStringToNumber(value, { thousandDelimiter, decimalDelimiter });
  return FormatLargeNumber(v, 2);
};

export const formatPositionPriceBasedOnOne: FormatterFunction = ({
  value,
  thousandDelimiter,
  decimalDelimiter
}) => {
  const { numberValue, decimalLength } = convertStringToNumberAndCheckDecimalPlaces(value, {
    thousandDelimiter,
    decimalDelimiter
  });
  if (numberValue == 0 || decimalLength <= 2) {
    return FormatLargeNumber(numberValue, 2);
  } else if (numberValue >= 1) {
    return FormatLargeNumber(numberValue, 4, true);
  } else {
    return FormatLargeNumber(numberValue, 6, true);
  }
};

export const formatPositionNumber: FormatterFunction = ({ value, thousandDelimiter, decimalDelimiter }) => {
  const v = convertStringToNumber(value, { thousandDelimiter, decimalDelimiter });
  return FormatLargeNumber(v, Math.abs(v) < 1 ? 6 : 4);
};

export const formatDecimal: FormatterFunction = ({
  value,
  thousandDelimiter,
  decimalDelimiter,
  decimalPlaces = 2,
  negativeOneAsEmpty,
  trimTrailingZeros,
  hasDelimiters
}) => {
  const v = convertStringToNumber(value, { thousandDelimiter, decimalDelimiter });
  if (negativeOneAsEmpty && v === -1) return '';
  return FormatLargeNumber(v, decimalPlaces, trimTrailingZeros, false, hasDelimiters);
};

export const formatDecimalTwoFour: FormatterFunction = ({
  value,
  thousandDelimiter,
  decimalDelimiter,
  decimalPlaces,
  negativeOneAsEmpty,
  trimTrailingZeros,
  hasDelimiters
}) => {
  const v = convertStringToNumber(value, { thousandDelimiter, decimalDelimiter });
  const len = decimalPlaces || Math.abs(v) < 1 ? 4 : 2;
  if (negativeOneAsEmpty && v === -1) return '';
  return FormatLargeNumber(v, len, trimTrailingZeros, false, hasDelimiters);
};

export const formatQuantity: FormatterFunction = ({
  value,
  thousandDelimiter,
  decimalDelimiter,
  decimalPlaces = 0
}) => {
  const converted = convertStringToNumber(value, { thousandDelimiter, decimalDelimiter });
  const formatted = FormatLargeNumber(converted, decimalPlaces);
  return formatted;
};
//** Example: '25' to '25.00%' */
export const formatPercentage: FormatterFunction = ({ value, decimalPlaces = 2 }) => {
  const decimals = getNumberOfDecimalPlaces(value);
  return `${FormatLargeNumber(
    convertStringToNumber(value),
    decimalPlaces > decimals ? decimals : decimalPlaces
  )}%`;
};

//** Example: '0.25' to '25.00%' */
export const formatDecimalToPercentage: FormatterFunction = ({ value, decimalPlaces = 2 }) => {
  const decimals = getNumberOfDecimalPlaces(value);
  return `${FormatLargeNumber(
    convertStringToNumber(value) * 100,
    decimalPlaces > decimals ? decimals : decimalPlaces
  )}%`;
};

type DateFormatterFunction = FormatterFunction<string | number | Date>;

export const formatDate: DateFormatterFunction = ({ value }) => date(value);

/**
 * Format time for 24hr display
 */
export const formatTime: DateFormatterFunction = ({ value }) => time(value);

/**
 * Format time for 12hr display with AM/PM
 */
export const formatTime12: DateFormatterFunction = ({ value }) => time12(value);

export const formatDatetime: DateFormatterFunction = ({ value }) => dateTimeUi(value, 'dateAndTime');

// ⚖️ Scales ---------------------------------------------------------------------------------------- /

type ScaleRangeNumberType = 'charge' | 'price' | 'volume';

export const formatScaleRangeNumber: Record<ScaleRangeNumberType, FormatterFunction> = {
  charge: ({ value, hasDelimiters = true, decimalPlaces = 0, negativeOneAsEmpty = true, ...rest }) =>
    formatNumber({ value, hasDelimiters, decimalPlaces, negativeOneAsEmpty, ...rest }),
  price: ({ value, hasDelimiters = true, decimalPlaces = 2, negativeOneAsEmpty = true, ...rest }) =>
    formatPrice({ value, hasDelimiters, decimalPlaces, negativeOneAsEmpty, ...rest }),
  volume: ({ value, hasDelimiters = true, decimalPlaces = 0, negativeOneAsEmpty = true, ...rest }) =>
    formatNumber({ value, hasDelimiters, decimalPlaces, negativeOneAsEmpty, ...rest })
};

/**
 * Dummy formatter for text.
 * Means "Do not apply formatting and display value as is"
 */
export const formatText: FormatterFunction = ({ value }) => String(value);
