import {
  hiddenMode,
  hiddenStrategyOptions,
  instrument,
  venue,
  sideType,
  quantity,
  timeInForce,
  locate,
  strategy,
  limitPrice,
  trader,
  enrichedTrader,
  customerNotes,
  tradeCurrency,
  settlement,
  orderTags,
  matchedInvestorOrderIds,
  enrichedLocate,
  enrichedTradeCurrency,
  enrichedSettlement,
  enrichedCustomerNotes,
  enrichedOrderTags,
  venueNotes,
  firmAccount,
  showOnTOFormCondition,
  tradingOrderCategory,
  display,
  strategyPreset
} from '@app/widgets/trading/route-order/route-order.form-fields';
import type { RouteOrderFieldContract } from '@app/widgets/trading/route-order/route-order.form-common';
import { FieldDefinition, FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import {
  createAdditionalAllFields,
  createEnrichedField
} from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';
import { createMarketDataField } from '@app/forms/form-builder/fields/market-data-field/market-data-field.common';
import type { AssertMutuallyAssignable, Expect } from '@oms/frontend-schemas';
import type { RouteOrderFormValuesSchema } from './route-order.form-builder.config';

export const routeOrderContract = FormContract.create<RouteOrderFieldContract>()
  .fields({
    hiddenMode,
    hiddenStrategyOptions,
    instrument,
    venue,
    trader,
    sideType,
    quantity,
    timeInForce,
    locate,
    strategy,
    limitPrice,
    // route specific fields
    customerNotes,
    venueNotes,
    tradeCurrency,
    settlementType: settlement.settlementType,
    settlementDate: settlement.settlementDate,
    orderTags,
    matchedInvestorOrderIds,
    firmAccount,
    tradingOrderCategory,
    display,
    strategyPreset
  })
  .schema((f) => {
    const settlementContainer = settlement.settlementContainer.build();
    return {
      fields: [
        createMarketDataField('market-data', {
          instrumentFieldName: 'instrument',
          trackableFieldMap: {
            bid: 'limitPrice',
            ask: 'limitPrice'
          },
          alterDoubleClick: true
        }),
        // Runway
        FieldDefinition.runway('runway', [
          { ...f.instrument, isPrimaryField: true, style: { minWidth: 100 }, wrapperSx: { flexGrow: 1 } },
          { ...f.sideType, isPrimaryField: true, style: { minWidth: 100 } },
          { ...f.quantity, isPrimaryField: true, style: { minWidth: 100 } },
          { ...f.limitPrice, isPrimaryField: true, style: { minWidth: 100 } },
          { ...f.timeInForce, isPrimaryField: true, style: { minWidth: 100 } }
        ]),
        // Additional fields / all fields
        createAdditionalAllFields<RouteOrderFieldContract>({
          groups: ['Order', 'Details', 'Settle'],
          fixedFields: [
            'venue',
            'trader',
            settlementContainer.name,
            'strategy',
            'locate',
            'display',
            'strategyPreset'
          ],
          fields: [
            [createEnrichedField(f.firmAccount, { groupName: 'Order', condition: showOnTOFormCondition })],
            [createEnrichedField(f.venue, { groupName: 'Order' })],
            [enrichedTrader],
            [createEnrichedField(f.strategy, { groupName: 'Order' })],
            [createEnrichedField(f.strategyPreset, { groupName: 'Order', condition: showOnTOFormCondition })],
            [createEnrichedField(f.display, { groupName: 'Order', condition: showOnTOFormCondition })],
            [enrichedSettlement],
            [enrichedLocate],
            [enrichedTradeCurrency],
            [enrichedOrderTags],
            [createEnrichedField(f.venueNotes, { groupName: 'Details' })],
            [enrichedCustomerNotes]
          ]
        }),
        // Mappable IOs
        {
          component: 'section',
          name: 'mappable-investor-orders',
          sx: {
            backgroundColor: 'transparent'
          },
          fields: [[f.matchedInvestorOrderIds]]
        }
      ]
    };
  })
  .template('simple', { showSubmitButton: false });

export type RouteOrderFormContractType = typeof routeOrderContract;

export type RouteOrderFormValues = InferFormValuesFromFormContract<RouteOrderFormContractType>;

// If this type assertion fails, it means that the form values do not match the schema
// To fix, either update the form values to match the current schema, or update the schema to match the form values
// To update the schema, you need to create a new one & write a migration:
// pnpm exec nx generate @oms/ui-gen:sub-schema-migration --key=ROUTE_ORDER_FORM --no-interactive

// To see which properties are not assignable from one type to another, you can use the following utility types:

// type Diff<T, U> = {
//   [K in keyof T]: K extends keyof U ? (T[K] extends U[K] ? never : K) : K;
// }[keyof T];

// type NotAssignable<T, U> = Diff<T, U> | Diff<U, T>;

// type Test = NotAssignable<RouteOrderFormValues, GetVersionedSchemaDataType<typeof LATEST_VERSIONED_SCHEMA>>;

export type SCHEMA_ASSERTION_DO_NOT_TOUCH = Expect<
  AssertMutuallyAssignable<RouteOrderFormValues, RouteOrderFormValuesSchema>
>;
