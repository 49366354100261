import { CSSProperties, FC } from 'react';
import { Heading } from '../../layout/heading/heading';
import { Box } from '../../system/components/box/box';
import { Sprinkles } from '../../system/sprinkles.css';

export type SectionProps = {
  title?: string;
  children?: JSX.Element | string;
  style?: CSSProperties;
  sx?: Sprinkles;
  testId?: string;
};

export const Section: FC<SectionProps> = ({ title, style, sx, children, testId }) => {
  return (
    <Box
      style={style}
      sx={{ bg: 'layout.level3', borderRadius: 'md', padding: 4, ...sx }}
      data-testid={testId}
    >
      {title && (
        <Heading type="baseR" sx={{ marginBottom: 5 }}>
          {title}
        </Heading>
      )}
      {children}
    </Box>
  );
};
