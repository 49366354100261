import { RemoteProxy, Unexpose, Unsubscribe, createProxy, expose } from '@valstro/remote-link';
import type { AnyRecord, Maybe } from './utils';
import { Workspace, WorkspaceOptions } from './workspace';
import {
  ActorCreatedEvent,
  ActorDestroyedEvent,
  ActorParentChangedEvent,
  ActorPingEvent,
  ActorTakingSnapshotResultEvent,
  CoreEvents,
  EVENT_TYPE,
  EventType,
  EventsTransport
} from './events';
import { URL_PARAM, getStorageKey } from './constants';
import { Actor } from './actor';
import {
  ActorOperationOptions,
  ActorSchema,
  ActorSchemaAPI,
  AnyActorSchema,
  UnwrapContext,
  UnwrapOperations
} from './actor.schema';
import { deleteLocalActorProxy, setLocalActorProxy } from './actor.registry';
import { logger } from './logger';
import { COMMON_ACTOR_TYPE, CommonWindowActorSchema, WindowContext } from '../platforms/common';

export const ACTOR_STATE = {
  IDLE: 'idle',
  STARTING: 'starting',
  RUNNING: 'running',
  APPLYING_SNAPSHOT: 'applying-snapshot',
  FAILED: 'failed',
  DESTROYED: 'destroyed'
} as const;

export type ActorStateType = (typeof ACTOR_STATE)[keyof typeof ACTOR_STATE];

export type AnyActorOperation = (...args: any[]) => Promise<any>;

export type AnyActorOperations = Record<string, AnyActorOperation>;

export type ActorEvents = Maybe<() => void | Promise<Unsubscribe>>;

export type ActorStartOptions = {
  shouldSync?: boolean;
};

export type CreateActorOptions<TContext extends AnyRecord = AnyRecord, TType extends string = string> = {
  type: TType;
  name?: string;
  id?: string;
  context?: Partial<TContext>;
  parentId?: string;
  children?: CreateActorChildOptions[];
  applyingSnapshotFromId?: string;
};

export type CreateActorChildOptions<TContext extends AnyRecord = AnyRecord> = {
  type: string;
  name?: string;
  id?: string;
  context?: Partial<TContext>;
  children?: CreateActorOptions[];
};

export type CreateActorDefinition<TContext extends AnyRecord = AnyRecord> = {
  id: string;
  type: string;
  name?: string;
  context?: Partial<TContext>;
  children: ChildActorDefinition[];
  parentId?: string;
  userCreated?: boolean;
  applyingSnapshotFromId?: string;
};

export type ActorDefinition<TContext extends AnyRecord = AnyRecord> = {
  id: string;
  name: string;
  type: string;
  context: TContext;
  state: ActorStateType;
  children: ChildActorDefinition[];
  parentId?: string;
};

export type ChildActorDefinition<TContext extends AnyRecord = AnyRecord> = {
  id: string;
  name: string;
  type: string;
  initialContext?: TContext;
  parentId?: string;
};

export type SpawnedActorResult<T extends AnyActorSchema = AnyActorSchema> = [
  initialDefinition: ActorDefinition<T>,
  proxy: Actor<T>
];

export type ApplyActorRootSnapshotDefinition<TContext extends AnyRecord = AnyRecord> = {
  parentId?: string;
  context?: Partial<TContext>;
  children?: CreateActorOptions[];
};

export type ActorSnapshotDefinition<TContext extends AnyRecord = AnyRecord> = {
  id: string;
  name: string;
  type: string;
  context: TContext;
  children: ActorSnapshotDefinition[];
};

export type InternalActorContext = {
  eventsTransport: EventsTransport<CoreEvents>;
  workspace: Workspace;
  actorSchema: ActorSchema;
};

export type ChildInternalActor = {
  id: string;
  proxy: Actor;
  instance?: InternalActor | null;
};

export type UnwrapInternalActor<T extends AnyActorSchema> = InternalActor<
  UnwrapContext<T>,
  UnwrapOperations<T>
>;

export const ACTOR_HOOK_TYPE = {
  START: 'start',
  SPAWN_CHILD: 'spawnChild',
  DESTROY_CHILD: 'destroyChild',
  TAKE_SNAPSHOT: 'takeSnapshot',
  APPLY_SNAPSHOT: 'applySnapshot',
  CONTEXT_CHANGE: 'contextChange',
  DESTROY: 'destroy',
  REGISTER: 'register'
} as const;

type HookReturn<T> = T | undefined | void;

type SerializableSchemaInfo = {
  name: AnyActorSchema['name'];
  type: AnyActorSchema['type'];
  supportedPlatforms: AnyActorSchema['supportedPlatforms'];
  isWindowRootable: AnyActorSchema['isWindowRootable'];
};

export type ActorHookMap<TContext extends AnyRecord = AnyRecord> = {
  [ACTOR_HOOK_TYPE.START]: {
    before: undefined;
    after: undefined;
  };
  [ACTOR_HOOK_TYPE.SPAWN_CHILD]: {
    before: [CreateActorOptions, SerializableSchemaInfo];
    after: [CreateActorDefinition, SerializableSchemaInfo];
  };
  [ACTOR_HOOK_TYPE.DESTROY_CHILD]: {
    before: ActorDefinition;
    after: undefined;
  };
  [ACTOR_HOOK_TYPE.TAKE_SNAPSHOT]: {
    before: undefined;
    after: ActorSnapshotDefinition;
  };
  [ACTOR_HOOK_TYPE.APPLY_SNAPSHOT]: {
    before: ApplyActorRootSnapshotDefinition;
    after: undefined;
  };
  [ACTOR_HOOK_TYPE.CONTEXT_CHANGE]: {
    before: [Partial<TContext>, ActorOperationOptions];
    after: [TContext, ActorOperationOptions];
  };
  [ACTOR_HOOK_TYPE.DESTROY]: {
    before: undefined;
    after: undefined;
  };
};

type ActorOperationBeforeArgsHookMap<T extends AnyActorOperations> = {
  [K in keyof T]: Parameters<T[K]>;
};

type ActorOperationAfterHookDataMap<T extends AnyActorOperations> = {
  [K in keyof T]: T[K] extends (...args: any) => Promise<any> ? Awaited<ReturnType<T[K]>> : never;
};

type PromiseOrValue<T> = T | Promise<T>;

export type ActorHookCallback<
  T extends 'before' | 'after',
  TOperations extends AnyActorOperations,
  TContext extends AnyRecord,
  K extends keyof ActorHookMap<TContext> | keyof TOperations
> = (
  inData: K extends keyof ActorHookMap<TContext>
    ? ActorHookMap<TContext>[K][T]
    : K extends keyof TOperations
      ? T extends 'before'
        ? ActorOperationBeforeArgsHookMap<TOperations>[K]
        : ActorOperationAfterHookDataMap<TOperations>[K]
      : never,
  context: ActorSchemaAPI<TContext, TOperations>
) => PromiseOrValue<
  K extends keyof ActorHookMap<TContext>
    ? HookReturn<ActorHookMap<TContext>[K][T]>
    : K extends keyof TOperations
      ? T extends 'before'
        ? HookReturn<ActorOperationBeforeArgsHookMap<TOperations>[K]>
        : HookReturn<ActorOperationAfterHookDataMap<TOperations>[K]>
      : never
>;

export type AnyActorHookCallback = ActorHookCallback<any, any, any, string>;

export type AnyActorHook = {
  type: string;
  callback: AnyActorHookCallback;
};

type AnyActorHooksMap = Map<string, Set<AnyActorHook>>;
type ChildenMap = Map<string, ChildInternalActor>;

/**
 * Core actor class - This is the meat of the framework
 * It represents an actor interbally, which is a self-contained unit of code that can be spawned, destroyed, and interacted with
 *
 * On construction, it's passed the actor's schema and create options
 * It then sets up the actor's hooks, operations, and context.
 *
 * @internal
 */
export class InternalActor<
  TContext extends AnyRecord = AnyRecord,
  TOperations extends AnyActorOperations = AnyActorOperations
> {
  public name: string;
  public id: string;
  public parentId: string | undefined;

  public children: ChildActorDefinition[] = [];
  public context: TContext = {} as TContext;
  public state: ActorStateType = 'idle';
  public type: string;
  public failedMessage: string | null = null;

  private _actorSchema: ActorSchema<TContext, TOperations>;
  private _operations: TOperations;
  private _workspace: Workspace;
  private _eventsTransport: EventsTransport<CoreEvents>;
  private _unexpose: Unexpose | undefined;
  private _listeners: Unsubscribe[] = [];
  private _eventsUnsubscribe: Unsubscribe | undefined;
  private _internalProxy: RemoteProxy<InternalActor> | undefined;
  private _initialCreateDef: CreateActorDefinition | undefined;
  private _initialContextDef: Partial<TContext> | undefined;
  private _children: ChildenMap = new Map();
  private _spawningChildren: Set<string> = new Set();
  private _userCreated: boolean;
  private _beforeHooks: AnyActorHooksMap = new Map();
  private _afterHooks: AnyActorHooksMap = new Map();

  constructor(context: InternalActorContext, createDefinition: CreateActorDefinition<TContext>) {
    this.id = createDefinition.id;
    this.type = createDefinition.type;
    this.name = context.actorSchema.name;
    this.parentId = this._determineParentId(createDefinition, context);
    this._operations = this._getTypedSchemaOperations(context);

    this._actorSchema = this._getTypedSchema(context);
    this._eventsTransport = context.eventsTransport;
    this._workspace = context.workspace;
    this._userCreated = !!createDefinition.userCreated;
    this._initialCreateDef = createDefinition;
    this._initialContextDef = createDefinition.context || {};

    this._setupActorHooks(context);
  }

  /**
   * Get the current JSON definition of the actor
   *
   * @returns ActorDefinition<TContext>
   */
  getDefinition(): ActorDefinition<TContext> {
    return {
      id: this.id,
      parentId: this.parentId,
      name: this.name,
      type: this.type,
      context: this.context,
      children: this.children,
      state: this.state
    };
  }

  /**
   * Create a proxy for the actor's operations, defined by the schema
   * This allows us to run before & after hooks
   *
   * @returns TOperations
   */
  get operations(): TOperations {
    return new Proxy(
      {},
      {
        get: (_target, prop) => {
          const key = prop.toString() as keyof ActorHookMap<AnyRecord>;
          return async (...args: any[]) => {
            // Run pre hook
            const beforeResult = await this._triggerHook('before', key as any, args);

            if (beforeResult) {
              args = Array.isArray(beforeResult) ? beforeResult : [beforeResult];
            }

            // Execute operation with new args
            const data = await this._operations[key](...args);

            // Run post hook
            const afterResult = await this._triggerHook('after', key, data);
            const opResult = afterResult || data;

            this._eventsTransport
              .emit(EVENT_TYPE.ACTOR_OPERATION_PERFORMED, {
                id: this.id,
                type: this.type,
                parentId: this.parentId,
                definition: this.getDefinition(),
                operationArgs: args,
                operationName: key,
                operationResult: opResult
              })
              .catch(console.error);

            // Return potentially transformed data
            return opResult;
          };
        },
        set: () => {
          return true;
        }
      }
    ) as TOperations;
  }

  /**
   * Spawn a child actor
   *
   * @returns ActorDefinition<ActorSchema>
   */
  async spawnChild(
    createOptions: CreateActorOptions<AnyRecord>,
    _actorSchema?: AnyActorSchema | undefined
  ): Promise<SpawnedActorResult<AnyActorSchema>> {
    const { type, name, applyingSnapshotFromId } = createOptions;
    const actorRegistry = this._workspace.getActorRegistry();

    // Get the actor schema by either name or type
    const actorSchema = !_actorSchema
      ? name
        ? actorRegistry.getActorByName(name)
        : actorRegistry.getActorByType(type, this.getDefinition(), createOptions)
      : _actorSchema;

    if (!actorSchema) {
      throw new Error(
        `Actor ${name ? 'name' : 'type'} "${
          name || type
        }" not found in the actor registery when trying to spawn child actor from actor "${this.name}"`
      );
    }

    // If the child is already spawning, wait for it and return it
    if (createOptions.id && this._spawningChildren.has(createOptions.id)) {
      logger.debug(`[Actor: ${this.id}] Child with id "${createOptions.id}" is already being spawned`);

      // Wait for the child to be spawned & return it...
      return await new Promise((resolve, reject) => {
        let timeout: NodeJS.Timeout | undefined = undefined;
        const waitForChild = async () => {
          const child = this._children.get(createOptions.id || '');
          if (createOptions.id && child) {
            const def = await child.proxy.getDefinition();
            if (timeout) {
              clearTimeout(timeout);
            }
            clearInterval(interval);
            resolve([def] as unknown as SpawnedActorResult<AnyActorSchema>);
          }
        };
        const interval = setInterval(() => {
          waitForChild().catch(console.error);
        }, 25);

        timeout = setTimeout(() => {
          clearInterval(interval);
          reject(new Error(`Child with id "${createOptions.id}" is already being spawned`));
        }, 15_000);
      });
    }

    // If the child already exists, sync it & return it
    const child = this._children.get(createOptions.id || '');
    if (createOptions.id && child) {
      logger.debug(`[Actor: ${this.id}] Child already exists, syncing: ${child.id}`);
      child.proxy
        .sync((createOptions.context as Partial<TContext>) || {}, {
          isFocus: true
        })
        .catch(console.error);
      const def = await child.proxy.getDefinition();
      return [def] as unknown as SpawnedActorResult<AnyActorSchema>;
    }

    let id = createOptions.id || this._workspace.generateUUID(); // TODO: Generate either a hash or hiarchy of ids

    this._spawningChildren.add(id);

    try {
      // Get initial context from the actor schema
      const currentContext = { ...createOptions.context };
      createOptions.context = await actorSchema.initialContext({
        schema: actorSchema,
        initialContext: currentContext,
        workspace: this._workspace
      });

      // Create an API for the schema hooks
      const hookSchemaApi: SerializableSchemaInfo = {
        isWindowRootable: actorSchema.isWindowRootable,
        name: actorSchema.name,
        supportedPlatforms: actorSchema.supportedPlatforms,
        type: actorSchema.type
      };

      // Run pre hook
      const beforeHookResult = await this._triggerHook('before', 'spawnChild', [
        { ...createOptions, id },
        hookSchemaApi
      ]);

      // If the hook returns data, use that as the create options & ID
      const args = beforeHookResult || [];
      const nextCreateOptions = args[0] || createOptions;
      id = nextCreateOptions.id || id;

      logger.debug(`[Actor: ${this.id}] Spawning child ${id}`);

      // Render the actor (return instance if in current process - not rootable)
      const instanceIfInProcess = await this._renderAndStart(actorSchema, id, {
        type,
        name,
        children: nextCreateOptions.children,
        context: nextCreateOptions.context,
        applyingSnapshotFromId
      });

      // Get the actor proxy for the rendered actor
      const actorProxy = await Actor.get(id);

      // Add the child to the internal map
      await this._addChild(id, {
        id,
        proxy: actorProxy,
        instance: instanceIfInProcess
      });

      // Fire actor created event
      this._eventsTransport
        .emit(EVENT_TYPE.ACTOR_CREATED, {
          ...actorProxy.initialDefinition,
          isUserCreated: false
        })
        .catch(console.error);

      await this._triggerHook('after', 'spawnChild', [{ ...actorProxy.initialDefinition }, hookSchemaApi]);

      logger.debug(`[Actor: ${this.id}]: Spawned child ${id}`);
      // Note: Not returning the proxy here, as it can't be serialized
      // so need to cast the types
      return [actorProxy.initialDefinition] as unknown as SpawnedActorResult<AnyActorSchema>;
    } catch (e) {
      this._spawningChildren.delete(id);
      throw e;
    }
  }

  /**
   * Destroy a child actor
   *
   * @returns ActorDefinition<ActorSchema>
   */
  async destroyChild(id: string) {
    const child = this._children.get(id);
    if (!child) {
      throw new Error(`Child with id "${id}" not found`);
    }

    const childDef = child.instance ? child.instance.getDefinition() : await child.proxy.getDefinition();

    await this._triggerHook('before', 'destroyChild', childDef);

    if (child.instance) {
      await child.instance.destroy();
      return;
    }

    await child.proxy.destroy();

    await this._triggerHook('after', 'destroyChild', undefined);
  }

  /**
   * Apply a snapshot to the actor
   * That recursively spawns all children
   *
   * @returns void
   */
  async applySnapshot(snapshot: ApplyActorRootSnapshotDefinition) {
    await this._eventsTransport.emit(EVENT_TYPE.ACTOR_APPLYING_SNAPSHOT, {
      id: this.id,
      type: this.type,
      parentId: this.parentId
    });

    this.state = 'applying-snapshot';
    await this._fireStateChangedEvent();

    try {
      const beforeHookResult = await this._triggerHook('before', 'applySnapshot', snapshot);

      logger.debug(`[Actor: ${this.id}]: Applying snapshot`, { snapshot });

      if (beforeHookResult) {
        snapshot = beforeHookResult;
      }

      if (this.parentId && snapshot.parentId && snapshot.parentId !== this.parentId) {
        const fromParentId = this.parentId;
        this.parentId = snapshot.parentId;
        // TODO: Actually do something here...
        this._eventsTransport
          .emit(EVENT_TYPE.ACTOR_PARENT_CHANGED, {
            fromParentId,
            toParentId: snapshot.parentId,
            id: this.id
          })
          .catch(console.error);
      }

      const { context, children } = snapshot;
      if (context) {
        await this._actorSchema.sync?.({
          contextDelta: context as Partial<TContext>,
          operations: this._operations,
          workspace: this._workspace,
          isFocus: false
        });
        await this._updateContext(context);
      }

      if (!children) {
        return;
      }

      const actorRegistry = this._workspace.getActorRegistry();

      // Close all existing windows, starting from the leaf nodes
      const currentSnapshot = await this._takeSnapshot();
      await performWindowActionFromLeafToRoot(this._workspace, currentSnapshot, async (def) => {
        try {
          const windowActor = await Actor.get<CommonWindowActorSchema>(def.id, 1_000);
          const destroyFn = windowActor.operations.destroy();
          const actorName = windowActor.name;
          const actorCtx = windowActor.initialDefinition.context;

          const schema = actorRegistry.getActorByName(actorName);
          const confirmDestroy = schema?.confirmDestroy;

          if (!confirmDestroy) {
            await destroyFn;
            return;
          }

          await confirmDestroy(destroyFn, actorCtx);

          const child = this._children.get(def.id);
          if (child) {
            this._removeChild(child.id).catch(console.error);
          }
        } catch (e) {
          console.error(e);
        }
      });

      // Wait... this allows React to render/remove children, ready for a re-render
      // Because IDs are sometimes the same, React doesn't re-render and we get a stale state
      await new Promise((resolve) => setTimeout(resolve, 0));

      // Note: We have to create each actor sequentially, as we need to wait for the previous one to be created before we can create the next one
      // Otherwise, Tauri locks up.
      for (const child of children) {
        // Get the actor schema by either name or type
        const schema = child.name
          ? actorRegistry.getActorByName(child.name)
          : actorRegistry.getActorByType(child.type, this.getDefinition(), child);

        if (!schema) {
          throw new Error(
            `Actor ${child.name ? 'name' : 'type'} "${
              child.name || child.type
            }" not found in the actor registery when trying to spawn child actor from actor "${this.name}"`
          );
        }

        // Ignore actors that are not snapshotable
        if (schema?.ignoreFromApplySnapshot) {
          continue;
        }

        await this.spawnChild({
          ...child,
          parentId: this.id,
          applyingSnapshotFromId: this.id
        });

        await new Promise((resolve) => setTimeout(resolve, 0)); // Allow React to render
      }

      await this._triggerHook('after', 'applySnapshot', undefined);

      this.state = 'running';
      this._fireStateChangedEvent().catch(console.error);

      this._eventsTransport
        .emit(EVENT_TYPE.ACTOR_APPLYING_SNAPSHOT_RESULT, {
          id: this.id,
          type: this.type,
          parentId: this.parentId,
          isSuccessful: true
        })
        .catch(console.error);
    } catch (e) {
      this._eventsTransport
        .emit(EVENT_TYPE.ACTOR_APPLYING_SNAPSHOT_RESULT, {
          id: this.id,
          type: this.type,
          parentId: this.parentId,
          isSuccessful: false,
          error: (e as Error)?.message || 'Unknown error in applying snapshot'
        })
        .catch(console.error);

      this.state = 'running';
      this._fireStateChangedEvent().catch(console.error);

      throw e;
    }
  }

  /**
   * Take a snapshot of the actor and all children
   * by recursively proxying & calling getDefinition on all children
   *
   * Note: This is the public method, which runs hooks
   *
   * @returns ActorSnapshotDefinition
   */
  async takeSnapshot(): Promise<ActorSnapshotDefinition> {
    await this._eventsTransport.emit(EVENT_TYPE.ACTOR_TAKING_SNAPSHOT, {
      id: this.id,
      type: this.type,
      parentId: this.parentId
    });

    try {
      await this._triggerHook('before', 'takeSnapshot', undefined);

      const snapshotDef = await this._takeSnapshot();

      // Run post hook
      const result = await this._triggerHook('after', 'takeSnapshot', snapshotDef);

      const data = result || snapshotDef;

      await this._eventsTransport.emit(EVENT_TYPE.ACTOR_TAKING_SNAPSHOT_RESULT, {
        id: this.id,
        type: this.type,
        parentId: this.parentId,
        isSuccessful: true,
        snapshot: data
      });

      return data;
    } catch (e) {
      this._eventsTransport
        .emit(EVENT_TYPE.ACTOR_TAKING_SNAPSHOT_RESULT, {
          id: this.id,
          type: this.type,
          parentId: this.parentId,
          isSuccessful: false,
          error: (e as Error)?.message || 'Unknown error in taking snapshot'
        })
        .catch(console.error);

      throw e;
    }
  }

  /**
   * Take a snapshot of the actor and all children
   * by recursively proxying & calling getDefinition on all children
   *
   * @returns ActorSnapshotDefinition
   */
  private async _takeSnapshot(): Promise<ActorSnapshotDefinition> {
    const actorRegistry = this._workspace.getActorRegistry();
    const def = this.getDefinition();

    async function getChildSnapshots(childrenDef: ChildActorDefinition[]) {
      const snapshots: ActorSnapshotDefinition[] = [];
      for (const childDef of childrenDef) {
        const actor = await Actor.get(childDef.id);
        if (actor) {
          const def = await actor.getDefinition();
          const schema = actorRegistry.getActorByName(def.name);

          // Ignore actors that are not snapshotable
          if (schema?.ignoreFromTakeSnapshot) {
            continue;
          }

          const childSnapshot: ActorSnapshotDefinition = {
            ...def,
            children: def.children.length ? await getChildSnapshots(def.children) : []
          };
          snapshots.push(childSnapshot);
        }
      }
      return snapshots;
    }

    const snapshotDef: ActorSnapshotDefinition = {
      ...def,
      children: await getChildSnapshots(def.children)
    };

    return snapshotDef;
  }

  /**
   * Start the actor
   * - Run the actor's initialContext() function to get the initial context
   * - Expose the actor to the world for other actors to interact with
   * - Run the actor's events() function to subscribe to user-based events
   * - Spawn any children
   * - Emit events
   * - Set the actor's state to running
   *
   * @returns void
   */

  async start(options?: ActorStartOptions) {
    const { shouldSync } = options || {};
    if (this.state === 'starting' || this.state === 'running' || this.state === 'applying-snapshot') {
      return;
    }

    try {
      await this._triggerHook('before', 'start', undefined);

      this.state = 'starting';
      this._fireStateChangedEvent().catch(console.error);

      let initialContextFromSync: TContext | undefined;
      if (shouldSync || this._actorSchema.syncOnStart) {
        const ic = await this.sync(this._initialContextDef || {});
        initialContextFromSync = ic ? ic : undefined;
      }

      this.context = initialContextFromSync
        ? initialContextFromSync
        : await this._actorSchema.initialContext({
            initialContext: this._initialContextDef || {},
            schema: this._actorSchema,
            workspace: this._workspace
          });

      this._listenForEvents();

      if (this.parentId) {
        Actor.get(this.parentId)
          .then(() => {
            logger.debug(`[Actor: ${this.id}] Parent found and proxied: ${this.parentId}`);
          })
          .catch((e) => {
            console.error(
              `[Actor: ${this.id}] Parent not found and proxied: ${this.parentId}, assigning to leader process or undefined`,
              e
            );
            this.parentId =
              this.parentId !== this._workspace.getLeaderProcessId()
                ? this._workspace.getLeaderProcessId()
                : undefined;
          });
      }

      if (this._actorSchema.events) {
        const unsub = await this._actorSchema.events(this._getSchemaAPI(), this._workspace);
        this._eventsUnsubscribe = typeof unsub === 'function' ? unsub : undefined;
      }

      this._expose();

      if (this._initialCreateDef?.children) {
        const children = this._initialCreateDef.children.map((createOptions) => {
          const { type, name } = createOptions;
          const actorRegistry = this._workspace.getActorRegistry();

          // Get the actor schema by either name or type
          const actorSchema = name
            ? actorRegistry.getActorByName(name)
            : actorRegistry.getActorByType(type, this.getDefinition(), createOptions);

          return {
            createOptions,
            actorSchema,
            isRootable: actorSchema?.isWindowRootable
          };
        });

        const nonRootableChildren = children.filter((child) => !child.isRootable);

        const rootableChildren = children.filter((child) => child.isRootable);

        // Synchrnously spawn non-rootable children
        // We don't need to block the actor from starting if the child is not rootable (actor inside a window)
        for (const child of nonRootableChildren) {
          this.spawnChild(
            {
              ...child.createOptions,
              parentId: this.id
            },
            child.actorSchema
          ).catch(console.error);
        }

        // Asynchronously spawn rootable children, we want to block the actor from starting until the child is ready
        // To avoid deadlocks in Tauri
        for (const child of rootableChildren) {
          await this.spawnChild(
            {
              ...child.createOptions,
              parentId: this.id
            },
            child.actorSchema
          );
        }
      }

      this._eventsTransport.emit(EVENT_TYPE.ACTOR_STARTED, this.getDefinition()).catch(console.error);

      const applyingSnapshotFromId = this._initialCreateDef?.applyingSnapshotFromId;

      this.state = applyingSnapshotFromId ? 'applying-snapshot' : 'running';
      this._fireStateChangedEvent().catch(console.error);

      if (this._userCreated) {
        this._eventsTransport
          .emit(EVENT_TYPE.ACTOR_CREATED, {
            ...this.getDefinition(),
            isUserCreated: true
          })
          .catch(console.error);
      }
      await this._triggerHook('after', 'start', undefined);
    } catch (error) {
      console.error(error);
      this.failedMessage = (error as Error).message;
      this.state = 'failed';
      this._eventsTransport
        .emit(EVENT_TYPE.ACTOR_FAILED, {
          definition: this.getDefinition(),
          failedReason: this.failedMessage
        })
        .catch(console.error);
      this._fireStateChangedEvent().catch(console.error);
      throw error;
    }
  }

  /**
   * Destroy the actor
   * - Emit events
   * - Unexpose the actor
   * - Clear all listeners & clean-up
   *
   * @returns void
   */
  async destroy() {
    try {
      await this._triggerHook('before', 'destroy', undefined);
      this._eventsTransport
        .emit(EVENT_TYPE.ACTOR_BEFORE_DESTROY, {
          id: this.id,
          type: this.type,
          parentId: this.parentId
        })
        .catch(console.error);

      this._eventsUnsubscribe?.();
      this.state = 'destroyed';
      this._unlistenForEvents();
      if (this._unexpose) {
        this._unexpose();
        this._internalProxy = undefined;
        deleteLocalActorProxy(this.id);
      }
      logger.debug(`[Actor: ${this.id}] Has been destroyed`);
      await this._eventsTransport.emit(EVENT_TYPE.ACTOR_DESTROYED, {
        id: this.id,
        type: this.type,
        parentId: this.parentId
      });
      await this._fireStateChangedEvent();
      const actorSchema = this._workspace.getActorRegistry().getActorByName(this._actorSchema.name);
      if (actorSchema?.destroy && this._workspace.getIsLeader() === false) {
        await actorSchema.destroy(this.getDefinition(), this._workspace);
      }
      await this._triggerHook('after', 'destroy', undefined);
    } catch (error) {
      console.error(error);
      this.failedMessage = (error as Error).message;
      this.state = 'failed';
      await this._fireStateChangedEvent();
      throw error;
    }
  }

  /**
   * Sync the actor's view with the actor's context
   *
   * @returns void
   */
  async sync(contextDelta?: Partial<TContext>, isFocus = false) {
    return await this._actorSchema.sync?.({
      contextDelta: contextDelta || this.context,
      operations: this._operations,
      workspace: this._workspace,
      isFocus
    });
  }

  /**
   * Update a portion of actor's context via its proxy
   * This is to trigger the proxy's change event
   *
   * @private
   * @param context - Partial<TContext>
   * @returns void
   */
  private async _updateContext<T extends AnyRecord = AnyRecord>(
    context: Partial<T>,
    _operation: ActorOperationOptions = {}
  ) {
    // Run before hook
    const result = await this._triggerHook('before', 'contextChange', [context, _operation]);

    if (result) {
      context = result[0] as Partial<T>;
    }

    const nextContext = { ...this.context, ...context };
    await this._setContext(nextContext, _operation, true);

    // Run after hook
    await this._triggerHook('after', 'contextChange', [nextContext, _operation]);
  }

  /**
   * Set the entire actor's context via its proxy
   * This is to trigger the proxy's change event
   *
   * @private
   * @param context - TContext
   * @returns void
   */
  private async _setContext<T extends AnyRecord = AnyRecord>(
    context: T,
    _operation: ActorOperationOptions = {},
    triggeredFromUpdate = false
  ) {
    let nextContext = { ...context };

    if (triggeredFromUpdate === false) {
      // Run before hook
      const result = await this._triggerHook('before', 'contextChange', [context, _operation]);

      if (result) {
        nextContext = result[0] as T;
      }
    }

    await this._internalProxy?.context.set(nextContext);

    this._eventsTransport
      .emit(EVENT_TYPE.ACTOR_CONTEXT_CHANGED, {
        id: this.id,
        type: this.type,
        parentId: this.parentId,
        definition: this.getDefinition(),
        context: nextContext
      })
      .catch(console.error);

    if (triggeredFromUpdate === false) {
      // Run after hook
      await this._triggerHook('after', 'contextChange', [nextContext, _operation]);
    }
  }

  /**
   * Helper to determine the actors parent ID
   *
   * @private
   * @param createOptions - CreateActorDefinition
   * @param context - InternalActorContext
   * @returns void
   */
  private _determineParentId(
    createOptions: CreateActorDefinition,
    context: InternalActorContext
  ): string | undefined {
    return createOptions.parentId
      ? createOptions.parentId
      : context.workspace.getIsLeader() === false
        ? context.workspace.getLeaderProcessId()
        : undefined;
  }

  /**
   * Helper to get a typed version of the actor's schema
   *
   * @private
   * @param context - TContext
   * @returns ActorSchema<TContext, TOperations>
   */
  private _getTypedSchema(context: InternalActorContext) {
    return context.actorSchema as unknown as ActorSchema<TContext, TOperations>;
  }

  /**
   * Helper to get a typed version of the actor's operations
   *
   * @private
   * @param context - InternalActorContext
   * @returns TOperations
   */
  private _getTypedSchemaOperations(context: InternalActorContext): TOperations {
    return (
      this._getTypedSchema(context).operations?.(this._getSchemaAPI(), this._workspace) || ({} as TOperations)
    );
  }

  /**
   * Setup the actor's before & after hooks defined in the schema
   *
   * @private
   * @param context - InternalActorContext
   * @returns void
   */
  private _setupActorHooks(context: InternalActorContext) {
    context.actorSchema.hooks?.({
      ...(this._getSchemaAPI() as ActorSchemaAPI<any, any>),
      hooks: {
        before: (key, callback) => {
          const hooks = this._beforeHooks.get(key);
          if (hooks) {
            hooks.add({
              callback,
              type: this.type
            });
          } else {
            this._beforeHooks.set(key, new Set([{ callback, type: this.type }]));
          }
          return () => {
            hooks?.delete({ callback, type: this.type });
          };
        },
        after: (key, callback) => {
          const hooks = this._afterHooks.get(key);
          if (hooks) {
            hooks.add({ callback, type: this.type });
          } else {
            this._afterHooks.set(key, new Set([{ callback, type: this.type }]));
          }
          return () => {
            hooks?.delete({ callback, type: this.type });
          };
        }
      }
    });
  }

  /**
   * Expose the actor to other actors
   * This is done by exposing itself but ignoring certain properties that are not needed
   * Additionally, we create a proxy to the actor for internal use, to get real-time updates on "set"
   *
   * @private
   * @returns void
   */
  private _expose() {
    const [unexpose] = expose(this, this.id, {
      autoSerialize: false,
      observe: false,
      resolveProxy: false,
      awaitConfirmation: false,
      ignoreProperties: [
        '_userCreated',
        '_context',
        '_operations',
        '_eventsTransport',
        '_workspace',
        '_parentProxy',
        '_children',
        '_listenForEvents',
        '_listeners',
        '_eventHandlers',
        '_unlistenForEvents',
        '_internalProxy',
        '_initialCreateDef',
        '_initialContextDef',
        '_actorSchema',
        '_renderAndStart',
        '_getSchemaAPI',
        '_beforeHooks',
        '_afterHooks',
        '_spawningChildren',
        '_unexpose',
        '_triggerHook',
        '_runHooks',
        '_eventsUnsubscribe',
        '_addChild',
        '_removeChild',
        '_fireStateChangedEvent',
        '_internalUpdatePublicChildren',
        '_setContext',
        '_updateContext',
        '_getTypedSchema',
        '_getTypedSchemaOperations',
        '_setupActorHooks',
        '_determineParentId',
        '_expose',
        '_takeSnapshot'
      ] as (keyof typeof this)[]
    });
    this._unexpose = unexpose;
    this._internalProxy = createProxy<InternalActor>(this.id, {
      awaitConfirmation: false
    });
    setLocalActorProxy(this.id, this._internalProxy);
  }

  /**
   * Helper to add a child to the actor
   *
   * @private
   * @param id - string
   * @param child - ChildInternalActor
   * @returns void
   */
  private async _addChild(id: string, child: ChildInternalActor) {
    if (!this._children.has(id)) {
      this._children.set(id, child);
      await this._internalUpdatePublicChildren();
    }
    this._spawningChildren.delete(id);
  }

  /**
   * Helper to remove a child from the actor
   *
   * @private
   * @param id - string
   * @returns void
   */
  private async _removeChild(id: string) {
    if (this._children.has(id)) {
      this._children.delete(id);
      await this._internalUpdatePublicChildren();
    }
  }

  /**
   * Helper used to set children on the actor's proxy
   * This is to trigger the proxy's change event
   *
   * @private
   * @returns void
   */
  private async _internalUpdatePublicChildren() {
    const children = Array.from(this._children, ([, v]) => ({
      id: v.proxy.id,
      name: v.proxy.name,
      type: v.proxy.type
    }));
    await this._internalProxy?.children.set(children);
    this._eventsTransport
      .emit(EVENT_TYPE.ACTOR_CHILDREN_CHANGED, {
        id: this.id,
        type: this.type,
        parentId: this.parentId,
        children
      })
      .catch(console.error);
  }

  /**
   * Helper used to render a new actor
   * - Get the actor's renderer
   * - Render the actor
   * - - By creating a new process with a definition in the URL
   * - - Or, creating a new instance of the actor in this process
   * - Start the actor
   * - Wait for events to confirm the actor has started (if in another process)
   * - Resolve or reject the promise
   *
   * @private
   * @param options - CreateActorOptions
   * @returns void
   */
  private _renderAndStart = (actorSchema: ActorSchema, id: string, options: CreateActorOptions) =>
    new Promise<InternalActor | null>((resolve, reject) => {
      const { type, children, name, context, applyingSnapshotFromId } = options;

      let timeout: NodeJS.Timeout | undefined = undefined;
      let actorInstance: InternalActor | null = null;

      const unsubscribeStarted = this._eventsTransport.listen(EVENT_TYPE.ACTOR_STARTED, (p) => {
        if (p.id === id) {
          unsubscribeStarted();
          if (timeout) clearTimeout(timeout);
          resolve(actorInstance);
        }
      });

      const unsubscribeFailed = this._eventsTransport.listen(EVENT_TYPE.ACTOR_FAILED, (p) => {
        if (p.definition.id === id) {
          unsubscribeFailed();
          if (timeout) clearTimeout(timeout);
          console.error(`Child ${id} failed to start: ${p.failedReason}`);
          reject(new Error(`Child ${id} failed to start: ${p.failedReason}`));
        }
      });

      const isWindowRootable = actorSchema.isWindowRootable;

      const render = actorSchema.render;

      const createDefinition: CreateActorDefinition = {
        id,
        type,
        name,
        children:
          children?.map((c) => ({
            id: c.id || this._workspace.generateUUID(), // TODO: Hierarchical IDs
            name: getActorNameByType(this._workspace, c.type, this.getDefinition(), c),
            type: c.type,
            parentId: id,
            context: c.context,
            children: c.children || [],
            applyingSnapshotFromId
          })) || [],
        context,
        parentId: this.id,
        applyingSnapshotFromId
      };

      switch (true) {
        case isWindowRootable && !render: {
          reject(new Error(`Actor renderer for multi-process type "${type}" not found but required`));
          break;
        }
        case isWindowRootable && !!render: {
          render?.(
            createActorURL(this._workspace.getWorkspaceOptions(), createDefinition).toString(),
            createDefinition,
            this._workspace
          )?.catch((error) => {
            console.error(error);
            if (timeout) clearTimeout(timeout);
            unsubscribeStarted();
            unsubscribeFailed();
            reject(error);
          });
          break;
        }
        case !isWindowRootable: {
          actorInstance = new InternalActor(
            {
              workspace: this._workspace,
              eventsTransport: this._eventsTransport,
              actorSchema
            },
            createDefinition
          );

          actorInstance.start().catch((error) => {
            console.error(error);
          });

          break;
        }
      }

      timeout = setTimeout(() => {
        unsubscribeStarted();
        unsubscribeFailed();
        reject(new Error(`Child ${id} failed to start due to timeout of more than 30s.`));
      }, 30_000);
    });

  /**
   * Helper to listen for private events on the actor
   *
   * @private
   * @returns void
   */
  private _listenForEvents() {
    Object.entries(this._eventHandlers).forEach(([eventType, handler]) => {
      const listener = this._eventsTransport.listen(
        eventType as EventType,
        handler as (p: CoreEvents['payload']) => void | Promise<void>
      );
      this._listeners.push(listener);
    });
  }

  /**
   * Define important private event handlers
   * - Actor created
   * - - Add child if it is the parent and it doesn't already exist
   * - Actor before destroy
   * - - Destroy self if the parent is closing
   * - - Delete child if it is the parent and it already exists
   *
   * @private
   * @returns void
   */
  private _eventHandlers = {
    [EVENT_TYPE.ACTOR_CREATED]: async (p: ActorCreatedEvent['payload']) => {
      // Add child if it is the parent and it doesn't already exist
      if (p.parentId === this.id && this._children.has(p.id) === false) {
        const actorProxy = await Actor.get(p.id);
        if (actorProxy) {
          await this._addChild(p.id, {
            id: p.id,
            proxy: actorProxy
          });
          logger.debug(`[Actor: ${this.id}] Child created & added ${p.id}`, p);
        }
      }
    },
    [EVENT_TYPE.ACTOR_BEFORE_DESTROY]: async (p: ActorDestroyedEvent['payload']) => {
      // Destroy self if the parent is closing
      if (this.parentId && this.parentId === p.id) {
        logger.debug(`[Actor: ${this.id}] Parent destroyed, destroying self`);
        this.destroy().catch(console.error);
      }

      // Delete child if it is the parent and it already exists
      if (p.parentId === this.id && this._children.has(p.id) === true) {
        await this._removeChild(p.id);
        logger.debug(`[Actor: ${this.id}] Child destroyed & removed ${p.id}`, {
          actor: p
        });
      }
    },
    [EVENT_TYPE.ACTOR_PARENT_CHANGED]: async (p: ActorParentChangedEvent['payload']) => {
      if (p.fromParentId === this.id) {
        await this._removeChild(p.id);
        logger.debug(`[Actor: ${this.id}] Child removed ${p.id} due to parent change`, {
          actor: p
        });
      }

      if (p.toParentId === this.id) {
        const actorProxy = await Actor.get(p.id);
        if (actorProxy) {
          await this._addChild(p.id, {
            id: p.id,
            proxy: actorProxy
          });
          logger.debug(`[Actor: ${this.id}] Child added ${p.id} due to parent change`, p);
        }
      }
    },
    [EVENT_TYPE.ACTOR_PING]: async (p: ActorPingEvent['payload']) => {
      if (this.state === 'destroyed') {
        return;
      }

      this._eventsTransport
        .emit(EVENT_TYPE.ACTOR_PONG, {
          actor: this.getDefinition(),
          pingId: p.pingId,
          state: this.state
        })
        .catch(console.error);
    },
    [EVENT_TYPE.ACTOR_APPLYING_SNAPSHOT_RESULT]: (p: ActorTakingSnapshotResultEvent['payload']) => {
      const applyingFromId = this._initialCreateDef?.applyingSnapshotFromId;
      const matchesAncestor = p.id === applyingFromId;

      if (matchesAncestor && p.isSuccessful && this.state === 'applying-snapshot') {
        this.state = 'running';
        this._fireStateChangedEvent().catch(console.error);
      }
    }
  } as const;

  /**
   * Helper to unlisten for private events on the actor
   *
   * @private
   * @returns void
   */
  private _unlistenForEvents() {
    this._listeners.forEach((listener) => listener());
    this._listeners = [];
  }

  /**
   * Helper to fire the state changed event
   *
   * @private
   * @returns Promise<void>
   */
  private _fireStateChangedEvent = () =>
    this._eventsTransport.emit(EVENT_TYPE.ACTOR_STATE_CHANGED, {
      id: this.id,
      state: this.state,
      type: this.type,
      failedMessage: this.failedMessage,
      parentId: this.parentId
    });

  /**
   * Helper to get an API for the actor's schema functions
   * Usually used as context for schema hooks/methods
   *
   * @private
   * @returns void
   */
  private _getSchemaAPI(): ActorSchemaAPI<TContext, TOperations> {
    return {
      workspace: this._workspace,
      getDefinition: this.getDefinition.bind(this),
      setContext: this._setContext.bind(this),
      updateContext: this._updateContext.bind(this),
      destroy: this.destroy.bind(this),
      getContext: () => this.context,
      operations: this.operations,
      spawnChild: this.spawnChild.bind(this),
      destroyChild: this.destroyChild.bind(this),
      getInternalChildren: () => Array.from(this._children.values())
    };
  }

  /**
   * Helper to trigger a hook defined by the actor's schema OR at the workspace level
   * This is used to run before & after hooks
   *
   * @private
   * @param type - 'before' | 'after'
   * @param hookType - keyof ActorHookMap
   * @returns HookReturn<ActorHookMap[K][T] | Promise<HookReturn<ActorHookMap[K][T]>
   */
  private _triggerHook<T extends 'before' | 'after', K extends keyof ActorHookMap>(
    type: T,
    hookType: K,
    dataIn: ActorHookMap[K][T]
  ): HookReturn<ActorHookMap[K][T]> | Promise<HookReturn<ActorHookMap[K][T]>> {
    const hooks =
      type === 'before'
        ? [
            ...(this._workspace.getBeforeActorHooks().get(hookType) || []),
            ...(this._beforeHooks.get(hookType) || [])
          ]
        : [
            ...(this._workspace.getAfterActorHooks().get(hookType) || []),
            ...(this._afterHooks.get(hookType) || [])
          ];

    if (hooks.length === 0) {
      return dataIn;
    }

    return this._runHooks(hooks, dataIn);
  }

  /**
   * Helper to run hooks
   *
   * @private
   * @returns void
   */
  private async _runHooks<T extends 'before' | 'after', K extends keyof ActorHookMap>(
    hooks: AnyActorHook[],
    dataIn: ActorHookMap[K][T]
  ): Promise<HookReturn<ActorHookMap[K][T]>> {
    let nextDataIn: ActorHookMap[K][T] | undefined = undefined;
    let resultOut: HookReturn<ActorHookMap[K][T]> | undefined = undefined;

    for (const { type: actorType, callback } of hooks) {
      if (actorType !== this.type) {
        continue;
      }

      const result = await callback(nextDataIn || dataIn, this._getSchemaAPI());
      nextDataIn = result;
      resultOut = nextDataIn ?? resultOut;
    }

    return resultOut || dataIn;
  }
}

/**
 * Get the actor's name by its type
 *
 * @param workspace - Workspace
 * @param type - string
 * @returns string
 */
export function getActorNameByType(
  workspace: Workspace,
  type: string,
  source?: CreateActorDefinition,
  createOptions?: CreateActorOptions<AnyRecord>
) {
  const Actor = workspace.getActorRegistry().getActorByType(type, source, createOptions);
  return Actor ? Actor.name : 'Unknown';
}

/**
 * Check if the actor type is window rootable
 *
 * @param type - string
 * @param workspace - Workspace
 * @param source - CreateActorDefinition
 * @returns boolean
 */
export function isActorTypeWindowRoot(
  workspace: Workspace,
  type: string,
  source?: CreateActorDefinition,
  createOptions?: CreateActorOptions<AnyRecord>
) {
  const Actor = workspace.getActorRegistry().getActorByType(type, source, createOptions);
  return Actor?.isWindowRootable ?? false;
}

/**
 * Check if the actor name is window rootable
 *
 * @param type - string
 * @param workspace - Workspace
 * @returns boolean
 */
export function isActorNameWindowRoot(workspace: Workspace, name: string) {
  const Actor = workspace?.getActorRegistry()?.getActorByName?.(name);
  return Actor?.isWindowRootable ?? false;
}

/**
 * Create an enriched URL with the actor definition in the query params
 * to be used as context for the actor's schema's render() function
 *
 * @param def - CreateActorDefinition
 * @param url - string | URL
 * @returns URL
 */
function createActorURL(
  { rootableActorLoadingStrategy = 'url' }: WorkspaceOptions,
  def: CreateActorDefinition,
  existingUrl?: string | URL
) {
  const Url =
    existingUrl && existingUrl instanceof URL ? existingUrl : new URL(existingUrl || window.location.href);

  if (rootableActorLoadingStrategy === 'localStorage') {
    Url.searchParams.set(URL_PARAM.ACTOR_DEFINITION_ID, def.id);
    localStorage.setItem(getStorageKey(def.id), JSON.stringify(def));
  } else {
    Url.searchParams.set(URL_PARAM.ACTOR_DEFINITION_JSON, JSON.stringify(def));
  }

  return Url;
}

type NodeWithDepth = {
  node: ActorSnapshotDefinition<any>;
  depth: number;
};

/**
 * Recursively collect all window nodes in the snapshot by depth
 *
 * @param node - {ActorSnapshotDefinition<any>}
 * @param depth - number
 * @returns {NodeWithDepth[]}
 */
function collectNodes(node: ActorSnapshotDefinition<any>, depth = 0): NodeWithDepth[] {
  let nodes: NodeWithDepth[] = [{ node, depth }];
  const windowChildren = node.children.filter((child) => child.type === COMMON_ACTOR_TYPE.WINDOW);
  for (const child of windowChildren) {
    nodes = nodes.concat(collectNodes(child, depth + 1));
  }
  return nodes;
}

/**
 * Performs an action on each window node in the snapshot from leaf to root
 *
 * @param workspace - {Workspace}
 * @param snapshot - {ActorSnapshotDefinition<any>}
 * @param action - Callback function to perform on each window node
 */
async function performWindowActionFromLeafToRoot(
  workspace: Workspace,
  snapshot: ActorSnapshotDefinition<any>,
  action: (node: ActorSnapshotDefinition<WindowContext>) => Promise<void>
): Promise<void> {
  const windowNodes = collectNodes(snapshot).filter((n) => n.node.id !== workspace.getLeaderProcessId());

  // Sort nodes by depth in descending order
  windowNodes.sort((a, b) => b.depth - a.depth);

  // Perform the action on each node
  for (const { node } of windowNodes) {
    await action(node);
  }
}
