import type { UseFieldApiConfig, Field } from '@data-driven-forms/react-form-renderer';
import type { EnhancedFormOptions } from '@oms/frontend-foundation';
import type { ActionButtonFormValues } from './action-button.form-contract';
import { actionButtonCommands } from '../utils/button.action.schema';

export const BUTTON_CONFIG_ALLOWED_COMMANDS_ACTION = 'buttonConfigAllowedCommands';

export const buttonConfigAllowedCommands =
  () =>
  (_field: Field, _fieldApi: UseFieldApiConfig, formOptions: EnhancedFormOptions<ActionButtonFormValues>) => {
    const formValues = formOptions.getState()?.values || {};
    const { allowedCommands = [] } = formValues;

    const options: ReturnType<typeof actionButtonCommands> = actionButtonCommands().filter(
      (a) => !allowedCommands?.length || allowedCommands.includes(a.value)
    );
    return { options };
  };
