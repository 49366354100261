import { FieldDefinition, FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import { email, firstName, lastName, phoneNumber } from './mock-user-settings.form-fields';
import type { MockUserSettingsOutput } from './mock-user-settings.form-common';
import {
  createFormContractWrapper,
  createFormContractTemplateOptions
} from '../../user-preferences.styling.util';

export const mockUserSettingsContract = FormContract.create<MockUserSettingsOutput>()
  .fields({
    email,
    firstName,
    lastName,
    phoneNumber
  })
  .schema((f) => ({
    fields: [
      createFormContractWrapper([
        FieldDefinition.simpleGrid('grid', 2, [f.firstName, f.lastName, f.email, f.phoneNumber])
      ])
    ]
  }))
  .template('simple', createFormContractTemplateOptions());

export type MockUserSettingsContractType = typeof mockUserSettingsContract;

export type MockUserSettingsValues = InferFormValuesFromFormContract<MockUserSettingsContractType>;
