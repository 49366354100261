import { createIconComponent } from './create-icon-component';
import {
  Pencil1Icon as RadixEditIcon,
  CheckIcon as RadixCheckIcon,
  CheckCircledIcon as RadixCheckCircledIcon,
  StackIcon as RadixStackIcon,
  StarIcon as RadixStarIcon,
  StarFilledIcon as RadixStarFilledIcon,
  HamburgerMenuIcon as RadixHamburgerMenuIcon,
  Cross1Icon as RadixCross1Icon,
  MinusIcon as RadixMinusIcon,
  ExternalLinkIcon as RadixExternalLinkIcon,
  EnterFullScreenIcon as RadixFullScreenIcon,
  ExitFullScreenIcon as RadixExitFullScreenIcon,
  LockClosedIcon as RadixLockClosedIcon,
  LockOpen1Icon as RadixLockOpenIcon,
  EnvelopeOpenIcon as RadixEnvelopeOpenIcon,
  GridIcon as RadixGridIcon,
  LightningBoltIcon as RadixLightningBoltIcon,
  PersonIcon as RadixPersonIcon,
  MixerHorizontalIcon as RadixMixerHorizontalIcon,
  CodeIcon as RadixCodeIcon,
  PlusIcon as RadixPlusIcon,
  ChevronRightIcon as RadixChevronRightIcon,
  ChevronLeftIcon as RadixChevronLeftIcon,
  DotFilledIcon as RadixDotFilledIcon,
  ArrowRightIcon as RadixArrowRightIcon,
  ArrowLeftIcon as RadixArrowLeftIcon,
  UpdateIcon as RadixUpdateIcon,
  SizeIcon as RadixSizeIcon,
  DragHandleDots2Icon as RadixDragHandleDots2Icon,
  StopIcon as RadixStopIcon,
  ResetIcon as RadixResetIcon,
  ReloadIcon as RadixReloadIcon,
  EyeClosedIcon as RadixEyeClosedIcon,
  DrawingPinIcon as RadixDrawingPinIcon,
  DrawingPinFilledIcon as RadixDrawingPinFilledIcon,
  UploadIcon as RadixUploadIcon,
  ClipboardCopyIcon as RadixClipboardCopyIcon,
  CaretSortIcon as RadixCaretSortIcon,
  CaretDownIcon as RadixCaretDownIcon,
  CaretUpIcon as RadixCaretUpIcon,
  DotsVerticalIcon as RadixDotsVerticalIcon,
  InfoCircledIcon as RadixInfoCircledIcon,
  ExitIcon as RadixExitIcon,
  EnterIcon as RadixEnterIcon,
  MarginIcon as RadixMarginIcon,
  BoxIcon as RadixBoxIcon,
  TimerIcon as RadixTimerIcon,
  ExclamationTriangleIcon as RadixExclamationTriangleIcon,
  CrossCircledIcon as RadixCrossCircledIcon,
  Cross2Icon as RadixDeleteIcon,
  GearIcon as RadixGearIcon
} from '@radix-ui/react-icons';
import ErrorIconSvg from '../../assets/svg/assets/error.svg?react';
import WarningIconSvg from '../../assets/svg/assets/warning.svg?react';
import { ElementType } from 'react';

export const BoxIcon = createIconComponent(RadixBoxIcon, 'Box');
export const EnterIcon = createIconComponent(RadixEnterIcon, 'Enter');
export const SettingsIcon = createIconComponent(RadixGearIcon, 'Settings');
export const ExitIcon = createIconComponent(RadixExitIcon, 'Exit');
export const InfoCircledIcon = createIconComponent(RadixInfoCircledIcon, 'Info');
export const ExclamationTriangleIcon = createIconComponent(RadixExclamationTriangleIcon, 'Warning');
export const CheckCircledIcon = createIconComponent(RadixCheckCircledIcon, 'Success');
export const CrossCircledIcon = createIconComponent(RadixCrossCircledIcon, 'Error');
export const DotsVerticalIcon = createIconComponent(RadixDotsVerticalIcon, 'Options');
export const CaretDownIcon = createIconComponent(RadixCaretDownIcon, 'Down');
export const CaretUpIcon = createIconComponent(RadixCaretUpIcon, 'Up');
export const ClipboardCopyIcon = createIconComponent(RadixClipboardCopyIcon, 'Copy');
export const ToggleOpenSelect = createIconComponent(RadixCaretSortIcon, 'Toggle open select');
export const UploadIcon = createIconComponent(RadixUploadIcon, 'Upload');
export const UnpinnedIcon = createIconComponent(RadixDrawingPinIcon, 'Unpinned');
export const PinnedIcon = createIconComponent(RadixDrawingPinFilledIcon, 'Pinned');
export const SleepIcon = createIconComponent(RadixEyeClosedIcon, 'Sleeping');
export const StopIcon = createIconComponent(RadixStopIcon, 'Stop');
export const ResetIcon = createIconComponent(RadixResetIcon, 'Reset');
export const ReloadIcon = createIconComponent(RadixReloadIcon, 'Reload');
export const EditIcon = createIconComponent(RadixEditIcon, 'Edit');
export const CheckIcon = createIconComponent(RadixCheckIcon, 'Check', {
  sx: { fontSize: 'mediumR' },
  style: { margin: 0 }
});

export const AcceptIcon = createIconComponent(RadixCheckIcon, 'Accept', {
  sx: { fontSize: 'mediumR' }
});
export const PositionWindowIcon = createIconComponent(RadixMarginIcon, 'Position Window');
export const SizeIcon = createIconComponent(RadixSizeIcon, 'Size');
export const DragIcon = createIconComponent(RadixDragHandleDots2Icon, 'Drag');
export const ResaveIcon = createIconComponent(RadixUpdateIcon, 'Re-Save');
export const LayoutIcon = createIconComponent(RadixStackIcon, 'Layout');
export const StarIcon = createIconComponent(RadixStarIcon, 'Favourite');
export const StarFilledIcon = createIconComponent(RadixStarFilledIcon, 'Make Favourite');
export const HamburgerMenuIcon = createIconComponent(RadixHamburgerMenuIcon, 'Menu');
export const CloseIcon = createIconComponent(RadixCross1Icon, 'Close');
export const RejectIcon = createIconComponent(RadixCross1Icon, 'Reject');
export const AddIcon = createIconComponent(RadixPlusIcon, 'Add');
export const MinusIcon = createIconComponent(RadixMinusIcon, 'Minus');
export const DeleteIcon = createIconComponent(RadixDeleteIcon, 'Delete');
export const MinimizeIcon = createIconComponent(RadixMinusIcon, 'Minimize');
export const UnMaximizeIcon = createIconComponent(RadixExitFullScreenIcon, 'Minimize');
export const MaximizeIcon = createIconComponent(RadixFullScreenIcon, 'Maximize');
export const PopOutIcon = createIconComponent(RadixExternalLinkIcon, 'Pop Out');
export const PopInIcon = createIconComponent(RadixExternalLinkIcon, 'Pop In', {
  style: { transform: 'scale(-1, -1)' }
});
export const LockIcon = createIconComponent(RadixLockClosedIcon, 'Lock');
export const UnlockIcon = createIconComponent(RadixLockOpenIcon, 'Unlock');
export const OrdersListIcon = createIconComponent(RadixEnvelopeOpenIcon, 'Orders List');
export const MontageIcon = createIconComponent(RadixGridIcon, 'Montage');

export const CheckAllIcon = createIconComponent(RadixCheckIcon, 'Check All');
export const TradeIcon = createIconComponent(RadixLightningBoltIcon, 'Trade');
export const ActionIcon = createIconComponent(RadixLightningBoltIcon, 'Action');
export const UserIcon = createIconComponent(RadixPersonIcon, 'User');
export const FilterRightIcon = createIconComponent(RadixMixerHorizontalIcon, 'Filter');
export const FilterIcon = createIconComponent(RadixMixerHorizontalIcon, 'Filter');
export const CodeIcon = createIconComponent(RadixCodeIcon, 'Code');

export const ChevronRightIcon = createIconComponent(RadixChevronRightIcon, 'Right');
export const ChevronLeftIcon = createIconComponent(RadixChevronLeftIcon, 'Left');
export const DotFilledIcon = createIconComponent(RadixDotFilledIcon, 'Selected');

export const ArrowRightIcon = createIconComponent(RadixArrowRightIcon, 'Arrow right');
export const ArrowLeftIcon = createIconComponent(RadixArrowLeftIcon, 'Arrow left');
export const TimerIcon = createIconComponent(RadixTimerIcon, 'Timer');

export const ErrorIcon = createIconComponent(ErrorIconSvg as ElementType, 'Error', {
  suppressDefaultBoxSize: true
});
export const WarningIcon = createIconComponent(WarningIconSvg as ElementType, 'Warning', {
  suppressDefaultBoxSize: true
});
