import { logger } from './logger';
import { Workspace } from './workspace';

export const COMMON_PLATFORM_NAME = {
  BROWSER: 'browser',
  TAURI: 'tauri'
} as const;

export type CommonPlatformNameType = (typeof COMMON_PLATFORM_NAME)[keyof typeof COMMON_PLATFORM_NAME];

export type CheckPlatformPredicate = () => boolean;

export type MonitorOrientation = 'landscape' | 'portrait';

export type MonitorInfo = {
  name: string | null;
  width: number;
  height: number;
  x: number;
  y: number;
  scaleFactor: number;
  orientation?: MonitorOrientation;
  [key: string]: any;
};

export type PlatformInfo = {
  name: string;
  version?: string;
  platform?: string;
  arch?: string;
  locale?: string;
  os?: string;
  monitors?: MonitorInfo[];
  [key: string]: any;
};

export type PlatformOptions = {
  /**
   * Unique name of the platform (used for registration & identification)
   */
  name: string;
  /**
   * Predicate function that is run to determine if the platform is active
   */
  predicate?: CheckPlatformPredicate;
  /**
   * Function that is run to get platform information
   */
  getPlatformInfo?: () => PlatformInfo | Promise<PlatformInfo>;
  /**
   * Function that is run to get the current monitor
   */
  getCurrentMonitor?: () => MonitorInfo | Promise<MonitorInfo>;
  /**
   * Priority of the platform (used to determine which platform is active)
   */
  priority?: number;
  /**
   * Function that is run to determine if the current window is the leader
   */
  leaderElection?: (workspace: Workspace) => boolean | Promise<boolean>;
};

export type PlatformAPI = {
  /**
   * Unique name of the platform (used for registration & identification)
   */
  name: string;
  /**
   * Function that is run to get platform information
   */
  getPlatformInfo?: () => PlatformInfo | Promise<PlatformInfo>;
  /**
   * Function that is run to get the current monitor
   */
  getCurrentMonitor?: () => MonitorInfo | Promise<MonitorInfo>;
};

/**
 * Platform
 * - Represents a platform that can be registered with the workspace
 * - Platforms are used to provide platform specific functionality
 * - Platforms are used to provide platform specific information
 * - Platforms are used to make actor selections
 */
export class Platform {
  /**
   * Unique name of the platform (used for registration & identification)
   */
  name: string;
  /**
   * Predicate function that is run to determine if the platform is active
   */
  predicate?: CheckPlatformPredicate;
  /**
   * Function that is run to get platform information
   */
  getPlatformInfo?: () => PlatformInfo | Promise<PlatformInfo>;
  /**
   * Function that is run to get the current monitor
   */
  getCurrentMonitor?: () => MonitorInfo | Promise<MonitorInfo>;
  /**
   * Function that is run to determine if the current window is the leader
   */
  leaderElection?: (workspace: Workspace) => boolean | Promise<boolean>;
  /**
   * Priority of the platform (used to determine which platform is active)
   */
  priority?: number;

  private constructor(readonly options: PlatformOptions) {
    this.name = options.name;
    this.predicate = options.predicate;
    this.priority = options.priority;
    this.getPlatformInfo = options.getPlatformInfo;
    this.getCurrentMonitor = options.getCurrentMonitor;
    this.leaderElection = options.leaderElection;
  }

  /**
   *
   * @param options - PlatformOptions
   * @returns
   */
  static create(options: PlatformOptions) {
    return new Platform(options);
  }
}

export class PlatformRegistry {
  private _platforms: Map<string, Platform> = new Map();

  register(platform: Platform) {
    this._platforms.set(platform.name, platform);
    logger.debug(`[Platform Registry] Registered platform "${platform.name}"`, {
      platform
    });
    return this;
  }

  unregister(name: string) {
    this._platforms.delete(name);
    return this;
  }

  get(name: string): Platform | null {
    return this._platforms.get(name) || null;
  }

  getAll(): Platform[] {
    return Array.from(this._platforms.values());
  }

  getCurrentPlatformName(): string {
    const ascPlatforms = [...this._platforms.values()].sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));

    for (const { name, predicate } of ascPlatforms) {
      if (predicate && predicate()) {
        return name;
      }
    }

    return COMMON_PLATFORM_NAME.BROWSER;
  }

  getCurrentPlatformLeaderElection() {
    const platform = this.get(this.getCurrentPlatformName());
    return platform?.options.leaderElection;
  }

  getCurrentPlatformAPI(): PlatformAPI {
    const platform = this.get(this.getCurrentPlatformName());
    if (!platform) {
      throw new Error(`Platform "${this.getCurrentPlatformName()}" is not registered`);
    }
    return {
      name: platform.name,
      getPlatformInfo: platform.options.getPlatformInfo,
      getCurrentMonitor: platform.options.getCurrentMonitor
    };
  }

  destroy() {
    this._platforms.clear();
    return this;
  }
}

export const createPlatformRegistry = () => {
  return new PlatformRegistry();
};
