import { isTauri, Plugin } from '@valstro/workspace';
import { createLogger } from '@oms/shared/util';
import { createWorkspaceLeaderWindowReady$ } from '../../common/workspace/workspace.rxjs';
import { MDaaSProviderService } from '../../data-access/services/marketdata/mdaas-provider.service';
import type { IMDaaSProviderService } from '../../data-access/services/marketdata/mdaas-provider.service';
import { Mdg2Client } from '@fds/frontgate-js-sdk';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { AppState } from '@app/data-access/memory/app.stream';
import { combineLatest } from 'rxjs';
import { container } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { invokeTauriCommand } from '@app/tauri/commands';

export const marketdataPluginLogger = createLogger({ label: 'Marketdata Plugin' });

export interface MarketdataPluginOptions {
  MdgClient?: new (...args: any[]) => Mdg2Client;
  container: DependencyContainer;
}

/**
 * Creates the marketdata provider when the leader window is ready
 */
export const marketdataPlugin = (options: MarketdataPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-marketdata-plugin',
    pluginFn: ({ workspace }) => {
      const appStateService = container.resolve(AppState);
      let marketdataProvider: IMDaaSProviderService | undefined;

      const { MdgClient = Mdg2Client } = options || {};
      const leaderWindowReady$ = createWorkspaceLeaderWindowReady$(workspace);
      const ready$ = combineLatest([leaderWindowReady$, appStateService.ready$]);
      const sideCarSubscription = ready$.subscribe(() => {
        if (isTauri()) {
          void invokeTauriCommand('get_level2_sidecar').then((result) => {
            result.mapSync(
              (sidecar) => {
                const { name, status } = sidecar;
                if (typeof status === 'object') {
                  const pid = status.Started;
                  // eslint-disable-next-line no-restricted-syntax
                  console.debug(`✅ Sidecar "${name}" is running with PID: ${pid}`, sidecar);
                } else {
                  console.warn(`❌ Sidecar "${name}" failed to start. Status: ${status}`);
                }
              },
              (e) => {
                console.error(e);
              }
            );
          });
        }
      });

      const subscription = leaderWindowReady$.subscribe(() => {
        marketdataProvider?.dispose();
        const mdg2Client = new MdgClient(undefined, { shouldRestartSubscriptions: true });
        marketdataProvider = new MDaaSProviderService(workspace, mdg2Client);

        marketdataProvider.initialize();
      });

      // Cleanup function
      return async () => {
        await Promise.allSettled([
          sideCarSubscription.unsubscribe(),
          marketdataProvider?.dispose(),
          subscription.unsubscribe()
        ]);
      };
    }
  });
