import { mapRateType } from '@app/common/mappers/map-rate-type';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type {
  FieldContract,
  ModifiedFieldForOptions,
  ISelectField,
  INumberFormatField,
  AnyRecord
} from '@oms/frontend-foundation';
import { RateType } from '@oms/generated/frontend';

export const createCommissionFields = <
  TOutputContract extends AnyRecord,
  TRateType extends keyof TOutputContract,
  TCommission extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  rateType: TRateType,
  commission: TCommission,
  commissionTypeOverrides: ModifiedFieldForOptions<ISelectField<TOutputContract[TRateType]>> = {},
  commissionOverrides: ModifiedFieldForOptions<INumberFormatField<TOutputContract[TCommission]>> = {}
) => {
  const commissionTypeField = fc.field(rateType, 'select').options<ISelectField<any>>({
    wrapperSx: {
      display: 'flex',
      width: 'full'
    },
    label: 'Commission',
    options: Object.values(RateType)
      .filter((k) => k !== RateType.CostPlus && k !== RateType.Scale)
      .map((k) => ({ value: k, label: mapRateType(k) })),
    ...commissionTypeOverrides
  });

  const commissionField = fc.field(commission, 'number-format').options({
    wrapperSx: {
      width: 'full'
    },
    ...commissionOverrides
  });

  const commissionContainer = fc
    .field(`commission-container-${String(rateType)}-${String(commission)}`, 'box')
    .options({
      fields: [
        [
          fc
            .field(`commission-type-container-${String(rateType)}`, 'box')
            .options({
              fields: [[commissionTypeField.build()]],
              sx: { marginRight: '2px' },
              style: {
                width: '40%'
              }
            })
            .build()
        ],
        [
          fc
            .field(`commission-container-${String(commission)}`, 'box')
            .options({
              fields: [[commissionField.build()]],
              style: {
                width: '60%'
              }
            })
            .build()
        ]
      ],
      sx: {
        display: 'flex',
        flexDirection: 'row',
        width: 'full'
      }
    });

  return {
    commission: commissionField,
    commissionType: commissionTypeField,
    commissionContainer
  };
};
