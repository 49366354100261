import { tradingOrderStatusMapping } from './order-enums';

export const mapTradingOrderStatus = <T extends string | undefined | null>(
  value?: T,
  defaultVal = value || ''
): string => {
  return value && (tradingOrderStatusMapping[value as never] as string)
    ? (tradingOrderStatusMapping[value as never] as string)
    : defaultVal;
};
