import { FieldContract } from '@oms/frontend-foundation';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { MockUserSettingsOutput } from './mock-user-settings.form-common';

const fc = FieldContract.create<MockUserSettingsOutput, AdditionalFormFieldUnion>();

export const firstName = fc.field('firstName', 'text-field').options({
  label: 'First Name',
  isRequired: true
});

export const lastName = fc.field('lastName', 'text-field').options({
  label: 'Last Name',
  isRequired: true
});

export const email = fc.field('email', 'text-field').options({
  label: 'Email',
  isRequired: true
});

export const phoneNumber = fc.field('phoneNumber', 'text-field').options({
  label: 'Phone Number',
  isRequired: true
});
