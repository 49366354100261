import { useAppStateStream } from '@app/data-access/memory/app.stream';
import { Center, Flex } from '@oms/shared-frontend/ui-design-system';
import { APP_STATE_TYPE } from '@app/common/app/app.contracts';
import type { ValstroEntitlement } from '@app/common/auth/keycloak.types';
import { useUserRoles } from '@app/data-access/services/system/auth/auth.hooks';

const ChildComponent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      sx={{
        position: 'absolute',
        inset: 0
      }}
      direction="column"
    >
      {children}
    </Flex>
  );
};
export const AppStateWrapper = ({
  children,
  requiredEntitlements = []
}: {
  children: React.ReactNode;
  requiredEntitlements?: ValstroEntitlement[];
}) => {
  const canAccess = useUserRoles();
  const isUserAuthorized = canAccess(requiredEntitlements);
  const { state } = useAppStateStream();

  switch (state) {
    case APP_STATE_TYPE.IDLE:
      return null;
    case APP_STATE_TYPE.AUTHENTICATING:
      return <Center>Authenticating...</Center>;
    case APP_STATE_TYPE.UNAUTHORIZED:
      return <Center>Unauthorized</Center>;
    case APP_STATE_TYPE.DATA_ACCESS_CONNECTING:
      return <Center>Connecting...</Center>;
    case APP_STATE_TYPE.DATA_ACCESS_DISCONNECTED:
      return <Center>Data connection lost. Please refresh or restart.</Center>;
    case APP_STATE_TYPE.SYNCRONISING:
      return <Center>Syncronising user configuration...</Center>;
    case APP_STATE_TYPE.READY:
      switch (true) {
        case !isUserAuthorized:
          return <Center>Unauthorized</Center>;
        default:
          return <ChildComponent>{children}</ChildComponent>;
      }
    default:
      return null;
  }
};
