import type { Index, Maybe, OneOrMore, Optional } from '@oms/shared/util-types';

export type CompactMapTransformFn<T, ElementOfResult> = (
  element: T,
  index: number,
  array: T[]
) => Maybe<ElementOfResult>;

/**
 * Returns an array containing the non-null and defined results of calling the given
 * transformation with each element of this sequence.
 *
 * This is a TS implementation of the [Swift method](https://developer.apple.com/documentation/swift/anybidirectionalcollection/compactmap(_:)).
 * @param array - An array to transform. Passing null or undefined will be interpreted as an empty array.
 * @param transform - A callback that accepts an element of this sequence as its argument and returns an optional value.
 * @returns An array of the non-null and defined results of calling transform with each element of the sequence.
 */
export const compactMap = <T, ElementOfResult = NonNullable<T>>(
  array?: Maybe<T[]>,
  transform?: CompactMapTransformFn<T, ElementOfResult>
): ElementOfResult[] => {
  if (!Array.isArray(array)) return [];
  if (!transform)
    return array.filter(
      (element) => typeof element !== 'undefined' && element !== null
    ) as unknown as ElementOfResult[];
  return array.reduce((results, element, index) => {
    if (typeof element === 'undefined' || element === null) return results;
    const result = transform(element, index, array);
    if (typeof result === 'undefined' || result === null) return results;
    results.push(result as ElementOfResult);
    return results;
  }, [] as ElementOfResult[]);
};

/**
 * If you have some input that may be given as an array, a single value (or some mixture of both),
 * this util function will flatten all supplied values into a single array.
 * For example:
 * ```ts
 * const foo = asArray(3); // [3]
 * const bar = asArray([3]); // [3] (same result as above)
 * // or
 * const baz = asArray(1, 2, 3, [4, 5], [6, 7], 8); // [1, 2, 3, 4, 5, 6, 7, 8]
 * ```
 *
 * @param content - A spread of some type and/or arrays of that type.
 * @returns An array of all values supplied to the content param.
 */
export function asArray<T>(...content: (T | T[])[]): T[] {
  return content.flatMap((item) => (Array.isArray(item) ? item : [item]));
}

/**
 * A type predicate that checks if an unknown value is an array and optionally checks each item.
 * Safely type-casts as specified array type if successful.
 *
 * @param input - Any value to check, even if unknown or any
 * @param [tPredicate] - An optional type predicate to evaluate each item in the list.
 * @returns A boolean that also casts the type as the specified array type
 */
export const isArrayOf = <T>(input: unknown, tPredicate?: (t: unknown) => t is T): input is T[] => {
  if (typeof input !== 'object' || input === null || !Array.isArray(input)) return false;
  if (!tPredicate) return true;
  for (const t of input as T[]) {
    if (!tPredicate(t)) return false;
  }
  return true;
};

/**
 * @param input - Any possible array
 * @returns - A boolean type predicate that casts the input as a valid array
 */
export const isNonEmptyArrayOf = <T>(
  input: Maybe<Maybe<T>[]>,
  tPredicate?: (t: unknown) => t is T
): input is Maybe<T>[] => isArrayOf(input, tPredicate) && input.length > 0;

/**
 * @param input - Any possible array
 * @returns - A boolean type predicate that casts the input as a valid array
 */
export const toCompactArrayOf = <T>(input: Maybe<Maybe<T>[]>): T[] =>
  Array.isArray(input)
    ? compactMap(input, (it) => {
        if (!it) return;
        return it as T;
      })
    : [];

/**
 * Truncates the head of an array, starting with a specific element in that array.
 * The specified element is the head of the new array returned and all elements before
 * are discarded. For example:
 * ```ts
 * const original = ['foo', 'bar', 'baz'];
 * const fromBar = reHeadArray(original, 'bar');
 * console.log(fromBar); // ['bar', 'baz']
 * ```
 *
 * @param original - The original input array
 * @param newHeadElement - The element from the initial array you want to be the new head of the array
 * @returns A new array starting with the new head element you requested
 */
export const reHeadArray = <T>(original: T[], newHeadElement?: T): T[] => {
  const newStart = newHeadElement ? original.indexOf(newHeadElement) : -1;
  if (newStart < 0) return [];
  return original.slice(newStart);
};

/**
 * @param collection - Any collection type, array or set
 * @returns That collection as a set
 */
export const asSet = <T>(collection: T[] | Set<T>): Set<T> =>
  Array.isArray(collection) ? new Set<T>(collection) : collection;

interface CloneSetOptions<T> {
  omit?: OneOrMore<T>;
}

/**
 *
 * @param set - Any set to clone... this will not be mutated
 * @param options.omit - Can provide one or more values NOT to copy to the new set
 * @returns A new instance of set that is an identical copy of the original
 */
export const cloneSet = <T>(set: Set<T>, options?: CloneSetOptions<T>): Set<T> => {
  const cloned = new Set<T>([...set]);
  if (options?.omit) {
    asArray(options.omit).forEach((value) => {
      cloned.delete(value);
    });
  }
  return cloned;
};

/**
 * @param ofArray - Any array
 * @param index - Supports either arbitrary index number or positional descriptions "first" or "last"
 * @returns The value at the requested index, if any
 */
export const getValueAtIndex = <T>(ofArray: T[], index: Index = 'first'): Optional<T> => {
  if (!ofArray.length) return undefined;
  switch (index) {
    case 'first':
      return ofArray[0];
    case 'last':
      return ofArray[ofArray.length - 1];
    default:
      return ofArray[index];
  }
};
