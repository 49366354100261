import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import type { ActionComponentConfig, ActionContext, ActionDefFactory } from '@oms/frontend-vgrid';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';

type InvestorOrderActionContext = ActionContext<IOFragment, ActionComponentConfig<IOFragment>>;

export const acceptInvestorOrderAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('accept_order')
    .toolbar((t) =>
      t.component('action-button').id('accept_order_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'Accept'
      })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .customMenu((m) =>
      m
        .name('Accept')
        .visible(({ rowData }) => isVisible(rowData[0]))
        .primary()
    )
    .onChange<ActionComponentConfig<IOFragment>>(acceptInvestorOrderOnChange);

function isVisible(rowData: IOFragment | undefined): boolean {
  return (
    !!rowData &&
    'status' in rowData &&
    rowData.status === InvestorOrderStatus.Unaccepted &&
    rowData.__typename === 'VisibleInvestorOrder'
  );
}

export const acceptInvestorOrderOnChange = async (ctx: InvestorOrderActionContext) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];
  const isUnacceptedIO = isVisible(selectedRow);

  ctx.notify({ isDisabled: !isUnacceptedIO });

  if (lifecycle === 'change') {
    if (!selectedRow || !selectedRow.id) {
      return;
    }

    const orderService = ctx.appContainer.resolve(InvestorOrdersService);

    try {
      ctx.notify({ isLoading: true, rowData: selectedRow });

      const { isSuccess, errors } = await orderService.acceptOrder(selectedRow.id);

      if (isSuccess()) {
        ctx.notify({ isLoading: false, rowData: selectedRow });
      } else {
        const msgs = errors.map((e) => e.message).join(', ');
        await openConfirmation(ctx.workspace, PROCESS_ID.LEADER, {
          title: 'Error accepting order',
          componentProps: {
            message: `Error accepting order: ${msgs}`,
            autoClose: true,
            hideCancelButton: true,
            confirmButtonText: 'Ok'
          }
        });
        throw new Error(msgs);
      }
    } catch (e) {
      ctx.notify({ isLoading: false, rowData: selectedRow });
      console.error(e);
    }
  }
};
