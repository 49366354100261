import { useMemo, useRef } from 'react';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';
import PositionsInstrumentGridService from './positions-instrument-grid.service';

export const usePositionsInstrumentGridService = () => {
  const scopedActorId = useScopedActorId();
  return useMemo(() => new PositionsInstrumentGridService(scopedActorId), [scopedActorId]);
};

export const usePositionsInstrumentGridServiceRef = () => {
  const scopedActorId = useScopedActorId();
  return useRef(new PositionsInstrumentGridService(scopedActorId));
};

export default usePositionsInstrumentGridService;
