import {
  InvestorAccountType,
  InvestorAccountSubType,
  PrimaryBenchmark,
  CoverageModelType,
  TradeCounterpartyTypeEnum,
  AccountDomainListType,
  InstrumentDomainListType
} from '@oms/generated/frontend';
import type {
  InvestorAccountClientSubType,
  InvestorAccountFirmSubType,
  InvestorAccountIntermediarySubType
} from './ref-data.types';

/**
 * Mapping for investor account types
 */
export const investorAccountTypeMapping = {
  [InvestorAccountType.Client]: 'Client',
  [InvestorAccountType.Firm]: 'Firm',
  [InvestorAccountType.Intermediary]: 'Intermediary'
};

/**
 * Mapping for investor account sub types
 */
export const investorAccountSubTypeMapping = {
  [InvestorAccountSubType.Broker]: 'Broker',
  [InvestorAccountSubType.Employee]: 'Employee',
  [InvestorAccountSubType.Error]: 'Error',
  [InvestorAccountSubType.Institutional]: 'Institutional',
  [InvestorAccountSubType.Intermediary]: 'Intermediary',
  [InvestorAccountSubType.Principal]: 'Principal',
  [InvestorAccountSubType.Proprietary]: 'Proprietary',
  [InvestorAccountSubType.Retail]: 'Retail'
};

/**
 * Mapping for investor account sub types, for the "Client" type.
 */
export const investorAccountClientSubTypeMapping: Record<InvestorAccountClientSubType, string> = {
  [InvestorAccountSubType.Broker]: 'Broker',
  [InvestorAccountSubType.Employee]: 'Employee',
  [InvestorAccountSubType.Institutional]: 'Institutional',
  [InvestorAccountSubType.Retail]: 'Retail'
};

/**
 * Mapping for investor account sub types, for the "Firm" type.
 */
export const investorAccountFirmSubTypeMapping: Record<InvestorAccountFirmSubType, string> = {
  [InvestorAccountSubType.Error]: 'Error',
  [InvestorAccountSubType.Principal]: 'Principal',
  [InvestorAccountSubType.Proprietary]: 'Proprietary'
};

/**
 * Mapping for investor account sub types, for the "Intermediary" type.
 */
export const investorAccountIntermediarySubTypeMapping: Record<InvestorAccountIntermediarySubType, string> = {
  [InvestorAccountSubType.Intermediary]: 'Intermediary'
};

export enum YesNo {
  Yes = 'Y',
  No = 'N'
}

export const yesNoMapping = {
  [YesNo.Yes]: 'Yes',
  [YesNo.No]: 'No'
};

/**
 * Mapping for primary benchmark values.
 */
export const primaryBenchmarkMapping = {
  [PrimaryBenchmark.Unspecified]: 'Unspecified',
  [PrimaryBenchmark.DayVwap]: 'Day VWAP',
  [PrimaryBenchmark.OrderVwap]: 'Order VWAP',
  [PrimaryBenchmark.ArrivalPrice]: 'Arrival Price',
  [PrimaryBenchmark.PreviousClose]: 'Previous Close',
  [PrimaryBenchmark.OpenPrice]: 'Open Price',
  [PrimaryBenchmark.Last]: 'Last'
};

/**
 * Mapping for Coverage Model values.
 */
export const coverageModelMapping = {
  [CoverageModelType.AccountCoverage]: 'Account Coverage',
  [CoverageModelType.RestrictedCoverage]: 'Restricted Coverage',
  [CoverageModelType.UserCoverage]: 'User Coverage'
};

/**
 * Mapping for Trade Counterparty Types.
 */
export const tradeCounterpartyTypeMapping = {
  [TradeCounterpartyTypeEnum.Affiliate]: 'Affiliate',
  [TradeCounterpartyTypeEnum.Broker]: 'Broker',
  [TradeCounterpartyTypeEnum.Exchange]: 'Exchange',
  [TradeCounterpartyTypeEnum.FloorBroker]: 'Floor Broker'
};

/**
 * Mapping for Exchange Types.
 */
export const exchangeTypeMapping: Record<string, string> = {
  ATS: 'ATS',
  ATSD: 'ATSD',
  BRO: 'BRO',
  COMP: 'COMP',
  CONT: 'CONT',
  EXCH: 'EXCH',
  MIX: 'MIX',
  OTC: 'OTC',
  SI: 'SI',
  UNLISTED: 'Unlisted'
};

/**
 * Mapping for MIC Levels.
 */
export const micLevelMapping: Record<string, string> = {
  MIC_OP_EXCHANGE_CODE: 'Operating',
  MIC_NONOP_EXCHANGE_CODE: 'Non-operating'
};

/**
 * Mapping for Account List Types
 */
export const accountListTypeMapping = {
  [AccountDomainListType.Charge]: 'Charge',
  [AccountDomainListType.Compliance]: 'Compliance',
  [AccountDomainListType.Other]: 'Other',
  [AccountDomainListType.RoutingRule]: 'Routing Rule'
};

/**
 * Mapping for Instrument List Types
 */
export const instrumentListTypeMapping = {
  [InstrumentDomainListType.Charge]: 'Charge',
  [InstrumentDomainListType.Compliance]: 'Compliance',
  [InstrumentDomainListType.InstrumentCoverage]: 'Instrument Coverage',
  [InstrumentDomainListType.Other]: 'Other',
  [InstrumentDomainListType.RoutingRule]: 'Routing Rule'
};

/**
 * Mapping for Sector codes.
 * This is a placeholder until we have full support of Sector reference data.
 */
export const businessSectorMapping = {
  '1100': 'Non-Energy Minerals',
  '1200': 'Producer Manufacturing',
  '1300': 'Electronic Technology',
  '1400': 'Consumer Durables',
  '2100': 'Energy Minerals',
  '2200': 'Process Industries',
  '2300': 'Health Technology',
  '2400': 'Consumer Non-Durables',
  '3100': 'Industrial Services',
  '3200': 'Commercial Services',
  '3250': 'Distribution Services',
  '3300': 'Technology Services',
  '3350': 'Health Services',
  '3400': 'Consumer Services',
  '3500': 'Retail Trade',
  '4600': 'Transportation',
  '4700': 'Utilities',
  '4800': 'Finance',
  '4900': 'Communications',
  '6000': 'Miscellaneous',
  '7000': 'Government',
  '9999': 'Not Classified'
};

/**
 * Mapping for Industry codes.
 * This is a placeholder until we have full support of Industry reference data.
 */
export const industryMapping = {
  '1105': 'Steel',
  '1115': 'Aluminum',
  '1120': 'Precious Metals',
  '1125': 'Other Metals/Minerals',
  '1130': 'Forest Products',
  '1135': 'Construction Materials',
  '1205': 'Metal Fabrication',
  '1210': 'Industrial Machinery',
  '1220': 'Trucks/Construction/Farm Machinery',
  '1225': 'Auto Parts: OEM',
  '1230': 'Building Products',
  '1235': 'Electrical Products',
  '1245': 'Office Equipment/Supplies',
  '1250': 'Miscellaneous Manufacturing',
  '1255': 'Industrial Conglomerates',
  '1305': 'Semiconductors',
  '1310': 'Electronic Components',
  '1315': 'Electronic Equipment/Instruments',
  '1320': 'Telecommunications Equipment',
  '1330': 'Aerospace & Defense',
  '1340': 'Computer Processing Hardware',
  '1345': 'Computer Peripherals',
  '1352': 'Computer Communications',
  '1355': 'Electronic Production Equipment',
  '1405': 'Motor Vehicles',
  '1410': 'Automotive Aftermarket',
  '1415': 'Homebuilding',
  '1420': 'Home Furnishings',
  '1425': 'Electronics/Appliances',
  '1430': 'Tools & Hardware',
  '1435': 'Recreational Products',
  '1445': 'Other Consumer Specialties',
  '2105': 'Oil & Gas Production',
  '2110': 'Integrated Oil',
  '2120': 'Oil Refining/Marketing',
  '2125': 'Coal',
  '2205': 'Chemicals: Major Diversified',
  '2210': 'Chemicals: Specialty',
  '2215': 'Chemicals: Agricultural',
  '2220': 'Textiles',
  '2225': 'Agricultural Commodities/Milling',
  '2230': 'Pulp & Paper',
  '2235': 'Containers/Packaging',
  '2240': 'Industrial Specialties',
  '2305': 'Pharmaceuticals: Major',
  '2310': 'Pharmaceuticals: Other',
  '2315': 'Pharmaceuticals: Generic',
  '2320': 'Biotechnology',
  '2325': 'Medical Specialties',
  '2405': 'Food: Major Diversified',
  '2410': 'Food: Specialty/Candy',
  '2415': 'Food: Meat/Fish/Dairy',
  '2420': 'Beverages: Non-Alcoholic',
  '2425': 'Beverages: Alcoholic',
  '2430': 'Tobacco',
  '2435': 'Household/Personal Care',
  '2440': 'Apparel/Footwear',
  '2450': 'Consumer Sundries',
  '3105': 'Contract Drilling',
  '3110': 'Oilfield Services/Equipment',
  '3115': 'Engineering & Construction',
  '3120': 'Environmental Services',
  '3130': 'Oil & Gas Pipelines',
  '3205': 'Miscellaneous Commercial Services',
  '3210': 'Advertising/Marketing Services',
  '3215': 'Commercial Printing/Forms',
  '3220': 'Financial Publishing/Services',
  '3235': 'Personnel Services',
  '3255': 'Wholesale Distributors',
  '3260': 'Food Distributors',
  '3265': 'Electronics Distributors',
  '3270': 'Medical Distributors',
  '3305': 'Data Processing Services',
  '3308': 'Information Technology Services',
  '3310': 'Packaged Software',
  '3320': 'Internet Software/Services',
  '3355': 'Managed Health Care',
  '3360': 'Hospital/Nursing Management',
  '3365': 'Medical/Nursing Services',
  '3370': 'Services to the Health Industry',
  '3405': 'Media Conglomerates',
  '3410': 'Broadcasting',
  '3415': 'Cable/Satellite TV',
  '3420': 'Publishing: Newspapers',
  '3425': 'Publishing: Books/Magazines',
  '3430': 'Movies/Entertainment',
  '3435': 'Restaurants',
  '3440': 'Hotels/Resorts/Cruise lines',
  '3445': 'Casinos/Gaming',
  '3450': 'Other Consumer Services',
  '3505': 'Food Retail',
  '3510': 'Drugstore Chains',
  '3515': 'Department Stores',
  '3520': 'Discount Stores',
  '3525': 'Apparel/Footwear Retail',
  '3530': 'Home Improvement Chains',
  '3535': 'Electronics/Appliance Stores',
  '3540': 'Specialty Stores',
  '3545': 'Catalog/Specialty Distribution',
  '3550': 'Internet Retail',
  '4605': 'Air Freight/Couriers',
  '4610': 'Airlines',
  '4615': 'Trucking',
  '4620': 'Railroads',
  '4625': 'Marine Shipping',
  '4630': 'Other Transportation',
  '4705': 'Electric Utilities',
  '4735': 'Gas Distributors',
  '4755': 'Water Utilities',
  '4760': 'Alternative Power Generation',
  '4805': 'Major Banks',
  '4810': 'Regional Banks',
  '4825': 'Savings Banks',
  '4830': 'Finance/Rental/Leasing',
  '4840': 'Investment Banks/Brokers',
  '4845': 'Investment Managers',
  '4850': 'Financial Conglomerates',
  '4855': 'Property/Casualty Insurance',
  '4860': 'Multi-Line Insurance',
  '4865': 'Life/Health Insurance',
  '4875': 'Specialty Insurance',
  '4880': 'Insurance Brokers/Services',
  '4885': 'Real Estate Development',
  '4890': 'Real Estate Investment Trusts',
  '4905': 'Major Telecommunications',
  '4910': 'Specialty Telecommunications',
  '4915': 'Wireless Telecommunications',
  '6005': 'Miscellaneous',
  '6010': 'Investment Trusts/Mutual Funds',
  '7005': 'Sovereign',
  '7010': 'Province/State',
  '7015': 'Municipality',
  '7020': 'National Agency',
  '7025': 'State Agency',
  '7028': 'Local Agencies',
  '7030': 'Supranational',
  '7035': 'General Government',
  '9999': 'Not Classified'
};
