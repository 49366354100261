import { COLLECTION_NAME } from '../collections/collections.contracts';
import {
  APP_SUB_SCHEMA,
  type AppSubSchemaRegistery,
  type AppSubSchemaType,
  type SubSchemaItem
} from './sub-schema.registry.contracts';

const appPath = `apps/oms-app/src/app`;

export const SUB_SCHEMA_REGISTRY: AppSubSchemaRegistery = {
  [APP_SUB_SCHEMA.BUMP_PRICE]: {
    collection: COLLECTION_NAME.ACTIONS,
    subSchemaFolderPath: `${appPath}/widgets/trading/montage/commands/bump-price/schema`,
    type: 'action',
    versionedSchemas: []
  },
  [APP_SUB_SCHEMA.CREATE_UNBOUND_TO]: {
    collection: COLLECTION_NAME.ACTIONS,
    dependencies: [APP_SUB_SCHEMA.ROUTE_ORDER_FORM_VALUES],
    subSchemaFolderPath: `${appPath}/widgets/trading/route-order/commands/create-unbound-trading-order/schema`,
    type: 'action',
    versionedSchemas: []
  },
  [APP_SUB_SCHEMA.SNAPSHOT]: {
    collection: COLLECTION_NAME.SNAPSHOTS,
    subSchemaFolderPath: `${appPath}/app-config/schemas/snapshots`,
    type: 'other',
    versionedSchemas: []
  },
  [APP_SUB_SCHEMA.FLEX_LAYOUT]: {
    subSchemaFolderPath: `${appPath}/app-config/schemas/flex-layout`,
    type: 'other',
    versionedSchemas: []
  },
  // Snapshot-based sub-schemas
  [APP_SUB_SCHEMA.ROUTE_ORDER_FORM_INPUT]: {
    collection: COLLECTION_NAME.SNAPSHOTS,
    type: 'snapshot-form-input',
    subSchemaFolderPath: `${appPath}/widgets/trading/route-order/schemas/form-input`,
    versionedSchemas: []
  },
  [APP_SUB_SCHEMA.ROUTE_ORDER_FORM_VALUES]: {
    collection: COLLECTION_NAME.SNAPSHOTS,
    subSchemaFolderPath: `${appPath}/widgets/trading/route-order/schemas/form-values`,
    type: 'snapshot-form-values',
    versionedSchemas: []
  },
  [APP_SUB_SCHEMA.MONTAGE]: {
    collection: COLLECTION_NAME.SNAPSHOTS,
    subSchemaFolderPath: `${appPath}/widgets/trading/montage/schemas/layout`,
    type: 'snapshot-component',
    versionedSchemas: []
  },
  [APP_SUB_SCHEMA.SYSTEM_REMOTE_FORM]: {
    collection: COLLECTION_NAME.SNAPSHOTS,
    subSchemaFolderPath: `${appPath}/widgets/system/remote-form/schema`,
    type: 'snapshot-component',
    versionedSchemas: []
  }
};

export const createAppSubSchemaRegistry = (
  appRegistry: Record<AppSubSchemaType, { versionedSchemas: SubSchemaItem['versionedSchemas'] }>
): AppSubSchemaRegistery => {
  // Merges the appRegistry with the SUB_SCHEMA_REGISTRY
  const mergedRegistry = Object.entries(appRegistry).reduce((acc, [key, value]) => {
    const subSchemaItem = SUB_SCHEMA_REGISTRY[key as AppSubSchemaType];
    if (!subSchemaItem) {
      return acc;
    }
    return {
      ...acc,
      [key]: {
        ...subSchemaItem,
        ...value
      }
    };
  }, {} as AppSubSchemaRegistery);
  return mergedRegistry;
};
