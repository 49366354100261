import { useVGrid, VGrid, CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { inlineTradingOrderActions } from '../trading-order-monitor/grid-actions/inline.trading-order.actions';
import { createOpenEntryTradingOrderAction } from '../trading-order-monitor/grid-actions/open-entry.trading-order.action';
import { cancelTradingOrderAction } from '../trading-order-monitor/grid-actions/cancel.trading-order.action';
import { modifyTradingOrderAction } from '../trading-order-monitor/grid-actions/modify.trading-order.action';
import { viewTradingOrderAction } from '../trading-order-monitor/grid-actions/view.trading-order.action';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { investorOrderTradingOrderMonitorColumnLibrary } from '../trading-order-monitor/trading-order-monitor.columns';
import { InvestorOrderTradingOrdersService } from '@app/data-access/services/trading/investor-orders/investor-order-trading-orders.service';
import type { InvestorOrderTradingOrderRow } from '@app/data-access/services/trading/investor-orders/investor-order-trading-orders.service';
import { memo } from 'react';
import { createInstrumentFollowEventHandler } from '@app/data-access/services/system/instrument-follow/instrument-follow.grid.event-handler';
import { TOHasExecutedQuantity, TONoExecutedQuantity, TOPending } from '../utils/row-state-rule-utils';

export type TradingOrderDetailGridProps = {
  investorOrderId?: string;
};

export const TradingOrdersDetailGrid = memo(
  ({ investorOrderId }: TradingOrderDetailGridProps) => {
    const datasourceService = useService(InvestorOrderTradingOrdersService);
    const gridProps = useVGrid<InvestorOrderTradingOrderRow>(
      `investor-order-${investorOrderId || ''}-trading-orders`,
      (builder) =>
        builder
          .columnLibrary(investorOrderTradingOrderMonitorColumnLibrary)
          .rowSelection((c) => c.multiple())
          // TODO This is should be a subscription based on createServerSideDatasource instead of polling
          .datasource((d) => d.source(datasourceService.watchAll$(investorOrderId)).rowId((r) => r.data?.id))
          .toolbarStrategies('hidden')
          .injectEvents([
            createInstrumentFollowEventHandler<InvestorOrderTradingOrderRow>({
              sourceType: 'TRADING_ORDER_MONITOR_GRID',
              getId: (data) => data.instrument?.id,
              colId: 'instrument.mappings.displayCode',
              getDisplayCode: (data) => data.instrument?.mappings?.displayCode
            })
          ])
          .rowStateRules({
            pending: (params) => TOPending(params.data),
            noExecutedQuantity: (params) => TONoExecutedQuantity(params.data),
            hasExecutedQuantity: (params) => TOHasExecutedQuantity(params.data)
          })
          .actions((a) =>
            a
              .schema((s) =>
                s
                  .action(createOpenEntryTradingOrderAction<InvestorOrderTradingOrderRow>())
                  .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                  .action(viewTradingOrderAction)
                  .action(modifyTradingOrderAction)
                  .action(cancelTradingOrderAction)
                  .action(inlineTradingOrderActions)
                  .action(refreshServersideCustomMenuAction)
              )
              .settings((s) => s.fromFile({}))
          ),
      [datasourceService]
    );

    return <VGrid {...gridProps} />;
  },
  (prev, next) => prev.investorOrderId === next.investorOrderId
);

export default TradingOrdersDetailGrid;
