import { BroadcastChannel, BroadcastChannelOptions, EventContext, OnMessageHandler } from 'broadcast-channel';
import { isBroadcastChannelSimulated } from './options';

type EventListener<T> = (event: T) => void;

/**
 * Global channel that emits & receives events locally AND remotely via Broadcast Channel.
 * @param T - The type of the message
 */
export class GlobalChannel<T> extends BroadcastChannel<T> {
  private listeners: Map<EventContext, Set<EventListener<T>>> = new Map();
  private _onmessageExtended: OnMessageHandler<T> | null = null;
  private _simulatedFlag: boolean | null = null;

  constructor(name: string, opts?: BroadcastChannelOptions) {
    super(name, opts);
  }

  override postMessage(msg: T): Promise<void> {
    // If we're simulating a broadcast channel, don't actually send the message. Just notify listeners locally.
    if (isBroadcastChannelSimulated()) {
      this._simulatedFlag = true;
      return new Promise((resolve) => {
        this.notifyListeners('message', msg);
        resolve();
      });
    }

    return new Promise((resolve, reject) => {
      super
        .postMessage(msg)
        .then(() => {
          this.notifyListeners('message', msg);
          resolve();
        })
        .catch(reject);
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  override get onmessage(): OnMessageHandler<T> {
    return this._onmessageExtended;
  }

  override set onmessage(handler: OnMessageHandler<T>) {
    // If there's an existing handler, remove it first
    if (this._onmessageExtended) {
      this.removeEventListener('message', this._onmessageExtended);
    }

    this._onmessageExtended = handler;

    // If the new handler is not null, add it to listeners
    if (handler) {
      this.addEventListener('message', handler);
    }
  }

  override addEventListener(type: EventContext, handler: OnMessageHandler<T>): void {
    if (!this.listeners.has(type)) {
      this.listeners.set(type, new Set());
    }

    this.listeners.get(type)?.add(handler as EventListener<T>);
    super.addEventListener(type, handler);
  }

  override removeEventListener(type: EventContext, handler: OnMessageHandler<T>): void {
    this.listeners.get(type)?.delete(handler as EventListener<T>);
    super.removeEventListener(type, handler);
  }

  override close(): Promise<void> {
    this.listeners.clear();
    return super.close();
  }

  private notifyListeners(type: EventContext, message: T) {
    this.listeners.get(type)?.forEach((listener) => listener(message));
  }
}

/**
 * Creates a global channel that emits & receives events locally AND remotely via Broadcast Channel.
 *
 * @param id - The channel ID
 * @returns A global channel
 */
export function createGlobalChannel<T>(id: string) {
  return new GlobalChannel<T>(id);
}
