import { enableQuoteFormContract } from './enable-quote.form-contract';
import type {
  EnableQuoteFormInput,
  EnableQuoteFormContractType,
  EnableQuoteFormValues,
  EnableQuoteFormOutput
} from './enable-quote.form-contract';
import type { DependencyContainer } from 'tsyringe';
import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';
import type { EnhancedFormOptions } from '@oms/frontend-foundation';
import { MontageService } from '@app/data-access/services/trading/montage/montage.service';
import { yesNoComboBoxMapper } from './enable-quote.form.util';
import { MarketDataService } from '@app/data-access/services/marketdata/marketdata.service';

async function handleInstrumentChange(
  values: EnableQuoteFormValues,
  formApi: EnhancedFormOptions<EnableQuoteFormValues>,
  container: DependencyContainer
) {
  const marketDataService = container.resolve(MarketDataService);
  const instrumentId = values?.instrument?.id;

  if (!instrumentId) {
    formApi.change('mmStatus', undefined);
    return;
  }

  const instrument = await marketDataService.getInstrumentById(instrumentId);

  if (instrument?.firmMarketMaker !== undefined) {
    formApi.change('mmStatus', yesNoComboBoxMapper(instrument?.firmMarketMaker));
  } else {
    formApi.change('mmStatus', undefined);
  }
}

export const enableQuoteFormBuilder = FormBuilder.create<EnableQuoteFormInput, EnableQuoteFormOutput>(
  'enable-quote'
)
  .contract<EnableQuoteFormContractType>(enableQuoteFormContract)
  .type('enableQuote')
  .sanitizer((s) =>
    s
      .input(async function sanitize(input, { container }) {
        if (!input.instrument) return;

        const montageService = container.resolve(MontageService);
        const [instrument] = input.instrument
          ? await montageService.lookupInstrumentBySymbol(input.instrument, false)
          : [];

        const formValues: EnableQuoteFormValues = {
          instrument: instrument
            ? {
                id: instrument.id
              }
            : undefined,
          mmStatus:
            instrument?.firmMarketMaker !== undefined
              ? yesNoComboBoxMapper(instrument.firmMarketMaker)
              : undefined
        };
        return formValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.instrument?.id || !formValues.mmStatus) {
          return;
        }

        const output: EnableQuoteFormOutput = {
          instrument: formValues.instrument?.id,
          mmStatus: formValues.mmStatus.value
        };

        return output;
      })
  )
  .effect(({ formApi, container }) => {
    const subscription = formApi.get$({ values: true, fields: ['instrument'] }).subscribe(({ values }) => {
      handleInstrumentChange(values, formApi, container).catch(console.error);
    });
    return () => {
      subscription.unsubscribe();
    };
  })
  .change(async (event, ctx) => {
    const montageService = ctx.container.resolve(MontageService);

    switch (event.type) {
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        const output = event.payload.output;

        if (!output) {
          return;
        }
        return await montageService.toggleMarketMakerStatus({
          instrumentId: output.instrument,
          mmStatus: output.mmStatus,
          dryRun: true
        });
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        const output = event.payload.output;
        if (!output) {
          return;
        }
        return await montageService.toggleMarketMakerStatus({
          instrumentId: output.instrument,
          mmStatus: output.mmStatus,
          dryRun: false
        });
      }
    }
  });

export type EnableQuoteFormBuilderType = typeof enableQuoteFormBuilder;

export default enableQuoteFormBuilder;
