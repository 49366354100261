import { PropsWithChildren, useCallback } from 'react';
import { CommonWindowActorSchema, Actor } from '@valstro/workspace';
import { ReactActorComponentProps, useActorSchemaMeta } from '../../../core';

export function useGetWindowWrapperComponent(
  actor: Actor<
    CommonWindowActorSchema<
      React.ComponentType<PropsWithChildren<ReactActorComponentProps<CommonWindowActorSchema>>>
    >
  >
) {
  const meta = useActorSchemaMeta(actor) || {};
  const { wrapperComponent: WindowWrapperComponent } = meta;
  return useCallback(
    ({ children }: PropsWithChildren<{}>) => {
      if (!WindowWrapperComponent) {
        return <>{children}</>;
      }
      return <WindowWrapperComponent actor={actor}>{children}</WindowWrapperComponent>;
    },
    [WindowWrapperComponent, actor]
  );
}
