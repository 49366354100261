import { AnyRecord } from '@oms/frontend-foundation';
import { RowDragStrategy } from './drag.change.event.strategies';
import { ActionChangeEventStrategy, ActionLifecycle } from '../../../models/actions.model';

export class ActionChangeEventFactory {
  // TODO: Breaks ESLint server
  private static _actionStrats = new Map<ActionLifecycle, ActionChangeEventStrategy<any>>([
    ['onRowDragEnd', new RowDragStrategy()]
  ]);

  public static create<TData extends AnyRecord>(type: ActionLifecycle): ActionChangeEventStrategy<TData> {
    return (this._actionStrats.get(type) || {
      execute(actions) {
        return actions;
      }
    }) as ActionChangeEventStrategy<TData>;
  }
}
