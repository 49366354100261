import { countBy, entries, flow } from 'lodash';
import { Optional } from '@oms/shared/util-types';

export function mostCommonArrayValues(list: any[], take: number) {
  const orderedArray = flow(countBy, entries, (x) => x.sort((a, b) => b[1] - a[1]))(list);
  return orderedArray.slice(0, take).map((x) => x[0]);
}

/**
 * Gets the value from an array at the specified index. However,
 * if the index exceeds the array bounds, rather than returning `undefined`
 * this returns the value at the wrapped index. For example:
 * ```ts
 * const arr = ['foo', 'bar', 'baz'];
 * const value01 = getWrappedIndex(arr, 1); // 'bar'
 * const value02 = getWrappedIndex(arr, 3); // 'foo' (index 3 does't exist so wraps to index 0)
 * ```
 * > Note that a value is still optional as the array may be empty.
 *
 * @param array - The array to index
 * @param index - The requested index, defaulting to 0 and wrapping if array bounds are exceeded
 * @returns The value at the wrapped index.
 */
export const getWrappedIndex = <T>(array: T[], index: number = 0): Optional<T> => {
  if (array.length < 1) return;
  const wrappedIndex = index % array.length;
  const absoluteWrappedIndex = wrappedIndex < 0 ? array.length + wrappedIndex : wrappedIndex;
  return array[absoluteWrappedIndex];
};

/**
 * Wraps the given value in a one-element array
 *
 * @param value - Any value
 * @returns An array containing the single value provided.
 */
export const wrapInArray = <T>(value: T): T[] => [value];

/**
 * Wrap each element of an array in an array. AKA: Turns an array into an array of arrays.
 *
 * @param list - Any list of elements
 * @returns A new array with each element wrapped in its own array.
 */
export const wrapEach = <T>(list: T[]): T[][] => list.map(wrapInArray);
