import { Observable, filter } from 'rxjs';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import type { WindowReadyContext, CoreEvents, IEventType, IExtractEventPayload } from '@valstro/workspace';

export function createWorkspaceWindowReady$(workspace: AppWorkspace) {
  return new Observable<WindowReadyContext>((subscriber) => {
    const unsubscribe = workspace.addHook('windowReady', (ctx) => {
      subscriber.next(ctx);
    });
    return () => {
      unsubscribe();
    };
  });
}

export function createWorkspaceLeaderWindowReady$(workspace: AppWorkspace) {
  return createWorkspaceWindowReady$(workspace).pipe(filter(({ isLeader }) => isLeader));
}

export function getWorkspaceEvent$<K extends IEventType<CoreEvents>>(workspace: AppWorkspace, event: K) {
  return new Observable<IExtractEventPayload<CoreEvents, K>>((subscriber) => {
    const unsub = workspace.listen(event, (p) => {
      subscriber.next(p);
    });
    return () => {
      unsub();
    };
  });
}

export function getWindowDestroyedEvent$({ workspace, id }: { workspace: AppWorkspace; id?: string }) {
  return getWorkspaceEvent$(workspace, 'actor/destroyed').pipe(
    filter((e) => {
      if (!id) return true;

      return e.id === id;
    })
  );
}
