import { FieldContract } from '@oms/frontend-foundation';
import type { MontageFilterFormInput } from './montage-filters.form-common';
import { createAccountField } from '@app/forms/common/fields/account-field/account.field.common';
import { createFollowSymbolField } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import { makeInstrumentCommonProps } from '@app/forms/common/fields/shared-field-props';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import { createCoverageUserField } from '@app/forms/form-builder/fields/coverage-user-field/coverage-user-field.common';

export const montageFiltersFieldContract = FieldContract.create<MontageFilterFormInput>();

export const instrument = createFollowSymbolField(montageFiltersFieldContract, 'instrument', {
  ...makeInstrumentCommonProps({
    queryType: 'simple',
    label: { suppressLabel: true }
  }),
  hideFormControls: true,
  toggleStateSource: 'montage'
});

export const layout = montageFiltersFieldContract.field('layout', 'hidden-field');

export const investorAccount = createAccountField(montageFiltersFieldContract, 'investorAccount', {
  label: undefined,
  hideFormControls: true,
  query: ADVANCED_SELECT_QUERY_ENUM.VISIBLE_FIRM_ACCOUNTS_WATCH_QUERY,
  condition: {
    or: [
      {
        when: 'layout',
        is: 'expanded'
      },
      {
        when: 'layout',
        is: 'standard'
      }
    ]
  }
});

export const coverageUser = createCoverageUserField('coverage-user', {
  accountFieldName: 'investorAccount',
  layoutFieldName: 'layout'
});

export default montageFiltersFieldContract;
