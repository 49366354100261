import { ICellRendererParams } from '@ag-grid-community/core';
import { Flex } from '@oms/shared-frontend/ui-design-system';
import { isJsonRuleGroup, jsonRuleGroupIterator } from '@oms/frontend-foundation';
import { TopLevelCondition } from 'json-rules-engine';
import { FC, useMemo } from 'react';

const countRules = (rules: TopLevelCondition): number => {
  let count = 0;

  jsonRuleGroupIterator(rules).forEach((r) => {
    if (isJsonRuleGroup(r)) {
      count += countRules(r as TopLevelCondition);
    } else {
      count++;
    }
  });

  return count;
};

export const RULE_COUNT = 'rule_count';

export const RuleCountCell: FC<ICellRendererParams<TopLevelCondition, TopLevelCondition>> = (props) => {
  const { value } = props;

  const ruleCount = useMemo(() => {
    return value ? countRules(value) : 0;
  }, [value]);

  const ruleText = useMemo(() => {
    return ruleCount === 1 ? 'rule' : 'rules';
  }, [ruleCount]);

  return (
    <Flex
      direction={'column'}
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: 'full',
        height: 'full',
        color: 'text.active',
        cursor: 'pointer'
      }}
    >
      {ruleCount} {ruleText}
    </Flex>
  );
};
