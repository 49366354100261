import { Unsubscribe } from '@valstro/remote-link';
import type { AnyRecord } from './utils';
import { Workspace } from './workspace';
import { Actor } from './actor';
import type {
  ActorHookCallback,
  ActorHookMap,
  ChildInternalActor,
  CreateActorOptions,
  InternalActor,
  SpawnedActorResult
} from './actor.internal';
import type { ActorDefinition, AnyActorOperations, CreateActorDefinition } from './actor.internal';
import type { PlatformRegistry } from './platform';
import type { PluginRegistry } from './plugins';
import type { ActorRegistry } from './actor.registry';

export type ActorSchemaSelectStrategyContext = {
  location: 'root' | 'child';
  platformName: string;
  isLeader: boolean;
  createActorOptionsOrDef?: CreateActorDefinition | CreateActorOptions;
  rootWindowActor?: Actor<any> | null; // TODO: Fix any
  source?: CreateActorDefinition;
};

export type ActorSchemaSelectStrategy = (
  context: ActorSchemaSelectStrategyContext
) => boolean | Promise<boolean>;

export type ActorSchemaInitialContextContext<TContext extends AnyRecord, TSchema extends AnyActorSchema> = {
  initialContext: Partial<TContext>;
  schema: TSchema;
  workspace: Workspace;
};

export type ActorSchemaInitialContext<TContext extends AnyRecord, TSchema extends AnyActorSchema> = (
  context: ActorSchemaInitialContextContext<TContext, TSchema>
) => TContext | Promise<TContext>;

export type ActorSchemaSyncContext<TContext extends AnyRecord, TOperations extends AnyActorOperations> = {
  isFocus: boolean;
  contextDelta: Partial<TContext>;
  operations: TOperations;
  workspace: Workspace;
};

export type ActorSchemaSync<TContext extends AnyRecord, TOperations extends AnyActorOperations> = (
  context: ActorSchemaSyncContext<TContext, TOperations>
) => void | Promise<void> | TContext | Promise<TContext>;

export type ActorSchemaRender<TContext extends AnyRecord = AnyRecord> = (
  url: string,
  options: CreateActorDefinition<TContext>,
  workspace: Workspace
) => void | Promise<void>;

export type ActorSchemaDestroy = (
  actorDefinition: ActorDefinition,
  workspace: Workspace
) => void | Promise<void>;

export type ActorOperationOptions = {
  /**
   * If the operation is triggered by user interaction
   */
  isUserInteraction?: boolean;
};

export type ActorSchemaAPI<TContext extends AnyRecord, TOperations extends AnyActorOperations> = {
  workspace: Workspace;
  spawnChild: (createOptions: CreateActorOptions<AnyRecord>) => Promise<SpawnedActorResult<AnyActorSchema>>;
  destroyChild: (id: string) => Promise<void>;
  getDefinition: () => ActorDefinition<TContext>;
  updateContext: (context: Partial<TContext>, options?: ActorOperationOptions) => Promise<void>;
  setContext: (context: TContext, options?: ActorOperationOptions) => Promise<void>;
  getContext: () => TContext;
  operations: TOperations;
  destroy: InternalActor['destroy'];
  getInternalChildren: () => ChildInternalActor[];
};

export type ActorSchemaOperations<TContext extends AnyRecord, TOperations extends AnyActorOperations> = (
  context: ActorSchemaAPI<TContext, TOperations>,
  workspace: Workspace
) => TOperations;

export type ActorSchemaEvents<TContext extends AnyRecord, TOperations extends AnyActorOperations> =
  | ((context: ActorSchemaAPI<TContext, TOperations>, workspace: Workspace) => Unsubscribe)
  | ((context: ActorSchemaAPI<TContext, TOperations>, workspace: Workspace) => Promise<Unsubscribe>);

export type ActorHooksContext<TContext extends AnyRecord, TOperations extends AnyActorOperations> = {
  before: <K extends keyof ActorHookMap | keyof TOperations>(
    key: K,
    callback: ActorHookCallback<'before', TOperations, TContext, K>
  ) => Unsubscribe;
  after: <K extends keyof ActorHookMap | keyof TOperations>(
    key: K,
    callback: ActorHookCallback<'after', TOperations, TContext, K>
  ) => Unsubscribe;
};

export type ActorSchemaHooksContext<TContext extends AnyRecord, TOperations extends AnyActorOperations> = {
  hooks: ActorHooksContext<TContext, TOperations>;
} & ActorSchemaAPI<TContext, TOperations>;

export type ActorSchemaHooks<TContext extends AnyRecord, TOperations extends AnyActorOperations> = (
  context: ActorSchemaHooksContext<TContext, TOperations>
) => Unsubscribe;

export type ActorSchemaShouldImmediatelySpawn<
  TContext extends AnyRecord,
  TOperations extends AnyActorOperations
> = (context: ActorSchemaHooksContext<TContext, TOperations>) => void;

export type OnRegistrationFinishedContext = {
  platformName: string;
  workspace: Workspace;
  actorRegistry: ActorRegistry;
  platformRegistry: PlatformRegistry;
  pluginRegistry: PluginRegistry;
};

export type ActorSchema<
  TContext extends AnyRecord = AnyRecord,
  TOperations extends AnyActorOperations = AnyActorOperations,
  TViewType = any,
  TMeta extends AnyRecord = AnyRecord
> = {
  /**
   * Unique name of the actor
   */
  name: string;
  /**
   * Type of the actor, e.g. `window`, `widget`, `tab`, etc.
   * This can be used as a "group" name for actors based on platform.
   *
   * For example, all actors of type `window` can be grouped together and
   * the correct actor can be selected based on the platform.
   */
  type: string;
  /**
   * Supported platforms for the actor
   * This can be a string or an array of strings
   * If the value is `*`, then the actor is supported on all platforms
   * This will be used to select the correct actor based on the platform by the default "setWindowRootStrategy" strategy
   */
  supportedPlatforms: string | string[];
  /**
   * Whether the actor can be used as a root actor for a window
   * e.g. `window` actors
   */
  isWindowRootable: boolean;
  /**
   * Strategy to use to select the correct actor based on different factors.
   * Return `true` to select the actor (or prioritize the actor in the search/sort), `false` to ignore the actor/exclude it from the search.
   *
   * Different factors to consider when "selecting" an actor:
   * - location: root | child (there are two locations where an actor can be spawned / selected from. In the workspace root or as a child of another actor)
   * - platformName: string
   * - isLeader: boolean
   * - initialURLCreateActorDefinition: CreateActorDefinition | null
   * - rootWindowActor: Actor | null
   * - source: CreateActorDefinition
   */
  selectStrategy?: ActorSchemaSelectStrategy;
  /**
   * Initial context for the actor
   * This is run before the actor is started to initialize the actor with full context
   */
  initialContext: ActorSchemaInitialContext<TContext, ActorSchema<TContext, TOperations, any, TMeta>>;
  /**
   * Render logic for the actor
   * Usually for rendering a "rootable" actor which represents a window,
   * Therefore this logic often contains instructions to open a new window with the enriched URL
   * It's run when an actor is spawned which also occurs in applySnapshot.
   */
  render?: ActorSchemaRender<TContext>;
  /**
   * Sync view to be inline with context
   * This is run when applySnapshot is called
   */
  sync?: ActorSchemaSync<TContext, TOperations>;
  /**
   * Sync actor on start (after it's first rendered)
   * This is useful if you can't set properties when first rendering/opening an actor, but can when it's already rendered.
   * For example, opening a tab with window.open and setting the "title"
   */
  syncOnStart?: boolean;
  /**
   * Destroy logic for the actor
   * Usually for destroying a "rootable" actor which represents a window,
   * Therefore this logic often contains instructions to close the current window
   * This is run when an actor is destroyed
   */
  destroy?: ActorSchemaDestroy;
  /**
   * Confirm destroy runs in the process that's actually destroying the actor
   * This is useful because it resolves when the actor has ACTUALLY been destroyed
   */
  confirmDestroy?: (destroyFn: Promise<void>, ctx: TContext) => Promise<void>;
  /**
   * Custom set of operations for the actor
   * Operations are used to interact with the actor
   * e.g. `setTitle`, `move`, `updateProps`, etc.
   * They are the application consumable "API" of the actor
   * The best practice is to use the `updateContext` method or `setContext` to update the actor context after an operation is run
   */
  operations: ActorSchemaOperations<TContext, TOperations>;
  /**
   * Subscribe to user-based events for the actor
   * e.g. `onResize`, `onMove`, etc.
   * Used to keep the actor context up-to-date with the user's actions
   * Returns an unsubscribe function
   */
  events?: ActorSchemaEvents<TContext, TOperations>;
  /**
   * Hooks for the actor
   * Run custom logic before or after an operation is run
   * works for both custom operations and default operations
   */
  hooks?: ActorSchemaHooks<TContext, TOperations>;
  /**
   * View for the actor
   * e.g. `React.ComponentType`, etc.
   * Used to store the view layer of the actor in the UI
   * Often left undefined and set later by the UI specific libraries
   */
  view?: TViewType;
  /**
   * Meta data for the actor
   * Stores additional information about the actor, usually to configure options for the actor.
   * Often left undefined and set later by the UI specific libraries
   */
  meta?: TMeta;
  /**
   * Spawn the actor as a child of the leader window, immediately after it's ready.
   */
  shouldImmediatelySpawn?:
    | boolean
    | Omit<CreateActorOptions<TContext>, 'type'>
    | Array<Omit<CreateActorOptions<TContext>, 'type'>>;
  /**
   * Ignore the actor when taking snapshots
   * This is useful for actors that low-level / system based, rather than the users "layout"
   */
  ignoreFromTakeSnapshot?: boolean;
  /**
   * Ignore the actor when applying snapshots
   * This is useful for actors that low-level / system based, rather than the users "layout"
   */
  ignoreFromApplySnapshot?: boolean;
  /**
   * Called after all actors are registered in the workspace
   * Can be used to run custom logic after all actors are registered
   * e.g. throw an error if a required actor is not registered
   */
  onRegistrationFinished?: (context: OnRegistrationFinishedContext) => void;
};

export type AnyActorSchema = ActorSchema<any, any, any, any>;

type UnwrapPromise<T> = T extends Promise<infer U> ? U : T;

export type UnwrapContext<T extends AnyActorSchema> = UnwrapPromise<
  ReturnType<NonNullable<T['initialContext']>>
>;

export type UnwrapOperations<T extends AnyActorSchema> = ReturnType<NonNullable<T['operations']>>;

export type UnwrapMeta<T extends AnyActorSchema> = NonNullable<T['meta']>;

export type UnwrapView<T extends AnyActorSchema> = NonNullable<T['view']>;

export class ActorSchemaBuilder<T extends AnyActorSchema> {
  private _schema: T;
  private constructor(schema: T) {
    this._schema = schema;
  }

  static create<
    TContext extends AnyRecord = AnyRecord,
    TOperations extends AnyActorOperations = AnyActorOperations,
    TViewType = any,
    TMeta extends AnyRecord = AnyRecord
  >(schema: ActorSchema<TContext, TOperations, TViewType, TMeta>) {
    return new ActorSchemaBuilder(schema);
  }

  extend<
    TContext extends AnyRecord,
    TOperations extends AnyActorOperations,
    TMetaOverride extends AnyRecord = UnwrapMeta<T>
  >(
    overrides: (
      prev: ActorSchema<TContext, TOperations, UnwrapView<T>, TMetaOverride>
    ) => Partial<Omit<ActorSchema<TContext, TOperations, UnwrapView<T>, TMetaOverride>, 'view'>> = () => ({})
  ) {
    return new ActorSchemaBuilder<ActorSchema<TContext, TOperations, UnwrapView<T>, TMetaOverride>>({
      ...this.schema,
      ...overrides(this.schema)
    });
  }

  extendName(name: string) {
    this._schema = {
      ...this.schema,
      name
    };
    return this;
  }

  extendView<V = any>(view: V) {
    return new ActorSchemaBuilder<ActorSchema<UnwrapContext<T>, UnwrapOperations<T>, V, UnwrapMeta<T>>>({
      ...this.schema,
      view
    });
  }

  optionsCreator<M extends AnyRecord = AnyRecord>() {
    return (options?: M & { view?: UnwrapView<T> }) => {
      const { view, ...meta } = options || {};
      return new ActorSchemaBuilder<ActorSchema<UnwrapContext<T>, UnwrapOperations<T>, UnwrapView<T>, M>>({
        ...this.schema,
        meta,
        view: view ? view : this.schema.view
      }).schema;
    };
  }

  get schema() {
    return this._schema;
  }
}
