import { OpenPopoverBoundsOptions, OpenPopoverOptions, PopoverOpenedBounds } from './popover.contracts';

export interface PopoverTriggerBounds {
  x: number;
  y: number;
  width: number;
  height: number;
  windowX: number;
  windowWidth: number;
  windowY: number;
  windowHeight: number;
  scaleFactor: number;
}

export const calculatePopoverBounds = (
  triggerBounds: PopoverTriggerBounds,
  _options: OpenPopoverBoundsOptions
) => {
  const options = mergeNullableObjects(_options || {}, {
    side: 'bottom',
    align: 'center',
    width: 125 * triggerBounds.scaleFactor,
    height: 150 * triggerBounds.scaleFactor,
    minWidth: 0,
    minHeight: 0,
    sideOffset: 2 * triggerBounds.scaleFactor,
    alignOffset: 0 * triggerBounds.scaleFactor
  });

  function getBounds(options: Required<OpenPopoverBoundsOptions>) {
    let x = triggerBounds.x;
    let y = triggerBounds.y;
    switch (options.side) {
      /**
       * Bottom
       */
      case 'bottom': {
        y = triggerBounds.y + triggerBounds.height + options.sideOffset;
        switch (options.align) {
          case 'start': {
            x = triggerBounds.x + options.alignOffset;
            break;
          }
          case 'center': {
            const midPoint = triggerBounds.x + triggerBounds.width / 2;
            x = midPoint - options.width / 2 + options.alignOffset;
            break;
          }
          case 'end': {
            x = triggerBounds.x + triggerBounds.width - options.width + options.alignOffset;
            break;
          }
        }
        break;
      }
      /**
       * Top
       */
      case 'top': {
        y = triggerBounds.y - options.height - options.sideOffset;
        switch (options.align) {
          case 'start': {
            x = triggerBounds.x + options.alignOffset;
            break;
          }
          case 'center': {
            const midPoint = triggerBounds.x + triggerBounds.width / 2;
            x = midPoint - options.width / 2 + options.alignOffset;
            break;
          }
          case 'end': {
            x = triggerBounds.x + triggerBounds.width - options.width + options.alignOffset;
            break;
          }
        }
        break;
      }
      /**
       * Left
       */
      case 'left': {
        x = triggerBounds.x - options.width - options.sideOffset;
        switch (options.align) {
          case 'start': {
            y = triggerBounds.y + options.alignOffset;
            break;
          }
          case 'center': {
            const ymidPoint = triggerBounds.y + triggerBounds.height / 2;
            y = ymidPoint - options.height / 2 + options.alignOffset;
            break;
          }
          case 'end': {
            y = triggerBounds.y + triggerBounds.height - options.height + options.alignOffset;
            break;
          }
        }
        break;
      }
      /**
       * Right
       */
      case 'right': {
        x = triggerBounds.x + triggerBounds.width + options.sideOffset;
        switch (options.align) {
          case 'start': {
            y = triggerBounds.y + options.alignOffset;
            break;
          }
          case 'center': {
            const ymidPoint = triggerBounds.y + triggerBounds.height / 2;
            y = ymidPoint - options.height / 2 + options.alignOffset;
            break;
          }
          case 'end': {
            y = triggerBounds.y + triggerBounds.height - options.height + options.alignOffset;
            break;
          }
        }
        break;
      }
    }

    return {
      x,
      y,
      side: options.side,
      align: options.align
    };
  }

  function getBoundsWithOffsets() {
    const { x, y } = getBounds(options);

    const bXMin = triggerBounds.windowX;
    const bYMin = triggerBounds.windowY;
    const bXMax = triggerBounds.windowX + triggerBounds.windowWidth;
    const bYMax = triggerBounds.windowY + triggerBounds.windowHeight;

    let side = options.side;
    let alignOffset = options.alignOffset;

    switch (options.side) {
      /**
       * Bottom
       */
      case 'bottom': {
        if (y + options.height > bYMax) {
          side = 'top';
        }
        if (x < bXMin) {
          alignOffset = bXMin - x;
        } else if (x + options.width > bXMax) {
          alignOffset = bXMax - (x + options.width);
        }
        break;
      }
      /**
       * Top
       */
      case 'top': {
        if (y < bYMin) {
          side = 'bottom';
        }
        if (x < bXMin) {
          alignOffset = bXMin - x;
        } else if (x + options.width > bXMax) {
          alignOffset = bXMax - (x + options.width);
        }
        break;
      }
      /**
       * Left
       */
      case 'left': {
        if (x < bXMin) {
          side = 'right';
        }
        if (y < bYMin) {
          alignOffset = bYMin - y;
        } else if (y + options.height > bYMax) {
          alignOffset = bYMax - (y + options.height);
        }
        break;
      }
      /**
       * Right
       */
      case 'right': {
        if (x + options.width > bXMax) {
          side = 'left';
        }
        if (y < bYMin) {
          alignOffset = bYMin - y;
        } else if (y + options.height > bYMax) {
          alignOffset = bYMax - (y + options.height);
        }
        break;
      }
    }

    return getBounds({ ...options, side, alignOffset });
  }

  const { x, y, align, side } = getBoundsWithOffsets();
  const bounds = {
    x: x || 0,
    y: y || 0,
    width: options.width,
    height: options.height,
    renderedSide: side,
    renderedAlign: align,
    externalUpdate: false
  };

  return bounds;
};

function mergeNullableObjects<T, U>(object1: T, object2: U): T & U {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = { ...object1 };

  for (const key in object2) {
    if (Object.prototype.hasOwnProperty.call(object2, key)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const val = (object2 as any)[key];
      if (result[key] === undefined || result[key] === null) {
        result[key] = val;
      }
    }
  }

  return result as T & U;
}

type WindowBounds = {
  width: number;
  height: number;
  x: number;
  y: number;
};

type MousePosition = {
  x: number;
  y: number;
};

export function isMouseInsideBounds(bounds: WindowBounds, mousePosition: MousePosition): boolean {
  const { width, height, x, y } = bounds;
  const { x: mouseX, y: mouseY } = mousePosition;
  const northWest = [x, y];
  const southEast = [x + width, y + height];
  return mouseX >= northWest[0] && mouseX <= southEast[0] && mouseY >= northWest[1] && mouseY <= southEast[1];
}

export function getIntersectionBounds(
  popoverOptions: OpenPopoverOptions,
  popoverBounds: PopoverOpenedBounds,
  mousePos: { x: number; y: number }
) {
  const { x: physicalWinX, y: physicalWinY } = popoverOptions.innerPosition;
  const x = physicalWinX + popoverOptions.rectWithScaling.x;
  const y = physicalWinY + popoverOptions.rectWithScaling.y;
  const elementBounds = {
    x,
    y,
    width: popoverOptions.rectWithScaling.width,
    height: popoverOptions.rectWithScaling.height
  };

  const isOutsidePopover = !isMouseInsideBounds(popoverBounds, mousePos);
  const isOutsideElement = !isMouseInsideBounds(elementBounds, mousePos);

  return {
    isOutsidePopover,
    isOutsideElement
  };
}
