import { OperatorFunction, map } from 'rxjs';
import { AnyRecord, DataSourceCommon } from '@oms/frontend-foundation';
import type { AgGridRowData } from '../../models/datasource.model';

/**
 * Convert a DataSourceCommon to AgGridRowData for usage inside client side ag-grid
 *
 * @returns AgGridRowData
 */
export const toClientsideGridData = <TData extends AnyRecord>(): OperatorFunction<
  DataSourceCommon<TData>,
  AgGridRowData<TData>
> => {
  return (obs$) =>
    obs$.pipe(
      map((data) => {
        const isLoading = data.isFetching || data.results === undefined;
        if (isLoading) {
          // Null will give the grid a loading spinner?
          return null;
        }

        const isEmpty = Array.isArray(data.results) && data.results.length === 0;
        if (isEmpty) {
          // Empty array will show an empty grid
          return [];
        }

        return data.results ?? [];
      })
    );
};
