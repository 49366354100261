import { useEffect, useMemo, Fragment, useRef } from 'react';
import type { FC } from 'react';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { Flex, Section } from '@oms/shared-frontend/ui-design-system';
import { flatten, groupBy } from 'lodash';
import { useAllFieldsContext } from './all-fields-context';
import type { AllFieldsContext } from './all-fields-context';
import type { IEnrichedField, IconClickHandler } from '../enriched-field/enriched-field.component';
import { useAdditionalFieldsState } from './use-additional-fields-state';
import { useEnhancedFormApi } from '@oms/frontend-foundation';
import { allFields$ } from './all-fields-events';
import { filter } from 'rxjs';

const enrichField = (
  field: IEnrichedField,
  context: AllFieldsContext,
  selectedFields: string[],
  onIconClick: IconClickHandler
) => {
  const { renderStrategy, showIcons, columns, fixedFields } = context;

  field.columns = columns;
  field.showIcon = showIcons;
  field.renderStrategy = renderStrategy;
  field.selectedFields = selectedFields;
  field.fixedFields = fixedFields;
  field.onIconClick = onIconClick;

  return field;
};

const getStrippedFormId = (formId?: string) => {
  return formId?.replace('_all-fields', '');
};

const EnrichedFieldRenderer: FC<{
  fields: IEnrichedField[];
  onIconClick: IconClickHandler;
}> = ({ fields = [], onIconClick }) => {
  const { renderForm } = useFormApi();
  const { context } = useAllFieldsContext();
  const { renderStrategy, formType, renderBehaviour } = context;
  const { selectedFields } = useAdditionalFieldsState(formType);

  return useMemo(() => {
    if (!fields) return null;

    return (
      <>
        {fields?.map((field) => {
          if (renderBehaviour === 'add' && renderStrategy === 'dialog-form') {
            if (field.field) {
              if (field.field?.fields) {
                // This field.field is another container (e.g. settlement container). Disable its children too.
                field.field.fields.forEach((item: { fields: any[] }[]) => {
                  if (item[0]) {
                    item[0].fields.forEach((subItem) => {
                      if (subItem[0]) {
                        subItem[0].isDisabled = true;
                        subItem[0].forceIsDisabled = true;
                      }
                    });
                  }
                });
              } else {
                field.field.isDisabled = true;
                field.field.forceIsDisabled = true;
              }
            }
          }

          // Even if the field is NOT visible, we still need to render it in the main form
          // to ensure our entire state is there.
          if (!field.field.isVisible && renderStrategy === 'main-form') {
            return (
              <div key={field.name} style={{ display: 'none' }}>
                {renderForm([field])}
              </div>
            );
          }

          const enrichedField = enrichField(field, context, selectedFields, onIconClick);

          return <Fragment key={field.name}>{renderForm([enrichedField])}</Fragment>;
        })}
      </>
    );
  }, [fields, renderStrategy, context, selectedFields, onIconClick, renderForm]);
};

const DialogFormFieldRenderer = ({ onIconClick }: { onIconClick: IconClickHandler }) => {
  const {
    context: { fields, fixedFields, groups }
  } = useAllFieldsContext();

  const filteredEntries = useMemo(() => {
    // fixed fields should not be shown in the All Fields dialog
    const fieldsToShow = flatten(fields).filter((field) => !fixedFields?.includes(field.field.name));

    const fieldsByGroup = groupBy(fieldsToShow, 'groupName');

    return groups && groups.length > 0
      ? groups.map((groupName) => [groupName, fieldsByGroup[groupName]])
      : Object.entries(fieldsByGroup);
  }, [fields, fixedFields, groups]);

  return (
    <>
      {filteredEntries.map(([groupName, fields], i) => {
        return (
          <Section
            key={i}
            title={groupName as string}
            sx={{
              backgroundColor: 'layout.level1',
              borderRadius: 'none'
            }}
          >
            <Flex wrap="wrap" sx={{ marginLeft: '-5' }}>
              <EnrichedFieldRenderer fields={fields as IEnrichedField[]} onIconClick={onIconClick} />
            </Flex>
          </Section>
        );
      })}
    </>
  );
};

const MainFormFieldRenderer = ({ onIconClick }: { onIconClick: IconClickHandler }) => {
  const {
    context: { fields, fixedFields, formType }
  } = useAllFieldsContext();

  const { selectedFields } = useAdditionalFieldsState(formType);

  const fieldsWithVisibility = fields.map((fields) => {
    const field = fields[0].field;
    return [
      {
        ...fields[0],
        field: { ...field, isVisible: [...fixedFields, ...selectedFields].includes(field.name) }
      }
    ];
  });

  return (
    <Section
      sx={{
        backgroundColor: 'layout.level1',
        borderRadius: 'none'
      }}
    >
      <Flex wrap="wrap" sx={{ marginLeft: '-5' }}>
        {fieldsWithVisibility.map((fields, i) => {
          return <EnrichedFieldRenderer key={i} fields={fields} onIconClick={onIconClick} />;
        })}
      </Flex>
    </Section>
  );
};

export const AllFieldsRenderer: FC<{ onIconClick: IconClickHandler }> = ({ onIconClick }) => {
  const shouldBroadcastValuesChangedEvRef = useRef(true);
  const formApi = useEnhancedFormApi();

  const {
    context: { fields, formType, formId, renderStrategy }
  } = useAllFieldsContext();

  useEffect(() => {
    const sub = allFields$
      .pipe(
        filter(({ type, meta }) => {
          const strippedFormId = getStrippedFormId(formId);
          const strippedMetaFormId = getStrippedFormId(meta?.formId);

          return (
            type === 'VALUES_CHANGE' &&
            strippedFormId === strippedMetaFormId &&
            meta?.renderStrategy !== renderStrategy
          );
        })
      )
      .subscribe(({ payload }) => {
        if ('formValues' in payload) {
          shouldBroadcastValuesChangedEvRef.current = false;
          formApi.reset(payload.formValues);
        }
      });

    return () => {
      sub.unsubscribe();
    };
  }, [formApi, formId, renderStrategy]);

  useEffect(() => {
    const sub = formApi.get$({ values: true }).subscribe(({ values }) => {
      if (!shouldBroadcastValuesChangedEvRef.current) {
        shouldBroadcastValuesChangedEvRef.current = true;
        return;
      }

      if (!renderStrategy) {
        console.error('renderStrategy is not defined');
        return;
      }

      allFields$.next({
        type: 'VALUES_CHANGE',
        payload: {
          formValues: values
        },
        meta: { formId, formType: formType || '', renderStrategy }
      });
    });

    return () => {
      sub.unsubscribe();
    };
  }, [fields, formApi, formId, formType, renderStrategy]);

  useEffect(() => {
    const sub = allFields$
      .pipe(
        filter(({ type, meta }) => {
          const strippedFormId = getStrippedFormId(formId);
          const strippedMetaFormId = getStrippedFormId(meta?.formId);

          return type === 'RESET_VALUES' && strippedFormId === strippedMetaFormId;
        })
      )
      .subscribe(() => formApi.reset());

    return () => {
      sub.unsubscribe();
    };
  }, [formApi, formId]);

  if (renderStrategy === 'dialog-form') {
    return <DialogFormFieldRenderer onIconClick={onIconClick} />;
  }

  return <MainFormFieldRenderer onIconClick={onIconClick} />;
};
