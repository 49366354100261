import { CommandPaletteService } from '@app/data-access/services/system/command-palette/command-palette.service';
import type { DependencyContainer } from 'tsyringe';
import type { UncontructedRegisterCommand, RegisterCommand } from './register-command.model';
import { COMMAND_PALETTE_GROUP_ORDER } from './command-palette.constants';
import { SystemCommands } from './registry/system.commands';
import { NavigationCommands } from './registry/navigation.commands';
import { LayoutCommands } from './registry/layout.commands';

const commandsRegistry: UncontructedRegisterCommand[] = [SystemCommands, NavigationCommands, LayoutCommands];

export async function registerCommandPaletteDefaults(container: DependencyContainer) {
  const cmdp = container.resolve(CommandPaletteService);
  await cmdp.unregisterAll();
  await cmdp.setCategoriesOrder(COMMAND_PALETTE_GROUP_ORDER);

  const disposers: Array<RegisterCommand['dispose']> = [];
  for (const item of commandsRegistry) {
    const instance = container.resolve(item);
    await instance.init();
    disposers.push(() => instance.dispose());
  }

  return () => {
    for (const disposer of disposers) {
      disposer();
    }
  };
}
