import { DateTime } from 'luxon';
import type { Maybe } from '@oms/shared/util-types';
import { cleanMaybe } from '@oms/shared/util';
import { OrderSide, OrderType } from '@oms/generated/frontend';
import type { MontageInstrumentFragment, MontageUnboundTradingOrderFragment } from '@oms/generated/frontend';
import type { OnCountChangeEvent } from '@oms/shared-frontend/ui-design-system';
import type { SimpleInstrument } from '@app/common/types/instrument/instrument.types';
import type {
  LimitPriceInfo,
  MontageItem,
  MontageItemPriceInfo,
  MontageItemType,
  Level2QuotePage
} from '../montage.types';
import { DEFAULT_LOT_SIZE } from '../montage.constants';
import type {
  CalculateMontageItemQuantitiesOptions,
  CalculateMontageSizeExtendedOptions,
  CalculateMontageSizeOptions,
  GetLotSizeForOptions,
  InstrumentAddOnOptions,
  MontageItemQuantities
} from './utils.types.internal';

export const getMontageItemTypeForSide = (side: Maybe<OrderSide>, or: MontageItemType): MontageItemType => {
  switch (side) {
    case OrderSide.Buy:
      return 'bid';
    case OrderSide.Sell:
      return 'ask';
    default:
      return or;
  }
};

export const toMontageDate = (createdTimestamp: any): string => {
  if (createdTimestamp instanceof Date) {
    return DateTime.fromJSDate(createdTimestamp).toISO() || createdTimestamp.toISOString();
  }
  switch (typeof createdTimestamp) {
    case 'string':
      return DateTime.fromISO(createdTimestamp).toISO() || createdTimestamp;
    case 'number':
      return DateTime.fromMillis(createdTimestamp).toISO() ?? `${createdTimestamp}`;
    default:
      return DateTime.now().toISO();
  }
};

export const montageDateComparator = ({ time: a }: MontageItem, { time: b }: MontageItem) => {
  return DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();
};

export const montageTypeComparator = ({ type: a }: MontageItem, { type: b }: MontageItem) => {
  if (a === b) return 0;
  return a === 'ask' ? 1 : -1;
};

export const montageItemTypeComparator = (
  { orderType: a }: MontageItemPriceInfo,
  { orderType: b }: MontageItemPriceInfo
) => {
  if (a === b) return 0;
  return a === OrderType.Market ? 1 : -1;
};

export const montageLimitPriceComparator =
  (type: MontageItemType) =>
  ({ limitPrice: a }: LimitPriceInfo, { limitPrice: b }: LimitPriceInfo) =>
    type === 'bid' ? b - a : a - b;

export const montageSortOrderComparator = (a: MontageItem, b: MontageItem) => {
  if (a.type !== b.type) {
    return montageTypeComparator(a, b);
  }

  const { type } = a;

  if (a.orderType !== b.orderType) {
    return montageItemTypeComparator(a, b);
  }

  if (a.orderType === OrderType.Limit && b.orderType === OrderType.Limit && a.limitPrice !== b.limitPrice) {
    return montageLimitPriceComparator(type)(a, b);
  }

  if (a.time !== b.time) {
    return montageDateComparator(a, b);
  }

  if (a.size !== b.size) {
    return a.size - b.size;
  }

  if (a.volume !== b.volume) {
    return a.volume - b.volume;
  }

  // If sizes are equal, compare by customer
  if (a.counterpartyId !== b.counterpartyId) {
    return a.counterpartyId.localeCompare(b.counterpartyId);
  }

  return 0;
};

export const montagePriceColorComparator = (a: MontageItem, b: MontageItem) => {
  if (a.type !== b.type) {
    return montageTypeComparator(a, b);
  }

  const { type } = a;

  if (a.orderType === OrderType.Limit && b.orderType === OrderType.Limit && a.limitPrice !== b.limitPrice) {
    return montageLimitPriceComparator(type)(a, b);
  } else {
    return 0;
  }
};

export const calculateTarget = (curr: number, max: number, type: OnCountChangeEvent['countType']): number => {
  const next = type === 'up' ? curr - 1 : curr + 1;
  return type === 'up' ? (next < 0 ? max : next) : next > max ? 0 : next;
};

/**
 * @param instrument - Pass an instrument object (could be undefined or `null`)
 * @param options.fallback - Pass a fallback lot size. If omitted, the constant default (100) will be used.
 * @returns The numeric lot size for the given instrument.
 */
export const getLotSizeFor = (
  instrument?: Maybe<MontageInstrumentFragment>,
  options?: GetLotSizeForOptions
): number => cleanMaybe(instrument?.lotSize, options?.fallback ?? DEFAULT_LOT_SIZE);

/**
 * Calculate the montage sized based on total shares, lot size and user preferences.
 *
 * @param totalShares - The `quantity` or total number of shares from the trading order
 * @param lotSize - Pass a known numeric lot size
 * @param options.preferences - Pass the user montage preferences
 * @param options.roundingType - If rounding is needed, round down, up or nearest
 * @returns The numeric montage size (based on total shares and passed settings)
 */
export function calculateMontageSize(
  totalShares: number,
  lotSize: number,
  options?: CalculateMontageSizeOptions
): number;

/**
 * Calculate the montage sized based on total shares, default lot size and user preferences.
 *
 * @param totalShares - The `quantity` or total number of shares from the trading order
 * @param options.preferences - Pass the user montage preferences
 * @param options.roundingType - If rounding is needed, round down, up or nearest
 * @param options.lotSize - Pass a possible lot size. If omitted, the constant default (100) will be used.
 * @returns The numeric montage size (based on total shares and passed settings)
 */
export function calculateMontageSize(
  totalShares: number,
  options?: CalculateMontageSizeExtendedOptions
): number;

// Implementation only ------------- /
export function calculateMontageSize(
  totalShares: number,
  lotSizeOrOptions?: number | CalculateMontageSizeExtendedOptions,
  options?: CalculateMontageSizeOptions
): number {
  const lotSize =
    typeof lotSizeOrOptions === 'number' ? lotSizeOrOptions : lotSizeOrOptions?.lotSize || DEFAULT_LOT_SIZE;
  const { preferences, roundingType = 'floor' } =
    typeof lotSizeOrOptions === 'object' ? lotSizeOrOptions : options || {};
  const displayQuotesInShares = cleanMaybe(preferences?.displayQuotesInShares);
  if (displayQuotesInShares) return totalShares;
  const hideOddLots = cleanMaybe(preferences?.hideOddLots);
  const size = totalShares / lotSize;
  if (hideOddLots) {
    switch (roundingType) {
      case 'down':
        return Math.floor(size);
      case 'up':
        return Math.ceil(size);
      case 'nearest':
        return Math.round(size);
    }
  }
  return size;
}

export const calculateMontageItemQuantities = {
  /**
   * Calculate volume (total shares), lot size and size for Montage from an unbound trading order
   *
   * @param unboundTradingOrder - Pass an unbound trading order
   * @param options.preferences - Pass the user montage preferences
   * @param options.roundingType - If rounding is needed, round down, up or nearest
   * @param options.fallbackVolume - Pass a fallback volume in case `quantity` is not specified in the trading order. Will default to zero if omitted.
   * @param options.fallbackLotSize - Pass a fallback lot size. If omitted, the constant default (100) will be used.
   * @returns An object with resolved volume (total shares), lot size and size (may be total shares or total lots)
   */
  fromUnboundTradingOrder: (
    unboundTradingOrder: MontageUnboundTradingOrderFragment,
    options?: CalculateMontageItemQuantitiesOptions
  ): MontageItemQuantities => {
    const { fallbackVolume = 0, ...calculateSizeOptions } = options || {};
    const { quantity, instrument } = unboundTradingOrder;
    const lotSize = getLotSizeFor(instrument, { fallback: calculateSizeOptions.fallbackLotSize });
    const totalShares = cleanMaybe(quantity, fallbackVolume);
    const size = calculateMontageSize(totalShares, lotSize, calculateSizeOptions);
    return { volume: totalShares, lotSize, size };
  },
  /**
   * Calculate volume (total shares), lot size and size for Montage from an unbound trading order
   *
   * @param level2QuotePage - Pass the quote page object from level 2 data
   * @param options.preferences - Pass the user montage preferences
   * @param options.instrument - Pass instrument fragment for lot size lookup
   * @param options.roundingType - If rounding is needed, round down, up or nearest
   * @param options.fallbackLotSize - Pass a fallback lot size. If omitted, the constant default (100) will be used.
   * @returns An object with resolved volume (total shares), lot size and size (may be total shares or total lots)
   */
  fromLevel2QuotePage: (
    level2QuotePage: Level2QuotePage,
    options?: Omit<CalculateMontageItemQuantitiesOptions, 'fallbackVolume'> & InstrumentAddOnOptions
  ): MontageItemQuantities => {
    const { instrument, ...calculateSizeOptions } = options || {};
    const { volume } = level2QuotePage;
    const lotSize = getLotSizeFor(instrument, { fallback: calculateSizeOptions.fallbackLotSize });
    const size = calculateMontageSize(volume, lotSize, calculateSizeOptions);
    return { volume, lotSize, size };
  }
};

export const extractMontageItemPriceInfoFrom = (
  unboundTradingOrder: MontageUnboundTradingOrderFragment,
  fallbackLimitPrice?: number
): MontageItemPriceInfo => {
  const { limitPrice } = unboundTradingOrder;
  const orderType = cleanMaybe(
    unboundTradingOrder.orderType,
    typeof limitPrice === 'number' ? OrderType.Limit : OrderType.Market
  );
  switch (orderType) {
    case OrderType.Limit:
      return {
        orderType: OrderType.Limit,
        limitPrice: cleanMaybe(limitPrice, fallbackLimitPrice ?? 0)
      };
    case OrderType.Market:
      return {
        orderType: OrderType.Market
      };
  }
};

export const isMontageInstrumentFragment = (
  instrument: MontageInstrumentFragment | SimpleInstrument
): instrument is MontageInstrumentFragment => {
  if ((instrument as MontageInstrumentFragment).__typename === 'InstrumentDetails') return true;
  return typeof (instrument as Partial<SimpleInstrument>).displayCode === 'undefined';
};
