import React, { useMemo } from 'react';
import { cloneElement, useRef, useState } from 'react';
import type { CheckboxButtonProps } from './checkbox-button';
import type { StackProps } from '../../layout/stack/stack';
import clsx from 'clsx';
import { checkboxButtonGroupContainer } from './css/checkbox-button.css';
import { getValidChildren } from '../../system/utils/children';
import { useId } from '../../system/hooks/use-id';
import { Box } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';

export type CheckboxButtonGroupProps = {
  /**
   * The id of the checkbox-button/radio group.
   */
  id?: CheckboxButtonProps['id'];
  /**
   * The name of the checkbox-button/radio group. This prop is passed to each checkbox
   */
  name?: CheckboxButtonProps['name'];
  /**
   * The content of the checkbox-button group. Must be the `CheckboxButton` or `Radio` component
   */
  children?: React.ReactNode;
  /**
   * The initial values of the checkbox-button group
   */
  defaultCheckedValues?: Array<CheckboxButtonProps['value']>;
  /**
   * The values of the checkbox-button group
   */
  checkedValues?: Array<CheckboxButtonProps['value']>;
  /**
   * The callback fired when any children CheckboxButton/Radio is checked or unchecked
   */
  onChange?: (checkedValues: Array<CheckboxButtonProps['value']>) => void;
  /**
   * If `true`, the checkbox-buttons/radios will aligned horizontally.
   */
  isInline?: boolean;
  /**
   * Size of all wrapped checkbox-buttons
   */
  size?: CheckboxButtonProps['size'];
} & Omit<StackProps, 'onChange' | 'value' | 'size'>;

export const CheckboxButtonGroup = React.memo(
  React.forwardRef<HTMLDivElement, CheckboxButtonGroupProps>((props, ref) => {
    const {
      checkedValues: _checkedValuesProp,
      defaultCheckedValues: _defaultCheckedValuesProp = [],
      onChange,
      name,
      size,
      isInline,
      children,
      ...rest
    } = props;

    // If no name is passed, we'll generate a random, unique name
    const fallbackName = `checkbox-button-${useId()}`;
    const _name = name || fallbackName;
    const validChildren = getValidChildren(children);

    const [checkedValues, setCheckedValues] = useState(_defaultCheckedValuesProp);

    const { current: isControlled } = useRef(!!_checkedValuesProp);
    const _checkedValues = isControlled ? _checkedValuesProp : checkedValues;

    const clones = useMemo(() => {
      return validChildren.map((child, index) => {
        const name = child.props.name || `${_name}-${index}`;
        const value = `${child.props.value}`;
        const _onChange = (checked: boolean) => {
          const newValues = [];
          if (checked) {
            newValues.push(...(_checkedValues || []), value);
          } else {
            newValues.push(...(_checkedValues || []).filter((v) => v !== value));
          }
          !isControlled && setCheckedValues(newValues);
          onChange && onChange(newValues);
        };

        return (
          <Box key={index} className={clsx(child.props.className)}>
            {cloneElement(child, {
              size,
              color: child.props.palette,
              name: name,
              onChange: _onChange,
              isChecked: (_checkedValues || []).includes(child.props.value)
            })}
          </Box>
        );
      });
    }, [_checkedValues, _name, isControlled, onChange, size, validChildren]);

    return (
      <Box ref={ref} {...rest}>
        <Box className={clsx(checkboxButtonGroupContainer)}>{clones}</Box>
      </Box>
    );
  })
);

if (__DEV__) {
  CheckboxButtonGroup.displayName = 'CheckboxButtonGroup';
}
