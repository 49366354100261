import type { AnyRecord } from '@valstro/workspace';
import type { ISelectField, ModifiedFieldForOptions } from '@oms/frontend-foundation';
import { DistributionType } from '@oms/generated/frontend';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { FieldContract } from '@oms/frontend-foundation';

export const createDistributionTypeField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<ISelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'select').options<ISelectField<any>>({
    label: 'Distribution',
    options: [
      {
        value: DistributionType.EvenDistribution,
        label: 'Even'
      }
    ],
    ...overrides
  });
