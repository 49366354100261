import { CustomGraphQLError } from './custom-error';
import { ErrorTypeEnum } from './errors.types';

export const validationErrorMessage = 'Validation error';
export class ValidationError<TError = any> extends CustomGraphQLError<
  ErrorTypeEnum.VALIDATION_ERROR,
  TError[]
> {
  constructor(errors: TError[], code?: string) {
    super(validationErrorMessage);
    this.extensions = {
      type: ErrorTypeEnum.VALIDATION_ERROR,
      code: code || ErrorTypeEnum.VALIDATION_ERROR,
      data: errors
    };
  }
}
