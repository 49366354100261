import { Box, IconButton } from '@oms/shared-frontend/ui-design-system';
import { type FC, type MouseEventHandler, useCallback } from 'react';
import { DragHandleDots2Icon, TrashIcon } from '@radix-ui/react-icons';
import {
  type RuleDragCollectionProps,
  RuleDragItemTypes,
  type RuleGroup,
  type RuleProps
} from './rule.types';
import { useDrag } from 'react-dnd';

export const Rule: FC<RuleProps> = ({ children, index, level, onDelete }) => {
  const [_, drag, preview] = useDrag<RuleProps, any | RuleGroup, RuleDragCollectionProps>(
    () => ({
      type: RuleDragItemTypes.RULE,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        path: []
      })
    }),
    [index, level]
  );

  const deleteCb: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      onDelete?.(e);
    },
    [onDelete]
  );

  return (
    <Box
      ref={preview}
      style={{ margin: '2px' }}
      sx={{
        bg: 'layout.level3',
        display: 'flex',
        flexDirection: 'row',
        padding: 4,
        flexGrow: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          padding: 2,
          alignItems: 'center'
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <IconButton
          icon={<TrashIcon />}
          variant={'secondary'}
          sx={{ backgroundColor: 'icons.primary', margin: 1 }}
          aria-label="remove-rule"
          onClick={deleteCb}
        />
        <IconButton
          as={'div'}
          ref={drag}
          icon={<DragHandleDots2Icon />}
          aria-label="drag rule"
          variant={'secondary'}
          sx={{ backgroundColor: 'icons.primary', margin: 1 }}
        />
      </Box>
    </Box>
  );
};
