import { isValidElement, cloneElement } from 'react';
import type { ReactElement } from 'react';
import clsx from 'clsx';
import { Button, ButtonProps } from './button';
import * as styles from './css/icon-button.css';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

type OmittedProps = 'leftIcon' | 'isFullWidth' | 'rightIcon' | 'loadingText';

export type IconButtonProps = {
  icon?: ReactElement;
  isRound?: boolean;
  'aria-label': string;
} & Omit<ButtonProps, OmittedProps>;

export const IconButton = polymorphicComponent<'button', IconButtonProps>((props, ref) => {
  const { icon, children, isLoading, isRound, 'aria-label': ariaLabel, className, sx = {}, ...rest } = props;

  /**
   * Passing the icon as prop or children should work
   */
  const element = icon || children;
  const _children = isValidElement(element)
    ? cloneElement(element as any, {
        'aria-hidden': true,
        focusable: false
      })
    : null;

  return (
    <Button
      sx={{
        borderRadius: isRound ? 'full' : undefined,
        ...sx
      }}
      ref={ref}
      aria-label={ariaLabel}
      className={clsx(styles.iconButton, className)}
      isLoading={isLoading}
      {...rest}
    >
      {!isLoading && _children}
    </Button>
  );
});

if (__DEV__) {
  IconButton.displayName = 'IconButton';
}
