import {
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef
} from '@ag-grid-community/core';
import { inject, Lifecycle, scoped, delay } from 'tsyringe';
import uniqBy from 'lodash/uniqBy';
import { AnyRecord } from '@oms/frontend-foundation';
import {
  ContextMenuItem,
  ContextMenuItemId,
  GridMenuOptionLocation,
  GridMenuOptions
} from '../models/grid-menu-options.model';

@scoped(Lifecycle.ContainerScoped)
export class ContextMenuService<TData extends AnyRecord> {
  public _contextMenuItems: ContextMenuItem<TData>[] = [];
  private _gridApi: GridApi<TData>;

  constructor(@inject(delay(() => GridApi)) gridApi: GridApi<TData>) {
    this._gridApi = gridApi;
  }

  public loadMenuOptions(menuOptions: GridMenuOptions<TData>) {
    // TODO: Breaks ESLint server
    const contextMenuItems: ContextMenuItem<TData>[] = menuOptions
      .filter((o) => o.locations.includes(GridMenuOptionLocation.ContextMenu))
      .map((o) => ({ id: o.id, callback: o.get, pinToBottom: true }));
    if (contextMenuItems.length) {
      this.append(contextMenuItems);
    }
  }

  public prepend(items: ContextMenuItem<TData>[]) {
    this._contextMenuItems = uniqBy([...items, ...this._contextMenuItems], 'id');
    this._render();
  }

  public append(items: ContextMenuItem<TData>[]) {
    // TODO: Breaks ESLint server
    const { pinnedToBottom, rest } = this._splitExistingItems();
    this._contextMenuItems = uniqBy([...rest, ...items, ...pinnedToBottom], 'id');
    this._render();
  }

  private _splitExistingItems() {
    // TODO: Breaks ESLint server
    return {
      pinnedToBottom: this._contextMenuItems.filter((item) => item.pinToBottom),
      rest: this._contextMenuItems.filter((item) => !item.pinToBottom)
    };
  }

  public update(items: ContextMenuItem<TData>[]) {
    // TODO: Breaks ESLint server
    this._contextMenuItems = this._contextMenuItems.map((oldItem) => {
      const newItem = items.find((updatedItem) => updatedItem.id === oldItem.id);
      return newItem || oldItem;
    });
    this._render();
  }

  public upsert(items: ContextMenuItem<TData>[]) {
    this.append(items);
    this.update(items);
  }

  public remove(itemsIds: ContextMenuItemId[]) {
    // TODO: Breaks ESLint server
    this._contextMenuItems = this._contextMenuItems.filter(
      (menuItem) => menuItem.id && !itemsIds.includes(menuItem.id)
    );
    this._render();
  }

  public getMenuItemsFunc(): GetContextMenuItems<TData> {
    return (params: GetContextMenuItemsParams<TData>) => {
      // Create array to hold Ag Grid menu items
      const items: (string | MenuItemDef)[] = [];

      // When migrating from v1, we don't have this._vgridSettingsService any more
      // Future work - do we need it, and should we use something like
      // <AgGridReact onCellContextMenu={event => event.node.setSelected()}>
      // instead if we do?
      if (/*!!this._vgridSettingsService.settings.rowSelection && */ params.node?.selectable) {
        // Select the row that was right-clicked on, to make it clear to the user
        // which row he's working on (otherwise the row they right clicked on might not
        // be the selected one which causes doubt which row they the action will work on)
        params.node?.setSelected(true, true, 'rowClicked');
      }

      // Flags to determine if we need to add a separator
      let hasNonPinnedItems = false;
      let hasBottomSeparator = false;

      // Loop through all menu items
      for (let i = 0; i < this._contextMenuItems.length; i++) {
        const item = this._contextMenuItems[i];

        // Run the callbacks and add the results to the items array if visible
        const menuItemResult = item.callback(params);
        if (!menuItemResult) {
          continue;
        }

        if (!item.pinToBottom) {
          hasNonPinnedItems = true;
        }

        // Add a separator if needed for bottom items
        if (hasNonPinnedItems && item.pinToBottom && !hasBottomSeparator) {
          items.push('separator');
          hasBottomSeparator = true;
        }

        items.push(menuItemResult);
      }

      return items;
    };
  }

  private _render(): void {
    // TODO: Breaks ESLint server
    this._gridApi.setGridOption('getContextMenuItems', this.getMenuItemsFunc());
  }
}
