import { Box, type BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { HeadingTypographyStyleUnion, TypographyStyle } from '../../theme/foundations/typographyStyle';
import { typographyStyleVariants } from '../../theme/typography.css';
import clsx from 'clsx';
import * as textStyles from '../text/text.css';

type OmittedProps = 'as';

export type HeadingProps = {
  type?: HeadingTypographyStyleUnion;
  isTruncated?: boolean;
} & Omit<BoxProps, OmittedProps>;

export const Heading = polymorphicComponent<'div', HeadingProps>((props, ref) => {
  const { type = TypographyStyle.BaseB, children, className, isTruncated = false, ...rest } = props;

  const as =
    type === TypographyStyle.H1 ||
    type === TypographyStyle.H2 ||
    type === TypographyStyle.H3 ||
    type === TypographyStyle.H4 ||
    type === TypographyStyle.H5 ||
    type === TypographyStyle.H6
      ? type
      : 'div';

  return (
    <Box
      as={as}
      className={clsx(typographyStyleVariants[type], isTruncated && textStyles.truncated, className)}
      {...rest}
      ref={ref}
    >
      {children}
    </Box>
  );
});

if (__DEV__) {
  Heading.displayName = 'Heading';
}
