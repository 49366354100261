import { invoke } from '@tauri-apps/api/tauri';
import type { Monitor, WindowOptions } from '@tauri-apps/api/window';
import type { Sidecar } from './types';
import { Result } from '@oms/shared/util';

// Main app commands ---------------------------------------- /

type TauriCommandReturnMap = {
  start_level2_sidecar: Sidecar;
  get_level2_sidecar: Sidecar;
  stop_level2_sidecar: Sidecar;
};

type TauriCommandInvokeArgMap = {
  start_level2_sidecar: undefined;
  get_level2_sidecar: undefined;
  stop_level2_sidecar: undefined;
};

export type TauriCommand = keyof TauriCommandReturnMap;

export const invokeTauriCommand = async <TC extends TauriCommand>(
  command: TC,
  args?: TauriCommandInvokeArgMap[TC]
): Promise<Result<TauriCommandReturnMap[TC], Error>> => {
  try {
    const value = await invoke<TauriCommandReturnMap[TC]>(command, args);
    return Result.success(value);
  } catch (e) {
    if (e instanceof Error) {
      return Result.failure(e);
    } else {
      return Result.failure(new Error(`Error invoking "${command}"`));
    }
  }
};

// plugin:workspace-actor commands ---------------------------------------- /

type TauriWorkspacePluginCommandReturnMap = {
  get_mouse_position: [number, number];
  get_active_monitor: Monitor;
  get_window_active_monitor_center_coords: [number, number];
  create_unfocused_window: void;
};

type TauriWorkspacePluginCommandInvokeArgMap = {
  get_mouse_position: undefined;
  get_active_monitor: undefined;
  get_window_active_monitor_center_coords: { label: string; pre_width: number; pre_height: number };
  create_unfocused_window: { label: string; options: WindowOptions };
};

export type TauriWorkspacePluginCommand = keyof TauriWorkspacePluginCommandReturnMap;

export const invokeTauriWorkspacePluginCommand = async <TC extends TauriWorkspacePluginCommand>(
  command: TC,
  args?: TauriWorkspacePluginCommandInvokeArgMap[TC]
): Promise<Result<TauriWorkspacePluginCommandReturnMap[TC], Error>> => {
  try {
    const value = await invoke<TauriWorkspacePluginCommandReturnMap[TC]>(
      `plugin:workspace-actor|${command}`,
      args
    );
    return Result.success(value);
  } catch (e) {
    if (e instanceof Error) {
      return Result.failure(e);
    } else {
      return Result.failure(new Error(`Error invoking "${command}"`));
    }
  }
};
