import { useFormApi } from '@data-driven-forms/react-form-renderer';
import {
  DisplayField as DisplayFieldComp,
  type DisplayFieldProps
} from '@oms/shared-frontend/ui-design-system';
import React, { type FC } from 'react';
import { type FieldProps } from '../../types';
import { type IDisplayField } from './display-field.types';
import isEmpty from 'lodash/isEmpty';

export const DisplayField: FC<FieldProps<IDisplayField>> = React.memo(({ displayFieldComponentProps }) => {
  const api = useFormApi();
  const { fieldName, label, component } = displayFieldComponentProps || {};
  const fieldState = fieldName ? (api.getState().values[fieldName] as DisplayFieldProps | undefined) : null;
  const renderedLabel = fieldState?.label || label;
  const renderedComponent = { ...component, ...fieldState?.component };

  if (isEmpty(renderedComponent)) {
    return null;
  }

  return (
    <DisplayFieldComp
      label={renderedLabel}
      component={renderedComponent}
      sx={{
        color: 'white'
      }}
      labelMargin={6}
    />
  );
});
