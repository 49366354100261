import { Box, type BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { Sprinkles } from '../../system/sprinkles.css';

export type DividerProps = {
  /**
   * Orientation aligns divider either horizontal or vertical.
   */
  orientation?: 'horizontal' | 'vertical';

  /**
   * Border color sets the line color
   */
  borderColor?: Sprinkles['borderColor'];

  /**
   * Border style sets the line style
   */
  borderStyle?: Sprinkles['borderStyle'];

  /**
   * Border width sets the line width
   */
  borderWidth?: Sprinkles['borderWidth'];
} & BoxProps;

export const Divider = polymorphicComponent<'div', DividerProps>((props, ref) => {
  const {
    as = 'hr',
    borderColor = 'border.subtle',
    borderStyle = 'solid',
    orientation = 'horizontal',
    borderWidth = '1px',
    sx = {},
    ...rest
  } = props;

  return (
    <Box
      as={as}
      {...rest}
      aria-orientation={orientation}
      sx={{
        boxSizing: 'content-box',
        borderColor: borderColor,
        borderTopWidth: 'none',
        borderLeftWidth: 'none',
        borderBottomStyle: borderStyle,
        borderBottomWidth: orientation === 'horizontal' ? borderWidth : 'none',
        borderRightWidth: orientation === 'vertical' ? borderWidth : 'none',
        width: orientation === 'horizontal' ? 'full' : 'none',
        height: orientation === 'vertical' ? 'full' : 'none',
        overflow: 'visible',
        ...sx
      }}
      ref={ref}
    />
  );
});

if (__DEV__) {
  Divider.displayName = 'Divider';
}
