import React from 'react';
import { Stack as StackComponent } from '@oms/shared-frontend/ui-design-system';
import { useFieldApi } from '../../helpers';
import type { FieldProps } from '../../types';
import { type IStackField } from './stack.type';
import { type Field, useFormApi } from '@data-driven-forms/react-form-renderer';

export const Stack: React.FC<FieldProps<IStackField>> = (props) => {
  const { renderForm } = useFormApi();
  const { fields, stackType, equalWidthChildren, wrap, spacing = 2 } = useFieldApi<IStackField>(props);
  const { sx = {}, style = {} } = props;

  const isInline = stackType === 'stack-h';

  return (
    <StackComponent
      isInline={isInline}
      wrap={wrap}
      equalWidthChildren={equalWidthChildren}
      spacing={spacing}
      sx={{
        alignItems: 'flex-end',
        ...sx
      }}
      style={style}
    >
      {fields.map((f: Field[], _i: number) => renderForm(f))}
    </StackComponent>
  );
};
