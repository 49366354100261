import { AppWorkspace } from '@app/app-config/workspace.config';
import { COMMAND_PALETTE_GROUP } from '@app/commands/command-palette.constants';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import type { CommandPaletteItem } from '@app/common/command-palette/command-palette.contracts';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { CommandPaletteService } from '@app/data-access/services/system/command-palette/command-palette.service';
import { openSaveSnapshotForm } from '@app/generated/sdk';
import { PROCESS_ID, isTauri } from '@valstro/workspace';
import type { Subscription } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { RegisterCommand } from '../register-command.model';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class LayoutCommands implements RegisterCommand {
  private authSub: Subscription | undefined = undefined;
  constructor(
    @inject(CommandPaletteService) private cmpd: CommandPaletteService,
    @inject(AuthService) private authService: AuthService,
    @inject(AppWorkspace) private appWorkspace: AppWorkspace
  ) {}

  init() {
    this.authSub = this.authService.$.subscribe((auth: AuthClientState) => {
      this.onAuth(auth).catch(console.error);
    });
  }

  dispose() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  private async onAuth(auth: AuthClientState) {
    const items = this.layoutItems();
    if (auth.isAuthenticated) {
      await this.cmpd.register(items);
    } else {
      await this.cmpd.unregister(items);
    }
  }

  private layoutItems(): CommandPaletteItem[] {
    return [
      {
        id: 'save-new-layout',
        label: 'Save new layout',
        category: COMMAND_PALETTE_GROUP.LAYOUTS,
        onSelect: async (_, { windowId }) => {
          try {
            await openSaveSnapshotForm(this.getWindowIdToOpenFrom(windowId));
          } catch (error) {
            console.error(error);
          }
        }
      }
    ];
  }

  private getWindowIdToOpenFrom(windowId: string) {
    return isTauri() ? PROCESS_ID.LEADER : windowId; // In browser we want windows to open in the root window
  }
}
