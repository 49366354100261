import { RowDragEndEvent } from '@ag-grid-community/core';
import { ActionsService } from '../../../services/actions.service';
import { Action, ActionChangeEventStrategy, ActionStateChangeEvent } from '../../../models/actions.model';
export class RowDragStrategy implements ActionChangeEventStrategy<RowDragEndEvent<any>> {
  public execute(
    actions: Action<any>[],
    e: ActionStateChangeEvent<RowDragEndEvent>
  ): Action<any>[] | Promise<Action<any>[]> {
    if (!e.event) {
      return [];
    }

    const event = e.event;

    const action = actions.find((a) => {
      const nodeId = event.node.id;

      return a.id.includes(nodeId || '');
    });

    const { name = '' } = ActionsService.parse(action?.id || '');

    // TODO: Breaks ESLint server
    return actions.filter((a) => a.name === name && (a.id === action?.id || a.actionType !== 'Inline'));
  }
}
