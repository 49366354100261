import type { Field } from '@data-driven-forms/react-form-renderer';
import type { FormOptions, ITextField } from '@oms/frontend-foundation';
import type { RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';

export const ROUTE_ORDER_INVESTOR_ORDER_IS_SYMBOL_ENABLED = 'routeOrderInvestorOrderIsSymbolEnabled';

export const routeOrderInvestorOrderIsSymbolEnabled =
  () =>
  (_props: Field, _field: Field, formOptions: FormOptions): Partial<ITextField> => {
    const formValues: Partial<RouteOrderFormValues> = formOptions.getState().values;
    const { hiddenMode } = formValues || {};

    switch (hiddenMode?.type) {
      case 'modify':
      case 'route': {
        return {
          isDisabled: true,
          forceIsDisabled: true
        };
      }
      default:
        return {};
    }
  };
