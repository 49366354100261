import type { FormOptions, UseFieldApiConfig, Field } from '@data-driven-forms/react-form-renderer';

export type DisableWhen = {
  fieldName: string;
  equals: any[];
  disableProps: string[];
  clearValueWhenDisabled: boolean;
};

export const disableWhen =
  (opts: DisableWhen) => (_field: Field, fieldApi: UseFieldApiConfig, formOptions: FormOptions) => {
    const { fieldName, equals, disableProps, clearValueWhenDisabled = false } = opts;
    const { getFieldState, change } = formOptions;
    const fieldState = getFieldState(fieldName);
    const shouldBeDisabled = equals.some((e) => e === fieldState?.value);
    const props: Record<string, boolean> = {};

    if (clearValueWhenDisabled && shouldBeDisabled && fieldApi.input.value) {
      setTimeout(() => change(fieldApi.input.name, undefined));
    }

    disableProps.forEach((p: string) => {
      props[p] = shouldBeDisabled;
    });

    return props;
  };
