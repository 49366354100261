import * as columns from '@app/widgets/trading/trading-order-monitor-table-server/shared-columns';

export const columnMapping = {
  generic: {
    default: columns.defaultCol,
    text: columns.text,
    numeric: columns.numeric,
    currency: columns.currency,
    dateTime: columns.dateTime,
    date: columns.date,
    timestamp: columns.timestamp
  },
  orders: {
    id: columns.id,
    side: columns.side,
    instrumentDisplayCode: columns.instrumentDisplayCode,
    instrumentLongName: columns.instrumentLongName,
    instrumentCusip: columns.instrumentCusip,
    instrumentId: columns.instrumentId,
    instrumentExchCode: columns.instrumentExchCode,
    price: columns.price,
    quantity: columns.quantity,
    settleType: columns.settleType,
    investorAccount: columns.investorAccount,
    timeInForce: columns.timeInForce,
    user: columns.user,
    status: columns.orderStatus,
    ownerId: columns.ownerId,
    orderType: columns.orderType
  },
  tradingOrders: {
    tradingOrderCapacity: columns.tradingOrderCapacity
  }
} as const;
