import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { FieldContract, IPriceInputField, ModifiedFieldForOptions } from '@oms/frontend-foundation';
import type { AnyRecord } from '@valstro/workspace';
import type { MinNumberVal } from '../../validators/min-number-value/min-number-value.validator';

export const createPriceAndOptionsField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IPriceInputField> & { minNumber?: Partial<MinNumberVal> }
) =>
  fc.field(key, 'price-input').options<IPriceInputField>({
    label: 'Price',
    selectAllOnFocus: true,
    ...overrides
  });
