import { Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import type { DependencyContainer } from 'tsyringe';
import { AppState } from '@app/data-access/memory/app.stream';
import type { Subscription } from 'rxjs';
import { NotificationsBackgroundService } from './notifications.background.service';

export interface NotificationsPluginOptions {
  container: DependencyContainer;
}

export const notificationsPlugin = ({ container }: NotificationsPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-notifications-plugin',
    pluginFn: ({ workspace }) => {
      let subscription: Subscription | undefined = undefined;
      let notificationsBackgroundService: NotificationsBackgroundService | undefined;

      workspace.addHook('leaderWindowReady', () => {
        const appState = container.resolve(AppState);

        subscription = appState.$.subscribe(({ state }) => {
          switch (state) {
            case 'Ready': {
              notificationsBackgroundService = container.resolve(NotificationsBackgroundService);
              notificationsBackgroundService.initialize();
              break;
            }
            case 'Disconnected':
            case 'Unauthorized': {
              notificationsBackgroundService?.dispose();
              break;
            }
          }
        });
      });

      return function unsubscribe() {
        notificationsBackgroundService?.dispose();
        subscription?.unsubscribe();
      };
    }
  });
