import { Observable } from 'rxjs';
import type { AnyRecord } from '@oms/frontend-foundation';
import type { Datasource } from '../models/datasource.model';

export class DatasourceBuilder<TData extends AnyRecord> {
  private _dataSourceConfig: Datasource<TData> = {} as Datasource<TData>;

  public rowId(cb: Datasource<TData>['rowId']): DatasourceBuilder<TData> {
    this._dataSourceConfig.rowId = cb;
    return this;
  }

  public source(
    data$:
      | NonNullable<Datasource<TData>['data$']>
      | NonNullable<Datasource<TData>['rowData']>
      | NonNullable<Datasource<TData>['serverSideDatasource']>
  ): DatasourceBuilder<TData> {
    if (Array.isArray(data$)) {
      this._dataSourceConfig.rowData = data$;
      this._dataSourceConfig.rowModelType = 'clientSide';
    } else if (data$ instanceof Observable) {
      this._dataSourceConfig.data$ = data$;
      this._dataSourceConfig.rowModelType = 'clientSide';
    } else if ('getRows' in data$) {
      this._dataSourceConfig.serverSideDatasource = data$;
      this._dataSourceConfig.rowModelType = 'serverSide';
    }

    return this;
  }

  public cacheBlockSize(size: number): DatasourceBuilder<TData> {
    this._dataSourceConfig.cacheBlockSize = size;
    return this;
  }

  public maxBlocksInCache(blocks: number): DatasourceBuilder<TData> {
    this._dataSourceConfig.maxBlocksInCache = blocks;
    return this;
  }

  public rowBuffer(buffer: number): DatasourceBuilder<TData> {
    this._dataSourceConfig.rowBuffer = buffer;
    return this;
  }

  public cacheOverflowSize(size: number): DatasourceBuilder<TData> {
    this._dataSourceConfig.cacheOverflowSize = size;
    return this;
  }

  public build(): Datasource<TData> {
    const { data$, rowId, serverSideDatasource, rowData } = this._dataSourceConfig;

    // Bypass checking for row ID if server-side tree data as row ID conflicts
    if (!rowId) {
      throw this.error('A datasource requires a row id.');
    }

    if (!data$ && !serverSideDatasource && !rowData) {
      throw this.error('Please provide a datasource.');
    }

    return this._dataSourceConfig;
  }

  // 👁️ Private ---------------------------------------------------- /

  private error(message: string) {
    return new Error(`[DatasourceBuilder] ${message}`);
  }
}
