import { forwardRef } from 'react';
import type { FC, RefAttributes, ForwardRefExoticComponent } from 'react';
import clsx from 'clsx';

type HTMLType = 'div' | 'span' | 'button' | 'p' | 'section' | 'header' | 'footer' | 'a' | 'ul' | 'li' | 'ol';

export function styled<Props extends { className?: string }>(
  Component: FC<any> | HTMLType,
  className: string,
  defaultProps?: Props
): FC<Props> {
  return ({ className: classNameProp, ...props }) => (
    <Component
      {...props}
      {...(defaultProps ? defaultProps : {})}
      className={clsx(className, classNameProp)}
    />
  );
}

export function styledForwardRef<Props = undefined, TElement = HTMLDivElement>(
  Component: FC<any> | HTMLType,
  className?: string,
  defaultProps?: Props
) {
  return forwardRef<TElement, Props & { className?: string }>(
    ({ className: classNameProp, ...props }, ref) => (
      <Component
        {...props}
        {...(defaultProps ? defaultProps : {})}
        ref={ref}
        className={clsx(className, classNameProp)}
      />
    )
  ) as ForwardRefExoticComponent<Props & RefAttributes<TElement>>;
}
