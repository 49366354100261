import { createContext, useContext } from 'react';
import { Actor, ActorSchema, AnyActorSchema } from '@valstro/workspace';

export interface ReactCurrentActorContext<T extends AnyActorSchema> {
  actor: Actor<T>;
  hierarchy: Actor[];
  leaderActor: Actor;
}

export const ReactCurrentActorContext = createContext<ReactCurrentActorContext<ActorSchema>>(
  {} as ReactCurrentActorContext<ActorSchema>
);

/**
 * Use the current actor (based on context set by ReactActorComponent)
 *
 * @returns Actor - The current actor
 */
export const useCurrentActor = <T extends AnyActorSchema>() =>
  useContext(ReactCurrentActorContext).actor as ReactCurrentActorContext<T>['actor'];

/**
 * Use the current actor hierachy (based on context set by ReactActorComponent)
 * This is an array of all actors from the root actor to the current actor
 *
 * @returns Actor - The current actor hierachy
 */
export const useCurrentActorHierachy = () => useContext(ReactCurrentActorContext).hierarchy;
