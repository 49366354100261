import { type FC } from 'react';
import type { AlertBannerInternalProps, IconColor } from './types.internal';
import { useClassName } from './util.internal';
import { Box } from '../../system/components/box/box';
import { Icon } from '../icon/icon';

type AlertBannerIconProps = Pick<AlertBannerInternalProps, 'title' | 'icon'> & { color: IconColor };

export const AlertBannerIcon: FC<AlertBannerIconProps> = ({ title, icon, color }) => {
  const className = useClassName('iconOuter');
  const iconClassName = useClassName('icon');

  return (
    <Box className={className}>
      <Icon as={icon} label={title} className={iconClassName} suppressDefaultBoxSize={true} sx={{ color }} />
    </Box>
  );
};

export default AlertBannerIcon;
