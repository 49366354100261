import type { Field, UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import type { EnhancedFormOptions } from '@oms/frontend-foundation';
import type { TradeModifyValues } from '../trade-modify.form-contract';
import { TradeModifyEntryType } from '../trade-modify.form-common';
import { RepairCategory } from '@oms/generated/frontend';

export const TRADE_FIELDS_DISABLE_ACTION = 'tradeFieldsDisableAction';
export const TRADE_FIELDS_DISABLE_BY_REPAIR_MODE_ACTION = 'tradeFieldsDisableByRepairModeAction';

export const tradeFieldsDisableAction =
  () =>
  (_field: Field, _fieldApi: UseFieldApiConfig, formOptions: EnhancedFormOptions<TradeModifyValues>) => {
    const { getState } = formOptions;
    const { values } = getState();
    const isDisabledForModify = values.hiddenFormInfo?.type === TradeModifyEntryType.TradeModify;
    const isDisabledForRepair =
      values.hiddenFormInfo?.type === TradeModifyEntryType.TradeRepair &&
      values.hiddenFormInfo?.tradeRepair?.category === RepairCategory.TradeModify;

    return {
      isDisabled: isDisabledForModify || isDisabledForRepair
    };
  };

export const tradeFieldsDisableByRepairModeAction =
  () =>
  (_field: Field, _fieldApi: UseFieldApiConfig, formOptions: EnhancedFormOptions<TradeModifyValues>) => {
    const { getState } = formOptions;
    const { values } = getState();

    return {
      isDisabled: values.hiddenFormInfo?.type === TradeModifyEntryType.TradeRepair
    };
  };
