/**
 * Bound keys, for action triggering in numeric input
 */
export enum KeyBinding {
  NUMPAD_ADD = 'add',
  NUMPAD_SUBTRACT = 'subtract',
  NUMPAD_MULTIPLY = 'multiply',
  NUMPAD_DIVIDE = 'divide',
  META = 'metaKey',
  CTRL = 'ctrlKey',
  SHIFT = 'shiftKey',
  ALT = 'altKey'
}

/**
 * Input action types
 */
export enum NumericInputActionType {
  NUMBER = 'NUMBER',
  SHORTCUT = 'SHORTCUT',
  DECIMAL = 'DECIMAL',
  THOUSANDS = 'THOUSANDS',
  MINUS = 'MINUS',
  UNKNOWN = 'UNKNOWN',
  BACKSPACE = 'BACKSPACE',
  DELETE = 'DELETE',
  UNDO = 'UNDO',
  REDO = 'REDO'
}

export enum DragState {
  NONE = 'NONE',
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL'
}

/**
 * The possible range of values that the value can take.
 * Default: ALL
 */
export enum NumericInputRange {
  /**
   * Number can take any value
   */
  ALL = 'ALL',
  /**
   * Number can only be positive
   */
  POSITIVE = 'POSITIVE'
}
