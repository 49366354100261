import { useEffect, useRef } from 'react';

export const useSyncDepthScroll = () => {
  const hasListeners = useRef(false);
  const ref = useRef<HTMLDivElement>(null);
  const bidRef = useRef<HTMLDivElement>(null);
  const askRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const abort = new AbortController();

    let isSyncingScroll = false;

    const observer = new MutationObserver(() => {
      const bidScrollContainer = bidRef.current?.querySelector('.ag-body-viewport');
      const askScrollContainer = askRef.current?.querySelector('.ag-body-viewport');

      if (bidScrollContainer && askScrollContainer && !hasListeners.current) {
        const syncScroll = (source: Element, target: Element) => {
          if (!isSyncingScroll) {
            isSyncingScroll = true;
            target.scrollTo({ top: source.scrollTop });
            isSyncingScroll = false;
          }
        };

        askScrollContainer.addEventListener(
          'scroll',
          () => syncScroll(askScrollContainer, bidScrollContainer),
          { signal: abort.signal }
        );

        bidScrollContainer.addEventListener(
          'scroll',
          () => syncScroll(bidScrollContainer, askScrollContainer),
          { signal: abort.signal }
        );

        hasListeners.current = true;
      }
    });

    observer.observe(ref.current, { childList: true });

    return () => {
      observer.disconnect();
      abort.abort();
    };
  }, []);

  return [ref, bidRef, askRef] as const;
};
