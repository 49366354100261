import { useAddToolbarActions } from '@app/common/toolbar/hooks/use-add-toolbar-action.hook';
import AddNewPositionsAccountTabButton from './add-new-positions-account-tab-button.component';

export const usePositionsAccountToolbarActions = () => {
  useAddToolbarActions(
    [
      {
        location: 'flexlayout-tabset',
        id: 'add-new-positions-account-tab',
        component: AddNewPositionsAccountTabButton
      }
    ],
    []
  );
};

export default usePositionsAccountToolbarActions;
