import { AnyRecord, StandardActorEvent, createEventsTransport } from '@valstro/workspace';
import { OpenPopoverOptions } from './popover.contracts';

export const POPOVER_EVENT = {
  TOGGLE_POPOVER: 'popover/toggle',
  OPEN_POPOVER: 'popover/open',
  CANCEL_OPEN_POPOVER: 'popover/open/cancel',
  OPENED_POPOVER: 'popover/opened',
  CLOSE_POPOVER: 'popover/close',
  CLOSED_POPOVER: 'popover/closed',
  POPOVER_COMPONENT_PROPS_UPDATE: 'popover/component-props/update',
  POPOVER_CUSTOM_EVENT: 'popover/custom-event'
} as const;

export type TogglePopoverEvent = {
  type: typeof POPOVER_EVENT.TOGGLE_POPOVER;
  payload: OpenPopoverOptions;
};

export type OpenPopoverEvent = {
  type: typeof POPOVER_EVENT.OPEN_POPOVER;
  payload: OpenPopoverOptions;
};

export type OpenedPopoverEvent = {
  type: typeof POPOVER_EVENT.OPENED_POPOVER;
  payload: OpenPopoverOptions;
};

export type ClosePopoverEvent = {
  type: typeof POPOVER_EVENT.CLOSE_POPOVER;
  payload: {
    popoverId: string;
    windowId: string;
  };
};

export type ClosedPopoverEvent = {
  type: typeof POPOVER_EVENT.CLOSED_POPOVER;
  payload: {
    popoverId: string;
    windowId: string;
  };
};

export type UpdatePopoverComponentPropsEvent<T extends AnyRecord = AnyRecord> = {
  type: typeof POPOVER_EVENT.POPOVER_COMPONENT_PROPS_UPDATE;
  payload: {
    popoverId: string;
    windowId: string;
    method: 'set' | 'merge';
    componentProps: T;
  };
};

export type PopoverCustomEvent<TEvent extends StandardActorEvent = StandardActorEvent> = {
  type: typeof POPOVER_EVENT.POPOVER_CUSTOM_EVENT;
  payload: {
    event: TEvent;
    popoverId: string;
    windowId: string;
  };
};

export type PopoverEvents =
  | TogglePopoverEvent
  | OpenPopoverEvent
  | OpenedPopoverEvent
  | ClosePopoverEvent
  | ClosedPopoverEvent
  | UpdatePopoverComponentPropsEvent
  | PopoverCustomEvent;

export const popoverEventsTransport = createEventsTransport<PopoverEvents>('popover-events');
