import { Plugin } from '@valstro/workspace';
import { DEFAULT_FORM_MAPPERS } from '../forms/workspace/form.workspace.mappers';
import type { FoundationFormMappers } from '../forms/common/form.contracts';
import { type DependencyContainer } from 'tsyringe';
import type { FoundationWorkspace } from './workspace.common';

export interface FoundationWorkspacePluginOptions extends Partial<FoundationFormMappers> {
  container: DependencyContainer;
}

export const foundationWorkspacePlugin = ({
  actionMapper = {},
  advancedSelectQueryMapper = {},
  componentMapper = {},
  validatorMapper = {},
  conditionMapper = {},
  formBuilderTemplateMapper = {},
  formBuilderMapper: _formBuilderMapper = {},
  container
}: FoundationWorkspacePluginOptions) =>
  Plugin.create<FoundationWorkspace>({
    name: 'valstro-foundation-plugin',
    pluginFn: ({ workspace }) => {
      workspace.updateMeta({
        container
      });

      const formBuilderMapper: FoundationFormMappers['formBuilderMapper'] = {
        ...DEFAULT_FORM_MAPPERS.formBuilderMapper,
        ..._formBuilderMapper
      };

      workspace.updateMeta({
        formMappers: {
          componentMapper: {
            ...DEFAULT_FORM_MAPPERS.componentMapper,
            ...componentMapper
          },
          validatorMapper: {
            ...DEFAULT_FORM_MAPPERS.validatorMapper,
            ...validatorMapper
          },
          actionMapper: {
            ...DEFAULT_FORM_MAPPERS.actionMapper,
            ...actionMapper
          },
          conditionMapper: {
            ...DEFAULT_FORM_MAPPERS.conditionMapper,
            ...conditionMapper
          },
          advancedSelectQueryMapper: {
            ...DEFAULT_FORM_MAPPERS.advancedSelectQueryMapper,
            ...advancedSelectQueryMapper
          },
          formBuilderTemplateMapper: {
            ...DEFAULT_FORM_MAPPERS.formBuilderTemplateMapper,
            ...formBuilderTemplateMapper
          },
          formBuilderMapper
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return function unsubscribe() {};
    }
  });
