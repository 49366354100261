import { Box, type BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import type { Sprinkles } from '../../system/sprinkles.css';

export type FlexProps = {
  /**
   * Short for flexDirection
   */
  direction?: Sprinkles['flexDirection'];
  /**
   * Short for alignItems
   */
  align?: Sprinkles['alignItems'];
  /**
   * Short for justifyContent
   */
  justify?: Sprinkles['justifyContent'];
  /**
   * Short for flex
   */
  wrap?: Sprinkles['flexWrap'];
  /**
   * Short for flex grow
   */
  grow?: Sprinkles['flexGrow'];
  /**
   * Short for flex shrink
   */
  shrink?: Sprinkles['flexShrink'];
} & BoxProps;

export const Flex = polymorphicComponent<'div', FlexProps>((props, ref) => {
  const { as, children, direction, align, justify, grow, shrink, wrap, sx = {}, ...rest } = props;

  return (
    <Box
      as={as}
      sx={{
        display: 'flex',
        flexDirection: direction,
        alignItems: align,
        justifyContent: justify,
        flexWrap: wrap,
        flexGrow: grow,
        flexShrink: shrink,
        ...sx
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </Box>
  );
});

if (__DEV__) {
  Flex.displayName = 'Flex';
}
