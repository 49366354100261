import type { LoggerOptions } from './logger/logger.types';
import Logger from './logger/logger.class';

/**
 * Creates labeled debug logger that can be enabled or disabled.
 *
 * ```ts
 * const l = createLogger({ label: 'MyLogger' });
 * l('warn', 'Hello, World!'); // (WARN) [MyLogger] Hello, World!
 * l.info('Hello, World!'); // (INFO) [MyLogger] Hello, World!
 * ```
 *
 * @param label - Prepends all logs with this label
 * @param options.initAsDisabled - Disables logs on init, but enabled status can be toggled as needed... Note that errors will show even when disabled unless explicitly suppressed with `suppressErrorLogs`
 * @param options.suppressConsoleLogs - Suppresses logging to console without fully disabling
 * @param options.suppressErrorLogs - By default, logs with "error" level will logged even if disabled. However, you can explicity suppress them with this flag.
 * @param options.suppressLogLevelPrefix - Disables the log level prefixed at the beginning of each log
 * @param options.suppressTimestamp - Disables the timestamp prefixed at the beginning of each log
 * @returns A logger instance function to use for debugging
 */
export function createLogger(options?: LoggerOptions): Logger {
  return Logger.create(options);
}

/**
 * @deprecated Use `Logger` static constructors.
 *
 * A quick way to spin up a default
 */
export const logger = {
  debug: Logger.debug(),
  as: (label: string) => Logger.labeled(label)
};
