import { openCancelInvestorOrderDialog } from '@app/common/types/orders/investor-order/open.cancel.investor-order';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { InvestorOrderStatus } from '@oms/generated/frontend';
import type { VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment } from '@oms/generated/frontend';

function getActiveCancelRows(rowData: IOFragment[]) {
  return rowData.filter((selectedRow) => selectedRow.status === InvestorOrderStatus.Active);
}

export const cancelInvestorOrderOnChange = async (
  ctx: ActionContext<IOFragment, ActionComponentConfig<IOFragment>>
) => {
  const { lifecycle, data } = ctx;
  const activeRows = getActiveCancelRows(data);

  ctx.notify({ isDisabled: activeRows.length === 0 });

  if (lifecycle === 'change') {
    const activeIOIds = activeRows.map((row) => row.id);
    await openCancelInvestorOrderDialog({
      workspace: ctx.workspace,
      container: ctx.appContainer,
      windowId: ctx.windowActor.id,
      investorOrderIds: activeIOIds
    });
  }
};

export const cancelInvestorOrderAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('cancel_investor_order')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('cancel_investor_order_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Cancel'
        })
    )
    .customMenu((m) => m.name('Cancel').visible(({ rowData }) => getActiveCancelRows(rowData).length > 0))
    .onChange<ActionComponentConfig<IOFragment>>(cancelInvestorOrderOnChange);
