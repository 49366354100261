import React, { useMemo } from 'react';
import {
  PopoverTrigger,
  type PopoverTriggerProps,
  usePopoverComponentProps
} from '@valstro/workspace-react-plugin-popover';
import { POPOVER } from '../popover.config';
import { Box, Text } from '@oms/shared-frontend/ui-design-system';

export type TooltipTriggerProps = Omit<PopoverTriggerProps, 'ariaType' | 'componentId'> & {
  title: string;
  description?: string;
};

export const TooltipTrigger = React.forwardRef<HTMLButtonElement, TooltipTriggerProps>(
  (
    {
      asChild,
      width = 200,
      height = 120,
      side = 'bottom',
      align = 'center',
      sideOffset = 2,
      autoSizeWidth = true,
      autoSizeHeight = true,
      trigger = 'hover',
      title,
      description,
      ...props
    },
    forwardedRef
  ) => {
    const componentProps = useMemo(
      () => ({
        title,
        description
      }),
      [title, description]
    );

    return (
      <PopoverTrigger
        ref={forwardedRef}
        ariaType="dialog"
        componentId={POPOVER.TOOLTIP}
        componentProps={componentProps}
        side={side}
        align={align}
        sideOffset={sideOffset}
        width={width}
        height={height}
        autoSizeWidth={autoSizeWidth}
        autoSizeHeight={autoSizeHeight}
        trigger={trigger}
        asChild={asChild}
        {...props}
      />
    );
  }
);

export interface PopoverTooltipComponentProps {
  title: string;
  description?: string;
}

export function PopoverTooltipComponent() {
  const { title, description } = usePopoverComponentProps<PopoverTooltipComponentProps>();

  const justTitle = !description;

  return (
    <Box sx={{ p: 2, overflow: 'hidden' }}>
      <Text as="div" type={justTitle ? 'baseR' : 'baseB'} isTruncated={true}>
        {title}
      </Text>
      {description && (
        <Text as="div" type="baseR" sx={{ color: 'text.minor' }}>
          {description}
        </Text>
      )}
    </Box>
  );
}
