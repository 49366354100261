import { PrincipalFillCapacity, TagTypeEnum } from '@oms/generated/frontend';
import type { PrincipalFillFormContract } from './principal-fill.contracts';
import { createAccountField } from '@app/forms/common/fields/account-field/account.field.common';
import { FieldContract } from '@oms/frontend-foundation';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { createSymbolField } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import { createTagsField } from '@app/forms/common/fields/tags-field/tags.common';

const ofc = FieldContract.create<PrincipalFillFormContract, AdditionalFormFieldUnion>();
export const regNmsApplicability = ofc.field('regNmsApplicability', 'hidden-field');

export const firmAccount = createAccountField(ofc, 'firmAccount', {
  isRequired: true,
  query: ADVANCED_SELECT_QUERY_ENUM.VISIBLE_FIRM_ACCOUNTS_WATCH_QUERY
});

export const instrument = createSymbolField(ofc, 'instrument', {
  isRequired: true
});

export const capacity = ofc.field('capacity', 'select').options({
  label: 'Capacity',
  options: [
    { label: 'Riskless', value: PrincipalFillCapacity.RisklessPrincipal },
    { label: 'Principal', value: PrincipalFillCapacity.Principal }
  ]
});

export const quantity = ofc.field('quantity', 'number-format').options({
  label: 'Fill Qty',
  format: 'quantity'
});

export const price = ofc.field('price', 'number-format').options({
  label: 'Price'
});

export const investorOrders = ofc.field('investorOrders', 'principal-fill').options({});

export const tradeDateTime = ofc.field('tradeDateTime', 'native-date-picker').options({
  label: 'Trade date time',
  timePicker: true
});

export const locate = ofc.field('locate', 'text-field').options({
  label: 'Locate'
});

export const tradeTagIds = createTagsField(ofc, 'tradeTagIds', {
  label: 'Trade Tags',
  queryProps: {
    filters: [TagTypeEnum.Trade]
  }
});

export const tradeThrough = ofc
  .field('tradeThrough', 'trade-through-obligation')
  .options({ initialValue: { allowSweep: true } });
