import React, { useMemo } from 'react';
import {
  PopoverTrigger,
  type PopoverTriggerProps,
  usePopoverComponentProps
} from '@valstro/workspace-react-plugin-popover';
import { POPOVER } from '../popover.config';
import {
  DisplayGrid,
  type DisplayGridItemProps,
  type GridProps
} from '@oms/shared-frontend/ui-design-system';

export type AppDisplayGridPopoverTriggerProps = Omit<PopoverTriggerProps, 'ariaType' | 'componentId'> & {
  items: DisplayGridItemProps[];
  gridProps: GridProps;
  testId?: string;
};

export const AppDisplayGridPopoverTrigger = React.forwardRef<
  HTMLButtonElement,
  AppDisplayGridPopoverTriggerProps
>(({ asChild, testId, items = [], gridProps = {}, ...props }, forwardedRef) => {
  const componentProps = useMemo(
    () => ({
      items,
      gridProps
    }),
    [items, gridProps]
  );

  return (
    <PopoverTrigger
      ref={forwardedRef}
      ariaType="dialog"
      popoverTestId={testId}
      componentId={POPOVER.DISPLAY_GRID}
      componentProps={componentProps}
      side="bottom"
      align="center"
      sideOffset={props.sideOffset || 2}
      width={props.width || 180}
      height={props.height || 100}
      trigger="hover"
      asChild={asChild}
      {...props}
    />
  );
});

export interface PopoverAppDisplayGridComponentProps {
  gridProps: GridProps;
  items: DisplayGridItemProps[];
}

export function PopoverAppDisplayGridComponent() {
  const { gridProps = { columns: 1 }, items = [] } =
    usePopoverComponentProps<PopoverAppDisplayGridComponentProps>();

  return <DisplayGrid items={items} gridProps={gridProps} sx={{ p: 3 }} />;
}
