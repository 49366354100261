import { z } from 'zod';
import { createVersionedSchema } from '@oms/frontend-schemas';

export default createVersionedSchema('MONTAGE', {
  version: 0,
  schema: z.object({
    instrumentTrackingEnabled: z
      .boolean()
      .optional()
      .describe('Current status of instrument tracking set by the toggle button in the toolbar'),
    instrumentId: z.string().optional().describe('Current instrument ID set by the dropdown in the toolbar'),
    investorAccountId: z
      .string()
      .optional()
      .describe('Current investor account ID set by the dropdown in the toolbar')
  })
});
