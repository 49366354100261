export const COMMAND_PALETTE_GROUP = {
  LAYOUTS: 'Layouts',
  NAVIGATE: 'Navigate',
  SYSTEM: 'System'
} as const;

export const COMMAND_PALETTE_GROUP_ORDER = [
  COMMAND_PALETTE_GROUP.NAVIGATE,
  COMMAND_PALETTE_GROUP.LAYOUTS,
  COMMAND_PALETTE_GROUP.SYSTEM
];
