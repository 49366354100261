import {
  ModalWindow,
  ReactBrowserModalWindowActorSchemaBuilder,
  useActorContext
} from '@valstro/workspace-react';
import {
  CommonPopoverActorSchema,
  PopoverContext,
  PopoverOperations,
  commonBrowserPopoverSchemaOverrides
} from '@valstro/workspace-plugin-popover';
import { ReactPopoverActorSchemaOptions, ReactPopoverActorViewType } from './popover.contracts';
import { PopoverComponent } from './popover.component';

export const REACT_BROWSER_POPOVER_ACTOR_NAME = 'react-browser-popover-window';

const View: ReactPopoverActorViewType = ({ actor }) => {
  const { isOpen } = useActorContext<CommonPopoverActorSchema>(actor);
  return (
    <ModalWindow<ReactBrowserPopoverActorSchema> actor={actor} isVisibleOverride={isOpen}>
      <PopoverComponent actor={actor} />
    </ModalWindow>
  );
};

export const ReactBrowserPopoverActorSchemaBuilder = ReactBrowserModalWindowActorSchemaBuilder.extend<
  PopoverContext,
  PopoverOperations,
  ReactPopoverActorSchemaOptions
>((prevSchema) => ({
  ...commonBrowserPopoverSchemaOverrides(prevSchema),
  name: REACT_BROWSER_POPOVER_ACTOR_NAME
})).extendView(View);

export const reactBrowserPopoverActor =
  ReactBrowserPopoverActorSchemaBuilder.optionsCreator<ReactPopoverActorSchemaOptions>();

export type ReactBrowserPopoverActorSchema = typeof ReactBrowserPopoverActorSchemaBuilder.schema;
