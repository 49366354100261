import { useEffect, useState } from 'react';
import type { Optional } from '@oms/shared/util-types';
import { useService } from '@oms/frontend-foundation';
import { MontageService } from '../montage.service';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';

export const useMontageInstrumentTrackingEnabled = (): Optional<boolean> => {
  const scopedActorId = useScopedActorId();
  const montageService = useService(MontageService);

  const [trackingEnabled, setTrackingEnabled] = useState<Optional<boolean>>(undefined);

  useEffect(() => {
    const subscription = montageService.watchInstrumentTrackingEnabled$(scopedActorId).subscribe((value) => {
      setTrackingEnabled(value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [scopedActorId, montageService]);

  return trackingEnabled;
};
