import type { LogLevel, OneOrMore } from '@oms/shared/util-types';
import { asArray } from '../../collections';
import LoggerState from './logger-state.internal.class';
import type { LoggerOptions, UnlabeledLoggerOptions } from './logger.types';

/**
 * A simple debug logger that defaults to logging to the console.
 */
export class Logger {
  protected state: LoggerState;

  //  Constructor ------------------------------------------------ /

  protected constructor(options?: LoggerOptions) {
    this.state = LoggerState.init(options);
  }

  /**
   * Creates debug logger that can be enabled or disabled.
   *
   * ```ts
   * const logger = Logger.create({ label: 'MyLogger' });
   * logger.logAs('warn', 'Hello, World!'); // (WARN) [MyLogger] Hello, World!
   * logger.info('Hello, World!'); // (INFO) [MyLogger] Hello, World!
   * ```
   *
   * @param options.label - Prepends all logs with this label
   * @param options.suppressConsoleLogs - Suppresses logging to console without fully disabling
   * @param options.suppressErrorLogs - By default, logs with "error" level will logged even if disabled. However, you can explicity suppress them with this flag.
   * @param options.suppressLogLevelPrefix - Disables the log level prefixed at the beginning of each log
   * @param options.suppressTimestamp - Disables the timestamp prefixed at the beginning of each log
   * @returns A logger instance to use for debugging
   */
  public static create(options?: LoggerOptions): Logger {
    return new Logger(options);
  }

  /**
   * Creates labeled debug logger that can be enabled or disabled.
   *
   * ```ts
   * const logger = Logger.labeled('MyLogger');
   * logger.logAs('warn', 'Hello, World!'); // (WARN) [MyLogger] Hello, World!
   * logger.info('Hello, World!'); // (INFO) [MyLogger] Hello, World!
   * ```
   *
   * @param label - Prepends all logs with this label
   * @param options.suppressConsoleLogs - Suppresses logging to console without fully disabling
   * @param options.suppressErrorLogs - By default, logs with "error" level will logged even if disabled. However, you can explicity suppress them with this flag.
   * @param options.suppressLogLevelPrefix - Disables the log level prefixed at the beginning of each log
   * @param options.suppressTimestamp - Disables the timestamp prefixed at the beginning of each log
   * @returns A logger instance function to use for debugging
   */
  public static labeled(label: string, options?: UnlabeledLoggerOptions): Logger {
    return new Logger({ label, ...(options ?? {}) });
  }

  // Presets ----------- /

  public static debug(options?: UnlabeledLoggerOptions): Logger {
    return Logger.labeled('DEBUG', options);
  }

  // 📢 Public ------------------------------------------------ /

  public get disabled(): boolean {
    return this.state.disabled ?? false;
  }

  public scope(scope: OneOrMore<string>): Logger {
    this.state.scope = asArray(scope);
    return this;
  }

  public logAs<Args extends Array<any>>(level: LogLevel, ...args: Args): Logger {
    if (this.state.disabled && level !== 'error') return this.resetScope();
    if (level === 'error' && this.state.suppressErrorLogs) return this.resetScope();
    if (!this.state.suppressConsoleLogs) {
      console[level](this.buildPrefix(level), ...args);
    }
    return this.resetScope();
  }

  public info<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('info', ...args);
  }

  public debug<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('debug', ...args);
  }

  public log<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('log', ...args);
  }

  public warn<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('warn', ...args);
  }

  public error<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('error', ...args);
  }

  public disable(): Logger {
    this.state.disabled = true;
    return this;
  }

  public enable(): Logger {
    if (this.state.disabled) this.state.resetDisabled();
    return this;
  }

  public changeLabel(label: string): Logger {
    this.state.label = label;
    return this;
  }

  public resetLabel(): Logger {
    this.state.resetLabel();
    return this;
  }

  public reset(): Logger {
    this.state.resetAll();
    return this;
  }

  // 🔒 Protected / private ------------------------------------------------ /

  protected resetScope(): Logger {
    if (this.state.scope) this.state.resetScope();
    return this;
  }

  protected buildPrefix(level: LogLevel): string {
    return [
      !this.state.suppressTimestamp ? `${new Date().toISOString()}: ` : '',
      !this.state.suppressLogLevelPrefix ? `(${level.toUpperCase()}) ` : '',
      this.state.label || this.state.scope
        ? `[${this.buildScopedLabel(this.state.label, this.state.scope)}]:`
        : ''
    ].join('');
  }

  protected buildScopedLabel(label?: string, scope?: string[]): string {
    return [label, ...(scope ?? [])]
      .reduce((scopedLabel, component) => {
        if (component) scopedLabel.push(component);
        return scopedLabel;
      }, [] as string[])
      .join('.');
  }
}

export default Logger;
