import { FORM_RENDERER_EVENT_TYPE } from '@oms/frontend-foundation';
import type {
  FormBuilderEventSanitizedValuesChanged,
  FormBuilderEventSubmit,
  FormBuilderChangeContext
} from '@oms/frontend-foundation';
import type { CrossPrincipalFormValues } from './cross-principal-fill.form-contract';
import type { CrossPrincipalFillOutput } from './cross-principal-fill.form-common';
import { CrossPrincipalFillService } from '@app/data-access/services/trading/cross-principal-fill/cross-principal-fill.service';
import { enrichFieldValues } from './cross-principal-fill.util';
import type { CrossTradeRequestResponseEnvelope } from '@oms/generated/frontend';
import { xor } from 'lodash';

type FormCtx = FormBuilderChangeContext<CrossPrincipalFillOutput, CrossPrincipalFormValues>;
type SanitizedValuesChangedEvent = FormBuilderEventSanitizedValuesChanged<
  CrossPrincipalFormValues,
  CrossPrincipalFillOutput
>;
type SubmitEvent = FormBuilderEventSubmit<CrossPrincipalFillOutput, CrossPrincipalFormValues>;

export const handleSanitizedValuesChange = async (event: SanitizedValuesChangedEvent, ctx: FormCtx) => {
  const service = ctx.container.resolve(CrossPrincipalFillService);
  const output = event.payload.output;
  const diff = event.payload.formValuesDiff;
  const formValues = event.payload.formValues;
  const allowSweepChanged =
    event.payload.prevFormValues.tradeThrough?.allowSweep !== formValues.tradeThrough?.allowSweep;
  const tradeTagsChanged = xor(event.payload.prevFormValues.tradeTags, formValues.tradeTags).length !== 0;
  const tradeDateTimeChanged = event.payload.prevFormValues.tradeDateTime !== formValues.tradeDateTime;

  if (!output) return;

  const ordersAvailable =
    !!output?.crossTrade.buyOrdersInfo.length || !!output.crossTrade.sellOrdersInfo.length;

  // we want to make a dryRun call only if quantity, limitPrice, excluded orders or tradeDateTime get updated
  if (
    !(
      ordersAvailable &&
      (diff.quantity ||
        diff.limitPrice ||
        diff.orders ||
        tradeDateTimeChanged ||
        allowSweepChanged ||
        tradeTagsChanged)
    )
  ) {
    return;
  }

  const result = await service.requestCrossTrade({
    ...output,
    dryRun: true
  });

  if (result.isSuccess()) {
    const response = result.value.data?.requestCrossTrade as CrossTradeRequestResponseEnvelope;

    const fieldValues = enrichFieldValues(response, output, formValues);

    if (fieldValues) {
      ctx.notify({
        type: FORM_RENDERER_EVENT_TYPE.SET_FIELD_VALUES,
        payload: {
          fieldValues
        }
      });
    }

    if (response?.feedback) {
      ctx.notify({
        type: FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK,
        payload: {
          feedback: response.feedback
        }
      });
    }
  }

  return result;
};

export const handleSubmit = async (event: SubmitEvent, ctx: FormCtx) => {
  const service = ctx.container.resolve(CrossPrincipalFillService);
  const output = event.payload.output;

  const result = await service.requestCrossTrade({
    ...output,
    dryRun: false
  });

  if (result.isSuccess()) {
    const response = result.value.data?.requestCrossTrade;

    if (response?.feedback) {
      ctx.notify({
        type: FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK,
        payload: {
          feedback: response.feedback
        }
      });
    }
  }

  return result;
};
