import { CellRendererSelectorResult, ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';
import { emptyGroupRenderer } from '../renderers/cell-renderers/empty-group-renderer';
import {
  createDetailCellRenderer,
  type CustomDetailRendererGetPropsCallback,
  type CustomDetailRendererGetRowHeightParamsCallback,
  type DetailCellRendererParams
} from '../renderers/cell-renderers/detail-cell-renderer';
import { type AnyRecord } from '@oms/frontend-foundation';
import { type ColumnBuilderField } from './column.builder.types';
import type { MemoExoticComponent } from 'react';

export class DetailGridBuilder<TData extends AnyRecord, TDetailProps extends AnyRecord> {
  private _masterDetailGridSettings: AgGridReactProps<TData> = {
    masterDetail: true,
    reactiveCustomComponents: true
  };

  private _groupByColumnId: ColumnBuilderField<TData> = '';
  private _groupByColDefOverrides: ColDef<TData, TDetailProps> = {};
  private _customRendererSettings: DetailCellRendererParams<TData, TDetailProps> = {};

  groupByColumn(
    colId: ColumnBuilderField<TData>,
    shouldShowGroup: (data: TData) => boolean
  ): DetailGridBuilder<TData, TDetailProps> {
    this._groupByColumnId = colId;
    this._groupByColDefOverrides = {
      cellRenderer: undefined,
      cellRendererSelector: (params: ICellRendererParams<any>): CellRendererSelectorResult => ({
        component: shouldShowGroup(params.data) ? 'agGroupCellRenderer' : emptyGroupRenderer
      })
    };

    return this;
  }

  headerComponent(
    HeaderComponent: (props: AnyRecord) => JSX.Element
  ): DetailGridBuilder<TData, TDetailProps> {
    this._customRendererSettings.HeaderComponent = HeaderComponent;
    return this;
  }

  detailComponent(
    DetailComponent:
      | MemoExoticComponent<(props: TDetailProps) => JSX.Element>
      | ((props: TDetailProps) => JSX.Element)
  ): DetailGridBuilder<TData, TDetailProps> {
    this._customRendererSettings.DetailComponent = DetailComponent;
    return this;
  }

  getDetailProps(
    getDetailProps: CustomDetailRendererGetPropsCallback<TData, TDetailProps>
  ): DetailGridBuilder<TData, TDetailProps> {
    this._customRendererSettings.getDetailProps = getDetailProps;
    return this;
  }

  getDetailRowHeightParams(
    getRowHeightParams: CustomDetailRendererGetRowHeightParamsCallback<TData>
  ): DetailGridBuilder<TData, TDetailProps> {
    this._masterDetailGridSettings.detailRowAutoHeight = true;
    this._customRendererSettings.getDetailRowHeightParams = getRowHeightParams;
    return this;
  }

  public build() {
    if (this._groupByColumnId === '') {
      throw new Error('colId is required');
    }

    return {
      masterDetailGridSettings: {
        ...this._masterDetailGridSettings,
        detailCellRenderer: createDetailCellRenderer<TData, TDetailProps>(this._customRendererSettings)
      },
      groupByColumnId: this._groupByColumnId,
      groupByColDefOverrides: this._groupByColDefOverrides
    };
  }
}
