import { useObservableState } from 'observable-hooks';
import { ComponentService } from '../services/component.service';
import { useGridContainer } from './grid.container.hook';
import { ComponentEvent, ComponentLocation, VComponent } from '../models/v.component.model';

export const useComponentService = (location: ComponentLocation): VComponent[] => {
  const container = useGridContainer();
  const componentContainerService = container.resolve(ComponentService);
  const renderEvent: ComponentEvent | undefined = useObservableState(
    componentContainerService.observe(location),
    undefined
  );

  return renderEvent ? renderEvent.components : componentContainerService.getComponents(location);
};
