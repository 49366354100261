import { useMemo } from 'react';
import { Box, type BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import mapValues from 'lodash/mapValues';
import type { ResponsiveValue, GridSpanColumns, GridSpanRows, Sprinkles } from '../../system/sprinkles.css';

type ResponsiveGridSpanColumns = ResponsiveValue<GridSpanColumns>;
type ResponsiveGridSpanRows = ResponsiveValue<GridSpanRows>;

type OmittedProps = 'display';

export type GridItemProps = {
  gap?: Sprinkles['gap'];
  colSpan?: ResponsiveValue<
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 'auto'
    | 'full'
  >;
  rowSpan?: ResponsiveValue<1 | 2 | 3 | 4 | 5 | 6 | 'auto' | 'full'>;
} & Omit<BoxProps, OmittedProps>;

export const GridItem = polymorphicComponent<'div', GridItemProps>((props, ref) => {
  const { as, children, colSpan, rowSpan, sx = {}, ...rest } = props;

  const gridProps = useMemo(() => {
    let gridColumn = colSpan as ResponsiveGridSpanColumns;
    let gridRow = rowSpan as ResponsiveGridSpanRows;

    if (colSpan) {
      gridColumn =
        typeof colSpan === 'object'
          ? mapValues(colSpan, (item) => `span ${item} / span ${item}`)
          : `span ${colSpan} / span ${colSpan}`;
    }

    if (rowSpan) {
      gridRow =
        typeof rowSpan === 'object'
          ? mapValues(rowSpan, (item) => `span ${item} / span ${item}`)
          : `span ${rowSpan} / span ${rowSpan}`;
    }

    return {
      gridColumn,
      gridRow
    };
  }, [colSpan, rowSpan]);

  return (
    <Box as={as} sx={{ ...gridProps, display: 'grid', ...sx }} {...rest} ref={ref}>
      {children}
    </Box>
  );
});

if (__DEV__) {
  GridItem.displayName = 'GridItem';
}
