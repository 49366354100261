import type { ReactActorComponentProps } from '@valstro/workspace-react';
import type { AuthClientState, AuthClientError, AuthClientEvent } from '@app/common/auth/keycloak.types';
import { COMMON_AUTH_WINDOW, DEFAULT_AUTH_STATE, AUTH_EVENT_ACTION } from '@app/common/auth/auth.contracts';
import type { CommonAuthWindowActorSchema } from '@app/common/auth/auth.contracts';
import { persistAuthState } from '@app/common/auth/auth.helpers';
import { keycloakInstance } from '@app/common/auth/keycloak.client-instance';
import { parseKeycloakToken, getUserRoles } from '@app/common/auth/keycloak.helpers';
import type Keycloak from 'keycloak-js';
import type { KeycloakInitOptions } from 'keycloak-js';
import { createLogger } from '@oms/shared/util';
import { Center } from '@oms/shared-frontend/ui-design-system';
import { filter } from 'rxjs';
import { container } from 'tsyringe';
import { AuthSignal } from '@app/data-access/memory/auth.signal';
import { useAuthState } from '@app/data-access/services/system/auth/auth.hooks';
import { IS_BROWSER } from '@app/common/workspace/workspace.constants';

/**
 * Auth Window Actor Logger
 */
export const authActorLogger = createLogger({ label: 'Auth Window Actor' });

/**
 * Common Schema
 */
type CommonAuthWindowActorSchemaOverrides = (
  prev: Omit<CommonAuthWindowActorSchema, 'view'>
) => Partial<Omit<CommonAuthWindowActorSchema, 'view'>>;

export const commonAuthWindowSchemaOverrides: CommonAuthWindowActorSchemaOverrides = (prevSchema) => ({
  ignoreFromTakeSnapshot: true,
  ignoreFromApplySnapshot: true,
  initialContext: async (ctx) => ({
    ...(await prevSchema.initialContext(ctx)),
    title: 'Authenticate',
    alwaysOnTop: true,
    isVisible: true,
    width: 422,
    height: 300,
    minWidth: 400,
    minHeight: 270,
    isClosable: true,
    skipTaskbar: true,
    isMaximizable: IS_BROWSER,
    isMinimizable: false,
    isMaximized: IS_BROWSER
  }),
  operations: (api, workspace) => {
    const prevOperations = prevSchema.operations(api, workspace);
    return {
      ...prevOperations,
      initialize: async (forceAuthState?: AuthClientState) => {
        /**
         * If the actor has a forceAuthState, return early
         * to avoid initializing keycloak (for testing)
         * Note: The auth state gets set in the events method below
         */
        if (forceAuthState) {
          await prevOperations.hide();
          await prevOperations.centerInActiveMonitor();
          return;
        }

        const defaultOptions: KeycloakInitOptions = {
          enableLogging: true,
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + `/silent-check-sso.html`,
          silentCheckSsoFallback: true
        };

        await keycloakInstance.init(defaultOptions);
      },
      startLogin: async () => {
        await Promise.all([prevOperations.setTitle('Login'), prevOperations.centerInActiveMonitor()]);
        await Promise.all([prevOperations.show(), keycloakInstance.login()]);
      },
      finishLogin: async () => {
        await prevOperations.hide();
        await prevOperations.centerInActiveMonitor();
      },
      logout: async () => {
        await keycloakInstance.logout();
      }
    };
  },
  events: async (ctx, workspace) => {
    const unsubPrevEvents = await prevSchema.events?.(ctx, workspace);
    const { operations } = ctx;
    let refreshTokenTimeout: number | undefined;

    // TODO: Do not use root container, use container from workspace meta when
    const authSignalService = container.resolve(AuthSignal);

    const actor = workspace
      .getActorRegistry()
      .getActorByType<CommonAuthWindowActorSchema>(COMMON_AUTH_WINDOW.TYPE);

    /**
     * If the actor has a forceAuthState, update the store and return
     * This is used for testing
     */
    if (actor?.meta?.forceAuthState) {
      authSignalService.signal.set((v) => ({
        ...v,
        ...actor?.meta?.forceAuthState,
        isReady: true
      }));

      return () => {};
    }

    /**
     * Set-up automatic token refresh function
     */
    const createTokenRefreshTimer = (expiryTime: number | undefined) => {
      if (!expiryTime) {
        return;
      }
      if (refreshTokenTimeout) {
        clearTimeout(refreshTokenTimeout);
      }
      const now = new Date().getTime() / 1000;
      const secondsUntilExpiry = expiryTime - now;
      const refreshTokenInSeconds = secondsUntilExpiry - 15;
      refreshTokenTimeout = window.setTimeout(() => {
        keycloakInstance.updateToken(refreshTokenInSeconds).catch(console.error);
      }, refreshTokenInSeconds * 1000);
    };

    /**
     * Sync instance events to the store
     */
    const onEvent = (keycloak: Keycloak) => {
      const keycloakEventHandler = async (event: AuthClientEvent, error?: AuthClientError) => {
        if (error) {
          authActorLogger.error('Keycloak Error', error);
        }

        switch (event) {
          case 'onReady': {
            if (!keycloak.authenticated) {
              authSignalService.signal.set((v) => ({
                ...v,
                lastAuthClientEvent: 'onReady',
                isReady: true
              }));
              await operations.startLogin();
            }

            break;
          }
          case 'onAuthRefreshSuccess':
          case 'onAuthSuccess': {
            createTokenRefreshTimer(keycloak.tokenParsed?.exp);
            const tokenParsed = parseKeycloakToken(keycloak);
            const roles = tokenParsed ? getUserRoles(tokenParsed) : [];
            authSignalService.signal.set((v) => ({
              ...v,
              isReady: true,
              isAuthenticated: true,
              lastAuthClientEvent: event,
              token: keycloak.token,
              tokenParsed: tokenParsed,
              idToken: keycloak.idToken,
              refreshToken: keycloak.refreshToken,
              sessionId: keycloak.sessionId,
              roles,
              expiry: tokenParsed?.exp ? tokenParsed.exp : null
            }));

            if (event === 'onAuthSuccess') {
              await operations.finishLogin();
            }

            break;
          }
          case 'onAuthRefreshError':
          case 'onAuthLogout':
          case 'onTokenExpired': {
            if (event === 'onTokenExpired') {
              console.error('Token expired, logging out');
            }

            if (event === 'onAuthRefreshError') {
              console.error('Token refresh error, logging out');
            }

            authSignalService.signal.set((v) => ({
              ...v,
              ...DEFAULT_AUTH_STATE,
              lastAuthClientEvent: event
            }));
            await operations.startLogin();
            break;
          }
        }
      };

      return keycloakEventHandler;
    };

    keycloakInstance.onReady = () => {
      onEvent(keycloakInstance)('onReady').catch(console.error);
    };

    keycloakInstance.onAuthSuccess = () => {
      onEvent(keycloakInstance)('onAuthSuccess').catch(console.error);
    };

    keycloakInstance.onAuthError = () => {
      onEvent(keycloakInstance)('onAuthError').catch(console.error);
    };

    keycloakInstance.onAuthRefreshSuccess = () => {
      onEvent(keycloakInstance)('onAuthRefreshSuccess').catch(console.error);
    };

    keycloakInstance.onAuthLogout = () => {
      onEvent(keycloakInstance)('onAuthLogout').catch(console.error);
    };

    keycloakInstance.onTokenExpired = () => {
      onEvent(keycloakInstance)('onTokenExpired').catch(console.error);
    };

    keycloakInstance.onAuthRefreshError = () => {
      onEvent(keycloakInstance)('onAuthRefreshError').catch(console.error);
    };

    /**
     * Listen to auth actions from other processes
     */
    const unsubAuth = authSignalService.action$
      .pipe(filter((a) => a === AUTH_EVENT_ACTION.LOGOUT))
      .subscribe(() => {
        authSignalService.signal.set((v) => ({
          ...v,
          ...DEFAULT_AUTH_STATE,
          lastAuthClientEvent: 'onAuthLogout'
        }));
        operations.logout().catch(console.error);
      });

    /**
     * Listen to authSignal & persist auth state
     */
    const unsubAuthPersist = authSignalService.signal.subscribe((v) => {
      persistAuthState(v);
    });

    return () => {
      unsubAuthPersist();
      unsubAuth.unsubscribe();

      if (unsubPrevEvents) {
        unsubPrevEvents();
      }

      if (refreshTokenTimeout) {
        clearTimeout(refreshTokenTimeout);
      }

      keycloakInstance.onReady = () => {};
      keycloakInstance.onAuthSuccess = () => {};
      keycloakInstance.onAuthError = () => {};
      keycloakInstance.onAuthRefreshSuccess = () => {};
      keycloakInstance.onAuthLogout = () => {};
      keycloakInstance.onTokenExpired = () => {};
    };
  }
});

export const CommonAuthWindowComponent: React.FC<
  ReactActorComponentProps<CommonAuthWindowActorSchema>
> = () => {
  const { isAuthenticated, isReady } = useAuthState();
  const isAuthenticating = !isAuthenticated && !isReady;

  return (
    <Center>
      {isAuthenticating ? (
        <div>Authenticating...</div>
      ) : isAuthenticated ? (
        <div>Authenticated</div>
      ) : (
        <div>Preparing...</div>
      )}
    </Center>
  );
};
