import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

export const NEGATIVE_NUM_CELL_RENDERER = 'negativeNumRenderer';

/**
 * ag-grid renderer to show a negative number in red
 */
export const negativeNumRenderer: React.FC<ICellRendererParams> = ({ value }) => (
  <span className={parseInt(value) < 0 ? 'negative' : ''}>{value}</span>
);
