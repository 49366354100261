import { AnyActorSchema, Actor, CreateActorDefinition } from '@valstro/workspace';
import { ReactActorComponentSelector } from './react-actor-component-selector';
import { useActorChildren } from './react-use-actor';
import { useRef, memo } from 'react';

export interface ReactActorComponentChildrenProps<T extends AnyActorSchema = AnyActorSchema> {
  actor: Actor<T>;
  filter?: (actor: CreateActorDefinition, index: number) => boolean;
}

const ReactActorComponentChildrenInner = memo(
  <T extends AnyActorSchema = AnyActorSchema>({
    actorChildren,
    filter
  }: {
    actorChildren: CreateActorDefinition[];
    filter: ReactActorComponentChildrenProps<T>['filter'];
  }) => {
    return actorChildren
      .filter((c) => {
        if (filter) {
          return filter(c, actorChildren.indexOf(c));
        }
        return true;
      })
      .map((child) => <ReactActorComponentSelector key={child.id} actorId={child.id} />);
  },
  (prev, next) => {
    // Only update if the children have been added/removed (not udpated)
    const childIds = prev.actorChildren
      .map((c) => c.id)
      .sort()
      .join(',');

    const nextChildIds = next.actorChildren
      .map((c) => c.id)
      .sort()
      .join(',');

    return childIds === nextChildIds;
  }
);

export const ReactActorComponentChildren = memo(
  <T extends AnyActorSchema = AnyActorSchema>({ actor, filter }: ReactActorComponentChildrenProps<T>) => {
    const filterRef = useRef(filter);
    const children = useActorChildren(actor);
    return <ReactActorComponentChildrenInner actorChildren={children} filter={filterRef.current} />;
  },
  (prev, next) => prev.actor.id === next.actor.id
);
