import { AnyRecord } from '@oms/frontend-foundation';
import { ComponentType } from 'react';

export enum ComponentLocation {
  HorizontalToolbarLeft = 'HorizontalToolbarLeft',
  HorizontalToolbarRight = 'HorizontalToolbarRight',
  LeftVerticalToolbar = 'LeftVerticalToolbar',
  RightVerticalToolbar = 'RightVerticalToolbar',
  Selection = 'Selection'
}

export type ComponentLocationKeys = keyof typeof ComponentLocation;

export type ComponentId = string | number;

export interface ComponentEvent<TProps extends AnyRecord = AnyRecord> {
  location: ComponentLocation;
  components: VComponent<TProps>[];
}

export interface VComponent<TProps extends AnyRecord = AnyRecord> {
  id: ComponentId;
  component?: ComponentType<any>;
  location?: ComponentLocation;
  props?: Partial<TProps>;
}
