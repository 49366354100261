import { isNil } from 'lodash';

import type {
  CoverageModel,
  CoverageModelInput,
  InvestorAccount,
  RepresentativeCodeInfoFragment
} from '@oms/generated/frontend';
import { CoverageCategory, CoverageModelFragment, CoverageModelType } from '@oms/generated/frontend';
import { compactMap } from '@oms/shared/util';

import type { CoverageDisplayInfo, CoveragePersonDisplayable } from './coverage.model';
import {
  convertCoverageModelForInput,
  getCoverageDisplayInfo,
  makeCoveragePersonDisplayable,
  simplifyInvestorAccount
} from './coverage.util';
import type { AnyInvestorAccount, SimpleInvestorAccount } from '@app/common/types/accounts/types';

export interface ExtendedCoverageModelObject extends CoverageModelFragment {
  [key: string]: unknown;
  [key: symbol]: unknown;

  investorAccount?: SimpleInvestorAccount;
  accountLabel?: string;
  accountId?: string;
  input?: CoverageModelInput;
  //
  primaryCoverage?: CoveragePersonDisplayable[];
  backupCoverage?: CoveragePersonDisplayable[];
  supportingCoverage?: CoveragePersonDisplayable[];
  //
  primaryCoverageInfo?: CoverageDisplayInfo;
  backupCoverageInfo?: CoverageDisplayInfo;
  supportingCoverageInfo?: CoverageDisplayInfo;
  //
  primaryCoverageLabel?: string;
  backupCoverageLabel?: string;
  supportingCoverageLabel?: string;

  isSendingDesk?: boolean;
  isDefault?: boolean;

  canAddSendingDesk: boolean;
  canAddUnderlyingAccount: boolean;
}

/**
 * Extension of base `CoverageModel` type with expanded capabilities and properties for the UI.
 */
export class ExtendedCoverageModel implements ExtendedCoverageModelObject {
  [key: string]: unknown;
  [key: symbol]: unknown;

  public readonly __typename: 'CoverageModel' = 'CoverageModel' as const;
  public readonly coveragePersons: CoveragePersonDisplayable[];
  public readonly id?: string;
  public readonly sendingDesk?: string;
  public readonly canAddSendingDesk: boolean;
  public readonly underlyingAccount?: string;
  public readonly canAddUnderlyingAccount: boolean;
  public readonly type: CoverageModelType;
  public readonly representativeCode?: RepresentativeCodeInfoFragment;
  public readonly isSendingDesk?: boolean;
  public readonly isDefault?: boolean;
  public readonly investorAccount?: SimpleInvestorAccount;

  public get accountLabel(): string {
    return this.investorAccount?.label ?? '';
  }

  public get accountId(): string | undefined {
    return this.investorAccount?.id;
  }

  public readonly primaryCoverage: CoveragePersonDisplayable[] = [];
  public readonly backupCoverage: CoveragePersonDisplayable[] = [];
  public readonly supportingCoverage: CoveragePersonDisplayable[] = [];

  public get primaryCoverageInfo(): CoverageDisplayInfo {
    return this.displayCoverageColumn('primary');
  }

  public get primaryCoverageLabel(): string {
    return this.primaryCoverageInfo.label;
  }

  public get backupCoverageInfo(): CoverageDisplayInfo {
    return this.displayCoverageColumn('backup');
  }

  public get backupCoverageLabel(): string {
    return this.backupCoverageInfo.label;
  }

  public get supportingCoverageInfo(): CoverageDisplayInfo {
    return this.displayCoverageColumn('supporting');
  }

  public get supportingCoverageLabel(): string {
    return this.supportingCoverageInfo.label;
  }

  public get input(): CoverageModelInput {
    return convertCoverageModelForInput(this);
  }

  #lookupById: Map<string, CoveragePersonDisplayable> = new Map();
  #lookupByUserId: Map<string, CoveragePersonDisplayable> = new Map();

  public constructor(
    coverageModel: CoverageModelFragment,
    investorAccount?: AnyInvestorAccount | SimpleInvestorAccount
  ) {
    const {
      coveragePersons,
      id,
      sendingDesk,
      underlyingAccount,
      type,
      representativeCode,
      isDefault,
      isSendingDesk
    } = coverageModel;
    this.canAddSendingDesk = false;
    this.canAddUnderlyingAccount = false;
    this.coveragePersons = coveragePersons
      ? compactMap(coveragePersons, (coveragePerson, index) => {
          if (!coveragePerson) return;
          const coveragePersonDisplayable = makeCoveragePersonDisplayable(coveragePerson, {
            fallbackLabel: `User ${index}`
          });
          const { id, userId, coverageCategory } = coveragePersonDisplayable;
          if (id) this.#lookupById.set(id, coveragePersonDisplayable);
          if (userId) this.#lookupByUserId.set(userId, coveragePersonDisplayable);
          switch (type) {
            case CoverageModelType.UserCoverage:
              switch (coverageCategory) {
                case CoverageCategory.UserPrimary:
                  this.primaryCoverage.push(coveragePersonDisplayable);
                  break;
              }
              break;
            case CoverageModelType.RestrictedCoverage:
              switch (coverageCategory) {
                case CoverageCategory.RestrictedPrimary:
                  this.primaryCoverage.push(coveragePersonDisplayable);
                  break;
                case CoverageCategory.RestrictedBackup:
                  this.backupCoverage.push(coveragePersonDisplayable);
                  break;
                case CoverageCategory.RestrictedSupport:
                  this.supportingCoverage.push(coveragePersonDisplayable);
                  break;
              }
              break;
            default:
              switch (coverageCategory) {
                case CoverageCategory.AccountPrimary:
                  this.primaryCoverage.push(coveragePersonDisplayable);
                  break;
                case CoverageCategory.AccountBackup:
                  this.backupCoverage.push(coveragePersonDisplayable);
                  break;
                case CoverageCategory.AccountSupport:
                  this.supportingCoverage.push(coveragePersonDisplayable);
                  break;
              }
          }
          return coveragePersonDisplayable;
        })
      : [];

    if (this.coveragePersons) {
      this.coveragePersons = this.reduceCoverageUsersToTeams(this.coveragePersons);
    }
    if (this.primaryCoverage) {
      this.primaryCoverage = this.reduceCoverageUsersToTeams(this.primaryCoverage);
    }
    if (this.backupCoverage) {
      this.backupCoverage = this.reduceCoverageUsersToTeams(this.backupCoverage);
    }
    if (this.supportingCoverage) {
      this.supportingCoverage = this.reduceCoverageUsersToTeams(this.supportingCoverage);
    }
    if (id) this.id = id;
    if (representativeCode) this.representativeCode = representativeCode;
    if (sendingDesk) this.sendingDesk = sendingDesk;
    if (underlyingAccount) this.underlyingAccount = underlyingAccount;
    this.type = type ?? CoverageModelType.AccountCoverage;

    if (investorAccount) {
      this.investorAccount = simplifyInvestorAccount(investorAccount);

      if ((investorAccount as InvestorAccount)?.coverageModels) {
        // check for existing UACMs
        const existingUnderlyingAccountCoverage: CoverageModel[] =
          ((investorAccount as InvestorAccount)?.coverageModels?.filter(
            (cm) => cm?.underlyingAccount !== '' && !isNil(cm?.underlyingAccount)
          ) as CoverageModel[]) || [];

        // we can only add a sending desk if no underlying account records exist
        this.canAddSendingDesk = existingUnderlyingAccountCoverage.length === 0;

        // check for existing SDCMs
        const existingSendingDeskCoverage: CoverageModel[] =
          ((investorAccount as InvestorAccount)?.coverageModels?.filter(
            (cm) => cm?.sendingDesk !== '' && !isNil(cm?.sendingDesk)
          ) as CoverageModel[]) || [];

        // we can only add an underlying account if no sending desk records exist
        this.canAddUnderlyingAccount = existingSendingDeskCoverage.length === 0;
      }
    }

    this.isDefault = Boolean(isDefault);
    this.isSendingDesk = Boolean(isSendingDesk);
  }

  /**
   * Returns a new array from `coveragePersons` with any entries that represent a team reduced to that team. Any
   * Entry that represents a user and not a team will be returned without any transformation.
   * @param coveragePersons
   * @private
   */
  private reduceCoverageUsersToTeams(
    coveragePersons: CoveragePersonDisplayable[]
  ): CoveragePersonDisplayable[] {
    const map: Map<string, CoveragePersonDisplayable> = new Map();
    coveragePersons.map(this.mapCoveragePersonToUserOrGroup).forEach((c) => {
      if (c.id) {
        map.set(c.id, c);
      }
    });
    return Array.from(map.values()) ?? [];
  }

  private mapCoveragePersonToUserOrGroup(c: CoveragePersonDisplayable): CoveragePersonDisplayable {
    // map CoveragePerson to Team
    if (c?.group?.id) {
      if (!c.group.name) {
        throw Error('Cannot set label for CoveragePerson team. group.name is missing');
      }
      return {
        ...c,
        id: c.group.id,
        user: undefined,
        userId: undefined,
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        username: undefined,
        label: c.group.name
      };
    } else {
      // map CoveragePerson to User
      return { ...c, id: c?.id ?? c.userId };
    }
  }

  /**
   * @param type - Specify the column heading you need to generate.
   * @returns An object of information needed to display a coverage column in the UI
   */
  public displayCoverageColumn(type: 'primary' | 'backup' | 'supporting'): CoverageDisplayInfo {
    const coverageGroup = (() => {
      switch (type) {
        case 'primary':
          return this.primaryCoverage;
        case 'backup':
          return this.backupCoverage;
        case 'supporting':
          return this.supportingCoverage;
      }
    })();
    return getCoverageDisplayInfo(coverageGroup, { coverageModelId: this.id });
  }

  public lookupPersonById(id: string): CoveragePersonDisplayable | undefined {
    return this.#lookupById.get(id);
  }

  public lookupPersonByUserId(userId: string): CoveragePersonDisplayable | undefined {
    return this.#lookupByUserId.get(userId);
  }

  public lookupPersonByUnknownId(value: string): CoveragePersonDisplayable | undefined {
    return this.#lookupById.get(value) ?? this.#lookupByUserId.get(value);
  }

  public toObject(): ExtendedCoverageModelObject {
    const {
      id,
      type,
      coveragePersons,
      sendingDesk,
      underlyingAccount,
      investorAccount,
      accountLabel,
      accountId,
      input,
      primaryCoverage,
      backupCoverage,
      supportingCoverage,
      primaryCoverageInfo,
      backupCoverageInfo,
      supportingCoverageInfo,
      representativeCode,
      isSendingDesk,
      isDefault,
      canAddSendingDesk,
      canAddUnderlyingAccount
    } = this;
    return {
      id,
      type,
      coveragePersons,
      sendingDesk,
      underlyingAccount,
      investorAccount,
      accountLabel,
      accountId,
      input,
      primaryCoverage,
      backupCoverage,
      supportingCoverage,
      primaryCoverageInfo,
      backupCoverageInfo,
      supportingCoverageInfo,
      primaryCoverageLabel: primaryCoverageInfo.label,
      backupCoverageLabel: backupCoverageInfo.label,
      supportingCoverageLabel: supportingCoverageInfo.label,
      representativeCode,
      isSendingDesk,
      isDefault,
      canAddSendingDesk,
      canAddUnderlyingAccount
    };
  }
}

export default ExtendedCoverageModel;
