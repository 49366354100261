import { Platform } from './platform';
import type { PlatformInfo } from './platform';
import { createLeaderElection, BroadcastChannel, isBroadcastChannelSimulated } from '@valstro/remote-link';
import { getBrowserMonitors } from './platform.browser.util';
import { logger } from './logger';

// Setup the leader elector
const leaderElectionChannel = new BroadcastChannel('leader-elector');
const leaderElector = createLeaderElection(leaderElectionChannel);

export const browserPlatform = Platform.create({
  name: 'browser',
  leaderElection: async () => {
    if (isBroadcastChannelSimulated()) {
      logger.debug('[browserPlatform] Simulating leader election. I am the leader.');
      return true;
    }
    const hasLeader = await leaderElector.hasLeader();
    if (hasLeader) {
      return false;
    }
    await leaderElector.awaitLeadership();
    return leaderElector.isLeader;
  },
  getPlatformInfo: async () => {
    // window.getScreenDetails();

    const info: PlatformInfo = {
      name: 'browser',
      version: navigator.userAgent,
      platform: navigator.platform,
      locale: navigator.languages ? navigator.languages[0] : navigator.language,
      monitors: await getBrowserMonitors()
    };

    return info;
  },
  getCurrentMonitor: async () => {
    return {
      name: null,
      width: window.screen.width,
      height: window.screen.height,
      x: 0,
      y: 0,
      scaleFactor: 1
    };
  }
});
