import Decimal from 'decimal.js';
import { roundUpNum } from './number.util';

const ROUNDING_STRATEGY = Decimal.ROUND_UP;
const GENERAL_INDICATOR_ROUNDING = {
  EQUAL_TO_OR_GREATER_THAN_ONE: 4,
  LESS_THAN_ONE: 6
};
/**
 * The purpose of this object:
 * - To offer type safety for mathematical operations.
 * - Correctly calculate floating-point numbers
 * - It is intended to be improved by integrating Bignumber.js
 * - by default, all numbers are rounded up to 2 decimal places.
 */
export const mathOp = {
  /**
   * Add up all numbers consecutively.
   * By default it is returned in 2 decimal places for floating point numbers.
   * @returns the result of all additions.
   */
  add(numbers: number[]): number {
    const result = numbers.reduce((prev: number, curr: number) => {
      const prevNum = prev || 0;
      const currNum = curr || 0;
      return roundUpNum(prevNum + currNum);
    });
    return result;
  },

  /**
   * No rounding
   * @param numbers
   */
  addNumbersAsDecimals(numbers: number[]): Decimal {
    const decimalNumbers = numbers.map((number) => (number ? new Decimal(number) : new Decimal(0)));
    if (decimalNumbers.length === 1) return decimalNumbers[0];
    return this.addDecimals(decimalNumbers);
  },

  /**
   * No rounding
   * @param numbers
   */
  addDecimals(numbers: Decimal[]): Decimal {
    if (!numbers || numbers.length == 0) {
      return new Decimal(0);
    }

    if (numbers.length == 1) {
      return numbers[0];
    }

    return numbers.reduce((prev: Decimal, curr: Decimal) => {
      const prevNum = prev || new Decimal(0);
      const currNum = curr || new Decimal(0);
      return prevNum.add(currNum);
    });
  },

  /**
   * Adds all numbers and rounds the result
   * @param numbers
   * @param decimalPlaces decimal places to be rounded to
   */
  addDecimalsAndRound(numbers: Decimal[], decimalPlaces: number): Decimal {
    return this.addDecimals(numbers).toDecimalPlaces(decimalPlaces, ROUNDING_STRATEGY);
  },

  addDecimalsAndRoundTwo(numbers: Decimal[]): Decimal {
    return this.addDecimalsAndRound(numbers, 2);
  },

  /**
   * Multiply all numbers consecutively.
   * @returns the result of all multiplications.
   */
  multiply(numbers: number[]): number {
    const result = numbers.reduce((prev: number, curr: number) => {
      return roundUpNum(prev * curr);
    });
    return result;
  },

  /**
   * No rounding
   * @param numbers
   */
  multiplyDecimal(numbers: Decimal[]): Decimal {
    return numbers.reduce((prev: Decimal, curr: Decimal) => {
      return prev.mul(curr);
    });
  },

  multiplyDecimalAndRound(numbers: Decimal[], decimalPlaces: number): Decimal {
    return this.multiplyDecimal(numbers).toDecimalPlaces(decimalPlaces, ROUNDING_STRATEGY);
  },

  multiplyDecimalAndRoundTwo(numbers: Decimal[]): Decimal {
    return this.multiplyDecimalAndRound(numbers, 2);
  },

  /**
   * Subtract all numbers in the array consecutively in the order they were placed in the array
   * e.g. [34,21,5] will be 34 - 21 - 5
   * @returns the result of all subtractions.
   */
  subtract(numbers: number[]): number {
    const result = numbers.reduce((prev: number, curr: number) => {
      return roundUpNum(prev - curr);
    });
    return result;
  },

  /**
   * No rounding
   * @param numbers
   */
  subtractDecimal(numbers: Decimal[]): Decimal {
    return numbers.reduce((prev: Decimal, curr: Decimal) => {
      return prev.sub(curr);
    });
  },

  subtractDecimalAndRound(numbers: Decimal[], decimalPlaces: number): Decimal {
    return this.subtractDecimal(numbers).toDecimalPlaces(decimalPlaces, ROUNDING_STRATEGY);
  },

  subtractDecimalAndRoundTwo(numbers: Decimal[]): Decimal {
    return this.subtractDecimalAndRound(numbers, 2);
  },
  /**
   * Divide two numbers.
   */
  divide(num1: number, num2: number): number {
    return num1 / num2;
  },

  /**
   * Casts the parameters into `Decimal` before performing division to avoid floating point rounding errors.
   * Returns 0 if denominator is 0.
   * @param num1
   * @param num2
   */
  divideNumbersSafely(num1: number, num2: number): number {
    if (!num2) {
      return 0;
    }
    return new Decimal(num1 ?? 0).div(new Decimal(num2)).toNumber();
  },

  /**
   * No rounding
   * @param num1
   * @param num2
   */
  divideDecimal(num1: Decimal, num2: Decimal): Decimal {
    return num1.div(num2);
  },

  divideDecimalAndRound(num1: Decimal, num2: Decimal, decimalPlaces: number): Decimal {
    return this.divideDecimal(num1, num2).toDecimalPlaces(decimalPlaces, ROUNDING_STRATEGY);
  },

  divideDecimalAndRoundTwo(num1: Decimal, num2: Decimal): Decimal {
    return this.divideDecimalAndRound(num1, num2, 2);
  },

  roundUpDecNum(val: number): number {
    return roundUpNum(val);
  },

  abs(val: number): number {
    return Math.abs(val);
  },

  absoluteDecimal(val: Decimal): Decimal {
    return val.abs();
  },

  roundTwoDecimalPlaces(val: Decimal): Decimal {
    if (!val) {
      val = new Decimal(0);
    }
    return val.toDecimalPlaces(2, ROUNDING_STRATEGY);
  },

  /**
   * round decimals based on whther it's greater than or less than 1.
   */
  roundDecimalValueBasedOnUnityValue(decimalValue: Decimal): Decimal {
    let value = decimalValue;
    if (!value) {
      value = new Decimal(0);
    }
    if (value.greaterThanOrEqualTo(1)) {
      return value.toDecimalPlaces(
        GENERAL_INDICATOR_ROUNDING.EQUAL_TO_OR_GREATER_THAN_ONE,
        ROUNDING_STRATEGY
      );
    } else {
      return value.toDecimalPlaces(GENERAL_INDICATOR_ROUNDING.LESS_THAN_ONE, ROUNDING_STRATEGY);
    }
  },

  roundDecimalToWholeNumber(value: Decimal): number {
    return value.round().toNumber();
  },

  divideDecimalAndRoundBasedOnUnity(num1: Decimal, num2: Decimal): Decimal {
    const dividedDecimal = this.divideDecimal(num1, num2);
    return this.roundDecimalValueBasedOnUnityValue(dividedDecimal);
  },

  multiplyDecimalAndRoundBasedOnUnity(numbers: Decimal[]): Decimal {
    const multipliedValue = this.multiplyDecimal(numbers);
    return this.roundDecimalValueBasedOnUnityValue(multipliedValue);
  },

  subtractDecimalAndRoundBasedOnUnity(numbers: Decimal[]): Decimal {
    const subtractedValue = this.subtractDecimal(numbers);
    return this.roundDecimalValueBasedOnUnityValue(subtractedValue);
  },

  countDecimalPlaces(input: string): number {
    if (!input) return 0;
    if (input.indexOf('.') !== -1) {
      return input.split('.')[1].length;
    } else {
      return 0;
    }
  }
};
