/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

export function traverseAndApplyTransformationToObject<T>(
  value: T,
  condition: (obj: any) => boolean,
  transformation: (obj: any) => any
): T {
  // Handle primitives (non-objects) by simply returning them
  if (value === null || typeof value !== 'object') {
    return value;
  }

  // Use a type guard to refine the type of `value`
  if (Array.isArray(value)) {
    return value.map((item) =>
      traverseAndApplyTransformationToObject(item, condition, transformation)
    ) as unknown as T;
  } else if (condition(value)) {
    // Apply transformation if condition is met
    value = transformation(value);
  }

  const newValue = value as { [key: string]: any }; // To allow manipulation of key-value pairs
  // Recursively apply to all properties if it's an object
  Object.keys(newValue).forEach((key) => {
    // check if read only property
    if (Object.getOwnPropertyDescriptor(newValue, key)?.writable === false) {
      return;
    }
    newValue[key] = traverseAndApplyTransformationToObject(newValue[key], condition, transformation);
  });

  return newValue as T;
}

export function traverseAndCreateFlatList<T, R = any>(
  value: T,
  condition: (obj: any) => boolean,
  getter: (obj: any) => R
): R[] {
  const flatList: any[] = [];

  function traverseAndCreateFlatListInternal(obj: any) {
    if (condition(obj)) {
      flatList.push(getter(obj));
    }

    if (obj === null || typeof obj !== 'object') {
      return;
    }

    if (Array.isArray(obj)) {
      obj.forEach((item) => traverseAndCreateFlatListInternal(item));
    } else {
      Object.keys(obj).forEach((key) => traverseAndCreateFlatListInternal(obj[key]));
    }
  }

  traverseAndCreateFlatListInternal(value);

  return flatList;
}
