import { appWindow, availableMonitors, currentMonitor } from '@tauri-apps/api/window';
import { COMMON_PLATFORM_NAME, Platform } from '../../../core';
import { PlatformInfo } from '../../../core/platform';
import { arch, locale, version, platform } from '@tauri-apps/api/os';

export function isTauri() {
  return !!window.__TAURI_METADATA__;
}

export const tauriPlatform = Platform.create({
  name: COMMON_PLATFORM_NAME.TAURI,
  predicate: isTauri,
  leaderElection: (workspace) => appWindow.label === workspace.getLeaderProcessId(),
  getPlatformInfo: async () => {
    const info: PlatformInfo = {
      name: COMMON_PLATFORM_NAME.TAURI,
      version: await version(),
      os: await platform(),
      arch: await arch(),
      locale: (await locale()) || undefined
    };

    const monitors = await availableMonitors();
    if (monitors) {
      info.monitors = monitors.map((monitor) => ({
        name: monitor.name,
        width: monitor.size.width,
        height: monitor.size.height,
        x: monitor.position.x,
        y: monitor.position.y,
        scaleFactor: monitor.scaleFactor
      }));
    }

    return info;
  },
  getCurrentMonitor: async () => {
    const monitor = await currentMonitor();
    if (!monitor) {
      throw new Error('No monitor found');
    }
    return {
      name: monitor.name,
      width: monitor.size.width,
      height: monitor.size.height,
      x: monitor.position.x,
      y: monitor.position.y,
      scaleFactor: monitor.scaleFactor
    };
  }
});
