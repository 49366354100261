import { FC } from 'react';
import { TooltipProvider } from '../components/tooltip/tooltip';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import './fonts.scss';
import './ag-grid.scss';
import './toasts.scss';
import './global.css';
import './global-factory.css';
import { ColorModeScript } from '../components/color-mode-toggle/color-mode-script';
import { ColorModeProvider } from '../components/color-mode-toggle/color-mode-provider';

export const ThemeProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <HelmetProvider>
      <ToastContainer autoClose={2750} theme="dark" />
      <ColorModeScript />
      <ColorModeProvider>
        <TooltipProvider delayDuration={0} skipDelayDuration={150}>
          {children}
        </TooltipProvider>
      </ColorModeProvider>
    </HelmetProvider>
  );
};
