import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { FieldContract, INumberFormatField, ModifiedFieldForOptions } from '@oms/frontend-foundation';
import type { AnyRecord } from '@valstro/workspace';
import type { MinNumberVal } from '../../validators/min-number-value/min-number-value.validator';

export const createPriceField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<INumberFormatField> & { minNumber?: Partial<MinNumberVal> }
) =>
  fc.field(key, 'number-format').options<INumberFormatField>({
    label: 'Price',
    placeholder: 'Market',
    selectAllOnFocus: true,
    ...overrides,
    validate: [
      {
        type: 'min-number-value',
        value: 0.01,
        message: 'Min $0.01',
        ignoreZero: true,
        ...(overrides?.minNumber || {})
      },
      ...(overrides?.validate || [])
    ]
  });
