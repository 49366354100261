import { ModificationType } from '@oms/generated/frontend';
import type { VisibleModificationInfoFragment } from '@oms/generated/frontend';
import { CellBadgeClassEnum, valueChangedRenderer } from '@oms/frontend-vgrid';
import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import {
  sharedDefaultCol,
  sharedPriceCol,
  sharedQuantityCol,
  sharedTextCol,
  sharedTimestampCol
} from '@app/common/grids/columns/generic-cols';
import { sharedInvestorAccountCol, sharedSideCol } from '@app/common/grids/columns/order-cols';
import { instrumentDisplayCode } from '@app/common/grids/columns/instrument-cols';
import { mapModificationType } from '@app/common/mappers/map-modification-type';

type PendingModificationsColumnBuilder = ColumnBuilderCallback<VisibleModificationInfoFragment>;

const defaultCol: PendingModificationsColumnBuilder = (c) => sharedDefaultCol(c);

const investorAccountCol: PendingModificationsColumnBuilder = (c) =>
  sharedInvestorAccountCol(c, 'investorOrder.investorAccount.name');

const instrumentCol: PendingModificationsColumnBuilder = instrumentDisplayCode('investorOrder.instrument');

const sideCol: PendingModificationsColumnBuilder = (c) => sharedSideCol(c, 'investorOrder.sideType');

export const statusCol: PendingModificationsColumnBuilder = (c) =>
  sharedTextCol(c, `requests.${0}.type`)
    .header(t('app.orders.orderMonitor.status'))
    .shortHeader(t('app.orders.orderMonitor.status', { ns: 'short' }))
    .maxWidth(220)
    .minWidth(100)
    .filter('agSetColumnFilter')
    .filterParams<ModificationType>({
      values: Object.values(ModificationType).map((v) => mapModificationType(v) as ModificationType)
    })
    .cell((c) =>
      c.badge(CellBadgeClassEnum.Capital, (data) => {
        const { requests = [] } = data ?? {};
        const type = requests?.some((request) => request?.type === ModificationType.Cancel)
          ? ModificationType.Cancel
          : ModificationType.Modify;

        return mapModificationType(type, 'Unknown');
      })
    );

const requestedPriceCol: PendingModificationsColumnBuilder = (c) =>
  sharedPriceCol(c, 'requested.price')
    .header(t('app.common.grids.limitPrice'))
    .shortHeader(t('app.common.grids.limitPrice', { ns: 'short' }))
    .cell((cell) =>
      cell.renderer(valueChangedRenderer('existing.price', 'requested.price', 'MARKET', 'MARKET'))
    );

const requestedQuantityCol: PendingModificationsColumnBuilder = (c) =>
  sharedQuantityCol(c, 'requested.quantity')
    .header(t('app.common.quantity'))
    .shortHeader(t('app.common.quantity', { ns: 'short' }))
    .cell((cell) =>
      cell.renderer(valueChangedRenderer('existing.quantity', 'requested.quantity', 'CANCELLED', 'CANCELLED'))
    );

const modifyTimeCol: PendingModificationsColumnBuilder = (c) =>
  sharedTimestampCol(c, 'snapshotTime')
    .header(t('app.orders.pendingModifications.modifyTime'))
    .shortHeader(t('app.orders.pendingModifications.modifyTime', { ns: 'short' }))
    .sort('desc')
    .filter(false);

export const pendingModificationsColumnLibrary: ColumnLibrary<VisibleModificationInfoFragment> = {
  defaultColumn: defaultCol,
  columns: [
    investorAccountCol,
    instrumentCol,
    sideCol,
    statusCol,
    requestedPriceCol,
    requestedQuantityCol,
    modifyTimeCol
  ]
};
