export type LazyStatus = 'uninitialized' | 'ready';

/**
 * Wraps a value that will compute lazily.
 *
 * ```ts
 * const lazy_value = new Lazy(() => performExpensiveComputation());
 * ```
 * The underlying value in `_lazy_value` won't be computed until accessed the first time.
 * After the first access, the value is stored and won't be recomputed.
 *
 * ```ts
 * const value = lazy_value.value; // <-- Computation happens here
 * const sameValue = lazy_value.value; // Accesses stored value
 * ```
 */
export class Lazy<T> {
  protected _status: LazyStatus;
  protected _value?: T;
  protected _getValue: () => T;

  /**
   * Wraps a value that will compute lazily.
   * @param getValue A callback that computes the lazy value. This will run on first access.
   */
  public constructor(getValue: () => T) {
    this._status = 'uninitialized';
    this._value = undefined;
    this._getValue = getValue;
  }

  /** Gets status: Either `uninitialized` or `ready` */
  public get status(): LazyStatus {
    return this._status;
  }

  public isReady(): boolean {
    return this._status === 'ready';
  }

  /**
   * Access to the underlying value, getting the value if ready or
   * computing it if not.
   */
  public get value(): T {
    switch (this.status) {
      case 'ready':
        return this._value as T;
      case 'uninitialized': {
        const value = this._getValue();
        this._value = value;
        this._status = 'ready';
        return value;
      }
    }
  }
}

export default Lazy;
