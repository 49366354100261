import { AnyRecord } from '@oms/frontend-foundation';
import {
  GridMenuOptions,
  GridMenuOption,
  GridMenuOptionLocation,
  GetMenuOptionCb
} from '../models/grid-menu-options.model';

export class GridMenuOptionBuilder<TData extends AnyRecord> {
  private _gridOption: GridMenuOption<TData> = {
    locations: [GridMenuOptionLocation.ContextMenu, GridMenuOptionLocation.GlobalMenu]
  } as GridMenuOption<TData>;

  public id(id: string): GridMenuOptionBuilder<TData> {
    this._gridOption.id = id;
    return this;
  }

  public location(
    ...args: (
      | 'contextMenu'
      | 'globalMenu'
      | GridMenuOptionLocation.ContextMenu
      | GridMenuOptionLocation.GlobalMenu
    )[]
  ): GridMenuOptionBuilder<TData> {
    const locationEnums = args.map((arg) => {
      if (arg === GridMenuOptionLocation.ContextMenu || arg === GridMenuOptionLocation.GlobalMenu) {
        return arg;
      }
      return arg === 'globalMenu' ? GridMenuOptionLocation.GlobalMenu : GridMenuOptionLocation.ContextMenu;
    }) as GridMenuOptionLocation[];

    this._gridOption.locations = locationEnums;
    return this;
  }

  public get(cb: GetMenuOptionCb<TData>): GridMenuOptionBuilder<TData> {
    this._gridOption.get = cb;
    return this;
  }

  public build(): GridMenuOption<TData> {
    if (!this._gridOption.id) {
      throw new Error('GridMenuOptionBuilder: id is required');
    }

    if (!this._gridOption.get) {
      throw new Error('GridMenuOptionBuilder: get callback is required');
    }

    return this._gridOption;
  }
}

export class GridMenuOptionsBuilder<TData extends AnyRecord> {
  private _gridOptions: GridMenuOptions<TData> = [];

  public option(
    cb: (builder: GridMenuOptionBuilder<TData>) => GridMenuOptionBuilder<TData>
  ): GridMenuOptionsBuilder<TData> {
    const option = cb(new GridMenuOptionBuilder<TData>()).build();
    this._gridOptions.push(option);
    return this;
  }

  public standardOptions(): GridMenuOptionsBuilder<TData> {
    this._gridOptions.push({
      id: 'grid-sizing',
      locations: [GridMenuOptionLocation.ContextMenu],
      get: (params) => {
        return {
          name: 'Grid sizing',
          subMenu: [
            {
              name: 'Size to fit',
              action: () => {
                params.api.sizeColumnsToFit();
              }
            },
            {
              name: 'Auto size',
              action: () => {
                params.columnApi.autoSizeAllColumns();
              }
            }
          ]
        };
      }
    });

    return this;
  }

  public build(): GridMenuOptions<TData> {
    return this._gridOptions;
  }
}
