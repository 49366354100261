import { type Optional } from '@oms/shared/util-types';
import { BehaviorSubject, type Observable, switchMap } from 'rxjs';

export interface FilterShape<TFilter, TData> {
  filter: TFilter;
  data: TData;
}

type QueryFn<TFilter, TData> = (filter: Optional<TFilter>) => Observable<TData>;

/**
 * Combines a filter object to an observable query of information.
 * Useful for filtering queries and syncing data.
 */
export class FilterSubject<TFilter, TData> {
  private filterSubject$ = new BehaviorSubject<Optional<TFilter>>(undefined);
  private datasourceQuery: QueryFn<TFilter, TData>;
  private datasource$: Observable<TData>;

  constructor(query: QueryFn<TFilter, TData>) {
    this.datasourceQuery = query;
    this.datasource$ = this.filterSubject$.pipe(switchMap((filter) => this.datasourceQuery(filter)));
  }

  public get data$(): Observable<TData> {
    return this.datasource$;
  }

  public filter(filter?: TFilter) {
    this.filterSubject$.next(filter);
  }

  public static from<TFilter, TData>(query: QueryFn<TFilter, TData>) {
    return new FilterSubject<TFilter, TData>(query);
  }
}
