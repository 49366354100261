import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import type {
  AllocationMap,
  GetTradingOrdersByOrderIdQuery,
  GetTradingOrdersByOrderIdQueryVariables,
  VisibleTradingOrderInfoFragment
} from '@oms/generated/frontend';
import { GetTradingOrdersByOrderIdDocument } from '@oms/generated/frontend';
import { catchError, map, of } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

export type InvestorOrderTradingOrderRow = VisibleTradingOrderInfoFragment & {
  mappedQuantity?: number;
};

@testScoped
@singleton()
export class InvestorOrderTradingOrdersService {
  constructor(@inject(ApolloClientRPC) private apolloClient: ApolloClientRPC) {}

  watchAll$(id?: string, pollInterval = 5000) {
    return this.apolloClient
      .watchQuery<GetTradingOrdersByOrderIdQuery, GetTradingOrdersByOrderIdQueryVariables>({
        query: GetTradingOrdersByOrderIdDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: id ?? ''
        }
      })
      .pipe(
        map((res) => {
          const allocationMapsResults = res.data.investorOrder?.allocationMaps?.nodes
            ? (res.data.investorOrder.allocationMaps?.nodes as AllocationMap[])
            : [];
          // Trading Order values are in allocationMaps.tradingOrder
          // Mapped Quantity is in allocationMaps.allocatedQuantity
          // Combine them.
          const combinedResults = allocationMapsResults.map((res) => {
            return {
              ...res.visibleTradingOrder,
              mappedQuantity: res.allocatedQuantity
            };
          }) as InvestorOrderTradingOrderRow[];
          return combinedResults;
        }),
        catchError((e) => {
          console.error(e);
          return of([]);
        })
      );
  }
}
