import { t } from '@oms/codegen/translations';
import type { InstrumentDetails, InstrumentMapping } from '@oms/generated/frontend';
import type { ColumnBuilder, ColumnBuilderCallback } from '@oms/frontend-vgrid';
import isEmpty from 'lodash/isEmpty';
import type { AnyRecord } from '@oms/frontend-foundation';

export type InstrumentField = Partial<keyof InstrumentMapping | keyof Omit<InstrumentDetails, 'mappings'>>;
export type InstrumentFieldMap<T extends AnyRecord> = Partial<
  Record<InstrumentField, ColumnBuilderCallback<T>>
>;
export type InstrumentOverrideConfig<T extends AnyRecord> = InstrumentFieldMap<T> & {
  field?: string;
  include?: InstrumentField[];
};

export const instrumentDisplayCode =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) => {
    const builder = c
      .field(`${field}.mappings.displayCode` as any)
      .header(t('app.common.symbol'))
      .width(110)
      .maxWidth(120)
      .minWidth(100)
      .filter('agTextColumnFilter');

    return builder;
  };

export const instrumentLongName =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c
      .field(`${field}.longName` as any)
      .header('Instrument Long Name')
      .minWidth(100)
      .hide();

export const cusip =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c
      .field(`${field}.mappings.cusip` as any)
      .header('CUSIP')
      .width(100)
      .maxWidth(120)
      .minWidth(90)
      .hide();

export const exchangeCode =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c
      .field(`${field}.mappings.exchCode` as any)
      .header('Exchange Code')
      .width(200)
      .maxWidth(200)
      .minWidth(200)
      .hide();

export const instrumentCols = <T extends AnyRecord = AnyRecord>(
  overrides: InstrumentOverrideConfig<T> = { field: 'instrument' }
): ColumnBuilderCallback<T>[] => {
  const { field = 'instrument', include } = overrides;
  const cols: InstrumentFieldMap<T> = {
    displayCode: instrumentDisplayCode(field),
    longName: instrumentLongName(field),
    cusip: cusip(field),
    exchCode: exchangeCode(field)
  };

  const overrideFn =
    (fn: ColumnBuilderCallback<T>, override: ColumnBuilderCallback<T>) => (cb: ColumnBuilder<T>) => {
      return override(fn(cb));
    };

  return Object.keys(cols)
    .filter((k) => {
      return isEmpty(include) || include?.includes(k as any);
    })
    .map((k) => {
      const builder: ColumnBuilderCallback<T> = cols[k as keyof typeof cols] as ColumnBuilderCallback<T>;
      if (!overrides) {
        return builder;
      }
      const overrideBuilder = overrides[k as keyof typeof overrides] as ColumnBuilderCallback<T> | undefined;
      return overrideBuilder ? overrideFn(builder, overrideBuilder) : builder;
    });
};
