export function formatOpenQuantityForMatchedOrders(num: number): string {
  if (num < 1000) {
    return '<1K';
  }

  const options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 1,
    trailingZeroDisplay: 'stripIfInteger',
    roundingMode: 'halfExpand'
  } as unknown as Intl.NumberFormatOptions;

  const numberFormatter = new Intl.NumberFormat('en-US', options);

  const dividedByThousand = num / 1000;

  const fomatted = numberFormatter.format(dividedByThousand);

  return fomatted + 'K';
}
