import { tap, of, BehaviorSubject } from 'rxjs';
import type { Observable, Subscription, OperatorFunction } from 'rxjs';
import type { Destroyable, Optional } from '@oms/shared/util-types';
import type { OnCountChangeEvent } from '@oms/shared-frontend/ui-design-system';
import type { MontageContext, Target } from '../montage.types';
import type { MontageItem } from '../montage.types';
import { calculateTarget } from './montage.utils';

type MontageItemsOperator = OperatorFunction<MontageItem[], MontageItem[]>;

export type CountType = OnCountChangeEvent['countType'];

/** Tool to manage moving the Montage target */
export class MontageTargetState implements Destroyable {
  protected context?: MontageContext;

  protected items$: Observable<MontageItem[]>;

  protected targetStateSubject = new BehaviorSubject<Target>({ index: 0 });
  protected indexToIdMap = new Map<number, string>();
  protected itemCount: number = 0;

  protected subscription?: Subscription;
  protected onError?: (e: Error) => void;

  // 🏗️ Constructor ----------------------------------------------------------------- /

  public constructor(onError?: (e: Error) => void) {
    this.items$ = of([] as MontageItem[]);
    if (onError) this.onError = onError;
  }

  // ⚒️ Setup /teardown --------------------------------------------------------- /

  public init(
    items$: Observable<MontageItem[]>,
    context: MontageContext,
    next?: (items: MontageItem[]) => void
  ) {
    this.context = context;
    this.items$ = items$.pipe(this.setInternalLookups(), this.checkTarget());
    this.subscription = next ? this.items$.subscribe(next) : this.items$.subscribe();
  }

  public destroy() {
    this.subscription?.unsubscribe();
    delete this.subscription;
  }

  // 📢 Public ----------------------------------------------------------------- /

  public get $(): Observable<Target> {
    return this.targetStateSubject.asObservable();
  }

  public get currentTarget(): Target {
    return this.targetStateSubject.getValue();
  }

  public get currentIndex(): number {
    return this.currentTarget.index;
  }

  public get currentId(): Optional<string> {
    return this.currentTarget.id;
  }

  /**
   * Shifts the target position.
   * - 'up' - Moves the target 👆. Shifting the cursor up the screen *decreases* the index
   * - 'down' - Moves the target 👇. Shifting the cursor down the screen *increases* the index
   *
   * @param countType - 'down' the screen *increases* the index and 'up' the screen *decreases* the index
   */
  public shift(countType: CountType) {
    const index = this.calculateTarget(countType);
    this.setState({ index, id: this.getId(index) });
  }

  public toString(): string {
    return `${this.itemCount} item${this.itemCount === 1 ? '' : 's'}: index: ${this.currentIndex}, id: ${
      this.currentId ?? 'None'
    }`;
  }

  // 🔒 Protected / private ------------------------------------------------------ /

  // 🔨 Util ------ /

  protected getId(index?: number): Optional<string> {
    return this.indexToIdMap.get(typeof index == 'number' ? index : this.currentIndex);
  }

  protected calculateTarget(countType: CountType) {
    return calculateTarget(this.currentIndex, this.itemCount - 1, countType);
  }

  protected onRejected(e: any) {
    this.onError?.(e as Error);
  }

  // 🔪 Operators ------ /

  /** Sets internal item state (count and ID lookup map) when new items arrive */
  protected setInternalLookups(): MontageItemsOperator {
    return (observable$) =>
      observable$.pipe(
        tap((items) => {
          this.itemCount = items.length;
          this.indexToIdMap.clear();
          items.forEach(({ targetingIndex, id }) => {
            if (typeof targetingIndex === 'number' && id) this.indexToIdMap.set(targetingIndex, id);
          });
        })
      );
  }

  /**
   * Checks to ensure:
   * - Target ID is initialized
   * - Target index still points to the correct target ID
   */
  protected checkTarget(): MontageItemsOperator {
    return (observable$) =>
      observable$.pipe(
        tap((items) => {
          if (!this.currentId || !this.itemCount) {
            if (!this.itemCount) return;
            // Set initial target ID
            this.setState(this.getId());
          } else {
            const newIndex = items.findIndex(({ id }) => id === this.currentId);
            if (newIndex !== this.currentIndex || newIndex === -1) {
              // Items may have been sorted and the target item may have a new index
              const index = Math.max(newIndex, 0);
              this.setState({ index, id: this.currentId });
            }
          }
        })
      );
  }

  // 🗃️ Set to RxDB state ------ /

  protected setState(id?: string): void;
  protected setState(target: Target): void;
  // Implementation only ------------ /
  protected setState(input?: Target | string) {
    if (typeof input === 'string' || typeof input === 'undefined') {
      const { index } = this.targetStateSubject.getValue();
      this.targetStateSubject.next({ index, id: input });
    } else {
      this.targetStateSubject.next(input);
    }
  }
}

export default MontageTargetState;
