import { t } from '@oms/codegen/translations';
import type { Field, FormBuilderTemplateProps } from '@oms/frontend-foundation';
import { FieldDefinition } from '@oms/frontend-foundation';

export function createFormContractWrapper(fields: Field<unknown>[]) {
  return FieldDefinition.box('wrapper', fields, {
    sx: {
      p: 3
    }
  });
}

export function createFormContractTemplateOptions(
  overrides: Partial<FormBuilderTemplateProps['simple']> = {}
): Partial<FormBuilderTemplateProps['simple']> {
  return {
    showSubmitButton: true,
    submitLabel: t('app.common.save'),
    showResetButton: true,
    sx: { backgroundColor: 'layout.level2', ...overrides.sx },
    toolbarSx: { backgroundColor: 'layout.level2', ...overrides.toolbarSx },
    ...overrides
  };
}
