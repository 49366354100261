import type { CurrencyFragment } from '@oms/generated/frontend';
import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { sharedDefaultCol, sharedIdCol } from '@app/common/grids/columns/generic-cols';
import { sharedNameCol } from '@app/common/grids/columns/reference-data-cols';

const defaultCol: ColumnBuilderCallback<CurrencyFragment> = (c) => sharedDefaultCol<CurrencyFragment>(c);

const idCol: ColumnBuilderCallback<CurrencyFragment> = (c) =>
  sharedIdCol<CurrencyFragment>(c, 'id').header('Code');

const longNameCol: ColumnBuilderCallback<CurrencyFragment> = (c) =>
  sharedNameCol<CurrencyFragment>(c, 'longName');

export const currencyColumnLibrary: ColumnLibrary<CurrencyFragment> = {
  defaultColumn: defaultCol,
  columns: [idCol, longNameCol]
};
