import { InvestorOrderStatus } from '@oms/generated/frontend';
import type { OrderSide, VisibleInvestorOrderFilter } from '@oms/generated/frontend';

export const getVisibleInvestorOrderFilter = (
  instrumentId: string,
  side: OrderSide,
  ownerId: string
): VisibleInvestorOrderFilter => {
  return {
    status: {
      equalTo: InvestorOrderStatus.Active
    },
    openQuantity: {
      greaterThan: 0
    },
    instrument: {
      id: {
        equalTo: instrumentId
      }
    },
    owner: {
      id: {
        equalTo: ownerId
      }
    },
    side: {
      equalTo: side
    }
  };
};
