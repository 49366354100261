import type { PositionFragment } from '@oms/generated/frontend';
import { POSITIONS_MANUAL_MARK_NAME } from '@oms/shared/oms-common';
import type {
  PositionFragmentWithValuationOverride,
  PositionRow,
  PositionRowWithValuationOverride
} from './positions.types';

export function isPositionWithValuationOverride(
  positionFragment?: PositionFragment
): positionFragment is PositionFragmentWithValuationOverride;

export function isPositionWithValuationOverride(
  positionRow?: PositionRow
): positionRow is PositionRowWithValuationOverride;

// Implementation only
export function isPositionWithValuationOverride(
  position?: PositionFragment | PositionRow
): position is PositionFragmentWithValuationOverride | PositionRowWithValuationOverride {
  if (!position) return false;
  const { valuationPrice, positionValuationStrategy, manualMark } = position;
  if (typeof valuationPrice !== 'number') return false;
  if (typeof manualMark === 'undefined' || manualMark === null) return false;
  return positionValuationStrategy === POSITIONS_MANUAL_MARK_NAME.MANUAL_MARK;
}
