import { MarketDataService } from '../marketdata.service';
import { useService } from '@oms/frontend-foundation';
import { useLayoutEffect, useState } from 'react';
import type { MarketData } from '../marketdata.common';

export const useRxMarketDataSubscription = (ticker: string | undefined) => {
  const marketDataService = useService(MarketDataService);

  const [data, setData] = useState<MarketData | null>();

  useLayoutEffect(() => {
    if (!ticker) {
      setData(null);
      return;
    }

    const sub = marketDataService.observe(ticker).subscribe((e) => {
      setData(e.data);
    });

    return () => {
      setData(null);
      sub.unsubscribe();
    };
  }, [ticker]);

  return data;
};
