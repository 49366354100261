import type { ReactNode, FC, PropsWithChildren } from 'react';
import { Box } from '@oms/shared-frontend/ui-design-system';
import {
  bottomRailStyle,
  leftContentStyle,
  leftRailStyle,
  logoStyle,
  logoWrapperStyle,
  screenshotStyle,
  screenshotWrapperStyle,
  infoContainerStyle
} from './valstro-info.container.css';

export type ValstroInfoContainerProps = PropsWithChildren<{
  footer?: ReactNode;
}>;

export const ValstroInfoContainer: FC<ValstroInfoContainerProps> = ({ children, footer }) => {
  return (
    <Box className={infoContainerStyle}>
      <Box className={leftRailStyle}>
        <Box className={logoWrapperStyle}>
          <img className={logoStyle} src="images/valstro-logo.svg" alt="Valstro" />
        </Box>
        <Box className={leftContentStyle}>{children}</Box>
      </Box>
      <Box className={screenshotWrapperStyle}>
        <img className={screenshotStyle} src="images/about-valstro-screenshot.png" alt="About Valstro" />
      </Box>
      <Box className={bottomRailStyle}>{footer}</Box>
    </Box>
  );
};
