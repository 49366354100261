import { CustomGraphQLError } from './custom-error';
import { ErrorTypeEnum } from './errors.types';

export interface InternalServerErrorException extends Error {
  code?: string;
  // TODO: Add others as needed
}

export type IInternalServerError = CustomGraphQLError<
  ErrorTypeEnum.INTERNAL_SERVER_ERROR,
  string,
  InternalServerErrorException
>;

export class InternalServerError extends CustomGraphQLError<
  ErrorTypeEnum.INTERNAL_SERVER_ERROR,
  string,
  InternalServerErrorException
> {
  constructor(message: string, code: string) {
    super(message);
    this.extensions = {
      type: ErrorTypeEnum.INTERNAL_SERVER_ERROR,
      code,
      data: message
    };
  }
}

export default InternalServerError;
