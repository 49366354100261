import { FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { MontageBumpPriceFormOutput } from './bump-price.form-common';
import * as fields from './bump-price.form-fields';
import { FieldDefinition } from '@oms/frontend-foundation';
import type { AssertMutuallyAssignable, Expect, GetVersionedSchemaDataType } from '@oms/frontend-schemas';
import type { LATEST_VERSIONED_SCHEMA } from '../schema';

export const montageBumpPriceContract = FormContract.create<MontageBumpPriceFormOutput>()
  .fields({
    bumpPrice: fields.bumpPrice
  })
  .schema((f) => {
    return {
      fields: [
        FieldDefinition.box('bump-price-container', [f.bumpPrice], {
          sx: {
            display: 'flex',
            flexDirection: 'row'
          }
        })
      ]
    };
  })
  .template('simple', { showSubmitButton: false });

export type MontageBumpPriceContractType = typeof montageBumpPriceContract;

export type MontageBumpPriceValues = InferFormValuesFromFormContract<MontageBumpPriceContractType>;

// If this type errors, you've likely changed the form and it no longer matches the database schema.
// To fix, either change your form to match the schema or generate a new schema & migration by running the following command:
// pnpm exec nx generate @oms/ui-gen:sub-schema-migration --key=BUMP_PRICE --no-interactive
export type SCHEMA_ASSERTION_DO_NOT_TOUCH = Expect<
  AssertMutuallyAssignable<MontageBumpPriceValues, GetVersionedSchemaDataType<typeof LATEST_VERSIONED_SCHEMA>>
>;
