import { CustomGraphQLError } from './custom-error';
import { ErrorTypeEnum } from './errors.types';

export type IValidationFieldError = {
  message: string;
  field: string;
  index?: number;
};

export const isIValidationFieldError = (data: unknown): data is IValidationFieldError => {
  if (typeof data !== 'object' || data === null || Array.isArray(data)) return false;
  const { message, field } = data as Partial<IValidationFieldError>;
  return typeof message === 'string' && typeof field === 'string';
};

export type IFieldValidationError = CustomGraphQLError<
  ErrorTypeEnum.FIELD_VALIDATION_ERROR,
  IValidationFieldError[]
>;

export class FieldValidationError extends CustomGraphQLError<
  ErrorTypeEnum.FIELD_VALIDATION_ERROR,
  IValidationFieldError[]
> {
  constructor(errors: IValidationFieldError[], code?: string) {
    super(
      'Field validation error(s): ' + errors?.map((e) => `field: ${e.field} message: ${e.message}`).join(', ')
    );
    this.extensions = {
      type: ErrorTypeEnum.FIELD_VALIDATION_ERROR,
      code: code || ErrorTypeEnum.FIELD_VALIDATION_ERROR,
      data: errors
    };
  }
}
