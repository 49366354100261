import type { FC } from 'react';
import type { IHeaderParams } from '@ag-grid-community/core';
import { Box } from '@oms/shared-frontend/ui-design-system';
import type { MontageItem, MontageItemType } from '@app/data-access/services/trading/montage/montage.types';
import { getCellTextColorByGridType } from './renderers/depth.cell.util';

export type DepthPriceColumnHeaderSpecificParams = { type: MontageItemType };
export type DepthPriceColumnHeaderParams = IHeaderParams<MontageItem> & DepthPriceColumnHeaderSpecificParams;

export const DepthPriceColumnHeader: FC<DepthPriceColumnHeaderParams> = ({ displayName, type }) => {
  return (
    <Box
      sx={{
        color: getCellTextColorByGridType(type),
        textAlign: 'right',
        width: 'full',
        paddingRight: 0,
        marginRight: 0
      }}
    >
      {displayName}
    </Box>
  );
};

export default DepthPriceColumnHeader;
