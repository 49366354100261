import { INLINE_SPLIT_BUTTON_ACTION } from '@oms/frontend-vgrid';
import type { ActionDefFactory, SplitButtonActionComponentConfig } from '@oms/frontend-vgrid';
import { InvestorOrderStatus } from '@oms/generated/frontend';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { acceptInvestorOrderOnChange } from '../../investor-order-monitor/grid-actions/accept.investor-order.action';
import { rejectInvestorOrderOnChange } from '../../investor-order-monitor/grid-actions/reject.investor-order.action';

export const inlineNewOrdersActions: ActionDefFactory<VisibleInvestorOrderInfoWithAllocationsFragment> = (
  builder
) =>
  builder
    .name('inline_new_orders_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(110)
        .filter(false)
        .cell((c) =>
          c.renderer(INLINE_SPLIT_BUTTON_ACTION, {
            actionsConfig: [
              {
                id: 'accept',
                title: 'Accept'
              },
              {
                id: 'reject',
                title: 'Reject'
              }
            ]
          })
        )
    )
    .lifecycles('change', 'init')
    .onChange<SplitButtonActionComponentConfig<VisibleInvestorOrderInfoWithAllocationsFragment>>(
      async (ctx) => {
        const { state, lifecycle } = ctx;

        const selectedId = state.selectedId;
        const selectedRow = ctx.data[0];

        const isAcceptingStatus =
          selectedRow &&
          'status' in selectedRow &&
          selectedRow?.status !== InvestorOrderStatus.Unaccepted &&
          selectedRow?.__typename === 'VisibleInvestorOrder';

        switch (lifecycle) {
          case 'init': {
            ctx.notify({ isDisabled: isAcceptingStatus });
            break;
          }

          case 'change': {
            switch (selectedId) {
              case 'accept':
                return await acceptInvestorOrderOnChange(ctx);
              case 'reject':
                return await rejectInvestorOrderOnChange(ctx);
              default:
                throw new Error(`onChange not defined for ${selectedId || 'selectedId'}`);
            }
          }
        }
      }
    );
