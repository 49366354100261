import { omit } from 'lodash';
import type { AnyBaseUserObject, BaseUserObject } from './types';

export const isBaseUserObject = (baseUser?: Partial<AnyBaseUserObject>): baseUser is BaseUserObject =>
  baseUser?.__typename !== 'UserRepresentation';

export const standardizeBaseUserObject = (baseUser: Partial<AnyBaseUserObject>): BaseUserObject => {
  if (isBaseUserObject(baseUser)) return baseUser;
  return { ...omit(baseUser, '__typename'), __typename: 'User' } as BaseUserObject;
};
