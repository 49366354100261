import {
  createClearGridFilterAction,
  CLEAR_GRID_FILTER_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  createCustomMenuClearGridFilterAction
} from './clear.grid.filter.action';
import { createRowSelectionCountAction, ROW_SELECTION_COUNT_ACTION_TYPE } from './row.selection.count.action';

export const DEFAULT_ACTIONS = {
  [CLEAR_GRID_FILTER_ACTION_TYPE]: createClearGridFilterAction<any>(),
  [CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE]: createCustomMenuClearGridFilterAction<any>(),
  [ROW_SELECTION_COUNT_ACTION_TYPE]: createRowSelectionCountAction<any>()
} as const;
