import React, { useCallback, useEffect } from 'react';
import type { InstrumentFollowToggleStateProps } from '@app/data-access/services/system/instrument-follow/instrument-follow.toggle-state.registry';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import type { OrderVwapProps } from '../order-vwap.widget.config';

export const ORDER_VWAP_FOLLOW_STATE_KEY = 'order_vwap';

export const FollowOrderVwapToggleState: React.FC<InstrumentFollowToggleStateProps> = ({
  children,
  onPressedChange: _onPressedChange
}) => {
  const [trackingEnabled, setFollow] = useTrackingEnabled();

  // Sync the pressed state with the parent component
  useEffect(() => {
    if (_onPressedChange && trackingEnabled !== undefined) {
      _onPressedChange(trackingEnabled);
    }
  }, [trackingEnabled]);

  const onPressedChange = useCallback(
    (pressed: boolean) => {
      setFollow(pressed);
    },
    [setFollow]
  );

  return children({ pressed: trackingEnabled, onPressedChange });
};

function useTrackingEnabled() {
  const [props, setProps] = useCurrentAppWidgetActorProps<OrderVwapProps>();
  const { investorOrderId, trackingEnabled = false } = props || {};
  const setFollow = useCallback(
    (pressed: boolean) => {
      setProps({ investorOrderId, trackingEnabled: pressed }).catch(console.error);
    },
    [setProps, investorOrderId]
  );
  return [trackingEnabled, setFollow] as const;
}
