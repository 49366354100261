import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import type { BoxProps } from '@oms/shared-frontend/ui-design-system';
import { useFieldApi, useFormBuilderTemplate } from '@oms/frontend-foundation';
import type { KeyboardEventHandler } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { AllFieldsRenderer } from './all-fields-renderer';
import { HotKeys } from 'react-hotkeys';
import { AllFieldsContextProvider, useAllFieldsContext } from './all-fields-context';
import type { FORM_COMPONENT_TYPE } from '../../common/form.contracts';
import { useAdditionalFieldsState } from './use-additional-fields-state';
import type { IEnrichedField } from '../enriched-field/enriched-field.component';

export type IAllFields = ICommonField<typeof FORM_COMPONENT_TYPE.ALL_FIELDS> & {
  fields: IEnrichedField[][];
  fixedFields?: string[];
  groups?: string[];
  columns?: number;
  renderStrategy: 'main-form' | 'dialog-form';
  renderBehaviour?: 'all' | 'add';
  formId: string;
  formType: string;
  formValues?: Record<string, any>;
  sx?: BoxProps['sx'];
};

export const AllFields: React.FC<FieldProps<IAllFields>> = (props) => {
  const {
    fields = [],
    groups,
    columns,
    renderStrategy,
    fixedFields,
    renderBehaviour = 'all'
  } = useFieldApi<IAllFields>(props);
  const { formId, formType } = useFormBuilderTemplate();

  return (
    <AllFieldsContextProvider
      fields={fields as unknown as IEnrichedField[][]}
      fixedFields={fixedFields || []}
      groups={groups}
      columns={columns}
      showIcons={false}
      renderStrategy={renderStrategy}
      renderBehaviour={renderBehaviour}
      formId={formId}
      formType={formType || ''}
    >
      <AllFieldsWithContext />
    </AllFieldsContextProvider>
  );
};

export const AllFieldsWithContext = () => {
  const { context, setContext } = useAllFieldsContext();
  const { renderStrategy, formType } = context;
  const { addFields, removeFields } = useAdditionalFieldsState(formType);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (renderStrategy === 'dialog-form') {
      containerRef?.current?.focus();
    }
  }, [renderStrategy]);

  const handleIconClick = useCallback(
    (fieldName: string) => {
      if (renderStrategy === 'main-form') {
        removeFields([fieldName]);
      } else {
        addFields([fieldName]);
      }
      // re-focus the container to continue receiving keyboard events
      containerRef?.current?.focus();
    },
    [addFields, removeFields, renderStrategy]
  );

  const handleKeyDown: KeyboardEventHandler<HotKeys> = useCallback(
    (event) => {
      if (event.key === 'Control') {
        setContext({
          ...context,
          showIcons: true
        });
      }
    },
    [context, setContext]
  );

  const handleKeyUp: KeyboardEventHandler<HotKeys> = useCallback(
    (event) => {
      if (event.key === 'Control') {
        setContext({
          ...context,
          showIcons: false
        });
      }
    },
    [context, setContext]
  );

  return (
    <HotKeys onKeyUp={handleKeyUp} onKeyDown={handleKeyDown}>
      <div ref={containerRef} tabIndex={-1}>
        <AllFieldsRenderer onIconClick={handleIconClick} />
      </div>
    </HotKeys>
  );
};
