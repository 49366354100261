import { TextField } from '@oms/frontend-foundation';
import type { FieldProps } from '@oms/frontend-foundation';
import { isEqual } from 'lodash';
import React from 'react';
import type { FC } from 'react';
import type { DoubleSpinnerProps } from '../../fixatdl-form.types';

export const DoubleSpinner: FC<FieldProps<DoubleSpinnerProps>> = React.memo(
  ({ innerIncrement, ...props }) => {
    return <TextField {...props} type="number" step={innerIncrement} component="text-field" />;
  },
  isEqual
);
