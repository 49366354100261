import { TextField } from '@oms/frontend-foundation';
import type { FieldProps } from '@oms/frontend-foundation';
import { isEqual } from 'lodash';
import React from 'react';
import type { FC } from 'react';
import type { TextProps } from '../../fixatdl-form.types';

export const Text: FC<FieldProps<TextProps>> = React.memo((props) => {
  return <TextField {...props} component="text-field" />;
}, isEqual);
