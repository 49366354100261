import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type { MockUserSettingsInput, MockUserSettingsOutput } from './mock-user-settings.form-common';
import { mockUserSettingsContract } from './mock-user-settings.form-contract';
import type { MockUserSettingsContractType } from './mock-user-settings.form-contract';
import { Lifecycle, scoped } from 'tsyringe';

@scoped(Lifecycle.ContainerScoped)
export class MockServiceForForm {
  get(): Promise<MockUserSettingsOutput> {
    return Promise.resolve({
      email: 'john@doe.com',
      firstName: 'John',
      lastName: 'Doe',
      phoneNumber: '00000000000'
    });
  }

  save(_values: MockUserSettingsOutput) {
    return Promise.resolve();
  }
}

export const mockUserSettingsBuilder = FormBuilder.create<MockUserSettingsInput, MockUserSettingsOutput>(
  'mock-user-settings-form'
)
  .contract<MockUserSettingsContractType>(mockUserSettingsContract)
  .type('mock-user-settings')
  .sanitizer((s) =>
    s
      .input(async function initializeFormValues(_input, ctx) {
        const service = ctx.container.resolve(MockServiceForForm);
        const values = await service.get();
        return values;
      })
      .output(function sanitizeFormValuesToOutput(formValues) {
        const output: MockUserSettingsOutput = {
          email: formValues.email || '',
          firstName: formValues.firstName || '',
          lastName: formValues.lastName || '',
          phoneNumber: formValues.phoneNumber || ''
        };
        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(MockServiceForForm);
        await service.save(event.payload.output);
        alert('Form saved');
      }
      case FORM_EVENT_TYPE.RESET: {
        ctx.notify({
          type: 'RESET'
        });
      }
    }
  });

export type MockUserSettingsBuilderType = typeof mockUserSettingsBuilder;

export default mockUserSettingsBuilder;
