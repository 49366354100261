import type { MemoryDatabaseCollections, OfflineDatabaseCollections } from './collections';
import type { MemoryDatabase } from './memory-database';
import type { OfflineDatabase } from './offline-database';

export class AppDatabase {
  public offline: OfflineDatabaseCollections;
  public memory: MemoryDatabaseCollections;

  constructor(
    public memoryDb: MemoryDatabase,
    public offlineDb: OfflineDatabase
  ) {
    this.offline = offlineDb.collections;
    this.memory = memoryDb.collections;
  }

  public async destroy() {
    const offline = await this.offlineDb.destroy();
    const memory = await this.memoryDb.destroy();
    return { offline, memory };
  }
}
