export enum VGridInstance {
  Container = 'vgrid-container',
  InjectedServiceTokens = 'injected-service-tokens',
  InitialSettings = 'vgrid-initial-settings',
  Context = 'vgrid-context',
  Datasource = 'vgrid-datasource',
  RowSelection = 'vgrid-row-selection',
  GridActionEvents = 'vgrid-action-events',
  GridActionsConfig = 'grid-actions-config',
  ToolbarStrategies = 'toolbar-strategies'
}
