import React from 'react';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { Box as BoxComp } from '@oms/shared-frontend/ui-design-system';
import type { FieldProps, ICommonField, Field } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';

export interface IBoxField extends ICommonField<typeof FORM_COMPONENT_TYPE.BOX> {
  fields: Field[][];
}

export const Box: React.FC<FieldProps<IBoxField>> = (props) => {
  const { renderForm } = useFormApi();
  const { fields = [], style = {}, sx = {} } = useFieldApi<IBoxField>(props);

  return (
    <BoxComp style={style} sx={sx}>
      {fields.map((fields: Field[]) => renderForm(fields))}
    </BoxComp>
  );
};
