import { DateFormatters, DATE_FORMATTERS } from './date.formatters';
import { BooleanFormatters, BOOLEAN_FORMATTERS } from './boolean.formatters';
import { VALUE_FORMATTERS } from './formatters';
import { ValueFormatterFunc } from '@ag-grid-community/core';

export type DefaultFormatters = DateFormatters | BooleanFormatters;

export const DEFAULT_VALUE_FORMATTERS: Partial<Record<DefaultFormatters, ValueFormatterFunc>> = {
  ...DATE_FORMATTERS,
  ...VALUE_FORMATTERS,
  ...BOOLEAN_FORMATTERS
};
