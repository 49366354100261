import React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import * as styles from './dropdown-menu.css';
import type {
  DropdownMenuContentProps,
  DropdownMenuItemProps,
  DropdownMenuCheckboxItemProps,
  DropdownMenuRadioGroupProps,
  DropdownMenuRadioItemProps,
  DropdownMenuArrowProps,
  DropdownMenuLabelProps,
  DropdownMenuGroupProps,
  DropdownMenuItemIndicatorProps,
  DropdownMenuSeparatorProps,
  DropdownMenuProps,
  DropdownMenuTriggerProps,
  DropdownMenuSubTriggerProps,
  DropdownMenuSubContentProps
} from '@radix-ui/react-dropdown-menu';
import { styledForwardRef } from '../../system/utils/component-factory';

export const DropdownMenuRoot = DropdownMenuPrimitive.Root;
export type DropdownMenuRootProps = DropdownMenuProps;

export const DropdownMenuPortal = DropdownMenuPrimitive.DropdownMenuPortal;

export const DropdownMenuSub = DropdownMenuPrimitive.Sub;

export const DropdownMenuTrigger = styledForwardRef<DropdownMenuTriggerProps>(DropdownMenuPrimitive.Trigger);
export const DropdownMenuSubTrigger = styledForwardRef<DropdownMenuSubTriggerProps>(
  DropdownMenuPrimitive.SubTrigger
);

export const DropdownMenuGroup = styledForwardRef<DropdownMenuGroupProps>(DropdownMenuPrimitive.Group);

export const DropdownMenuContent = styledForwardRef<DropdownMenuContentProps>(
  DropdownMenuPrimitive.Content,
  styles.dropdownMenuContent
);

export const DropdownMenuSubContent = styledForwardRef<DropdownMenuSubContentProps>(
  DropdownMenuPrimitive.SubContent,
  styles.dropdownMenuContent
);

export const RightSlot = styledForwardRef<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>(
  'div',
  styles.dropdownMenuRightSlot
);

export const DropdownMenuTriggerItem = styledForwardRef<DropdownMenuTriggerProps>(
  DropdownMenuPrimitive.Trigger,
  styles.dropdownMenuTriggerItem
);

export const DropdownMenuItem = styledForwardRef<DropdownMenuItemProps>(
  DropdownMenuPrimitive.Item,
  styles.dropdownMenuItem
);

/** Use within `DropdownMenuSubTrigger` to wrap label. This gives the same appearance as other menu items. */
export const DropdownMenuSubTriggerItem = styledForwardRef<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>('div', styles.dropdownMenuItem);

export const DropdownMenuCheckboxItem = styledForwardRef<DropdownMenuCheckboxItemProps>(
  DropdownMenuPrimitive.CheckboxItem,
  styles.dropdownMenuItem
);

export const DropdownMenuRadioGroup = styledForwardRef<DropdownMenuRadioGroupProps>(
  DropdownMenuPrimitive.RadioGroup
);

export const DropdownMenuRadioItem = styledForwardRef<DropdownMenuRadioItemProps>(
  DropdownMenuPrimitive.RadioItem,
  styles.dropdownMenuItem
);

export const DropdownMenuLabel = styledForwardRef<DropdownMenuLabelProps>(
  DropdownMenuPrimitive.Label,
  styles.dropdownMenuLabel
);

export const DropdownMenuSeparator = styledForwardRef<DropdownMenuSeparatorProps>(
  DropdownMenuPrimitive.Separator,
  styles.dropdownMenuSeparator
);

export const DropdownMenuItemIndicator = styledForwardRef<DropdownMenuItemIndicatorProps>(
  DropdownMenuPrimitive.ItemIndicator,
  styles.dropdownMenuIndicator
);

/** Use if indicator should be placed within right slot */
export const DropdownMenuItemRightSlotIndicator = styledForwardRef<DropdownMenuItemIndicatorProps>(
  DropdownMenuPrimitive.ItemIndicator,
  styles.dropdownMenuIndicatorBase
);

export const DropdownMenuArrow = styledForwardRef<DropdownMenuArrowProps>(
  DropdownMenuPrimitive.ItemIndicator,
  styles.dropdownMenuArrow
);
