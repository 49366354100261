import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useMontageState } from '@app/data-access/services/trading/montage/hooks/use-montage-state.hook';
import WorkingOpenQuantityService from '@app/data-access/services/reference-data/working-open-quantity/working-open-quantity.subscriptions.service';
import { APP_DISPLAY_FIELD_COMPONENT_TYPE, AppDisplayGrid, useService } from '@oms/frontend-foundation';
import type { AppDisplayGridProps } from '@oms/frontend-foundation';
import type { WorkingOpenAggregate } from '@oms/generated/frontend';
import { Flex } from '@oms/shared-frontend/ui-design-system';
import type { Sprinkles } from '@oms/shared-frontend/ui-design-system';
import { useMontageLayout } from './use-montage-layout.hooks';
import { useUser } from '@app/data-access/services/system/auth/auth.hooks';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';
import { PositionsService } from '@app/data-access/services/trading/positions/positions.service';

const flexSx: Sprinkles = {
  backgroundColor: 'layout.level2',
  borderRadius: 'md',
  padding: 'small'
};

const getDisplayGridItems = (
  { openBuy, openSell, workingBuy, workingSell }: WorkingOpenAggregate,
  { positionQuantity }: { positionQuantity: number }
): AppDisplayGridProps['items'] => {
  return [
    {
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: 'BUY',
        textProps: { sx: { color: 'Green.400' } }
      }
    },
    {
      label: 'W',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: workingBuy,
        format: 'quantity'
      },
      colSpan: 2
    },
    {
      label: 'O',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: openBuy,
        format: 'quantity'
      },
      colSpan: 7
    },
    {
      label: 'P',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: positionQuantity,
        format: 'quantity'
      },
      colSpan: 6
    },
    {
      label: 'W',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: workingSell,
        format: 'quantity'
      },
      colSpan: 2
    },
    {
      label: 'O',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: openSell,
        format: 'quantity'
      },
      colSpan: 2
    },
    {
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: 'SELL',
        textProps: { sx: { color: 'Red.400' } }
      }
    }
  ];
};

const gridProps: AppDisplayGridProps['gridProps'] = {
  columns: 21,
  rows: 1
};

export const OrderPositions: FC = () => {
  const [workingOpenAggregate, setWorkingOpenAggregate] = useState<WorkingOpenAggregate | undefined>(
    undefined
  );
  const [positionQuantity, setPositionQuantity] = useState<number>(0);
  const { layout } = useMontageLayout();
  const { instrumentId } = useMontageState();
  const user = useUser();
  const scopedActorId = useScopedActorId();

  const datasourceService = useService(WorkingOpenQuantityService);
  const positionsService = useService(PositionsService);

  useEffect(() => {
    if (!instrumentId || !user?.id) return;
    const sub = datasourceService.watchAll$({ ownerId: user?.id, instrumentId }).subscribe((data) => {
      if (data.error) {
        return;
      }
      if (data.results) {
        setWorkingOpenAggregate(data.results[0]);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [datasourceService, instrumentId, user?.id]);

  useEffect(() => {
    if (!scopedActorId) {
      return setPositionQuantity(0);
    }

    const sub = positionsService
      .watchPositionsQuantityUpdated$(scopedActorId)
      .subscribe((positionQuantity) => {
        setPositionQuantity(positionQuantity);
      });
    return () => {
      sub.unsubscribe();
    };
  }, [positionsService, scopedActorId]);

  const isVisible = instrumentId && workingOpenAggregate && (layout === 'expanded' || layout === 'standard');

  const displayGridItems = useMemo(
    () =>
      workingOpenAggregate
        ? getDisplayGridItems(workingOpenAggregate, {
            positionQuantity: positionQuantity
          })
        : [],
    [workingOpenAggregate, positionQuantity]
  );

  return isVisible ? (
    <Flex direction={'row'} sx={flexSx}>
      <AppDisplayGrid
        layout="horizontal"
        items={displayGridItems}
        width="100%"
        gridProps={gridProps}
        labelMargin={6}
      />
    </Flex>
  ) : null;
};
