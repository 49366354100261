import type { RegistryDefinitionDialogWithCompKey } from '@app/app-config/registry.config';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { useCurrentAppWidgetActorProps, useCurrentAppWindow } from '@app/common/workspace/workspace.hooks';
import { useService } from '@oms/frontend-foundation';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import type { AnyRecord } from '@valstro/workspace';
import { Actor, PROCESS_ID } from '@valstro/workspace';
import { useMemo } from 'react';
import type { Observable } from 'rxjs';
import { filter, firstValueFrom, map, merge, take, takeUntil } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { getWorkspaceEvent$ } from '../workspace/workspace.rxjs';
import { BaseWindowOpenable, getDialogId, internalOpenInWindowActor } from './common.open';
import { testScoped } from '@app/workspace.registry';

/**
 * Dialog Events --------------------------------------------------------------
 * -----------------------------------------------------------------------------
 */
export const DIALOG_EVENT_TYPE = {
  CLOSED: 'CLOSED',
  CANCEL: 'CANCEL',
  OK: 'OK',
  FAIL: 'FAIL',
  CUSTOM: 'CUSTOM',
  UNOPENED: 'UNOPENED',
  OPENED: 'OPENED'
} as const;

export type DialogEventType = (typeof DIALOG_EVENT_TYPE)[keyof typeof DIALOG_EVENT_TYPE];

interface DialogEvent<T = unknown, P = unknown> {
  type: T;
  payload?: P;
  meta?: {
    dialogId: string;
    sourceId: string;
  };
}

export type DialogOpenedEvent<P = unknown> = DialogEvent<typeof DIALOG_EVENT_TYPE.OPENED, P>;
export type DialogUnopenedEvent<P = unknown> = DialogEvent<typeof DIALOG_EVENT_TYPE.UNOPENED, P>;

export type DialogOkEvent<P = unknown> = DialogEvent<typeof DIALOG_EVENT_TYPE.OK, P>;

export type DialogFailEvent = DialogEvent<
  typeof DIALOG_EVENT_TYPE.FAIL,
  {
    reason?: string;
  }
>;

export type DialogClosedEvent = DialogEvent<typeof DIALOG_EVENT_TYPE.CLOSED, unknown>;
export type DialogCancelEvent = DialogEvent<typeof DIALOG_EVENT_TYPE.CANCEL, unknown>;
export type DialogCustomEvent<P = unknown> = DialogEvent<typeof DIALOG_EVENT_TYPE.CUSTOM, P>;
export type DialogEventUnion =
  | DialogOkEvent
  | DialogCancelEvent
  | DialogCustomEvent
  | DialogFailEvent
  | DialogClosedEvent
  | DialogOpenedEvent;

export const dialogEvent$ = new BroadcastSubject<DialogEventUnion>('dialog-event');

export const getDialogClosed$ = (workspace: AppWorkspace, dialogId: string) =>
  getWorkspaceEvent$(workspace, 'actor/destroyed').pipe(
    filter((p) => p.id === dialogId),
    map(
      () =>
        ({
          type: DIALOG_EVENT_TYPE.CLOSED
        }) as DialogClosedEvent
    )
  );

const getDialogOpened$ = (workspace: AppWorkspace, dialogId: string) =>
  getWorkspaceEvent$(workspace, 'actor/created').pipe(
    filter((p) => p.id === dialogId),
    map(
      () =>
        ({
          type: DIALOG_EVENT_TYPE.OPENED
        }) as DialogOpenedEvent
    )
  );

const getDialogCancelled$ = (dialogId: string) =>
  dialogEvent$
    .asObservable()
    .pipe(filter((p) => p?.meta?.dialogId === dialogId && p.type === DIALOG_EVENT_TYPE.CANCEL));

export const getDialogClosedOrCancelled$ = (workspace: AppWorkspace, dialogId: string) =>
  merge(getDialogClosed$(workspace, dialogId), getDialogCancelled$(dialogId)).pipe();

const getDialogActionEvent$ = (dialogId: string) =>
  dialogEvent$
    .asObservable()
    .pipe(
      filter(
        (p) =>
          p?.meta?.dialogId === dialogId &&
          p.type !== DIALOG_EVENT_TYPE.CLOSED &&
          p.type !== DIALOG_EVENT_TYPE.CANCEL &&
          p.type !== DIALOG_EVENT_TYPE.OPENED
      )
    );

export const getDialogEvents$ = (workspace: AppWorkspace, dialogId: string) =>
  merge(
    getDialogClosed$(workspace, dialogId),
    getDialogCancelled$(dialogId),
    getDialogOpened$(workspace, dialogId),
    getDialogActionEvent$(dialogId)
  ).pipe(map((p) => p as DialogEventUnion));

export const getDialogEventsAfterOpened$ = (workspace: AppWorkspace, dialogId: string) =>
  getDialogEvents$(workspace, dialogId).pipe(filter((p) => p.type !== DIALOG_EVENT_TYPE.OPENED));

/**
 * Dialog
 * -----------------------------------------------------------------------------
 */
export class Dialog<_TProps> extends BaseWindowOpenable<_TProps> {
  /**
   * Get a dialog by id.
   *
   * @param id - Dialog id
   * @returns Dialog
   */
  static async get<TProps>(id: string) {
    const actor = await Actor.get<AppWindowActorSchema>(id);
    if (actor.id === undefined) {
      throw new Error('Actor is undefined');
    }
    return new Dialog<TProps>(actor);
  }

  /**
   * Open a dialog using the dialog definition.
   *
   * @param definition - Dialog definition
   * @param parentActorOrId - Parent actor or parent actor id
   * @returns Dialog
   */
  static async open<TProps>(
    definition: RegistryDefinitionDialogWithCompKey<TProps>,
    parentActorOrId: Actor | string,
    lazy?: boolean
  ) {
    const [_, actor] = await internalOpenInWindowActor<TProps>(definition, parentActorOrId, lazy);
    if (actor.id === undefined) {
      throw new Error('Actor is undefined');
    }
    return new Dialog<TProps>(actor);
  }
}

/**
 * DialogListenApi - The api returned from DialogService.listen()
 */
export type DialogListenApi = {
  closed$: Observable<DialogClosedEvent>;
  events$: Observable<DialogEventUnion>;
  eventsAfterOpened$: Observable<DialogEventUnion>;
  eventsUntilClosedOrCancelled$: Observable<DialogEventUnion>;
  awaitFirstEvent: Promise<DialogEventUnion>;
};

/**
 * OpenDialogApi - The api returned from DialogService.open()
 */
export type OpenDialogApi<TProps> = readonly [Dialog<TProps>, DialogListenApi];

/**
 * DialogService - Opening dialogs and listening for events back.
 *
 * @example
 * ```ts
 * const [dialog, api] = await dialogService.open(definition, 'parent-id');
 *
 * api.firstEvent.then((event) => {
 *   switch (event.type) {
 *    case DIALOG_EVENT_TYPE.OK:
 *      // Do something
 *      break;
 *    case DIALOG_EVENT_TYPE.CLOSED:
 *    case DIALOG_EVENT_TYPE.CANCEL:
 *      // Do something
 *      break;
 *   }
 * });
 * ```
 */
@testScoped
@singleton()
export class DialogService {
  private _openDialogs = new Map<string, Dialog<any>>();
  constructor(@inject(AppWorkspace) private _workspace: AppWorkspace) {}

  /**
   * Open a dialog and return the dialog and listen api.
   *
   * @param definition - Dialog definition
   * @param parentActorOrId - Parent actor or parent actor id
   * @returns [Dialog<TProps>, DialogListenApi] - A tuple of the dialog and the listen api
   */
  async open<TProps>(
    definition: RegistryDefinitionDialogWithCompKey<TProps>,
    parentActorOrId: Actor | string
  ): Promise<OpenDialogApi<TProps>> {
    const dialogId = getDialogId(definition.key, parentActorOrId);

    // If dialog is already open, return existing dialog
    const existingDialog = this._openDialogs.get(dialogId);
    if (existingDialog) {
      return this._getDialogApi(existingDialog as Dialog<TProps>);
    }

    // Generate events$ & closed$ observables
    const listenResponse = this.listen(dialogId);

    // Clean up cached open dialogs on close
    listenResponse.closed$.pipe(take(1)).subscribe(() => {
      this._openDialogs.delete(dialogId);
    });

    // Otherwise, create new dialog & listen for close to remove from open dialogs
    const dialog = await Dialog.open(definition, parentActorOrId);
    return [dialog, listenResponse] as const;
  }

  /**
   * Listen for events from a dialog.
   *
   * @param dialogId - Dialog id
   * @returns DialogListenApi - A listen api for the dialog (see example above)
   */
  public listen(dialogId: string): DialogListenApi {
    const baseArgs = [this._workspace, dialogId] as const;
    const events$ = getDialogEvents$(...baseArgs);
    const eventsAfterOpened$ = getDialogEventsAfterOpened$(...baseArgs);
    const eventsUntilClosedOrCancelled$ = events$.pipe(takeUntil(getDialogClosedOrCancelled$(...baseArgs)));
    const closed$ = getDialogClosed$(...baseArgs);
    const awaitFirstEvent = firstValueFrom(eventsAfterOpened$);
    return {
      closed$,
      events$,
      eventsAfterOpened$,
      eventsUntilClosedOrCancelled$,
      awaitFirstEvent
    };
  }

  /**
   * Helper to get the dialog api from a dialog.
   *
   * @param dialog - Dialog<TProps>
   * @returns OpenDialogApi<TProps>
   */
  private _getDialogApi<TProps>(dialog: Dialog<TProps>): OpenDialogApi<TProps> {
    const listenResponse = this.listen(dialog.id);
    return [dialog, listenResponse] as const;
  }
}

/**
 * Get the dialog service in hook form.
 */
export function useDialogService() {
  return useService(DialogService);
}

/**
 * Helper for working with dialog events
 */
export function useDialogEvents() {
  const windowActor = useCurrentAppWindow();
  const dialogId = windowActor.id;
  const sourceId = windowActor.parentId || PROCESS_ID.LEADER;

  return useMemo(() => {
    return {
      emit: (event: DialogEventUnion) => {
        dialogEvent$.next({ ...event, meta: { dialogId, sourceId } });
      },
      events$: dialogEvent$.pipe(filter((p) => p?.meta?.dialogId === dialogId))
    } as const;
  }, [dialogId, sourceId]);
}

/**
 * Hook API for working with a dialog component itself
 */
export type UseCurrentDialogApi<TProps extends AnyRecord> = readonly [
  TProps,
  {
    readonly setProps: (propsDelta: Partial<TProps>) => Promise<void>;
    readonly sourceId: string | undefined;
    readonly emit: (event: DialogEventUnion) => void;
    readonly close: () => Promise<void>;
  }
];

/**
 * Hook for working with a dialog component itself.
 * This is useful for creating a dialog component that communicates events back to its parent/trigger
 *
 * @returns UseCurrentDialogApi
 */
export function useCurrentDialogApi<TProps extends AnyRecord>(): UseCurrentDialogApi<TProps> {
  const windowActor = useCurrentAppWindow();
  const dialogId = windowActor.id;
  const sourceId = windowActor.parentId;
  const events = useDialogEvents();

  const [props, setProps] = useCurrentAppWidgetActorProps<TProps>();
  return useMemo(() => {
    return [
      props,
      {
        setProps,
        sourceId,
        dialogId,
        emit: (event) => events.emit(event),
        close: () => windowActor.operations.close()
      }
    ] as const;
  }, [props, setProps, dialogId, sourceId, events, windowActor.operations]);
}
