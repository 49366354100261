import { useMemo } from 'react';
import { COMMON_ACTOR_TYPE } from '@valstro/workspace';
import { useClosestActorByType } from '@valstro/workspace-react';
import { cleanMaybe } from '@oms/shared/util';
import type { AppWidgetActorSchema, AppWindowActorSchema } from '@app/app-config/workspace.config';
import { useClosestAppFlexLayoutActor } from '@app/common/workspace/workspace.hooks';

export const useScopedActorId = (): string => {
  const flexLayout = useClosestAppFlexLayoutActor(); // Note, still need to rename this to "parent"
  const widget = cleanMaybe(useClosestActorByType<AppWidgetActorSchema>(COMMON_ACTOR_TYPE.WIDGET));
  const window = cleanMaybe(useClosestActorByType<AppWindowActorSchema>(COMMON_ACTOR_TYPE.WINDOW));
  return useMemo(() => {
    // Scope the state to the flexLayout FIRST, then the widget, then window... if all else fails, we throw
    const scopedActorId = flexLayout?.id || widget?.id || window?.id;
    if (!scopedActorId) {
      throw new Error('Could not resolve scoped actor ID');
    }
    return scopedActorId;
  }, [flexLayout?.id, widget?.id, window?.id]);
};
