import type { Validator } from '@data-driven-forms/react-form-renderer';
import type {
  FORM_COMPONENT_TYPE,
  FORM_COMPONENT_TYPE as FOUNDATION_FORM_COMPONENT_TYPE,
  ICommonField,
  ICommonSelectField
} from '@oms/frontend-foundation';
import type { LayoutAction, DefinedColors } from '../types';
import type { Action } from '@app/actions/types/action.types';
import type { FieldOption, IDisplayField } from '@oms/frontend-foundation';
import { COMMANDS_TO_META } from '@app/actions/commands/command.registry.types';
import type { CommandMeta, ActionCommands } from '@app/actions/commands/command.registry.types';

export const actionButtonSizeOptions = (): FieldOption<Action['size']>[] => [
  { label: 'XS', value: 'XS' },
  { label: 'S', value: 'S' },
  { label: 'M', value: 'M' },
  { label: 'L', value: 'L' },
  { label: 'XL', value: 'XL' }
];

export const actionButtonColorOptions = (): FieldOption<DefinedColors>[] => [
  { label: 'Blue', value: 'Blue' },
  { label: 'Light Blue', value: 'LightBlue' },
  { label: 'Dark Blue', value: 'DarkBlue' },
  { label: 'Red', value: 'Red' },
  { label: 'Grey', value: 'Secondary' }
];

export const ACTION_COMMANDS_TO_LABELS: Record<ActionCommands, string> = {
  create_unbound_to: 'Create Trading Order',
  montage_bump_price: 'Bump',
  montage_target_trading_order: 'Target'
};

export const ACTION_LABELS_TO_COMMANDS: Record<string, ActionCommands> = Object.entries(
  ACTION_COMMANDS_TO_LABELS
).reduce(
  (result, [commandId, label]) => {
    result[label] = commandId as ActionCommands;
    return result;
  },
  {} as Record<string, ActionCommands>
);

export const actionButtonCommands = (): FieldOption<ActionCommands>[] =>
  Object.entries(ACTION_COMMANDS_TO_LABELS).map(([commandId, label]) => ({
    label,
    value: commandId as unknown as ActionCommands
  }));

export const buttonLayoutActionSchema: (
  fieldName: string,
  values: LayoutAction
) => (
  | ICommonField<
      | (typeof FORM_COMPONENT_TYPE)[keyof typeof FORM_COMPONENT_TYPE]
      | (typeof FOUNDATION_FORM_COMPONENT_TYPE)[keyof typeof FOUNDATION_FORM_COMPONENT_TYPE],
      any,
      Validator
    >
  | IDisplayField<Validator>
  | ICommonSelectField<
      | (typeof FORM_COMPONENT_TYPE)[keyof typeof FORM_COMPONENT_TYPE]
      | (typeof FOUNDATION_FORM_COMPONENT_TYPE)[keyof typeof FOUNDATION_FORM_COMPONENT_TYPE]
    >
)[] = (fieldName, values) => {
  const defaultCommandMeta: CommandMeta = {
    allowsConfirmation: true,
    allowsLabel: true,
    allowsColor: true,
    allowsSize: true,
    commandType: 'DialogWithDefaults'
  };

  const isVertical = values.locationId.includes('Vert');

  const commandMeta: CommandMeta = values.commandId
    ? COMMANDS_TO_META[ACTION_LABELS_TO_COMMANDS[values.commandId]] || defaultCommandMeta
    : defaultCommandMeta;

  const defaultDisplay: ReturnType<typeof buttonLayoutActionSchema> = [
    {
      component: 'display-field',
      name: `${fieldName}.label`,
      displayFieldComponentProps: {
        component: {
          type: 'Text',
          value: values.label
        },
        label: 'Label:'
      }
    },
    {
      component: 'display-field',
      name: `${fieldName}.command`,
      displayFieldComponentProps: {
        component: {
          type: 'Text',
          value: values.commandId
        },
        label: 'Command:'
      }
    },
    {
      component: 'select',
      name: `${fieldName}.size`,
      initialValue: values.size,
      options: actionButtonSizeOptions(),
      hideFormControls: true,
      style: { marginTop: 0 }
    },
    {
      component: 'select',
      name: `${fieldName}.color`,
      initialValue: values.color,
      options: actionButtonColorOptions(),
      hideFormControls: true,
      style: { marginTop: 0 }
    }
  ];

  return defaultDisplay.filter((d) => {
    const isLabelField = d.name.includes('label');
    const allowsLabel = isLabelField && commandMeta.allowsLabel;

    const isSizeField = d.name.includes('size');
    const allowsSize = isSizeField && commandMeta.allowsSize;

    const isColorField = d.name.includes('color');
    const allowsColor = isColorField && commandMeta.allowsColor;

    return (
      (isLabelField && allowsLabel) ||
      (isSizeField && allowsSize && !isVertical) ||
      (isColorField && allowsColor) ||
      (!isLabelField && !isColorField && !isSizeField)
    );
  });
};
