import { FORM_BUILDER_TEMPLATE_MAPPER } from '../builder/components/templates/form-builder.template-mapper';
import type { FoundationFormMappers } from '../common/form.contracts';
import { FORM_ACTION_MAPPER } from '../mappers/ddf-action-mapper';
import { FORM_COMPONENT_MAPPER } from '../mappers/ddf-mapper';
import { FORM_VALIDATOR_MAPPER } from '../mappers/ddf-validator-mapper';

export const DEFAULT_FORM_MAPPERS: FoundationFormMappers = {
  componentMapper: FORM_COMPONENT_MAPPER,
  validatorMapper: FORM_VALIDATOR_MAPPER,
  actionMapper: FORM_ACTION_MAPPER,
  conditionMapper: {},
  advancedSelectQueryMapper: {},
  formBuilderTemplateMapper: FORM_BUILDER_TEMPLATE_MAPPER,
  formBuilderMapper: {}
};
