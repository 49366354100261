import type { AnyRecord } from '@oms/frontend-foundation';
import type { VGridProps } from '../components/vgrid.component';
import { ComponentBuilder } from './component.builder';
import { RowSelection, RowSelectionBroadcastFn, VRowDoubleClickedEvent } from '../models/row.selection.model';
import { ComponentLocation } from '../models/v.component.model';

export class RowSelectionBuilder<TData extends AnyRecord, TProps extends AnyRecord = AnyRecord> {
  private _rowSelection: RowSelection<TData, TProps>;
  private _props: VGridProps<TData>;

  constructor(props: VGridProps<TData>) {
    this._props = props;
    this._rowSelection = {} as RowSelection<TData, TProps>;
  }

  public multiple(count?: number): RowSelectionBuilder<TData, TProps> {
    this._rowSelection.amount = count;
    this._props.rowSelection = !count || count > 1 ? 'multiple' : 'single';
    return this;
  }

  public single(): RowSelectionBuilder<TData, TProps> {
    this.multiple(1);
    return this;
  }

  public broadcast(broadcastFn: RowSelectionBroadcastFn<TData>): RowSelectionBuilder<TData, TProps> {
    this._rowSelection.broadcast = broadcastFn;
    return this;
  }

  public selectRowsOnFirstDataRender(rowIndexes: number[]): RowSelectionBuilder<TData, TProps> {
    this._rowSelection.selectedRowIndexesOnFirstDataRender = rowIndexes;
    return this;
  }

  public dblClick(cb: (e: VRowDoubleClickedEvent<TData>) => void): RowSelectionBuilder<TData, TProps> {
    this._rowSelection.dblClick = cb;
    return this;
  }

  public build(): RowSelection<TData, TProps> {
    return this._rowSelection;
  }

  public withComponent(
    cb: (builder: ComponentBuilder<TData, TProps>) => ComponentBuilder<TData, TProps>
  ): RowSelectionBuilder<TData, TProps> {
    const componentInstance = new ComponentBuilder<TData, TProps>({
      location: ComponentLocation.Selection
    });
    const builder = cb(componentInstance);
    const definition = builder.build();
    this._rowSelection.component = definition;
    return this;
  }
}
