import { useLeaderOrTabId } from '@app/common/workspace/workspace.hooks';
import { openInvestorOrderEntry } from '@app/generated/sdk';
import type { InvestorOrderEntryType } from '@app/widgets/trading/investor-order-entry/investor-order-entry.form-common';
import { useCallback } from 'react';

export const openInvestorOrderModify = async (
  windowId: string,
  mode: InvestorOrderEntryType,
  investorOrderId?: string
) => {
  await openInvestorOrderEntry(windowId, {
    title: mode === 'update' ? 'Modify Investor Order' : 'Investor Order Entry',
    form: {
      input: {
        entryType: mode,
        id: mode === 'update' ? investorOrderId : undefined
      }
    }
  });
};

export const useOpenModifyInvestorOrderDiablog = (mode: InvestorOrderEntryType, investorOrderId?: string) => {
  const leaderOrTabId = useLeaderOrTabId();
  return useCallback(() => {
    openInvestorOrderModify(leaderOrTabId, mode, investorOrderId).catch((e) => console.error(e));
  }, []);
};
