import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import type {
  PopoverContentProps as RadixPopoverContentProps,
  PopoverTriggerProps as RadixPopoverTriggerProps,
  PopoverProps as RadixPopoverProps
} from '@radix-ui/react-popover';
import { Box } from '../../system/components/box/box';
import type { BoxProps } from '../../system/components/box/box';
import { CloseButton } from '../button/close-button';
import clsx from 'clsx';
import * as styles from './popover.css';
import { lightMode } from '../../system/sprinkles.css';

export const Popover = PopoverPrimitive.Root;
export type PopoverProps = RadixPopoverProps;
export const PopoverArrow = PopoverPrimitive.Arrow;
export const PopoverClose = PopoverPrimitive.Close;
export const PopoverAnchor = PopoverPrimitive.Anchor;

export const PopoverTrigger = React.forwardRef<HTMLButtonElement, RadixPopoverTriggerProps>(
  ({ asChild = true, children, ...rest }, ref) => (
    <PopoverPrimitive.Trigger ref={ref} asChild={asChild} {...rest}>
      {children}
    </PopoverPrimitive.Trigger>
  )
);

export type PopoverContentProps = {
  hasArrow?: boolean;
  hasClose?: boolean;
  fixLightMode?: boolean;
} & RadixPopoverContentProps &
  BoxProps;

export const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(
  (
    { children, sx = {}, hasArrow = true, hasClose = true, fixLightMode = false, className, ...props },
    ref
  ) => {
    const { p, padding } = sx;
    const pad = p !== undefined ? p : padding !== undefined ? padding : 3;

    return (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content sideOffset={5} asChild={true} ref={ref} {...props}>
          <Box
            sx={{ p: pad, ...sx }}
            className={clsx(styles.popoverContent, fixLightMode && lightMode, className)}
          >
            {children}
            {hasClose && (
              <PopoverClose asChild={true}>
                <CloseButton
                  size="sm"
                  style={{
                    position: 'absolute',
                    top: '0.25rem',
                    right: '0.25rem'
                  }}
                />
              </PopoverClose>
            )}
            {hasArrow && <PopoverArrow className={clsx(styles.popoverArrow)} />}
          </Box>
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    );
  }
);
