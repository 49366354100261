import { CustomGraphQLError } from './custom-error';
import { ErrorTypeEnum } from './errors.types';

export type IUnauthorizedError = CustomGraphQLError<ErrorTypeEnum.UNAUTHORIZED, string>;

export class UnauthorizedError extends CustomGraphQLError<ErrorTypeEnum.UNAUTHORIZED, string> {
  constructor(message: string, code?: string) {
    super(message);
    this.extensions = {
      type: ErrorTypeEnum.UNAUTHORIZED,
      code: code || ErrorTypeEnum.UNAUTHORIZED,
      data: message
    };
  }
}
