import { DateTime } from 'luxon';
import type { TradeModifyValues } from '@app/widgets/trading/trade-modify/trade-modify.form-contract';

export const VALIDATE_TRADE_MODIFY_SETTLEMENT_DATE = 'validateTradeModifySettlementDate';

export const validateTradeModifySettlementDate =
  () => (settleDateValue: string | number | Date | undefined, allValues?: TradeModifyValues) => {
    if (!settleDateValue) {
      return null;
    }
    const message = 'Not after trade date.';
    const formValues: Partial<TradeModifyValues> = allValues ? allValues : {};
    const { tradeDateTime } = formValues || {};

    const settleDate =
      settleDateValue instanceof Date
        ? DateTime.fromJSDate(settleDateValue)
        : typeof settleDateValue === 'string'
          ? DateTime.fromISO(settleDateValue, { setZone: true })
          : DateTime.fromMillis(settleDateValue);
    const tradeDate = tradeDateTime
      ? typeof tradeDateTime === 'number'
        ? DateTime.fromMillis(tradeDateTime)
        : DateTime.fromISO(tradeDateTime, { setZone: true })
      : DateTime.now();
    const datesAreValid = settleDate && settleDate.isValid && tradeDate && tradeDate.isValid;

    if (!datesAreValid) {
      return message;
    }

    const sameDay = tradeDate.hasSame(settleDate, 'day');
    const isSameOrAfter = settleDate.toUTC().toMillis() >= tradeDate.toUTC().toMillis();

    return sameDay || isSameOrAfter ? null : message;
  };
