import { getAdvancedSelectQueryReturn } from '@oms/frontend-foundation';
import type { AdvancedSelectQueryFn } from '@oms/frontend-foundation';
import { TimezonesService } from '@app/data-access/services/reference-data/timezones/timezones.service';
import type { TimezoneFragment } from '@app/data-access/services/reference-data/timezones/timezones.service';
import { comboBoxItemFrom } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/frontend-foundation';

export const timezoneResultMapper = (
  timezone: TimezoneFragment
): ComboBoxItem<TimezoneFragment> | undefined =>
  comboBoxItemFrom.id(timezone, {
    id: ({ id }: TimezoneFragment) => id ?? '',
    label: ({ name }: TimezoneFragment) => name ?? ''
  });

export const watchAllTimezonesQuery: AdvancedSelectQueryFn = () => {
  return getAdvancedSelectQueryReturn<TimezoneFragment>({
    queryFn: new TimezonesService().watchAllStaticData$,
    resultMapper: timezoneResultMapper
  });
};

export default watchAllTimezonesQuery;
