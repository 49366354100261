import type { Maybe, Optional, RequireSome } from '@oms/shared/util-types';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import { useObservableState } from 'observable-hooks';
import { useMemo } from 'react';
import { filter, map } from 'rxjs';
import type { Observable } from 'rxjs';
import { useClosestAppFlexLayoutActor } from '@app/common/workspace/workspace.hooks';

// ------ Constants ---------------------------------------------------

export const FLEX_LAYOUT_EVENT_TYPE = {
  ON_ADDED_TAB: 'ON_ADDED_TAB',
  ON_SELECT_TAB: 'ON_SELECT_TAB',
  ON_MOVE_TAB: 'ON_MOVE_TAB',
  ON_DELETE_TAB: 'ON_DELETE_TAB'
} as const;

// ------ Types ---------------------------------------------------

export type FlexLayoutEventType = (typeof FLEX_LAYOUT_EVENT_TYPE)[keyof typeof FLEX_LAYOUT_EVENT_TYPE];

export type FlexLayoutWrapper<T> = T & {
  meta: {
    flexLayoutActorId: string;
  };
};

export type AnyFlexLayoutEventPayload = {
  selectedTabId?: string;
};

export type FlexLayoutAddTabEventPayload = AnyFlexLayoutEventPayload;

export type FlexLayoutSelectTabEventPayload = RequireSome<AnyFlexLayoutEventPayload, 'selectedTabId'>;

export type FlexLayoutMoveTabEventPayload = AnyFlexLayoutEventPayload & {
  fromId?: string;
  toId?: string;
};

export type FlexLayoutDeleteTabEventPayload = AnyFlexLayoutEventPayload & {
  deletedTabId: string;
};

export type FlexLayoutAddTabEvent = FlexLayoutWrapper<{
  type: typeof FLEX_LAYOUT_EVENT_TYPE.ON_ADDED_TAB;
  payload: FlexLayoutAddTabEventPayload;
}>;

export type FlexLayoutSelectTabEvent = FlexLayoutWrapper<{
  type: typeof FLEX_LAYOUT_EVENT_TYPE.ON_SELECT_TAB;
  payload: FlexLayoutSelectTabEventPayload;
}>;

export type FlexLayoutMoveTabEvent = FlexLayoutWrapper<{
  type: typeof FLEX_LAYOUT_EVENT_TYPE.ON_MOVE_TAB;
  payload: FlexLayoutMoveTabEventPayload;
}>;

export type FlexLayoutDeleteTabEvent = FlexLayoutWrapper<{
  type: typeof FLEX_LAYOUT_EVENT_TYPE.ON_DELETE_TAB;
  payload: FlexLayoutDeleteTabEventPayload;
}>;

export type FlexLayoutUnion =
  | FlexLayoutAddTabEvent
  | FlexLayoutSelectTabEvent
  | FlexLayoutMoveTabEvent
  | FlexLayoutDeleteTabEvent;

// ------ Private utils ---------------------------------------------------

const validatePayload = (
  payload: Maybe<Partial<FlexLayoutUnion['payload']>>,
  type: FlexLayoutUnion['type']
): FlexLayoutUnion['payload'] => {
  if (!payload) throw new Error('No payload data provided');
  if (type === 'ON_SELECT_TAB' && typeof payload.selectedTabId !== 'string') {
    throw new Error('Invalid selected tab id');
  }
  if (
    type === 'ON_DELETE_TAB' &&
    typeof (payload as Partial<FlexLayoutDeleteTabEventPayload>).deletedTabId !== 'string'
  ) {
    throw new Error('Invalid deleted tab id');
  }
  return payload as FlexLayoutUnion['payload'];
};

const validateMeta = (meta: Maybe<Partial<FlexLayoutUnion['meta']>>): FlexLayoutUnion['meta'] => {
  if (!meta) throw new Error('No meta data provided');
  if (typeof meta.flexLayoutActorId !== 'string') {
    throw new Error('Missing FlexLayout actor id in meta data');
  }
  return meta as FlexLayoutUnion['meta'];
};

// ------ Public utils ---------------------------------------------------

/**
 * Event bus
 */
export const flexLayoutEvent$ = new BroadcastSubject<FlexLayoutUnion>('flexlayout-events');

/**
 * Select Tab Observable
 */
export const getFlexLayoutSelectedTabNodeIdEvent$ = (
  flexLayoutActorId: string
): Observable<Optional<string>> => {
  return flexLayoutEvent$.pipe(
    filter(({ meta }) => meta.flexLayoutActorId === flexLayoutActorId),
    filter(({ type }) => {
      switch (type) {
        case 'ON_ADDED_TAB':
        case 'ON_SELECT_TAB':
        case 'ON_MOVE_TAB':
        case 'ON_DELETE_TAB':
          return true;
        default:
          return false;
      }
    }),
    map(({ payload }) => payload.selectedTabId)
  );
};

export function useCurrentFlexLayoutSelectedTabIdEvent$() {
  const flexLayoutActor = useClosestAppFlexLayoutActor();

  return useMemo(
    () => getFlexLayoutSelectedTabNodeIdEvent$(flexLayoutActor?.id || 'UNKNOWN_ACTOR_ID'),
    [flexLayoutActor]
  );
}

/**
 * Get the last selected tab id value from the flex layout select tab event
 *
 * @example
 * ```ts
 * const selectedTabId = useCurrentFlexLayoutSelectedTabIdEvent();
 * ```
 *
 * @returns string | null
 */
export function useCurrentFlexLayoutSelectedTabIdEvent() {
  return useObservableState(useCurrentFlexLayoutSelectedTabIdEvent$(), null);
}

export function emitTabEvent(
  type: typeof FLEX_LAYOUT_EVENT_TYPE.ON_ADDED_TAB,
  payload: Partial<FlexLayoutAddTabEventPayload>,
  meta: Partial<FlexLayoutUnion['meta']>
): void;

export function emitTabEvent(
  type: typeof FLEX_LAYOUT_EVENT_TYPE.ON_SELECT_TAB,
  payload: Partial<FlexLayoutSelectTabEventPayload>,
  meta: Partial<FlexLayoutUnion['meta']>
): void;

export function emitTabEvent(
  type: typeof FLEX_LAYOUT_EVENT_TYPE.ON_MOVE_TAB,
  payload: Partial<FlexLayoutMoveTabEventPayload>,
  meta: Partial<FlexLayoutUnion['meta']>
): void;

export function emitTabEvent(
  type: typeof FLEX_LAYOUT_EVENT_TYPE.ON_DELETE_TAB,
  payload: Partial<FlexLayoutDeleteTabEventPayload>,
  meta: Partial<FlexLayoutUnion['meta']>
): void;

// Implementation only ---------- /
export function emitTabEvent(
  type: Maybe<FlexLayoutUnion['type']>,
  payload: Maybe<Partial<FlexLayoutUnion['payload']>>,
  meta: Maybe<Partial<FlexLayoutUnion['meta']>>
): void {
  if (typeof type !== 'string') {
    throw new Error('Invalid type');
  }
  const validPayload = validatePayload(payload, type);
  const validMeta = validateMeta(meta);
  const value = { type, payload: validPayload, meta: validMeta } as FlexLayoutUnion;
  flexLayoutEvent$.next(value);
}
