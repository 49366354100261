import { InfoCircledIcon } from '@radix-ui/react-icons';
import * as styles from './style.css';
import type { AlertLevelMap } from './types';
import type { BackgroundColor, Icon, IconColor } from './types.internal';
import { ErrorIcon, WarningIcon } from '../../icons/icons';

export const AlertBannerClassName: Partial<Record<keyof typeof styles.alertBanner, string>> = {
  stackItem: 'alert-banner-stack-item',
  wrapper: 'alert-banner-wrapper',
  iconAndTitleWrapper: 'alert-banner-icon-and-title-wrapper',
  iconOuter: 'alert-banner-icon-outer',
  icon: 'alert-banner-icon',
  title: 'alert-banner-title',
  messageWrapper: 'alert-banner-message-wrapper',
  message: 'alert-banner-message'
};

export const defaultTitles: AlertLevelMap<string> = {
  error: 'Errors',
  warning: 'Warnings',
  info: 'Info'
};

export const defaultIcons: AlertLevelMap<Icon> = {
  error: ErrorIcon,
  warning: WarningIcon,
  info: InfoCircledIcon
};

export const defaultIconColors: AlertLevelMap<IconColor> = {
  error: 'Red.500',
  warning: 'Yellow.500',
  info: 'BrandBlue.500'
};

export const defaultBackgroundColors: Partial<AlertLevelMap<BackgroundColor>> = {
  error: 'Red.700',
  default: 'layout.level3'
};
