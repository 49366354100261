import * as React from 'react';
import { cloneElement, useRef, useState, useImperativeHandle } from 'react';
import type { CheckboxGroupProps } from '../checkbox/checkbox-group';
import type { RadioProps } from './radio';
import { useId } from '../../system/hooks/use-id';
import { Stack } from '../../layout/stack/stack';
import { __DEV__ } from '../../system/utils/assertion';
import { getValidChildren } from '../../system/utils/children';

type OmittedProps = 'shape' | 'value' | 'defaultValue' | 'onChange';

export interface RadioGroupProps extends Omit<CheckboxGroupProps, OmittedProps> {
  /**
   * The initial value of the radio group
   */
  defaultValue?: RadioProps['value'];
  /**
   * The value of the radio group
   */
  value?: RadioProps['value'];

  /**
   * The callback fired when any children Radio is checked or unchecked
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: RadioProps['value']) => void;
}

type RadioGroupElement =
  | {
      focus: () => void;
    }
  | undefined;

export const RadioGroup = React.forwardRef<RadioGroupElement, RadioGroupProps>((props, ref) => {
  const {
    onChange,
    name,
    size,
    defaultValue,
    isInline,
    align = 'center',
    value: valueProp,
    children,
    ...rest
  } = props;
  const { current: isControlled } = useRef(valueProp != null);
  const [value, setValue] = useState(defaultValue || null);
  const _value = isControlled ? valueProp : value;

  const rootRef = useRef<HTMLDivElement>(null);

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isControlled) {
      setValue(event.target.value);
    }

    if (onChange) {
      onChange(event, event.target.value);
    }
  };

  // If no name is passed, we'll generate a random, unique name
  const fallbackName = `radio-${useId()}`;
  const _name = name || fallbackName;

  const validChildren = getValidChildren(children);

  const clones = validChildren.map((child, index) => {
    return (
      <div key={index} className={child.props.className}>
        {cloneElement(child, {
          size,
          name: _name,
          onChange: _onChange,
          isChecked: child.props.value === _value,
          verticalAlign: 'bottom'
        })}
      </div>
    );
  });

  // Calling focus() on the radiogroup should focus on the selected option or first enabled option
  useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        let input: HTMLInputElement | null =
          rootRef.current?.querySelector('input:not(:disabled):checked') || null;

        if (!input) {
          input = rootRef.current?.querySelector('input:not(:disabled)') || null;
        }

        if (input) {
          input.focus();
        }
      }
    }),
    []
  );

  return (
    <Stack
      role="radiogroup"
      align={align}
      direction={isInline ? 'horizontal' : 'vertical'}
      ref={rootRef}
      {...rest}
    >
      {clones}
    </Stack>
  );
});

if (__DEV__) {
  RadioGroup.displayName = 'RadioGroup';
}
