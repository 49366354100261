import { ColGroupDef } from '@ag-grid-community/core';
import { ColumnBuilder } from './column.builder';
import { AnyRecord } from '@oms/frontend-foundation';

interface IBaseColumnGroupBuilder<TData extends AnyRecord> {
  build(): ColGroupDef<TData>;
}
export abstract class BaseColumnGroupBuilder<
  TData extends AnyRecord,
  TBuilder extends IBaseColumnGroupBuilder<TData>,
  TColDef extends ColGroupDef<TData> = ColGroupDef<TData>
> implements IBaseColumnGroupBuilder<TData>
{
  protected _colGroupDef: TColDef = {} as TColDef;
  protected abstract create(): TBuilder;
  protected abstract get instance(): TBuilder;

  public locked(locked?: boolean): TBuilder {
    this._colGroupDef.marryChildren = locked === undefined || locked;
    return this.instance;
  }

  public header(header: string): TBuilder {
    this._colGroupDef.headerName = header;
    return this.instance;
  }

  public headerComp(value: ColGroupDef<TData>['headerGroupComponent']) {
    this._colGroupDef.headerGroupComponent = value;
    return this.instance;
  }

  public headerParams(value: ColGroupDef<TData>['headerGroupComponentParams']) {
    this._colGroupDef.headerGroupComponentParams = value;
    return this.instance;
  }

  public titleBar() {
    this._colGroupDef.headerClass = `${this._colGroupDef.headerClass || ''} ag-header-group-title-bar`.trim();
    return this.instance;
  }

  public titleText(type: 'buy' | 'sell') {
    this._colGroupDef.headerClass = `${
      this._colGroupDef.headerClass || ''
    } ag-group-title ag-group-title--${type}`.trim();
    return this.instance;
  }

  public marryChildren(marryChildren: boolean = true) {
    this._colGroupDef.marryChildren = marryChildren;
    return this.instance;
  }

  public column(cb: (builder: ColumnBuilder<TData>) => ColumnBuilder<TData>): TBuilder {
    this._colGroupDef.children = this._colGroupDef.children || [];
    const columnBuilder = new ColumnBuilder<TData>();
    const extendedBuilder = cb(columnBuilder);
    const colDef = extendedBuilder.build();
    this._colGroupDef.children.push(colDef);
    return this.instance;
  }

  public columns(cbs: ((builder: ColumnBuilder<TData>) => ColumnBuilder<TData>)[]): TBuilder {
    cbs.forEach((cb) => this.column(cb));
    return this.instance;
  }

  public group(cb: (builder: TBuilder) => TBuilder): TBuilder {
    this._colGroupDef.children = this._colGroupDef.children || [];
    const child = this.create();
    const childBuilder = cb(child);
    const colDef = childBuilder.build();
    this._colGroupDef.children.push(colDef);
    return this.instance;
  }

  public id(groupId: string): TBuilder {
    this._colGroupDef.groupId = groupId;
    return this.instance;
  }

  public open(): TBuilder {
    this._colGroupDef.openByDefault = true;
    return this.instance;
  }

  public build(): ColGroupDef<TData> {
    return this._colGroupDef as ColGroupDef<TData>;
  }
}

export class ColumnGroupBuilder<TData extends AnyRecord> extends BaseColumnGroupBuilder<
  TData,
  ColumnGroupBuilder<TData>
> {
  protected create(): ColumnGroupBuilder<TData> {
    return new ColumnGroupBuilder<TData>();
  }
  protected get instance() {
    return this;
  }
}
