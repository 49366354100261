import { useEffect, useRef, type Ref } from 'react';
import { useMergedRef } from '../system/hooks/use-merge-ref';

export function useAutoFocus<T extends HTMLElement>(ref: Ref<T>, autoFocus: boolean = false) {
  const _ref = useRef<T>(null);
  // Note: We are NOT using HTML autoFocus property because it runs too quickly to register in our form library
  // Therefore, we are using a useEffect to focus the input after it has been rendered
  // Add using setTimeout to ensure it runs after the input has been rendered on the next event loop (after DDF)
  useEffect(() => {
    if (autoFocus && _ref.current) {
      setTimeout(() => _ref.current?.focus(), 0);
    }
  }, [autoFocus, _ref]);

  return useMergedRef(ref, _ref);
}
