import { isDefined } from '@valstro/workspace';

type WindowBounds = {
  width?: number;
  height?: number;
  x?: number;
  y?: number;
};

type MousePosition = {
  x: number;
  y: number;
};

/**
 * Check if the mouse is inside the window bounds
 *
 * @param bounds - The bounds of the window
 * @param mousePosition - The mouse position
 * @returns boolean - Whether the mouse is inside the window bounds
 */
export function isMouseInsideBounds(bounds: WindowBounds, mousePosition: MousePosition): boolean {
  const { width, height, x, y } = bounds;
  const { x: mouseX, y: mouseY } = mousePosition;

  if (!isDefined(x) || !isDefined(y) || !isDefined(width) || !isDefined(height)) {
    return false;
  }

  return mouseX >= x && mouseX <= x + width && mouseY >= y && mouseY <= y + height;
}
