import type { AnyRecord } from '../../common/type.helpers';
import type { CombinedFormFieldUnion, IAnyField, IExtractedField, IFieldType } from '../types';
import type { Validator as DdfValidator } from '@data-driven-forms/react-form-renderer';
import { FieldDefinition } from './form-field-definition.class';

/**
 * Field contract builder
 * Used to create a single field definition to conform to an external contract.
 *
 * @example
 * ```ts
 * const f = new FieldContract<RouteOrderMutationInput>();
 *
 * const quantity = f.field('quantity', 'number').options({
 *   numericScale: 2
 * });
 * ```
 */
export class FieldContract<
  TOutputContract extends AnyRecord,
  TCustomFields extends IAnyField | undefined = undefined
> {
  static create<
    TOutputContract extends AnyRecord,
    TCustomFields extends IAnyField | undefined = undefined
  >() {
    return new FieldContract<TOutputContract, TCustomFields>();
  }

  public field<
    K extends keyof TOutputContract,
    C extends IFieldType<CombinedFormFieldUnion<TOutputContract[K], DdfValidator, TCustomFields>>,
    TField extends IAnyField = IExtractedField<
      CombinedFormFieldUnion<TOutputContract[K], DdfValidator, TCustomFields>,
      C
    >
  >(key: K, component: C) {
    return new FieldDefinition<K, C, TField>(key, component, {} as TField);
  }

  public fieldRaw<T extends FieldDefinition<any, any, any>>(fieldDefinition: T['fieldDef']) {
    return new FieldDefinition<T['name'], T['component'], T['fieldDef']>(
      '',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      fieldDefinition.component,
      fieldDefinition
    );
  }
}
