import { Identifiable, UUIDString, isUUIDString, Optional } from '@oms/shared/util-types';
import { compactMap, CompactMapTransformFn } from '../collections.util';

type MapCallbackParams<T> = Parameters<Parameters<Array<T>['map']>[0]>;
type ForEachCallbackParams<T> = Parameters<Parameters<Array<T>['forEach']>[0]>;
type FilterPredicateParams<T> = Parameters<Parameters<Array<T>['filter']>[0]>;
type ReduceCallbackParams<T> = Parameters<Parameters<Array<T>['reduce']>[0]>;

/**
 * A type that acts like a `Set` to store types with a UUID (`id`) property.
 * Like a `Set`, it cannot have duplicates, but
 * uses only the `id` to determine the equality of it's elements.
 * Additionally, it is ordered, so new items can be appended or prepended,
 * which affects the order when iterating.
 */
export class IdentifiableOrderedSet<T extends Identifiable> {
  #store: Map<UUIDString, T> = new Map();
  #order: UUIDString[] = [];
  #count: number = 0;

  /**
   * A type that acts like a `Set` to store types with a UUID (`id`) property.
   * Like a `Set`, it cannot have duplicates, but
   * uses only the `id` to determine the equality of it's elements.
   * Additionally, it is ordered, so new items can be appended or prepended,
   * which affects the order when iterating.
   *
   * @param initialElements - A variadic rest param of initial elements to add. Omit to start with an empty set.
   */
  public constructor(...initialElements: T[]) {
    for (const element of initialElements) {
      this.add(element);
    }
  }

  public get count(): number {
    return this.#count;
  }

  public has(element: T): boolean;
  public has(id: UUIDString): boolean;
  public has(query: T | UUIDString): boolean {
    if (typeof query === 'string') {
      return this.#store.has(query);
    } else {
      const { id } = query;
      const existing = this.#store.get(id);
      return existing ? query === existing : false;
    }
  }

  public get(element: T): Optional<T>;
  public get(id: UUIDString): Optional<T>;
  public get(query: T | UUIDString): Optional<T> {
    if (typeof query === 'string') {
      return this.#store.get(query);
    } else {
      const { id } = query;
      const existing = this.#store.get(id);
      return existing && query === existing ? existing : undefined;
    }
  }

  public add(...elements: T[]): T[] {
    return this.append(...elements);
  }

  public append(...elements: T[]): T[] {
    return compactMap(elements, (element) => {
      const { id } = element;
      if (!isUUIDString(id)) return;
      const exists = this.#store.has(id);
      this.#store.set(id, element);
      if (!exists) {
        this.#order.push(id);
        this.#count += 1;
      }
      return element;
    });
  }

  public prepend(...elements: T[]): T[] {
    return compactMap(elements, (element) => {
      const { id } = element;
      if (!isUUIDString(id)) return;
      const exists = this.#store.has(id);
      this.#store.set(id, element);
      if (!exists) {
        this.#order.unshift(id);
        this.#count += 1;
      }
      return element;
    });
  }

  public remove(element: T): Optional<T>;
  public remove(id: UUIDString): Optional<T>;
  public remove(query: T | UUIDString): Optional<T> {
    const existing = (() => {
      if (typeof query === 'string') {
        return this.get(query);
      } else {
        return this.get(query);
      }
    })();
    if (!existing) return;
    const { id } = existing;
    this.#store.delete(id);
    const index = this.#order.findIndex((it) => it === id);
    if (typeof index !== 'undefined') this.#order.splice(index, 1);
    this.#count -= 1;
    return existing;
  }

  // 🎶 Iteration ------------------------------------------------------------ /

  public iterate(): T[] {
    return [...this] as T[];
  }

  public map<ResultType>(transform: (...params: MapCallbackParams<T>) => ResultType): ResultType[] {
    return this.iterate().map(transform);
  }

  public compactMap<ResultType>(transform: CompactMapTransformFn<T, ResultType>): ResultType[] {
    return compactMap(this.iterate(), transform);
  }

  public forEach(sideEffect: (...params: ForEachCallbackParams<T>) => void): void {
    return this.iterate().forEach(sideEffect);
  }

  public filter(predicate: (...params: FilterPredicateParams<T>) => boolean): T[] {
    return this.iterate().filter(predicate);
  }

  public reduce<ResultType>(
    callback: (
      previousValue: ResultType,
      currentValue: ReduceCallbackParams<T>[1],
      currentIndex: ReduceCallbackParams<T>[2],
      array: ReduceCallbackParams<T>[3]
    ) => ResultType,
    initial: ResultType
  ): ResultType {
    return this.iterate().reduce(callback, initial);
  }

  public [Symbol.iterator](): Iterator<T> {
    const { count } = this;
    let currentIndex = 0;
    return {
      next: () => {
        while (currentIndex < count) {
          const key = this.#order[currentIndex] ?? '';
          const item = this.#store.get(key);
          currentIndex += 1;
          if (!item) continue;
          return { done: false, value: item };
        }
        return { done: true, value: undefined };
      }
    };
  }
}

export default IdentifiableOrderedSet;
