import React, { useLayoutEffect, useMemo, useState } from 'react';
import {
  AnyRecord,
  WidgetActorSchemaOptions,
  CommonWidgetActorSchema,
  Actor,
  WidgetActorSchemaBuilder,
  WIDGET_ACTOR_NAME,
  CreateActorDefinition,
  COMMON_ACTOR_TYPE,
  WidgetActorComponents
} from '@valstro/workspace';
import {
  ReactActorComponentProps,
  useCurrentActor,
  useActorSchemaByName,
  useActorContext,
  useActorState,
  useActorClassNames,
  ReactActorComponentChildren
} from '../../../core';

export const useCurrentWidgetActorProps = <T extends AnyRecord = AnyRecord>() => {
  const actor = useCurrentActor<CommonWidgetActorSchema<T>>();
  const context = useActorContext(actor);
  return useMemo(
    () => [context.componentProps, actor.operations.updateProps] as const,
    [context.componentProps, actor.operations.updateProps]
  );
};

let existingComponentsMap: WidgetActorComponents<any> | undefined = undefined;

export const useReactWidgetActorComponents = (actor: Actor<CommonWidgetActorSchema>) => {
  const context = useActorContext(actor);
  const actorSchema = useActorSchemaByName<CommonWidgetActorSchema>(actor.name);
  const [component, setComponent] = useState<React.ReactNode | null>(() => {
    const widgetComponentsMap = actorSchema?.meta?.widgetComponentsMap;
    if (widgetComponentsMap) {
      const Component = widgetComponentsMap[context.componentId];
      return Component ? <Component /> : null;
    }

    if (!existingComponentsMap) {
      return null;
    }

    const Component = existingComponentsMap[context.componentId];
    return Component ? <Component /> : null;
  });

  useLayoutEffect(() => {
    if (component) {
      return;
    }

    async function getComp() {
      if (existingComponentsMap) {
        return;
      }

      const dynamicComponentsMap = actorSchema?.meta?.dynamicWidgetComponentsMap;

      if (!dynamicComponentsMap) {
        return;
      }

      if (!actorSchema) {
        setComponent(<div className="widget__error">Could not find actor: {WIDGET_ACTOR_NAME}</div>);

        return;
      }

      const result = await dynamicComponentsMap;

      if (!result.widgetComponentsMap) {
        throw new Error('dynamicWidgetComponentsMap must return a "widgetComponentsMap"');
      }

      existingComponentsMap = result.widgetComponentsMap;

      const Component = existingComponentsMap[context.componentId];

      if (!Component) {
        setComponent(
          <div className="widget__error">
            Could not find component: {context.componentId}. Please check your "widgetComponentsMap" in the
            "widgetActor" schema contains this component
          </div>
        );

        return;
      }

      setComponent(<Component />);
    }

    getComp().catch((e) => {
      console.error(e);
      setComponent(<div className="widget__error">Error loading component: {e.message}</div>);
    });
  }, [component, actorSchema, context.componentId, actor]);

  return useMemo(() => {
    const Wrapper = (actorSchema?.meta as ReactWidgetActorSchemaOptions)?.wrapperComponent;

    if (!Wrapper) {
      return component;
    }

    return <Wrapper actor={actor}>{component}</Wrapper>;
  }, [component, actorSchema, actor]);
};

// Allow only window actors to be children of widget actors (for modal support)
const actorChildrenFilter = (a: CreateActorDefinition) => {
  return a.type === COMMON_ACTOR_TYPE.WINDOW;
};

export const ReactWidgetActorView: React.FC<ReactActorComponentProps<CommonWidgetActorSchema>> = ({
  actor
}) => {
  const component = useReactWidgetActorComponents(actor);
  const [state, failedMessage] = useActorState(actor);
  const className = useActorClassNames(actor, state);

  switch (state) {
    case 'idle':
      return <div className={className.wrapper}>Idle</div>;
    case 'starting':
      return <div className={className.wrapper}>Loading...</div>;
    case 'failed':
      return (
        <div className={className.wrapper}>
          <div className={className.box}>
            <div className={className.title}>Error</div>
            {failedMessage && <div className={className.message}>{failedMessage}</div>}
          </div>
        </div>
      );
    case 'destroyed':
      return (
        <div className={className.wrapper}>
          <div className={className.box}>
            <div className={className.title}>Widget Disconnected</div>
            <div className={className.message}>Please refresh to re-connect</div>
          </div>
        </div>
      );
    default:
      return (
        <>
          <div className={className.wrapper} data-actor-id={actor.id} data-actor-type={actor.type}>
            {component}
          </div>
          <ReactActorComponentChildren actor={actor} filter={actorChildrenFilter} />
        </>
      );
  }
};

export type ReactWidgetActorViewType = React.ComponentType<ReactActorComponentProps<CommonWidgetActorSchema>>;

export const ReactWidgetActorSchemaBuilder = WidgetActorSchemaBuilder.extendView(ReactWidgetActorView);

export type ReactWidgetActorWrapperComp = React.FC<
  React.PropsWithChildren<ReactActorComponentProps<CommonWidgetActorSchema>>
>;

export type ReactWidgetActorSchemaOptions = WidgetActorSchemaOptions & {
  wrapperComponent?: ReactWidgetActorWrapperComp;
};

export const widgetActor = ReactWidgetActorSchemaBuilder.optionsCreator<ReactWidgetActorSchemaOptions>();

export type ReactWidgetActorSchema = typeof ReactWidgetActorSchemaBuilder.schema;
