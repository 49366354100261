import {
  getDate,
  isSameDay,
  isWithinInterval,
  parseISO,
  setHours,
  setMinutes,
  sub,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfQuarter,
  endOfQuarter,
  subMonths,
  subQuarters,
  subYears
} from 'date-fns';
import { IntervalPeriod } from './date-enums';

/**
 * Simple date range with beginning and end dates
 */
export type DayRange = {
  begin: Date;
  end: Date;
};

/**
 * Create a DayRange object from 2 dates. For the begin + end dates:
 * sets (start time of start day) and (end time on end day)
 *
 * @param begin Begin date in range
 * @param end End date in range
 * @returns DayRange with start of begin date + end of end date
 */
const makeIntervalDateRange = (begin: Date, end: Date): DayRange => {
  return {
    begin: startOfDay(begin),
    end: endOfDay(end)
  };
};

/**
 * Relative to an input date (end of the period), generate an interval
 * of (start time of start day) - (end time on end day)
 *
 * @param endDateInput End of period
 * @param period Period selection enum
 * @returns DayRange object with start and end datetimes in period
 */
export const getHistoricDateInterval = (endDateInput: Date | string, period: IntervalPeriod): DayRange => {
  const dtEnd: Date =
    endDateInput && typeof endDateInput === 'string' ? parseISO(endDateInput) : (endDateInput as any as Date);

  switch (period) {
    case IntervalPeriod.TODAY: {
      return makeIntervalDateRange(dtEnd, dtEnd);
    }
    case IntervalPeriod.MTD: {
      return makeIntervalDateRange(startOfMonth(dtEnd), dtEnd);
    }
    case IntervalPeriod.PREV_MTD: {
      const monthAgo = subMonths(dtEnd, 1);
      return makeIntervalDateRange(startOfMonth(monthAgo), endOfMonth(monthAgo));
    }
    case IntervalPeriod.QTD: {
      return makeIntervalDateRange(startOfQuarter(dtEnd), dtEnd);
    }
    case IntervalPeriod.PREV_QTD: {
      const quarterAgo = subQuarters(dtEnd, 1);
      return makeIntervalDateRange(startOfQuarter(quarterAgo), endOfQuarter(quarterAgo));
    }
    case IntervalPeriod.YTD: {
      return makeIntervalDateRange(startOfYear(dtEnd), dtEnd);
    }
    case IntervalPeriod.PREV_YTD: {
      const yearAgo = subYears(dtEnd, 1);
      return makeIntervalDateRange(startOfYear(yearAgo), endOfYear(yearAgo));
    }
    default:
      // default to 1-day ranger
      return makeIntervalDateRange(dtEnd, dtEnd);
  }
};

/**
 * Check whether the dataDate is between the 1st of the
 * prior month + "now"
 *
 * @param now most recent starting point for query
 * @param dataDate date to eval
 */
export const isInPrevMonth = (now: Date, dataDate: Date): boolean => {
  const prevMonthStart = setMinutes(setHours(sub(now, { months: 1, days: getDate(now) - 1 }), 0), 0);

  // check if the dates match or if the dataDate is between prevMonthStart + now
  return (
    isSameDay(prevMonthStart, dataDate) || isWithinInterval(dataDate, { start: prevMonthStart, end: now })
  );
};

/**
 * Bounds a date to an optional min and/or max
 *
 * @param date - The input date to evaluate;
 * @param bounds - Specify begin and/or end dates to clamp to
 * @returns A date that is either the input or clamped to the beginning/end dates
 */
export const boundDate = (date: Date, bounds?: Partial<DayRange>) => {
  const { begin, end } = bounds ?? {};
  if (begin && date < begin) return begin;
  if (end && date > end) return end;
  return date;
};
