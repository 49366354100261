import type { ICellRendererParams } from '@ag-grid-community/core';
import { IconButton } from '@oms/shared-frontend/ui-design-system';
import { DragHandleDots2Icon } from '@radix-ui/react-icons';
import { omit } from 'lodash';
import { type FC, useRef } from 'react';
import { useInlineActionProps } from '../../../hooks/action.events.hook';
import type { InlineActionComponentProps } from '../../../models/actions.model';

export const INLINE_DRAG_ACTION = 'inline-drag-action';

export const InlineDragActionCell: FC<ICellRendererParams<any, any> & InlineActionComponentProps<any>> = (
  props
) => {
  const dragRef = useRef<HTMLElement>();
  const actionProps = useInlineActionProps<any, any>(props);

  if (actionProps.isVisible === false) {
    return null;
  }

  if (dragRef.current) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    props.registerRowDragger(dragRef.current);
  }

  return (
    <IconButton
      {...(omit(actionProps, 'onChange', 'content', 'actionType', 'isVisible', 'instanceId') as any)}
      style={{ outline: 0, backgroundColor: 'inherit' }}
    >
      <DragHandleDots2Icon ref={dragRef as any} style={{ cursor: 'grab' }} />
    </IconButton>
  );
};
