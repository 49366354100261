import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { TargetIcon } from '@radix-ui/react-icons';
import type { CountType } from '@app/data-access/services/trading/montage/utils/montage-target-state.class';
import { MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME } from './target-trading-order.action.types';
import type {
  ActionCommandConfig,
  ActionCommandContracts
} from '@app/actions/commands/command.registry.types';

const isCountType = (countType: unknown): countType is CountType => {
  switch (countType as CountType) {
    case 'up':
    case 'down':
      return true;
    default:
      return false;
  }
};

export const MONTAGE_TARGET_TRADING_ORDER_ACTION: ActionDefFactory = (b) =>
  b
    .name(MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME)
    .toolbar((t) =>
      t
        .location('LeftVerticalToolbar')
        .component('icon-counter-button')
        .props({ content: <TargetIcon /> })
        .id(MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME)
    )
    .lifecycles('change')
    .onChange((e) => {
      const { state, config } = e;
      const { countType } = state;
      if (!isCountType(countType)) return;
      const { actionContext$ } = config as unknown as ActionCommandConfig<
        ActionCommandContracts['montage_target_trading_order']
      >;
      if (!actionContext$) return;

      const { targetState } = actionContext$.getCommand('montage_target_trading_order') || {};
      if (!targetState) return;

      targetState.shift(countType);
    });
