import React, { useMemo } from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import {
  DatePicker as DatePickerComponent,
  type DatePickerProps
} from '@oms/shared-frontend/ui-design-system';
import { useFieldApi } from '../../helpers';
import type { FieldProps } from '../../types';
import { type IDatePickerField } from './date-picker.type';
import { type Optional } from '@oms/shared/util-types';

export const DatePicker: React.FC<FieldProps<IDatePickerField>> = (props) => {
  const {
    meta,
    label,
    input,
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    requiredFieldIndicatorStyle,
    autoFocus = false,
    hideFormControls,
    placeholder,
    minDate,
    maxDate,
    timePicker,
    dateFormat,
    showTimeSelectOnly,
    inline,
    valueChange,
    value
  } = useFieldApi<IDatePickerField>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  // Let's spread the props here so we can pass only what's necessary for an input
  const { name, onBlur, onChange, onFocus, value: _value, type } = input;

  const val = value || _value;

  /*
    Convert string (if value has been passed as a string to Date)
  */
  const date: Optional<Date> = useMemo(() => {
    if (val instanceof Date) return val;
    if (typeof val === 'string') {
      const timestamp = Date.parse(val);
      if (isNaN(timestamp)) return undefined;
      return new Date(timestamp);
    }
    return undefined;
  }, [val]);

  const extraDateProps: DatePickerProps = useMemo(() => {
    const base: DatePickerProps = {
      dateFormat: dateFormat || 'MMM d yyyy'
    };
    const baseWithTime: DatePickerProps = {
      ...base,
      showTimeSelect: true,
      timeFormat: 'HH:mm',
      timeIntervals: 15,
      timeCaption: 'Time',
      dateFormat: dateFormat || 'MMM d yyyy, HH:mm:ss'
    };
    switch (timePicker) {
      case 'selectOnly':
        return { ...baseWithTime };
      case 'selectAndInput':
        return {
          ...baseWithTime,
          showTimeInput: true
        };
      default:
        return { ...base };
    }
  }, [timePicker, dateFormat]);

  return (
    <FieldWrapper
      meta={meta}
      hideFormControls={hideFormControls}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isVisible={isVisible}
      isInvalid={isInvalid}
      helperText={helperText}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
    >
      <DatePickerComponent
        name={name}
        onBlur={onBlur}
        // eslint-disable-next-line react/jsx-no-bind
        onCalendarClose={() => onBlur({} as React.FocusEvent<HTMLElement>)} // Running the onBlur when the calendar is closed, to trigger validation in DDF
        onChange={valueChange || onChange}
        onFocus={onFocus}
        // Need to fix type issue in DatePickerComponent. multiple types are allowed for value
        // and it's breaking here. Normal coercion is not working.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        value={date}
        type={type}
        hidden={isVisible === false}
        autoFocus={autoFocus}
        placeholderText={placeholder}
        // Need to fix type issue in DatePickerComponent. multiple types are allowed for value
        // and it's breaking here. Normal coercion is not working.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        minDate={minDate}
        // Need to fix type issue in DatePickerComponent. multiple types are allowed for value
        // and it's breaking here. Normal coercion is not working.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        maxDate={maxDate}
        inline={inline}
        {...extraDateProps}
        showTimeSelectOnly={showTimeSelectOnly}
      />
    </FieldWrapper>
  );
};
