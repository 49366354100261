import { VisibilityReason } from '@oms/generated/frontend';

/**
 * Define Visibility Filter Keys
 */
export const IO_VIS_KEY = {
  MINE: 'MINE',
  COVERAGE_PRIMARY: 'COVERAGE_PRIMARY',
  COVERAGE_TEAM: 'COVERAGE_TEAM',
  COVERAGE_TEAM_WITH_PRIMARY: 'COVERAGE_TEAM_WITH_PRIMARY',
  ALL: 'ALL'
} as const;

/**
 * Define Visibility Filter Values
 */
export const IO_VIS_VALUE = {
  [IO_VIS_KEY.MINE]: [
    VisibilityReason.Owner,
    VisibilityReason.RestrictedOwner,
    VisibilityReason.UserPrimary,
    VisibilityReason.AccountPrimary,
    VisibilityReason.RestrictedPrimary
  ],
  [IO_VIS_KEY.COVERAGE_PRIMARY]: [
    VisibilityReason.Owner,
    VisibilityReason.RestrictedOwner,
    VisibilityReason.UserPrimary,
    VisibilityReason.AccountPrimary,
    VisibilityReason.RestrictedPrimary
  ],
  [IO_VIS_KEY.COVERAGE_TEAM]: [
    VisibilityReason.UserTeamMember,
    VisibilityReason.AccountBackup,
    VisibilityReason.RestrictedBackup
  ],
  [IO_VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY]: [
    VisibilityReason.UserTeamMember,
    VisibilityReason.Owner,
    VisibilityReason.RestrictedOwner,
    VisibilityReason.UserPrimary,
    VisibilityReason.AccountBackup,
    VisibilityReason.RestrictedBackup,
    VisibilityReason.AccountPrimary,
    VisibilityReason.RestrictedPrimary
  ],
  [IO_VIS_KEY.ALL]: []
} as const;

export type IOVisKey = keyof typeof IO_VIS_VALUE;
