import { FORM_RENDERER_EVENT_TYPE } from '@oms/frontend-foundation';
import type {
  FormBuilderEventSanitizedValuesChanged,
  FormBuilderEventSubmit,
  FormBuilderChangeContext
} from '@oms/frontend-foundation';
import { PrincipalFillService } from '@app/data-access/services/trading/principal-fill.service';
import { enrichFieldValues, reverseMapSideType } from './principal-fill.util';
import type { PrincipalFillFormOutput } from './principal-fill.contracts';
import type { PrincipalFillFormValues } from './principal-fill.form-contract';

type FormCtx = FormBuilderChangeContext<PrincipalFillFormOutput, PrincipalFillFormValues>;
type SanitizedValuesChangedEvent = FormBuilderEventSanitizedValuesChanged<
  PrincipalFillFormValues,
  PrincipalFillFormOutput
>;
type SubmitEvent = FormBuilderEventSubmit<PrincipalFillFormOutput, PrincipalFillFormValues>;

export const handleSanitizedValuesChange = async (event: SanitizedValuesChangedEvent, ctx: FormCtx) => {
  const service = ctx.container.resolve(PrincipalFillService);

  const output = event.payload.output;

  const result = await service.requestPrincipalFill({
    ...createRequestPrincipalFillPayload(output),
    dryRun: true
  });

  if (result.isSuccess()) {
    const response = result.value.data?.requestPrincipalFill;

    const fieldValues = await enrichFieldValues(response, output);

    if (fieldValues) {
      ctx.notify({
        type: FORM_RENDERER_EVENT_TYPE.SET_FIELD_VALUES,
        payload: {
          fieldValues
        }
      });
    }

    if (response?.feedback) {
      ctx.notify({
        type: FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK,
        payload: {
          feedback: response.feedback
        }
      });
    }
  }
};

export const handleSubmit = async (event: SubmitEvent, ctx: FormCtx) => {
  const service = ctx.container.resolve(PrincipalFillService);
  const output = event.payload.output;

  if (!output?.tradeDateTimeType) return;

  const result = await service.requestPrincipalFill({
    ...createRequestPrincipalFillPayload(output),
    dryRun: false
  });

  if (result.isSuccess()) {
    const response = result.value.data?.requestPrincipalFill;

    if (response?.feedback) {
      ctx.notify({
        type: FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK,
        payload: {
          feedback: response.feedback
        }
      });
    }
  }

  return result;
};

function createRequestPrincipalFillPayload(output: PrincipalFillFormOutput) {
  return {
    clientInvestorOrderIds: output?.investorOrders?.map(({ id }) => id) || [],
    principalFill: {
      firmAccountId: output?.firmAccount?.id || '',
      instrumentId: output?.instrument?.id || '',
      sideType: reverseMapSideType(output?.investorOrders?.[0]?.sideType),
      capacity: output?.capacity,
      price: output?.price || null,
      quantity: output?.quantity || null,
      locate: output?.locate,
      tradeDateTimeType: output?.tradeDateTimeType,
      tradeDateTime: output?.tradeDateTime,
      tradeTagIds: output?.tradeTagIds,
      allowSweep: output.tradeThrough?.allowSweep
    }
  };
}
