import { AtdlFormats, InitValueMode } from '@valstro/fixatdl-core';
import type { Timezones } from '@valstro/fixatdl-core';
import { DateTime } from 'luxon';

/**
 * For initValueMode === 1, returns specified date if it's greater than the current date.
 * Otherwise returns current date
 * For initValueMode === 0, returns the specified date
 * @param dateStr string
 * @param initValueMode 0 | 1
 * @returns Date
 */
export const getFutureOrCurrentDate = (dateStr: string, initValueMode: InitValueMode) => {
  const date = createDateTimeObject(dateStr);
  const specifiedDate = date && date.toString() !== 'Invalid Date' ? date : new Date();
  const currentDate = new Date();

  return initValueMode === InitValueMode.CURRENT
    ? specifiedDate.getTime() > currentDate.getTime()
      ? specifiedDate
      : currentDate
    : specifiedDate;
};

/**
 * Creates a Date object from a string value
 * Possible values representing datetime or time: '2024-07-24T20:00:00.000Z' or '20:00:00'
 * @param value string
 * @param tz string
 * @returns Date
 */
const createDateTimeObject = (value: string, tz?: string) => {
  if (!value) {
    return null;
  }

  if (DateTime.fromISO(value).isValid) {
    return DateTime.fromISO(value, { zone: tz }).toJSDate();
  }

  if (AtdlFormats.Dates.isFixDate(value)) {
    return DateTime.fromFormat(value, 'YYYYMMDD', { zone: tz }).toJSDate();
  }

  if (AtdlFormats.Dates.isTime(value)) {
    return DateTime.fromFormat(value, 'HH:mm:ss', { zone: tz }).toJSDate();
  }

  return null;
};

/**
 * Returns Date in a local time zone
 * @param value '2022-07-24T10:00:00.000Z' or '20:00:00'
 * @param timeZone 'time zone of the specified datetime or time, ex. America/New_York'
 * @returns Date
 */
export const getLocalDateTime = (value: string, timeZone?: string): Date | null => {
  const dateTime = createDateTimeObject(value, timeZone);

  if (dateTime) {
    return dateTime;
  }

  return null;
};

/**
 * Formats Date to a FIXatdl time format, ex. '20:10:37'
 * @param date Date
 * @returns string
 */
export const formatDateToFixAtdlTimeString = (date: Date) => {
  return AtdlFormats.Dates.coerceDate(date.toISOString(), {
    format: 'time',
    from: 'UTC',
    to: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezones
  });
};

export const convertDateObjectToFixDate = (date: Date): string => {
  return AtdlFormats.Dates.coerceDate(date, { format: 'fix-date' })?.toString() || '';
};
