import { RepairCategory } from '@oms/generated/frontend';
import type { TradeRepairRequestFragment } from '@oms/generated/frontend';
import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import {
  sharedDefaultCol,
  sharedIdCol,
  sharedQuantityCol,
  sharedTextCol
} from '@app/common/grids/columns/generic-cols';
import { mapRepairCategory } from '@app/common/mappers/map-repair-category';
import {
  RepairErrorCodeRenderer,
  RepairErrorMessageRenderer
} from '@app/common/grids/cell-renderers/errors/errors.cell-renderer';

type TradeRepairRequestColumnBuilder = ColumnBuilderCallback<TradeRepairRequestFragment>;

const defaultCol: TradeRepairRequestColumnBuilder = (c) => sharedDefaultCol(c);

const idCol: TradeRepairRequestColumnBuilder = (c) => sharedIdCol(c, 'id').hide();

// TODO: For all columns, apply translations. e.g.:
// .header(t('app.orders.orderMonitor.timeInForce'))
// .shortHeader(t('app.orders.orderMonitor.timeInForce', { ns: 'short' }))

const categoryCol: TradeRepairRequestColumnBuilder = (c) =>
  c
    .field('category')
    .header('Category')
    .width(150)
    .filter('agSetColumnFilter')
    .filterParams<RepairCategory>({
      values: Object.values(RepairCategory),
      valueFormatter: ({ value }) => mapRepairCategory(value, 'Unknown')
    });
// TODO: this cell formatter is meant to convert the actual category values
// (e.g. 'IO_CREATE') to the display value (e.g. 'Create'). However, when grouping,
// it messes things up. Look into this.
// .cell((c) => c.valueFormatter(({ data }) => mapRepairCategory(data?.category, 'Unknown')));

const tradeInstrumentIdCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedIdCol(c, 'tradeInstrument.id').header('Instrument ID').width(100).hide();

const tradeInstrumentNameCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedTextCol(c, 'tradeInstrument.mappings.displayCode').header('Symbol').width(100);

const tradeAccountIdCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedIdCol(c, 'tradeAccount.id').header('Account ID').width(100).hide();

const tradeAccountNameCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedTextCol(c, 'tradeAccount.name').header('Account Name').shortHeader('Account').width(100);

const tradeCounterpartyIdCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedIdCol(c, 'tradeCounterparty.id').header('Counterparty ID').width(100).hide();

const tradeCounterpartyCodeCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedTextCol(c, 'tradeCounterparty.tradeCounterpartyCode')
    .header('Trade Counterparty')
    .shortHeader('TrdCpty')
    .width(100);

// Note: The 'standard' quantity format applies here. Since this is a repair,
// do we want a more forgiving format? (e.g. decimal places, negatives, etc.)
const tradeQuantityCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedQuantityCol(c, 'tradeQuantity').header('Quantity').width(100);

// To achieve the desired decimal places:
// - do NOT use sharedPriceCol nor sharedNumericCol
// - specify 'numericColumn'.
const tradePriceCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedTextCol(c, 'tradePrice').header('Price').width(100).type('numericColumn');

// Note: Ideally we should use the sharedTimestampCol. At the moment use 'dateTimeUi'
// formatter registry.
const sourceEventTimestampCol: TradeRepairRequestColumnBuilder = (c) =>
  c
    .field('sourceEventTimestamp')
    .header('Event Timestamp')
    .width(200)
    .cell((cb) => cb.valueFormatterRegistry('dateTimeUi'))
    .sort('desc');

const sourceEventPayloadCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedTextCol(c, 'sourceEventPayload').header('Event Payload').width(300).hide();

const errorCodeCol: TradeRepairRequestColumnBuilder = (c) =>
  c
    .field('errors')
    .header('Error Code')
    .cell((cb) => cb.renderer(RepairErrorCodeRenderer))
    .width(300);

const errorMessageCol: TradeRepairRequestColumnBuilder = (c) =>
  c
    .field('errors')
    .header('Error Message')
    .cell((cb) => cb.renderer(RepairErrorMessageRenderer))
    .width(300);

const tradingOrderIdCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedIdCol(c, 'tradingOrderId').header('Trading Order ID').shortHeader('TO ID').width(300);

const tradeIdCol: TradeRepairRequestColumnBuilder = (c) =>
  sharedIdCol(c, 'tradeId').header('Trade ID').width(300);

export const tradeRepairRequestColumnLibrary: ColumnLibrary<TradeRepairRequestFragment> = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    categoryCol,
    tradeInstrumentIdCol,
    tradeInstrumentNameCol,
    tradeAccountIdCol,
    tradeAccountNameCol,
    tradeCounterpartyIdCol,
    tradeCounterpartyCodeCol,
    tradeQuantityCol,
    tradePriceCol,
    sourceEventTimestampCol,
    sourceEventPayloadCol,
    errorCodeCol,
    errorMessageCol,
    tradingOrderIdCol,
    tradeIdCol
  ]
};
