import { INLINE_SPLIT_BUTTON_ACTION } from '@oms/frontend-vgrid';
import type {
  ActionComponentConfig,
  ActionContext,
  ActionDefFactory,
  SplitButtonActionComponentConfig,
  SplitButtonActionConfig
} from '@oms/frontend-vgrid';
import type { Notification } from '../notifications.contracts';
import { openConfirmation } from '@app/generated/sdk';
import { PROCESS_ID } from '@valstro/workspace';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { t } from '@oms/codegen/translations';

// TODO: This is a placeholder until each of the notification actions' OnChange handlers are supported.
const placeholderOnChange = async (ctx: ActionContext<Notification, ActionComponentConfig<Notification>>) => {
  const { lifecycle, workspace } = ctx;

  if (lifecycle === 'change') {
    try {
      const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
        title: 'Placeholder',
        componentProps: {
          autoClose: true,
          message: 'Are you sure you want to do X?',
          confirmButtonText: t('app.common.yes'),
          cancelButtonText: t('app.common.no')
        },
        windowOptions: {
          width: 400,
          height: 180
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          // For now do nothing.
          throw new Error('Function not implemented.');
          break;
        }
      }
    } catch (e) {
      openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
      console.error(e);
    }
  }
};

export const inlineNotificationsActions: ActionDefFactory<Notification> = (builder) =>
  builder
    .name('inline_notifications_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(100)
        .filter(false)
        .cell((c) => c.renderer(INLINE_SPLIT_BUTTON_ACTION, { variant: 'secondary' }))
    )
    .onChange<SplitButtonActionComponentConfig<Notification>>(async (ctx) => {
      switch (ctx.lifecycle) {
        case 'init':
        case 'refresh': {
          const [rowData] = ctx.data;
          ctx.notify({ actionsConfig: rowActions(rowData) });
          break;
        }
        case 'change': {
          const { selectedId } = ctx.state;
          switch (selectedId) {
            // TODO: Each action should call its own dedicated onChange. For now, none of the separate actions are implemented yet
            // and all call the placeholder onChange.
            case 'inline_notification_dismiss':
            case 'inline_notification_accept_io_new':
            case 'inline_notification_reject_io_new':
            case 'inline_notification_accept_pending_modification':
            case 'inline_notification_reject_pending_modificcation':
            case 'inline_notification_repair_failed_order':
            case 'inline_notification_retry_failed_order':
            case 'inline_notification_reject_failed_order':
            case 'inline_notification_repair_failed_order_modification':
            case 'inline_notification_retry_failed_order_modification':
            case 'inline_notification_reject_failed_order_modification':
            case 'inline_notification_dismiss_capital_threshold_warning':
            case 'inline_notification_repair_failed_trade':
            case 'inline_notification_retry_failed_trade':
            case 'inline_notification_reject_failed_trade':
            case 'inline_notification_repair_failed_trade_modification':
            case 'inline_notification_retry_failed_trade_modification':
            case 'inline_notification_reject_failed_trade_modification':
              return placeholderOnChange(ctx);
            default:
              throw new Error(`onChange not defined for ${String(selectedId)}`);
          }
        }
      }
    });

function rowActions(rowData: Notification): SplitButtonActionConfig[] {
  // TODO: Currently, the supported "notification name" types come from
  //       NotificationResourceTemplatesName and are not yet finalized.
  switch (rowData.name) {
    case 'IO New': {
      return [
        { id: 'inline_notification_accept_io_new', title: 'Accept' },
        { id: 'inline_notification_reject_io_new', title: 'Reject' }
      ];
    }
    case 'IO Modify Request': {
      return [
        { id: 'inline_notification_accept_pending_modification', title: 'Accept' },
        { id: 'inline_notification_reject_pending_modificcation', title: 'Reject' }
      ];
    }
    case 'IO Failed': {
      return [
        { id: 'inline_notification_repair_failed_order', title: 'Repair' },
        { id: 'inline_notification_retry_failed_order', title: 'Retry' },
        { id: 'inline_notification_reject_failed_order', title: 'Reject' }
      ];
    }
    case 'IO Failed - Modify': {
      return [
        { id: 'inline_notification_repair_failed_order_modification', title: 'Repair' },
        { id: 'inline_notification_retry_failed_order_modification', title: 'Retry' },
        { id: 'inline_notification_reject_failed_order_modification', title: 'Reject' }
      ];
    }
    case 'Capital Threshold Warning': {
      return [{ id: 'inline_notification_dismiss_capital_threshold_warning', title: '✕' }];
    }
    case 'Failed Trade': {
      return [
        { id: 'inline_notification_repair_failed_trade', title: 'Repair' },
        { id: 'inline_notification_retry_failed_trade', title: 'Retry' },
        { id: 'inline_notification_reject_failed_trade', title: 'Reject' }
      ];
    }
    case 'Failed Trade - Modify': {
      return [
        { id: 'inline_notification_repair_failed_trade_modification', title: 'Repair' },
        { id: 'inline_notification_retry_failed_trade_modification', title: 'Retry' },
        { id: 'inline_notification_reject_failed_trade_modification', title: 'Reject' }
      ];
    }
    // TODO: define actions for these other notification types. For now just display the dismiss button.
    case 'IO Cancel Request':
    case 'Unmatched Trade Report':
    case 'Trade Report Failed (rejected)':
    case 'TO Create Rejected':
    case 'TO Modify Rejected':
    case 'TO Cancel Rejected':
    case 'TO Unsolicited Cancelled - Partial Fills':
    case 'Trade Request (OTC)':
    default:
      return [
        // Use the special char ✕ to display the close button.
        { id: 'inline_notification_dismiss', title: '✕' }
      ];
  }
}
