import {
  COMMON_ACTOR_TYPE,
  Plugin,
  TAURI_WINDOW_ACTOR_NAME,
  BROWSER_MODAL_WINDOW_ACTOR_NAME
} from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { createLogger } from '@oms/shared/util';
import { COMMON_COMMAND_PALETTE } from '@app/common/command-palette/command-palette.contracts';
import { COMMON_AUTH_WINDOW } from '@app/common/auth/auth.contracts';
import { COMMON_POPOVER } from '@valstro/workspace-plugin-popover';
import { checkAndMoveWindowById } from './adaptive-layouts.util';
import type { Subscription } from 'rxjs';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';

export const adaptiveLayoutsPluginLogger = createLogger({ label: 'Adaptive Layouts Plugin' });

export interface AdaptiveLayoutsPluginOptions {
  excludeWindowIds?: string[];
}

const defaultExcludeStartsWithList = [
  COMMON_COMMAND_PALETTE.BASE_ID,
  COMMON_AUTH_WINDOW.ID,
  COMMON_POPOVER.ID_BASE
];

export const reflow$ = new BroadcastSubject<void>('reflow');

export const adaptiveLayoutsPlugin = (
  { excludeWindowIds }: AdaptiveLayoutsPluginOptions = { excludeWindowIds: [] }
) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-adaptive-layouts-plugin',
    pluginFn: ({ workspace }) => {
      const subs = new Map<string, Subscription>();
      const excludeStartsWithList = [...defaultExcludeStartsWithList, ...(excludeWindowIds || [])];

      workspace
        .addActorHook<AppWindowActorSchema>(COMMON_ACTOR_TYPE.WINDOW)
        .after('start', (_, { getDefinition }) => {
          const { id, name } = getDefinition();

          const isModalOrTauriWindow =
            name === BROWSER_MODAL_WINDOW_ACTOR_NAME || name === TAURI_WINDOW_ACTOR_NAME;

          const inExcludedList = [...excludeStartsWithList, workspace.getLeaderProcessId()].some((prefix) =>
            id.startsWith(prefix)
          );

          const isExcluded = isModalOrTauriWindow === false || inExcludedList;

          if (isExcluded) {
            return;
          }

          const existing = subs.get(id);

          if (existing) {
            existing.unsubscribe();
            subs.delete(id);
          }

          subs.set(
            id,
            reflow$.asObservable().subscribe(() => {
              checkAndMoveWindowById(workspace, id).catch(console.error);
            })
          );
        });

      workspace
        .addActorHook<AppWindowActorSchema>(COMMON_ACTOR_TYPE.WINDOW)
        .before('destroy', (_, { getDefinition }) => {
          const { id } = getDefinition();
          const existing = subs.get(id);

          if (existing) {
            existing.unsubscribe();
            subs.delete(id);
          }
        });

      workspace.listen('actor/applying-snapshot-result', (e) => {
        // Reload layout on when leader process has finished applying snapshot
        if (e.id === workspace.getLeaderProcessId()) {
          reflow$.next();
        }
      });

      return () => {
        subs.forEach((sub) => {
          sub.unsubscribe();
        });

        subs.clear();
      };
    }
  });
