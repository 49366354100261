import { fromPartial } from '@total-typescript/shoehorn';
import { InvestorOrderStatus } from '@oms/generated/frontend';
import type { InvestorOrder } from '@oms/generated/frontend';

export const investorOrdersMockData: InvestorOrder[] = [
  fromPartial<InvestorOrder>({
    status: InvestorOrderStatus.Unaccepted,
    executedQuantity: 0
  }),
  fromPartial<InvestorOrder>({
    status: InvestorOrderStatus.Inactive,
    executedQuantity: 0
  }),
  fromPartial<InvestorOrder>({
    status: InvestorOrderStatus.FullyFilled,
    executedQuantity: 300
  })
];
