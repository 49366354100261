import { BehaviorSubject, Observable } from 'rxjs';
import {
  BroadcastBehaviorSubject,
  type BroadcastBehaviorSubjectOptions
} from '../subjects/broadcast-behavior-subject';

export type SignalSetter<T> = (value: T) => T;

export type SignalUnsubscribeFn = () => void;

export interface Signal<T> {
  get: () => T;
  set: (value: T | SignalSetter<T>) => void;
  subscribe: (next: (value: T) => void) => SignalUnsubscribeFn;
  $: Observable<T>;
}

function isSignalSetter<T>(value: T | SignalSetter<T>): value is SignalSetter<T> {
  return typeof value === 'function';
}

export function createSignal<T>(
  channelName: string,
  initialValue: T,
  _options?: BroadcastBehaviorSubjectOptions & { broadcast?: boolean }
): Signal<T> {
  const { broadcast = false, ...options } = _options || {};
  const behaviorSubject$ = broadcast
    ? new BroadcastBehaviorSubject<T>(channelName, initialValue, options)
    : new BehaviorSubject<T>(initialValue);

  return {
    get: () => behaviorSubject$.getValue(),
    set: (value: T | SignalSetter<T>) => {
      if (isSignalSetter(value)) {
        const nextValue = value(behaviorSubject$.getValue());
        behaviorSubject$.next(nextValue);
      } else {
        behaviorSubject$.next(value);
      }
    },
    subscribe: (next: (value: T) => void) => {
      const sub = behaviorSubject$.subscribe(next);
      return () => {
        sub.unsubscribe();
      };
    },
    $: behaviorSubject$.asObservable()
  };
}

export function createBroadcastSignal<T>(
  channelName: string,
  initialValue: T,
  options?: BroadcastBehaviorSubjectOptions
): Signal<T> {
  return createSignal(channelName, initialValue, {
    ...options,
    broadcast: true
  });
}
