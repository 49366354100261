export interface TabbableServiceSettings {
  /**
   * When 'true', alpha-numeric-alpha-numeric auto-tabbing behaviour is switched on
   */
  sequence?: boolean;
  /**
   * When 'true', sequencing will still happen, even if the field is empty
   */
  sequenceIfEmpty?: boolean;
  /**
   * When 'true', alphanumeric (input) to alphanumeric (select) will sequence with a number.
   * Numbers will represent the index + 1 of the options in the select box. 0 will skip field to the next available.
   */
  sequenceSelectAsNumeric?: boolean;
}

export enum InputTypeEnum {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  TEXTAREA = 'TEXTAREA'
}

export enum FieldTypeEnum {
  NUMBER = 'number',
  NUMERIC = 'numeric',
  NUMERIC_SHORTCUTS = 'numeric-shortcuts',
  TIME = 'time',
  ALPHANUMERIC = 'alphanumeric'
}

export type FieldTypeUnion = `${FieldTypeEnum}`;

export interface TabbableField {
  element: HTMLElement;
  index: number;
  fieldType: FieldTypeUnion;
  isFocused: boolean;
  isSelect: boolean;
}
