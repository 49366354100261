export type Maybe<T> = null | undefined | T;

export type SipQuote = {
  exchangeId: string;
  quantity?: Maybe<number>;
  price?: Maybe<number>;
};

export type SipQuoteDetail = {
  exchangeId: string;
  quantity: number;
  price: number;
};

export type SipData = {
  askPrice?: Maybe<number>;
  bidPrice?: Maybe<number>;
  asks: SipQuote[];
  bids: SipQuote[];
};

export enum OrderSide {
  Buy = 'BUY',
  Sell = 'SELL'
}

export type TradeThroughDetails = {
  tradeThrough?: boolean;
  side?: OrderSide;
  quantity?: number;
  averagePrice?: number;
  bids: SipQuoteDetail[];
  asks: SipQuoteDetail[];
  malformed: boolean;
};
