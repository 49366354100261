import { useEffect, useState } from 'react';
import type { Optional } from '@oms/shared/util-types';
import type { MontageInvestorAccountFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { MontageService } from '../montage.service';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';

export const useMontageInvestorAccountDetails = (): Optional<MontageInvestorAccountFragment> => {
  const scopedActorId = useScopedActorId();
  const montageService = useService(MontageService);

  const [investorAccount, setInvestorAccount] = useState<Optional<MontageInvestorAccountFragment>>(undefined);

  useEffect(() => {
    const subscription = montageService.watchInvestorAccountDetails$(scopedActorId).subscribe((ia) => {
      setInvestorAccount(ia);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [scopedActorId, montageService]);

  return investorAccount;
};
