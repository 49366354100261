import { TypedStorage } from '@oms/shared/util';
import { singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class LocalStorageService extends TypedStorage {
  constructor() {
    super(localStorage);
  }
}
