import type { ModificationType } from '@oms/generated/frontend';
import type { Maybe } from '@oms/shared/util-types';
import { modificationTypeMapping } from './order-enums';

export const mapModificationType = (
  value?: Maybe<ModificationType> | string,
  defaultVal = value || ''
): string => {
  return value && modificationTypeMapping[value as ModificationType]
    ? modificationTypeMapping[value as ModificationType]
    : defaultVal;
};
