import { Select } from '@oms/frontend-foundation';
import type { FieldProps } from '@oms/frontend-foundation';
import { isEqual } from 'lodash';
import React from 'react';
import type { FC } from 'react';
import type { SingleSelectListProps } from '../../fixatdl-form.types';
import { mapFixAtdlListOptions } from '../../utils';

export const SingleSelectList: FC<FieldProps<SingleSelectListProps>> = React.memo(({ options, ...rest }) => {
  return <Select {...rest} options={mapFixAtdlListOptions(options)} component="select" />;
}, isEqual);
