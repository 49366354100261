import type { AnyKey, AnyRecord, RequireSome } from '@oms/shared/util-types';
import type { GetHierarchyInfoFrom, WithHierarchy, WithHierarchySet } from '../../types/tree-data.types';
import type { AnyHierarchyOptions, PathKeyToHierarchyOptions } from '../internal/types';
import { DEFAULT_GET_HIERARCHY_FN, DEFAULT_SEPARATOR } from '../internal/constants';

// 🌳 Hierarchy utils -------------------------------------------------------- /
// Utils to manage/convert/use tree data hierarchy information

/** Hierarchy conversions */
export const convert = {
  hierarchy: <Key extends AnyKey = string>(hierarchy: Key[]) => ({
    toPathKey: (options?: AnyHierarchyOptions): string =>
      hierarchy.map((level) => level.toString()).join(options?.separator ?? DEFAULT_SEPARATOR)
  }),
  pathKey: (pathKey: string) => ({
    toHierarchy: <Key extends AnyKey = string>(options?: PathKeyToHierarchyOptions<Key>): Key[] =>
      pathKey
        .split(options?.separator ?? DEFAULT_SEPARATOR)
        .map((stringValue) => options?.convertString?.(stringValue) ?? (stringValue as Key)) as Key[]
  })
};

/**
 * A `hierarchy` is an array describing the parents of an item in tree data.
 * This is for displaying a grid with tree data.
 * This adds the hierarchy as an `Array`
 *
 * @param data - An input object
 * @param getHierarchyInfo.getHierarchy - Logic to derive the hierarchy from the input
 * @param getHierarchyInfo.getHid - Logic to derive a hierarchy ID (hid) from the hierarchy. If not specified, a default function will generate a hierarchy ID by prefixing it's identifier with it's level.
 * @param options.separator - Separator used to join `pathKey` (Defaults to "." if omitted)
 * @returns A new array with `hierarchy` and `hierarchySet` added.
 */
export const addHierarchy = <TData extends AnyRecord, S extends AnyKey = string>(
  data: TData,
  getHierarchyInfo: RequireSome<Partial<GetHierarchyInfoFrom<TData, S>>, 'getHierarchy'>,
  options?: AnyHierarchyOptions
): WithHierarchy<TData, S> => {
  const { getHid, getHierarchy } = getHierarchyInfo;
  const hierarchy = getHierarchy(data);
  const parentHierarchy = hierarchy.slice(0, -1);
  const pathKey = convert.hierarchy(hierarchy).toPathKey(options);
  const hid = (getHid ?? DEFAULT_GET_HIERARCHY_FN)(hierarchy);
  return {
    ...data,
    hierarchy,
    parentHierarchy,
    pathKey,
    hid
  };
};

/**
 * A `hierarchy` is an array describing the parents of an item in tree data.
 * This is for displaying a grid with tree data.
 * This adds both the hierarchy as a `Set` (for faster lookup) when the input
 * already has a `hierarchy`.
 *
 * @param input - An input object known to contain a `hierarchy`.
 * @returns A new array with `hierarchySet` added.
 */
export const addHierarchySet = <TData extends AnyRecord, S extends AnyKey = string>(
  data: WithHierarchy<TData, S>
): WithHierarchySet<TData, S> => ({
  ...data,
  hierarchySet: new Set(data.hierarchy)
});

/**
 * A `hierarchy` is an array describing the parents of an item in tree data.
 * This is for displaying a grid with tree data.
 * This adds both the hierarchy as an `Array` and `Set` (for faster lookup)
 *
 * @param data - An input object
 * @param getHierarchyInfo.getHierarchy - Logic to derive the hierarchy from the input
 * @param getHierarchyInfo.getHid - Logic to derive a hierarchy ID (hid) from the hierarchy. If not specified, a default function will generate a hierarchy ID by prefixing it's identifier with it's level.
 * @param options.separator - Separator used to join `pathKey` (Defaults to "." if omitted)
 * @returns A new array with `hierarchy` and `hierarchySet` added.
 */
export const addHierarchyWithSet = <TData extends AnyRecord, S extends AnyKey = string>(
  data: TData,
  getHierarchyInfo: RequireSome<Partial<GetHierarchyInfoFrom<TData, S>>, 'getHierarchy'>,
  options?: AnyHierarchyOptions
): WithHierarchySet<TData, S> => addHierarchySet(addHierarchy(data, getHierarchyInfo, options));

// Type predicates --------------------------------------------- /

export const hasHierarchy = <TData extends AnyRecord, S extends AnyKey = string>(
  input?: unknown
): input is WithHierarchy<TData, S> => {
  if (typeof input !== 'object' || input === null) return false;
  const { hierarchy, hid, pathKey } = input as Partial<WithHierarchy<TData, S>>;
  return Array.isArray(hierarchy) && typeof hid === 'string' && typeof pathKey === 'string';
};

export const hasHierarchySet = <TData extends AnyRecord, S extends AnyKey = string>(
  input?: unknown
): input is WithHierarchySet<TData, S> => {
  if (!hasHierarchy(input)) return false;
  const { hierarchySet } = input as Partial<WithHierarchySet<TData, S>>;
  return hierarchySet instanceof Set;
};
