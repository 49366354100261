import { ICellRendererParams } from '@ag-grid-community/core';
import {
  DropdownMenuRoot,
  DropdownMenuTrigger,
  Flex,
  IconButton,
  Icon,
  IconProps,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal
} from '@oms/shared-frontend/ui-design-system';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { get } from 'lodash';
import isFunction from 'lodash/isFunction';
import ShortUniqueId from 'short-unique-id';
import { AnyRecord } from '@oms/frontend-foundation';

export const ICON_BUTTON_GROUP_CELL = 'iconButtonGroupCell';

export type IconButtonType = 'menu' | 'link';

export type IconButtonData = string | string[] | ((data: any) => string | string[]);

export interface IconButton<TData extends AnyRecord> {
  iconProps: IconProps;
  data: IconButtonData;
  fileName?: string | ((data: TData) => string);
  type: IconButtonType;
}

export interface IconButtons<TData extends AnyRecord> {
  buttons: IconButton<TData>[];
}

export type IconButtonGroupCellRendererParams<TData extends AnyRecord> = ICellRendererParams<TData> &
  IconButtons<TData>;

type RenderIcon<TData extends AnyRecord> = Omit<IconButton<TData>, 'data'> & { data: string[] | string };

const uniqueId = new ShortUniqueId();

export const IconButtonGroupCell: FC<IconButtonGroupCellRendererParams<AnyRecord>> = (props) => {
  const { buttons = [], data } = props;
  const rowId = uniqueId();

  const getFieldData = useCallback(
    (i: IconButtonData) => {
      const result = isFunction(i) ? i(data) : get(data, i) || i;
      return result;
    },
    [data]
  );

  const iconsToRender: RenderIcon<AnyRecord>[] = useMemo(() => {
    return buttons.map((o) => {
      const render: RenderIcon<AnyRecord> = {
        type: o.type,
        iconProps: o.iconProps,
        data: getFieldData(o.data)
      };

      return render;
    });
  }, [getFieldData, buttons]);

  const onMenuClick: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Flex direction={'row'} sx={{ flexWrap: 'wrap', paddingX: 2, width: 'full', height: 'full' }}>
      {iconsToRender.map((i, idx) => (
        <>
          {i.type === 'menu' && (
            <DropdownMenuRoot key={`${rowId}_${idx}_${i.type}`}>
              <DropdownMenuTrigger asChild onClick={onMenuClick}>
                <IconButton
                  variant="ghost"
                  icon={<Icon {...i.iconProps} sx={{ fontSize: 'gridLarge', color: 'icons.active' }} />}
                  aria-label={i.iconProps.label}
                />
              </DropdownMenuTrigger>
              <DropdownMenuPortal>
                <DropdownMenuContent side="bottom" align="start">
                  {Array.isArray(i.data) &&
                    i.data.map((d, didx) => (
                      <DropdownMenuItem key={`${rowId}_${idx}_${didx}_${i.type}`}>{d}</DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
              </DropdownMenuPortal>
            </DropdownMenuRoot>
          )}
          {i.type === 'link' && (
            <IconButton
              key={`${rowId}_${idx}_${i.type}`}
              as={'a'}
              target="_blank"
              variant="ghost"
              icon={<Icon {...i.iconProps} sx={{ fontSize: 'gridLarge', color: 'icons.active' }} />}
              download={isFunction(i.fileName) && data ? i.fileName(data) : i.fileName || 'download'}
              href={i.data as string}
              aria-label={i.iconProps.label}
            />
          )}
        </>
      ))}
    </Flex>
  );
};
