/**
 * Grid size enum
 */
export enum AgSizeValues {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG'
}

/**
 * Grid size labels
 */
export enum AgSizeLabels {
  XS = 'Tiny',
  SM = 'Compact',
  MD = 'Regular',
  LG = 'Large'
}

/**
 * ag-grid size enum <> label mappings
 */
export const AgSizings = [
  { value: AgSizeValues.XS, label: AgSizeLabels.XS },
  { value: AgSizeValues.SM, label: AgSizeLabels.SM },
  { value: AgSizeValues.MD, label: AgSizeLabels.MD },
  { value: AgSizeValues.LG, label: AgSizeLabels.LG }
];

export const AgHeaderHeights = {
  [AgSizeValues.XS]: 22,
  [AgSizeValues.SM]: 25,
  [AgSizeValues.MD]: 28,
  [AgSizeValues.LG]: 31
};

export const AgRowHeights = {
  [AgSizeValues.XS]: 21,
  [AgSizeValues.SM]: 24,
  [AgSizeValues.MD]: 27,
  [AgSizeValues.LG]: 30
};
