import { inject, Lifecycle, scoped } from 'tsyringe';
import { ToolbarLocationKeys, ToolbarStrategies, ToolbarStrategy } from '../models/toolbar.model';
import { VGridInstance } from '../models/v.instance.model';
import { BehaviorSubject, map, Observable } from 'rxjs';

export type UpdateToolbarConfig = {
  locations: ToolbarLocationKeys[];
  strategy: ToolbarStrategy;
};

@scoped(Lifecycle.ContainerScoped)
export class ToolbarService {
  private _toolbarStrategies$: BehaviorSubject<ToolbarStrategies>;

  constructor(@inject(VGridInstance.ToolbarStrategies) strategies: ToolbarStrategies) {
    this._toolbarStrategies$ = new BehaviorSubject(strategies);
  }

  public byLocation$(location: ToolbarLocationKeys): Observable<ToolbarStrategy> {
    return this._toolbarStrategies$.pipe(map((strats) => strats[location]));
  }

  public byLocation(location: ToolbarLocationKeys): ToolbarStrategy {
    return this._toolbarStrategies$.getValue()[location];
  }

  public all$(): Observable<ToolbarStrategies> {
    return this._toolbarStrategies$.asObservable();
  }

  public all(): ToolbarStrategies {
    return this._toolbarStrategies$.getValue();
  }

  public update(payload: UpdateToolbarConfig): void {
    const nextConfig = this._toolbarStrategies$.getValue();

    for (const location of payload.locations) {
      nextConfig[location] = payload.strategy;
    }

    this._toolbarStrategies$.next(nextConfig);
  }
}
