import type { ComboBoxItemUnion, ComboBoxItem } from '../combo-box.types';

/**
 * Extracts and flattens items from a ComboBoxItemUnion[] into a flat list of ComboBoxItem[]
 *
 * @param items - ComboBoxItemUnion[]
 * @returns ComboBoxItem[]
 */
export function extractAndFlattenItems(items: ComboBoxItemUnion[]): ComboBoxItem[] {
  const result: ComboBoxItem[] = [];

  items.forEach((item) => {
    if (item.type === 'group' || item.type === 'tab') {
      result.push(...extractAndFlattenItems(item.items));
    } else if (item.type === 'item') {
      result.push(item);
    }
  });

  return result;
}
