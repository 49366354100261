import {
  AsyncError,
  AsyncTimeoutError,
  FieldValidationError,
  GeneralError,
  InternalServerError,
  ServerError,
  type ServerParseError,
  UnauthorizedError,
  ValidationError
} from '@oms/shared/oms-common';
import { type ExtendedGQLErrorType } from './graphql-result';
import { type GraphQLParsedError } from './graphql.types';
import { EnvelopeFeedbackError } from './graphql-envelope';

const parseBadUserInputMessage = ({ message }: any): GraphQLParsedError => {
  const [error, details] = message.split('; ');
  const [_message, suggestion] = details.split('. ');
  const matches = error.match(/"([^"]*)"/g);
  const completeField = matches[matches.length - 1].slice(1, -1);
  const value = matches.length === 3 ? matches[1] : null;
  const index = +completeField.match(/\d/g) + 1;
  const [_arrName, field] = completeField.split('.');
  return {
    message: `Invalid value for field "${field}" provided on item #${index}.`,
    field,
    index,
    value,
    suggestion
  };
};

const parseBadFieldInputMessage = ({ context, path }: any): GraphQLParsedError => {
  return {
    message: `Invalid value for field "${context.key}" provided on item #${path[0] + 1}.`,
    field: context.key,
    index: path[0],
    value: context.value
  };
};

export const parseGQLErrors = (errors: any) => {
  const errorCode = errors[0].extensions.code;
  switch (errorCode) {
    case 'BAD_USER_INPUT':
      return errors.map((error: any) => parseBadUserInputMessage(error));
    case 'BAD_FIELD_INPUT':
      return errors[0].extensions.details.map((error: any) => parseBadFieldInputMessage(error));
    default:
      return errors;
  }
};

export const extractErrorCode = (error: ExtendedGQLErrorType) => {
  switch (true) {
    case error instanceof ServerError:
      return (error as ServerError | ServerParseError).code;
    case error instanceof InternalServerError:
    case error instanceof UnauthorizedError:
    case error instanceof AsyncTimeoutError:
    case error instanceof EnvelopeFeedbackError:
    case error instanceof AsyncError:
    case error instanceof ValidationError:
    case error instanceof FieldValidationError:
    case error instanceof GeneralError:
      return (
        error as
          | GeneralError
          | FieldValidationError
          | ValidationError
          | AsyncError
          | AsyncTimeoutError
          | EnvelopeFeedbackError
          | UnauthorizedError
          | InternalServerError
      ).extensions.code;
    default: {
      if ('code' in error && typeof error.code === 'string') {
        return error.code;
      }

      if (
        'extensions' in error &&
        typeof error.extensions === 'object' &&
        error.extensions &&
        'code' in error.extensions &&
        typeof error.extensions.code === 'string'
      ) {
        return error.extensions.code;
      }

      return 'UNKNOWN_ERROR';
    }
  }
};
