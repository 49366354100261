import { AppWorkspace } from '@app/app-config/workspace.config';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

/**
 * ProcessState
 *
 * Used to emit the current processId and isLeaderProcess state
 * and to listen for changes to the processId and isLeaderProcess state
 *
 * Used primarily at the framework level to emit the current processId and isLeaderProcess state
 * @see auth.signal
 * @see data-access.signal
 * @see plugin-process-id
 */
@testScoped
@singleton()
export class ProcessState {
  public processId$ = new BehaviorSubject<string | undefined>(undefined);
  public isLeaderProcess$ = new BehaviorSubject<boolean | undefined>(undefined);

  constructor(@inject(AppWorkspace) private appWorkspace: AppWorkspace) {}

  get LEADER_PROCESS_ID() {
    return this.appWorkspace.getLeaderProcessId();
  }

  get processId() {
    return this.processId$.getValue();
  }

  setProcessId(processId: string | undefined) {
    this.processId$.next(processId);
    switch (processId) {
      case this.LEADER_PROCESS_ID:
        this.isLeaderProcess$.next(true);
        break;
      case undefined:
        this.isLeaderProcess$.next(undefined);
        break;
      default:
        this.isLeaderProcess$.next(false);
        break;
    }
  }

  setLeader() {
    this.setProcessId(this.LEADER_PROCESS_ID);
  }

  async isLeaderProcess() {
    if (this.processId === this.LEADER_PROCESS_ID) {
      return true;
    }

    return await firstValueFrom(
      this.processId$.pipe(
        map((processId) => {
          return processId === this.LEADER_PROCESS_ID;
        })
      )
    );
  }
}
