import { registerComponent, COMMON_OBJECT_CATEGORY } from '@app/app-config/registry.config';

const config = registerComponent({
  key: 'POSITIONS_ACCOUNT_GRID',
  title: 'Positions Account Grid',
  objectCategory: COMMON_OBJECT_CATEGORY.TRADING,
  showInCommandPalette: false,
  exludeFromDictionary: true
});

export default config;
