import type { OrderDiscretionaryType } from '@oms/generated/frontend';
import { discretionaryTypeMapping } from './order-enums';

type OrderDiscretionaryTypeKeys = `${OrderDiscretionaryType}`;

export const mapOrderDiscretionaryType = (
  discretionaryType?: OrderDiscretionaryType | OrderDiscretionaryTypeKeys | null
): string => {
  return discretionaryType ? discretionaryTypeMapping[discretionaryType] || discretionaryType : '';
};
