import { Plugin, isTauri } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import type { Unsubscribe } from '@valstro/remote-link';
import { appWindow } from '@tauri-apps/api/window';
import type { ApolloClientLinkType } from '@app/data-access/api/apollo-client';
import { createExposedApolloClient, createGraphQLAuthWsClient } from '@app/data-access/api/apollo-client';
import { getValidPersistedAuthState } from '@app/common/auth/auth.helpers';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import { UUID, createLogger } from '@oms/shared/util';
import type { WildcardMockLink } from 'wildcard-mock-link';
import type { DependencyContainer } from 'tsyringe';
import { DataAccessSignal } from '@app/data-access/memory/data-access.signal';
import { AuthSignal } from '@app/data-access/memory/auth.signal';

/**
 * Logger
 */
export const dataAccessPluginLogger = createLogger({ label: 'Data Access Plugin' });

export interface DataAccessPluginOptions {
  apolloMockLink?: WildcardMockLink;
  container: DependencyContainer;
  /**
   * Unique ID for the apollo client
   * Useful for testing
   */
  id?: string;
}

/**
 * Manages data access
 */
export const dataAccessPlugin = ({ container, id, apolloMockLink }: DataAccessPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-data-access-plugin',
    pluginFn: ({ workspace }) => {
      let unsubAuth: Unsubscribe | undefined;
      let apolloRPC: ReturnType<typeof registerApolloClientProxy> | undefined;
      let exposedApolloClientTeardown: ReturnType<typeof setupExposedApolloClient> | undefined;
      const apolloClientId = id ? id : apolloMockLink ? UUID() : 'app-client';
      const dataAccessSignalService = container.resolve(DataAccessSignal);
      const authSignalService = container.resolve(AuthSignal);
      const authSignal = authSignalService.signal;
      const dataAccessSignal = dataAccessSignalService.signal;

      async function setupApolloClient(auth: AuthClientState) {
        const isSuccess = auth.lastAuthClientEvent === 'onAuthSuccess';
        const hasSetup = !!exposedApolloClientTeardown && !!apolloRPC;
        const isUnauthenticated =
          auth.lastAuthClientEvent === 'onTokenExpired' ||
          auth.lastAuthClientEvent === 'onAuthLogout' ||
          auth.lastAuthClientEvent === 'onAuthRefreshError';
        switch (true) {
          case isSuccess && hasSetup === false:
            exposedApolloClientTeardown = setupExposedApolloClient(apolloClientId, apolloMockLink);
            apolloRPC = registerApolloClientProxy(workspace, apolloClientId);
            dataAccessSignal.set({
              isConnected: true,
              isReady: true
            });
            break;
          case isUnauthenticated && hasSetup === true:
            await exposedApolloClientTeardown?.();
            exposedApolloClientTeardown = undefined;
            apolloRPC?.teardown();
            apolloRPC = undefined;
            dataAccessSignal.set({
              isConnected: false,
              isReady: true
            });
        }
      }

      workspace.addHook('leaderElection', ({ isLeader }) => {
        if (unsubAuth) {
          unsubAuth();
        }

        if (isLeader) {
          const state = authSignal.get();
          setupApolloClient(state).catch(console.error);
          unsubAuth = authSignal.subscribe((auth) => {
            setupApolloClient(auth).catch(console.error);
          });

          return;
        }

        if (isLeader === false) {
          apolloRPC?.teardown();
          apolloRPC = registerApolloClientProxy(workspace, apolloClientId);
          return;
        }
      });

      return async function unsubscribe() {
        await exposedApolloClientTeardown?.();
        apolloRPC?.teardown();
        unsubAuth?.();
        dataAccessSignalService.reset();
      };
    }
  });

/**
 * Creates an Apollo Client instance and exposes it
 * to the other processes
 */
function setupExposedApolloClient(apolloClientId: string, apolloMockLink?: WildcardMockLink) {
  const getAuthToken = () => {
    const authState = getValidPersistedAuthState();
    return authState?.token ?? '';
  };

  const wsClient = apolloMockLink ? undefined : createGraphQLAuthWsClient(getAuthToken);

  const linkType: ApolloClientLinkType = apolloMockLink ? 'mock' : 'auth-http-ws';
  const client = createExposedApolloClient(apolloClientId, linkType, {
    getAuthToken,
    graphqlWsClient: wsClient,
    mockLink: apolloMockLink
  });

  return async () => {
    if (wsClient) {
      await wsClient.dispose();
    }
    client.stop();
    dataAccessPluginLogger.log('Leader: Destroyed Exposed Apollo Client instance & GraphQL WS Client');
  };
}

/**
 * Initializes the Apollo Client proxy
 * and registers it as a service
 */
function registerApolloClientProxy(workspace: AppWorkspace, apolloClientId: string) {
  const client = new ApolloClientRPC(apolloClientId, {
    timeout: 10_000,
    destroyEventListener: isTauri()
      ? async (cb) => {
          const unsub = await appWindow.onCloseRequested(cb);
          return () => {
            unsub();
          };
        }
      : (cb) => {
          window.addEventListener('beforeunload', cb);
          return () => {
            window.removeEventListener('beforeunload', cb);
          };
        }
  });

  const container = workspace.meta?.container;
  if (!container) {
    throw new Error('Container not found');
  }

  container.register(ApolloClientRPC, { useValue: client });

  return {
    client,
    teardown: () => {
      client.destroy();
    }
  };
}
