import { enforceOptions } from 'broadcast-channel';
import { CommonOptions, CreateProxyOptions, ExposeOptions, GlobalOptions } from './types';

/**
 * Default timeouts for the remote link library
 */
export const DEFAULT_RESP_TIMEOUT = 15_000;
export const DEFAULT_HANDSHAKE_TIMEOUT = 50;

/**
 * Default common options for the remote link library
 */
const defaultCommonOptions: CommonOptions = {
  handshakeTimeout: DEFAULT_HANDSHAKE_TIMEOUT
};

/**
 * Default options for the expose function
 */
const defaultExposeOptions: ExposeOptions<any, any> = {
  ...defaultCommonOptions,
  awaitConfirmation: true,
  autoSerialize: true,
  observe: true,
  resolveProxy: true,
  hideWarnings: false,
  ignoreProperties: []
};

/**
 * Default options for the create proxy function
 */
const defaultCreateProxyOptions: CreateProxyOptions = {
  ...defaultCommonOptions,
  awaitConfirmation: true,
  resolveProxy: true,
  responseTimeout: DEFAULT_RESP_TIMEOUT
};

declare global {
  // Extending the global scope with your namespace
  // eslint-disable-next-line no-var
  var RemoteLinkOptions: GlobalOptions;
}

/**
 * Global options for the remote link library
 */
globalThis.RemoteLinkOptions = globalThis.RemoteLinkOptions || {
  common: defaultCommonOptions,
  expose: defaultExposeOptions,
  proxy: defaultCreateProxyOptions
};

/**
 * Sets the global options for the remote link library
 *
 * @param globalOptions - Global options for the remote link library
 */
export function setGlobalOptions(options: GlobalOptions) {
  if (options.broadcastChannelOptions) {
    enforceOptions(options.broadcastChannelOptions);
    globalThis.RemoteLinkOptions.broadcastChannelOptions = {
      ...globalThis.RemoteLinkOptions.broadcastChannelOptions,
      ...options.broadcastChannelOptions
    };
  }

  if (options.common) {
    globalThis.RemoteLinkOptions.common = {
      ...defaultCommonOptions,
      ...options.common
    };
  }

  if (options.expose) {
    globalThis.RemoteLinkOptions.expose = {
      ...defaultExposeOptions,
      ...options.common,
      ...options.expose
    };
  }

  if (options.proxy) {
    globalThis.RemoteLinkOptions.proxy = {
      ...defaultCreateProxyOptions,
      ...options.common,
      ...options.proxy
    };
  }
}

/**
 * Gets the global options for the remote link library
 *
 * @returns Global options for the remote link library
 */
export function getGlobalOptions() {
  return globalThis.RemoteLinkOptions;
}

/**
 * Checks if the broadcast channel is simulated
 */
export function isBroadcastChannelSimulated() {
  return getGlobalOptions().broadcastChannelOptions?.type === 'simulate';
}
