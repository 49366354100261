import { RequestOptions, Body, Response as TauriResponse, fetch as tauriFetch } from '@tauri-apps/api/http';

const createTauriBody = (body: BodyInit): Body => {
  if (body instanceof FormData) {
    return Body.form(body);
  }

  if (typeof body === 'string') {
    return Body.text(body);
  }

  if (typeof body === 'object') {
    return Body.json(body);
  }

  throw new Error(`Missing impl for Tauri fetch Type: ${typeof body}`);
};

/**
 * This function is intended to shim {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API | fetch} when running inside a Tauri App.
 * For security reasons, we need to be able to control the {@link https://tauri.app/v1/api/config/#httpallowlistconfig |desktops origin} in order to be compliant with {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS | CORS}
 * @param info {@link https://developer.mozilla.org/en-US/docs/Web/API/fetch | fetch}
 * @param init {@link https://developer.mozilla.org/en-US/docs/Web/API/fetch | fetch}
 * @returns A {@link https://developer.mozilla.org/en-US/docs/Web/API/Response | Response}
 */
const fetch = async (info: RequestInfo, init?: RequestInit): Promise<Response> => {
  const isTauri = !!window?.__TAURI_METADATA__;
  const fetchImpl: typeof fetch | typeof tauriFetch = isTauri ? tauriFetch : window.fetch;

  const opts: RequestOptions | RequestInit | undefined = isTauri
    ? ({
        method: (init?.method as unknown) || 'GET',
        headers: init?.headers,
        body: init?.body ? createTauriBody(init.body) : undefined
      } as RequestOptions)
    : init;

  const r = await fetchImpl(info.toString(), opts as any);
  if (isTauri) {
    const tauriResp = r as TauriResponse<any>;
    const stream = new ReadableStream({
      start(controller) {
        return pump();
        function pump() {
          controller.enqueue(Buffer.from(JSON.stringify(tauriResp.data)));
          controller.close();
        }
      },
      type: 'bytes'
    });
    const resp = new Response(stream, {
      headers: r.headers,
      status: r.status
    });
    return resp;
  }
  return r as Response;
};

export default fetch;
