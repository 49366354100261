import convert from './convert';
/**
 * @param hexColor - A valid hex color as a string
 * @param transparency - A percent transparency represented as a number between 0 (0%) and 1 (100%). For example, 0.5 = 50% transparent, etc.
 * @returns A string with the hexidecimal representation including extra alpha.
 */
export const addHexTransparency = (hexColor: string, transparency: number = 1): string => {
  const [validHex, hexValue] = hexColor.trim().match(/#([A-Fa-f0-9]{1,6})/) ?? [];
  if (!validHex || !hexValue) throw new Error(`Invalid hex color: ${hexColor}`);
  const hex =
    hexValue.length === 6
      ? validHex
      : (() => {
          let fullHex = validHex;
          const remaining = 6 - hexValue.length;
          const split = hexValue.split('');
          let index = 0;
          while (index < remaining) {
            fullHex += split[index % split.length];
            index += 1;
          }
          return fullHex;
        })();
  const hexTransparency = convert.percent.to.hex(transparency);
  return `${hex}${hexTransparency}`;
};
