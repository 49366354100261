import type { FactSetQuery } from './level2-websocket.types';

export const createFactSetQuery = (
  identifier: string,
  overrides?: Partial<Omit<FactSetQuery, 'identifier' | '_attributes' | '_subscriptionMinimumInterval'>>
): FactSetQuery => {
  const { identifierType = 'tickerExchange', maxLevels = 20, ...rest } = overrides || {};
  return {
    identifier,
    identifierType,
    maxLevels,
    ...rest
  };
};
