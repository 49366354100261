import { Cross1Icon } from '@radix-ui/react-icons';
import { PROCESS_ID } from '@valstro/workspace';
import { openConfirmation } from '@app/generated/sdk';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { t } from '@oms/codegen/translations';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';

import type { Notification } from '../notifications.contracts';

export const clearNotificationsOnChange = async (
  ctx: ActionContext<Notification, ActionComponentConfig<Notification>>
) => {
  const { lifecycle, workspace } = ctx;

  if (lifecycle === 'change') {
    try {
      const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
        title: t('app.notifications.dialog.clearNotifications.confirmationTitle'),
        componentProps: {
          autoClose: true,
          message: t('app.notifications.dialog.clearNotifications.confirmationMessage'),
          confirmButtonText: t('app.common.yes'),
          cancelButtonText: t('app.common.no')
        },
        windowOptions: {
          width: 400,
          height: 180
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          // For now do nothing.
          throw new Error('Function not implemented.');
          break;
        }
      }
    } catch (e) {
      openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
      console.error(e);
    }
  }
};

export const clearNotificationsAction: ActionDefFactory<Notification> = (builder) =>
  builder
    .name('clear_notifications')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('clear_notifications_button')
        .location('HorizontalToolbarLeft')
        .props({
          variant: 'link',
          leftIcon: <Cross1Icon />,
          content: 'Clear all notifications'
        })
    )
    .onChange<ActionComponentConfig<Notification>>(clearNotificationsOnChange);
