import type { ValidatorFunction } from '@data-driven-forms/react-form-renderer';
import { TimeInForce } from '@oms/generated/frontend';
import { DateTime } from 'luxon';

export const futureGTD: (opts: { message?: string }) => ValidatorFunction =
  ({ message = 'Must be future GTD' }) =>
  (_value: string | number | Date | undefined, allValues?: any, _meta?: object) => {
    if (allValues?.timeInForce !== TimeInForce.Gtd || !allValues?.gtdDate) {
      return null;
    }

    const { gtdDate, gtdTime } = allValues ?? {};
    const gtdTimestamp = DateTime.fromISO(!gtdTime ? gtdDate : `${gtdDate}T${gtdTime}`);
    const nowTimestamp = DateTime.now();

    const notInFuture =
      gtdTimestamp.isValid &&
      nowTimestamp.isValid &&
      nowTimestamp.toUTC().toMillis() > gtdTimestamp.toUTC().toMillis();

    return notInFuture ? message : null;
  };
