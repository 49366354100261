import { z } from 'zod';
import { createVersionedSchema } from '@oms/frontend-schemas';

export default createVersionedSchema('ROUTE_ORDER_FORM_INPUT', {
  version: 0,
  schema: z.object({
    mode: z
      .union([
        z.object({
          type: z.literal('route'),
          investorOrderId: z.string()
        }),
        z.object({
          type: z.literal('modify'),
          tradingOrderId: z.string(),
          location: z.literal('montage').optional()
        }),
        z.object({ type: z.literal('create'), location: z.literal('montage').optional() })
      ])
      .optional()
  })
});
