//@ts-nocheck
import { NumericInputActionHandler } from '../contracts/component-types';
import { INumericInputOptions } from '../contracts/config-types';
import { NumericInputActionType as ActionType } from '../contracts/enum-types';
import { IKeyInfo, INumericInputAction } from '../contracts/shared-types';
import { getHistoryKey } from '../utils/key-utils';
import * as keyHandlers from './key-handlers';

const createActions = (options: INumericInputOptions): INumericInputAction[] => [
  {
    names: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    type: ActionType.NUMBER
  },
  {
    names: ['-'],
    type: ActionType.MINUS
  },
  {
    names: [options.decimal, 'decimal'],
    type: ActionType.DECIMAL
  },
  {
    names: [options.thousands, 'separator'],
    type: ActionType.THOUSANDS
  },
  {
    names: Object.keys(options.shortcuts),
    type: ActionType.SHORTCUT
  },
  {
    names: ['backspace'],
    type: ActionType.BACKSPACE
  },
  {
    names: [
      'delete', // Chrome & Firefox
      'del' // Edge & IE
    ],
    type: ActionType.DELETE
  },
  {
    modifiers: [getHistoryKey()],
    names: ['z'],
    type: ActionType.UNDO
  },
  {
    modifiers: [getHistoryKey()],
    names: ['y'],
    type: ActionType.REDO
  }
];

export const getActionType = (keyInfo: IKeyInfo, options: INumericInputOptions): ActionType => {
  const actionTypes = createActions(options);

  const foundType = actionTypes.find(
    (actionType) =>
      actionType.names.indexOf(keyInfo.name) > -1 &&
      // eslint-disable-next-line no-self-compare
      JSON.stringify(keyInfo.modifiers) === JSON.stringify(keyInfo.modifiers)
  );

  return foundType ? foundType.type : ActionType.UNKNOWN;
};

const handlerForAction = {
  [ActionType.NUMBER]: keyHandlers.onNumber,
  [ActionType.DECIMAL]: keyHandlers.onDecimal,
  [ActionType.THOUSANDS]: keyHandlers.onThousands,
  [ActionType.MINUS]: keyHandlers.onMinus,
  [ActionType.SHORTCUT]: keyHandlers.onShortcut,
  [ActionType.BACKSPACE]: keyHandlers.onBackspace,
  [ActionType.DELETE]: keyHandlers.onDelete,
  [ActionType.UNDO]: keyHandlers.onUndo,
  [ActionType.REDO]: keyHandlers.onRedo,
  [ActionType.UNKNOWN]: keyHandlers.onUnknown
};

/**
 * Get the key henadler for a given action type
 *
 * @param action ActionType discriminator
 */
export const getHandlerForAction = (action: ActionType): NumericInputActionHandler => {
  return handlerForAction[action];
};
