import { ValueFormatterParams } from '@ag-grid-community/core';
import { AnyDateInput, dateTime, dateTimeUi } from '@oms/shared/util-date';
import { Interval } from '@oms/generated/frontend';
import { format } from 'date-fns';

const TIME_PATTERN = 'HH:mm:ss';
const TIME_WITH_TIMEZONE_PATTERN = 'MMM d, yyyy h:mm:ss a (z)';

const formatDate = (pattern: string) => (params: ValueFormatterParams) =>
  params.value ? format(new Date(params.value), pattern) : '';

export const DATE_FORMATTERS = {
  formatTime: formatDate(TIME_PATTERN),
  formatTimeWithTimezone: formatDate(TIME_WITH_TIMEZONE_PATTERN),
  dateTime: ({ value }: { value?: AnyDateInput }) => dateTime(value),
  dateTimeUi: ({ value }: { value?: AnyDateInput }) => dateTimeUi(value, 'dateAndTime'),
  dateOnlyUi: ({ value }: { value?: AnyDateInput }) => dateTimeUi(value, 'date'),
  timeOnlyUi: ({ value }: { value?: AnyDateInput }) => dateTimeUi(value, 'time'),
  timeOnlyFromInterval: ({ value }: { value?: Interval }) => {
    if (value) {
      return (
        value.hours?.toString().padStart(2, '0') +
        ':' +
        value.minutes?.toString().padStart(2, '0') +
        ':' +
        value.seconds?.toString().padStart(2, '0')
      );
    } else {
      return '';
    }
  }
};

export type DateFormatters = keyof typeof DATE_FORMATTERS;
