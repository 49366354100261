import type {
  ActionComponentConfig,
  ActionContext,
  ActionDefFactory,
  SplitButtonActionComponentConfig
} from '@oms/frontend-vgrid';
import type { VisibleModificationInfoFragment } from '@oms/generated/frontend';

export const acceptAllPendingModificationOnChange = async (
  ctx: ActionContext<VisibleModificationInfoFragment, ActionComponentConfig<VisibleModificationInfoFragment>>
) => {
  const numberOfRows = ctx.api.getDisplayedRowCount();
  ctx.notify({ isDisabled: numberOfRows === 0 });

  if (ctx.lifecycle === 'change') {
    const notifications: [string, VisibleModificationInfoFragment][] = [];
    for (let i = 0; i < numberOfRows; ++i) {
      const row = ctx.api.getDisplayedRowAtIndex(i);
      if (row) {
        const { id: rowId, data } = row;
        if (!rowId) {
          continue;
        }
        // TODO when adding other options, filter and push only push rows we can accept
        notifications.push([rowId, data as VisibleModificationInfoFragment]);
      }
    }
    ctx.notify(
      {
        selectedId: 'accept'
      } as SplitButtonActionComponentConfig<VisibleModificationInfoFragment>,
      {
        targets: notifications.map(([rowId, rowData]) => ({
          actionName: 'inline_pending_modification_split_button',
          location: 'Inline',
          rowId,
          rowsData: rowData ? [rowData] : undefined
        }))
      }
    );
  }
};

export const acceptAllPendingModificationAction: ActionDefFactory<VisibleModificationInfoFragment> = (
  builder
) =>
  builder
    .name('accept_all')
    .toolbar((t) =>
      t.id('accept_all_toolbar').component('action-button').location('HorizontalToolbarRight').props({
        content: 'Accept All',
        isDisabled: true
      })
    )
    .onChange<ActionComponentConfig<VisibleModificationInfoFragment>>(acceptAllPendingModificationOnChange);
