import { useAppWorkspace } from '@app/common/workspace/workspace.hooks';
import { openModifyTradingOrder } from '@app/widgets/trading/route-order/route-order.form-open';
import type { FoundationWorkspace } from '@oms/frontend-foundation';
import { useCallback } from 'react';

export const openTradingOrderModify = (
  workspace: FoundationWorkspace,
  id: string,
  symbol?: string | null
) => {
  openModifyTradingOrder(workspace, {
    id,
    symbol: symbol || ''
  });
};

export const useOpenTradingOrderModify = (tradingOrderId: string, orderSymbol?: string | null) => {
  const workspace = useAppWorkspace();
  return useCallback(
    () => openTradingOrderModify(workspace, tradingOrderId, orderSymbol),
    [workspace, orderSymbol, tradingOrderId]
  );
};
