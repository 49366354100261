const radii = {
  // CT: In Figma:
  // buttons' border-radius values are 5px, 6px, 8px for S, M, L.
  // inputs' border-radius values are 4px, 8px, 8px for S, M, L.
  // (Maybe speak with the design team to compromise. Perhaps 4px, 6px, 8px across the board?)

  none: '0',
  xs: '0.167rem', // 2px
  sm: '0.333rem', // 4px
  base: '0.5rem',
  md: '0.5rem', // 6px
  lg: '0.666rem', // 8px
  full: '9999px',
  half: '50%'
};

export type Radii = typeof radii;

export default radii;
