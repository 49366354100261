import React from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Input } from '@oms/shared-frontend/ui-design-system';
import type { FieldProps, ICommonTextField } from '../../types';
import { useFieldApi, useFieldFocus } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';

export interface ITextField<TValidator = Validator>
  extends ICommonTextField<typeof FORM_COMPONENT_TYPE.TEXT_FIELD, string, TValidator> {}

export const TextField: React.FC<FieldProps<ITextField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onBlur, onChange, onFocus, value },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isFeatureField,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    hideFormControls,
    style,
    sx,
    wrapperSx = {},
    format,
    initialValue: _initialValue,
    value: _value,
    fieldDef: _fieldDef,
    ...rest
  } = useFieldApi<ITextField, string>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  // Combine field/input props
  const fieldProps = {
    onBlur,
    onChange,
    onFocus,
    value,
    hidden: isVisible === false,
    style,
    sx,
    format,
    ...rest,
    name
  };

  // Combine field wrapper props
  const fieldWrapperProps = {
    meta,
    label,
    isReadOnly,
    isRequired,
    isDisabled,
    isInvalid,
    requiredFieldIndicatorStyle,
    helperText,
    hideFormControls,
    isFeatureField,
    isPrimaryField,
    isVisible,
    sx: wrapperSx
  };

  // Handle selectAllOnFocus & autoFocus
  const [inputRef, onInputFocus] = useFieldFocus<HTMLInputElement>(props);

  return (
    <FieldWrapper {...fieldWrapperProps}>
      <Input ref={inputRef} {...fieldProps} onFocus={onInputFocus} />
    </FieldWrapper>
  );
};
