import type { ColumnLibrary } from '@oms/frontend-vgrid';
import type { PositionHistoryRow } from '@app/common/types/positions/positions.types';
import {
  defaultColumn,
  id,
  instrumentSymbolData,
  eventTimestamp,
  event,
  eventUserId,
  eventId,
  account,
  eventCurrency,
  eventQuantity,
  eventPrice,
  quantity,
  averagePrice,
  realizedPnL,
  updateEventTimestamp,
  updateEventId,
  updateEvent
} from './position-history.columns';

const { displayCode, name } = instrumentSymbolData;

export const positionsHistoryColumnLibrary: ColumnLibrary<PositionHistoryRow> = {
  defaultColumn,
  columns: [
    id,
    displayCode,
    name,
    eventTimestamp,
    event,
    eventUserId,
    eventId,
    account,
    eventCurrency,
    eventQuantity,
    eventPrice,
    quantity,
    averagePrice,
    realizedPnL,
    updateEventTimestamp,
    updateEventId,
    updateEvent
  ]
};

export default positionsHistoryColumnLibrary;
