import { ParameterTypes } from '@valstro/fixatdl-core';
import type { ClockControl, Timezones, ParameterQuery } from '@valstro/fixatdl-core';
import type { ControlTransformer } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';

export const clockControl: ControlTransformer<ClockControl> = (clockControl, query, formVals) => {
  const componentMapping: { [key: string]: string } = {
    [ParameterTypes.TZTimeOnly]: fixatdlFormComponentTypes.TIME_PICKER,
    [ParameterTypes.UTCTimeOnly]: fixatdlFormComponentTypes.TIME_PICKER,
    [ParameterTypes.UTCTimestamp]: fixatdlFormComponentTypes.DATETIME_PICKER,
    [ParameterTypes.TZTimestamp]: fixatdlFormComponentTypes.DATETIME_PICKER,
    [ParameterTypes.LocalMktDate]: fixatdlFormComponentTypes.DATETIME_PICKER
  };

  const control = query.findControl(clockControl.id);
  const paramQuery = control?.parameter();
  const { parameter: param } = paramQuery || ({} as ParameterQuery);
  const localMktTz: Timezones | undefined = control?.find('localMktTz') || paramQuery?.find('localMktTz');

  return {
    component: param?.type ? componentMapping[param.type] : fixatdlFormComponentTypes.DATETIME_PICKER,
    label: clockControl.label,
    name: clockControl.id,
    initialValue: AtdlFormats.coerceValue(formVals[clockControl.id], {
      formats: {
        date: { format: 'iso', from: localMktTz, to: 'UTC' },
        time: { format: 'iso-date-time', from: localMktTz, to: 'UTC' }
      }
    }),
    initValueMode: clockControl.initValueMode
  };
};
