import { GetContextMenuItemsParams, MenuItemDef, MenuItemLeafDef } from '@ag-grid-community/core';
import { AnyRecord } from '@oms/frontend-foundation';

export interface MenuItemDefExtended extends MenuItemLeafDef {
  id?: string;
  subMenu?: (MenuItemDefExtended | string)[];
}

export type ContextMenuItemCallback<TData extends AnyRecord> = (
  params: GetContextMenuItemsParams<TData>
) => string | MenuItemDefExtended | undefined;

export type ContextMenuItemId = string | number;

export interface ContextMenuItem<TData extends AnyRecord> {
  id: ContextMenuItemId;
  callback: ContextMenuItemCallback<TData>;
  pinToBottom?: boolean;
}

export enum GridMenuOptionLocation {
  ContextMenu = 'contextMenu',
  GlobalMenu = 'globalMenu'
}

export type GetMenuOptionCb<TData extends AnyRecord> = (
  params: GetContextMenuItemsParams<TData>
) => string | MenuItemDef;

export type GridMenuOption<TData extends AnyRecord> = {
  locations: GridMenuOptionLocation[];
  id: string;
  get: GetMenuOptionCb<TData>;
};

export type GridMenuOptions<TData extends AnyRecord> = GridMenuOption<TData>[];
