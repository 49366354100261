import type { RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';
import { OrderSideType, OrderType, PriceOptions } from '@oms/generated/frontend';
import { convertToNumber, formatNumber } from '@oms/shared/util';

export const VALIDATE_ROUTE_ORDER_PRICE = 'validateRouteOrderPrice';

export const validateRouteOrderPrice = () => (valueAsNumber: number) => {
  // Don't validate if the value is 0 cause it's a market order
  if (valueAsNumber === 0) {
    return null;
  }

  if (valueAsNumber < 0.01) {
    return 'Min value $0.01';
  }

  return null;
};

export const VALIDATE_ROUTE_ORDER_LIMIT_PRICE = 'validateRouteOrderLimitPrice';

export const validateRouteOrderLimitPrice =
  () => (value: RouteOrderFormValues['limitPrice'], allValues?: RouteOrderFormValues) => {
    const valueAsNumber = value !== undefined && value !== null ? convertToNumber(value) : 0;
    const formValues: Partial<RouteOrderFormValues> = allValues ? allValues : {};
    const { hiddenMode } = formValues || {};

    if (Object.values(PriceOptions).includes(value as PriceOptions)) {
      return null;
    }

    switch (hiddenMode?.type) {
      case 'route': {
        const originalOrderType = hiddenMode?.investorOrderType;
        const originalOrderLimitPrice =
          hiddenMode?.type === 'route' ? hiddenMode.investorOrderLimitPrice : undefined;

        if (originalOrderType === OrderType.Market) {
          return validateRouteOrderPrice()(valueAsNumber);
        }

        // Run validation for limit orders
        // Make sure, depending on the sideType, the limit price is NOT more aggressive than it should be...
        const orderPrice = ['string', 'number'].includes(typeof originalOrderLimitPrice)
          ? convertToNumber(originalOrderLimitPrice)
          : undefined;

        if (orderPrice === undefined) {
          return validateRouteOrderPrice()(valueAsNumber);
        }

        const price = ['string', 'number'].includes(typeof value) ? convertToNumber(value) : 0;
        const sideType = formValues.sideType || ('' as OrderSideType);
        const buySides = [OrderSideType.Buy, OrderSideType.Btc];
        const sellSides = [OrderSideType.Sell, OrderSideType.Short, OrderSideType.Exempt];

        if (sellSides.includes(sideType)) {
          if (price < orderPrice) {
            return `Min value $${formatNumber(orderPrice)}`;
          }
        } else if (buySides.includes(sideType)) {
          if (price > orderPrice) {
            return `Max value $${formatNumber(orderPrice)}`;
          } else if (price < 0.01) {
            return 'Min value $0.01';
          }
        } else {
          return 'Incorrect value';
        }
        break;
      }
      default:
        return validateRouteOrderPrice()(valueAsNumber);
    }
  };
