import type { ExchangeFragment } from '@oms/generated/frontend';
import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { sharedDefaultCol, sharedIdCol, sharedTextCol } from '@app/common/grids/columns/generic-cols';
import { sharedNameCol } from '@app/common/grids/columns/reference-data-cols';
import { micLevelMapping } from './exchange.form-fields';

const defaultCol: ColumnBuilderCallback<ExchangeFragment> = (c) => sharedDefaultCol<ExchangeFragment>(c);

const idCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedIdCol<ExchangeFragment>(c, 'id').header('Code').width(100);

const longNameCol: ColumnBuilderCallback<ExchangeFragment> = (c) => sharedNameCol(c, 'longName').width(400);

const typeCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedTextCol<ExchangeFragment>(c, 'exchangeType').header('Type').width(100);

const micCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedTextCol<ExchangeFragment>(c, 'id').header('MIC Code').width(100);

const micLevelCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedDefaultCol<ExchangeFragment>(c)
    .field('micLevel')
    .header('MIC Level')
    .cell((cell) =>
      cell
        .valueFormatter((params) =>
          typeof params.value === 'string' && Object.keys(micLevelMapping).includes(params.value)
            ? micLevelMapping[params.value]
            : ''
        )
        .filterValueGetter((params) => micLevelMapping[params.getValue('micLevel')])
    )
    .width(100);

const countryIdCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedIdCol<ExchangeFragment>(c, 'countryId').header('Country').width(100);

const startTimeCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedTextCol<ExchangeFragment>(c, 'startTime')
    .header('Start Time')
    .shortHeader('Start')
    .cell((c) => c.valueFormatterRegistry('timeOnlyFromInterval'))
    .width(100);

const endTimeCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedTextCol<ExchangeFragment>(c, 'endTime')
    .header('End Time')
    .shortHeader('End')
    .cell((c) => c.valueFormatterRegistry('timeOnlyFromInterval'))
    .width(100);

const partialStartTimeCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedTextCol<ExchangeFragment>(c, 'partialStartTime')
    .header('Partial Start Time')
    .shortHeader('Partial Start')
    .cell((c) => c.valueFormatterRegistry('timeOnlyFromInterval'))
    .width(110);

const partialEndTimeCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedTextCol<ExchangeFragment>(c, 'partialEndTime')
    .header('Partial End Time')
    .shortHeader('Partial End')
    .cell((c) => c.valueFormatterRegistry('timeOnlyFromInterval'))
    .width(110);

const timezoneCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedTextCol<ExchangeFragment>(c, 'timezone').header('Timezone').shortHeader('Zone').width(200);

const expiryOffsetCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedTextCol<ExchangeFragment>(c, 'expiryOffset')
    .header('Expiry Offset (minutes)')
    .shortHeader('Exp Off')
    .format('number')
    .width(110);

const extendedExpiryOffsetCol: ColumnBuilderCallback<ExchangeFragment> = (c) =>
  sharedTextCol<ExchangeFragment>(c, 'extendedExpiryOffset')
    .header('Extended Expiry Offset (minutes)')
    .shortHeader('Ext Exp Off')
    .format('number')
    .width(110);

export const exchangesColumnLibrary: ColumnLibrary<ExchangeFragment> = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    longNameCol,
    typeCol,
    micCol,
    micLevelCol,
    countryIdCol,
    startTimeCol,
    endTimeCol,
    partialStartTimeCol,
    partialEndTimeCol,
    timezoneCol,
    expiryOffsetCol,
    extendedExpiryOffsetCol
  ]
};
