import { useMemo } from 'react';
import { useWorkspace as _useWorkspace } from '@valstro/workspace-react';
import type { FoundationFormMappers } from './form.contracts';
import type { FoundationWorkspace } from '../../workspace/workspace.common';

export function useWorkspaceFormMappers(): FoundationFormMappers {
  const workspace = _useWorkspace<FoundationWorkspace>();
  const mappers = useMemo(
    () =>
      workspace?.meta?.formMappers || {
        componentMapper: {},
        validatorMapper: {},
        actionMapper: {},
        conditionMapper: {},
        advancedSelectQueryMapper: {},
        formBuilderTemplateMapper: {},
        formBuilderMapper: {}
      },
    [workspace]
  );
  return mappers;
}

export function useWorkspaceFormBuilderByKey<T extends string>(key: T) {
  const mappers = useWorkspaceFormMappers();
  const formBuilderDef = useMemo(() => mappers.formBuilderMapper[key], [key, mappers.formBuilderMapper]);
  if (!formBuilderDef) throw new Error('No form definition found in workspace meta for key: ' + key);
  return formBuilderDef;
}
