import { FieldContract } from '@oms/frontend-foundation';
import type { ActionButtonLayoutFormOutput } from './action-button-layout.contracts';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { FORM_VALIDATOR_TYPE } from '@app/forms/form-builder/common/form.contracts';

const actionButtonLayoutContract = FieldContract.create<
  ActionButtonLayoutFormOutput,
  AdditionalFormFieldUnion
>();

export const actionButtonLayoutField = actionButtonLayoutContract
  .field('layout', 'action-button-layout')
  .options({
    validate: [{ type: FORM_VALIDATOR_TYPE.VALIDATE_ACTION_BUTTON_LAYOUT }]
  });
