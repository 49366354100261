import { MenuItemDef } from '@ag-grid-community/core';
import { BuilderCallback } from '@oms/shared/util-types';
import { isFunction } from 'lodash';
import { AnyRecord } from '@oms/frontend-foundation';
import { ContextMenuItemDef, type ContextMenuItemPredicate } from '../models/actions.model';

export class ContextMenuBuilder<TData extends AnyRecord> {
  private _menu: Partial<ContextMenuItemDef<TData>> = {};

  public name(name: string): ContextMenuBuilder<TData> {
    this._menu.name = name;
    return this;
  }

  public disabled(disabled: boolean | ContextMenuItemPredicate<TData>): ContextMenuBuilder<TData> {
    this._menu.disabled = disabled;
    return this;
  }

  public shortcut(shortcut: string): ContextMenuBuilder<TData> {
    this._menu.shortcut = shortcut;
    return this;
  }

  public action(fn: () => void): ContextMenuBuilder<TData> {
    this._menu.action = fn;
    return this;
  }

  public checked(checked: boolean): ContextMenuBuilder<TData> {
    this._menu.checked = checked;
    return this;
  }

  public visible(visible: boolean | ContextMenuItemPredicate<TData> = true): ContextMenuBuilder<TData> {
    this._menu.visible = visible;
    return this;
  }

  public tooltip(tooltip: string): ContextMenuBuilder<TData> {
    this._menu.tooltip = tooltip;
    return this;
  }

  public cssClasses(classes: string[]): ContextMenuBuilder<TData> {
    this._menu.cssClasses = classes;
    return this;
  }

  public subMenu(
    cbOrSep: BuilderCallback<ContextMenuBuilder<TData>> | 'separator',
    order: number = this._menu.subMenu?.length || 0
  ): ContextMenuBuilder<TData> {
    this._menu.subMenu = this._menu.subMenu || [];
    if (isFunction(cbOrSep)) {
      const builder = new ContextMenuBuilder<TData>();
      const extendedBuilder = cbOrSep(builder);
      const item = extendedBuilder.build();
      this._menu.subMenu.splice(order, 0, item);
    } else {
      this._menu.subMenu.splice(order, 0, cbOrSep);
    }

    return this;
  }

  public build(): MenuItemDef {
    if (!this._menu.name) {
      throw new Error('Menu name is required.');
    }

    return this._menu as MenuItemDef;
  }
}
