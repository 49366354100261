import { Box, type BoxProps } from '../../system/components/box/box';
import clsx from 'clsx';
import { VisuallyHidden } from '../visually-hidden/visually-hidden';
import { RocketIcon } from '@radix-ui/react-icons';
import * as styles from './icon.css';
import { useMemo } from 'react';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { __DEV__ } from '../../system/utils/assertion';

export type IconProps = {
  as?: React.ElementType;
  isInline?: boolean;
  role?: string;
  /** If omitted, 1em will be set as the default unless you specify `suppressDefaultBoxSize={true}` */
  boxSize?: string | number;
  /** Mark as `true` if you are setting size via CSS, else 1em will be set as the default box size */
  suppressDefaultBoxSize?: boolean;
  /**
   * The accessible label for the icon. This label will be visually hidden but announced to screen
   * reader users, similar to `alt` text for `img` tags.
   */
  label: string;
} & Omit<BoxProps, 'as'>;

export const Icon = polymorphicComponent<'svg', IconProps>((props, ref) => {
  const {
    as: Comp = RocketIcon,
    isInline = true,
    className,
    boxSize,
    suppressDefaultBoxSize,
    role = 'presentation',
    label,
    style = {},
    ...rest
  } = props;

  const _style = useMemo((): typeof style => {
    if (typeof boxSize === 'undefined' && suppressDefaultBoxSize) return style;
    const size = boxSize ?? '1em';
    return {
      width: size,
      height: size,
      ...style
    };
  }, [boxSize, suppressDefaultBoxSize, style]);

  return (
    <>
      <Box
        as={Comp as any}
        ref={ref}
        className={clsx(isInline ? styles.iconVariants['inline'] : styles.iconVariants['block'], className)}
        role={role}
        aria-hidden={true}
        focusable={false}
        style={_style}
        {...rest}
      />
      <VisuallyHidden>{label}</VisuallyHidden>
    </>
  );
});

if (__DEV__) {
  Icon.displayName = 'Icon';
}
