import { type ComponentType, useCallback, forwardRef, useMemo, useState } from 'react';
import {
  NumericFormat as NumericFormatComponent,
  type NumericFormatProps as NumericFormatComponentProps,
  type InputAttributes,
  type OnValueChange,
  numericFormatter
} from 'react-number-format';
import { Input } from '../input/input';
import type { InputProps } from '../input/input';
import { Sprinkles } from '../../system/sprinkles.css';
import { __DEV__ } from '../../system/utils/assertion';
import { pickBy } from 'lodash';
import {
  type CommonNumberFormatOptions,
  type FormatNumericOnlyKeys,
  numericFormatPropsMap,
  convertNumericStringOrNumToNum
} from '../../formatting/format';
import { useDeepMemo } from '../../hooks/use-deep-memo';

export type OnNumberFormatValueChange = OnValueChange;

type PickNumberFormatProps =
  | 'onChange'
  | 'onValueChange'
  | 'value'
  | 'defaultValue'
  | 'isAllowed'
  | 'valueIsNumericString';

export type UniqueNumberFormatProps = Omit<CommonNumberFormatOptions, 'negativeOneAsEmpty'>;

export type NumberFormatProps = {
  /** Text alignment. Default is `right`. */
  textAlign?: Sprinkles['textAlign'];
} & Pick<NumericFormatComponentProps, PickNumberFormatProps> &
  UniqueNumberFormatProps &
  Omit<InputProps, 'onChange' | 'value' | 'defaultValue'> & {
    type?: FormatNumericOnlyKeys;
    onChange?: NumericFormatComponentProps['onChange'];
  };

export const NumberFormat = forwardRef<HTMLInputElement, NumberFormatProps>((props, ref) => {
  const {
    sx = {},
    type,
    decimalScale,
    thousandSeparator = true,
    textAlign,
    fixedDecimalScale,
    allowLeadingZeros,
    allowNegative,
    decimalSeparator,
    suffix,
    prefix,
    thousandsGroupStyle,
    allowedDecimalSeparators,
    valueIsNumericString,
    isAllowed,
    onValueChange,
    defaultValue,
    value: _value,
    onKeyDown,
    ...restOfInputProps
  } = props;
  const isControlled = onValueChange !== undefined;
  const [internalValue, setInternalValue] = useState<string | number | undefined>(
    _value || defaultValue || undefined
  );

  const onValueChangeHandler: OnNumberFormatValueChange = useCallback(
    (values, sourceInfo) => {
      if (onValueChange) {
        onValueChange(values, sourceInfo);
      } else {
        setInternalValue(values.floatValue);
      }
    },
    [onValueChange]
  );

  // need to use an empty string to clear the input as undefined doesn't do it
  const value = isControlled ? _value || '' : internalValue || '';

  const inputProps: InputProps = useDeepMemo(() => {
    return {
      ...restOfInputProps,
      sx: {
        textAlign: textAlign ? textAlign : 'right',
        ...sx
      }
    };
  }, [sx, textAlign, restOfInputProps]);

  const CustomInput: ComponentType<InputAttributes> = useCallback(
    ({ size: _, ...restOfNumberFormatAttr }) => {
      return <Input {...restOfNumberFormatAttr} {...inputProps} ref={ref} />;
    },
    [inputProps, ref]
  );

  const numericProps = useMemo(() => {
    const props: NumericFormatComponentProps = {
      thousandSeparator,
      decimalScale,
      allowNegative,
      suffix,
      prefix,
      thousandsGroupStyle,
      decimalSeparator,
      allowedDecimalSeparators,
      fixedDecimalScale,
      allowLeadingZeros,
      valueIsNumericString,
      value
    };
    if (type && numericFormatPropsMap[type]) {
      const input = value;
      const fn = numericFormatPropsMap[type];
      const relevantProps = pickBy(props, (v) => v !== undefined);
      const numberInput = convertNumericStringOrNumToNum(input, relevantProps);
      const [newValue, newProps] = fn(
        {
          input,
          numberInput,
          mode: 'input'
        },
        relevantProps
      );
      return pickBy(
        {
          ...newProps,
          // Need to re-format (even though we are passing correct props)
          // to ensure correct formatting between raw format fn and numeric format component
          // it appears NumberFormat will round numbers whereras their internal numericFormatter will not
          // If we WANT rounding we'll need to our own rounding logic in both the raw format fn and here
          value: numericFormatter(`${newValue}`, newProps)
        },
        (v) => v !== undefined
      );
    }

    return pickBy(props, (v) => v !== undefined);
  }, [
    type,
    thousandSeparator,
    decimalScale,
    allowNegative,
    suffix,
    prefix,
    thousandsGroupStyle,
    decimalSeparator,
    allowedDecimalSeparators,
    fixedDecimalScale,
    allowLeadingZeros,
    valueIsNumericString,
    value
  ]);

  return (
    <NumericFormatComponent
      {...numericProps}
      onKeyDown={onKeyDown}
      isAllowed={isAllowed}
      onValueChange={onValueChangeHandler}
      customInput={CustomInput}
    />
  );
});

if (__DEV__) {
  NumberFormat.displayName = 'NumberFormat';
}
