import { forwardRef } from 'react';
import { __DEV__ } from '../../system/utils/assertion';
import { DefaultProps } from '../../system/utils/types';

interface INativeImageProps extends DefaultProps {
  /**
   * The native HTML `width` attribute to the passed to the `img`
   */
  htmlWidth?: string | number;
  /**
   * The native HTML `height` attribute to the passed to the `img`
   */
  htmlHeight?: string | number;
}

interface NativeImageProps extends React.ImgHTMLAttributes<HTMLImageElement>, INativeImageProps {}

export type { NativeImageProps };

export const NativeImage = forwardRef((props: NativeImageProps, ref: React.Ref<any>) => {
  const { htmlWidth, htmlHeight, style = {}, alt, ...rest } = props;
  return (
    <img
      width={htmlWidth}
      height={htmlHeight}
      ref={ref}
      alt={alt}
      style={{
        ...style,
        height: htmlHeight
      }}
      {...rest}
    />
  );
});

if (__DEV__) {
  NativeImage.displayName = 'NativeImage';
}
