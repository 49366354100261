import type { FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import {
  useEnhancedFormApi,
  useService,
  AdvancedSelectField,
  useFieldApi,
  useFormBuilderTemplate
} from '@oms/frontend-foundation';
import type { FieldProps, ICommonField, AnyRecord, ComboBoxItem } from '@oms/frontend-foundation';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { FIXatdlStrategyValue, PartialFIXatdlStrategyValue } from './fixatdl-strategy-field.contracts';
import type { RouteOrderFormValues } from '../route-order.form-contract';
import {
  getFIXatdlStrategyValue,
  getStrategyWindowId,
  getVenueIdFromRouteForm
} from './fixatdl-strategy-field.util';
import { StrategyService } from './fixatdl-strategy-field.service';
import { distinctUntilChanged } from 'rxjs';
import { isEqual } from 'lodash';
import {
  routeOrderValuesChange,
  strategyValuesChanged,
  useStrategyFormValues,
  validateStrategyField
} from './fixatdl-strategy-field.state';
import { useStrategies } from './fixatdl-strategy-field.hooks';
import { useOpenStrategyParameters } from '@app/generated/sdk';
import type { IAdvancedSelectFieldUnique, IAdvancedSelectValue } from '@oms/frontend-foundation';
import { MarketDataService } from '@app/data-access/services/marketdata/marketdata.service';

export type IFixatdlStrategyField<TValidator = Validator> = IAdvancedSelectFieldUnique<FIXatdlStrategyValue> &
  ICommonField<
    typeof FORM_COMPONENT_TYPE.FIXATDL_STRATEGY,
    IAdvancedSelectValue<FIXatdlStrategyValue>,
    TValidator
  > & { venueIdKey?: string; strategyFieldKey?: string; isDialogEnabled?: boolean };

export const FIXatdlStrategyField: React.FC<FieldProps<IFixatdlStrategyField>> = ({
  isDisabled: _isDisabled,
  options: _options,
  ...props
}) => {
  const formApi = useEnhancedFormApi<AnyRecord>();

  const {
    input: { value },
    venueIdKey = 'venue',
    strategyFieldKey = 'strategy',
    isDialogEnabled = true
  } = useFieldApi<IFixatdlStrategyField, IAdvancedSelectValue<FIXatdlStrategyValue>>(props);
  const [venueId, setVenueId] = useState('');
  const service = useService(StrategyService);
  const [isDisabled, setIsDisabled] = useState(!!_isDisabled);
  const modifyMode = formApi.getState().values?.hiddenMode?.type === 'modify';
  const disabledClickActive = value.value && isDisabled;
  const [modifyModeDialogInit, setModifyModeDialogInit] = useState(false);
  const { getOptions, options, isLoading } = useStrategies();
  const { formId } = useFormBuilderTemplate();
  const strategyFormValues = useStrategyFormValues(formId);
  const openStrategyParameters = useOpenStrategyParameters();
  const marketDataService = useService(MarketDataService);

  const valueRef = useRef(value);
  valueRef.current = value;

  useEffect(() => {
    // update the strategy field value if the strategy params form state changes
    if (!strategyFormValues?.strategyControls?.length) return;
    const newValue = valueRef.current?.value || {};

    newValue.strategyParams = strategyFormValues.strategyParams;
    newValue.strategyControls = strategyFormValues.strategyControls;

    formApi.change(strategyFieldKey, {
      ...valueRef.current,
      value: newValue
    });
  }, [formApi, strategyFieldKey, strategyFormValues]);

  useEffect(() => {
    // this sets the value of a hidden field, which is used in the strategy field validator
    formApi.change(
      'hiddenStrategyOptions',
      options.map((o) => o.id)
    );
  }, [formApi, options]);

  const findInstrumentById = useCallback(
    async (instrumentId: string) => {
      return await marketDataService.getInstrumentById(instrumentId);
    },
    [marketDataService]
  );

  useEffect(() => {
    const subscription = formApi.get$({ values: true, fields: [venueIdKey] }).subscribe(({ values }) => {
      // close dialog and clear strategy input when venue is changed
      const id = getVenueIdFromRouteForm(values, venueIdKey);

      if (!modifyMode) {
        const strategyName = values[strategyFieldKey]?.value?.strategyName;
        const initialLoad = !id || id === venueId;
        const venueCleared = venueId && !id;

        if (strategyName && venueId && (!initialLoad || venueCleared)) {
          service.closeStrategyDialog(formId, strategyName);
          formApi.change(strategyFieldKey, null);
        }
      }

      // update strategy options if different venue was selected
      const instrumentId = values?.instrument?.id;

      if (id && id !== venueId) {
        if (instrumentId) {
          findInstrumentById(instrumentId)
            .then((instrumentDetails) => {
              const countryId = instrumentDetails?.listingExchange?.countryId;
              void getOptions({ executionVenueId: id, countryId });
            })
            .catch(console.error);
        } else {
          void getOptions({ executionVenueId: id });
        }
      }

      // clear strategy parameter state if venue got removed
      if (!id) {
        strategyValuesChanged(formId, {});
      }

      setVenueId(id || '');
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    service,
    formApi,
    getOptions,
    venueId,
    formId,
    modifyMode,
    venueIdKey,
    strategyFieldKey,
    findInstrumentById
  ]);

  useEffect(() => {
    const subscription = formApi
      .get$({ values: true })
      .pipe(distinctUntilChanged(isEqual))
      .subscribe(({ values }) => {
        // fields such as quantity and price, and potentially other fields, can impact the strategy validation
        // as we don't know all the fields, we need to re-validate strategy on every field change
        if (values[strategyFieldKey]) {
          validateStrategyField(values, strategyFormValues?.strategyFormRawValues, formApi);
        }

        // new values need to be passed to the strategy dialog
        routeOrderValuesChange(formId, values);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [service, formApi, formId, strategyFormValues?.strategyFormRawValues, strategyFieldKey]);

  useEffect(() => {
    const subscription = formApi
      .get$({ values: true, fields: [strategyFieldKey] })
      .pipe(distinctUntilChanged(isEqual))
      .subscribe(({ values }) => {
        if (!values[strategyFieldKey] && valueRef.current?.value?.strategyName) {
          service.closeStrategyDialog(formId, valueRef.current.value.strategyName);
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [service, formApi, formId, strategyFieldKey]);

  useEffect(() => {
    setIsDisabled(!venueId || options.length <= 1 || modifyMode || isLoading);
  }, [venueId, options, isLoading, formApi, modifyMode]);

  // Open dialog on input click (if it's disabled, we have a value and the strategy has controls)
  const onDisabledClick = useCallback(() => {
    const value = valueRef.current;
    const hasControls = value.value?.isLayoutsPopulated || !!value.value?.strategyControls?.length;
    const hasParameters = !!value.value?.strategyParams?.length;

    if (venueId && value?.value && hasControls && !(modifyMode && !hasParameters)) {
      const fixAtdlValue: FIXatdlStrategyValue = getFIXatdlStrategyValue(
        null,
        value,
        venueId,
        formApi.getState().values as RouteOrderFormValues
      );
      openStrategyParameters('current', {
        windowId: getStrategyWindowId(formId, value.value?.strategyName),
        title: fixAtdlValue.strategyName,
        componentProps: {
          ...fixAtdlValue,
          formId
        }
      }).catch(console.error);

      setModifyModeDialogInit(true);
    }
  }, [formApi, formId, modifyMode, openStrategyParameters, venueId]);

  useEffect(() => {
    // this will automatically open the strategy dialog on initial load in modify mode
    if (modifyMode && !modifyModeDialogInit) {
      onDisabledClick();
    }
  }, [modifyMode, onDisabledClick, modifyModeDialogInit]);

  const onItemClick = useCallback(
    (item: ComboBoxItem<PartialFIXatdlStrategyValue>) => {
      const prevStrategyName = valueRef.current?.value?.strategyName;
      const currStrategyName = item.value.strategyName;

      // if the strategy is changed close the dialog before a new one is open
      if (prevStrategyName && currStrategyName !== prevStrategyName) {
        service.closeStrategyDialog(formId, prevStrategyName);
      }

      const newValue = getFIXatdlStrategyValue(
        valueRef.current,
        item,
        venueId,
        formApi.getState().values as RouteOrderFormValues
      );

      formApi.change(strategyFieldKey, {
        ...item,
        value: newValue
      });

      if (item?.value.isLayoutsPopulated && isDialogEnabled) {
        openStrategyParameters('current', {
          windowId: getStrategyWindowId(formId, newValue.strategyName),
          title: newValue.strategyName,
          componentProps: {
            ...newValue,
            formId
          }
        }).catch(console.error);
      }
    },
    [venueId, formApi, strategyFieldKey, service, formId, openStrategyParameters]
  );

  useEffect(() => {
    // Every time options are updated, we need to check if there is only 1 strategy.
    // If so, we need to select it by default.
    if (options.length === 1 && venueId && !isLoading) {
      void onItemClick(options[0]);
    }
  }, [options, onItemClick, venueId, isLoading]);

  return (
    <div style={{ position: 'relative' }} data-testid="strategy-field-wrapper">
      <div
        onClick={disabledClickActive ? onDisabledClick : undefined}
        style={{
          position: 'absolute',
          inset: 0,
          zIndex: 1,
          pointerEvents: disabledClickActive ? 'all' : 'none'
        }}
        data-testid="strategy-field"
      />
      <AdvancedSelectField
        {...props}
        label="Strategy"
        component="advanced-select"
        isDisabled={isDisabled}
        shouldAutoFilter={true}
        onItemClick={onItemClick}
        options={options}
        optionsOnFocus={true}
        isLoading={isLoading}
      />
    </div>
  );
};
