import { useEffect, useRef } from 'react';
import * as RadixCollapsible from '@radix-ui/react-collapsible';
import type { CollapsibleProps as RadixCollapsibleProps } from '@radix-ui/react-collapsible';
import { CaretDownIcon, CaretUpIcon } from '../../icons/icons';

import { Stack } from '../../layout/stack/stack';
import { useState } from 'react';

import * as styles from './css/collapsible.css';
import { IconButton } from '../button/icon-button';
import { Sprinkles } from '../../system/sprinkles.css';
import { Box } from '../../system/components/box/box';
import { Divider } from '../../layout/divider/divider';

type OmittedProps = 'onChange';
type FilteredRadixCollapsibleProps = Omit<RadixCollapsibleProps, OmittedProps>;

export interface CollapsibleProps extends FilteredRadixCollapsibleProps {
  // DDF Expects onChange
  onChange?: (expanded: boolean) => void;
  label?: string;
  direction?: string;
  defaultOpen?: boolean;
  headerSx?: Sprinkles;
  contentSx?: Sprinkles;
  forceMount?: true; //this is to match Radix type
  showDivider?: boolean;
}

export const getScrollParent = (node: Node | null): Element | undefined => {
  const regex = /(auto|scroll)/;

  const parents = (_node: Node | null, ps: Element[]): Element[] => {
    if (_node === null || _node.parentNode === null) {
      return ps;
    }
    return parents(_node.parentNode, ps.concat([_node as Element]));
  };

  const style = (_node: Element, prop: string): string =>
    getComputedStyle(_node, null).getPropertyValue(prop);

  const overflow = (_node: Element): string =>
    style(_node, 'overflow') + style(_node, 'overflow-y') + style(_node, 'overflow-x');

  const scroll = (_node: Element): boolean => regex.test(overflow(_node));

  const scrollParent = (_node: Node | null): Element | undefined => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return;
    }

    const ps = parents(_node.parentNode, []);

    for (let i = 0; i < ps.length; i += 1) {
      if (scroll(ps[i])) {
        return ps[i];
      }
    }

    return document.scrollingElement || document.documentElement;
  };

  return scrollParent(node);
};

export const Collapsible: React.FC<CollapsibleProps> = ({
  defaultOpen,
  onOpenChange,
  onChange,
  disabled,
  label,
  direction,
  children,
  className,
  style,
  headerSx,
  contentSx,
  forceMount,
  showDivider
}) => {
  const [currentOpen, setCurrentOpen] = useState(defaultOpen);
  const collapsibleRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setCurrentOpen(defaultOpen);
  }, [defaultOpen]);

  const handleOpenChange = (open: boolean) => {
    // Fire onChange so DDF knows we've changed the field value.
    if (onChange) {
      onChange(open);
    }

    // Call our onOpenChange
    if (onOpenChange) {
      onOpenChange(open);
    }

    setCurrentOpen(open);

    if (open && collapsibleRef.current) {
      // const nearestScrollParent = getScrollParent(collapsibleRef.current);
      // TODO: Danny to implement later.
    }
  };

  return (
    <RadixCollapsible.Root
      asChild={false}
      defaultOpen={defaultOpen}
      open={currentOpen}
      onOpenChange={handleOpenChange}
      disabled={disabled}
      ref={collapsibleRef}
      className={className}
      style={style}
    >
      <RadixCollapsible.CollapsibleTrigger asChild>
        <Box
          className={showDivider ? styles.headerWithNoBorder : styles.headerWithBorder}
          sx={{
            borderBottomLeftRadius: currentOpen ? 'none' : 'sm',
            borderBottomRightRadius: currentOpen ? 'none' : 'sm',
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 1.5,
            paddingBottom: 1.5,
            ...headerSx
          }}
        >
          {label}

          <IconButton
            type="button"
            className={styles.iconButton}
            icon={currentOpen ? <CaretUpIcon /> : <CaretDownIcon />}
            aria-label="Toggle"
          />
        </Box>
      </RadixCollapsible.CollapsibleTrigger>
      {showDivider && <Divider />}
      <RadixCollapsible.CollapsibleContent forceMount={forceMount}>
        <Box
          className={showDivider ? styles.contentWithNoBorder : styles.contentWithBorder}
          sx={{ paddingLeft: 4, paddingRight: 4, paddingTop: 4, paddingBottom: 4, ...contentSx }}
        >
          {direction === 'horizontal' ? <Stack>{children}</Stack> : children}
        </Box>
      </RadixCollapsible.CollapsibleContent>
    </RadixCollapsible.Root>
  );
};
