import { t } from '@oms/codegen/translations';
import type { UnknownRecord } from '@oms/frontend-foundation';
import type { ColumnBuilder, ColumnBuilderField } from '@oms/frontend-vgrid';
import { sharedTextCol } from './generic-cols';

export const sharedDescriptionCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) =>
  sharedTextCol(c, fieldPath)
    .header(t('app.common.description'))
    .shortHeader(t('app.common.description', { ns: 'short' }));

export const sharedNameCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => sharedTextCol(c, fieldPath).header(t('app.common.name')).minWidth(100);

export const sharedLongNameCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => sharedTextCol(c, fieldPath).header('Long Name');

export const sharedActiveCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) =>
  sharedTextCol(c, fieldPath)
    .header(t('app.common.active'))
    .shortHeader(t('app.common.active', { ns: 'short' }))
    .width(80)
    .bool();
