import { ToggleButton } from '@oms/shared-frontend/ui-design-system';
import type { ToggleButtonProps } from '@oms/shared-frontend/ui-design-system';

const style = {
  height: 'calc(var(--primarySize) - 1px)'
} as const;

const sx = { mr: 2 } as const;

export type InstrumentFollowToggleButtonProps = ToggleButtonProps & {
  isPrimaryField?: boolean;
};

export const InstrumentFollowToggleButton: React.FC<InstrumentFollowToggleButtonProps> = ({
  isPrimaryField,
  ...props
}) => {
  return (
    <ToggleButton
      shape="square"
      size={isPrimaryField ? 'md' : 'sm'}
      variant="secondary"
      sx={sx}
      style={style}
      aria-label="Follow instrument"
      {...props}
    >
      ◎
    </ToggleButton>
  );
};
