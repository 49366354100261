import {
  DisplayField,
  type DisplayFieldProps,
  type DisplayGridItemProps
} from '@oms/shared-frontend/ui-design-system';
import { type APP_DISPLAY_FIELD_COMPONENT_TYPE } from '../display-grid.contracts';
import { type PopoverTriggerProps } from '@valstro/workspace-react-plugin-popover';
import {
  AppDisplayGridPopoverTrigger,
  type PopoverAppDisplayGridComponentProps
} from '../../popovers/display-grid/popover.display-grid';

export type PopoverDisplayFieldContentProps = {
  testId?: string;
} & PopoverAppDisplayGridComponentProps;

export type PopoverDisplayFieldProps = {
  type: typeof APP_DISPLAY_FIELD_COMPONENT_TYPE.Popover;
  content: PopoverDisplayFieldContentProps;
  options?: Omit<PopoverTriggerProps, 'ariaLabel' | 'componentId' | 'ariaType' | 'componentProps'>;
  value: DisplayGridItemProps | string | number;
};

export const PopoverDisplayField: React.FC<PopoverDisplayFieldProps> = ({ content, options = {}, value }) => {
  const isSingleValue = typeof value === 'string' || typeof value === 'number';
  const ariaLabel = isSingleValue ? `${value}` : value['aria-label'] || value.label || 'Display Grid Popover';
  return (
    <AppDisplayGridPopoverTrigger
      asChild={true}
      ariaLabel={ariaLabel}
      items={content.items}
      gridProps={content.gridProps}
      testId={content.testId}
      align="start"
      side="bottom"
      {...options}
    >
      <div>
        {isSingleValue ? (
          value
        ) : (
          <DisplayField
            label={value.label}
            layout={value.layout}
            component={value.component as DisplayFieldProps['component']}
            key={value.label}
            labelMargin={value.labelMargin}
            testId={value.testId}
          />
        )}
      </div>
    </AppDisplayGridPopoverTrigger>
  );
};
