import { Subject } from 'rxjs';
import { BroadcastChannel } from 'broadcast-channel';

export class BroadcastSubject<T = any> extends Subject<T> {
  private _bc: BroadcastChannel<T>;

  constructor(channelName: string) {
    super();
    this._bc = new BroadcastChannel<T>(channelName);
    this._bc.onmessage = (msg) => {
      super.next(msg);
    };
  }

  next(value: T) {
    super.next(value);
    this._bc.postMessage(value).catch(console.error);
  }

  unsubscribe() {
    super.unsubscribe();
    this._bc.close().catch(console.error);
  }

  complete() {
    super.complete();
    this._bc.close().catch(console.error);
  }
}
