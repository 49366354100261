import { openTradingOrderView } from '@app/generated/sdk';
import type { VisibleTradingOrderInfoFragment as TOFragment } from '@oms/generated/frontend';
import { getOrderDialogTitle } from '../orders.utils';
import { useCallback } from 'react';
import { useLeaderOrTabId } from '@app/common/workspace/workspace.hooks';

export interface IOpenViewTradingOrderArgs {
  windowId: string;
  tradingOrderData: TOFragment;
}

export const openViewTradingOrder = async ({ windowId, tradingOrderData }: IOpenViewTradingOrderArgs) => {
  await openTradingOrderView(windowId, {
    componentProps: {
      id: tradingOrderData.id
    },
    title: getOrderDialogTitle(tradingOrderData)
  });
};

export const useOpenViewTradingOrder = (tradingOrderData?: TOFragment | null) => {
  const windowId = useLeaderOrTabId();

  return useCallback(() => {
    if (!tradingOrderData) {
      console.error('tradingOrderData is null or undefined');
      return;
    }
    openViewTradingOrder({ windowId, tradingOrderData }).catch((e) => console.error(e));
  }, [windowId, tradingOrderData]);
};
