import { inject, Lifecycle, scoped } from 'tsyringe';
import { GridIdService } from './grid-id.service';

export interface IShortcutService {
  init(): void | Promise<void>;
  destroy(): void | Promise<void>;
}

@scoped(Lifecycle.ContainerScoped)
export class ShortcutService implements IShortcutService {
  private _gridIdService: GridIdService;
  private _scrollContainer: HTMLElement | null = null;
  private _isSpacebarDown = false;

  constructor(@inject(GridIdService) gridIdService: GridIdService) {
    this._gridIdService = gridIdService;
  }

  public init() {
    this._scrollContainer = this._gridIdService.getGridViewportEl();
    if (this._scrollContainer) {
      window.addEventListener('keydown', this._onKeyDown.bind(this));
      window.addEventListener('keyup', this._onKeyUp.bind(this));
      this._scrollContainer.addEventListener('wheel', this._onWheel.bind(this));
    }
  }

  public destroy() {
    if (this._scrollContainer) {
      window.removeEventListener('keydown', this._onKeyDown.bind(this));
      window.removeEventListener('keyup', this._onKeyUp.bind(this));
      this._scrollContainer.removeEventListener('wheel', this._onWheel.bind(this));
    }
  }

  private _onKeyDown(evt: KeyboardEvent) {
    if (evt.code === 'Space' && this._isSpacebarDown === false) {
      this._isSpacebarDown = true;
    }
  }

  private _onKeyUp(_evt: KeyboardEvent) {
    this._isSpacebarDown = false;
  }

  private _onWheel(evt: WheelEvent) {
    if (this._scrollContainer && this._isSpacebarDown) {
      evt.preventDefault();
      this._scrollContainer.scrollLeft += evt.deltaY;
    }
  }
}
