import { DependencyContainer, instanceCachingFactory, Lifecycle } from 'tsyringe';
import { constructor } from 'tsyringe/dist/typings/types';

export const singletonWorkspaceRegistry: constructor<any>[] = [];

/**
 * Registers singletons as container scoped for testing purposes.
 * Makes sure all child containers made during the test use the same instance.
 */
export function testScoped(constructor: constructor<any>) {
  singletonWorkspaceRegistry.push(constructor);
}

export const containerScoped = (container: DependencyContainer, registry: constructor<any>[] = []) => {
  const tokens = [...singletonWorkspaceRegistry, ...registry];
  tokens.forEach((Token) => {
    if (!container.isRegistered(Token)) {
      container.register(
        Token,
        {
          useClass: Token
        },
        { lifecycle: Lifecycle.ContainerScoped }
      );
    }
  });
};

export const cacheToContainer = (container: DependencyContainer, registry: constructor<any>[] = []) => {
  const tokens = [...singletonWorkspaceRegistry, ...registry];
  tokens.forEach((Token) => {
    const instance = container.resolve(Token);
    container.register(Token, {
      useFactory: instanceCachingFactory(() => instance)
    });
  });
};
