import { ScaleBasis } from '@oms/generated/frontend';
import { scaleBasisMapping } from './order-enums';

export const mapScaleBasis = (
  value?: ScaleBasis | string | null,
  defaultValue: string = value || ''
): string => {
  return value && scaleBasisMapping[value as ScaleBasis]
    ? scaleBasisMapping[value as ScaleBasis]
    : defaultValue;
};

export const mapStringToScaleBasis = (value?: string): ScaleBasis => {
  switch (value) {
    case 'Order Value':
      return ScaleBasis.OrderValue;
    case 'Price':
      return ScaleBasis.Price;
    case 'Quantity':
      return ScaleBasis.Quantity;
    default:
      return ScaleBasis.Quantity;
  }
};
