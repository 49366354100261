import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { useAppWorkspace } from '@app/common/workspace/workspace.hooks';
import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import { t } from '@oms/codegen/translations';
import { convertAlert, useWorkspaceContainer } from '@oms/frontend-foundation';
import type { FoundationWorkspace } from '@oms/frontend-foundation';
import type { FeedbackWrapper } from '@oms/frontend-foundation';
import type { CancelTradingOrdersInput } from '@oms/generated/frontend';
import { useCurrentWindow } from '@valstro/workspace-react';
import { useCallback } from 'react';
import type { DependencyContainer } from 'tsyringe';

/**
 * Open a Confirmation Dialog with the relevant errors to display, handle the "OK" button
 * click to send the CancelOrders mutation, and handle any failures by recursion.
 */

async function openConfirmationAndListen(
  orderIds: string[],
  errors: FeedbackWrapper[],
  tradingOrderService: TradingOrdersService,
  workspace: FoundationWorkspace,
  numRows: number,
  windowId: string
) {
  // Convert the FeedbackWrapper[] into AlertBannerStackItem[]
  const alerts = errors.map((item) =>
    convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
  );

  // Invoke the confirmation dialog with the relevant info.
  const [_, api] = await openConfirmation(workspace, windowId, {
    title: `Cancel ${numRows} trading order${numRows > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      alerts: alerts,
      message: `Are you sure you want to cancel ${numRows} trading order${numRows > 1 ? 's' : ''}?`,
      confirmButtonText: t('app.common.retry'),
      cancelButtonText: t('app.common.no')
    }
  });

  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      const input: CancelTradingOrdersInput = {
        ids: orderIds
      };

      const resp = await tradingOrderService.cancelTradingOrders(input);

      if (resp.isSuccess() === false) {
        // The mutation failed. Invoke another confirmation.
        const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];
        await openConfirmationAndListen(
          orderIds,
          feedbackWrappers,
          tradingOrderService,
          workspace,
          numRows,
          windowId
        );
      }

      break;
    }
  }
}

export interface IOpenCancelInvestorOrderDialogOptions {
  workspace: FoundationWorkspace;
  container: DependencyContainer;
  windowId: string;
  tradingOrderIds: string[];
}

export const openCancelTradingOrderDialog = async ({
  workspace,
  container,
  windowId,
  tradingOrderIds
}: IOpenCancelInvestorOrderDialogOptions) => {
  {
    const tradingOrderService = container.resolve(TradingOrdersService);

    const [_, api] = await openConfirmation(workspace, windowId, {
      title: `Cancel ${tradingOrderIds.length} trading order${tradingOrderIds.length > 1 ? 's' : ''}`,
      componentProps: {
        autoClose: true,
        message: `Are you sure you want to cancel ${tradingOrderIds.length} trading order${
          tradingOrderIds.length > 1 ? 's' : ''
        }?`,
        confirmButtonText: t('app.common.yes'),
        cancelButtonText: t('app.common.no')
      }
    });
    const event = await api.awaitFirstEvent;
    switch (event.type) {
      case DIALOG_EVENT_TYPE.OK: {
        // By the time the user clicks past the confirmation dialog, the count of selected rows
        // that are active may have changed.

        const input: CancelTradingOrdersInput = {
          ids: tradingOrderIds
        };

        const result = await tradingOrderService.cancelTradingOrders(input);

        if (result.isSuccess() === false) {
          const feedbackWrappers: FeedbackWrapper[] = result.errors as unknown as FeedbackWrapper[];

          await openConfirmationAndListen(
            tradingOrderIds,
            feedbackWrappers,
            tradingOrderService,
            workspace,
            tradingOrderIds.length,
            windowId
          );
        }

        break;
      }
    }
  }
};

export const useOpenCancelTradingOrderDialog = (id: string) => {
  const workspace = useAppWorkspace();
  const container = useWorkspaceContainer();
  const windowActor = useCurrentWindow();
  return useCallback(() => {
    openCancelTradingOrderDialog({
      workspace: workspace,
      container: container,
      windowId: windowActor.id,
      tradingOrderIds: [id]
    }).catch((e) => console.error(e));
  }, [workspace, id, container, windowActor.id]);
};
