import { OrderType } from '@oms/generated/frontend';
import type { Maybe } from '@oms/generated/frontend';
import { zOrderTitleDetailsSchema } from '../../zod/trading.zod.schemas';
import type { TradingOrderRow } from '@app/widgets/trading/trading-order-monitor-table-server/trading-order-monitor.contracts';

type OrderTitleDetails = {
  sideType: Maybe<string>;
  quantity: Maybe<number>;
  symbol: Maybe<string>;
  price: Maybe<number | string>;
  account?: Maybe<string>;
};

export const getOrderDialogTitle = (order: TradingOrderRow) => {
  const id = order.id;
  const titleDetails: OrderTitleDetails = {
    sideType: order?.side,
    symbol: order?.instrumentDisplayCode,
    quantity: order?.totalQuantity,
    price: order?.orderType === OrderType.Market ? OrderType.Market : order?.limitPrice
  };

  let title = 'Trading Order - ';

  const result = zOrderTitleDetailsSchema.safeParse(titleDetails);
  if (result.success) {
    const { sideType, quantity, symbol, price } = result.data;
    title += `${sideType} ${quantity} ${symbol} @ ${price}`;
  } else if (id) {
    title += `${id}`;
  } else {
    title += 'Unknown';
  }
  return title;
};
