import type { Sprinkles, BoxProps } from '@oms/shared-frontend/ui-design-system';
import { Grid, GridItem, IconButton } from '@oms/shared-frontend/ui-design-system';
import { DragHandleDots2Icon } from '@radix-ui/react-icons';
import type { FC, PropsWithChildren } from 'react';
import { DragItemTypes } from '../types';
import type { DragItemDropResult, DragItemMeta, DragNDrop, DropItemMeta } from '../types';
import { useDrag, useDrop } from 'react-dnd';

type DragStyle = { sx?: Sprinkles; style?: BoxProps['style'] };

export type DragItemProps = {
  container?: DragStyle;
  iconContainer?: DragStyle;
  contentContainer?: DragStyle;
} & DragNDrop;

export const DragItem: FC<PropsWithChildren<DragItemProps>> = ({
  children,
  container,
  iconContainer,
  contentContainer,
  type,
  onDrag,
  onDrop
}) => {
  const { style: containerStyle, sx: containerSx } = container || {};
  const { style: iconStyle, sx: iconSx } = iconContainer || {};
  const { style: contentStyle, sx: contentSx } = contentContainer || {};

  const [dropStyles, drop] = useDrop<DropItemMeta, DragItemDropResult, DropItemMeta>(
    () => ({
      accept: [DragItemTypes.Action, DragItemTypes.ActionGroup, DragItemTypes.Separator],
      drop(_item, monitor) {
        if (monitor.isOver()) {
          onDrop();
        }
        return {};
      },
      collect(monitor) {
        const overDrop = monitor.canDrop() && monitor.isOver();
        return {
          borderStyle: 'solid',
          border: overDrop ? undefined : 'none',
          borderColor: overDrop ? 'text.active' : 'inherit',
          borderWidth: overDrop ? '1px' : 'none'
        };
      }
    }),
    [onDrop]
  );

  const [{ opacity: dragOpacity }, drag, preview] = useDrag<
    DragItemProps,
    DragItemDropResult,
    DragItemMeta
  >(() => {
    return {
      type,
      collect(monitor) {
        if (monitor.isDragging()) {
          onDrag();
        }

        return {
          opacity: monitor.isDragging() ? 0.5 : 1
        };
      }
    };
  }, [type, onDrag]);

  return (
    <Grid
      ref={(i: HTMLDivElement) => preview(drop(i))}
      columns={24}
      gap={0}
      sx={{
        borderRadius: 'xs',
        margin: 2,
        ...dropStyles,
        opacity: dragOpacity,
        ...containerSx
      }}
      style={containerStyle}
    >
      <GridItem
        colSpan={1}
        sx={{ alignItems: 'center', justifyContent: 'center', ...iconSx }}
        style={iconStyle}
      >
        <IconButton
          as={'div'}
          aria-label="drag-action-button-group"
          ref={drag}
          onClick={(e) => e.preventDefault()}
          isRound={true}
          sx={{
            color: 'icons.semiMinor',
            backgroundColor: iconSx?.backgroundColor ? iconSx.backgroundColor : 'inherit'
          }}
          variant="ghost"
          size={'sm'}
          icon={<DragHandleDots2Icon />}
        />
      </GridItem>
      <GridItem colSpan={23} sx={{ borderRadius: 'sm', ...contentSx }} style={contentStyle}>
        {children}
      </GridItem>
    </Grid>
  );
};
