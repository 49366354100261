// !!! DO NOT MODIFY THIS FILE, UNLESS YOU KNOW WHAT YOU ARE DOING !!!

import {
  type Actor,
  type ActorSchema,
  COMMON_ACTOR_TYPE,
  type CommonWindowActorSchema,
  type CommonWindowActorSchemaOptions,
  WidgetOperations,
  type WindowOperations,
  Workspace
} from '@valstro/workspace';
import type { AnyRecord, FoundationWorkspaceMeta } from '@oms/frontend-foundation';
import type { OfflineDatabase } from '@app/data-access/offline/offline-database';
import type { RxdbReplicationService } from '@app/data-access/offline/rxdb-replication.service';
import type {
  COMMON_AUTH_WINDOW,
  CommonAuthWindowActorSchema,
  CommonAuthWindowActorSchemaOptions
} from '@app/common/auth/auth.contracts';
import type { GetVersionedSchemaDataType } from '@oms/frontend-schemas';
import { LATEST_VERSIONED_SCHEMA } from './schemas/snapshots';
import type { GetActorDefFromAppSnapshotDefinition } from './schemas/snapshots/type.helpers';
import {
  FLEX_LAYOUT_ACTOR_TYPE,
  type FlexLayoutOperations,
  type ReactFlexLayoutActorSchemaOptions
} from '@valstro/workspace-react';
import React from 'react';
import type { MemoryDatabase } from '@app/data-access/offline/memory-database';

// The workspace definition types.
// !!! DO NOT MODIFY THESE TYPES, UNLESS YOU KNOW WHAT YOU ARE DOING !!!

/**
 * Custom Workspace meta is a place to store specific data relevant to our application.
 */
export type WorkspaceMeta = {
  offlineDatabase: OfflineDatabase;
  memoryDatabase: MemoryDatabase;
  authActor: Actor<CommonAuthWindowActorSchema>;
  replication: RxdbReplicationService;
} & FoundationWorkspaceMeta;

export type AppWorkspace = Workspace<WorkspaceMeta>;

export type UnconstructedAppWorkspace = new (
  ...args: ConstructorParameters<typeof Workspace>
) => AppWorkspace;

export const AppWorkspace = Workspace as unknown as UnconstructedAppWorkspace;

// The application snapshot definition & actor definiton types.
// !!! DO NOT MODIFY THESE TYPES, UNLESS YOU KNOW WHAT YOU ARE DOING !!!

export type AppSnapshotDefinition = GetVersionedSchemaDataType<typeof LATEST_VERSIONED_SCHEMA>;

export type AppWindowActorDef = GetActorDefFromAppSnapshotDefinition<typeof COMMON_ACTOR_TYPE.WINDOW>;
export type AppWindowContext = AppWindowActorDef['context'];
export type AppWindowActorSchema = ActorSchema<
  AppWindowContext,
  WindowOperations,
  React.ComponentType,
  CommonWindowActorSchemaOptions<React.ComponentType>
>;
export function asAppWindowActor(actor: Actor<CommonWindowActorSchema>): Actor<AppWindowActorSchema> {
  return actor as Actor<AppWindowActorSchema>;
}

export type AppWidgetActorDef = GetActorDefFromAppSnapshotDefinition<typeof COMMON_ACTOR_TYPE.WIDGET>;
export type AppWidgetContext = AppWidgetActorDef['context'];
export type AppWidgetActorSchema = ActorSchema<
  AppWidgetContext,
  WidgetOperations,
  React.FC<{}>,
  CommonAuthWindowActorSchemaOptions
>;

export type AppFlexLayoutActorDef = GetActorDefFromAppSnapshotDefinition<typeof FLEX_LAYOUT_ACTOR_TYPE>;
export type AppFlexLayoutContext<TProps extends AnyRecord = AnyRecord> = Omit<
  AppFlexLayoutActorDef['context'],
  'componentProps'
> & {
  componentProps: TProps & AppFlexLayoutActorDef['context']['componentProps'];
};
export type AppFlexLayoutActorSchema<TProps extends AnyRecord = AnyRecord> = ActorSchema<
  AppFlexLayoutContext<TProps>,
  FlexLayoutOperations<TProps>,
  any,
  ReactFlexLayoutActorSchemaOptions
>;

export type AppAuthWindowActorDef = GetActorDefFromAppSnapshotDefinition<typeof COMMON_AUTH_WINDOW.TYPE>;
export type AppAuthWindowContext = AppAuthWindowActorDef['context'];
