import { DISPLAY_FIELD_COMPONENT_TYPE as UI_DISPLAY_FIELD_COMPONENT_TYPE } from '@oms/shared-frontend/ui-design-system';

export const FOUNDATION_DISPLAY_FIELD_COMPONENT_TYPE = {
  Popover: 'Popover'
} as const;

export const APP_DISPLAY_FIELD_COMPONENT_TYPE = {
  ...UI_DISPLAY_FIELD_COMPONENT_TYPE,
  ...FOUNDATION_DISPLAY_FIELD_COMPONENT_TYPE
} as const;
