import type { RxCollection, RxDocument } from 'rxdb-v15';
import type { MigrationCollectionName } from './migrations';
import { REPLICATION_MIGRATIONS } from './migrations';
import type {
  AllRxDBMigrationItems,
  CollectionWithSchemaPayload,
  RxDBMigrationItem
} from '@oms/frontend-schemas';
import type { ReplicableRxDocumentType } from '../types';
import { CompletedMigrationsDocType } from '../collections/completed-migrations.collection';
import { AppDatabase } from '../app-database';
import { MemoryDatabaseCollections } from '../collections';
import { UUID } from '@oms/shared/util';

export const getDocumentVersionNumber = (document: RxDocument & CollectionWithSchemaPayload) =>
  document.subSchemaVersion;

export const getCollectionVersionNumber = (collection: RxCollection) => collection.schema.jsonSchema.version;

export const getMigrationItems = (collection: RxCollection): AllRxDBMigrationItems => {
  if (!(collection.name in REPLICATION_MIGRATIONS)) {
    return [];
  }
  return REPLICATION_MIGRATIONS[collection.name as MigrationCollectionName];
};

export const MAX_MIGRATION_DEPTH = 100;
export const migrateDocument = (
  document: ReplicableRxDocumentType & CollectionWithSchemaPayload,
  collection: RxCollection,
  migrations: AllRxDBMigrationItems = getMigrationItems(collection)
) => {
  const collectionSchemaVersion = getCollectionVersionNumber(collection);
  let migratedDocument = structuredClone(document);
  let migration;
  let migrationDepth = 1;
  do {
    if (
      typeof collectionSchemaVersion === 'number' &&
      collectionSchemaVersion > 0 &&
      typeof getDocumentVersionNumber(migratedDocument) !== 'number'
    ) {
      throw new Error('Document does not have a version number');
    }
    migration = Object.values(migrations).find(
      (m: RxDBMigrationItem) => m.version === (getDocumentVersionNumber(migratedDocument) || 0) + 1
    );

    if (!migration?.migration) {
      break;
    }

    if (migrationDepth > MAX_MIGRATION_DEPTH) {
      throw new Error('Migration depth exceeded');
    }

    migratedDocument = migration.migration(migratedDocument, collection);
    migratedDocument.subSchemaVersion = migration.version;
    migrationDepth++;

    setTimeout(() => {
      void collection.database?.collections.completed_migrations
        ?.insert({
          id: UUID(),
          documentCollectionName: collection.name as keyof MemoryDatabaseCollections,
          documentPrimaryKey: String(
            migratedDocument[collection.schema.primaryPath as keyof typeof migratedDocument]
          )
        })
        .catch(console.error);
    }, 0);
  } while (migration);
  return migratedDocument;
};

export const migrationNeeded = (
  document: RxDocument & CollectionWithSchemaPayload,
  collection: RxCollection
): Boolean =>
  typeof getCollectionVersionNumber(collection) === 'number' &&
  typeof getDocumentVersionNumber(document) === 'number' &&
  getDocumentVersionNumber(document) !== getCollectionVersionNumber(collection);

export function migrateDocumentsWithOutdatedSchemaVersion(
  documents: ReplicableRxDocumentType[],
  collection: RxCollection,
  migrations?: AllRxDBMigrationItems
): ReplicableRxDocumentType[] {
  return documents.map((document) => {
    if (migrationNeeded(document, collection)) {
      return migrateDocument(document, collection, migrations);
    } else {
      return document;
    }
  });
}

export function excludeDocumentsWithInvalidSchemaVersion(
  documents: ReplicableRxDocumentType[],
  collection: RxCollection
): ReplicableRxDocumentType[] {
  return documents.filter((document) => {
    const documentVersionNumber = getDocumentVersionNumber(document);
    const collectionVersionNumber = getCollectionVersionNumber(collection);
    if (typeof collectionVersionNumber !== 'number') {
      return true; // no schema expected
    }
    if (collectionVersionNumber === 0 && documentVersionNumber === undefined) {
      return true; // no schema comparison required
    }
    return (
      typeof documentVersionNumber === 'number' &&
      documentVersionNumber <= getCollectionVersionNumber(collection) &&
      getCollectionVersionNumber(collection) - documentVersionNumber < MAX_MIGRATION_DEPTH
    );
  });
}

export const lastUpdatedAtHooks = (collection: RxCollection) => {
  collection.preInsert((data: { lastUpdatedAt: string }) => {
    data.lastUpdatedAt = new Date().toISOString();
    return data;
  }, true);
  collection.preSave((data: { lastUpdatedAt: string }) => {
    data.lastUpdatedAt = new Date().toISOString();
    return data;
  }, true);
  collection.preRemove((data: { lastUpdatedAt: string }) => {
    data.lastUpdatedAt = new Date().toISOString();
    return data;
  }, true);
};

export const pushCompletedMigrations = (db: AppDatabase) => {
  return db.memory.completed_migrations.$.subscribe(
    (changeEvent: { documentData: CompletedMigrationsDocType }) => {
      const data = changeEvent.documentData;
      const collection = db.memoryDb.collections[data.documentCollectionName];
      const doc = collection.findOne(data.documentPrimaryKey);
      doc.incrementalPatch({ lastUpdatedAt: new Date().toISOString() }).catch(console.error);
    }
  );
};
