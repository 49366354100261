export const DEFAULT_BASE_PIXELS = 12;
export const pixelsToRem = (pixels: number, base: number = DEFAULT_BASE_PIXELS): number => pixels / base;
export const remToPixels = (rem: number, base: number = DEFAULT_BASE_PIXELS): number => rem * base;

export enum UIUnit {
  Pixels = 'px',
  Rem = 'rem'
}

export const formatUnit = (amount: number, unit: UIUnit): string => `${amount}${unit}`;

/**
 * Utility for converting between UI units.
 * ```ts
 * convert.px.to.rem('48px', 12); // '4rem'
 * convert.rem.to.px('4rem', 12); // '48px'
 * ```
 */
export const convertPixels = {
  [UIUnit.Pixels]: {
    to: {
      [UIUnit.Rem]: (pixels: string, base: number = DEFAULT_BASE_PIXELS): string => {
        if (!pixels.match(/([0-9.]+)px/g)) return pixels;
        const pxAmount = Number.parseFloat(pixels.slice(0, -2));
        if (Number.isNaN(pxAmount)) return pixels;
        return `${pixelsToRem(pxAmount, base)}rem`;
      }
    }
  },
  [UIUnit.Rem]: {
    to: {
      [UIUnit.Pixels]: (rem: string, base: number = DEFAULT_BASE_PIXELS): string => {
        if (!rem.match(/([0-9.]+)rem/g)) return rem;
        const remAmount = Number.parseFloat(rem.slice(0, -3));
        if (Number.isNaN(remAmount)) return rem;
        return `${remToPixels(remAmount, base)}px`;
      }
    }
  },
  percent: {
    to: {
      /**
       * Converts a percentage to hexidecimal value.
       * @param percent - A percent represented as a number between 0 (0%) and 1 (100%). For example, 0.5 = 50%, etc.
       * @returns A string with the hexidecimal representation
       */
      hex: (percent: number): string => {
        const p = Math.max(0, Math.min(1, percent));
        const int = Math.round(p * 255);
        const hex = int.toString(16);
        return hex.padStart(2, '0').toUpperCase();
      }
    }
  }
};

export default convertPixels;
