import type { AnyRecord } from '../../common/type.helpers';
import type { FormBuilderContextBase, FormBuilderContext } from './form-builder.common.types';
import type { Prettify } from '@oms/shared/util-types';

export type FormBuilderInputSanitizerFn<
  TInputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord
> = (
  input: Prettify<Partial<TInputContract>>,
  ctx: FormBuilderContextBase
) => Promise<Partial<TFormFieldValues> | undefined> | Partial<TFormFieldValues> | undefined;

export type FormBuilderOutputSanitizerFn<
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord
> = (
  input: Prettify<Partial<TFormFieldValues>>,
  ctx: FormBuilderContext<TFormFieldValues>
) => Promise<TOutputContract | undefined> | TOutputContract | undefined;

export type FormBuilderSanitizerDefinition<
  TInputContract extends AnyRecord,
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord
> = {
  input: FormBuilderInputSanitizerFn<TInputContract, TFormFieldValues>;
  output: FormBuilderOutputSanitizerFn<TOutputContract, TFormFieldValues>;
};

/**
 * Form Sanitizer Builder
 * Note: This is used internally in the form builder to create a sanitizer for inputs & outputs to/from the form.
 *
 * @see FormBuilder
 */
export class FormBuilderSanitizer<
  TInputContract extends AnyRecord,
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord
> {
  private _input: FormBuilderInputSanitizerFn<TInputContract, TFormFieldValues> | undefined;
  private _output: FormBuilderOutputSanitizerFn<TOutputContract, TFormFieldValues> | undefined;

  input(fn: FormBuilderInputSanitizerFn<TInputContract, TFormFieldValues>) {
    this._input = fn;
    return this;
  }

  output(fn: FormBuilderOutputSanitizerFn<TOutputContract, TFormFieldValues>) {
    this._output = fn;
    return this;
  }

  build(): FormBuilderSanitizerDefinition<TInputContract, TOutputContract, TFormFieldValues> {
    if (!this._input) {
      throw new Error('Missing input sanitizer');
    }

    if (!this._output) {
      throw new Error('Missing output sanitizer');
    }

    return {
      input: this._input,
      output: this._output
    };
  }
}
