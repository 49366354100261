import { SimplePosition, SimpleSize, WindowResizeDir, WindowResizeOrigin } from './actor.contracts.common';

export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
  let timeout: NodeJS.Timeout | undefined;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};

export function throttle<T extends (...args: any[]) => void>(
  this: unknown,
  func: T,
  limit: number
): (...funcArgs: Parameters<T>) => void {
  let inThrottle: boolean;
  let lastFunc: ReturnType<typeof setTimeout>;
  let lastRan: number;

  return function (this: unknown, ...args: Parameters<T>) {
    if (!inThrottle) {
      func.apply(this, args);
      lastRan = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(
        () => {
          if (Date.now() - lastRan >= limit) {
            func.apply(this, args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan)
      );
    }
  };
}

export function isUndefined<T>(value: T | undefined): value is undefined {
  return typeof value === 'undefined';
}

export function isDefined<T>(value: T | undefined): value is T {
  return !isUndefined(value);
}

export function ensureInt(value: number, floor = false) {
  return floor ? Math.floor(value) : Math.ceil(value);
}

export function resizeAroundOrigin(
  currentPos: SimplePosition,
  currentSize: SimpleSize,
  newSize: SimpleSize,
  origin: WindowResizeOrigin,
  direction: WindowResizeDir = 'both'
): Partial<SimplePosition & SimpleSize> {
  // Calculate the change in size
  const deltaWidth = newSize.width - currentSize.width;
  const deltaHeight = newSize.height - currentSize.height;

  // Initialize new position
  const newPos: SimplePosition = { ...currentPos };

  // Adjust position based on the origin and direction
  switch (origin) {
    case 'top-left':
      // No position change needed for top-left origin
      break;
    case 'top-center':
      if (direction === 'horizontal' || direction === 'both') {
        newPos.x -= deltaWidth / 2;
      }
      break;
    case 'center':
      if (direction === 'horizontal' || direction === 'both') {
        newPos.x -= deltaWidth / 2;
      }
      if (direction === 'vertical' || direction === 'both') {
        newPos.y -= deltaHeight / 2;
      }
      break;
    case 'bottom-center':
      if (direction === 'vertical' || direction === 'both') {
        newPos.y -= deltaHeight;
      }
      if (direction === 'horizontal' || direction === 'both') {
        newPos.x -= deltaWidth / 2;
      }
      break;
    case 'bottom-left':
      if (direction === 'vertical' || direction === 'both') {
        newPos.y -= deltaHeight;
      }
      break;
    case 'top-right':
      if (direction === 'horizontal' || direction === 'both') {
        newPos.x -= deltaWidth;
      }
      if (direction === 'vertical' || direction === 'both') {
        newPos.y -= deltaHeight;
      }
      break;
  }

  const bounds: Partial<SimplePosition & SimpleSize> = {
    ...newSize
  };

  // Set the new position if it has changed
  if (newPos.x !== currentPos.x || newPos.y !== currentPos.y) {
    bounds.x = newPos.x;
    bounds.y = newPos.y;
  }

  return bounds;
}
