import { AppWorkspace } from '@app/app-config/workspace.config';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import { inject, singleton } from 'tsyringe';
import type { InstrumentFollowEvent } from './instrument-follow.contracts';
import { testScoped } from '@app/workspace.registry';

const INSTRUMENT_FOLLOW_CHANNEL_NAME = 'instrument-follow';

/**
 * Used to broadcast the current selected instrument and to listen for changes.
 *
 * @usage
 * ```ts
 * const trackingSubject$ = container.resolve(InstrumentFollowSubject);
 * const subscription = trackingSubject$.subscribe(({ instrumentId }) => {
 *  console.log('Instrument ID selected:', instrumentId);
 * });
 * ```
 */
@testScoped
@singleton()
export class InstrumentFollowSubject extends BroadcastSubject<InstrumentFollowEvent> {
  public readonly channelName: string;

  constructor(
    @inject(AppWorkspace) workspace: AppWorkspace,
    channel = `${workspace.getLeaderProcessId()}-${INSTRUMENT_FOLLOW_CHANNEL_NAME}`
  ) {
    super(channel);
    this.channelName = channel;
  }
}
