import type { VersionedSchemaExport } from './schemas.common';
import type { CollectionName } from '../collections/collections.contracts';

export const APP_SUB_SCHEMA = {
  BUMP_PRICE: 'BUMP_PRICE',
  CREATE_UNBOUND_TO: 'CREATE_UNBOUND_TO',
  SNAPSHOT: 'SNAPSHOT',
  FLEX_LAYOUT: 'FLEX_LAYOUT',
  // Snapshot-based sub-schemas
  ROUTE_ORDER_FORM_VALUES: 'ROUTE_ORDER_FORM_VALUES',
  ROUTE_ORDER_FORM_INPUT: 'ROUTE_ORDER_FORM_INPUT',
  MONTAGE: 'MONTAGE',
  SYSTEM_REMOTE_FORM: 'SYSTEM_REMOTE_FORM'
} as const;

export type AppSubSchemaType = (typeof APP_SUB_SCHEMA)[keyof typeof APP_SUB_SCHEMA];

export type SubSchemaItem = {
  collection?: CollectionName;
  dependencies?: AppSubSchemaType[];
  subSchemaFolderPath: string;
  versionedSchemas: VersionedSchemaExport<any>[];
  type: 'snapshot-component' | 'snapshot-form-input' | 'snapshot-form-values' | 'action' | 'other';
};

export type AppSubSchemaRegistery = Record<AppSubSchemaType, SubSchemaItem>;
