import { FORM_SAVE_TYPE, FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';
import { CurrenciesService } from '@app/data-access/services/reference-data/currencies/currencies.service';
import type { CurrencyFormInput, CurrencyFormOutput } from './currency.form-common';
import { currencyFormContract } from './currency.form-contract';
import type { CurrencyFormContractType, CurrencyFormValues } from './currency.form-contract';

export const currencyFormBuilder = FormBuilder.create<CurrencyFormInput, CurrencyFormOutput>('currency-form')
  .contract<CurrencyFormContractType>(currencyFormContract)
  .type('currency')
  .sanitizer((s) =>
    s
      .input(async function sanitize(input, ctx) {
        if (!input.id) return;
        const service = ctx.container.resolve(CurrenciesService);
        const currency = await service.getById(input.id);
        if (!currency) return;

        const formValues: CurrencyFormValues = {
          id: currency.id,
          longName: currency.longName ?? ''
        };

        return formValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.id) return;
        if (!formValues.longName) return;
        const output: CurrencyFormOutput = {
          id: formValues.id,
          longName: formValues.longName
        };
        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.MOUNT:
        break;
      case FORM_EVENT_TYPE.UNMOUNT:
        break;
      case FORM_EVENT_TYPE.VALUES_CHANGED: {
        break;
      }
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(CurrenciesService);
        const formOutput = event.payload.output;
        const formSaveType = event.meta.formSaveType;
        const method = formSaveType === FORM_SAVE_TYPE.CREATE ? 'create' : 'update';

        // Can return a GQLResult directly...
        // and success / errors will be handled for you.
        return await service[method](formOutput);

        // Or you can handle success & errors manually.
        // Here's an example of sending feedback back to the form...
        // ctx.notify({
        //   type: FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK,
        //   payload: { feedback: [] }
        // });

        // you can also default fields
        // ctx.notify({
        //   type: FORM_RENDERER_EVENT_TYPE.SET_FIELD_VALUES,
        //   payload: { fieldValues: { id: '123' } }
        // });
      }
      case FORM_EVENT_TYPE.SUBMIT_FINISHED_SUCCESS:
        break;
      case FORM_EVENT_TYPE.SUBMIT_FINISHED_ERRORS:
        break;
    }
  });

export type CurrencyFormBuilderType = typeof currencyFormBuilder;

export default currencyFormBuilder;
