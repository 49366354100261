import type { LoggerOptions } from './logger.types';

/** @private Private to use within `Logger` class only */
export class LoggerState {
  public disabled?: boolean;

  public label?: string;
  public scope?: string[];

  public suppressConsoleLogs?: boolean;
  public suppressErrorLogs?: boolean;
  public suppressLogLevelPrefix?: boolean;
  public suppressTimestamp?: boolean;

  protected initial: LoggerOptions;

  // ------------------------------------ /

  protected constructor(options?: LoggerOptions) {
    this.initial = options ?? {};
    this.init();
  }

  public static init(options?: LoggerOptions): LoggerState {
    return new LoggerState(options);
  }

  // ------------------------------------ /

  public resetDisabled(): LoggerState {
    delete this.disabled;
    return this;
  }

  public resetLabel(): LoggerState {
    delete this.label;
    if (this.initial.label) this.label = this.initial.label;
    return this;
  }

  public resetScope(): LoggerState {
    delete this.scope;
    return this;
  }

  public resetSuppressConsoleLogs(): LoggerState {
    delete this.suppressConsoleLogs;
    if (typeof this.initial.suppressConsoleLogs === 'boolean')
      this.suppressConsoleLogs = this.initial.suppressConsoleLogs;
    return this;
  }

  public resetSuppressErrorLogs(): LoggerState {
    delete this.suppressErrorLogs;
    if (typeof this.initial.suppressErrorLogs === 'boolean')
      this.suppressErrorLogs = this.initial.suppressErrorLogs;
    return this;
  }

  public resetSuppressLogLevelPrefix(): LoggerState {
    delete this.suppressLogLevelPrefix;
    if (typeof this.initial.suppressLogLevelPrefix === 'boolean')
      this.suppressLogLevelPrefix = this.initial.suppressLogLevelPrefix;
    return this;
  }

  public resetSuppressTimestamp(): LoggerState {
    delete this.suppressTimestamp;
    if (typeof this.initial.suppressTimestamp === 'boolean')
      this.suppressTimestamp = this.initial.suppressTimestamp;
    return this;
  }

  public resetAll(): LoggerState {
    this.resetDisabled();
    this.resetLabel();
    this.resetScope();
    this.resetSuppressConsoleLogs();
    this.resetSuppressErrorLogs();
    this.resetSuppressLogLevelPrefix();
    this.resetSuppressTimestamp();
    return this;
  }

  // ------------------------------------ /

  protected init(): LoggerState {
    if (this.initial.label) this.label = this.initial.label;
    if (typeof this.initial.suppressConsoleLogs === 'boolean')
      this.suppressConsoleLogs = this.initial.suppressConsoleLogs;
    if (typeof this.initial.suppressErrorLogs === 'boolean')
      this.suppressErrorLogs = this.initial.suppressErrorLogs;
    if (typeof this.initial.suppressLogLevelPrefix === 'boolean')
      this.suppressLogLevelPrefix = this.initial.suppressLogLevelPrefix;
    if (typeof this.initial.suppressTimestamp === 'boolean')
      this.suppressTimestamp = this.initial.suppressTimestamp;
    return this;
  }
}

export default LoggerState;
