import { memo, useMemo } from 'react';
import { Actor, AnyActorSchema } from '@valstro/workspace';
import { useWorkspace } from './react-workspace.context';
import { useActorQuery } from './react-use-actor';
import { ReactCurrentActorContext, useCurrentActorHierachy } from './react-actor.context';
import { ReactActorComponentProps } from './react-actor-component';

export interface ReactActorComponentSelectorProps<T extends AnyActorSchema = AnyActorSchema> {
  actorId: string;
  actor?: Actor<T>;
  leaderActor?: Actor;
  UnknownActorComponent?: ReactActorComponentSelectorType;
  LoadingActorComponent?: ReactActorComponentSelectorType;
}

export type ReactActorComponentSelectorType<T extends AnyActorSchema = AnyActorSchema> = React.ComponentType<
  ReactActorComponentSelectorProps<T>
>;

export const DefaultUnknownActorView: React.FC<ReactActorComponentSelectorProps> = () => (
  <div>Unknown Actor Component</div>
);

export const DefaultLoadingActorView: React.FC<ReactActorComponentSelectorProps> = () => (
  <div className="actor actor--starting"></div>
);

export const ReactActorComponentSelector: React.FC<ReactActorComponentSelectorProps> = memo(
  ({
    actorId,
    actor,
    UnknownActorComponent = DefaultUnknownActorView,
    LoadingActorComponent = DefaultLoadingActorView
  }) => {
    const workspace = useWorkspace();
    const { data, isFetching, error } = useActorQuery(actor ?? actorId);
    const hierarchy = useCurrentActorHierachy();

    const Component: React.ComponentType<ReactActorComponentProps> = useMemo(() => {
      if (!data?.type || !data.initialDefinition) {
        return null;
      }
      const View = workspace.getActorRegistry()?.getViewByName(data.name);

      if (!View) {
        console.warn(actorId, 'View not found');
      }

      return View ?? UnknownActorComponent;
    }, [data, UnknownActorComponent, workspace, actorId]);

    if (isFetching) {
      return <LoadingActorComponent actorId={actorId} actor={actor} />;
    }

    if (error) {
      throw error;
    }

    if (data && Component) {
      return (
        <ReactCurrentActorContext.Provider
          value={{
            actor: data,
            hierarchy: [data, ...(hierarchy || [])],
            leaderActor: workspace.getLeaderActor()
          }}
        >
          <Component actor={data} />
        </ReactCurrentActorContext.Provider>
      );
    }

    return <UnknownActorComponent actorId={actorId} actor={actor} />;
  },
  (prev, next) => {
    return prev.actorId === next.actorId;
  }
);
