import { Actor, COMMON_ACTOR_TYPE, CommonWindowActorSchema, WidgetActorSchema } from '@valstro/workspace';
import { useClosestActorByType, useWorkspace } from '../../../core';

export function useLeaderWindow() {
  const workspace = useWorkspace();
  return workspace.getLeaderActor() as Actor<CommonWindowActorSchema>;
}

export function useCurrentWindow<T extends CommonWindowActorSchema>(
  otherTypes: Array<string> = []
): Actor<T> {
  const actor = useClosestActorByType<T>([COMMON_ACTOR_TYPE.WINDOW, ...otherTypes]);
  if (!actor) {
    throw new Error('No window found');
  }
  return actor;
}

export function useCurrentWidget(): Actor<WidgetActorSchema> {
  const actor = useClosestActorByType<WidgetActorSchema>(COMMON_ACTOR_TYPE.WIDGET);
  if (!actor) {
    throw new Error('No widget found');
  }
  return actor;
}
