import React from 'react';
import { Text, type TextProps } from '@oms/shared-frontend/ui-design-system';
import type { FieldProps, ICommonField } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';

export interface IPlainTextFieldUnique {
  textType?: TextProps['type'];
}

export interface IPlainTextField
  extends IPlainTextFieldUnique,
    ICommonField<typeof FORM_COMPONENT_TYPE.PLAIN_TEXT> {}

export const PlainText: React.FC<FieldProps<IPlainTextField>> = (props) => {
  const { label, textType, as, style, sx } = useFieldApi<IPlainTextField>(props);

  return (
    <Text as={as} type={textType} style={style} sx={sx || {}}>
      {label}
    </Text>
  );
};
