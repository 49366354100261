import clsx from 'clsx';
import * as styles from './css/select.css';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { useFormControl } from '../form-control/form-control';
import { Input, type InputProps } from '../input/input';

// readonly is not supported or relevant to <select>. Omit it.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/readonly
type OmitProps = 'type' | 'isReadOnly';

export type SelectProps = {} & Omit<InputProps, OmitProps>;

export const Select = polymorphicComponent<'select', SelectProps>((props, ref) => {
  const formControl = useFormControl(props);
  const {
    className,
    placeholder,
    children,
    as = 'select',
    variant = formControl.variant || 'default',
    ...rest
  } = props;

  return (
    //TODO: fix the types
    //@ts-ignore
    <Input as={as} ref={ref} variant={variant} className={clsx(styles.select, className)} {...rest}>
      {children}
    </Input>
  );
});

if (__DEV__) {
  Select.displayName = 'Select';
}
