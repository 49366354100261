import type { Maybe } from '@oms/generated/frontend';
import type { AnyUserInput, BaseUserObject } from '../types/users/types';

type UserLabelKeys = 'firstLast' | keyof Pick<BaseUserObject, 'username' | 'email'>;

export interface UserDisplayOptions {
  fallbackLabel?: string;
  preferenceOrder?: UserLabelKeys[];
}

/**
 * @param [user] - An object with user information
 * @param [options] - Options object
 * @returns A string label for the user
 */
export const getLabelForUser = (user?: Maybe<AnyUserInput>, options?: UserDisplayOptions): string => {
  const { fallbackLabel = '', preferenceOrder = ['firstLast', 'username', 'email'] } = options ?? {};
  if (!user) return fallbackLabel;
  const { username, firstName, lastName, email } = user;
  for (const labelKey of preferenceOrder) {
    switch (labelKey as UserLabelKeys) {
      case 'firstLast':
        if (firstName && lastName) {
          return [firstName, lastName].join(' ');
        }
        break;
      case 'username':
        if (username) return username;
        break;
      case 'email':
        if (email) return email;
        break;
    }
  }
  return fallbackLabel;
};

export default getLabelForUser;
