import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import type { FC } from 'react';
import {
  Checkbox as CheckboxComp,
  FormControl,
  FormErrorMessage
} from '@oms/shared-frontend/ui-design-system';
import type { CheckboxProps } from '../../fixatdl-form.types';
import { useFieldApi, useEnhancedFormApi } from '@oms/frontend-foundation';
import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import type { fixatdlFormComponentTypes } from '../fixatdl-component.mappers';

const getCheckboxState = (value: boolean | string | undefined, checkedEnumRef: string) => {
  return typeof value === 'boolean' ? value : value === checkedEnumRef ? true : false;
};

export const Checkbox: FC<FieldProps<CheckboxProps>> = React.memo(
  ({ checkedEnumRef, uncheckedEnumRef, ...rest }) => {
    const {
      meta,
      label,
      input,
      isReadOnly,
      isRequired,
      isDisabled: _isDisabled,
      forceIsDisabled,
      isInvalid
    } = useFieldApi<ICommonField<fixatdlFormComponentTypes.CHECKBOX, boolean>>(rest);

    const onChangeRef = useRef(input.onChange);
    onChangeRef.current = input.onChange;
    const formApi = useEnhancedFormApi();
    const initialValue = getCheckboxState(rest.initialValue, checkedEnumRef);
    const [checked, setChecked] = useState(initialValue);

    useEffect(() => {
      // as value comes as undefined, we need to get it from the form state
      const sub = formApi.get$({ values: true, fields: [input.name] }).subscribe(({ values }) => {
        setChecked(getCheckboxState(values[input.name], checkedEnumRef));
      });

      return () => {
        sub.unsubscribe();
      };
    }, [checkedEnumRef, formApi, input.name, rest.initialValue]);

    useEffect(() => {
      onChangeRef.current(initialValue ? checkedEnumRef : uncheckedEnumRef);
    }, [checkedEnumRef, initialValue, rest.name, uncheckedEnumRef]);

    const handleChange = useCallback(() => {
      const isChecked = !checked;
      onChangeRef.current(isChecked ? checkedEnumRef : uncheckedEnumRef);
    }, [checked, checkedEnumRef, uncheckedEnumRef]);

    const isDisabled = forceIsDisabled || _isDisabled;
    const error = meta.touched && meta.error ? meta.error : '';
    const extraProps = { isReadOnly, isRequired, isDisabled, isInvalid };

    return (
      <FormControl {...extraProps}>
        <CheckboxComp
          {...input}
          sx={{ paddingRight: 2, marginTop: 2 }}
          onChange={handleChange}
          checked={checked}
        >
          {label}
        </CheckboxComp>
        <FormErrorMessage>{error}&nbsp;</FormErrorMessage>
      </FormControl>
    );
  },
  isEqual
);
