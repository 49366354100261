import { TextField } from '@oms/frontend-foundation';
import type { FieldProps } from '@oms/frontend-foundation';
import { isEqual } from 'lodash';
import React from 'react';
import type { FC } from 'react';
import type { SingleSpinnerProps } from '../../fixatdl-form.types';

export const SingleSpinner: FC<FieldProps<SingleSpinnerProps>> = React.memo(({ increment, ...props }) => {
  return <TextField {...props} type="number" step={increment} component="text-field" />;
}, isEqual);
