import { ColGroupDef, ColDef } from '@ag-grid-community/core';
import { Level1MarketDataGroupBuilder } from './level1.marketdata.group.builder';
import { AnyRecord } from '@oms/frontend-foundation';

export interface MarketDataConfig<TData extends AnyRecord> {
  level1?: (ColGroupDef<TData> | ColDef<TData>)[];
}

export class MarketDataBuilder<TData extends AnyRecord> {
  private _level1GroupBuilder = new Level1MarketDataGroupBuilder<TData>()
    .id('level1-market-data')
    .header('Market Data')
    .locked()
    .open();

  public level1(
    cb: (builder: Level1MarketDataGroupBuilder<TData>) => Level1MarketDataGroupBuilder<TData>
  ): MarketDataBuilder<TData> {
    this._level1GroupBuilder = cb(this._level1GroupBuilder);
    return this;
  }

  public build(): MarketDataConfig<TData> {
    const l1Def = this._level1GroupBuilder.build();
    return { level1: l1Def.grouped ? [l1Def] : [...l1Def.children] };
  }
}
