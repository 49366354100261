import React from 'react';
import { useFieldApi, type UseFieldApiConfig, type Validator } from '@data-driven-forms/react-form-renderer';
import {
  CheckboxButton as CheckboxButtonComponent,
  type CheckboxButtonProps,
  type UniqueCheckboxButtonProps,
  FormControl,
  FormErrorMessage
} from '@oms/shared-frontend/ui-design-system';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import type { ICommonTextField } from '../../types';

type ICheckboxButtonFieldKeys = keyof UniqueCheckboxButtonProps;

export interface ICheckboxButtonField<TValidator = Validator>
  extends Omit<
      ICommonTextField<typeof FORM_COMPONENT_TYPE.CHECKBOX_BUTTON, boolean, TValidator>,
      ICheckboxButtonFieldKeys
    >,
    Omit<UniqueCheckboxButtonProps, 'label' | 'name'> {
  key?: string;
  name: string;
  checkboxLabel: string | string[];
  FieldProps?: CheckboxButtonProps; // TODO: CheckboxButtonProps being added twice. need to fix
}

// TODO: Fix this mess
export type CheckboxButtonEnhancedProps = CheckboxButtonProps &
  UseFieldApiConfig & {
    input: any;
    isDisabled?: boolean;
    label: string;
    isRequired?: boolean;
    providerRequired?: React.ReactNode;
    disabled: boolean;
    checkboxLabel: string | string[];
  };

/**
 * Model-driven checkbox-button field
 */
export const CheckboxButton: React.FC<CheckboxButtonEnhancedProps> = React.memo((props) => {
  const {
    meta,
    input,
    isReadOnly,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isInvalid,
    label,
    checkboxLabel,
    requiredFieldIndicatorStyle
  } = useFieldApi(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const error = meta.touched && meta.error ? meta.error : '';

  const extraProps = { isReadOnly, isRequired, isDisabled, isInvalid, requiredFieldIndicatorStyle };

  const { value } = input;

  const componentProps = {
    label: checkboxLabel ?? label,
    ...(props.FieldProps || {}),
    ...input,
    isChecked: typeof value === 'boolean' ? value : undefined
  };

  return (
    <FormControl {...extraProps}>
      <CheckboxButtonComponent {...componentProps} sx={{ paddingRight: 2 }} />
      <FormErrorMessage>{error}&nbsp;</FormErrorMessage>
    </FormControl>
  );
});
