import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { AtdlFormats, InitValueMode } from '@valstro/fixatdl-core';
import { DatePicker, useFieldApi } from '@oms/frontend-foundation';
import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import type { DatetimePickerFieldProps } from '../../fixatdl-form.types.js';
import { getFutureOrCurrentDate } from '../utils.ts/fixatdl-date.util.js';

export const DatetimePickerField: FC<FieldProps<DatetimePickerFieldProps>> = React.memo(
  ({ initialValue, initValueMode = InitValueMode.INIT, ...props }) => {
    const {
      input: { onChange, value: _value }
    } = useFieldApi<ICommonField<string>>(props);

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    const [initialized, setInitialized] = useState(false);

    let value = _value || initialValue;

    if (!AtdlFormats.Dates.isDateTime(value)) {
      value = null;
    }

    useEffect(() => {
      if (value && !initialized) {
        const dateTime = getFutureOrCurrentDate(value, initValueMode);
        onChangeRef.current(dateTime.toISOString());
        setInitialized(true);
      }
    }, [initValueMode, initialValue, initialized, value]);

    const handleChange = useCallback((value: Date | null) => {
      onChangeRef.current(value?.toISOString());
    }, []);

    return (
      <div style={{ minWidth: '180px', maxWidth: '180px', marginRight: '10px' }}>
        <DatePicker
          {...props}
          initialValue={value ? getFutureOrCurrentDate(value, initValueMode).toISOString() : undefined}
          timePicker="selectOnly"
          valueChange={handleChange}
          component="date-picker"
        />
      </div>
    );
  },
  isEqual
);
