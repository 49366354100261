import { FormContract, FieldDefinition } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import { idField, longNameField } from './currency.form-fields';
import type { CurrencyFormOutput } from './currency.form-common';

export const currencyFormContract = FormContract.create<CurrencyFormOutput>()
  .fields({
    id: idField,
    longName: longNameField
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box('wrapper', [f.id, f.longName], {
        sx: {}
      })
    ]
  }))
  .template('simple', { submitLabel: 'Save', formFieldsSx: { px: 4, pb: 2, marginTop: 4 } });

export type CurrencyFormContractType = typeof currencyFormContract;

export type CurrencyFormValues = InferFormValuesFromFormContract<CurrencyFormContractType>;
