import type {
  AdvancedSelectQueryFn,
  AdvancedSelectQueryPayload,
  ComboBoxItem
} from '@oms/frontend-foundation';
import { Observable } from 'rxjs';

/**
 * Instruments Query
 */
export interface ExampleInstrumentsEntity {
  id: string;
  name: string;
}

export const exampleInstrumentsWatchAllQuery: AdvancedSelectQueryFn = () => {
  return {
    type: 'watchAll',
    query: () => {
      return new Observable<AdvancedSelectQueryPayload<ExampleInstrumentsEntity>>((observer) => {
        const items = [
          {
            id: '1',
            name: 'TSLA'
          },
          {
            id: '2',
            name: 'TATA'
          },
          {
            id: '3',
            name: 'TITAN'
          },
          {
            id: '4',
            name: 'AAPL'
          },
          {
            id: '5',
            name: 'GOOG'
          },
          {
            id: '6',
            name: 'MSFT'
          }
        ].map((r) => {
          const item: ComboBoxItem = {
            type: 'item',
            id: r.id,
            label: r.name,
            value: r.id
          };
          return item;
        });

        observer.next({
          results: items,
          isFetching: false
        });
      });
    }
  };
};

export default exampleInstrumentsWatchAllQuery;
