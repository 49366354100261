import { FieldContract } from '@oms/frontend-foundation';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { ExchangeFormOutput } from './exchange.form-common';
import { makeValueLabelPairs } from '@oms/shared/util';
import type { Exchange } from '@oms/generated/frontend';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';

/**
 * Mapping for Exchange Types.
 */
export const exchangeTypeMapping: Record<string, string> = {
  ATS: 'ATS',
  ATSD: 'ATSD',
  BRO: 'BRO',
  COMP: 'COMP',
  CONT: 'CONT',
  EXCH: 'EXCH',
  MIX: 'MIX',
  OTC: 'OTC',
  SI: 'SI',
  UNLISTED: 'Unlisted'
};

/**
 * Mapping for MIC Levels.
 */
export const micLevelMapping: Record<string, string> = {
  MIC_OP_EXCHANGE_CODE: 'Operating',
  MIC_NONOP_EXCHANGE_CODE: 'Non-operating'
};

const ofc = FieldContract.create<ExchangeFormOutput, AdditionalFormFieldUnion>();

const required = { type: 'required', message: 'This field is required' };

const exchangeTypeOptions = makeValueLabelPairs(Object.keys(exchangeTypeMapping), exchangeTypeMapping);
const micLevelOptions = makeValueLabelPairs(Object.keys(micLevelMapping), micLevelMapping);

// For the Form Values, Advanced Select and Time Picker fields require to/from sanitizer handling.
export type ExchangeFormValues = Omit<
  Exchange,
  'countryId' | 'micCode' | 'startTime' | 'endTime' | 'partialStartTime' | 'partialEndTime' | 'timezone'
> & {
  countryId?: { id: string };
  micCode?: { id: string };
  startTime?: string;
  endTime?: string;
  partialStartTime?: string;
  partialEndTime?: string;
  timezone?: { id: string };
};

export const idField = ofc.field('id', 'text-field').options({
  label: 'Code',
  isRequired: true,
  validate: [required, { type: 'maxLength', length: 64 }]
});

export const longNameField = ofc.field('longName', 'text-field').options({
  label: 'Name',
  isRequired: true,
  validate: [required]
});

export const exchangeTypeField = ofc.field('exchangeType', 'select').options({
  label: 'type',
  options: exchangeTypeOptions
});

export const countryIdField = ofc.field('countryId', 'advanced-select').advancedSelectOptions({
  label: 'Country',
  query: ADVANCED_SELECT_QUERY_ENUM.EXCHANGE_COUNTRY_WATCH_QUERY
});

export const micField = ofc.field('micCode', 'advanced-select').advancedSelectOptions({
  label: 'MIC Code',
  query: ADVANCED_SELECT_QUERY_ENUM.EXCHANGE_MIC_WATCH_QUERY
});

export const micLevelField = ofc.field('micLevel', 'select').options({
  label: 'MIC Operating Level',
  options: micLevelOptions
});

export const startTimeField = ofc.field('startTime', 'time-picker').options({
  label: 'Start Time'
});

export const endTimeField = ofc.field('endTime', 'time-picker').options({
  label: 'End Time'
});

export const partialStartTimeField = ofc.field('partialStartTime', 'time-picker').options({
  label: 'Partial Start Time'
});

export const partialEndTimeField = ofc.field('partialEndTime', 'time-picker').options({
  label: 'Partial End Time'
});

export const timezoneField = ofc.field('timezone', 'advanced-select').advancedSelectOptions({
  label: 'Time Zone',
  query: ADVANCED_SELECT_QUERY_ENUM.EXCHANGE_TIMEZONE_WATCH_QUERY
});

export const expiryOffsetField = ofc.field('expiryOffset', 'number-format').options({
  label: 'Expiry Offset (minutes)',
  initialValue: 55,
  selectAllOnFocus: true,
  decimalScale: 0,
  isRequired: true,
  validate: [required]
});

export const extendedExpiryOffsetField = ofc.field('extendedExpiryOffset', 'number-format').options({
  label: 'Extended Expiry Offset (minutes)',
  initialValue: 55,
  selectAllOnFocus: true,
  decimalScale: 0,
  isRequired: true,
  validate: [required]
});
