import type { Interval } from '@oms/generated/frontend';

// Convert string values (as returned from the time-picker component) into Interval values.
// e.g. from "Tue Sep 19 2023 11:22:33 GMT-0400 (Eastern Daylight Time)" to { hours: 11, minutes: 22, seconds: 33 }.
export const getIntervalFromDateString = (dateString: string): Interval | null | undefined => {
  const date = new Date(Date.parse(dateString));
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds()
  };
};

// Convert Interval values into string values (as expected by the time-picker component).
export const getDateStringFromInterval = (interval: Interval | null | undefined): string => {
  const ret = new Date();
  if (!interval) {
    interval = {};
  }
  ret.setHours(interval.hours ?? 0);
  ret.setMinutes(interval.minutes ?? 0);
  ret.setSeconds(interval.seconds ?? 0);
  return ret.toString();
};
