import type { AnyFormBuilder, InferInfoFromFormBuilder as InferBuilder } from '../../form-builder.class';
import { FormRenderer, type FormRendererProps } from './form-renderer';

export type FormBuilderRendererProps<T extends AnyFormBuilder> = FormRendererProps<
  InferBuilder<T>['inputContract'],
  InferBuilder<T>['outputContract'],
  InferBuilder<T>['formContract'],
  InferBuilder<T>['fieldValues']
>;

export function FormBuilderRenderer<T extends AnyFormBuilder>(props: FormBuilderRendererProps<T>) {
  return (
    <FormRenderer<
      InferBuilder<T>['inputContract'],
      InferBuilder<T>['outputContract'],
      InferBuilder<T>['formContract'],
      InferBuilder<T>['fieldValues']
    >
      {...props}
    />
  );
}
