import { ErrorTypeEnum, ICustomGraphQLError } from './errors.types';
import { GraphQLError } from 'graphql';
export class CustomGraphQLError<TErrorType extends string, TData = any, TException extends Error = Error>
  extends GraphQLError
  implements ICustomGraphQLError<TErrorType, TData, TException>
{
  extensions = {
    type: ErrorTypeEnum.GENERAL_ERROR,
    code: ErrorTypeEnum.GENERAL_ERROR,
    data: null as TData
  } as ICustomGraphQLError<TErrorType, TData, TException>['extensions'];
}
