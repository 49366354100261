import type { IoRepairRequestFragment, Maybe } from '@oms/generated/frontend';
import type { InvestorOrderEventPayload } from './investor-order-entry.form-common';

function getParsedPayload(
  sourceEventPayload: string | InvestorOrderEventPayload
): Maybe<InvestorOrderEventPayload> {
  if (typeof sourceEventPayload === 'string') {
    try {
      return getParsedPayload(JSON.parse(sourceEventPayload) as string);
    } catch (error) {
      console.error('Error parsing sourceEventPayload', error);
      return null;
    }
  } else {
    return sourceEventPayload;
  }
}

export function getInvestorOrderFromRepair(ioRepair: IoRepairRequestFragment) {
  const { sourceEventPayload } = ioRepair;
  const parsedPayload = getParsedPayload(sourceEventPayload);

  if (!parsedPayload) {
    throw new Error('Unable to parse sourceEventPayload');
  }

  // TODO: discuss with the team
  // For IO_MODIFY, the originalCommand contain order details which is the order that failed
  // So to display correct data in the form, we need to use owerride the order with the order from originalCommand
  // which contains the updated modified values
  if (parsedPayload.originalCommand && parsedPayload.originalCommand.order) {
    return {
      ...parsedPayload.order,
      ...parsedPayload.originalCommand.order
    };
  }

  return parsedPayload.order;
}
