import type { CrossOrderInfoFragment, InvestorOrder } from '@oms/generated/frontend';
import type {
  CrossPrincipalFillRow,
  CrossPrincipalFillState
} from '@app/widgets/trading/cross-principal-fill/cross-principal-fill.form-common';
import { getState$ } from './cross-principal-fill.state';
import type { CrossPrincipalFormValues } from '@app/widgets/trading/cross-principal-fill/cross-principal-fill.form-contract';
import { isEqual } from 'lodash';
import type { Maybe } from '@graphql-tools/utils';

export const inputChanged = (formId: string, values: CrossPrincipalFormValues) => {
  const prevValues = getState$(formId).getValue().formValues;
  const prevEnrichedData = prevValues.hiddenFormInfo?.enrichedData;
  const enrichedData = values.hiddenFormInfo?.enrichedData;

  const enrichedOrdersChanged =
    !isEqual(prevEnrichedData?.buyOrdersInfo, enrichedData?.buyOrdersInfo) ||
    !isEqual(prevEnrichedData?.sellOrdersInfo, enrichedData?.sellOrdersInfo);
  const limitPriceChanged = values.limitPrice !== prevValues.limitPrice;

  return limitPriceChanged || enrichedOrdersChanged || totalQuantityChanged(formId, values);
};

export const totalQuantityEnriched = (formId: string, values: CrossPrincipalFormValues) => {
  const prevValues = getState$(formId).getValue().formValues;
  const enrichedData = values.hiddenFormInfo?.enrichedData;

  return prevValues.quantity !== enrichedData?.quantity;
};

export const totalQuantityChanged = (formId: string, values: CrossPrincipalFormValues) => {
  const prevValues = getState$(formId).getValue().formValues;

  return Boolean(prevValues.quantity && values.quantity !== prevValues.quantity);
};

const getUpdatedRowState = (
  rows: CrossPrincipalFillRow[],
  enrichedOrders?: Maybe<CrossOrderInfoFragment[]>
): CrossPrincipalFillRow[] => {
  return rows.map((row) => {
    const enrichedOrderInfo = enrichedOrders?.find((o) => o.investorOrder.id === row.order.id);
    const isDisabled = !enrichedOrderInfo?.isCompatible;

    return {
      ...row,
      state: {
        ...row.state,
        isDisabled,
        isChecked: !isDisabled && !row.state.isExcluded,
        fillQuantity: row.state?.isManualQuantity
          ? row.state?.fillQuantity
          : enrichedOrderInfo?.allocatedQuantity
      }
    };
  });
};

export const getInitialRowState = (orders: InvestorOrder[] | undefined) => {
  if (!orders) return [];

  return orders.map((order) => ({ order, state: {} })) as CrossPrincipalFillRow[];
};

export const getUpdatedRows = (state: CrossPrincipalFillState) => {
  const { buyOrders, sellOrders } = state.formValues.hiddenFormInfo || {};
  const { buySideRows, sellSideRows } = state;
  const enrichedData = state.formValues.hiddenFormInfo?.enrichedData;

  const buyOrderRows = buySideRows.length ? buySideRows : getInitialRowState(buyOrders);
  const sellOrderRows = sellSideRows.length ? sellSideRows : getInitialRowState(sellOrders);

  return {
    buySideRows: getUpdatedRowState(buyOrderRows, enrichedData?.buyOrdersInfo),
    sellSideRows: getUpdatedRowState(sellOrderRows, enrichedData?.sellOrdersInfo)
  };
};
