import { useCallback } from 'react';
import { useService } from '@oms/frontend-foundation';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';
import type { MontageLayoutProps } from '@app/widgets/trading/montage/montage.layout.config';
import { MontageService } from '@app/data-access/services/trading/montage/montage.service';

/**
 * @param [options] Set montage state options object
 * @returns A memoized callback to set Montage state
 */
export const useSetMontageState = () => {
  const scopedActorId = useScopedActorId();
  const service = useService(MontageService);

  return useCallback(
    (stateChange?: Partial<MontageLayoutProps>): Promise<void> =>
      service.setMontageState(scopedActorId, stateChange),
    [scopedActorId, service]
  );
};

export default useSetMontageState;
