import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { MONTAGE_BUMP_PRICE_ACTION_NAME } from './bump-price.action.types';
import type {
  ActionCommandConfig,
  ActionCommandContracts
} from '@app/actions/commands/command.registry.types';
import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';
import type { CountType } from '@app/data-access/services/trading/montage/utils/montage-target-state.class';

export const MONTAGE_BUMP_PRICE_ACTION: ActionDefFactory = (b) =>
  b
    .name(MONTAGE_BUMP_PRICE_ACTION_NAME)
    .toolbar((t) =>
      t.location('LeftVerticalToolbar').component('icon-counter-button').id(MONTAGE_BUMP_PRICE_ACTION_NAME)
    )
    .lifecycles('change')
    .onChange<ActionCommandConfig<ActionCommandContracts['montage_bump_price']>>(async (e) => {
      const { config, state, container } = e;
      const { payload, actionContext$ } = config;
      const { countType } = state as Record<'countType', CountType>;

      const tradingOrdersService = container.resolve(TradingOrdersService);
      const bumpPrice = Number(payload?.bumpPrice || '0');

      if (actionContext$ && bumpPrice > 0) {
        const bumpPriceState = actionContext$.getCommand('montage_bump_price');

        if (bumpPriceState) {
          const { targetState } = bumpPriceState;

          if (targetState?.currentTarget?.id && bumpPrice) {
            await tradingOrdersService.bump({
              bumpAmount: countType === 'up' ? bumpPrice : -bumpPrice,
              tradingOrderId: targetState?.currentTarget.id
            });
          }
        }
      }
    });
