// @ts-nocheck
import React, { useCallback, useEffect, useRef, forwardRef } from 'react';
import { Input, type InputProps } from '../input/input';
import { NumericInputBehavior } from './numeric-input.behavior';
import type { INumericInputOptions } from './contracts/config-types';
import { formatNumeric } from '@oms/shared/util';
import { Sprinkles } from '../../system/sprinkles.css';
import { __DEV__ } from '../../system/utils/assertion';
import { THOUSAND_DELIMITER, DECIMAL_DELIMITER } from './constants/delimiters';
import { useMergedRef } from '../../system/hooks/use-merge-ref';

type OmitProps = 'type';

/**
 * @deprecated Use `NumberFormatProps` instead
 */
export type NumericInputProps = {
  textAlign?: Sprinkles['textAlign'];
} & INumericInputOptions &
  Omit<InputProps, OmitProps>;

/**
 * Allows easy currency or large value input
 * @deprecated Use `NumberFormat` component instead
 */
export const NumericInput = forwardRef<HTMLInputElement, NumericInputProps>((props, ref) => {
  const {
    scale,
    range,
    fixed,
    thousands,
    decimal,
    shortcuts,
    sx = {},
    textAlign,
    onInvalidKey = () => null,
    onFocus = () => null,
    onChange,
    placeholder,
    disableShortcuts,
    format,
    value,
    ...rest
  } = props;

  const _ref = useRef<HTMLInputElement>(undefined);
  const mergedRef = useMergedRef(ref, _ref);

  const inputValue =
    typeof value === 'number'
      ? formatNumeric({
          type: format,
          value,
          thousandDelimiter: thousands || THOUSAND_DELIMITER,
          decimalDelimiter: decimal || DECIMAL_DELIMITER,
          trimTrailingZeros: true
        })
      : value;

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  useEffect(() => {
    const enhanced = new NumericInputBehavior(_ref, {
      scale,
      range,
      fixed,
      thousands,
      decimal,
      shortcuts,
      onInvalidKey,
      onFocus,
      disableShortcuts,
      format
    });

    return () => {
      if (enhanced && enhanced.destroy !== undefined) {
        enhanced.destroy();
      }
    };
  }, [
    ref,
    scale,
    range,
    fixed,
    thousands,
    decimal,
    shortcuts,
    onInvalidKey,
    onFocus,
    disableShortcuts,
    props.onChange,
    format
  ]);

  const extraProps = {} as { 'data-shortcuts': boolean };
  if (!disableShortcuts) {
    extraProps['data-shortcuts'] = true;
  }

  return (
    <Input
      ref={mergedRef}
      sx={{
        textAlign: textAlign ? textAlign : 'left',
        ...sx
      }}
      type="text"
      data-type="numeric"
      value={inputValue}
      onChange={onInputChange}
      {...extraProps}
      {...rest}
      placeholder={placeholder}
    />
  );
});

if (__DEV__) {
  NumericInput.displayName = 'NumericInput';
}
