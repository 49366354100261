import { InvestorAccountType, InvestorOrderStatus } from '@oms/generated/frontend';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';

export const internalizationCommonFilters = (
  order: VisibleInvestorOrderInfoWithAllocationsFragment,
  userId: string | null | undefined
) => {
  const accountTypeFilter = order.investorAccount?.accountType === InvestorAccountType.Client;
  const openQuantityFilter = order.openQuantity && order.openQuantity > 0;
  const statusFilter = order.status === InvestorOrderStatus.Active;
  const ownerFilter = userId ? order.owner?.id === userId : false;

  return Boolean(accountTypeFilter && openQuantityFilter && statusFilter && ownerFilter);
};
