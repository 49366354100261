import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { MarketDataService } from '@app/data-access/services/marketdata/marketdata.service';
import type {
  IServerSideSubscriptionDataSource,
  IServerSideSubscriptionService,
  ServerSideAddedEvent,
  ServerSideEvent,
  ServerSideRemovedEvent,
  ServerSideUpdateEvent,
  ServerSideViewportStore
} from '@app/data-access/services/trading/server-side-subscriptions/server-side-subscriptions.service';
import {
  ServerSideSubscriptionsObservable,
  hasNoRelayFilters
} from '@app/data-access/services/trading/server-side-subscriptions/server-side-subscriptions.service';
import type {
  GetOrdersQuery,
  GetOrdersQueryVariables,
  OnOrderAddedSubscription,
  OnOrderRemovedFromViewSubscription,
  OnOrderUpdatedSubscription,
  VisibleInvestorOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import {
  GetOrdersDocument,
  OnOrderAddedDocument,
  OnOrderRemovedFromViewDocument,
  OnOrderUpdatedDocument,
  VisibleInvestorOrdersOrderBy
} from '@oms/generated/frontend';
import type { Dispatch, SetStateAction } from 'react';
import { map } from 'rxjs';
import { inject, injectable, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

export type IInvestorOrderDataSource = IServerSideSubscriptionDataSource<
  VisibleInvestorOrderInfoWithAllocationsFragment,
  GetOrdersQueryVariables
>;

@injectable()
export class InvestorOrderSubcriptionDatasource implements IInvestorOrderDataSource {
  constructor(
    @inject(MarketDataService) private marketDataService: MarketDataService,
    @inject(ApolloClientRPC) private apolloClientRPC: ApolloClientRPC<NormalizedCacheObject>
  ) {}

  public async get(variables?: GetOrdersQueryVariables) {
    const result = await this.apolloClientRPC.query<GetOrdersQuery, GetOrdersQueryVariables>({
      query: GetOrdersDocument,
      variables,
      fetchPolicy: 'network-only',
      context: {
        fetchOptions: {
          // TODO: Need to implement this in ApolloClientRPC
          // signal: abortController.signal
        }
      }
    });
    const totalCount = result.data.visibleInvestorOrders?.totalCount || 0;
    const nodes = result.data?.visibleInvestorOrders?.nodes || [];
    const nodesWithMarketData = nodes.map((d) => {
      // Merge market data into results if it exists to avoid flash
      const ticker = d?.instrument?.mappings?.displayCode || '';
      const marketData = ticker ? this.marketDataService.read(ticker) : undefined;
      const rowDataWithMarketData = {
        ...(marketData ? marketData.level1 : {}),
        ...d
      };
      return rowDataWithMarketData as VisibleInvestorOrderInfoWithAllocationsFragment;
    });
    return {
      error: null,
      isFetching: false,
      nodes: nodesWithMarketData,
      totalCount
    };
  }

  public get added$() {
    return this.apolloClientRPC
      .subscribe<OnOrderAddedSubscription>({
        query: OnOrderAddedDocument
      })
      .pipe(
        map((result) => {
          const add = result.data?.orderAdded;
          const addEvent: ServerSideAddedEvent<VisibleInvestorOrderInfoWithAllocationsFragment> = {
            type: 'add',
            data: add?.order as VisibleInvestorOrderInfoWithAllocationsFragment
          };
          return addEvent;
        })
      );
  }

  public get updated$() {
    return this.apolloClientRPC
      .subscribe<OnOrderUpdatedSubscription>({
        query: OnOrderUpdatedDocument
      })
      .pipe(
        map((result) => {
          const update = result.data?.orderUpdated;
          const updateEvent: ServerSideUpdateEvent<VisibleInvestorOrderInfoWithAllocationsFragment> = {
            type: 'update',
            data: update?.order as VisibleInvestorOrderInfoWithAllocationsFragment
          };
          return updateEvent;
        })
      );
  }

  public get removed$() {
    return this.apolloClientRPC
      .subscribe<OnOrderRemovedFromViewSubscription>({
        query: OnOrderRemovedFromViewDocument
      })
      .pipe(
        map((result) => {
          const remove = result.data?.orderRemovedFromView;
          const removeEvent: ServerSideRemovedEvent = {
            type: 'remove',
            id: remove?.id
          };
          return removeEvent;
        })
      );
  }

  public getRowId(row: VisibleInvestorOrderInfoWithAllocationsFragment): string {
    return row.id;
  }

  public eventsTransformer(
    event: ServerSideEvent<VisibleInvestorOrderInfoWithAllocationsFragment>,
    variables?: GetOrdersQueryVariables
  ): ServerSideEvent<VisibleInvestorOrderInfoWithAllocationsFragment> {
    switch (event.type) {
      case 'add': {
        const orderBy =
          variables?.orderBy && Array.isArray(variables.orderBy) && variables.orderBy.length > 0
            ? variables.orderBy[0]
            : variables?.orderBy && !Array.isArray(variables.orderBy)
              ? variables.orderBy
              : null;

        const isOnlyOrderedByValidatedTimestampDesc =
          orderBy === VisibleInvestorOrdersOrderBy.ValidatedTimestampDesc;

        const canAddDataToTheTop = isOnlyOrderedByValidatedTimestampDesc && hasNoRelayFilters(variables);

        // TODO: I could be even smarter here and check more filters to see if I can apply the data to the top
        // TODO: But this should be a BE concern anyway.

        if (canAddDataToTheTop) {
          return {
            ...event,
            index: 0 // Add to the top of the list
          };
        } else {
          return {
            type: 'refetch'
          };
        }
      }
      default: {
        return event;
      }
    }
  }
}

export type IInvestorOrderSubscription = IServerSideSubscriptionService<
  VisibleInvestorOrderInfoWithAllocationsFragment,
  GetOrdersQueryVariables
>;

@testScoped
@singleton()
export class InvestorOrdersSubscriptionService implements IInvestorOrderSubscription {
  constructor(
    @inject(InvestorOrderSubcriptionDatasource)
    private investorOrderSubscriptionsDatasource: IInvestorOrderDataSource
  ) {}

  public query$(variables?: GetOrdersQueryVariables) {
    return new ServerSideSubscriptionsObservable<VisibleInvestorOrderInfoWithAllocationsFragment>(
      this.investorOrderSubscriptionsDatasource,
      variables
    );
  }

  public track(
    orderId: VisibleInvestorOrderInfoWithAllocationsFragment['id'],
    setOrder: Dispatch<SetStateAction<VisibleInvestorOrderInfoWithAllocationsFragment | undefined>>,
    setError: Dispatch<SetStateAction<Error | undefined>>
  ) {
    const subscription = this.query$({
      filter: {
        id: {
          equalTo: orderId
        }
      }
    }).subscribe(
      ({ error, nodes }: ServerSideViewportStore<VisibleInvestorOrderInfoWithAllocationsFragment>) => {
        setError(error || undefined);
        if (!error && nodes.length === 0) {
          setError(new Error('Order not found'));
        }
        setOrder(nodes[0]);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }

  public dispose() {}
}
