import { useMemo } from 'react';
import clsx from 'clsx';
import * as styles from './css/price-slider.css';
import { FormatLargeNumber } from '@oms/shared/util';
import { Tooltip } from '../tooltip/tooltip';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

export type PriceSliderProps = {
  /**
   * The low price to display on the left.
   */
  lowPrice: number;
  /**
   * The high price to display on the right.
   */
  highPrice: number;
  /**
   * The current price, represented by the "thumb".
   */
  currentPrice: number;
};

export const PriceSlider = polymorphicComponent<'div', PriceSliderProps>((props, ref) => {
  const lowVal: string = FormatLargeNumber(props.lowPrice);
  const highVal: string = FormatLargeNumber(props.highPrice);
  const currentVal: string = FormatLargeNumber(props.currentPrice);
  const pctVal: number = useMemo(() => {
    if (props.currentPrice < props.lowPrice) {
      return 0;
    }
    if (props.currentPrice > props.highPrice) {
      return 100;
    }
    if (props.highPrice === props.lowPrice) {
      return 50;
    }
    const denominator: number = props.highPrice - props.lowPrice;
    const numerator: number = props.currentPrice - props.lowPrice;
    return (numerator / denominator) * 100;
  }, [props.lowPrice, props.highPrice, props.currentPrice]);

  const toolTipContent = (
    <div className={clsx(styles.sliderTooltip)}>
      <div className={clsx(styles.sliderTooltipRow)}>
        <div>Low:</div>
        <div>{lowVal}</div>
      </div>
      <div className={clsx(styles.sliderTooltipRow)}>
        <div>High:</div>
        <div>{highVal}</div>
      </div>
      <div className={clsx(styles.sliderTooltipRow)}>
        <div>Last:</div>
        <div>{currentVal}</div>
      </div>
    </div>
  );

  return (
    <Tooltip label={toolTipContent}>
      <div className={clsx(styles.slider)} ref={ref}>
        <span className={clsx(styles.labelLeft)}>{lowVal}</span>
        <div className={clsx(styles.bar)}>
          <span className={clsx(styles.dot)} style={{ left: `${pctVal}%` }}></span>
        </div>
        <span className={clsx(styles.labelRight)}>{highVal}</span>
      </div>
    </Tooltip>
  );
});

if (__DEV__) {
  PriceSlider.displayName = 'PriceSlider';
}
