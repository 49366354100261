import React from 'react';
import type { FieldProps, ICommonField } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import {
  FormBuilderFeedback as FormBuilderFeedbackPrimitive,
  type FormBuilderFeedbackProps
} from '../../builder/components/helpers/form-builder-feedback/form-builder-feedback';
import { useFormBuilderTemplate } from '../../builder/components/templates/common/form-builder-template.context';

export interface IFormBuilderFeedbackField
  extends Omit<ICommonField<typeof FORM_COMPONENT_TYPE.FORM_BUILDER_FEEDBACK>, 'sx' | 'className'>,
    FormBuilderFeedbackProps {}

export const FormBuilderFeedback: React.FC<FieldProps<IFormBuilderFeedbackField>> = (props) => {
  const { sx = {}, shouldGrowWindow } = useFieldApi<IFormBuilderFeedbackField>(props);
  const { initialFeedback } = useFormBuilderTemplate();

  return (
    <FormBuilderFeedbackPrimitive
      sx={sx}
      shouldGrowWindow={shouldGrowWindow}
      initialFeedback={initialFeedback}
    />
  );
};
