import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { AtdlFormats, InitValueMode } from '@valstro/fixatdl-core';
import { DatePicker, useFieldApi } from '@oms/frontend-foundation';
import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import type { DatePickerFieldProps } from '../../fixatdl-form.types.js';
import type { fixatdlFormComponentTypes } from '../fixatdl-component.mappers.js';
import { convertDateObjectToFixDate, getFutureOrCurrentDate } from '../utils.ts/fixatdl-date.util.js';

export const DatePickerField: FC<FieldProps<DatePickerFieldProps>> = React.memo(
  ({ initialValue, initValueMode = InitValueMode.INIT, ...props }) => {
    const {
      input: { onChange, value: _value }
    } = useFieldApi<ICommonField<fixatdlFormComponentTypes.DATE_PICKER, string>>(props);

    let value = _value || initialValue;

    if (!AtdlFormats.Dates.isFixDate(value)) {
      value = null;
    }

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
      if (value && !initialized) {
        const dateTime = getFutureOrCurrentDate(value, initValueMode);
        onChangeRef.current(convertDateObjectToFixDate(dateTime));

        setInitialized(true);
      }
    }, [initValueMode, value, initialized]);

    const handleChange = useCallback((value: Date | null) => {
      onChangeRef.current(value ? convertDateObjectToFixDate(value) : null);
    }, []);

    const date = value ? getFutureOrCurrentDate(value, initValueMode).toString() : undefined;

    return (
      <div style={{ minWidth: '180px', maxWidth: '180px', marginRight: '10px' }}>
        <DatePicker {...props} initialValue={date} value={date} valueChange={handleChange} />
      </div>
    );
  },
  isEqual
);
