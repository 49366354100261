import React from 'react';
import clsx from 'clsx';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import type { ScrollAreaProps as ScrollAreaPropsPrimitive } from '@radix-ui/react-scroll-area';
import { Box, type BoxProps } from '../../system/components/box/box';
import * as styles from './scroll-area.css';
import { __DEV__ } from '../../system/utils/assertion';

export type ScrollAreaProps = ScrollAreaPropsPrimitive & BoxProps;

export const ScrollArea = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
  ({ asChild = true, children, type, scrollHideDelay, dir, className, ...rest }, ref) => (
    <ScrollAreaPrimitive.Root asChild={asChild} scrollHideDelay={scrollHideDelay} type={type} dir={dir}>
      <Box className={clsx(styles.root, className)} {...rest}>
        <ScrollAreaPrimitive.Viewport ref={ref} className={styles.viewport}>
          {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollAreaPrimitive.Scrollbar orientation="horizontal" className={styles.scrollbar}>
          <ScrollAreaPrimitive.Thumb className={styles.thumb} />
        </ScrollAreaPrimitive.Scrollbar>
        <ScrollAreaPrimitive.Scrollbar orientation="vertical" className={styles.scrollbar}>
          <ScrollAreaPrimitive.Thumb className={styles.thumb} />
        </ScrollAreaPrimitive.Scrollbar>
        <ScrollAreaPrimitive.Corner className={styles.corner} />
      </Box>
    </ScrollAreaPrimitive.Root>
  )
);

if (__DEV__) {
  ScrollArea.displayName = 'ScrollArea';
}
