import {
  DisplayGrid as UIDisplayGrid,
  type DisplayGridProps as UIDisplayGridProps
} from '@oms/shared-frontend/ui-design-system';
import {
  type FoundationDisplayGridComponentProps,
  customDisplayFieldComponents
} from './display-grid.dictionary';

export type AppDisplayGridProps = Omit<
  UIDisplayGridProps<FoundationDisplayGridComponentProps>,
  'customDisplayFieldComponents'
>;

export const AppDisplayGrid: React.FC<AppDisplayGridProps> = (props) => {
  return (
    <UIDisplayGrid<FoundationDisplayGridComponentProps>
      {...props}
      customDisplayFieldComponents={customDisplayFieldComponents}
    />
  );
};
