import { OrderSideType, PriceType, QuantityType, TimeInForce } from '@oms/generated/frontend';
import type { Maybe, MontagePreferences, OrderSettingsProfile } from '@oms/generated/frontend';
import { comboBoxItemFrom } from '@oms/frontend-foundation';
import { sideTypeMapping } from '@app/common/mappers/order-enums';
import {
  createStrategyFieldValue,
  createStrategyInput
} from '@app/widgets/trading/route-order/fixatdl-strategy-field/fixatdl-strategy-field.util';
import { getTomorrowMidnight } from '@app/forms/common/fields/TIF-field/TIF-field.common';
import type { UserPreferencesContractFormValues } from '../user-preferences.form-contract';
import { cleanMaybe } from '@oms/shared/util';
import {
  getDurationInMMSS,
  getDurationInSeconds
} from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';
import type { MontagePreferencesFormOutput } from './montage-preferences.types';
import type { MontagePreferencesFormSchemaInput } from '../user-preferences.form-builder.config';

export const getOrderSideTypeLabel = (side: OrderSideType): string => sideTypeMapping[side];

export const getQuantityLabel = (quantity: QuantityType): string => {
  switch (quantity) {
    case QuantityType.Blank:
      return 'Blank';
    case QuantityType.ExplicitValue:
      return 'Explicit value';
    case QuantityType.OrderSizes:
      return 'Order sizes';
  }
};

export const getPriceTypeLabel = (type: PriceType): string => {
  switch (type) {
    case PriceType.Blank:
      return 'Blank';
    case PriceType.BidInside:
      return 'Bid';
    case PriceType.AskInside:
      return 'Ask';
    case PriceType.Selection:
      return 'Selection';
    case PriceType.Market:
      return 'Market';
  }
};

export const defaultMontageSettingsInput: UserPreferencesContractFormValues = {
  askSideType: OrderSideType.Sell,
  askQuantityType: QuantityType.Blank,
  askPriceType: PriceType.AskInside,
  askTimeInForce: { id: TimeInForce.Day },
  bidSideType: OrderSideType.Buy,
  bidQuantityType: QuantityType.Blank,
  bidPriceType: PriceType.AskInside,
  bidTimeInForce: { id: TimeInForce.Day }
};

const comboBoxItemFromOrderSize = (profile: Maybe<OrderSettingsProfile>) => {
  const box = comboBoxItemFrom.record<OrderSettingsProfile>(profile, {
    label: ({ description }) => description,
    id: ({ id }) => id
  });

  return box
    ? {
        id: box.id,
        label: box.label,
        value: box.label
      }
    : undefined;
};
// TODO: temprorary approach, to prevent the bug with validation and saving quantitiy order size without order size selected from order settings
const blankIfNoOrderSize =
  (profile: Maybe<OrderSettingsProfile>) =>
  (quantityType: QuantityType): QuantityType =>
    !profile && quantityType === QuantityType.OrderSizes ? QuantityType.Blank : quantityType;

const createQuantityValueOutput = (
  quantityType: QuantityType | undefined,
  quantityValue: string | number | undefined
) =>
  quantityType === QuantityType.ExplicitValue
    ? typeof quantityValue === 'string'
      ? Number.parseFloat(quantityValue)
      : quantityValue
    : undefined;

const createDisplaySizeOutput = (displaySize: string | number | undefined) => {
  const possiblyString = typeof displaySize === 'string' ? Number.parseFloat(displaySize) : displaySize;
  return typeof possiblyString === 'number' && !Number.isNaN(possiblyString) ? possiblyString : undefined;
};

export function montageSettingsToFormValues(
  settings: Maybe<MontagePreferences>,
  profile: Maybe<OrderSettingsProfile>
) {
  const s = settings;

  const comboBoxFromOrderSize = comboBoxItemFromOrderSize(profile);

  const blankIfNoOrderSizeWithProfile = blankIfNoOrderSize(profile);

  const bidSideType = cleanMaybe(s?.bidSideType as OrderSideType);
  const askSideType = cleanMaybe(s?.askSideType as OrderSideType);

  const bidPriceType = cleanMaybe(s?.bidPriceType as PriceType);

  const bidQuantity = blankIfNoOrderSizeWithProfile(s?.bidQuantityType ?? QuantityType.Blank);
  const askQuantity = blankIfNoOrderSizeWithProfile(s?.askQuantityType ?? QuantityType.Blank);

  const bidQuantityType = cleanMaybe(s?.bidQuantityValue as QuantityType);
  const bidQuantityValue = cleanMaybe(s?.bidQuantityValue);

  const askQuantityType = s?.askQuantityValue as QuantityType;
  const askQuantityValue = cleanMaybe(s?.askQuantityValue);

  return {
    // bid
    bidInitiateOrder: s?.bidInitiateOrder ?? false,
    bidSideType,
    ...(bidQuantityType === QuantityType.ExplicitValue
      ? { bidQuantityType, bidQuantityValue }
      : { bidQuantityType: bidQuantity }),
    bidOrderSize: comboBoxFromOrderSize,
    bidPriceType,
    bidTimeInForceType: {
      id: s?.bidTimeInForceType ?? TimeInForce.Day,
      subValue:
        s?.bidTimeInForceType === TimeInForce.Gtd
          ? s?.bidGtdTimestamp || getTomorrowMidnight()
          : s?.bidTimeInForceType === TimeInForce.Duration
            ? getDurationInMMSS(Number(s?.bidTifDuration))
            : undefined
    },
    bidDisplaySize: s?.bidDisplaySize ? Number.parseFloat(String(s.bidDisplaySize)) : undefined,
    bidDestinationId: s?.bidDestinationId
      ? comboBoxItemFrom.string(s?.bidDestinationId, { label: (id) => id })
      : undefined,
    bidStrategy: createStrategyFieldValue(s?.bidStrategy, s?.bidDestinationId || ''),
    // ask
    askInitiateOrder: s?.askInitiateOrder ?? undefined,
    askSideType,
    ...(askQuantityType === QuantityType.ExplicitValue
      ? { askQuantityType, askQuantityValue }
      : { askQuantityType: askQuantity }),
    askOrderSize: comboBoxFromOrderSize,
    askPriceType: s?.askPriceType || undefined,
    askTimeInForceType: {
      id: s?.askTimeInForceType ?? TimeInForce.Day,
      subValue:
        s?.askTimeInForceType === TimeInForce.Gtd
          ? s?.askGtdTimestamp || getTomorrowMidnight()
          : s?.askTimeInForceType === TimeInForce.Duration
            ? getDurationInMMSS(Number(s?.askTifDuration))
            : undefined
    },
    askDisplaySize: s?.askDisplaySize ? Number.parseFloat(String(s.askDisplaySize)) : undefined,
    askDestinationId: comboBoxItemFrom.string(s?.askDestinationId, { label: (id) => id }),
    askStrategy: createStrategyFieldValue(s?.askStrategy, s?.askDestinationId || ''),
    // common
    sendAttributable: s?.sendAttributable ?? undefined,
    hideOddLots: s?.hideOddLots ?? undefined,
    displayQuotesInShares: s?.displayQuotesInShares ?? undefined
  };
}

export const montageSettingsFormValuesToOutput = (
  formValues: MontagePreferencesFormSchemaInput
): MontagePreferencesFormOutput => {
  const {
    bidInitiateOrder,
    bidSideType,
    bidOrderSize,
    bidQuantityType,
    bidQuantityValue,
    bidPriceType,
    bidDisplaySize,
    bidDestinationId,
    bidStrategy,
    bidTimeInForceType,
    askInitiateOrder,
    askSideType,
    askQuantityType,
    askQuantityValue,
    askOrderSize,
    askPriceType,
    askDisplaySize,
    askDestinationId,
    askStrategy,
    askTimeInForceType,
    sendAttributable,
    hideOddLots,
    displayQuotesInShares
  } = formValues;
  const askOrderSizeValue = askOrderSize?.id ? askOrderSize?.id : undefined;
  const bidOrderSizeValue = bidOrderSize?.id ? bidOrderSize?.id : undefined;

  const isAskQuantityOrderSizes = askQuantityType === QuantityType.OrderSizes;
  const isBidQuantityOrderSizes = bidQuantityType === QuantityType.OrderSizes;

  let extraBidTifProperties: {
    bidGtdTimestamp?: string;
    bidTifDuration?: string;
  } = {};

  if (bidTimeInForceType?.id === TimeInForce.Gtd) {
    extraBidTifProperties = { bidGtdTimestamp: bidTimeInForceType?.subValue };
  }
  if (bidTimeInForceType?.id === TimeInForce.Duration) {
    extraBidTifProperties = { bidTifDuration: String(getDurationInSeconds(bidTimeInForceType?.subValue)) };
  }

  let extraAskTifProperties: {
    askGtdTimestamp?: string;
    askTifDuration?: string;
  } = {};

  if (askTimeInForceType?.subValue) {
    if (askTimeInForceType?.id === TimeInForce.Gtd) {
      extraAskTifProperties = { askGtdTimestamp: askTimeInForceType?.subValue };
    }
    if (askTimeInForceType?.id === TimeInForce.Duration) {
      extraAskTifProperties = { askTifDuration: String(getDurationInSeconds(askTimeInForceType?.subValue)) };
    }
  }

  return {
    // bid
    bidInitiateOrder: bidInitiateOrder ?? false,
    bidSideType: bidSideType || OrderSideType.Buy,
    bidPriceType: bidPriceType || PriceType.BidInside,
    bidTimeInForceType: bidTimeInForceType?.id || TimeInForce.Day,
    ...extraBidTifProperties,
    bidDisplaySize: createDisplaySizeOutput(bidDisplaySize),
    bidDestinationId: bidDestinationId?.id ?? undefined,
    // TODO strategy types are all messed up but this is better than before
    // @ts-expect-error
    bidStrategy: createStrategyInput(bidStrategy?.value, bidStrategy?.value?.strategyName),
    bidQuantityType: bidQuantityType || QuantityType.Blank,
    bidQuantityValue: createQuantityValueOutput(bidQuantityType, bidQuantityValue),
    bidOrderSize: isBidQuantityOrderSizes ? bidOrderSizeValue : undefined,
    // ask
    askInitiateOrder: askInitiateOrder ?? false,
    askSideType: askSideType || OrderSideType.Sell,
    askPriceType: askPriceType || PriceType.AskInside,
    askTimeInForceType: askTimeInForceType?.id || TimeInForce.Day,
    ...extraAskTifProperties,
    askDisplaySize: createDisplaySizeOutput(askDisplaySize),
    askDestinationId: askDestinationId?.id ?? undefined,
    // @ts-expect-error
    askStrategy: createStrategyInput(askStrategy?.value, askStrategy?.value?.strategyName),
    askQuantityType: askQuantityType || QuantityType.Blank,
    askQuantityValue: createQuantityValueOutput(askQuantityType, askQuantityValue),
    askOrderSize: isAskQuantityOrderSizes ? askOrderSizeValue : undefined,
    // common
    sendAttributable: sendAttributable ?? false,
    hideOddLots: hideOddLots ?? false,
    displayQuotesInShares: displayQuotesInShares ?? false
  };
};
