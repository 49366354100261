import {
  caseToLabel,
  makeFormatEmailAddress,
  convertStringToNumber,
  makePhoneNumberFormatter,
  mathOp
} from '@oms/shared/util';
import { convertAnyDateInput } from '@oms/shared/util-date';
import { pickBy } from 'lodash';
import { type NumericFormatProps, numericFormatter } from 'react-number-format';

/**
 * Common utility types
 * -----------------------------------------------------------------------------------
 */
type ExtractType<U, T extends string> = U extends { type: T } ? U : never;

export type Format<
  T extends string,
  TInput,
  TOutput,
  O extends { [key: string]: any } | undefined = undefined
> = O extends undefined
  ? { type: T; input: TInput; output: TOutput }
  : { type: T; input: TInput; output: TOutput; options: O };

export type FormatFn<T extends Format<string, any, any, any>> = T extends {
  input: infer I;
  output: infer O;
  options: infer Op;
}
  ? (input: I, options: Op | undefined) => O
  : T extends { input: infer I; output: infer O }
    ? (input: I) => O
    : never;

type StringOrNumber = string | number;
type StringNumberOrDate = StringOrNumber | Date;

/**
 * Common format specific types
 * -----------------------------------------------------------------------------------
 */
export type CommonNumberFormatOptions = Pick<
  NumericFormatProps,
  | 'thousandSeparator'
  | 'decimalSeparator'
  | 'allowedDecimalSeparators'
  | 'thousandsGroupStyle'
  | 'decimalScale'
  | 'fixedDecimalScale'
  | 'allowNegative'
  | 'allowLeadingZeros'
  | 'suffix'
  | 'prefix'
> & {
  negativeOneAsEmpty?: boolean;
};

export type NumericFormatPropsMapFn = (
  ctx: {
    input: StringOrNumber;
    numberInput: number;
    mode: 'format' | 'input';
  },
  options?: CommonNumberFormatOptions
) => [StringOrNumber, CommonNumberFormatOptions];

/**
 * Common functions
 * -----------------------------------------------------------------------------------
 */

/**
 * Convert a numeric string or number to a number
 * Useful for getting the number before running the formatter so we can apply the correct format based on the current number
 *
 * @param input - string or number
 * @param options - CommonNumberFormatOptions
 * @returns number
 */
export function convertNumericStringOrNumToNum(
  input: StringOrNumber,
  options: CommonNumberFormatOptions = {}
): number {
  return typeof input === 'string'
    ? convertStringToNumber(input, {
        decimalDelimiter: options.decimalSeparator,
        thousandDelimiter:
          typeof options.thousandSeparator === 'string'
            ? options.thousandSeparator
            : options.thousandSeparator
              ? ','
              : undefined
      })
    : input;
}

/**
 * Get the number of decimal places in a number taking into account `allowLeadingZeros` & `decimalSeparator`
 *
 * @param input - number or numeric string
 * @param options - CommonNumberFormatOptions
 * @returns
 */
function convertStringToNumberAndCheckDecimalPlaces(
  value: StringOrNumber,
  options: CommonNumberFormatOptions = {}
): { numberValue: number; decimalLength: number } {
  const { thousandSeparator, decimalSeparator: decimalDelimiter } = options || {};
  const thousandDelimiter =
    typeof thousandSeparator === 'string' ? thousandSeparator : thousandSeparator ? ',' : '';

  if (thousandDelimiter && decimalDelimiter) {
    const parts = value.toString().split(decimalDelimiter);
    parts[0] = parts[0].replace(new RegExp('\\' + thousandDelimiter, 'g'), '');

    const numberValue = parseFloat(parts.join('.'));
    const decimalLengthValue = parts[1].length;
    return { numberValue, decimalLength: decimalLengthValue };
  }
  const numberValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;

  return { numberValue, decimalLength: mathOp.countDecimalPlaces(value.toString()) };
}

/**
 * 👇 Define our text-based formats here
 * 👇 Note: For each format, you can define the input, output, and options (if any)
 * -----------------------------------------------------------------------------------
 */

/**
 * Text Format: Do nothing just return the input value as string
 * e.g. Jane Doe -> Jane Doe
 */
const textFormat: FormatFn<TextFormatType> = (inputVal) => {
  return String(inputVal);
};
export type TextFormatType = Format<'text', StringOrNumber, string>;

/**
 * Auto Label From Snake Case Value Format
 * e.g. snake_case -> Snake Case
 */
const autoLabelFromSnakeCaseValueFormat: FormatFn<AutoLabelFromSnakeCaseValueFormatType> = (inputVal) => {
  const input = typeof inputVal === 'string' ? inputVal : '';
  return caseToLabel.snake(input, { capitalizeThreshold: 3, specialFormat: { id: 'capitalized' } });
};
export type AutoLabelFromSnakeCaseValueFormatType = Format<
  'auto-label-from-snake-case-value',
  string,
  string
>;

/**
 * Email Format
 * If email is invalid, it will fallback to the input value
 *
 * @param input - email address
 * @returns email address or raw input value
 */
const emailAddressFormat: FormatFn<EmailAddressFormatType> = (input) =>
  makeFormatEmailAddress(true)({ value: input });
export type EmailAddressFormatType = Format<'email-address', string, string>;

/**
 * Email Format Strict
 * If email is invalid, it will fallback to an empty string
 *
 * @param input - email address
 * @returns email address or empty string
 */
const emailAddressStrictFormat: FormatFn<EmailAddressStrictFormatType> = (input) =>
  makeFormatEmailAddress(false)({ value: input });
export type EmailAddressStrictFormatType = Format<'email-address-strict', string, string>;

/**
 * Phone Number International Format: +1 (123) 456-7890
 *
 * @param input - phone number
 * @returns international phone number or raw input value
 */
const phoneNumberInternationalFormat: FormatFn<PhoneNumberInternationalFormatType> = (input) =>
  makePhoneNumberFormatter('INTERNATIONAL')({ value: input });
export type PhoneNumberInternationalFormatType = Format<'phone-number-international', string, string>;

/**
 * Phone Number National Format: (123) 456-7890
 *
 * @param input - email address
 * @returns national phone number or raw input value
 */
const phoneNumberNationalFormat: FormatFn<PhoneNumberNationalFormatType> = (input) =>
  makePhoneNumberFormatter('NATIONAL')({ value: input });
export type PhoneNumberNationalFormatType = Format<'phone-number-national', string, string>;

/**
 * 👇 Define our date/time based formats here
 * 👇 Note: For each format, you can define the input, output, and options (if any)
 * -----------------------------------------------------------------------------------
 */

/**
 * Per [Figma Style Guide](https://www.figma.com/file/IOe34hekMhb1WnU3irAl8B/Style-guide?t=wIyNAoy9xf6qT7g5-1)
 * Keep these formats aligned to Figma.
 */
const UIStyleGuideDateFormat = {
  date: 'MMM dd yyyy',
  time24: 'HH:mm',
  time12: 'h:mma',
  time24WithSeconds: 'HH:mm:ss',
  time12WithSeconds: 'h:mma',
  dateAndTimeWithSeconds: 'MMM dd yyyy, HH:mm:ss'
} as const;

/**
 * Date Format: 'MMM d yyyy'
 *
 * @param input - Date object, ISO date string, or unix time
 * @returns formatted date string
 */
const dateFormat: FormatFn<DateType> = (input) => {
  return convertAnyDateInput(input, UIStyleGuideDateFormat.date);
};
export type DateType = Format<'date', StringNumberOrDate, string>;

/**
 * Date Time Format: 'MMM do, yyyy, h:mm a'
 *
 * @param input - Date object, ISO date string, or unix time
 * @returns formatted date time string
 */
const dateTimeFormat: FormatFn<DateTimeType> = (input) => {
  return convertAnyDateInput(input, UIStyleGuideDateFormat.dateAndTimeWithSeconds);
};
export type DateTimeType = Format<'datetime', StringNumberOrDate, string>;

/**
 * Time Format: 'HH:mm' (24 hours format)
 *
 * @param input - Date object, ISO date string, or unix time
 * @returns formatted time string
 */
const timeFormat: FormatFn<TimeType> = (input) => {
  return convertAnyDateInput(input, UIStyleGuideDateFormat.time24);
};
export type TimeType = Format<'time', StringNumberOrDate, string>;

/**
 * Time Format with Seconds: 'HH:mm:ss' (24 hours format)
 *
 * @param input - Date object, ISO date string, or unix time
 * @returns formatted time string
 */
const timeWithSecondsFormat: FormatFn<TimeWithSecondsType> = (input) => {
  return convertAnyDateInput(input, UIStyleGuideDateFormat.time24WithSeconds);
};
export type TimeWithSecondsType = Format<'time-with-seconds', StringNumberOrDate, string>;

/**
 * Time Format 12 Hours: 'h:mma' (12 hours format)
 *
 * @param input - Date object, ISO date string, or unix time
 * @returns formatted time string
 */
const time12Format: FormatFn<Time12Type> = (input) => {
  return convertAnyDateInput(input, UIStyleGuideDateFormat.time12);
};
export type Time12Type = Format<'time12', StringNumberOrDate, string>;

/**
 * Time Format 12 Hours with Seconds: 'h:mma' (12 hours format)
 *
 * @param input - Date object, ISO date string, or unix time
 * @returns formatted time string
 */
const time12WithSecondsFormat: FormatFn<Time12WithSecondsType> = (input) => {
  return convertAnyDateInput(input, UIStyleGuideDateFormat.time12WithSeconds);
};
export type Time12WithSecondsType = Format<'time12-with-seconds', StringNumberOrDate, string>;

/**
 * 👇 Define our numeric based formats here
 * 👇 Note: For each format, you can define the input, output, and options (if any)
 * -----------------------------------------------------------------------------------
 */

export type FormatNumericOnlyKeys =
  | 'number'
  | 'decimal'
  | 'decimal-2-4'
  | 'percentage'
  | 'decimal-to-percentage'
  | 'quantity'
  | 'price'
  | 'number-position'
  | 'price-position'
  | 'price-position-based-on-one'
  | 'price-trade'
  | 'price-change'
  | 'price-fx'
  | 'charge-scale-range-number'
  | 'price-scale-range-number'
  | 'volume-scale-range-number';

export const numericFormatPropsMap: Record<FormatNumericOnlyKeys, NumericFormatPropsMapFn> = {
  /**
   * General Number Format: '1,000.45' , '1,000.2'
   */
  number: ({ input }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: 2,
      allowLeadingZeros: false,
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Decimal Format: '1,000.00'
   */
  decimal: ({ input }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: 2,
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Decimal 2/4 Format: '1,000.00' or '1,000.0000' (fixedDecimalScale)
   */
  'decimal-2-4': ({ input, numberInput, mode }, options = {}) => {
    const decimalScale =
      options?.decimalScale !== undefined ? options.decimalScale : Math.abs(numberInput) < 1 ? 4 : 2;
    return [
      input,
      {
        thousandSeparator: true,
        decimalScale,
        fixedDecimalScale: mode === 'format',
        valueIsNumericString: typeof input === 'string',
        ...options
      }
    ];
  },
  /**
   * Percentage Format: '25' to '25%'
   */
  percentage: ({ input }, options = {}) => [
    input,
    {
      thousandSeparator: false,
      decimalScale: 2,
      allowLeadingZeros: false,
      suffix: '%',
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Decimal To Percentage Format: '0.25' to '25%'
   */
  'decimal-to-percentage': ({ input, numberInput }, options = {}) => [
    `${numberInput * 100}`,
    {
      thousandSeparator: false,
      decimalScale: 2,
      allowLeadingZeros: false,
      suffix: '%',
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Quantity Format: '1,000' (Integer only)
   */
  quantity: ({ input }, options = {}) => {
    return [
      input,
      {
        thousandSeparator: true,
        decimalScale: 0,
        fixedDecimalScale: true,
        valueIsNumericString: typeof input === 'string',
        ...options
      }
    ];
  },
  /**
   * Price Format: '1,000.00' (decimalScale: 2 & fixedDecimalScale: true)
   */
  price: ({ input, mode }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: mode === 'format',
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Number Position Format: If number is less than 1, decimalScale is 6, otherwise 4
   */
  'number-position': ({ input, numberInput, mode }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: Math.abs(numberInput) < 1 ? 6 : 4,
      fixedDecimalScale: mode === 'format',
      allowLeadingZeros: false,
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Price Position Format: '1,000.00' (decimalScale: 2 & fixedDecimalScale: true)
   */
  'price-position': ({ input, mode }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: 2,
      valueIsNumericString: typeof input === 'string',
      fixedDecimalScale: mode === 'format',
      ...options
    }
  ],
  /**
   * Position Based On One Price Format: '1,000.00' or '1,000.0000' or '1,000.0000000' (fixedDecimalScale)
   */
  'price-position-based-on-one': ({ input, mode }, options = {}) => {
    const { numberValue, decimalLength } = convertStringToNumberAndCheckDecimalPlaces(input, options);
    return [
      input,
      {
        thousandSeparator: true,
        decimalScale: numberValue === 0 || decimalLength <= 2 ? 2 : numberValue >= 1 ? 4 : 6,
        fixedDecimalScale: mode === 'format',
        valueIsNumericString: typeof input === 'string',
        ...options
      }
    ];
  },
  /**
   * Trade Price Format: '1,000.0000' OR '0.900000'
   */
  'price-trade': ({ input, mode, numberInput }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: Math.abs(numberInput) < 1 ? 6 : 4,
      fixedDecimalScale: mode === 'format',
      allowLeadingZeros: true,
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * FX Rate Price Format: Always 6 digits after the dot ('1,000.000000' and '0.900000')
   */
  'price-fx': ({ input, mode }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: 6,
      fixedDecimalScale: mode === 'format',
      allowLeadingZeros: true,
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Price Change Format: '+1,000.00' or '-1,000.00' or '0.00'
   */
  'price-change': ({ input, mode, numberInput }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: mode === 'format',
      allowNegative: false,
      prefix: numberInput > 0 ? '+' : numberInput < 0 ? '-' : '',
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Charge Scale Range Number Format: '1,000' (Integer only)
   */
  'charge-scale-range-number': ({ input }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: 0,
      negativeOneAsEmpty: true,
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Price Scale Range Number Format: '1,000.00'
   */
  'price-scale-range-number': ({ input, mode }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: mode === 'format',
      negativeOneAsEmpty: true,
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ],
  /**
   * Volume Scale Range Number Format: '1,000' (Integer only)
   */
  'volume-scale-range-number': ({ input }, options = {}) => [
    input,
    {
      thousandSeparator: true,
      decimalScale: 0,
      negativeOneAsEmpty: true,
      valueIsNumericString: typeof input === 'string',
      ...options
    }
  ]
};

/**
 * Helper types to automatically generate the dictionary for numeric formats
 * -----------------------------------------------------------------------------------
 */
export type FormatUnion = FormatTextUnion | FormatDateTimeUnion | FormatNumericOnlyUnion;

type FormatNumericDictionary = {
  [K in FormatNumericOnlyKeys]: Format<K, StringOrNumber, string, CommonNumberFormatOptions>;
};

export type FormatNumericOnlyUnion = FormatNumericDictionary[keyof FormatNumericDictionary];

export const numericFormatDictionary = Object.entries(numericFormatPropsMap).reduce(
  (acc, [key, numFn]) => ({
    ...acc,
    [key]: (input: StringOrNumber, options?: CommonNumberFormatOptions) => {
      const definedOptions = options ? pickBy(options, (v) => v !== undefined) : {};
      const numberInput = convertNumericStringOrNumToNum(input, definedOptions);
      if (options?.negativeOneAsEmpty && numberInput === -1) return '';
      const [newInput, newOptions] = numFn({ input, numberInput, mode: 'format' }, definedOptions);
      if (newOptions?.negativeOneAsEmpty && numberInput === -1) return '';
      return numericFormatter(`${newInput}`, newOptions);
    }
  }),
  {} as Record<FormatNumericOnlyUnion['type'], FormatFn<FormatNumericOnlyUnion>>
);

const dateTimeFormatTypes = new Set<FormatType>(['date', 'time', 'datetime']);
export const isNumericFormat = (type: FormatType) => Object.keys(numericFormatPropsMap).includes(type);
export const isDateTimeFormat = (type: FormatType) => dateTimeFormatTypes.has(type);

/**
 * 👇 Add them to the type union
 * -----------------------------------------------------------------------------------
 */
export type FormatTextUnion =
  | TextFormatType
  | AutoLabelFromSnakeCaseValueFormatType
  | EmailAddressFormatType
  | EmailAddressStrictFormatType
  | PhoneNumberInternationalFormatType
  | PhoneNumberNationalFormatType;

export type FormatDateTimeUnion =
  | DateType
  | DateTimeType
  | TimeType
  | TimeWithSecondsType
  | Time12Type
  | Time12WithSecondsType;

/**
 * 👇 Add them to the dictionary
 * -----------------------------------------------------------------------------------
 */
export const formatDictionary: FormatDictionary = {
  // Text-based formats
  text: textFormat,
  'auto-label-from-snake-case-value': autoLabelFromSnakeCaseValueFormat,
  'email-address': emailAddressFormat,
  'email-address-strict': emailAddressStrictFormat,
  'phone-number-international': phoneNumberInternationalFormat,
  'phone-number-national': phoneNumberNationalFormat,
  // Date/time formats
  date: dateFormat,
  datetime: dateTimeFormat,
  time: timeFormat,
  'time-with-seconds': timeWithSecondsFormat,
  time12: time12Format,
  'time12-with-seconds': time12WithSecondsFormat,
  // Numeric formats.
  // Note: Do not add new numeric formats here. Add them to numericFormatDictionary
  ...numericFormatDictionary
};

export type FormatType = FormatUnion['type'];
export type FormatDictionary = {
  [key in FormatType]: FormatFn<ExtractType<FormatUnion, key>>;
};

/**
 * Format function implementation
 * -----------------------------------------------------------------------------------
 */
export type GetFormatUnionInput<T extends string> = ExtractType<FormatUnion, T>['input'];
export type GetFormatUnionOutput<T extends string> = ExtractType<FormatUnion, T>['output'];
export type GetFormatUnionOptionsOrUndefined<T extends string> =
  ExtractType<FormatUnion, T> extends {
    options: infer O;
  }
    ? O
    : undefined;
export type GetFormatUnionOptions<T extends string> = Exclude<GetFormatUnionOptionsOrUndefined<T>, undefined>;

// Overload signatures
export function format<T extends FormatType>(
  type: T,
  input: GetFormatUnionInput<T>,
  options: GetFormatUnionOptions<T>
): GetFormatUnionOutput<T>;

export function format<T extends FormatType>(type: T, input: GetFormatUnionInput<T>): GetFormatUnionOutput<T>;

// Function implementation
export function format<T extends FormatType>(
  type: T,
  input: GetFormatUnionInput<T>,
  options?: GetFormatUnionOptionsOrUndefined<T>
): GetFormatUnionOutput<T> {
  const formatFn = formatDictionary[type];
  if (!formatFn) {
    throw new Error(`Format type '${type}' not found`);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return formatFn(input as any, options as any);
}
