import type { Maybe } from '@graphql-tools/utils';
import { Optional, WithRequiredFields } from '@oms/shared/util-types';

/**
 * Eliminates the possibility of a `null` value by mapping `null` to `undefined`.
 * ```ts
 * const name = cleanMaybe(response?.company?.name); // name is a `string | undefined`
 * ```
 *
 * @param value - Any value that could be `null` or `undefined`
 * @returns The value or `undefined` but never `null`
 */
export function cleanMaybe<T>(value: Maybe<T>): Optional<T>;

/**
 * Eliminates the possibility of a `null` or  `undefined` by supplying a fallback value.
 * ```ts
 * const name = cleanMaybe(response?.company?.name, "Valstro"); // name is a `string`
 * ```
 *
 * @param value - Any value that could be `null` or `undefined`
 * @param or - A fallback or default value to use if the value is `null` or `undefined`
 * @returns The value or fallback value
 */
export function cleanMaybe<T>(value: Maybe<T>, or: T): T;

/**
 * Eliminates the possibility of a `null` or  `undefined` by supplying a partial fallback value (only applicable when value is an objects).
 * ```ts
 * const company = cleanMaybe(response?.company, { name: "Valstro" }); // company is a `Partial<Company>`
 * ```
 *
 * @param value - Any value that could be `null` or `undefined`
 * @param or - A fallback or default value to use if the value is `null` or `undefined`
 * @returns The value or fallback value
 */
export function cleanMaybe<T extends object & { id?: string }>(
  value: Maybe<T>,
  orPartial: Partial<T>
): WithRequiredFields<Partial<T>, 'id'>;

// Implementation only
export function cleanMaybe<T>(value: Maybe<T>, fallback?: T): Optional<T> {
  if (typeof value === 'undefined' || value === null) return fallback;
  return value;
}

/**
 * For values that could be `null` or `undefined` this allows access to a nested or calculated value if the value is non-null
 * and defined while preserving the top-level `null` or `undefined` if not.
 *
 * @param value - Any value that could be `null` or `undefined`
 * @param transform - A callback that transforms the value if non-null and defined.
 * @returns The transformed value or `null` or `undefined`
 */
export const mapMaybe = <T, R>(value: Maybe<T>, transform: (value: T) => R): Maybe<R> => {
  if (value === null) return null;
  if (typeof value === 'undefined') return;
  return transform(value);
};
