import type { PositionEventType, PositionsTreeForAccountFragment } from '@oms/generated/frontend';
import type { Maybe, OneOrMore, Optional } from '@oms/shared/util-types';
import { asArray, cleanMaybe, compactMap } from '@oms/shared/util';
import { POSITION_EVENT_TYPE_TO_STRING_MAP } from '../positions.constants';
import type { ExtractedTreeIdMaps } from './types';

/**
 * For an array of positions trees, extract relevant account + position IDs and mappings to a
 * summary data structure.
 *
 * @param trees - Array of positions trees
 * @return An object with lookup maps
 */
export function extractTreeIds(trees: OneOrMore<PositionsTreeForAccountFragment>): ExtractedTreeIdMaps {
  const maps: ExtractedTreeIdMaps = {
    accountIdNameMap: new Map<string, string>(),
    positionIdSymbolMap: new Map<string, string>(),
    accountIdToPositionIdMap: new Map<string, string[]>()
  };
  const positionsTrees = asArray(trees);
  if (!positionsTrees.length) return maps;
  const [root] = positionsTrees;
  if (!root?.accountPositions) return maps;
  return root.accountPositions.reduce((acc, iter) => {
    let { accountIdNameMap, positionIdSymbolMap, accountIdToPositionIdMap } = acc;
    if (!iter?.accountId) {
      return {
        accountIdNameMap,
        positionIdSymbolMap,
        accountIdToPositionIdMap
      };
    }
    const { accountId, accountName, positions: _positions } = iter;
    // update account ID/name map if possible
    accountIdNameMap = accountIdNameMap.set(accountId, cleanMaybe(accountName, 'Name not available'));
    if (!_positions?.length) {
      return {
        accountIdNameMap,
        positionIdSymbolMap,
        accountIdToPositionIdMap
      };
    }
    const positions = compactMap(_positions, (position) => {
      if (!position) return;
      const { id, instrument } = position;
      return {
        id,
        // set symbol- first try to get ticker as instrumentSymbolData.displayCode, then instrumentSymbol,
        // then fallback to "Unavailable (positionId)"
        symbol: instrument?.mappings?.displayCode || `Unavailable (${id})`
      };
    });
    positions.forEach(({ id, symbol }) => {
      // update position/symbol mapping
      positionIdSymbolMap = positionIdSymbolMap.set(id, symbol);
      // get previous position IDs for this account, or initialize a new array
      const prevPositionIdsForAccount: string[] = accountIdToPositionIdMap.get(accountId) || [];
      accountIdToPositionIdMap = accountIdToPositionIdMap.set(accountId, [
        ...prevPositionIdsForAccount,
        ...[id]
      ]);
    });
    return {
      accountIdNameMap,
      positionIdSymbolMap,
      accountIdToPositionIdMap
    };
  }, maps);
}

/**
 * @param [event] - A possible event type
 * @returns A possible mapped string
 */
export function mapPositionEventTypeToString(event: Maybe<PositionEventType>): Optional<string>;

/**
 * @param event - An event type
 * @returns A guaranteed mapped string
 */
export function mapPositionEventTypeToString(event: PositionEventType): string;

/**
 * @param [event] - A possible event type
 * @param fallback - A fallback string
 * @returns A guaranteed string... mapped or fallback
 */
export function mapPositionEventTypeToString(event: Maybe<PositionEventType>, fallback: string): string;

// Implementation only
export function mapPositionEventTypeToString(
  event: Maybe<PositionEventType>,
  fallback?: string
): Optional<string> {
  return event ? POSITION_EVENT_TYPE_TO_STRING_MAP[event] : fallback;
}
