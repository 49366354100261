import { t } from '@oms/codegen/translations';
import type { Optional } from '@oms/shared/util-types';
import type {
  EnvExtensionMap,
  EnvType,
  MatchedEnvType,
  StructuredEnvLabel
} from '../types/app/app-version.types';

export interface AppEnvFormatOptions {
  type?: 'full' | 'short';
  case?: 'upper' | 'lower' | 'default';
  suppressTag?: boolean;
}

/** Structure containing application ENV information */
export class AppEnv<Type extends EnvType = EnvType, Tag extends string = string> {
  public readonly type: EnvType;
  public readonly tag?: Tag;

  // 🏗️ Constructors -------------------------------------------------- /

  public static fromType<Type extends EnvType = EnvType, Tag extends string = string>(
    type: Type,
    tag?: Tag
  ): AppEnv<Type, Tag> {
    if (type === 'Other') {
      return new AppEnv({ type, tag, unstructuredLabel: tag || type });
    } else {
      const structuredLabel: StructuredEnvLabel<MatchedEnvType, Tag> = tag
        ? `${tag}-${type as MatchedEnvType}`
        : (type as MatchedEnvType);
      return new AppEnv({ type, tag, structuredLabel });
    }
  }

  public static fromStructuredLabel<
    Type extends MatchedEnvType = MatchedEnvType,
    Tag extends string = string
  >(structuredLabel: StructuredEnvLabel<Type, Tag>) {
    const { type, tag } = extractFromEnvString(structuredLabel);
    return new AppEnv({ type, tag, structuredLabel });
  }

  /**
   * Create from any raw string input
   * @param label - Any string input
   * @returns AppEnv object
   */
  public static fromString(label: string) {
    const { type, tag } = extractFromEnvString(label);
    if (type !== 'Other' && isStructuredEnvLabel(label, { type, tag })) {
      return new AppEnv({ type, tag, structuredLabel: label });
    } else {
      return new AppEnv({ type, tag: tag || label, unstructuredLabel: label });
    }
  }

  // Presets ------- /

  public static get Prod(): AppEnv<'Prod'> {
    return AppEnv.fromType('Prod');
  }

  public static get Dev(): AppEnv<'Dev'> {
    return AppEnv.fromType('Dev');
  }

  public static get Test(): AppEnv<'Test'> {
    return AppEnv.fromType('Test');
  }

  public static get UAT(): AppEnv<'UAT'> {
    return AppEnv.fromType('UAT');
  }

  // 📢 Public -------------------------------------------------- /

  public get label(): string {
    return this.structuredLabel || this.unstructuredLabel || this.tag || this.type;
  }

  public format(options?: AppEnvFormatOptions): string {
    const shorten = options?.type === 'short';
    const getTranslatedTypeDescription = (): Optional<string> => {
      switch (this.type) {
        case 'Prod':
          return shorten ? t('app.system.envLevel.prod', { ns: 'short' }) : t('app.system.envLevel.prod');
        case 'Dev':
          return shorten ? t('app.system.envLevel.dev', { ns: 'short' }) : t('app.system.envLevel.dev');
        case 'Test':
          return shorten ? t('app.system.envLevel.test', { ns: 'short' }) : t('app.system.envLevel.test');
        case 'UAT':
          return shorten ? t('app.system.envLevel.uat', { ns: 'short' }) : t('app.system.envLevel.uat');
        default:
          return '';
      }
    };
    const getTypeDescription = () => {
      const description = getTranslatedTypeDescription();
      if (typeof description === 'string') return description;
      // Fallback in case i18n translation fails
      if (shorten) return this.type;
      switch (this.type) {
        case 'Prod':
          return 'Production';
        case 'Dev':
          return 'Development';
        default:
          return this.type;
      }
    };
    const getBase = () => {
      const typeDescription = getTypeDescription();
      if (!this.tag || options?.suppressTag) return typeDescription;
      if (!typeDescription) return this.tag;
      return `${this.tag}-${typeDescription}`;
    };
    switch (options?.case) {
      case 'upper':
        return getBase().toUpperCase();
      case 'lower':
        return getBase().toLowerCase();
      default:
        return getBase();
    }
  }

  public toString(): string {
    return this.format();
  }

  // 🔒 Protected / Private -------------------------------------------------- /

  protected readonly structuredLabel?: StructuredEnvLabel<MatchedEnvType, Tag>;
  protected readonly unstructuredLabel?: string;

  protected constructor({
    type,
    tag,
    structuredLabel,
    unstructuredLabel
  }: {
    type: Type;
    tag?: Tag;
    structuredLabel?: StructuredEnvLabel<MatchedEnvType, Tag>;
    unstructuredLabel?: string;
  }) {
    this.type = type;
    if (tag) this.tag = tag;
    if (structuredLabel) this.structuredLabel = structuredLabel;
    if (unstructuredLabel) this.unstructuredLabel = unstructuredLabel;
  }
}

// 👁️ Private ---------------------------------------------------------- /

// Types ------ /

interface AppEnvComponents {
  type: EnvType;
  tag?: string;
  main?: string;
}

interface EnvPatternSetup<Type extends MatchedEnvType = MatchedEnvType, Tag extends string = string> {
  type?: Type;
  tag?: Tag;
}

type EnvTypePattern<Type extends MatchedEnvType> =
  | `${Lowercase<Type>}(?:${EnvExtensionMap[Type]})?`
  | Lowercase<Type>;

// Utils ------ /

const allEnvs: MatchedEnvType[] = ['Prod', 'Dev', 'Test', 'UAT'];

const buildEnvTypePattern = (type: MatchedEnvType): EnvTypePattern<MatchedEnvType> => {
  switch (type) {
    case 'Prod':
      return 'prod(?:uction)?';
    case 'Dev':
      return 'dev(?:elopment)?';
    case 'Test':
      return 'test(?:ing)?';
    case 'UAT':
      return 'uat';
  }
};

const buildEnvExp = <Type extends MatchedEnvType = MatchedEnvType, Tag extends string = string>(
  options?: EnvPatternSetup<Type, Tag>
) => {
  const { type, tag } = options || {};
  const typePattern = type
    ? buildEnvTypePattern(type)
    : allEnvs.map((et) => buildEnvTypePattern(et)).join('|');
  const resolvedTag = tag || ('(?:[a-z0-9]*)' as Tag);
  const tagPattern = `(?:((?:${resolvedTag}))-)?`;
  return new RegExp(`^${tagPattern}(${typePattern})\$`, 'im');
};

const isStructuredEnvLabel = <Type extends MatchedEnvType = MatchedEnvType, Tag extends string = string>(
  envString: string,
  options?: EnvPatternSetup<Type, Tag>
): envString is StructuredEnvLabel<Type, Tag> => {
  return envString.match(buildEnvExp(options)) !== null;
};

const getEnvType = (input: string): EnvType => {
  const typeString = input.toLowerCase();
  for (const type of allEnvs) {
    if (typeString.match(new RegExp(buildEnvTypePattern(type), 'im'))) {
      return type;
    }
  }
  return 'Other';
};

const extractFromEnvString = (envString: string): AppEnvComponents => {
  const components: AppEnvComponents = { type: 'Other' };
  const results = envString.match(buildEnvExp());
  if (!results) return components;
  const tag = results?.[1] || undefined;
  const main = results?.[2] || undefined;
  if (tag) components.tag = tag;
  if (main) {
    components.main = main;
    components.type = getEnvType(main);
  }
  return components;
};
