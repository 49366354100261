import { useActorContext } from '@valstro/workspace-react';
import { useMemo } from 'react';
import { useCurrentAppWindow } from '../workspace/workspace.hooks';

export const useWindowMetaData = () => {
  const windowActor = useCurrentAppWindow();
  const context = useActorContext(windowActor);
  const { widgetCategory = '', widgetType = '', windowType = 'window' } = context.meta;
  return useMemo(
    () => ({ widgetCategory, widgetType, windowType }),
    [widgetCategory, widgetType, windowType]
  );
};

export default useWindowMetaData;
