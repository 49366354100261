import { MontageContextMenuItemTitle } from './title';
import { Option } from './montage-contextmenu-option';
import { wrapperStyle } from './montage-contextmenu.css';
import { openEnableQuote } from '@app/generated/sdk';

type QuoteMenuItemProps = {
  name: string;
  instrument: string;
};

async function handleQuoteClick({ name, instrument }: QuoteMenuItemProps) {
  await openEnableQuote(undefined, {
    title: name,
    form: {
      formId: `enable-quote-${instrument}`,
      input: {
        instrument
      }
    }
  });
}

export const QuoteMontageMenuItem = ({ name, instrument }: QuoteMenuItemProps) => {
  return (
    <div className={wrapperStyle}>
      <Option as="div" onClick={() => void handleQuoteClick({ name, instrument })}>
        <MontageContextMenuItemTitle name={name} />
      </Option>
    </div>
  );
};
