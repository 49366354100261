import { FormatType } from '@oms/shared/util';
import type { ValuedAndLabeled } from '@oms/shared/util-types';
import { FC } from 'react';
import { Flex, FlexProps } from '../../layout/flex/flex';
import { Text } from '../../layout/text/text';
import { DISPLAY_GRID_CUSTOM_COMPONENT_TYPE } from '../display-grid/display-grid';
import { Numeric } from '../display-field/components/numeric/numeric';
import { DISPLAY_FIELD_COMPONENT_TYPE } from '../display-field/display-field.contracts';
import type { Sprinkles } from '../../system/sprinkles.css';

export type StatItem = ValuedAndLabeled<string | number> & { format?: FormatType; sublabel?: string };

export type StatsProps = {
  type?: typeof DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.Stats;
  items: StatItem[];
  height?: string;
  width?: string;
  sx?: FlexProps['sx'];
  isTextActive?: boolean;
};

const borderStyle: Sprinkles = {
  borderRightStyle: 'solid',
  borderRightWidth: '1px',
  borderColor: 'text.semiMinor'
};

const textStyle: Sprinkles = {
  color: 'text.semiMinor',
  marginBottom: '-1'
};

export const Stats: FC<StatsProps> = ({ items, height, width, sx, isTextActive }) => {
  return (
    <Flex
      justify="space-between"
      sx={{ borderRadius: 'md', backgroundColor: 'layout.level4', ...sx }}
      style={{
        height: height || '100%',
        width: width || '100%',
        paddingTop: '2.5rem',
        paddingBottom: '2.5rem'
      }}
    >
      <Flex sx={{ flex: 1 }}>
        {items.map((item, index) => {
          return (
            <Flex
              direction="column"
              sx={{ flex: 1, ...(index < items.length - 1 ? borderStyle : null) }}
              style={{ paddingLeft: '1.5rem' }}
              key={item.label}
              data-testid="stat"
            >
              <Text sx={textStyle}>{item.label}</Text>
              {item.value ? (
                <Numeric
                  type={DISPLAY_FIELD_COMPONENT_TYPE.Numeric}
                  format={item.format}
                  value={item.value}
                  textProps={{
                    type: 'largeR',
                    sx: isTextActive ? { color: 'text.active' } : { color: 'white' }
                  }}
                />
              ) : (
                <Flex align="center" style={{ height: '24px' }}>
                  -
                </Flex>
              )}
              {item.sublabel && <Text sx={textStyle}>{item.sublabel}</Text>}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
