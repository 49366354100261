import type { Maybe, PrimaryBenchmark } from '@oms/generated/frontend';
import { primaryBenchmarkMapping } from './order-enums';

type PrimaryBenchmarkKeys = `${PrimaryBenchmark}`;

export const mapPrimaryBenchmarkType = (
  value?: Maybe<PrimaryBenchmark> | PrimaryBenchmarkKeys,
  defaultVal = value || ''
): string => {
  return value && primaryBenchmarkMapping[value as PrimaryBenchmark]
    ? primaryBenchmarkMapping[value as PrimaryBenchmark]
    : defaultVal;
};
