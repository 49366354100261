import { isEqual } from 'lodash';
import React from 'react';
import type { FC } from 'react';
import { mapFixAtdlListOptions } from '../../utils';
import { Select } from '@oms/frontend-foundation';
import type { FieldProps } from '@oms/frontend-foundation';
import type { DropdownListProps } from '../../fixatdl-form.types';

export const DropdownList: FC<FieldProps<DropdownListProps>> = React.memo((props) => {
  return <Select {...props} options={mapFixAtdlListOptions(props?.options)} component="select" />;
}, isEqual);
