import {
  sharedDateTimeCol,
  sharedDefaultCol,
  sharedIdCol,
  sharedPriceCol,
  sharedQuantityCol,
  sharedTextCol
} from '@app/common/grids/columns/generic-cols';
import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import type { InvestorOrderTradingOrderRow } from '@app/data-access/services/trading/investor-orders/investor-order-trading-orders.service';
import { sharedLimitPriceCol, sharedSideCol } from '@app/common/grids/columns/order-cols';

const defaultCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  sharedDefaultCol<InvestorOrderTradingOrderRow>(c);

const idCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  sharedIdCol<InvestorOrderTradingOrderRow>(c, 'id').hide();

const limitPriceCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) => sharedLimitPriceCol(c);

const sideCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) => sharedSideCol(c);

const capacityCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  c
    .colId('capacity')
    .field('capacity')
    .header(t('app.orders.tradingOrderMonitor.capacity'))
    .shortHeader(t('app.orders.tradingOrderMonitor.capacity'))
    .width(95)
    .maxWidth(180);

const locateCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  c.field('locate').header('Locate').width(82);

const quantityCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  sharedQuantityCol(c, 'quantity').header('Total Qty');

const mappedQuantityCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  sharedQuantityCol(c, 'mappedQuantity').header('Mapped Qty');

const executedQuantityCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  sharedQuantityCol(c, 'executedQuantity').header('Exec Qty');

const averagePriceCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  sharedPriceCol(c, 'averagePrice').header('Avg Px');

const venueIdCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  sharedTextCol(c, 'destinationName').header(t('app.orders.orderMonitor.routeTo'));

// "Order Time" is the Trading Order's created time.
const createdTimestampCol: ColumnBuilderCallback<InvestorOrderTradingOrderRow> = (c) =>
  sharedDateTimeCol(c, 'createdTimestamp').header('Order Time');

export const investorOrderTradingOrdersColumnLibrary: ColumnLibrary<InvestorOrderTradingOrderRow> = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    limitPriceCol,
    sideCol,
    locateCol,
    quantityCol,
    capacityCol,
    mappedQuantityCol,
    executedQuantityCol,
    venueIdCol,
    averagePriceCol,
    createdTimestampCol
  ]
};
