import type { ColumnLibrary } from '@oms/frontend-vgrid';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import type { TreeDataSlice } from '@app/data-access/services/trading/positions/common/tree-grid/types/tree-data.types';
import {
  defaultColumn,
  instrument,
  accountName,
  quantity,
  currencyId,
  totalPNLToday,
  unrealizedPNL,
  realizedPNLToday,
  monthToDatePNL,
  previousMonthPNL,
  quarterToDatePNL,
  previousQuarterPNL,
  yearToDatePNL,
  previousYearPNL,
  averagePrice,
  cost,
  trueAveragePrice,
  valuationPrice,
  positionValuationStrategy,
  lastMarkedDateTime,
  manualMarkEndDateTime,
  exposure,
  accountAccumulationFxRate
} from './position-columns';

const { displayCode, longName } = instrument;

export const positionsInstrumentColumnLibrary: ColumnLibrary<TreeDataSlice<PositionRow>> = {
  defaultColumn,
  columns: [
    displayCode,
    longName,
    accountName,
    quantity,
    currencyId,
    averagePrice,
    cost,
    trueAveragePrice,
    valuationPrice,
    positionValuationStrategy,
    lastMarkedDateTime,
    manualMarkEndDateTime,
    exposure,
    unrealizedPNL,
    realizedPNLToday,
    totalPNLToday,
    monthToDatePNL,
    previousMonthPNL,
    quarterToDatePNL,
    previousQuarterPNL,
    yearToDatePNL,
    previousYearPNL,
    accountAccumulationFxRate
  ]
};

export default positionsInstrumentColumnLibrary;
