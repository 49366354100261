import type { Field } from '@data-driven-forms/react-form-renderer';
import type { FormOptions, IAdvancedSelectField } from '@oms/frontend-foundation';
import type { RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type { UsersComboBoxValue } from '@app/forms/common/fields/user-field/users.watch-all.query';

export const ROUTE_ORDER_VENUE_QUERY_TO_USE = 'routeOrderVenueQueryToUse';

export const routeOrderVenueQueryToUse =
  () =>
  (
    _props: Field,
    _field: Field,
    formOptions: FormOptions
  ): Partial<IAdvancedSelectField<UsersComboBoxValue>> => {
    const formValues: Partial<RouteOrderFormValues> = formOptions.getState().values;
    const { hiddenMode } = formValues || {};
    const type = hiddenMode?.type || 'create';

    switch (type) {
      case 'modify':
        return {
          isDisabled: true,
          forceIsDisabled: true,
          query: ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY
        };
      case 'route':
        return {
          query: ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY
        };
      case 'create':
        return {
          query: ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_QUERY
        };
      default:
        return {};
    }
  };
