import type { Observable, Subscription } from 'rxjs';
import { map, catchError, startWith, firstValueFrom, finalize, BehaviorSubject, combineLatest } from 'rxjs';

import type {
  CreateInvestorAccountMutation,
  DeleteInvestorAccountMutation,
  FixEndpointFragment,
  GetAccountsQuery,
  GetAllSenderFirmFixEndpointsQuery,
  GetParentAccountsQuery,
  GetParentAccountsQueryVariables,
  GroupingAccountFragment,
  GetVisibleAccountsQuery,
  InvestorAccountFragment,
  GetVisibleAccountsInfoQuery,
  GetVisibleAccountsInfoQueryVariables,
  GetVisibleAccountsByTypeQuery,
  GetVisibleAccountsByTypeQueryVariables,
  VisibleAccountsInfoFragment,
  InvestorAccountBasicFragment,
  InvestorAccountInput,
  UpdateInvestorAccountMutation,
  InvestorAccountSubType,
  GetAccountsQueryVariables,
  GetVisibleAccountsQueryVariables,
  GetAllSenderFirmFixEndpointsQueryVariables,
  CreateInvestorAccountMutationVariables,
  UpdateInvestorAccountMutationVariables,
  DeleteInvestorAccountMutationVariables,
  GetAccountsBasicQuery,
  GetAccountsBasicQueryVariables,
  GetInvestorAccountQuery,
  GetInvestorAccountQueryVariables
} from '@oms/generated/frontend';
import {
  GetInvestorAccountDocument,
  CreateInvestorAccountDocument,
  DeleteInvestorAccountDocument,
  GetAccountsDocument,
  GetAllSenderFirmFixEndpointsDocument,
  GetParentAccountsDocument,
  GetVisibleAccountsDocument,
  GetVisibleAccountsInfoDocument,
  GetVisibleAccountsByTypeDocument,
  InvestorAccountType,
  UpdateInvestorAccountDocument,
  GetAccountsBasicDocument
} from '@oms/generated/frontend';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { whereNotUndefined } from '@oms/shared/util-types';
import type { Optional, ValuedAndLabeled } from '@oms/shared/util-types';
import { cleanMaybe, compactMap, IdentifiableOrderedSet, logger } from '@oms/shared/util';
import type { DataSourceRowData } from '@oms/frontend-foundation';
import { asDataSource } from '@oms/frontend-foundation';
import type { AwaitGQLResultType, DataSourceCommon, ICrudService } from '@oms/frontend-foundation';
import type { AnySimpleAccount } from '@app/common/types/accounts/types';
import { simplifyAccount } from '@app/common/types/accounts/utils';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import ExtendedCoverageModel from '../../coverage/extended-coverage-model.class';

type WithLabelsAndValues<T> = T & Partial<ValuedAndLabeled<string | number>>;

type ParentAccountLookup = (
  account?: InvestorAccountFragment | GroupingAccountFragment | AnySimpleAccount
) => Optional<AnySimpleAccount>;

export type AccountFilterOptions = {
  type?: InvestorAccountType;
  subType?: InvestorAccountSubType;
  accumulation?: boolean;
};

const name = 'AccountsService';

@scoped(Lifecycle.ContainerScoped)
export class AccountsService implements ICrudService<InvestorAccountFragment> {
  private _apolloClient: ApolloClientRPC;
  private _gqlResponse: GQLResponse;

  protected name: string = name;
  protected logger: typeof logger.debug;

  protected static logger = logger.as(`${name}.static`);

  constructor(
    @inject(ApolloClientRPC) apolloClient: ApolloClientRPC,
    @inject(GQLResponse) gqlResponse: GQLResponse
  ) {
    this._apolloClient = apolloClient;
    this._gqlResponse = gqlResponse;
    this.logger = logger.as(this.name);
  }

  public async getInvestorAccount(id: string): Promise<Optional<InvestorAccountFragment>> {
    return await firstValueFrom(this._watchAll_GetInvestorAccountQuery$(id));
  }

  public watchAll$(): Observable<DataSourceCommon<InvestorAccountFragment>> {
    return this._asDataSource$(this._watchAll_GetAccountsQuery$());
  }

  // Watch all Accounts (This version returns only basic information)
  public watchAllBasic$(): Observable<DataSourceCommon<InvestorAccountBasicFragment>> {
    return this._asDataSource$(this._watchAll_GetAccountsQueryBasic$());
  }

  public watchAllFirmAccounts$(): Observable<DataSourceCommon<InvestorAccountFragment>> {
    return this._asDataSource$(
      this._watchAll_GetAccountsQuery$({
        type: InvestorAccountType.Firm
      })
    );
  }

  public watchAllIntermediaryAccounts$(): Observable<DataSourceCommon<InvestorAccountFragment>> {
    return this._asDataSource$(
      this._watchAll_GetAccountsQuery$({
        type: InvestorAccountType.Intermediary
      })
    );
  }

  public watchAllClientAccounts$(): Observable<DataSourceCommon<InvestorAccountFragment>> {
    return this._asDataSource$(
      this._watchAll_GetAccountsQuery$({
        type: InvestorAccountType.Client
      })
    );
  }

  public watchFixEndpoints$(): Observable<DataSourceCommon<FixEndpointFragment>> {
    return this._asDataSource$(this._watchAll_GetAllSenderFirmFixEndpointsQuery$());
  }

  public watchAllVisibleAccounts$(): Observable<DataSourceCommon<InvestorAccountFragment>> {
    return this._asDataSource$(this._watchAll_GetVisibleAccountsQuery$());
  }

  public watchAllVisibleFirmAccounts$(): Observable<DataSourceCommon<InvestorAccountFragment>> {
    return this._asDataSource$(this._watchAll_GetVisibleAccountsByTypesQuery$([InvestorAccountType.Firm]));
  }

  public visibleAccountsInfo$(): Observable<DataSourceCommon<VisibleAccountsInfoFragment>> {
    return this._asDataSource$(this._watchAll_GetVisibleAccountsInfoQuery$());
  }

  /**
   * @returns An `Observable` with a list of firm accounts where `accumulation` is `true` as well as all parent accounts of each.
   */
  public watchAllPositionsAccessAccounts$(): Observable<DataSourceCommon<AnySimpleAccount>> {
    return this._asDataSource$(this._watchAll_PositionsAccessCombined$());
  }

  public getRowData$(): Observable<DataSourceRowData<InvestorAccountFragment>> {
    return this._asRowData$(this._watchAll_GetAccountsQuery$());
  }

  public async create(input: InvestorAccountInput): AwaitGQLResultType<CreateInvestorAccountMutation> {
    this.logger.log('start create, input is: ', input);

    const mutation = this._gqlResponse.wrapMutate<
      CreateInvestorAccountMutation,
      CreateInvestorAccountMutationVariables
    >({
      mutation: CreateInvestorAccountDocument,
      variables: {
        input
      },
      refetchQueries: [GetAccountsDocument, GetVisibleAccountsDocument],
      awaitRefetchQueries: true
    });

    const result = await mutation.exec();
    this.logger.log('result is: ', result);

    return result;
  }

  public async update(
    id: string,
    input: InvestorAccountInput
  ): AwaitGQLResultType<UpdateInvestorAccountMutation> {
    const mutation = this._gqlResponse.wrapMutate<
      UpdateInvestorAccountMutation,
      UpdateInvestorAccountMutationVariables
    >({
      mutation: UpdateInvestorAccountDocument,
      variables: {
        id,
        input
      },
      refetchQueries: [GetAccountsDocument, GetVisibleAccountsDocument],
      awaitRefetchQueries: true
    });

    return await mutation.exec();
  }

  public async delete(id: string): AwaitGQLResultType<DeleteInvestorAccountMutation> {
    const mutation = this._gqlResponse.wrapMutate<
      DeleteInvestorAccountMutation,
      DeleteInvestorAccountMutationVariables
    >({
      mutation: DeleteInvestorAccountDocument,
      variables: {
        id
      },
      refetchQueries: [GetAccountsDocument, GetVisibleAccountsDocument],
      awaitRefetchQueries: true
    });

    return await mutation.exec();
  }

  public makeAccountLookup = async (
    filterOptions?: AccountFilterOptions
  ): Promise<(accountId: string) => Optional<InvestorAccountFragment>> => {
    const observable = this._watchAll_GetAccountsQuery$(filterOptions).pipe(
      map((allAccounts) => {
        const lookup: Map<string, InvestorAccountFragment> = new Map();
        allAccounts.forEach((account) => {
          const { id } = account;
          if (id) lookup.set(id, account);
        });
        return (accountId: string) => {
          return lookup.get(accountId);
        };
      })
    );
    return await firstValueFrom(observable);
  };

  // 🔒 Protected --------------------------------------------------------- /

  protected _watchAll_GetAccountsQuery$(
    filterOptions?: AccountFilterOptions
  ): Observable<InvestorAccountFragment[]> {
    const result = this._apolloClient.watchQuery<GetAccountsQuery, GetAccountsQueryVariables>({
      query: GetAccountsDocument
    });
    return result.pipe(
      map(({ data }) => compactMap(cleanMaybe(data.allAccounts, []), (account) => account)),
      map((allAccounts) => this.filterAccounts(allAccounts, filterOptions)),
      map((allAccounts) =>
        allAccounts.map((account) => {
          const coverageModels =
            account?.coverageModels?.map((cm) => new ExtendedCoverageModel(cm ?? {}, account)) ?? [];
          const defaultCoverageModel = coverageModels.find((cm) => cm.isDefault);
          const primaryCoveragePersons = defaultCoverageModel?.primaryCoverage;
          return { ...account, coverageModels, primaryCoveragePersons };
        })
      )
    );
  }

  protected _watchAll_GetInvestorAccountQuery$(id: string): Observable<Optional<InvestorAccountFragment>> {
    return this._apolloClient
      .watchQuery<GetInvestorAccountQuery, GetInvestorAccountQueryVariables>({
        query: GetInvestorAccountDocument,
        variables: {
          id
        }
      })
      .pipe(map(({ data }) => cleanMaybe(data.getInvestorAccount)));
  }

  protected _watchAll_GetAccountsQueryBasic$(): Observable<InvestorAccountBasicFragment[]> {
    const result = this._apolloClient.watchQuery<GetAccountsBasicQuery, GetAccountsBasicQueryVariables>({
      query: GetAccountsBasicDocument
    });

    return result.pipe(map(({ data }) => compactMap(cleanMaybe(data.allAccounts, []), (account) => account)));
  }

  protected _watchAll_GetAllSenderFirmFixEndpointsQuery$(): Observable<FixEndpointFragment[]> {
    const result = this._apolloClient.watchQuery<
      GetAllSenderFirmFixEndpointsQuery,
      GetAllSenderFirmFixEndpointsQueryVariables
    >({
      query: GetAllSenderFirmFixEndpointsDocument
    });

    return result.pipe(
      map(({ data }) =>
        compactMap(cleanMaybe(data.getAllSenderFirmFixEndpoints, []), (fixEndpoint) => fixEndpoint)
      )
    );
  }

  protected _watchAll_GetVisibleAccountsQuery$(
    filterOptions?: AccountFilterOptions
  ): Observable<InvestorAccountFragment[]> {
    const result = this._apolloClient.watchQuery<GetVisibleAccountsQuery, GetVisibleAccountsQueryVariables>({
      query: GetVisibleAccountsDocument
    });

    return result.pipe(
      map(({ data }) => compactMap(cleanMaybe(data.getVisibleAccounts, []), (account) => account)),
      map((allAccounts) => this.filterAccounts(allAccounts, filterOptions))
    );
  }

  protected _watchAll_GetVisibleAccountsByTypesQuery$(
    accountTypes: InvestorAccountType[]
  ): Observable<InvestorAccountFragment[]> {
    const result = this._apolloClient.watchQuery<
      GetVisibleAccountsByTypeQuery,
      GetVisibleAccountsByTypeQueryVariables
    >({
      query: GetVisibleAccountsByTypeDocument,
      variables: {
        accountTypes
      }
    });

    return result.pipe(
      map(({ data }) => compactMap(cleanMaybe(data.getVisibleAccounts, []), (account) => account))
    );
  }

  protected _watchAll_GetVisibleAccountsInfoQuery$(
    filterOptions?: AccountFilterOptions
  ): Observable<VisibleAccountsInfoFragment[]> {
    const result = this._apolloClient.watchQuery<
      GetVisibleAccountsInfoQuery,
      GetVisibleAccountsInfoQueryVariables
    >({
      query: GetVisibleAccountsInfoDocument
    });

    return result.pipe(
      map(({ data }) => compactMap(cleanMaybe(data.getVisibleAccounts, []), (account) => account)),
      map((visibleAccounts) => this.filterAccounts(visibleAccounts, filterOptions))
    );
  }

  protected _watchAll_GetParentAccountsQuery$(): Observable<GroupingAccountFragment[]> {
    const result = this._apolloClient.watchQuery<GetParentAccountsQuery, GetParentAccountsQueryVariables>({
      query: GetParentAccountsDocument
    });

    return result.pipe(
      map(({ data }) => compactMap(cleanMaybe(data.getParentAccounts, []), (parentAccount) => parentAccount))
    );
  }

  /**
   * @returns An `Observable` with a list of firm accounts where `accumulation` is `true` as well as all parent accounts of each.
   */
  protected _watchAll_PositionsAccessCombined$(): Observable<AnySimpleAccount[]> {
    const [parentLookup, subscription, trigger$] = this.getParentLookup();
    const investorAccounts$ = this._watchAll_GetAccountsQuery$({
      type: InvestorAccountType.Firm,
      accumulation: true
    });
    const combinedAccounts$ = combineLatest([investorAccounts$, trigger$]).pipe(
      map(([allAccounts]) => {
        const combinedAccounts = new IdentifiableOrderedSet<AnySimpleAccount>();
        allAccounts.forEach((account) => {
          let parentAccount: Optional<AnySimpleAccount> = parentLookup(account);
          while (parentAccount) {
            combinedAccounts.prepend(parentAccount);
            parentAccount = parentLookup(parentAccount);
          }
          const simpleAccount = simplifyAccount(account);
          if (simpleAccount) combinedAccounts.append(simpleAccount);
        });
        return [...combinedAccounts];
      }),
      finalize(() => {
        subscription.unsubscribe();
      })
    );
    return combinedAccounts$;
  }

  protected filterAccounts(allAccounts: InvestorAccountFragment[], filterOptions?: AccountFilterOptions) {
    if (!filterOptions) return allAccounts;
    const { type, subType, accumulation: accumulationFilter } = filterOptions;
    return allAccounts.filter(
      ({ accountType, accountSubType, accumulation }) =>
        (type ? accountType === type : true) &&
        (subType ? accountSubType === subType : true) &&
        (typeof accumulationFilter === 'boolean'
          ? accumulationFilter === cleanMaybe(accumulation, false)
          : true)
    );
  }

  protected getParentLookup = (): [ParentAccountLookup, Subscription, Observable<number>] => {
    const allParents = new IdentifiableOrderedSet<AnySimpleAccount>();
    const trigger$ = new BehaviorSubject(0);
    const parentLookup: ParentAccountLookup = (account) => {
      const partialParent = cleanMaybe(account?.parent, {});
      const { id } = partialParent;
      if (!id) return;
      return allParents.get(id) ?? simplifyAccount(partialParent);
    };
    const subscription = this._watchAll_GetParentAccountsQuery$().subscribe((allParentAccounts) => {
      allParentAccounts.forEach((parentAccount) => {
        const value = simplifyAccount(parentAccount);
        if (value) {
          allParents.add(value);
        }
      });
      trigger$.next(trigger$.getValue() + 1);
    });
    return [parentLookup, subscription, trigger$];
  };

  protected _asDataSource$<T extends object>(observable$: Observable<T[]>): Observable<DataSourceCommon<T>> {
    return observable$.pipe(
      map((results) => asDataSource(results)),
      startWith(asDataSource([], { isFetching: true })),
      catchError((e) => {
        this.logger.error(e);
        return [];
      })
    );
  }

  protected _asRowData$<T extends object>(observable$: Observable<T[]>): Observable<DataSourceRowData<T>> {
    return observable$.pipe(
      startWith([]),
      catchError((e) => {
        this.logger.error(e);
        return [];
      })
    );
  }

  // 👓 Private --------------------------------------------------------- /

  private static investorAccountFragmentsToInputs(records: InvestorAccountFragment[]) {
    AccountsService.logger.log(`[investorAccountFragmentsToInputs]`, { records });
    const inputRecords: InvestorAccountInput[] = records.map((f) => {
      const currencyId = f.accumulationCurrency?.id;
      const aggregationUnitId = f.aggregationUnit as string;
      const fixEndpointIds: number[] =
        f?.fixEndpoints
          ?.filter(whereNotUndefined)
          ?.map((value) => (value as WithLabelsAndValues<FixEndpointFragment>).value as number | undefined)
          ?.filter(whereNotUndefined) ?? [];

      const tradingEntityName = f?.tradingEntity?.entityName;

      const input: InvestorAccountInput = {
        accumulationCurrencyId: currencyId,
        aggregationUnitId,
        fixEndpointIds,
        tradingEntityName,
        representativeCodeId: f.representativeCode?.id,
        defaultOrderTagIds: compactMap(f?.defaultOrderTags || [], (tag) => tag?.id)
      };

      AccountsService.logger.log('InvestorAccountInput', input);
      return input;
    });
    return inputRecords;
  }
}

export default AccountsService;
