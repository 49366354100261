import React from 'react';
import { JsonView, darkStyles } from 'react-json-view-lite';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import { EventDetailsComponentProps } from './event-details.widget.config';
import { StyleProps } from 'react-json-view-lite/dist/DataRenderer';
import 'react-json-view-lite/dist/index.css';
import { labelStyle, valueStyle, containerStyle } from './event-details.css';

const jsonViewStyles: StyleProps = {
  ...darkStyles,
  container: containerStyle,
  label: labelStyle,
  nullValue: valueStyle,
  undefinedValue: valueStyle,
  numberValue: valueStyle,
  stringValue: valueStyle,
  booleanValue: valueStyle,
  otherValue: valueStyle,
  punctuation: valueStyle,
  noQuotesForStringValues: true
};

export const EventDetailsWidget: React.FC = () => {
  const [props] = useCurrentAppWidgetActorProps<EventDetailsComponentProps>();

  if (!props?.eventDetails) {
    throw new Error('No event details provided');
  }

  return <JsonView data={props.eventDetails} style={jsonViewStyles} />;
};

export default EventDetailsWidget;
