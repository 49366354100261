import { exit } from '@tauri-apps/api/process';
import { invokeTauriCommand } from './commands';

/**
 * Exits the Tauri app.
 * >> Note: This will also stop the Level2 sidecar!
 */
export const exitTauriApp = async (): Promise<void> => {
  // eslint-disable-next-line no-restricted-syntax
  console.debug('⏹️ Quitting app');
  // eslint-disable-next-line no-restricted-syntax
  console.debug('🛑 Stopping sidecar...');
  const result = await invokeTauriCommand('stop_level2_sidecar');
  result.mapSync(({ status }) => {
    if (status === 'Stopped') {
      exit(0).catch(console.error);
    }
  }, console.error);
};
