import type { OrderVwapFormValues } from './order-vwap.form-contract';
import type { OrderVwapFormOutput } from './order-vwap.form-common';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { DependencyContainer } from 'tsyringe';

export async function orderVwapFormValuesToOutput(
  formValues: OrderVwapFormValues,
  container: DependencyContainer
): Promise<OrderVwapFormOutput | undefined> {
  const investorOrderIdFromInstrument = formValues?.instrument?.value?.sourceId;

  if (!investorOrderIdFromInstrument) {
    return;
  }

  const investorOrderService = container.resolve(InvestorOrdersService);
  const investorOrder = await investorOrderService.getById(investorOrderIdFromInstrument);

  if (investorOrder.isSuccess() && investorOrder.value.data.visibleInvestorOrder) {
    return {
      investorOrder: investorOrder.value.data.visibleInvestorOrder
    };
  }

  if (investorOrder.isFailure()) {
    console.error('Failed to get investor order', investorOrder.errors);
  }

  return undefined;
}
