import { COMMON_PLATFORM_NAME } from '@valstro/workspace';
import { CommonPopoverActorSchemaOverrides, commonPopoverSchemaOverrides } from './popover.common.actor';
import {
  COMMON_POPOVER,
  DEFAULT_POPOVER_OPTIONS,
  EMPTY_POPOVER_CTX,
  OpenPopoverOptions
} from './popover.contracts';
import { calculatePopoverBounds } from './popover.util';

export const BROWSER_POPOVER_ACTOR_NAME = 'browser-popover-window';

let mousePos: { x: number; y: number } = {
  x: 0,
  y: 0
};

export const commonBrowserPopoverSchemaOverrides: CommonPopoverActorSchemaOverrides = (prevSchema) => ({
  ...commonPopoverSchemaOverrides(prevSchema),
  initialContext: async (ctx) => ({
    ...(await prevSchema.initialContext(ctx)),
    isDecorated: false
  }),
  type: COMMON_POPOVER.TYPE,
  name: BROWSER_POPOVER_ACTOR_NAME,
  supportedPlatforms: [COMMON_PLATFORM_NAME.BROWSER],
  operations: (api, workspace) => {
    const prevOperations = prevSchema.operations(api, workspace);
    return {
      ...prevOperations,
      updateContext: async (ctx) => {
        await api.updateContext(ctx);
      },
      setComponentProps: async (ctx) => {
        await api.updateContext({
          componentProps: ctx
        });
      },
      updateComponentProps: async (ctx) => {
        const currentProps = api.getContext().componentProps || {};
        await api.updateContext({
          componentProps: {
            ...currentProps,
            ...ctx
          }
        });
      },
      getMousePosition: async () => {
        return mousePos;
      },
      discardPopover: async () => {
        await api.operations.hide();
        await api.updateContext(EMPTY_POPOVER_CTX);
      },
      openPopover: async ({
        windowId,
        popoverId,
        componentId,
        align,
        alignOffset,
        componentProps,
        height,
        side,
        sideOffset,
        rectWithScaling,
        width,
        autoSizeTriggerResizePixelSensitivity,
        ariaLabel,
        popoverTestId,
        autoSizeHeight,
        autoSizeWidth,
        autoSizeWidthToTrigger,
        autoSizePaddingHeight,
        autoSizePaddingWidth,
        focusOnOpen,
        enterDelay,
        exitDelay,
        transparent,
        trigger,
        closingStrategy
      }: OpenPopoverOptions) => {
        await api.updateContext({
          autoSizeTriggerResizePixelSensitivity,
          windowId,
          componentId,
          componentProps,
          popoverId,
          ariaLabel,
          popoverTestId,
          autoSizeHeight: autoSizeHeight ?? DEFAULT_POPOVER_OPTIONS.autoSizeHeight,
          autoSizeWidth: autoSizeWidth ?? DEFAULT_POPOVER_OPTIONS.autoSizeWidth,
          autoSizeWidthToTrigger: autoSizeWidthToTrigger ?? DEFAULT_POPOVER_OPTIONS.autoSizeWidthToTrigger,
          autoSizePaddingWidth,
          autoSizePaddingHeight,
          focusOnOpen: focusOnOpen ?? DEFAULT_POPOVER_OPTIONS.focusOnOpen,
          enterDelay: enterDelay ?? DEFAULT_POPOVER_OPTIONS.enterDelay,
          exitDelay: exitDelay ?? DEFAULT_POPOVER_OPTIONS.exitDelay,
          transparent: transparent ?? DEFAULT_POPOVER_OPTIONS.transparent,
          trigger: trigger ?? DEFAULT_POPOVER_OPTIONS.trigger,
          closingStrategy: closingStrategy ?? DEFAULT_POPOVER_OPTIONS.closingStrategy
        });

        const bounds = calculatePopoverBounds(
          {
            scaleFactor: 1,
            x: rectWithScaling.x,
            y: rectWithScaling.y,
            width: rectWithScaling.width,
            height: rectWithScaling.height,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            windowX: 0,
            windowY: 0
          },
          {
            align,
            alignOffset,
            height,
            side,
            sideOffset,
            width
          }
        );

        await api.operations.setSizeAndPosition(
          {
            width: bounds.width,
            height: bounds.height
          },
          {
            x: bounds.x,
            y: bounds.y
          }
        );

        await api.operations.show();

        await api.updateContext({
          isOpen: true,
          renderedSide: bounds.renderedSide,
          renderedAlign: bounds.renderedAlign
        });

        return {
          width: bounds.width,
          height: bounds.height,
          x: bounds.x,
          y: bounds.y
        };
      }
    };
  },
  events: async (...args) => {
    const unsubscribe = await commonPopoverSchemaOverrides(prevSchema)?.events?.(...args);

    const mouseMoveHandler = (e: MouseEvent) => {
      mousePos = { x: e.clientX, y: e.clientY };
    };

    window.addEventListener('mousemove', mouseMoveHandler);

    return () => {
      unsubscribe?.();
      window.removeEventListener('mousemove', mouseMoveHandler);
    };
  }
});
