import type { WinSizeOptions } from '@valstro/workspace';
import type { IJsonModel } from 'flexlayout-react';

export const standardMontageLayout: IJsonModel['layout'] = {
  id: 'standard',
  type: 'row',
  children: [
    {
      type: 'row',
      weight: 100,
      children: [
        {
          type: 'tabset',
          weight: 100,
          children: [
            {
              type: 'tab',
              name: 'Data',
              component: 'MONTAGE_DATA_PANEL'
            }
          ]
        }
      ]
    }
  ]
};

export const standardMontageWindowSize: WinSizeOptions = {
  width: 800,
  height: 700
};
