import { useCallback, useEffect, useRef, useState } from 'react';
import type { FieldProps, ICommonField } from '../../types';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Autocomplete } from '../../../popovers/autocomplete/popover.autocomplete';
import { Box, Flex } from '@oms/shared-frontend/ui-design-system';
import { useAdvancedSelectQuery } from '../advanced-select/advanced-select-query.hook';
import { useEnhancedFormApi } from '../../builder/helpers/form-api/form-api';
import { type Validator, useFormApi, type Field } from '@data-driven-forms/react-form-renderer';
import type { FORM_COMPONENT_TYPE } from '../../contracts';
import * as styles from './multi-input.css';
import type { IAdvancedSelectField } from '../advanced-select/advanced-select.types';
import type { ComboBoxItem } from '../../../combo-box/combo-box.types';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useFieldApi } from '../../helpers';

type ComboWithoutValue<TValue extends string = string> = Omit<ComboBoxItem<unknown, TValue>, 'value'>;

export type MultiInputOption<TValue extends string = string> = ComboWithoutValue<TValue> & {
  subcomponent?: Field;
};

export type MultiSelectValue<TValue extends string = string, TSubValue = unknown> = {
  id: TValue;
  subValue?: TSubValue;
};

export type IMultiInputField<
  TValue extends string = string,
  TSubValue = unknown,
  TValidator = Validator
> = ICommonField<typeof FORM_COMPONENT_TYPE.MULTI_INPUT, MultiSelectValue<TValue, TSubValue>, TValidator> & {
  options: MultiInputOption<TValue>[];
};

export const MultiInput = (props: FieldProps<IAdvancedSelectField<MultiInputOption>>) => {
  const field = useFieldApi<IAdvancedSelectField<MultiInputOption>>(props);

  const { fieldWrapperProps, autoCompleteProps } = useAdvancedSelectQuery(field);
  const [open, setOpen] = useState(false);
  const prevSubCompRefValue = useRef<any>(null);
  const subcompRef = useRef<Field | undefined>(undefined);
  // The function passed to useState is executed only once when the component is first rendered.
  // It is an "initializer function" https://react.dev/reference/react/useState#my-initializer-or-updater-function-runs-twice
  const [subcomponent, setSubcomponent] = useState<Field | undefined>(() => {
    // Destructure options from props
    const { options } = props;

    // Get the current value of the field, cast as MultiSelectValue
    const value = field.input.value as MultiSelectValue;

    // Find the option that matches the current value's id
    const foundOption = options?.filter(
      (option) => option?.type === 'item' && option?.id === value?.id
    ) as unknown as Pick<MultiInputOption, 'id' | 'label' | 'subcomponent'>[];

    // Extract the subcomponent from the found option (if any)
    const sub = foundOption?.[0]?.subcomponent;

    // If a subcomponent exists, create a modified version with hideFormControls set to true
    // Otherwise, set to undefined
    const modifiedSub = sub ? { ...sub, hideFormControls: true } : undefined;

    // Store the modified subcomponent in the ref for future use
    subcompRef.current = modifiedSub;

    // Return the modified subcomponent (or undefined) to set the initial state
    return modifiedSub;
  });

  const { renderForm } = useFormApi();
  const formApi = useEnhancedFormApi();

  useEffect(() => {
    // Subscribe to changes on the specific field (props.name)
    const sub = formApi.get$({ values: true, fields: [props.name] }).subscribe(({ values }) => {
      // If the field value is cleared
      if (!values[props.name]) {
        // Reset the subcomponent state
        setSubcomponent(undefined);
        // Close the dropdown
        setOpen(false);
      }

      // If the field has a subValue and a subcomponent reference exists
      if (values[props.name]?.subValue && subcompRef.current) {
        // Update the subcomponent's value in the form
        formApi.change(subcompRef.current.name, values[props.name].subValue);
        // Update the subcomponent state (triggering a re-render)
        setSubcomponent({ ...subcompRef.current });
      }
    });

    // Cleanup function to unsubscribe when the component unmounts or dependencies change
    return () => {
      sub.unsubscribe();
    };
  }, [formApi, props.name]); // Re-run effect if formApi or props.name changes

  const handleIconClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    const unsubscribe = formApi.subscribe(
      ({ values }) => {
        // Check if there's a subcomponent and its value has changed compared to the previous value
        if (
          subcompRef.current?.name &&
          !isEqual(values[subcompRef.current.name], prevSubCompRefValue.current)
        ) {
          // Update the value of the main field (props.name) with the new subcomponent value
          formApi.change(props.name, {
            id: values[props.name].id,
            subValue: values[subcompRef.current.name]
          });

          // Store the new subcomponent value for future comparison
          prevSubCompRefValue.current = values[subcompRef.current.name];
        }
      },
      { values: true }
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [formApi]);

  const handleChange = useCallback(
    (value: MultiInputOption[]) => {
      if (open) {
        setOpen(false);
      }

      const { options } = props;

      const foundOption = options?.filter(
        (option) => option?.type === 'item' && option?.id === value[0]?.id
      ) as unknown as Pick<MultiInputOption, 'id' | 'label' | 'subcomponent'>[];

      subcompRef.current = value[0]?.subcomponent;

      if (subcompRef.current) {
        formApi.change(subcompRef.current.name, undefined);
        subcompRef.current.hideFormControls = true;
        setSubcomponent(subcompRef.current);
      } else {
        if (subcomponent) {
          formApi.change(subcomponent.name, undefined);
        }
        setSubcomponent(undefined);
      }

      if (!foundOption[0]?.id) {
        formApi.change(props.name, undefined);
        return;
      }

      formApi.change(props.name, {
        id: foundOption[0].id
      });
    },
    [formApi, open, props.name, subcomponent]
  );

  return (
    <Box>
      <FieldWrapper {...{ ...fieldWrapperProps, ...(subcomponent?.label && { label: subcomponent.label }) }}>
        <Flex
          className={clsx([
            styles.wrapper,
            {
              [styles.wrapperNonPrimary]: !props.isPrimaryField
            }
          ])}
        >
          <Box
            sx={{ width: 'full', height: 'auto' }}
            className={clsx({ [styles.wrapperPrimary]: props.isPrimaryField })}
          >
            <Box
              className={clsx({
                [styles.parentComponentDisplay]: !subcomponent,
                [styles.subcomponentDisplay]: subcomponent
              })}
            >
              <Autocomplete
                {...autoCompleteProps}
                open={open}
                onChange={handleChange}
                style={{ border: 'none' }}
              />
            </Box>
            <Box className={styles.subcomponentWrapper} sx={{ marginTop: props.isPrimaryField ? 1 : '-2' }}>
              {subcomponent && renderForm([subcomponent])}
            </Box>
          </Box>
          <Flex direction="column" justify={'center'}>
            <Box
              className={props.isPrimaryField ? styles.icon.primaryField : styles.icon.nonPrimaryField}
              onClick={handleIconClick}
            />
          </Flex>
        </Flex>
      </FieldWrapper>
    </Box>
  );
};
