import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

export const STANDARD_DATE_CELL_RENDERER = 'standardDateRenderer';

/**
 * standard date renderer
 */
export const standardDateRenderer: React.FC<ICellRendererParams> = ({ value }) => {
  return <span>{value ? new Date(value).toLocaleDateString() : null}</span>; // CT: TODO: resolve time zone issue
};

export const STANDARD_DATE_TIME_CELL_RENDERER = 'standardDateTimeRenderer';

/**
 * standard date-time renderer
 */
export const standardDateTimeRenderer: React.FC<ICellRendererParams> = ({ value }) => {
  return <span>{value ? new Date(value).toLocaleString() : null}</span>;
};
