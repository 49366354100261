import React from 'react';
import { Switch as SwitchComponent, Tooltip } from '@oms/shared-frontend/ui-design-system';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import type { FieldProps, ICommonSwitchField } from '../../types';
import { useFieldApi } from '../../helpers';
import isBoolean from 'lodash/isBoolean';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';

export interface ISwitchField<TValidate = Validator>
  extends ICommonSwitchField<typeof FORM_COMPONENT_TYPE.SWITCH, boolean, TValidate> {}

export const Switch: React.FC<FieldProps<ISwitchField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onBlur, onChange, onFocus, value, checked },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    requiredFieldIndicatorStyle,
    isReadOnly,
    isInvalid,
    hideFormControls,
    type,
    tooltipText,
    sx = {},
    ...rest
  } = useFieldApi<ISwitchField>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  // Combine field/input props
  const fieldProps = {
    checked: isBoolean(checked) ? checked : Boolean(value),
    onBlur,
    onChange,
    onFocus,
    value,
    hidden: isVisible === false,
    isDisabled,
    type,
    ...rest,
    name
  };

  // Combine field wrapper props
  const fieldWrapperProps = {
    meta,
    label,
    isReadOnly,
    isRequired,
    isDisabled,
    isInvalid,
    requiredFieldIndicatorStyle,
    helperText,
    hideFormControls
  };

  const switchComp = (
    <SwitchComponent {...fieldProps} sx={{ ...sx, paddingRight: 2 }}>
      {label}
    </SwitchComponent>
  );

  return (
    <FieldWrapper {...fieldWrapperProps}>
      {tooltipText ? <Tooltip label={tooltipText}>{switchComp}</Tooltip> : switchComp}
    </FieldWrapper>
  );
};
