import type { PopoverTooltipComponentProps } from './tooltip/popover.tooltip';

export const POPOVER = {
  TOOLTIP: 'TOOLTIP',
  DISPLAY_GRID: 'DISPLAY_GRID',
  AUTOCOMPLETE: 'AUTOCOMPLETE'
} as const;

export type PopoverKey = keyof typeof POPOVER;

export type PopoverProps = {
  [POPOVER.TOOLTIP]: PopoverTooltipComponentProps;
};
