import { PropsWithChildren, useCallback } from 'react';
import * as ToggleGroupPrimitives from '@radix-ui/react-toggle-group';
import {
  ToggleGroupSingleProps,
  ToggleGroupMultipleProps,
  ToggleGroupItemProps as RadixToggleGroupItemProps
} from '@radix-ui/react-toggle-group';
import { ButtonProps, Button } from '../button/button';
import { ButtonGroupProps, ButtonGroup } from '../button/button-group';

export type SingleToggleGroupProps = {
  buttonGroupProps?: ButtonGroupProps;
  isDisabled?: boolean;
} & Omit<ToggleGroupSingleProps, 'type' | 'disabled'>;

export const SingleToggleGroup: React.FC<PropsWithChildren<SingleToggleGroupProps>> = ({
  children,
  value,
  defaultValue,
  onValueChange,
  isDisabled,
  rovingFocus,
  orientation,
  dir,
  loop,
  buttonGroupProps = {}
}) => {
  const onValueChangeCb = useCallback(
    (value: string) => {
      if (value && onValueChange) onValueChange(value);
    },
    [onValueChange]
  );
  return (
    <ToggleGroupPrimitives.Root
      type="single"
      value={value}
      defaultValue={defaultValue}
      onValueChange={onValueChangeCb}
      disabled={isDisabled}
      rovingFocus={rovingFocus}
      orientation={orientation}
      dir={dir}
      loop={loop}
    >
      <ButtonGroup isDisabled={isDisabled} {...buttonGroupProps}>
        {children}
      </ButtonGroup>
    </ToggleGroupPrimitives.Root>
  );
};

export type MultiToggleGroupProps = {
  buttonGroupProps?: ButtonGroupProps;
  isDisabled?: boolean;
} & Omit<ToggleGroupMultipleProps, 'type' | 'disabled'>;

export const MultiToggleGroup: React.FC<PropsWithChildren<MultiToggleGroupProps>> = ({
  children,
  value,
  defaultValue,
  onValueChange,
  isDisabled,
  rovingFocus,
  orientation,
  dir,
  loop,
  buttonGroupProps = {}
}) => {
  const onValueChangeCb = useCallback(
    (value: string[]) => {
      if (value && onValueChange) onValueChange(value);
    },
    [onValueChange]
  );

  return (
    <ToggleGroupPrimitives.Root
      type="multiple"
      value={value}
      defaultValue={defaultValue}
      onValueChange={onValueChangeCb}
      disabled={isDisabled}
      rovingFocus={rovingFocus}
      orientation={orientation}
      dir={dir}
      loop={loop}
    >
      <ButtonGroup {...buttonGroupProps}>{children}</ButtonGroup>
    </ToggleGroupPrimitives.Root>
  );
};

export type ToggleGroupItemProps = {
  buttonProps?: ButtonProps;
  isDisabled?: boolean;
  value?: string | number;
} & Omit<RadixToggleGroupItemProps, 'value'>;

export const ToggleGroupItem: React.FC<PropsWithChildren<ToggleGroupItemProps>> = ({
  value,
  defaultValue,
  isDisabled,
  dir,
  children,
  buttonProps = {}
}) => (
  <ToggleGroupPrimitives.Item
    asChild
    value={value as string}
    defaultValue={defaultValue}
    disabled={isDisabled}
    dir={dir}
  >
    <Button {...buttonProps} isDisabled={isDisabled}>
      {children}
    </Button>
  </ToggleGroupPrimitives.Item>
);
