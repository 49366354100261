import { OrderType } from '@oms/generated/frontend';
import type {
  Maybe,
  OrderSideType,
  VisibleInvestorOrderInfoWithAllocationsFragment,
  VisibleTradingOrderInfoWithAllocationsFragment,
  VisibleTradingOrderInfoFragment,
  TradeInfoFragment,
  OrderSide
} from '@oms/generated/frontend';
import { zOrderTitleDetailsSchema, zTradeDescriptionSchema } from '../../zod/trading.zod.schemas';

type OrderTitleDetails = {
  sideType: Maybe<OrderSideType>;
  quantity: Maybe<number>;
  symbol: Maybe<string>;
  price: Maybe<number | string>;
  account?: Maybe<string>;
};

export const getOrderDialogTitle = (
  order:
    | VisibleInvestorOrderInfoWithAllocationsFragment
    | VisibleTradingOrderInfoWithAllocationsFragment
    | VisibleTradingOrderInfoFragment
) => {
  const id = order.id;
  const titleDetails: OrderTitleDetails = {
    sideType: order?.sideType,
    symbol: order?.instrument?.mappings?.displayCode,
    quantity: order?.quantity,
    price: order?.orderType === OrderType.Market ? OrderType.Market : order?.limitPrice
  };

  let title = '';
  if (order.__typename?.includes('InvestorOrder')) {
    title += 'Investor Order - ';
  } else if (order.__typename?.includes('TradingOrder')) {
    title += 'Trading Order - ';
  }

  const result = zOrderTitleDetailsSchema.safeParse(titleDetails);
  if (result.success) {
    const { sideType, quantity, symbol, price } = result.data;
    title += `${sideType} ${quantity} ${symbol} @ ${price}`;
  } else if (id) {
    title += `${id}`;
  } else {
    title += 'Unknown';
  }
  return title;
};

type TradeDescriptionDetails = {
  side: Maybe<OrderSide>;
  quantity: Maybe<number>;
  symbol: Maybe<string>;
  price: Maybe<number | string>;
  account?: Maybe<string>;
};

export const getTradeDescription = (trade: TradeInfoFragment) => {
  const id = trade.id;
  const titleDetails: TradeDescriptionDetails = {
    side: trade?.side,
    symbol: trade?.instrument?.mappings?.displayCode,
    quantity: trade?.tradeQuantity,
    price: trade?.tradePrice
  };

  let description = '';

  const result = zTradeDescriptionSchema.safeParse(titleDetails);
  if (result.success) {
    const { side, quantity, symbol, price } = result.data;
    description += `${side} ${quantity} ${symbol} @ ${price}`;
  } else if (id) {
    description += `${id}`;
  } else {
    description += 'Unknown';
  }
  return description;
};
