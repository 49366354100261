import {
  ActorOperationOptions,
  ActorSchemaBuilder,
  COMMON_PLATFORM_NAME,
  CreateActorDefinition,
  internalWorkspace
} from '../../../core';
import {
  WindowContext,
  WindowOperations,
  COMMON_ACTOR_TYPE,
  WinPositionOptions,
  WinSizeOptions,
  UserAttentionType as WinUserAttentionType,
  isDefined,
  CommonWindowActorSchemaOptions,
  throttle,
  ClickEvent,
  WindowResizeOrigin,
  WindowResizeDir,
  resizeAroundOrigin,
  ensureInt
} from '../../common';
import { Event, UnlistenFn, listen } from '@tauri-apps/api/event';
import {
  appWindow,
  CloseRequestedEvent,
  getAll,
  LogicalPosition,
  LogicalSize,
  PhysicalPosition,
  PhysicalSize,
  ScaleFactorChanged,
  UserAttentionType,
  WebviewWindow,
  WindowOptions
} from '@tauri-apps/api/window';
import { maybeUseQuickWindowWebview } from './plugins/quick-windows.plugin.tauri';
import { centerInActiveMonitor, getMousePosition } from './window.actor.tauri.cargo';
import { EVENT_TYPE, emitCustomActorEvent } from '../../../core/events';
import {
  createTauriWindow,
  getTauriMonitorIndex,
  syncContextToTauriWindow,
  syncTauriWindowToContext
} from './window.actor.tauri.util';

export const TAURI_WINDOW_ACTOR_NAME = 'tauri-window';

export const TauriWindowActorSchemaBuilder = ActorSchemaBuilder.create<
  WindowContext,
  WindowOperations,
  any,
  CommonWindowActorSchemaOptions
>({
  name: TAURI_WINDOW_ACTOR_NAME,
  type: COMMON_ACTOR_TYPE.WINDOW,
  supportedPlatforms: [COMMON_PLATFORM_NAME.TAURI],
  isWindowRootable: true,
  initialContext: ({ initialContext, schema }) => {
    const defaultContext = schema.meta?.defaultContext || {};
    const final: WindowContext = {
      width: 500,
      height: 500,
      title: 'Window',
      isMaximized: false,
      isMinimized: false,
      isVisible: true,
      isFocused: undefined,
      isDecorated: true,
      isFullscreen: false,
      scaleFactor: 1,
      isClosable: true,
      isMaximizable: true,
      isMinimizable: true,
      isResizable: true,
      isPinnable: false,
      skipTaskbar: false,
      alwaysOnTop: false,
      isPinned: false,
      transparent: false,
      requestingUserAttention: null,
      currentMonitorIndex: 0, // Start at the first monitor and set later to improve performance
      meta: {},
      initiallyCentered: true,
      initiallyUseLogicalPixels: true,
      ...defaultContext,
      ...(initialContext || {}),
      tauriLabel: appWindow.label
    };

    return final;
  },
  render: async (url, options, workspace) => {
    const existingWebview = await maybeUseQuickWindowWebview(workspace, options);

    if (existingWebview) {
      return;
    }

    await createTauriWindowFromDef(url, options);
  },
  sync: async ({ contextDelta, isFocus }) => {
    // Sync is ONLY to focus rather than to update the window
    if (isFocus) {
      appWindow.setFocus().catch(console.error);
      return;
    }

    await syncTauriWindowToContext(appWindow, contextDelta);
  },
  destroy: async () => {
    appWindow.close().catch(console.error);
  },
  confirmDestroy: async (destroyFn, ctx) => {
    if (!ctx.tauriLabel) {
      return destroyFn;
    }
    return new Promise<void>((resolve, reject) => {
      let unsub: UnlistenFn | null = null;
      let timeout: NodeJS.Timeout | null = null;
      async function init() {
        unsub = await listen('tauri://destroyed', (e) => {
          if (e.windowLabel === ctx.tauriLabel) {
            unsub?.();
            if (timeout) {
              clearTimeout(timeout);
            }
            destroyFn.then(resolve).catch(reject);
          }
        });
      }

      timeout = setTimeout(() => {
        if (unsub) {
          unsub();
        }
        reject(new Error('Timeout waiting for destroy confirmation'));
      }, 3_000);

      init().catch(reject);
    });
  },
  operations: ({ updateContext, destroy, getContext, operations }) => ({
    setTitle: async (title: string) => {
      await appWindow.setTitle(title);
      await updateContext({ title });
    },
    setPinned: async (isPinned: boolean, ops?: ActorOperationOptions) => {
      await updateContext({ isPinned }, ops);
    },
    setPosition: async ({ x, y, useLogicalPixels }: WinPositionOptions) => {
      const position =
        useLogicalPixels === true
          ? new LogicalPosition(ensureInt(x), ensureInt(y))
          : new PhysicalPosition(ensureInt(x), ensureInt(y));

      await appWindow.setPosition(position);
    },
    setSize: async ({ width, height, useLogicalPixels }: WinSizeOptions) => {
      const size =
        useLogicalPixels === true
          ? new LogicalSize(ensureInt(width), ensureInt(height))
          : new PhysicalSize(ensureInt(width), ensureInt(height));

      await appWindow.setSize(size);
    },
    setSizeAndPosition: async (size: WinSizeOptions, position: WinPositionOptions) => {
      await operations.setSize(size);
      await operations.setPosition(position);
    },
    resizeAroundOrigin: async (
      newSize: WinSizeOptions,
      origin: WindowResizeOrigin,
      direction?: WindowResizeDir,
      ops?: ActorOperationOptions
    ) => {
      const [currentSize, currentPos, scaleFactor] = await Promise.all([
        appWindow.innerSize(),
        appWindow.innerPosition(),
        appWindow.scaleFactor()
      ]);

      const sizeAdjustedForScale = newSize.useLogicalPixels
        ? {
            width: newSize.width * scaleFactor,
            height: newSize.height * scaleFactor
          }
        : newSize;

      const ctx = resizeAroundOrigin(currentPos, currentSize, sizeAdjustedForScale, origin, direction);

      if (isDefined(ctx.x) && isDefined(ctx.y)) {
        await operations.setPosition(
          {
            x: ctx.x,
            y: ctx.y
          },
          ops
        );
      }

      if (isDefined(ctx.width) && isDefined(ctx.height)) {
        await operations.setSize(
          {
            width: ctx.width,
            height: ctx.height
          },
          ops
        );
      }
    },
    center: async () => {
      await appWindow.center();
    },
    centerInActiveMonitor: async () => {
      await centerInActiveMonitor(appWindow);
    },
    close: async () => {
      const ctx = getContext();
      if (ctx.isClosable === false || ctx.isPinned === true) {
        return;
      }
      await destroy();
    },
    destroy: async () => {
      await destroy();
    },
    focus: async () => {
      await appWindow.setFocus();
    },
    hide: async (ops?: ActorOperationOptions) => {
      await appWindow.hide();
      await updateContext({ isVisible: false }, ops);
    },
    toggleMaximize: async () => {
      await appWindow.toggleMaximize();
    },
    maximize: async () => {
      await appWindow.maximize();
    },
    minimize: async () => {
      await appWindow.minimize();
    },
    unmaximize: async () => {
      await appWindow.unmaximize();
    },
    unminimize: async () => {
      await appWindow.unminimize();
    },
    show: async (ops?: ActorOperationOptions) => {
      await appWindow.show();
      await updateContext({ isVisible: true }, ops);
    },
    setAlwaysOnTop: async (alwaysOnTop: boolean, ops?: ActorOperationOptions) => {
      await appWindow.setAlwaysOnTop(alwaysOnTop);
      await updateContext({ alwaysOnTop }, ops);
    },
    setDecorated: async (isDecorated: boolean) => {
      await appWindow.setDecorations(isDecorated);
      await updateContext({ isDecorated });
    },
    setFullscreen: async (isFullscreen: boolean, ops?: ActorOperationOptions) => {
      await appWindow.setFullscreen(isFullscreen);
      await updateContext({ isFullscreen }, ops);
    },
    setMaximizable: async (isMaximizable: boolean) => {
      await appWindow.setMaximizable(isMaximizable);
      await updateContext({ isMaximizable });
    },
    setMinimizable: async (isMinimizable: boolean) => {
      await appWindow.setMinimizable(isMinimizable);
      await updateContext({ isMinimizable });
    },
    setResizable: async (isResizable: boolean) => {
      await appWindow.setResizable(isResizable);
      await updateContext({ isResizable });
    },
    setSkipTaskbar: async (skipTaskbar: boolean) => {
      await appWindow.setSkipTaskbar(skipTaskbar);
      await updateContext({ skipTaskbar });
    },
    setMaxSize: async ({ width, height, useLogicalPixels }: WinSizeOptions) => {
      const size =
        useLogicalPixels === true
          ? new LogicalSize(ensureInt(width), ensureInt(height))
          : new PhysicalSize(ensureInt(width), ensureInt(height));

      await appWindow.setMaxSize(size);
      await updateContext({
        maxWidth: width,
        maxHeight: height
      });
    },
    setMinSize: async ({ width, height, useLogicalPixels }: WinSizeOptions) => {
      const size =
        useLogicalPixels === true
          ? new LogicalSize(ensureInt(width), ensureInt(height))
          : new PhysicalSize(ensureInt(width), ensureInt(height));

      await appWindow.setMinSize(size);
      await updateContext({
        minWidth: width,
        minHeight: height
      });
    },
    requestUserAttention: async (type: WinUserAttentionType) => {
      await updateContext({ requestingUserAttention: type });
      await appWindow.requestUserAttention(
        type === 'info' ? UserAttentionType.Informational : UserAttentionType.Critical
      );
    },
    focusAll: async () => {
      const all = getAll();
      for (const window of all) {
        window.setFocus().catch(console.error);
      }
    },
    setMeta: async (meta) => {
      await updateContext({ meta });
    },
    updateMeta: async (meta) => {
      const nextMeta = { ...getContext().meta, ...meta };
      await updateContext({ meta: nextMeta });
    },
    getInnerPosition: async () => {
      const [position, scaleFactor] = await Promise.all([appWindow.innerPosition(), appWindow.scaleFactor()]);
      return {
        x: position.x,
        y: position.y,
        scaleFactor
      };
    }
  }),
  events: async ({ updateContext, getContext, destroy, getDefinition, workspace }) => {
    const eventsTransport = internalWorkspace(workspace)._eventsTransport;
    const emitClickOutside = async () => {
      if (!getContext().isFocused || !getContext().isVisible) {
        return;
      }

      const mousePos = await getMousePosition();

      emitCustomActorEvent<ClickEvent>(eventsTransport, getDefinition(), {
        type: 'click',
        payload: {
          x: mousePos.x,
          y: mousePos.y,
          type: 'outside'
        }
      }).catch(console.error);
    };

    const handler = {
      click: async (e: MouseEvent) => {
        if (
          e.target &&
          'getAttribute' in e.target &&
          (e.target as HTMLElement).getAttribute('data-suppress-event') === 'click--inside'
        ) {
          return;
        }

        const scaleFactor = await appWindow.scaleFactor();
        const innerMousePos = {
          x: e.clientX * scaleFactor,
          y: e.clientY * scaleFactor
        };

        const { x: _x, y: _y } = getContext();
        let windowX = _x || 0;
        let windowY = _y || 0;

        if (!isDefined(_x) || !isDefined(_y)) {
          console.warn('Click outside event not emitted because window position is not defined');
          const position = await appWindow.innerPosition();
          windowX = position.x;
          windowY = position.y;
          return;
        }

        const mousePos = {
          x: windowX + innerMousePos.x,
          y: windowY + innerMousePos.y
        };

        emitCustomActorEvent<ClickEvent>(eventsTransport, getDefinition(), {
          type: 'click',
          payload: {
            ...mousePos,
            type: 'inside'
          }
        }).catch(console.error);
      },
      beforeunload: () => {
        const def = getDefinition();
        eventsTransport
          .emit(EVENT_TYPE.ACTOR_BEFORE_DESTROY, {
            id: def.id,
            type: def.type,
            parentId: def.parentId
          })
          .catch(console.error);
      },
      onCloseRequested: async (e: CloseRequestedEvent) => {
        e.preventDefault();
        destroy().catch(console.error);
      },
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onResized: throttle(async ({ payload }: Event<PhysicalSize>) => {
        await emitClickOutside();
        const isMaximized = await appWindow.isMaximized();
        const isMinimized = await appWindow.isMinimized();
        const currentMonitorIndex = await getTauriMonitorIndex();

        await updateContext(
          {
            width: payload.width,
            height: payload.height,
            isMaximized,
            isMinimized,
            currentMonitorIndex
          },
          { isUserInteraction: true }
        );
      }, 100),
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onMoved: throttle(async ({ payload }: Event<PhysicalPosition>) => {
        await emitClickOutside();
        const currentMonitorIndex = await getTauriMonitorIndex();
        await updateContext(
          {
            x: payload.x,
            y: payload.y,
            currentMonitorIndex
          },
          { isUserInteraction: true }
        );
      }, 100),
      onFocusChange: async (e: Event<boolean>) => {
        if (e.payload === false) {
          await emitClickOutside();
        }

        await updateContext(
          {
            isFocused: e.payload,
            requestingUserAttention: e.payload ? null : getContext().requestingUserAttention
          },
          { isUserInteraction: true }
        );
      },
      onScaleFactorChange: async (e: Event<ScaleFactorChanged>) => {
        await updateContext(
          {
            scaleFactor: e.payload.scaleFactor
            // TODO: Size too?
          },
          { isUserInteraction: true }
        );
      }
    } as const;

    window.addEventListener('beforeunload', handler.beforeunload);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    document.addEventListener('click', handler.click);

    const unsubPromises: Promise<UnlistenFn>[] = [
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      appWindow.onCloseRequested(handler.onCloseRequested),
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      appWindow.onResized(handler.onResized),
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      appWindow.onMoved(handler.onMoved),
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      appWindow.onFocusChanged(handler.onFocusChange),
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      appWindow.onScaleChanged(handler.onScaleFactorChange)
    ];

    const unsubEvents = await Promise.all(unsubPromises);

    // TODO: Without timeout, it creates a race condition with the quick window plugin on the isVisible property
    const timeout = setTimeout(() => {
      syncContextToTauriWindow(getContext(), updateContext).catch(console.error);
    }, 250);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      document.removeEventListener('click', handler.click);
      window.removeEventListener('beforeunload', handler.beforeunload);
      unsubEvents.forEach((unsub) => unsub());
    };
  }
});

export type TauriWindowActorSchema = typeof TauriWindowActorSchemaBuilder.schema;

async function createTauriWindowFromDef(
  url: string,
  options: CreateActorDefinition<WindowContext>
): Promise<WebviewWindow> {
  const { id, context } = options;
  let center = context?.initiallyCentered === false ? false : true;

  if (isDefined(context?.x) && isDefined(context?.y)) {
    center = false;
  }

  const windowOptions: WindowOptions = {
    url,
    alwaysOnTop: !!context?.alwaysOnTop,
    title: context?.title,
    width: context?.width,
    height: context?.height,
    x: context?.x,
    y: context?.y,
    resizable: context?.isResizable,
    maximizable: context?.isMaximizable,
    minimizable: context?.isMinimizable,
    fullscreen: context?.isFullscreen,
    transparent: context?.transparent,
    visible: context?.isVisible,
    focus: context?.isFocused === undefined ? true : context.isFocused,
    center,
    closable: context?.isClosable,
    decorations: context?.isDecorated,
    maxHeight: context?.maxHeight,
    minHeight: context?.minHeight,
    maxWidth: context?.maxWidth,
    minWidth: context?.minWidth,
    maximized: context?.isMaximized,
    skipTaskbar: context?.skipTaskbar
  };

  return createTauriWindow(id, windowOptions, {
    isMinimized: context?.isMaximized,
    initiallyUseLogicalPixels: context?.initiallyUseLogicalPixels
  });
}
