import { type FormatValue, type FormatterFunction, type FormatterFunctionParams } from './types';

export const getNumberOfDecimalPlaces = (
  number: FormatValue,
  trimTrailingZeros = true,
  decimalDelimiter = '.'
) => {
  const decimals = number.toString().split(decimalDelimiter)?.[1];

  if (decimals) {
    return trimTrailingZeros ? decimals.toString().replace(/0+$/, '').length : decimals.toString().length;
  }

  return 0;
};

export const withDelimiters = (getFormattedValue: FormatterFunction): FormatterFunction => {
  return ({
    value,
    thousandDelimiter = ',',
    decimalDelimiter = '.',
    hasDelimiters,
    trimTrailingZeros,
    decimalPlaces,
    negativeOneAsEmpty
  }: FormatterFunctionParams) => {
    const val = !hasDelimiters ? value.toString().replace('.', decimalDelimiter) : value;

    const formattedValue = getFormattedValue({
      value: val,
      thousandDelimiter,
      decimalDelimiter,
      trimTrailingZeros,
      decimalPlaces,
      negativeOneAsEmpty
    });

    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(new RegExp('\\' + ',', 'g'), thousandDelimiter);

    return parts.join(decimalDelimiter);
  };
};
