import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { mapFixAtdlListOptions } from '../../utils';
import { Checkbox, Flex, FormControl, FormErrorMessage } from '@oms/shared-frontend/ui-design-system';
import { Orientation } from '@valstro/fixatdl-core';
import { useFieldApi } from '@oms/frontend-foundation';
import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import type { CheckboxListProps } from '../../fixatdl-form.types';
import type { fixatdlFormComponentTypes } from '../fixatdl-component.mappers';

export const CheckboxList: FC<FieldProps<CheckboxListProps>> = React.memo(
  ({ initialValue, options = [], orientation, ...props }) => {
    const {
      meta,
      input,
      isReadOnly,
      isRequired,
      isDisabled: _isDisabled,
      forceIsDisabled,
      isInvalid
    } = useFieldApi<ICommonField<fixatdlFormComponentTypes.CHECKBOX_LIST, string[]>>(props);

    const [values, setValues] = useState(input.value || initialValue || []);

    const onChangeRef = useRef(input.onChange);
    onChangeRef.current = input.onChange;

    useEffect(() => {
      if (!input.value) {
        setValues(initialValue);
      }
    }, [initialValue, input.value]);

    useEffect(() => {
      onChangeRef.current(values);
    }, [values]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, value } = event.target;
        const updatedValues = checked ? [...values, value] : values.filter((v: string) => v !== value);
        setValues(updatedValues);
      },
      [values]
    );

    const isDisabled = forceIsDisabled || _isDisabled;
    const error = meta.touched && meta.error ? meta.error : '';
    const extraProps = { isReadOnly, isRequired, isDisabled, isInvalid };

    return (
      <FormControl {...extraProps}>
        <Flex direction={orientation === Orientation.HORIZONTAL ? 'row' : 'column'}>
          {mapFixAtdlListOptions(options).map(({ value, label }) => (
            <Checkbox
              {...props}
              value={value}
              checked={values.includes(value)}
              onChange={handleChange}
              sx={{ ...(orientation === Orientation.HORIZONTAL ? { marginRight: 3 } : { marginBottom: 3 }) }}
              key={value}
            >
              {label}
            </Checkbox>
          ))}
        </Flex>
        {error && <FormErrorMessage>{error}&nbsp;</FormErrorMessage>}
      </FormControl>
    );
  },
  isEqual
);
