import { Howl } from 'howler';
import { NotificationResourceTemplatesName } from '@app/widgets/trading/notifications/notifications.contracts';
import positive1 from '/sounds/positive-1.mp3';
import positive2 from '/sounds/positive-2.mp3';
import negative from '/sounds/negative.mp3';

const positive1Sound = new Howl({ src: [positive1], preload: true });
const positive2Sound = new Howl({ src: [positive2], preload: true });
const negativeSound = new Howl({ src: [negative], preload: true });

export const notificationSoundLibrary: ReadonlyMap<NotificationResourceTemplatesName, Howl> = new Map([
  ['IO Cancel Request', positive1Sound],
  ['IO Modify Request', positive1Sound],
  ['IO New', positive2Sound],
  ['IO Failed', negativeSound],
  ['IO Failed - Modify', negativeSound],
  ['Capital Threshold Warning', negativeSound],
  ['Failed Trade', negativeSound],
  ['Failed Trade - Modify', negativeSound],
  // Execution New
  // Execution Cancelled
  // Execution Modified
  // Don’t Know Execution
  // IO Fully Filled
  // Trade Modified
  // Trade Cancelled
  // TO Fully Filled
  // TO Should have Ack'd
  ['Unmatched Trade Report', negativeSound],
  ['Trade Report Failed (rejected)', negativeSound],
  ['TO Create Rejected', negativeSound],
  ['TO Modify Rejected', negativeSound],
  ['TO Cancel Rejected', negativeSound],
  ['TO Unsolicited Cancelled - Partial Fills', negativeSound],
  ['Trade Request (OTC)', positive2Sound]
  // Trading Along
]);
