import { useEffect, useState } from 'react';
import { Logger } from '@oms/shared/util';
import type { Optional } from '@oms/shared/util-types';
import { RegNmsApplicability } from '@oms/generated/frontend';
import type { SipData } from '@oms/shared/regnms';
import { Level2WebSocketSubject } from '../../marketdata/level2-websocket.subjects';
const logger = Logger.labeled('useLevel2SipQuotes');

export const useLevel2SipQuotes = (
  instrumentId: Optional<string>,
  regNmsApplicability: RegNmsApplicability,
  shouldConnectToSidecar: boolean
): Optional<SipData> => {
  const [level2Data, setLevel2Data] = useState<Optional<SipData>>(undefined);

  useEffect(() => {
    if (!instrumentId || regNmsApplicability !== RegNmsApplicability.Applicable || !shouldConnectToSidecar) {
      return;
    }

    const webSocketSubject$ = new Level2WebSocketSubject('sip');
    const obs$ = webSocketSubject$.multiplex(
      () => instrumentId,
      () => '',
      () => true
    );

    const subscription = obs$.subscribe({
      next: (value) => {
        setLevel2Data(value);
      },
      error: logger.error
    });

    return () => {
      subscription.unsubscribe();
      webSocketSubject$.complete();
    };
  }, [instrumentId, regNmsApplicability]);

  return level2Data;
};
