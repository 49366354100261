import { GlobalChannel, Unsubscribe, createGlobalChannel, OnMessageHandler } from '@valstro/remote-link';
import {
  ActorDefinition,
  ActorSnapshotDefinition,
  ActorStateType,
  ChildActorDefinition,
  CreateActorDefinition
} from './actor.internal';
import { AnyRecord, isPromiseLike } from './utils';
import { logger } from './logger';

export type StandardActorEvent = { type: string; payload: any };

export const EVENT_TYPE = {
  ACTOR_STARTED: 'actor/started',
  ACTOR_FAILED: 'actor/failed',
  ACTOR_CREATED: 'actor/created',
  ACTOR_BEFORE_DESTROY: 'actor/before-destroy',
  ACTOR_DESTROYED: 'actor/destroyed',
  ACTOR_PARENT_CHANGED: 'actor/parent-changed',
  ACTOR_OPERATION_PERFORMED: 'actor/operation/performed',
  ACTOR_CONTEXT_CHANGED: 'actor/context/changed',
  ACTOR_CHILDREN_CHANGED: 'actor/children/changed',
  ACTOR_STATE_CHANGED: 'actor/state/changed',
  ACTOR_PING: 'actor/ping',
  ACTOR_PONG: 'actor/pong',
  ACTOR_CUSTOM_EVENT: 'actor/custom-event',
  ACTOR_TAKING_SNAPSHOT: 'actor/taking-snapshot',
  ACTOR_TAKING_SNAPSHOT_RESULT: 'actor/taking-snapshot-result',
  ACTOR_APPLYING_SNAPSHOT: 'actor/applying-snapshot',
  ACTOR_APPLYING_SNAPSHOT_RESULT: 'actor/applying-snapshot-result'
} as const;

export type EventType = (typeof EVENT_TYPE)[keyof typeof EVENT_TYPE];

export type ActorStartedEvent = {
  type: typeof EVENT_TYPE.ACTOR_STARTED;
  payload: CreateActorDefinition;
};

export type ActorFailedEvent = {
  type: typeof EVENT_TYPE.ACTOR_FAILED;
  payload: {
    definition: CreateActorDefinition;
    failedReason: string;
  };
};

export type ActorCreatedEvent = {
  type: typeof EVENT_TYPE.ACTOR_CREATED;
  payload: ActorDefinition & { isUserCreated: boolean };
};

export type ActorBeforeDestroyEvent = {
  type: typeof EVENT_TYPE.ACTOR_BEFORE_DESTROY;
  payload: {
    id: string;
    type: string;
    parentId?: string;
  };
};

export type ActorParentChangedEvent = {
  type: typeof EVENT_TYPE.ACTOR_PARENT_CHANGED;
  payload: {
    fromParentId: string;
    toParentId: string;
    id: string;
  };
};

export type ActorDestroyedEvent = {
  type: typeof EVENT_TYPE.ACTOR_DESTROYED;
  payload: {
    id: string;
    type: string;
    parentId?: string;
  };
};

export type ActorOperationPerformed = {
  type: typeof EVENT_TYPE.ACTOR_OPERATION_PERFORMED;
  payload: {
    id: string;
    parentId?: string;
    type: string;
    definition: ActorDefinition;
    operationName: string;
    operationArgs: any[];
    operationResult: any;
  };
};

export type ActorContextChanged = {
  type: typeof EVENT_TYPE.ACTOR_CONTEXT_CHANGED;
  payload: {
    id: string;
    parentId?: string;
    type: string;
    definition: ActorDefinition;
    context: AnyRecord;
  };
};

export type ActorStateChanged = {
  type: typeof EVENT_TYPE.ACTOR_STATE_CHANGED;
  payload: {
    id: string;
    parentId?: string;
    type: string;
    state: ActorStateType;
    failedMessage?: string | null;
  };
};

export type ActorChildrenChanged = {
  type: typeof EVENT_TYPE.ACTOR_CHILDREN_CHANGED;
  payload: {
    id: string;
    parentId?: string;
    type: string;
    children: ChildActorDefinition[];
  };
};

export type ActorPingEvent = {
  type: typeof EVENT_TYPE.ACTOR_PING;
  payload: {
    pingId: string;
  };
};

export type ActorPongEvent = {
  type: typeof EVENT_TYPE.ACTOR_PONG;
  payload: {
    pingId: string;
    state: Exclude<ActorStateType, 'destroy'>;
    actor: ActorDefinition;
  };
};

export type ActorCustomEvent<TEvent extends StandardActorEvent = StandardActorEvent> = {
  type: typeof EVENT_TYPE.ACTOR_CUSTOM_EVENT;
  payload: {
    event: TEvent;
    actor: ActorDefinition;
  };
};

export type ActorTakingSnapshotEvent = {
  type: typeof EVENT_TYPE.ACTOR_TAKING_SNAPSHOT;
  payload: {
    id: string;
    parentId?: string;
    type: string;
  };
};

export type ActorTakingSnapshotResultEvent = {
  type: typeof EVENT_TYPE.ACTOR_TAKING_SNAPSHOT_RESULT;
  payload:
    | {
        isSuccessful: true;
        id: string;
        parentId?: string;
        type: string;
        snapshot: ActorSnapshotDefinition<any>;
      }
    | {
        isSuccessful: false;
        id: string;
        parentId?: string;
        type: string;
        error: string;
      };
};

export type ActorApplyingSnapshotEvent = {
  type: typeof EVENT_TYPE.ACTOR_APPLYING_SNAPSHOT;
  payload: {
    id: string;
    parentId?: string;
    type: string;
  };
};

export type ActorApplyingSnapshotResultEvent = {
  type: typeof EVENT_TYPE.ACTOR_APPLYING_SNAPSHOT_RESULT;
  payload:
    | {
        isSuccessful: true;
        id: string;
        type: string;
        parentId?: string;
      }
    | {
        isSuccessful: false;
        id: string;
        type: string;
        parentId?: string;
        error: string;
      };
};

export type CoreEvents =
  | ActorStartedEvent
  | ActorFailedEvent
  | ActorCreatedEvent
  | ActorBeforeDestroyEvent
  | ActorDestroyedEvent
  | ActorParentChangedEvent
  | ActorOperationPerformed
  | ActorContextChanged
  | ActorStateChanged
  | ActorChildrenChanged
  | ActorCustomEvent
  | ActorPingEvent
  | ActorPongEvent
  | ActorTakingSnapshotEvent
  | ActorTakingSnapshotResultEvent
  | ActorApplyingSnapshotEvent
  | ActorApplyingSnapshotResultEvent;

export interface IEvent {
  type: string;
  payload?: any;
}

export type IEventType<E extends IEvent> = E['type'];
export type IExtractedEvent<E extends IEvent, K = E['type']> = Extract<E, { type: K }>;
export type IExtractEventPayload<E extends IEvent, K = E['type']> = 'payload' extends keyof IExtractedEvent<
  E,
  K
>
  ? IExtractedEvent<E, K>['payload']
  : undefined;

export class EventsTransport<E extends IEvent> {
  private _channel: GlobalChannel<E>;

  constructor(channelName: string) {
    this._channel = createGlobalChannel<E>(channelName);
  }

  public async emit<K extends IEventType<E>>(eventType: K, payload: IExtractEventPayload<E, K>) {
    try {
      await this._channel.postMessage(this._serializeEvent({ type: eventType, payload } as unknown as E));
    } catch (error) {
      logger.debug('[DEBUG] Failed to emit event', { eventType, payload });
      logger.error(error);
      throw error;
    }
  }

  public listen<K extends IEventType<E>>(
    eventType: K,
    cb: (payload: IExtractEventPayload<E, K>) => Promise<void> | void
  ): Unsubscribe {
    const handler: OnMessageHandler<E> = (e) => {
      if (e.type === eventType) {
        const result = cb(e?.payload as IExtractEventPayload<E, K>);
        if (isPromiseLike(result)) {
          result.catch((error) => {
            logger.error(error);
          });
        }
      }
    };

    this._channel.addEventListener('message', handler);

    return () => {
      this._channel.removeEventListener('message', handler);
    };
  }

  public listenAll(cb: (event: E) => Promise<void> | void): Unsubscribe {
    const handler: OnMessageHandler<E> = (e) => {
      const result = cb(e);
      if (isPromiseLike(result)) {
        result.catch((error) => {
          logger.error(error);
        });
      }
    };

    this._channel.addEventListener('message', handler);

    return () => {
      this._channel.removeEventListener('message', handler);
    };
  }

  public listenOnce<K extends IEventType<E>>(
    eventType: K,
    cb: (payload: IExtractEventPayload<E, K>) => Promise<void> | void
  ): Unsubscribe {
    const unsubscribe = this.listen(eventType, (payload) => {
      unsubscribe();
      const result = cb(payload);
      if (isPromiseLike(result)) {
        result.catch((error) => {
          logger.error(error);
        });
      }
    });

    return unsubscribe;
  }

  public async destroy() {
    await this._channel.close();
  }

  private _serializeEvent(event: E) {
    return JSON.parse(JSON.stringify(event)) as E;
  }
}

export function createEventsTransport<E extends IEvent>(channelName: string) {
  return new EventsTransport<E>(channelName);
}

export function emitCustomActorEvent<TEvent extends StandardActorEvent = StandardActorEvent>(
  eventsTransport: EventsTransport<CoreEvents>,
  actor: ActorDefinition,
  event: TEvent
) {
  const _event: ActorCustomEvent<TEvent> = {
    type: EVENT_TYPE.ACTOR_CUSTOM_EVENT,
    payload: {
      event,
      actor
    }
  };

  return eventsTransport.emit(EVENT_TYPE.ACTOR_CUSTOM_EVENT, _event.payload);
}

export function listenToCustomActorEvent<TEvent extends StandardActorEvent = StandardActorEvent>(
  eventsTransport: EventsTransport<CoreEvents>,
  eventType: TEvent['type'],
  listener: (event: ActorCustomEvent<TEvent>['payload']) => void
) {
  return eventsTransport.listen(EVENT_TYPE.ACTOR_CUSTOM_EVENT, (p) => {
    if (p.event.type === eventType) {
      listener(p as ActorCustomEvent<TEvent>['payload']);
    }
  });
}
