import { SideBarDef } from '@ag-grid-community/core';
import { ToolPanelBuilder } from './tool.panel.builder';
import { AnyRecord } from '@oms/frontend-foundation';

export class SidebarBuilder<TData extends AnyRecord> {
  private _sidebar: SideBarDef = {};

  public toolPanel(
    panel: (builder: ToolPanelBuilder<TData>) => ToolPanelBuilder<TData>
  ): SidebarBuilder<TData> {
    this._sidebar.toolPanels = this._sidebar.toolPanels || [];
    this._sidebar.toolPanels.push(panel(new ToolPanelBuilder<TData>()).build());
    return this;
  }

  public defaultPanel(panel: string): SidebarBuilder<TData> {
    this._sidebar.defaultToolPanel = panel;
    return this;
  }

  public hidden(hidden?: boolean): SidebarBuilder<TData> {
    this._sidebar.hiddenByDefault = hidden === undefined || hidden;
    return this;
  }

  public position(position: 'left' | 'right'): SidebarBuilder<TData> {
    this._sidebar.position = position;
    return this;
  }

  public useDefaults(): SidebarBuilder<TData> {
    this.position('right')
      .defaultPanel('')
      .toolPanel((t) => t.id('columns').label('Columns').icon('columns').component('agColumnsToolPanel'))
      .toolPanel((t) => t.id('filters').label('Filters').icon('filter').component('agFiltersToolPanel'));
    return this;
  }

  public build(): SideBarDef {
    return this._sidebar;
  }
}
