import { useOfflineRxData } from '@app/common/rxdb/rxdb.hooks';
import { ADDITIONAL_FIELDS_COLLECTION_NAME } from '@app/data-access/offline/collections/additional-fields.collection';
import type { AdditionalFieldsDocType } from '@app/data-access/offline/collections/additional-fields.collection';
import { useCallback, useMemo } from 'react';
import type { QueryConstructor } from 'rxdb-hooks-v5';

export const useAdditionalFieldsState = (formType: string) => {
  if (!formType) {
    throw new Error(`formType is required. Please add .type('my-form-type') to the form builder.`);
  }

  const queryConstructor: QueryConstructor<AdditionalFieldsDocType> = useCallback(
    (c) => c.findOne(formType),
    [formType]
  );

  const { collection, data = [] } = useOfflineRxData<AdditionalFieldsDocType>(
    ADDITIONAL_FIELDS_COLLECTION_NAME,
    queryConstructor
  );

  const updateFields = useCallback(
    (selectedFields: string[]) => {
      if (collection) {
        collection.upsert({ formType, selectedFields }).catch(console.error);
      }
    },
    [collection, formType]
  );

  const removeFields = useCallback(
    (fields: string[]) => {
      const selectedFields = data.length
        ? data[0].selectedFields.filter((field) => !fields.includes(field))
        : [];

      updateFields(selectedFields);
    },
    [data, updateFields]
  );

  const addFields = useCallback(
    (fields: string[]) => {
      const selectedFields = data.length
        ? Array.from(new Set([...data[0].selectedFields, ...fields]))
        : fields;

      updateFields(selectedFields);
    },
    [data, updateFields]
  );

  return useMemo(
    () => ({
      addFields,
      removeFields,
      selectedFields: data?.[0]?.selectedFields ? Array.from(data[0].selectedFields) : []
    }),
    [addFields, removeFields, data]
  );
};
