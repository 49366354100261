import * as React from 'react';
import * as styles from './visually-hidden.css';
import clsx from 'clsx';
import { __DEV__ } from '../../system/utils/assertion';

/**
 * VisuallyHidden
 *
 * Provides text for screen readers that is visually hidden.
 * It is the logical opposite of the `aria-hidden` attribute.
 */
const VisuallyHidden = React.forwardRef<any, any>(function VisuallyHidden(
  { as: Comp = 'span', className, ...props },
  ref
) {
  return <Comp ref={ref} className={clsx(styles.visuallyHidden, className)} {...props} />;
});

/**
 * @see Docs https://reach.tech/visually-hidden#visuallyhidden-props
 */
interface VisuallyHiddenProps {
  /**
   * @see Docs https://reach.tech/visually-hidden#visuallyhidden-children
   */
  children?: React.ReactNode;
}

if (__DEV__) {
  VisuallyHidden.displayName = 'VisuallyHidden';
}

export type { VisuallyHiddenProps };
export { VisuallyHidden };
