import type { AnyObject, FieldApi, FormOptions } from '@data-driven-forms/react-form-renderer';
import { orderSettingsFieldIndexes } from '../order-settings.util';

export const SET_PRICE_FROM_VALUE = 'setPriceFromValue';

export const setPriceFromValue =
  () => (props: AnyObject, fieldApi: FieldApi<any>, formOptions: FormOptions) => {
    const formValues = formOptions.getState().values;
    const { input } = fieldApi;

    const [profileIndex, rowIndex] = orderSettingsFieldIndexes(input.name);

    switch (rowIndex) {
      case 0: {
        formOptions.change(input.name, '0');
        return props;
      }

      default: {
        const prevPriceTo = formValues.orderSettings[profileIndex].rows[rowIndex - 1]?.priceTo;
        formOptions.change(input.name, prevPriceTo || 0);
        return props;
      }
    }
  };
