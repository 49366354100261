import { URL_PARAM } from './constants';

export type AnyRecord = Record<string, any>;

export type Maybe<T> = T | undefined | null;

export type Optional<T> = T | undefined;

export type MaybePromise<T> = T | Promise<T>;

export type StringKeys<T> = Extract<keyof T, string>;

export function asError(e: any) {
  return e instanceof Error ? e : new Error(e);
}

export function JSONParse<T>(value: string): T {
  try {
    return JSON.parse(value);
  } catch (e) {
    throw new Error(`Failed to parse JSON: ${asError(e).message}`);
  }
}

export function getDeactivatedWorkspaceURL() {
  const deactivatedURL = new URL(window.location.href);
  deactivatedURL.searchParams.set(URL_PARAM.WORKSPACE_DEACTIVATED, 'true');
  return deactivatedURL;
}

export function isDeactivatedWorkspaceURL() {
  const deactivatedURL = new URL(window.location.href);
  return deactivatedURL.searchParams.has(URL_PARAM.WORKSPACE_DEACTIVATED);
}

export const isPromiseLike = (value: any): value is PromiseLike<any> =>
  value && typeof value.then === 'function';

/**
 * Checks if the page was accessed by reload
 */
export const pageAccessedByReload =
  (window &&
    window.performance &&
    window.performance.navigation &&
    window.performance.navigation.type === 1) ||
  (window &&
    window.performance &&
    window.performance.getEntriesByType &&
    typeof window.performance.getEntriesByType === 'function' &&
    window.performance
      .getEntriesByType('navigation')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      .map((nav) => (nav as any).type)
      .includes('reload'));
