import { isTauri } from '@valstro/workspace';

export const FORCE_CONNECT_TO_SIDECAR = 'force_connect_to_sidecar';
export const DATA_AVAILABLE_ON_DESKTOP_APPLICATION_ONLY = 'Data available on Desktop Application Only';

export const useShouldConnectToSidecar = (forceEnable?: boolean): boolean => {
  if (forceEnable === true) {
    return true;
  }

  if (isTauri()) {
    // Always connect to sidecar on Tauri
    return true;
  } else {
    // By default, do not connect to sidecar from browser. You can override this by passing a truthy condition as an argument to this hook
    return false;
  }
};
