import { useActorContext, useActorSchemaMeta, useCurrentActorContext } from '@valstro/workspace-react';
import { useMemo, useRef } from 'react';
import { ReactPopoverActorSchema } from './popover.contracts';
import { Actor, AnyRecord, EventsTransport, Workspace } from '@valstro/workspace';
import type { PopoverEvents } from '@valstro/workspace-plugin-popover';
import { useWorkspace } from '@valstro/workspace-react';

export function usePopoverTransport() {
  const workspace = useWorkspace<Workspace<{ popoverEventsTransport?: EventsTransport<PopoverEvents> }>>();
  const popoverEventsTransportRef = useRef(workspace.meta?.popoverEventsTransport);
  if (!popoverEventsTransportRef.current) {
    throw new Error(
      'PopoverManager is not initialized, please make sure the `@valstro/workspace-react-plugin-popover` plugin `reactPopoverPlugin` is loaded before using the PopoverTrigger component.'
    );
  }
  return popoverEventsTransportRef.current;
}

export function useCurrentPopoverDialog() {
  const {
    scaleFactor,
    autoSizeHeight,
    autoSizeWidth,
    autoSizePaddingWidth,
    autoSizePaddingHeight,
    popoverId,
    ariaLabel,
    popoverTestId,
    isOpen,
    renderedSide,
    renderedAlign,
    windowId,
    autoSizeTriggerResizePixelSensitivity
  } = useCurrentActorContext<ReactPopoverActorSchema>();

  return useMemo(
    () => ({
      autoSizeHeight,
      autoSizeWidth,
      popoverId,
      ariaLabel,
      renderedSide,
      renderedAlign,
      windowId,
      autoSizePaddingWidth,
      autoSizePaddingHeight,
      isOpen: isOpen ?? false,
      popoverTestId: popoverTestId || undefined,
      autoSizeTriggerResizePixelSensitivity,
      scaleFactor
    }),
    [
      autoSizeHeight,
      autoSizeWidth,
      autoSizePaddingWidth,
      autoSizePaddingHeight,
      popoverId,
      windowId,
      ariaLabel,
      isOpen,
      renderedSide,
      renderedAlign,
      popoverTestId,
      autoSizeTriggerResizePixelSensitivity,
      scaleFactor
    ]
  );
}

export const useReactPopoverActorComponent = (actor: Actor<ReactPopoverActorSchema>) => {
  const { componentId } = useActorContext(actor);
  const meta = useActorSchemaMeta<ReactPopoverActorSchema>(actor);
  return useMemo(() => {
    if (!meta) {
      return <div className="popover-component__error">Could not find actor</div>;
    }

    if (!componentId) {
      return <div className="popover-component__error">Could not find componentId</div>;
    }

    if (!meta?.componentsMap) {
      return <div className="popover-component__error">Could not find componentsMap</div>;
    }

    const Component = meta.componentsMap[componentId];

    if (!Component) {
      return (
        <div className="popover-component__error">
          Could not find component: {componentId}. Please check your "componentsMap" in the popover plugin
          options
        </div>
      );
    }

    return <Component actor={actor} />;
  }, [meta, componentId, actor]);
};

export function usePopoverWindow() {
  const {
    autoSizeHeight,
    autoSizeWidth,
    autoSizeWidthToTrigger,
    height,
    width,
    popoverId,
    windowId,
    componentId,
    renderedSide,
    renderedAlign
  } = useCurrentActorContext<ReactPopoverActorSchema>();
  return useMemo(
    () => ({
      autoSizeHeight,
      autoSizeWidth,
      autoSizeWidthToTrigger,
      height,
      width,
      popoverId,
      windowId,
      componentId,
      renderedSide,
      renderedAlign
    }),
    [
      autoSizeHeight,
      autoSizeWidth,
      autoSizeWidthToTrigger,
      height,
      width,
      popoverId,
      windowId,
      componentId,
      renderedSide,
      renderedAlign
    ]
  );
}

export function usePopoverComponentProps<T extends AnyRecord>(): T {
  const { componentProps } = useCurrentActorContext<ReactPopoverActorSchema>();
  return useMemo(() => (componentProps as T) || ({} as T), [componentProps]);
}
