import {
  Actor,
  ActorSchema,
  ActorSchemaBuilder,
  AnyRecord,
  COMMON_ACTOR_TYPE,
  CreateActorOptions,
  WIDGET_ACTOR_NAME,
  WidgetActorSchema,
  createEventsTransport
} from '@valstro/workspace';
import { uuid } from '@valstro/remote-link';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  IJsonModel,
  Layout,
  Model,
  TabNode,
  Action,
  IJsonRowNode,
  IJsonTabSetNode,
  IJsonTabNode,
  Actions,
  TabSetNode,
  BorderNode,
  ITabSetRenderValues
} from 'flexlayout-react';
import {
  ReactActorComponentProps,
  ReactActorComponentSelector,
  useActorChildren,
  useActorClassNames,
  useActorContext,
  useActorSchemaByName,
  useActorSchemaMeta,
  useActorState,
  useMaybeActorContext,
  useClosestActorByType
} from '../../../core';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  createContext,
  useContext,
  useLayoutEffect,
  DependencyList
} from 'react';

/**
 * Constants
 */
export const FLEX_LAYOUT_ACTOR_TYPE = 'react-flex-layout';
export const REACT_FLEX_LAYOUT_ACTOR_NAME = 'react-flex-layout';
const DEFAULT_JSON_MODEL: IJsonModel = {
  global: {
    splitterSize: 4,
    tabSetHeaderHeight: 20,
    tabSetTabStripHeight: 24
  },
  borders: [],
  layout: {
    type: 'row',
    children: [
      {
        type: 'tabset',
        weight: 50,
        children: [
          {
            type: 'tab',
            name: 'MSFT',
            component: 'MARKET_DATA_WIDGET',
            config: {
              symbol: 'MSFT',
              price: 100
            }
          }
        ],
        active: true
      },
      {
        type: 'tabset',
        weight: 50,
        children: [
          {
            type: 'tab',
            name: 'BTC',
            component: 'MARKET_DATA_WIDGET',
            config: {
              symbol: 'BTC',
              price: 100
            }
          }
        ]
      }
    ]
  }
};

/**
 * Types
 */
export type FlexLayoutContext<TProps extends AnyRecord = AnyRecord> = {
  global?: IJsonModel['global'];
  borders?: IJsonModel['borders'];
  layout: IJsonModel['layout'];
  componentId?: string;
  componentProps: TProps;
  version?: number;
  headerComponent?: string;
  footerComponent?: string;
};

export type FlexLayoutOperations<TProps extends AnyRecord = AnyRecord> = {
  setGlobal: (layout: IJsonModel['global']) => Promise<void>;
  setBorders: (layout: IJsonModel['borders']) => Promise<void>;
  setLayout: (layout: IJsonModel['layout'], flexAction?: Action) => Promise<void>;
  setJSON: (json: IJsonModel, flexAction?: Action) => Promise<void>;
  setProps: (props: TProps) => Promise<void>;
  updateProps: (props: Partial<TProps>) => Promise<void>;
};

/**
 * Flex layout actor schema builder
 */
const FlexLayoutActorSchemaBuilder = ActorSchemaBuilder.create<FlexLayoutContext, FlexLayoutOperations>({
  name: REACT_FLEX_LAYOUT_ACTOR_NAME,
  type: FLEX_LAYOUT_ACTOR_TYPE,
  supportedPlatforms: '*',
  isWindowRootable: false,
  initialContext: ({ initialContext }) => {
    const {
      borders,
      global,
      layout,
      componentProps,
      footerComponent,
      headerComponent,
      componentId,
      version
    } = initialContext || {};
    const mergedLayout: IJsonModel = {
      ...DEFAULT_JSON_MODEL,
      borders: borders || DEFAULT_JSON_MODEL.borders,
      global: global || DEFAULT_JSON_MODEL.global,
      layout: layout || DEFAULT_JSON_MODEL.layout
    };
    return {
      ...Model.fromJson(mergedLayout).toJson(),
      componentProps: componentProps || {},
      componentId,
      version,
      footerComponent,
      headerComponent
    };
  },
  operations: ({ updateContext, getContext }) => ({
    setBorders: async (borders) => {
      await updateContext({
        borders
      });
    },
    setGlobal: async (global) => {
      await updateContext({
        global
      });
    },
    setLayout: async (layout) => {
      // Ensure all tab nodes have an id
      visitTabNodes(layout.children, (tabNode) => {
        if (tabNode.component && !tabNode.id) {
          tabNode.id = uuid();
        }
      });

      await updateContext({
        layout
      });
    },
    setJSON: async ({ layout, borders, global }) => {
      // Ensure all tab nodes have an id
      visitTabNodes(layout.children, (tabNode) => {
        if (tabNode.component && !tabNode.id) {
          tabNode.id = uuid();
        }
      });

      await updateContext({
        layout,
        borders,
        global
      });
    },
    updateProps: async (propsDelta: Partial<AnyRecord>) => {
      await updateContext({
        componentProps: {
          ...getContext().componentProps,
          ...propsDelta
        }
      });
    },
    setProps: async (props: AnyRecord) => {
      await updateContext({
        componentProps: props
      });
    }
  }),
  events: ({ workspace, getDefinition, spawnChild, destroyChild, updateContext }) => {
    /**
     * Keep track of pending add/remove widget operations
     */
    const currentId = getDefinition().id;
    const pendingAddWidgetIds: Set<string> = new Set();
    const pendingRemoveWidgetIds: Set<string> = new Set();

    /**
     * Sync actors children to reflect the layout
     *
     * @param layout - IJsonModel['layout']
     */
    async function syncChildrenToLayout(layout: IJsonModel['layout']) {
      const widgetIds: Set<string> = new Set(
        getDefinition()
          .children.filter((c) => c.type === COMMON_ACTOR_TYPE.WIDGET)
          .map((c) => c.id)
      );

      type Widget = {
        id: string;
        component: string;
        componentProps: AnyRecord;
      };

      const nextWidgets: Widget[] = [];

      visitTabNodes(layout.children, (tabNode) => {
        if (tabNode.component && tabNode.id) {
          nextWidgets.push({
            id: tabNode.id,
            component: tabNode.component,
            componentProps: tabNode.config && typeof tabNode.config === 'object' ? tabNode.config : {}
          });
        }
      });

      const addWidgets = nextWidgets.filter((w) => !widgetIds.has(w.id) && !pendingAddWidgetIds.has(w.id));

      const removeWidgets = Array.from(widgetIds).filter(
        (w) => !nextWidgets.find((nw) => nw.id === w) && !pendingRemoveWidgetIds.has(w)
      );

      for (const widget of addWidgets) {
        pendingAddWidgetIds.add(widget.id);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        spawnChild({
          type: COMMON_ACTOR_TYPE.WIDGET,
          id: widget.id,
          context: {
            componentId: widget.component,
            componentProps: widget.componentProps
          }
        })
          .catch(console.error)
          .then(() => {
            widgetIds.add(widget.id);
          })
          .finally(() => {
            pendingAddWidgetIds.delete(widget.id);
          });
      }

      for (const widgetId of removeWidgets) {
        pendingRemoveWidgetIds.add(widgetId);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        destroyChild(widgetId)
          .catch(console.error)
          .then(() => {
            widgetIds.delete(widgetId);
          })
          .finally(() => {
            pendingRemoveWidgetIds.delete(widgetId);
          });
      }
    }

    /**
     * Sync children to layout on actor creation
     */
    const unsubscribeCreated = workspace.listen('actor/created', (p) => {
      if (p.id !== currentId) {
        return;
      }

      syncChildrenToLayout(p.context.layout).catch(console.error);
    });

    /**
     * Sync children to layout on layout change
     */
    const unsubscribeChange = workspace.listen('actor/operation/performed', (op) => {
      if (currentId !== op.id || (op.operationName !== 'setLayout' && op.operationName !== 'setJSON')) {
        return;
      }

      let layoutChange: IJsonModel['layout'] | undefined;

      if (op.operationName === 'setLayout') {
        layoutChange = op.operationArgs[0];
      }

      if (op.operationName === 'setJSON') {
        layoutChange = op.operationArgs[0].layout;
      }

      if (layoutChange) {
        syncChildrenToLayout(layoutChange).catch(console.error);
      }
    });

    /**
     * Sync widget context to layout
     */
    const unsubscribeWidgetPropChange = workspace.listen('actor/operation/performed', async (op) => {
      // Ignore widgets that are not parents of this layout actor
      if (currentId !== op.parentId) {
        return;
      }

      const model = Model.fromJson(getDefinition().context);
      const tabNode = model.getNodeById(op.id) as TabNode | undefined;
      if (!tabNode) {
        return;
      }

      // Update title
      if (op.operationName === 'setTitle') {
        model.doAction(Actions.renameTab(op.id, op.operationArgs[0]));
      } else {
        // Update props
        const existingConfig = tabNode.getConfig() || {};
        const nextConfig =
          op.operationName === 'setProps'
            ? op.operationArgs[0]
            : {
                ...existingConfig,
                ...op.operationArgs[0]
              };

        model.doAction(
          Actions.updateNodeAttributes(op.id, {
            config: nextConfig
          })
        );
      }

      await updateContext({
        layout: model.toJson().layout
      });
    });

    /**
     * Sync layout title to widget context title
     */
    const unsubscribeLayoutTitleChange = workspace.listen('actor/operation/performed', (op) => {
      // Ignore widgets that are not parents of this layout actor
      if (currentId !== op.parentId) {
        return;
      }

      if (op.operationName !== 'setJSON' && op.operationName !== 'setLayout') {
        return;
      }

      const action: Action | undefined = op.operationArgs[1];

      if (action && action.type === Actions.RENAME_TAB) {
        const nodeId = action.data.node as string;
        const title = action.data.text as string;
        const currentChildren = getDefinition().children;
        const child = currentChildren.find((c) => c.id === nodeId);
        if (!child) {
          return;
        }

        Actor.get<WidgetActorSchema>(child.id)
          .then((actor) => {
            actor.operations.setTitle(title).catch(console.error);
          })
          .catch(console.error);
      }
    });

    /**
     * Sync widget close to layout node close
     */
    const unsubscribeLayoutWidgetClose = workspace.listen('actor/before-destroy', (op) => {
      if (currentId !== op.id) {
        return;
      }

      const currentChildren = getDefinition().children;
      const child = currentChildren.find((c) => c.id === op.id);

      if (!child) {
        return;
      }

      destroyChild(child.id).catch(console.error);
    });

    return () => {
      unsubscribeCreated();
      unsubscribeChange();
      unsubscribeWidgetPropChange();
      unsubscribeLayoutTitleChange();
      unsubscribeLayoutWidgetClose();
    };
  },
  hooks: ({ hooks }) => {
    const unsubscribe = hooks.before('spawnChild', async ([, schema]) => {
      // TODO: Block external spawnChild calls...
      if (schema.isWindowRootable || schema.name === WIDGET_ACTOR_NAME) {
        return;
      }

      throw new Error(`Cannot spawn a child actor of type ${schema.type} because it is not window rootable`);
    });

    return () => {
      unsubscribe();
    };
  },
  onRegistrationFinished: ({ actorRegistry }) => {
    if (!actorRegistry.hasActorByName(WIDGET_ACTOR_NAME)) {
      throw new Error(`${REACT_FLEX_LAYOUT_ACTOR_NAME} requires ${WIDGET_ACTOR_NAME} to be registered`);
    }
  },
  view: ReactFlexLayoutActorView
});

export type ReactFlexLayoutActorWrapperLayoutCompProps = {
  onLayout?: (layout: Layout) => void;
  onModelChange?: (model: Model, action: Action) => void;
  onRenderTabSet?: (tabSetNode: TabSetNode | BorderNode, renderValues: ITabSetRenderValues) => void;
};

export type ReactFlexLayoutActorWrapperCompProps = ReactActorComponentProps<FlexLayoutActorSchema> & {
  FlexLayout: React.ComponentType<ReactFlexLayoutActorWrapperLayoutCompProps>;
};

export type ReactFlexLayoutComponentMapProps = ReactActorComponentProps<FlexLayoutActorSchema> & {
  model: Model;
};

export type ReactFlexLayoutActorWrapperComp = React.FC<ReactFlexLayoutActorWrapperCompProps>;

export type ReactFlexLayoutActorComponentMapComp = React.FC<ReactFlexLayoutComponentMapProps>;

export type FlexLayoutActorSchema<Props extends AnyRecord = AnyRecord> = ActorSchema<
  FlexLayoutContext<Props>,
  FlexLayoutOperations<Props>,
  any,
  ReactFlexLayoutActorSchemaOptions
>;

export type ReactFlexLayoutActorViewType = React.ComponentType<
  ReactActorComponentProps<FlexLayoutActorSchema>
>;

/**
 * Extend the flex-layout actor schema builder for React types
 */
export const ReactFlexLayoutActorSchemaBuilder =
  FlexLayoutActorSchemaBuilder.extendView(ReactFlexLayoutActorView);

export type ReactFlexLayoutTabSetActionsTransformer = (
  currentActions: ITabSetRenderValues['buttons'],
  node: TabSetNode,
  flexLayoutActor: Actor<ReactFlexLayoutActorSchema>
) => ITabSetRenderValues['buttons'];

export interface ReactFlexLayoutActorSchemaOptions {
  global?: IJsonModel['global'];
  borders?: IJsonModel['borders'];
  layout?: IJsonModel['layout'];
  tabSetActionsTransformer?: ReactFlexLayoutTabSetActionsTransformer;
  wrapperComponent?: ReactFlexLayoutActorWrapperComp;
  componentMap?: Record<string, ReactFlexLayoutActorComponentMapComp>;
}

export const flexLayoutActor =
  ReactFlexLayoutActorSchemaBuilder.optionsCreator<ReactFlexLayoutActorSchemaOptions>();

export type ReactFlexLayoutActorSchema = ReturnType<typeof flexLayoutActor>;

/**
 * Custom Events
 */
export type ReactFlexLayoutDoActionEvent = {
  type: 'doAction';
  payload: {
    flexLayoutActorId: string;
    action: {
      type: string;
      data: Record<string, any>;
    };
  };
};

export type ReactFlexLayoutAddTabIndirectEvent = {
  type: 'addTabWithDragAndDropIndirect';
  payload: {
    flexLayoutActorId: string;
    tabJSON: IJsonTabNode;
  };
};

export type ReactFlexLayoutEvents = ReactFlexLayoutDoActionEvent | ReactFlexLayoutAddTabIndirectEvent;

export const reactFlexLayoutEvents = createEventsTransport<ReactFlexLayoutEvents>('react-flex-layout');

/**
 * Flex Wrapper Context
 */
export type FlexLayoutActionComponentProps = {
  node: TabSetNode;
  flexLayoutActor: Actor<ReactFlexLayoutActorSchema>;
};

export type FlexLayoutTabsSetAction = {
  actionId: string;
  component: React.FC<FlexLayoutActionComponentProps>;
  order?: number;
};

export type FlexLayoutTabsSetActionMap = Map<string, Map<string, FlexLayoutTabsSetAction>>;

export type FlexLayoutWrapperContext = {
  tabSetActionsMap: FlexLayoutTabsSetActionMap;
  addTabSetAction: (tabSetNode: TabSetNode, action: FlexLayoutTabsSetAction) => void;
  removeTabSetAction: (tabSetNode: TabSetNode, actionId: string) => void;
  getTabSetActions: (tabSetId: string) => FlexLayoutTabsSetAction[];
};

const FlexLayoutWrapperContext = createContext<FlexLayoutWrapperContext>({} as FlexLayoutWrapperContext);

/**
 * Flex Layout Actor Wrapper Provider
 */
function ReactFlexLayoutActorWrapperProvider({
  children
}: React.PropsWithChildren<ReactActorComponentProps<ReactFlexLayoutActorSchema>>) {
  const queueTimeout = useRef<NodeJS.Timeout | null>(null);
  const queue = useRef<Map<string, () => void>>(new Map());
  const tabSetActionsRef = useRef(new Map<string, Map<string, FlexLayoutTabsSetAction>>());

  const triggerTabSetRender = useCallback((tabSetNode: TabSetNode) => {
    queue.current.set(`render_${tabSetNode.getId()}`, () => {
      const model = tabSetNode.getModel();
      model.doAction(
        Actions.updateNodeAttributes(tabSetNode.getId(), {
          ...tabSetNode.getConfig()
        })
      );
    });
  }, []);

  const drainQueue = useCallback(() => {
    if (queueTimeout.current) {
      return;
    }

    queueTimeout.current = setTimeout(() => {
      queue.current.forEach((render) => {
        render();
      });
      queue.current.clear();
      queueTimeout.current = null;
    }, 150);
  }, []);

  useEffect(() => {
    return () => {
      if (queueTimeout.current) {
        clearTimeout(queueTimeout.current);
      }
    };
  }, []);

  const removeTabSetAction = useCallback(
    (tabSetNode: TabSetNode, actionId: string) => {
      const tabSetActions = tabSetActionsRef.current.get(tabSetNode.getId());
      if (!tabSetActions) {
        return;
      }

      const shouldTriggerRender = tabSetActions.has(actionId);
      tabSetActions.delete(actionId);
      if (shouldTriggerRender) {
        triggerTabSetRender(tabSetNode);
        drainQueue();
      }
    },
    [triggerTabSetRender, drainQueue]
  );

  const addTabSetAction = useCallback(
    (tabSetNode: TabSetNode, action: FlexLayoutTabsSetAction) => {
      let tabSetActions = tabSetActionsRef.current.get(tabSetNode.getId());
      if (!tabSetActions) {
        tabSetActions = new Map();
        tabSetActionsRef.current.set(tabSetNode.getId(), tabSetActions);
      }

      const shouldTriggerRender = !tabSetActions.has(action.actionId);
      tabSetActions.set(action.actionId, action);
      if (shouldTriggerRender) {
        triggerTabSetRender(tabSetNode);
        drainQueue();
      }
    },
    [triggerTabSetRender, drainQueue]
  );

  const getTabSetActions = useCallback(
    (tabSetId: string) => {
      const actions = tabSetActionsRef.current.get(tabSetId);
      if (!actions) {
        return [];
      }
      return Array.from(actions.values()).sort((a, b) => {
        const orderA = a.order ?? Number.MAX_SAFE_INTEGER;
        const orderB = b.order ?? Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      });
    },
    [tabSetActionsRef]
  );

  return (
    <FlexLayoutWrapperContext.Provider
      value={{
        tabSetActionsMap: tabSetActionsRef.current,
        getTabSetActions,
        addTabSetAction,
        removeTabSetAction
      }}
    >
      {children}
    </FlexLayoutWrapperContext.Provider>
  );
}

export function useFlexLayoutWrapperContext() {
  return useContext(FlexLayoutWrapperContext) || {};
}

export function useAddFlexLayoutTabSetAction(action: FlexLayoutTabsSetAction, deps: DependencyList) {
  const { tabSetNode } = useFlexLayoutNodeContext();
  const { addTabSetAction } = useFlexLayoutWrapperContext();

  useLayoutEffect(() => {
    if (!addTabSetAction || !tabSetNode) {
      return;
    }

    return addTabSetAction(tabSetNode, action);
  }, [...deps, tabSetNode, addTabSetAction]);
}

/**
 * Flex Layout Node Context
 */
export type FlexLayoutTabNodeContext = {
  tabSetNode?: TabSetNode | null;
  tabNode: TabNode;
};

const FlexLayoutTabNodeContext = createContext<FlexLayoutTabNodeContext>({} as FlexLayoutTabNodeContext);

export function useFlexLayoutNodeContext() {
  return useContext(FlexLayoutTabNodeContext) || {};
}

/**
 * Actor view for flex-layout
 */
export function ReactFlexLayoutActorView({ actor }: ReactActorComponentProps<ReactFlexLayoutActorSchema>) {
  const [state, failedMessage] = useActorState(actor);
  const className = useActorClassNames(actor, state);
  const actorSchema = useActorSchemaByName<FlexLayoutActorSchema>(actor.name);

  const FlexLayout = useCallback(
    (props: ReactFlexLayoutActorWrapperLayoutCompProps) => {
      return <ReactFlexLayoutActorViewLayout actor={actor} {...props} />;
    },
    [actor]
  );

  const WrapperComp = useMemo(() => {
    const Wrapper = actorSchema?.meta?.wrapperComponent;
    return Wrapper
      ? Wrapper
      : (_props: ReactFlexLayoutActorWrapperCompProps) => {
          return <FlexLayout />;
        };
  }, [actorSchema, FlexLayout]);

  if (!actorSchema) {
    return (
      <div className={className.wrapper}>
        <div className={className.box}>
          <div className={className.title}>Could not find actor: {FLEX_LAYOUT_ACTOR_TYPE}</div>
        </div>
      </div>
    );
  }

  switch (state) {
    case 'idle':
      return <div className={className.wrapper}>Idle</div>;
    case 'starting':
      return <div className={className.wrapper}>Loading...</div>;
    case 'applying-snapshot':
      return <div className={className.wrapper}>Loading layout...</div>;
    case 'failed':
      return (
        <div className={className.wrapper}>
          <div className={className.box}>
            <div className={className.title}>Error</div>
            {failedMessage && <div className={className.message}>{failedMessage}</div>}
          </div>
        </div>
      );
    case 'destroyed':
      return (
        <div className={className.wrapper}>
          <div className={className.box}>
            <div className={className.title}>Widget Disconnected</div>
            <div className={className.message}>Please refresh to re-connect</div>
          </div>
        </div>
      );
    default:
      return (
        <div className={className.wrapper}>
          <ReactFlexLayoutActorWrapperProvider actor={actor}>
            <WrapperComp actor={actor} FlexLayout={FlexLayout} />
          </ReactFlexLayoutActorWrapperProvider>
        </div>
      );
  }
}

/**
 * Actor view for flex-layout
 */
export function ReactFlexLayoutActorViewLayout({
  actor,
  onLayout: _onLayout,
  onModelChange: _onModelChange,
  onRenderTabSet: _onRenderTabSet
}: ReactActorComponentProps<ReactFlexLayoutActorSchema> & ReactFlexLayoutActorWrapperLayoutCompProps) {
  const layoutRef = useRef<Layout | null>(null);
  const context = useActorContext(actor);
  const model = useMemo(
    () =>
      Model.fromJson({
        layout: context.layout,
        global: context.global,
        borders: context.borders
      }),
    [context.layout, context.global, context.borders]
  );
  const children = useActorChildren(actor);
  const widgetChildren = useMemo(
    () => children.filter((c) => c.type === COMMON_ACTOR_TYPE.WIDGET),
    [children]
  );

  const meta = useActorSchemaMeta(actor);

  const { tabSetActionsTransformer, componentMap } = meta || {};
  const { getTabSetActions } = useFlexLayoutWrapperContext();
  const HeaderComponent = useMemo(() => {
    return context.headerComponent ? (componentMap?.[context.headerComponent] ?? null) : null;
  }, [context.headerComponent, componentMap]);

  const FooterComponent = useMemo(() => {
    return context.footerComponent ? (componentMap?.[context.footerComponent] ?? null) : null;
  }, [context.footerComponent, componentMap]);

  const onLayoutRef = useCallback(
    (layout: Layout) => {
      layoutRef.current = layout;
      _onLayout && _onLayout(layout);
    },
    [_onLayout]
  );

  useEffect(() => {
    const unsubscribeDoAction = reactFlexLayoutEvents.listen('doAction', (event) => {
      if (event.flexLayoutActorId === actor.id) {
        model.doAction(event.action);
      }
    });

    const unsubscribeAddTabIndirect = reactFlexLayoutEvents.listen(
      'addTabWithDragAndDropIndirect',
      (event) => {
        if (layoutRef.current && event.flexLayoutActorId === actor.id) {
          layoutRef.current.addTabWithDragAndDropIndirect(
            `Drag ${event.tabJSON.name} into position`,
            event.tabJSON
          );
        }
      }
    );

    return () => {
      unsubscribeDoAction();
      unsubscribeAddTabIndirect();
    };
  }, [actor.id, model]);

  const factory = useCallback(
    (node: TabNode) => {
      const id = node.getId();
      const widgetChild = widgetChildren.find((c) => c.id === id);

      if (widgetChild) {
        node.setEventListener('close', () => {
          // TODO: Implement moving logic eventually
          // const config = node.getConfig();
          // If widget is moving, don't fire closing event
          // if (config && config?.isMoving) {
          //   return;
          // }
        });

        const parentNode = node.getParent();
        const tabSetNode = parentNode instanceof TabSetNode ? parentNode : null;

        return (
          <FlexLayoutTabNodeContext.Provider value={{ tabNode: node, tabSetNode }}>
            <ReactActorComponentSelector actorId={widgetChild.id} />
          </FlexLayoutTabNodeContext.Provider>
        );
      }
      console.debug('Warning: Could not find widget child for node', node);
      return null;
    },
    [widgetChildren]
  );

  const onModelChange = useCallback(
    (model: Model, action: Action) => {
      actor.operations.setJSON(model.toJson(), action).catch(console.error);
      _onModelChange && _onModelChange(model, action);
    },
    [actor, _onModelChange]
  );

  const onRenderTabSet = useCallback(
    (tabSetNode: TabSetNode | BorderNode, renderValues: ITabSetRenderValues) => {
      const actionsForTabSet = getTabSetActions(tabSetNode.getId());
      if (tabSetNode instanceof TabSetNode && actionsForTabSet && actionsForTabSet.length > 0) {
        const actionComponents = actionsForTabSet.map(({ component: Component }) => {
          // enrich the component with the node and flexLayoutActor
          return <Component key={tabSetNode.getId()} node={tabSetNode} flexLayoutActor={actor} />;
        });

        renderValues.buttons = [...actionComponents, ...renderValues.buttons];
      }

      if (tabSetNode instanceof TabSetNode && tabSetActionsTransformer) {
        renderValues.buttons = tabSetActionsTransformer(renderValues.buttons, tabSetNode, actor);
      }

      _onRenderTabSet && _onRenderTabSet(tabSetNode, renderValues);
    },
    [getTabSetActions, tabSetActionsTransformer, actor, _onRenderTabSet]
  );

  const onExternalDrag = useCallback((_event: React.DragEvent<HTMLDivElement>) => {
    const result:
      | {
          dragText: string;
          json: any;
          onDrop?: (node?: Node, event?: Event) => void;
        }
      | undefined = undefined;

    return result;
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {HeaderComponent && <HeaderComponent actor={actor} model={model} />}
      <div style={{ flexGrow: 1, position: 'relative' }}>
        <Layout
          ref={onLayoutRef}
          model={model}
          factory={factory}
          onModelChange={onModelChange}
          onRenderTabSet={onRenderTabSet}
          onExternalDrag={onExternalDrag}
        />
      </div>
      {FooterComponent && <FooterComponent actor={actor} model={model} />}
    </div>
  );
}

/**
 * Generate a flex-layout actor definition
 *
 * @param layout - IJsonModel['layout']
 * @param children - The component children
 * @returns - The actor definition
 */
export function flexLayoutActorDef(
  layout: IJsonModel['layout'],
  options?: FlexLayoutActorDefOptions
): CreateActorOptions<AnyRecord> {
  const context: FlexLayoutActorDefOptions = {};

  if (options?.borders) {
    context.borders = options.borders;
  }

  if (options?.global) {
    context.global = options.global;
  }

  if (options?.componentProps) {
    context.componentProps = options.componentProps;
  }

  if (options?.headerComponent) {
    context.headerComponent = options.headerComponent;
  }

  if (options?.footerComponent) {
    context.footerComponent = options.footerComponent;
  }

  return {
    id: options?.id,
    type: FLEX_LAYOUT_ACTOR_TYPE,
    context: {
      layout,
      ...context
    },
    children: options?.children || []
  };
}

export type FlexLayoutActorDefOptions = {
  borders?: IJsonModel['borders'];
  global?: IJsonModel['global'];
  componentProps?: AnyRecord;
  headerComponent?: string;
  footerComponent?: string;
  children?: CreateActorOptions<AnyRecord>[];
  id?: string;
};

/**
 * Helper function to visit all tab nodes
 *
 * @param children - (IJsonRowNode | IJsonTabSetNode | IJsonTabNode)[]
 * @param cb - (tabNode: IJsonTabNode) => void
 */
function visitTabNodes(
  children: (IJsonRowNode | IJsonTabSetNode | IJsonTabNode)[],
  cb: (tabNode: IJsonTabNode) => void
) {
  children.forEach((child) => {
    switch (child.type) {
      case 'tabset':
        visitTabNodes((child as IJsonTabSetNode).children, cb);
        break;
      case 'row':
        visitTabNodes((child as IJsonRowNode).children, cb);
        break;
      case 'tab':
        cb(child as IJsonTabNode);
        break;
    }
  });
}

/**
 * Useful hooks for interacting with the nearest flex-layout
 */
export const useClosestFlexLayoutActor = <T extends AnyRecord = AnyRecord>(): Actor<
  FlexLayoutActorSchema<T>
> | null => {
  return useClosestActorByType<FlexLayoutActorSchema<T>>(FLEX_LAYOUT_ACTOR_TYPE);
};

export const useClosestFlexLayoutActorProps = <T extends AnyRecord = AnyRecord>(): readonly [
  T | null,
  (props: Partial<T>) => Promise<void>
] => {
  const actor = useClosestFlexLayoutActor<T>();
  const context = useMaybeActorContext(actor);
  return useMemo(
    () =>
      [
        context?.componentProps || null,
        async (p: Partial<T>) => {
          if (!actor) {
            throw new Error('No flex-layout actor found');
          }
          await actor.operations.updateProps(p);
        }
      ] as const,
    [context?.componentProps, actor]
  );
};
