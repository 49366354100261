import type {
  ColDef,
  ColGroupDef,
  ColumnsMenuParams,
  ColumnChooserParams,
  ColumnGroupShowType,
  ISetFilterParams
} from '@ag-grid-community/core';
import { CellBuilder, type CellRenderer } from './cell.builder';
import type { Level1IntegrationEvent } from '@oms/generated/frontend';
import type { BooleanFormatters } from '../formatters/boolean.formatters';
import type { Optional, Paths } from '@oms/shared/util-types';
import {
  type GetFormatUnionInput,
  isDateTimeFormat,
  isNumericFormat
} from '@oms/shared-frontend/ui-design-system';
import { format, type FormatType } from '@oms/shared-frontend/ui-design-system';
import { ColumnGroupBuilder } from './column.group.builder';
import { COL_TEXT_ATTR, COL_TEXT_SHORT_ATTR } from '../services/grid-column-header.service';
import type { AnyRecord } from '@oms/frontend-foundation';
import type { ColumnBuilderField, FilterParams } from './column.builder.types';
import { ColumnBuilderCallback } from './grid.builder.types';

/**
 * This method is used to create the ag-grid header template
 * with the long & short header name included as HTML attributes
 *
 * @param headerName string
 * @param shortHeaderName string
 * @returns Default ag-grid header template (with short header name & long header name included)
 */
export const createAgGridHeaderTemplate = (
  headerName: string,
  shortHeaderName?: string
) => `<div class="ag-cell-label-container" role="presentation">
<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
<div ref="eLabel" class="ag-header-cell-label" role="presentation">
    <span ref="eText" class="ag-header-cell-text" ${COL_TEXT_ATTR}="${headerName}" title="${headerName}" ${
      shortHeaderName ? `${COL_TEXT_SHORT_ATTR}="${shortHeaderName}"` : ``
    }></span>
    <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
    <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
    <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
    <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
    <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
</div>
</div>`;

export class ColumnBuilder<TData extends AnyRecord> {
  private _colDef: ColDef<TData> = {};
  private _cellBuilder!: CellBuilder<TData>;
  private _requiresMarketData = false;

  constructor(colDef: ColDef<TData> = {}, cellBuilder?: CellBuilder<TData>) {
    this._colDef = colDef;
    this._cellBuilder = cellBuilder || new CellBuilder<TData>();
  }

  public id(id: string) {
    this._colDef.colId = id;
    return this;
  }

  /**
   * This is useful if you need to apply one or more utils to a builder instance without wrapping around the whole chain.
   *
   * ```ts
   * (c) => c.field('name').header('Name').pipe(
   *   (c) => applyFiltersTo(c),
   *   (c) => applyFormattingTo(c)
   * ).hide(false)
   * ```
   *
   * @param builders One or more builder callbacks that pass along the column builder reference
   * @returns The reference to `this` builder with all changes applied.
   */
  public pipe(...builders: ColumnBuilderCallback<TData>[]): ColumnBuilder<TData> {
    builders.forEach((builder) => {
      builder(this);
    });
    return this;
  }

  public header(name: string, shortName?: string) {
    this._colDef.headerName = name;
    this.shortHeader(shortName || name);
    return this;
  }

  public shortHeader(shortName: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this._colDef.headerComponentParams = {
      ...(this._colDef.headerComponentParams || {}),
      template: createAgGridHeaderTemplate(this._colDef.headerName || shortName, shortName)
    };
    return this;
  }

  public headerComp(value: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this._colDef.headerComponent = value;
    return this;
  }

  public headerParams<TParams extends AnyRecord = AnyRecord>(value: TParams) {
    this._colDef.headerComponentParams = value;
    return this;
  }

  public columnGroupShow(columnGroupShow?: Optional<ColumnGroupShowType>) {
    this._colDef.columnGroupShow = columnGroupShow;
    return this;
  }

  /** @deprecated use 'columnChooserParams */
  public columnsMenuParams(value?: Optional<ColumnsMenuParams>) {
    this._colDef.columnsMenuParams = value;
    return this;
  }

  public columnChooserParams(value?: Optional<ColumnChooserParams>) {
    this._colDef.columnsMenuParams = value;
    return this;
  }

  public type(type: string) {
    this._colDef.type = type;
    return this;
  }

  public hide(hide: boolean = true) {
    this._colDef.hide = hide;
    return this;
  }

  public show() {
    this._colDef.hide = false;
    return this;
  }

  public rowGroup(rowGroup: ColDef<TData>['rowGroup'] = true) {
    this._colDef.rowGroup = rowGroup;
    return this;
  }

  public comparator(comparator: ColDef<TData>['comparator']) {
    this._colDef.comparator = comparator;
    return this;
  }

  public filter(filter?: ColDef<TData>['filter']) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this._colDef.filter = filter !== undefined ? filter : true;
    return this;
  }

  public floatingFilter(
    floatingFilter: ColDef<TData>['floatingFilter'] = true,
    floatingFilterComponentParams?: any
  ) {
    this._colDef.floatingFilter = floatingFilter;
    this._colDef.floatingFilterComponentParams = floatingFilterComponentParams;
    return this;
  }

  public floatingFilterComponent(component: ColDef<TData>['floatingFilterComponent']) {
    this._colDef.floatingFilterComponent = component;
    return this;
  }

  public filterParams<TValue = unknown>(filterParams: FilterParams<TData, TValue>): ColumnBuilder<TData>;

  public filterParams<TValue = unknown>(filterParams: ISetFilterParams<TData, TValue>): ColumnBuilder<TData>;

  // Implementation only ----- /
  public filterParams(filterParams: any): ColumnBuilder<TData> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this._colDef.filterParams = filterParams;
    return this;
  }

  public sortable(sortable = true) {
    this._colDef.sortable = sortable;
    return this;
  }

  public lockVisible() {
    this._colDef.lockVisible = true;
    return this;
  }

  public dragText(dragText: ColDef<TData>['rowDragText']) {
    this._colDef.rowDragText = dragText;

    return this;
  }

  public lockPosition() {
    this._colDef.lockPosition = true;
    return this;
  }

  public sort(sort: ColDef<TData>['sort']) {
    this._colDef.sort = sort;
    return this;
  }

  public resizable(resize: boolean = true) {
    this._colDef.resizable = resize;
    return this;
  }

  public hideMenu() {
    this._colDef.suppressMenu = true;
    return this;
  }

  public suppressColumnsToolPanel() {
    this._colDef.suppressColumnsToolPanel = true;
    return this;
  }

  public flex(flex: number) {
    this._colDef.flex = flex;
    return this;
  }

  public movable(move?: boolean) {
    const shouldMove = move === undefined || move === null || move;
    this._colDef.suppressMovable = !shouldMove;
    return this;
  }

  public width(width: number) {
    this._colDef.width = width;
    return this;
  }

  public minWidth(width: number) {
    this._colDef.minWidth = width;
    return this;
  }

  public maxWidth(width: number) {
    this._colDef.maxWidth = width;
    return this;
  }

  public initialWidth(width: number) {
    this._colDef.initialWidth = width;
    return this;
  }

  public enableRowGroup(enableRowGroup: ColDef<TData>['enableRowGroup'] = true) {
    this._colDef.enableRowGroup = enableRowGroup;
    return this;
  }

  public enablePivot(enablePivot: ColDef<TData>['enablePivot'] = true) {
    this._colDef.enablePivot = enablePivot;
    return this;
  }

  /**
   * Optionally pin the column to one side.
   * You can pin multiple columns in a single grid- they will just stack
   * @param side left or right
   */
  public pin(side: 'left' | 'right') {
    this._colDef.pinned = side;
    return this;
  }

  public format<T extends FormatType>(
    type?: T,
    field?: keyof TData | keyof Level1IntegrationEvent,
    callback: (cb: CellBuilder<TData>) => CellBuilder<TData> = (cb: CellBuilder<TData>) => cb
  ) {
    if (!type) {
      return this;
    }

    if (field) {
      this.field(field);
    }
    if (isNumericFormat(type)) {
      this.filter('agNumberColumnFilter');
      this.type('rightAligned');
    }
    if (isDateTimeFormat(type)) {
      this.filter('agDateColumnFilter');
    }
    this.cell((cb) =>
      cb.valueFormatter<string | number>(({ value }) => {
        callback(cb);
        return value || value === 0 ? format(type, value as GetFormatUnionInput<T>) : '';
      })
    );
    return this;
  }

  public bool(
    booleanValueFormatter: BooleanFormatters = 'yesNo',
    callback: (cb: CellBuilder<TData>) => CellBuilder<TData> = (cb: CellBuilder<TData>) => cb
  ) {
    this.filter('agSetColumnFilter');
    this.cell((cb) => cb.valueFormatterRegistry(booleanValueFormatter) && callback(cb));
    return this;
  }

  public cell(callback: (cb: CellBuilder<TData>) => CellBuilder<TData>) {
    this._cellBuilder = callback(this._cellBuilder);
    return this;
  }

  public static of<TData extends AnyRecord>(def: ColDef<TData>) {
    return new ColumnBuilder<TData>({ ...def });
  }

  public clone() {
    return new ColumnBuilder<TData>({ ...this._colDef }, this._cellBuilder.clone());
  }

  public colDef(colDef: ColDef<TData> | ColGroupDef<TData>) {
    this._colDef = {
      ...this._colDef,
      ...colDef
    };
    return this;
  }

  public colId(colId: string) {
    this._colDef.colId = colId;
    return this;
  }

  public colGroup(callback: (cb: ColumnGroupBuilder<TData>) => ColumnGroupBuilder<TData>) {
    const builder = new ColumnGroupBuilder<TData>();
    this._colDef = callback(builder).build();
    return this;
  }

  public field(field: ColumnBuilderField<TData>) {
    this._colDef.colId = field as string;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this._colDef.field = field as string;
    return this;
  }

  public level1MarketData(
    field: keyof Level1IntegrationEvent,
    identifier: keyof TData | Paths<TData, 3>,
    renderer: CellRenderer = '',
    width: number = 80,
    type: string = 'numericColumn',
    header?: string
  ) {
    const cb = (cb: CellBuilder<TData>) => cb.renderer(renderer);
    this.id(field).format('number', field, cb);
    this._colDef.refData = this._colDef.refData || {};
    this._colDef.refData['tickerId'] = identifier as string;
    this._colDef.width = width;
    this._colDef.type = type;
    if (header) {
      this._colDef.headerName = header;
    }
    this._requiresMarketData = true;
    return this;
  }

  public aggFunc(value: ColDef<TData>['aggFunc']) {
    this._colDef.aggFunc = value;
    return this;
  }

  public get requiresMarketData(): boolean {
    return this._requiresMarketData;
  }

  public build(): ColDef<TData> {
    const cellDef = this._cellBuilder.build();

    this._colDef.cellRendererParams = cellDef.groupCellParams;
    this._colDef.cellRenderer = cellDef.cellRenderer;
    if (!cellDef.groupCellParams) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this._colDef.cellRendererParams = cellDef.cellRendererParams;
    }
    this._colDef.valueFormatter = cellDef.valueFormatter;
    this._colDef.valueGetter = cellDef.valueGetter;
    this._colDef.filterValueGetter = cellDef.filterValueGetter;
    this._colDef.cellClassRules = cellDef.cellClassRules;

    if (this._colDef.refData && this._colDef.refData['tickerData']) {
      this._requiresMarketData = true;
    }

    return this._colDef;
  }
}
