import { TradeRepairRequestService } from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import { TradesService } from '@app/data-access/services/trading/trades/trades.service';
import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type { RepairFailedTradeFields } from '@oms/generated/frontend';
import { convertToNumber } from '@oms/shared/util';
import { TradeModifyEntryType } from './trade-modify.form-common';
import type { TradeModifyInput, TradeModifyOutput } from './trade-modify.form-common';
import { tradeModifyContract } from './trade-modify.form-contract';
import type { TradeModifyContractType, TradeModifyValues } from './trade-modify.form-contract';
import {
  getInitialCommonFormValues,
  getInitialRepairOnlyFormValues,
  getTradeInfo
} from './trade-modify.form-sanitizers';

export const tradeModifyBuilder = FormBuilder.create<TradeModifyInput, TradeModifyOutput>('trade-modify-form')
  .contract<TradeModifyContractType>(tradeModifyContract)
  .type('trade-modify')
  .sanitizer((s) =>
    s
      .input(async function initializeFormValues(input, ctx) {
        const tradeInfo = await getTradeInfo(input, ctx.container);
        let initialFormValues: Partial<TradeModifyValues> = {
          id: input.id
        };

        if (tradeInfo?.type === TradeModifyEntryType.TradeRepair) {
          initialFormValues = {
            ...initialFormValues,
            ...getInitialCommonFormValues(tradeInfo.tradeData),
            ...getInitialRepairOnlyFormValues(tradeInfo.tradeRepair, tradeInfo.tradeData)
          };
        }

        if (tradeInfo?.type === TradeModifyEntryType.TradeModify) {
          initialFormValues = {
            ...initialFormValues,
            ...getInitialCommonFormValues(tradeInfo.trade)
          };
        }

        return {
          hiddenFormInfo: tradeInfo,
          ...initialFormValues
        };
      })
      .output(function sanitizeFormValuesToOutput(formValues) {
        if (
          !formValues?.id ||
          !formValues?.instrument ||
          !formValues?.tradeQuantity ||
          !formValues?.hiddenFormInfo
        ) {
          return;
        }

        const output: TradeModifyOutput['output'] = {
          id: formValues.id
        };
        const { hiddenFormInfo } = formValues;

        output.tradeQuantity = formValues.tradeQuantity ? convertToNumber(formValues.tradeQuantity) : null;
        output.tradePrice = formValues.tradePrice ? convertToNumber(formValues.tradePrice) : null;
        output.tradeCounterpartyId = formValues.tradeCounterpartyId?.id || undefined;
        const tradeContraAccountId = formValues.tradeContraAccountId?.id;
        if (tradeContraAccountId) {
          output.tradeContraAccount = {
            id: tradeContraAccountId
          };
        }
        output.settleDate = formValues.settleDate || undefined;
        output.settleType = formValues.settleType;
        output.tradeDateTime = formValues.tradeDateTime ? formValues.tradeDateTime : undefined;
        output.tradeTagsId = formValues.tradeTags?.map((tag) => tag.id);

        if (hiddenFormInfo.type === TradeModifyEntryType.TradeRepair) {
          // set correct trade id for repair case
          output.id = hiddenFormInfo.tradeRepair?.tradeId || '';
          output.side = formValues.side;
          output.instrument = formValues.instrument ? { id: formValues.instrument.id } : undefined;

          return {
            type: hiddenFormInfo.type,
            output,
            repairTradeId: hiddenFormInfo.tradeRepair?.id || '',
            repairCategory: hiddenFormInfo.tradeRepair?.category
          };
        }

        return {
          type: hiddenFormInfo.type,
          output
        };
      })
  )
  .change(async (event, ctx) => {
    const tradesService = ctx.container.resolve(TradesService);
    const repairService = ctx.container.resolve(TradeRepairRequestService);

    switch (event.type) {
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED:
      case FORM_EVENT_TYPE.SUBMIT: {
        const isDryRun = event.type === FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED;
        const { type, output } = event.payload.output;

        switch (type) {
          case TradeModifyEntryType.TradeModify: {
            return await tradesService.modify(output, isDryRun);
          }
          case TradeModifyEntryType.TradeRepair: {
            const { repairTradeId } = event.payload.output;
            const repairTradeFields: RepairFailedTradeFields = {
              side: output.side,
              instrument: output.instrument,
              tradeQuantity: output.tradeQuantity,
              tradePrice: output.tradePrice,
              tradeCounterpartyId: output.tradeCounterpartyId,
              tradeDateTime: output.tradeDateTime,
              tags: JSON.stringify(output.tradeTagsId)
            };

            return await repairService.repairTradesRequest(
              [
                {
                  tradeRepairRequestId: repairTradeId,
                  trade: repairTradeFields
                }
              ],
              isDryRun
            );
          }
        }
      }
    }
  });

export type TradeModifyBuilderType = typeof tradeModifyBuilder;

export default tradeModifyBuilder;
