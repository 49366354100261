import { FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import {
  idField,
  longNameField,
  exchangeTypeField,
  countryIdField,
  micField,
  micLevelField,
  startTimeField,
  endTimeField,
  partialStartTimeField,
  partialEndTimeField,
  timezoneField,
  expiryOffsetField,
  extendedExpiryOffsetField
} from './exchange.form-fields';
import type { ExchangeFormOutput } from './exchange.form-common';

export const exchangeFormContract = FormContract.create<ExchangeFormOutput>()
  .fields({
    id: idField,
    longName: longNameField,
    exchangeType: exchangeTypeField,
    countryId: countryIdField,
    mic: micField,
    micLevel: micLevelField,
    startTime: startTimeField,
    endTime: endTimeField,
    partialStartTime: partialStartTimeField,
    partialEndTime: partialEndTimeField,
    timezone: timezoneField,
    expiryOffset: expiryOffsetField,
    extendedExpiryOffset: extendedExpiryOffsetField
  })
  .schema((f) => ({
    fields: [
      {
        component: 'section',
        title: 'Exchange Info',
        name: 'exchange-info-section',
        sx: {
          backgroundColor: 'transparent'
        },
        fields: [
          [
            {
              component: 'simple-grid',
              name: 'exchange-info-wrappera',
              columns: 3,
              fields: [[f.id], [f.longName], [f.exchangeType], [f.countryId], [f.mic], [f.micLevel]]
            }
          ]
        ]
      },
      {
        component: 'section',
        title: 'Exchange Details',
        name: 'exchange-details-section',
        sx: {
          backgroundColor: 'transparent'
        },
        fields: [
          [
            {
              component: 'simple-grid',
              name: 'exchange-details-wrappera',
              columns: 3,
              fields: [
                [f.startTime],
                [f.endTime],
                [f.partialStartTime],
                [f.partialEndTime],
                [f.timezone],
                [f.expiryOffset],
                [f.extendedExpiryOffset]
              ]
            }
          ]
        ]
      }
    ]
  }))
  .template('simple', { submitLabel: 'Save' });

export type ExchangeFormContractType = typeof exchangeFormContract;

export type ExchangeFormValues = InferFormValuesFromFormContract<ExchangeFormContractType>;
