import { AppWorkspace } from '@app/app-config/workspace.config';
import { COMMAND_PALETTE_GROUP } from '@app/commands/command-palette.constants';
import { hasRequiredEntitlements } from '@app/common/auth/keycloak.helpers';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import type { CommandPaletteItem } from '@app/common/command-palette/command-palette.contracts';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { CommandPaletteService } from '@app/data-access/services/system/command-palette/command-palette.service';
import type { ComponentDictEnumKey, LayoutDictEnumKey } from '@app/generated/common';
import { COMPONENT_DICTIONARY, FORM_DICTIONARY, LAYOUT_DICTIONARY } from '@app/generated/common';
import type { FormMap } from '@app/generated/mappers';
import {
  openComponentFromDictionary,
  openFormFromDictionary,
  openLayoutFromDictionary
} from '@app/generated/sdk';
import { PROCESS_ID, isTauri } from '@valstro/workspace';
import type { Subscription } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { RegisterCommand } from '../register-command.model';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class NavigationCommands implements RegisterCommand {
  private authSub: Subscription | undefined = undefined;
  private loggedInNavigationItems: CommandPaletteItem[] = [];
  constructor(
    @inject(CommandPaletteService) private cmpd: CommandPaletteService,
    @inject(AppWorkspace) private appWorkspace: AppWorkspace,
    @inject(AuthService) private authService: AuthService
  ) {}

  init() {
    this.authSub = this.authService.$.subscribe((auth: AuthClientState) => {
      this.onAuth(auth).catch(console.error);
    });
  }

  dispose() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  private async onAuth(auth: AuthClientState) {
    if (auth.isAuthenticated) {
      this.loggedInNavigationItems = this.navigationItems(auth);
      await this.cmpd.register(this.loggedInNavigationItems);
    } else {
      if (this.loggedInNavigationItems) {
        await this.cmpd.unregister(this.loggedInNavigationItems);
      }
    }
  }

  private navigationItems(auth: AuthClientState): CommandPaletteItem[] {
    return Object.entries({
      ...COMPONENT_DICTIONARY,
      ...FORM_DICTIONARY,
      ...LAYOUT_DICTIONARY
    })
      .filter(
        ([_, v]) =>
          (v.showInCommandPalette === undefined || v.showInCommandPalette === true) &&
          hasRequiredEntitlements(auth.roles, v.hasEntitlement ?? [])
      )
      .map(([_, value]) => {
        switch (value.type) {
          case 'form': {
            const { title, key, objectCategory } = value;
            return {
              id: `OPEN_${key}`,
              label: `Open ${title.toLowerCase()}`,
              category: COMMAND_PALETTE_GROUP.NAVIGATE,
              group: objectCategory,
              value: key,
              onSelect: async (_, { windowId }) => {
                try {
                  await openFormFromDictionary(key as keyof FormMap, this.getWindowIdToOpenFrom(windowId));
                } catch (error) {
                  console.error(error);
                }
              }
            };
          }
          case 'component': {
            const { title, key, objectCategory } = value;
            return {
              id: `OPEN_${key}`,
              label: `Open ${title.toLowerCase()}`,
              category: COMMAND_PALETTE_GROUP.NAVIGATE,
              group: objectCategory,
              value: key,
              onSelect: async (_, { windowId }) => {
                try {
                  await openComponentFromDictionary(
                    key as ComponentDictEnumKey,
                    this.getWindowIdToOpenFrom(windowId)
                  );
                } catch (error) {
                  console.error(error);
                }
              }
            };
          }
          case 'layout': {
            const { title, key, objectCategory } = value;

            return {
              id: `OPEN_${key}`,
              label: `Open ${title.toLowerCase()}`,
              category: COMMAND_PALETTE_GROUP.NAVIGATE,
              group: objectCategory,
              value: key,
              onSelect: async (_, { windowId }) => {
                try {
                  await openLayoutFromDictionary(
                    key as LayoutDictEnumKey,
                    this.getWindowIdToOpenFrom(windowId)
                  );
                } catch (error) {
                  console.error(error);
                }
              }
            };
          }
          default: {
            throw new Error(`Unknown dictionary item type`);
          }
        }
      });
  }

  private getWindowIdToOpenFrom(windowId: string) {
    return isTauri() ? PROCESS_ID.LEADER : windowId; // In browser we want windows to open in the root window
  }
}
