import {
  type Field,
  useFieldApi,
  type UseFieldApiConfig,
  useFormApi
} from '@data-driven-forms/react-form-renderer';
import {
  TabContent,
  Tabs as TabsComponent,
  TabsList,
  TabTrigger
} from '@oms/shared-frontend/ui-design-system';
import { type FC, useMemo } from 'react';
import { omit } from 'lodash';
import { type ITabField, type TabsConfig } from './tabs.type';
import { createTabsContentSpacingSx } from './tabs.util';

export const Tabs: FC<UseFieldApiConfig> = (props) => {
  const field = useFieldApi(props) as unknown as ITabField;
  const { tabs } = field;
  const { renderForm } = useFormApi();

  if (!tabs) {
    throw new Error('Missing tabs property.');
  }

  const tabsConfig: TabsConfig<string, Field>['config'] = useMemo(() => tabs.config, [tabs]);

  const tabKeys = useMemo(() => {
    return Object.keys(tabsConfig);
  }, [tabsConfig]);

  const tabsContentSpacingSx = useMemo(() => createTabsContentSpacingSx(4, 10), []);

  return (
    <TabsComponent
      {...omit(tabs, 'config')}
      sx={{
        height: 'full',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        ...(tabs.sx || {})
      }}
      style={tabs.style || {}}
    >
      <TabsList>
        {tabKeys.map((tk) => (
          <TabTrigger key={tk} value={tk}>
            {tabsConfig[tk].title}
          </TabTrigger>
        ))}
      </TabsList>

      {tabKeys.map((tk) => (
        <TabContent
          key={tk}
          value={tk}
          sx={{
            bgColor: tabsConfig[tk].contentBackground === false ? 'transparent' : 'layout.level2',
            ...(tabsConfig[tk].contentSpacing === false ? {} : tabsContentSpacingSx),
            height: 'full',
            flexGrow: 1,
            flexDirection: 'column',
            display: 'flex',
            ...(tabsConfig[tk].sx || {})
          }}
        >
          {renderForm(tabsConfig[tk].fields)}
        </TabContent>
      ))}
    </TabsComponent>
  );
};
