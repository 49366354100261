import { Actor } from '@valstro/workspace';
import type { RegistryDefinitionComponentWithCompKey } from '@app/app-config/registry.config';
import { BaseWindowOpenable, internalOpenInWindowActor } from './common.open';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';

/**
 * Component
 * -----------------------------------------------------------------------------
 */
export class Component<_TProps> extends BaseWindowOpenable<_TProps> {
  /**
   * Get a component by id.
   *
   * @param id - Component id
   * @returns Component
   */
  static async get<TProps>(id: string) {
    const actor = await Actor.get<AppWindowActorSchema>(id);
    if (actor.id === undefined) {
      throw new Error('Actor is undefined');
    }
    return new Component<TProps>(actor);
  }

  /**
   * Open a component using the component definition.
   *
   * @param definition - Component definition
   * @param parentActorOrId - Parent actor or parent actor id
   * @returns Component
   */
  static async open<TProps>(
    definition: RegistryDefinitionComponentWithCompKey<TProps>,
    parentActorOrId: Actor | string,
    lazy?: boolean
  ) {
    const [_, actor] = await internalOpenInWindowActor<TProps>(definition, parentActorOrId, lazy);
    if (actor.id === undefined) {
      throw new Error('Actor is undefined');
    }
    return new Component<TProps>(actor);
  }
}
