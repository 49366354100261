import {
  ActorOperationOptions,
  ActorSchemaBuilder,
  emitCustomActorEvent,
  internalWorkspace
} from '../../../core';
import { COMMON_PLATFORM_NAME } from '../../../core/platform';
import { getCurrentBrowserMonitorIndex } from '../../../core/platform.browser.util';
import {
  WindowContext,
  WindowOperations,
  COMMON_ACTOR_TYPE,
  debounce,
  WinSizeOptions,
  UserAttentionType,
  CommonWindowActorSchemaOptions,
  ClickEvent
} from '../../common';

export const BROWSER_TAB_WINDOW_ACTOR_NAME = 'browser-tab-window';

export const BrowserTabWindowActorSchemaBuilder = ActorSchemaBuilder.create<
  WindowContext,
  WindowOperations,
  any,
  CommonWindowActorSchemaOptions
>({
  name: BROWSER_TAB_WINDOW_ACTOR_NAME,
  type: COMMON_ACTOR_TYPE.WINDOW,
  supportedPlatforms: [COMMON_PLATFORM_NAME.BROWSER],
  isWindowRootable: true,
  syncOnStart: true,
  initialContext: async ({ initialContext, schema }) => {
    const defaultContext = schema.meta?.defaultContext || {};
    const final: WindowContext = {
      title: document.title,
      x: window.screenX,
      y: window.screenY,
      width: window.innerWidth,
      height: window.innerHeight,
      isMaximized: false,
      isMinimized: false,
      isVisible: true,
      isFocused: false,
      isDecorated: false,
      isFullscreen: false,
      scaleFactor: 1,
      skipTaskbar: false,
      alwaysOnTop: false,
      isPinned: false,
      isPinnable: false,
      currentMonitorIndex: await getCurrentBrowserMonitorIndex(),
      requestingUserAttention: null,
      isClosable: true,
      isMaximizable: true,
      isMinimizable: true,
      isResizable: true,
      meta: {},
      initiallyCentered: false,
      initiallyUseLogicalPixels: false,
      ...defaultContext,
      ...(initialContext || {})
    };

    return final;
  },
  render: async (url) => {
    window.open(url);
  },
  sync: async ({ contextDelta }) => {
    if (contextDelta.title) {
      document.title = contextDelta.title;
    }
  },
  destroy: async () => {
    window.close();
  },
  operations: ({ updateContext, destroy, getContext }) => ({
    setTitle: async (title: string, ops?: ActorOperationOptions) => {
      window.document.title = title;
      await updateContext({ title }, ops);
    },
    setPinned: async (isPinned: boolean, ops?: ActorOperationOptions) => {
      await updateContext({ isPinned }, ops);
    },
    setPosition: async (_, ops?: ActorOperationOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext(
        {
          x: window.screenX,
          y: window.screenY,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    setSize: async (_, ops?: ActorOperationOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext(
        {
          width: window.innerWidth,
          height: window.innerHeight,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    setSizeAndPosition: async (_, __, ops?: ActorOperationOptions) => {
      await updateContext(
        {
          width: window.innerWidth,
          height: window.innerHeight,
          x: window.screenX,
          y: window.screenY,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    resizeAroundOrigin: async () => {
      // noop
    },
    center: async (ops?: ActorOperationOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext(
        {
          x: window.screenX,
          y: window.screenY,
          width: window.innerWidth,
          height: window.innerHeight,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    close: async () => {
      const ctx = getContext();
      if (ctx.isClosable === false || ctx.isPinned === true) {
        return;
      }
      await destroy();
    },
    destroy: async () => {
      await destroy();
    },
    centerInActiveMonitor: async (ops?: ActorOperationOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext(
        {
          x: window.screenX,
          y: window.screenY,
          width: window.innerWidth,
          height: window.innerHeight,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    focus: async (ops?: ActorOperationOptions) => {
      await updateContext({ isFocused: true }, ops);
      window.focus();
    },
    hide: async (ops?: ActorOperationOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext(
        {
          isVisible: false
        },
        ops
      );
    },
    toggleMaximize: async () => {
      // Cannot be maximized
    },
    maximize: async () => {
      // Cannot be maximized
    },
    minimize: async () => {
      // Cannot be minimized
    },
    unmaximize: async () => {
      // Cannot be unmaximized
    },
    unminimize: async () => {
      // Cannot be unminimized
    },
    show: async (ops?: ActorOperationOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({ isVisible: true }, ops);
    },
    setAlwaysOnTop: async (alwaysOnTop: boolean, ops?: ActorOperationOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({ alwaysOnTop }, ops);
    },
    setDecorated: async (isDecorated: boolean) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({ isDecorated });
    },
    setFullscreen: async (isFullscreen: boolean, ops?: ActorOperationOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({ isFullscreen }, ops);
    },
    setMaximizable: async (isMaximizable: boolean) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({ isMaximizable });
    },
    setMinimizable: async (isMinimizable: boolean) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({ isMinimizable });
    },
    setResizable: async (isResizable: boolean) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({ isResizable });
    },
    setSkipTaskbar: async (skipTaskbar: boolean) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({ skipTaskbar });
    },
    setMaxSize: async ({ width, height }: WinSizeOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({
        maxWidth: width,
        maxHeight: height
      });
    },
    setMinSize: async ({ width, height }: WinSizeOptions) => {
      // This actor can't perform this op
      // But let's update the context anyway, in-case something changes in the view later
      await updateContext({
        maxWidth: width,
        maxHeight: height
      });
    },
    requestUserAttention: async (type: UserAttentionType) => {
      await updateContext({ requestingUserAttention: type });
    },
    focusAll: async () => {
      // TODO: Implement
    },
    setMeta: async (meta) => {
      await updateContext({ meta });
    },
    updateMeta: async (meta) => {
      const nextMeta = { ...getContext().meta, ...meta };
      await updateContext({ meta: nextMeta });
    },
    getInnerPosition: async () => {
      return {
        x: 0,
        y: 0,
        scaleFactor: 1
      };
    }
  }),
  events: ({ updateContext, getDefinition, destroy, workspace }) => {
    const eventsTransport = internalWorkspace(workspace)._eventsTransport;

    const handler = {
      click: async (e: MouseEvent) => {
        if (
          e.target &&
          'getAttribute' in e.target &&
          (e.target as HTMLElement).getAttribute('data-suppress-event') === 'click--inside'
        ) {
          return;
        }

        const innerMousePos = {
          x: e.clientX,
          y: e.clientY
        };

        const mousePos = {
          x: 0 + innerMousePos.x,
          y: 0 + innerMousePos.y
        };

        emitCustomActorEvent<ClickEvent>(eventsTransport, getDefinition(), {
          type: 'click',
          payload: {
            ...mousePos,
            type: 'inside'
          }
        }).catch(console.error);
      },
      beforeunload: () => {
        destroy().catch(console.error);
      },
      blur: async () => {
        await updateContext({ isFocused: false });
      },
      focus: async () => {
        await updateContext({ isFocused: true });
      },
      resize: debounce(async () => {
        await updateContext({
          x: window.screenX,
          y: window.screenY,
          width: window.innerWidth,
          height: window.innerHeight,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        });
      }, 100),
      move: debounce(async () => {
        // Browser doesn't support move events, so we have to poll
        await updateContext({
          width: window.innerWidth,
          height: window.innerHeight,
          x: window.screenX,
          y: window.screenY,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        });
      }, 100)
    } as const;

    /* eslint-disable @typescript-eslint/no-misused-promises */
    document.addEventListener('click', handler.click);
    /* eslint-disable @typescript-eslint/no-misused-promises */
    window.addEventListener('beforeunload', handler.beforeunload);
    /* eslint-disable @typescript-eslint/no-misused-promises */
    window.addEventListener('resize', handler.resize);
    /* eslint-disable @typescript-eslint/no-misused-promises */
    window.addEventListener('blur', handler.blur);
    /* eslint-disable @typescript-eslint/no-misused-promises */
    window.addEventListener('focus', handler.focus);
    /* eslint-disable @typescript-eslint/no-misused-promises */
    const moveInterval = setInterval(handler.move, 500);
    return () => {
      /* eslint-disable @typescript-eslint/no-misused-promises */
      document.removeEventListener('click', handler.click);
      /* eslint-disable @typescript-eslint/no-misused-promises */
      window.removeEventListener('beforeunload', handler.beforeunload);
      /* eslint-disable @typescript-eslint/no-misused-promises */
      window.removeEventListener('resize', handler.resize);
      /* eslint-disable @typescript-eslint/no-misused-promises */
      window.removeEventListener('blur', handler.blur);
      /* eslint-disable @typescript-eslint/no-misused-promises */
      window.removeEventListener('focus', handler.focus);
      clearInterval(moveInterval);
    };
  }
});

export type BrowserTabWindowActorSchema = typeof BrowserTabWindowActorSchemaBuilder.schema;
