import { ParameterQuery, ParameterTypes, Use } from '@valstro/fixatdl-core';

export interface LengthConfig {
  comparator: number;
  required?: boolean;
  message?: string;
}

type ValidationResult = string | null | undefined;

export const minValue: (cfg: LengthConfig) => (val: any) => ValidationResult =
  ({ comparator, message = `Minimum is ${comparator}.`, required = false }) =>
  (value: any) => {
    const validValue = ParameterQuery.from({
      type: ParameterTypes.String,
      minValue: comparator,
      name: 'memes',
      use: required ? Use.Required : Use.Optional
    }).minValue(value);

    return validValue ? undefined : message;
  };

export const maxValue: (
  cfg: LengthConfig
) => (val: any, allValues: Record<string, any>) => ValidationResult =
  ({ comparator, message = `Maximum is ${comparator}.`, required = false }) =>
  (value: any) => {
    const validValue = ParameterQuery.from({
      type: ParameterTypes.String,
      maxValue: comparator,
      name: 'memes',
      use: required ? Use.Required : Use.Optional
    }).maxValue(value);

    return validValue ? undefined : message;
  };

export const minLength: (cfg: LengthConfig) => (val: any) => ValidationResult =
  ({ comparator, message = `Minimum length is ${comparator}.`, required = false }) =>
  (value: any) => {
    const validLength = ParameterQuery.from({
      type: ParameterTypes.String,
      minLength: comparator,
      name: 'memes',
      use: required ? Use.Required : Use.Optional
    }).minLength(value);

    return validLength ? undefined : message;
  };

export const maxLength: (
  cfg: LengthConfig
) => (val: any, allValues: Record<string, any>) => ValidationResult =
  ({ comparator, message = `Maximum length is ${comparator}.`, required = false }) =>
  (value: any) => {
    const validLength = ParameterQuery.from({
      type: ParameterTypes.String,
      maxLength: comparator,
      name: 'memes',
      use: required ? Use.Required : Use.Optional
    }).maxLength(value);

    return validLength ? undefined : message;
  };
