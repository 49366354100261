import { WindowDecorationComponent as Window } from '@valstro/workspace-react';
import { PropsWithChildren } from 'react';
import { useCurrentPopoverDialog } from './popover.hooks';

export function PopoverWidgetDecoration({
  children,
  ...decorationProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const { ariaLabel, popoverId, isOpen, popoverTestId } = useCurrentPopoverDialog();
  return (
    <Window.Container
      {...decorationProps}
      role="dialog"
      id={`${popoverId}-dialog`}
      data-id={`${popoverId}-dialog`}
      data-popover-dialog-id={popoverId}
      data-state={isOpen ? 'open' : 'closed'}
      data-testid={popoverTestId}
      aria-label={ariaLabel}
    >
      <Window.ContentContainer>{children}</Window.ContentContainer>
    </Window.Container>
  );
}
