import type { ApolloQueryResult, FetchPolicy, FetchResult } from '@apollo/client';
import type { SimpleInvestorAccount } from '@app/common/types/accounts/types';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import {
  MontageFlexLayoutContext,
  MontageLayoutProps
} from '@app/widgets/trading/montage/montage.layout.config';
import type { AwaitGQLResultType, DataSourceCommon } from '@oms/frontend-foundation';
import { asObservableDataSource } from '@oms/frontend-foundation';
import type {
  ApplyManualMarkRequestInput,
  ApplyManualMarkRequestMutation,
  ApplyManualMarkRequestMutationVariables,
  CreatePositionAdjustmentInput,
  CreatePositionAdjustmentMutation,
  CreatePositionAdjustmentMutationVariables,
  GetMontagePositionQuantityQuery,
  GetMontagePositionQuantityQueryVariables,
  GetPositionsTreeForAccountQuery,
  GetPositionsTreeForAccountQueryVariables,
  GetSimplePositionQuery,
  GetSimplePositionQueryVariables,
  OnMontagePositionUpdatedSubscription,
  OnMontagePositionUpdatedSubscriptionVariables,
  OnPositionsValuationUpdatedSubscription,
  OnPositionsValuationUpdatedSubscriptionVariables,
  PositionPreviewFragment,
  PositionsTreeForAccountFragment,
  PositionsValuationUpdatedFragment,
  PreviewPositionAdjustmentQuery,
  PreviewPositionAdjustmentQueryVariables,
  PreviewPositionTransferQuery,
  PreviewPositionTransferQueryVariables,
  RemoveManualMarkRequestInput,
  RemoveManualMarkRequestMutation,
  RemoveManualMarkRequestMutationVariables,
  TransferPositionRequestInput,
  TransferPositionRequestMutation,
  TransferPositionRequestMutationVariables
} from '@oms/generated/frontend';
import {
  ApplyManualMarkRequestDocument,
  CreatePositionAdjustmentDocument,
  GetMontagePositionQuantityDocument,
  GetPositionsTreeForAccountDocument,
  GetSimplePositionDocument,
  InvestorAccountType,
  OnMontagePositionUpdatedDocument,
  OnPositionsValuationUpdatedDocument,
  PreviewPositionAdjustmentDocument,
  PreviewPositionTransferDocument,
  RemoveManualMarkRequestDocument,
  TransferPositionRequestDocument
} from '@oms/generated/frontend';
import { Logger, cleanMaybe, compactMap } from '@oms/shared/util';
import type { Maybe, Optional } from '@oms/shared/util-types';
import { Actor } from '@valstro/workspace';
import { FlexLayoutActorSchema } from '@valstro/workspace-react';
import { uniqBy } from 'lodash';
import type { OperatorFunction, Subscription } from 'rxjs';
import {
  Observable,
  Subject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  map,
  merge,
  of,
  share,
  startWith,
  switchMap,
  take
} from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { NestedTreeData } from './common/tree-grid/types/tree-data.types';
import PositionRowTool from './helpers/position-row-tool.internal.class';
import PositionTransferFormState from './helpers/position-transfer-form-state.internal.class';
import { extractTreeIds } from './util/positions.util';
import type { ExtractedTreeIdMaps } from './util/types';
import { testScoped } from '@app/workspace.registry';

type UnsubscribeWrapper = {
  unsubscribe: () => void;
};

@testScoped
@singleton()
export class PositionsService {
  protected name: string = 'PositionsService';
  protected logger: Logger;

  protected positionsSubject = new Subject<Partial<PositionsValuationUpdatedFragment>>();
  protected subscription?: Subscription;

  protected fetchPolicy: FetchPolicy = 'cache-first';
  protected pollInterval: number = 5000;

  protected transferState: PositionTransferFormState;

  // 🏗️ Constructor ------------------------------------------------------- /

  constructor(
    @inject(ApolloClientRPC) protected apolloClient: ApolloClientRPC,
    @inject(GQLResponse) protected gqlResponse: GQLResponse
  ) {
    this.logger = Logger.labeled(this.name);
    this.transferState = new PositionTransferFormState();
  }

  // 🍱 Grid ------------------------------------------------------------- /

  /**
   * Initializes the subscription to Positions data as needed.
   * This can be safely called multiple times and will only subscribe on the first usage.
   * > **IMPORTANT:** Remember to use the supplied `unsubscribe` function to clean up.
   *
   * @returns An object containing a function that can be called to clean up the subscription.
   */
  public subscribe(): UnsubscribeWrapper {
    const unsubscribe = this.unsubscribe.bind(this);
    if (this.subscription) return { unsubscribe };
    this.subscription = this.subscribeToPositionsValuationUpdates()
      .pipe(map(({ data }) => cleanMaybe(data?.positionsValuationUpdated, {})))
      .subscribe((data) => {
        this.positionsSubject.next(data);
      });
    return { unsubscribe };
  }

  /**
   * Data for the Positions Account (upper) grid
   * > **IMPORTANT:** The Positions subscription must be started with the `subscribe` method.
   *
   * @returns An Observable of Positions Tree data for the Positions Account (upper) grid.
   */
  public positionsTree$(): Observable<DataSourceCommon<NestedTreeData<PositionRow>>> {
    return merge(
      this.watchQuery_GetPositionsTreeForAccountQuery$().pipe(
        map((trees) => PositionRowTool.for(trees).getTreeDataPositionRows()),
        take(1)
      ),
      this.positionsTreeSubscription$()
    ).pipe(
      asObservableDataSource({
        onError: (e) => {
          this.logger.scope('watchAll$').error(e);
        }
      })
    );
  }

  /**
   * Data for the Positions Instrument (lower) grid
   * > **IMPORTANT:** The Positions subscription must be started with the `subscribe` method.
   *
   * @param accountId - Pass the account ID (if any), selected on the Positions Account (upper) grid.
   * @returns An Observable of Positions data for the Positions Instrument (lower) grid.
   */
  public positionsForAccount$(accountId?: string): Observable<DataSourceCommon<PositionRow>> {
    return merge(
      this.watchQuery_GetPositionsTreeForAccountQuery$(accountId).pipe(
        map((trees) => PositionRowTool.for(trees).getAccountPositionRows(accountId)),
        take(1)
      ),
      this.positionsForAccountSubscription$(accountId)
    ).pipe(
      asObservableDataSource({
        onError: (e) => {
          this.logger.scope('getPositionsForAccount$').error(e);
        }
      })
    );
  }

  /** For the current user: get a simple account/position mapping */
  public async getAccountPositionMapping(): Promise<Maybe<ExtractedTreeIdMaps>> {
    return await firstValueFrom(
      this.watchQuery_GetPositionsTreeForAccountQuery$().pipe(
        map((trees) => (trees.length ? extractTreeIds(trees) : null))
      )
    );
  }

  // 💁 Position info ------------------------------------------------------- /

  public watchPositionsQuantityUpdated$(scopedActorId: string) {
    return combineLatest([
      this.getInstrumentIdFromAppDatabase(scopedActorId),
      this.getInvestorAccountId$(scopedActorId)
    ]).pipe(
      filter(
        ([accountId, instrumentId]) => typeof accountId === 'string' && typeof instrumentId === 'string'
      ),
      map(([accountId, instrumentId]) => ({
        accountId: accountId as string,
        instrumentId: instrumentId as string
      })),
      switchMap(({ accountId, instrumentId }) =>
        merge(
          this.watchQuery_getMontagePositionQuantity$(accountId, instrumentId),
          this._subscribe_OnMontagePositionUpdatedSubscription$(accountId, instrumentId)
        )
      )
    );
  }

  public async getSimplePositionInfo(id: string): Promise<Optional<PositionPreviewFragment>> {
    const result = await this.gqlResponse
      .wrapQuery<GetSimplePositionQuery, GetSimplePositionQueryVariables>({
        query: GetSimplePositionDocument,
        variables: { id },
        fetchPolicy: this.fetchPolicy
      })
      .exec();
    return result.mapTo(
      ({ data }) => cleanMaybe(data.position),
      (errors) => {
        errors.forEach((e) => {
          this.logger.scope('getSimplePositionInfo').error(e);
        });
        return undefined;
      }
    );
  }

  public simplePositionInfo$(id: string): Observable<Optional<PositionPreviewFragment>> {
    return this.apolloClient
      .watchQuery<GetSimplePositionQuery, GetSimplePositionQueryVariables>({
        query: GetSimplePositionDocument,
        variables: { id },
        fetchPolicy: this.fetchPolicy
      })
      .pipe(
        map(({ data }) => cleanMaybe(data.position)),
        startWith(undefined),
        catchError((e) => {
          this.logger.scope('simplePositionInfo$').error(e);
          return of(undefined);
        })
      );
  }

  // ✏️ Forms ------------------------------------------------------- /

  public getInvestorAccounts$(accountId?: string): Observable<DataSourceCommon<SimpleInvestorAccount>> {
    return this.watchQuery_GetPositionsTreeForAccountQuery$(accountId).pipe(
      map((trees) => PositionRowTool.for(trees).simpleInvestorAccounts),
      asObservableDataSource({
        onError: (e) => {
          this.logger.scope('getInvestorAccounts$').error(e);
        }
      })
    );
  }

  public getInvestorAccountsFor$(
    type: Optional<'buyer' | 'seller' | 'any'>,
    accountId?: string
  ): Observable<DataSourceCommon<SimpleInvestorAccount>> {
    switch (type) {
      case 'buyer':
        return this.getInvestorAccountsDisablingOtherId$(this.transferState.sellerId$, accountId);
      case 'seller':
        return this.getInvestorAccountsDisablingOtherId$(this.transferState.buyerId$, accountId);
      default:
        return this.getInvestorAccounts$(accountId);
    }
  }

  public updateTransferFormState(formValues: Parameters<typeof this.transferState.update>[0]): void {
    this.transferState.update(formValues);
  }

  public resetTransferFormState(): void {
    this.transferState.reset();
  }

  // 🔧 Adjustment ------------------------------------------------------- /

  public async createPositionAdjustment(
    input?: CreatePositionAdjustmentInput
  ): AwaitGQLResultType<CreatePositionAdjustmentMutation> {
    return await this.gqlResponse
      .wrapMutate<CreatePositionAdjustmentMutation, CreatePositionAdjustmentMutationVariables>({
        mutation: CreatePositionAdjustmentDocument,
        variables: { input },
        refetchQueries: [GetPositionsTreeForAccountDocument],
        awaitRefetchQueries: true
      })
      .exec();
    // TODO: Move this to form/widget logic
    // if (result.isSuccess()) {
    //   this._platformApiService.notification
    //     .notify({
    //       title: isFlatten
    //         ? t('app.positions.dialog.flatten.title')
    //         : t('app.positions.dialog.adjustment.title'),
    //       body: isFlatten
    //         ? t('app.positions.dialog.flatten.body')
    //         : t('app.positions.dialog.adjustment.body'),
    //       status: 'success'
    //     })
    //     .catch((e) => {
    //       this.logger.error(e);
    //     });
    // }
  }

  public previewPositionAdjustment$(
    data: CreatePositionAdjustmentInput
  ): Observable<ApolloQueryResult<PreviewPositionAdjustmentQuery>> {
    return this.apolloClient.watchQuery<
      PreviewPositionAdjustmentQuery,
      PreviewPositionAdjustmentQueryVariables
    >({
      query: PreviewPositionAdjustmentDocument,
      variables: { data },
      fetchPolicy: this.fetchPolicy
    });
  }

  // 🚚 Transfer ------------------------------------------------------- /

  public async transferPosition(
    input?: TransferPositionRequestInput
  ): AwaitGQLResultType<TransferPositionRequestMutation> {
    return await this.gqlResponse
      .wrapMutate<TransferPositionRequestMutation, TransferPositionRequestMutationVariables>({
        mutation: TransferPositionRequestDocument,
        variables: { input },
        refetchQueries: [GetPositionsTreeForAccountDocument],
        awaitRefetchQueries: true
      })
      .exec();
    // TODO: Move this to form/widget logic
    // if (result.isSuccess()) {
    //   this._platformApiService.notification
    //     .notify({
    //       title: 'Position transfer',
    //       body: 'Position transfer has been created',
    //       status: 'success'
    //     })
    //     .catch((e) => {
    //       this.logger.error(e);
    //     });
    // }
  }

  public previewPositionTransfer$(
    data: TransferPositionRequestInput
  ): Observable<ApolloQueryResult<PreviewPositionTransferQuery>> {
    return from(
      this.apolloClient.query<PreviewPositionTransferQuery, PreviewPositionTransferQueryVariables>({
        query: PreviewPositionTransferDocument,
        variables: { data },
        fetchPolicy: this.fetchPolicy
      })
    );
  }

  // ✔️ Mark ------------------------------------------------------- /

  public async markPosition(
    input: ApplyManualMarkRequestInput
  ): AwaitGQLResultType<ApplyManualMarkRequestMutation> {
    return await this.gqlResponse
      .wrapMutate<ApplyManualMarkRequestMutation, ApplyManualMarkRequestMutationVariables>({
        mutation: ApplyManualMarkRequestDocument,
        variables: { input },
        refetchQueries: [GetPositionsTreeForAccountDocument],
        awaitRefetchQueries: true
      })
      .exec();
    // TODO: Move this to form/widget logic
    // if (result.isSuccess()) {
    //   this._platformApiService.notification
    //     .notify({
    //       title: 'Set valuation price',
    //       body: 'Valuation price has been set',
    //       status: 'success'
    //     })
    //     .catch((e) => {
    //       this.logger.error(e);
    //     });
    // }
  }

  // 🗙 Unmark ------------------------------------------------------- /

  public async unmarkPosition(
    input: RemoveManualMarkRequestInput
  ): AwaitGQLResultType<RemoveManualMarkRequestMutation> {
    return await this.gqlResponse
      .wrapMutate<RemoveManualMarkRequestMutation, RemoveManualMarkRequestMutationVariables>({
        mutation: RemoveManualMarkRequestDocument,
        variables: { input },
        refetchQueries: [GetPositionsTreeForAccountDocument],
        awaitRefetchQueries: true
      })
      .exec();
    // TODO: Move this to form/widget logic
    // if (result.isSuccess()) {
    //   this._platformApiService.notification
    //     .notify({
    //       title: 'Valuation price removed',
    //       body: 'Valuation has been removed',
    //       status: 'success'
    //     })
    //     .catch((e) => {
    //       this.logger.error(e);
    //     });
    // }
  }

  // 🔒 Protected --------------------------------------------------------- /

  protected getFlexLayoutActorProps(scopedActorId: string): Observable<MontageLayoutProps> {
    return from(Actor.get<FlexLayoutActorSchema>(scopedActorId)).pipe(
      switchMap((actor) =>
        new Observable<MontageFlexLayoutContext>((observer) => {
          actor
            .context()
            .then((context) => {
              observer.next(context);
            })
            .catch(console.error);

          const unlisten = actor.listen('context', (context) => {
            observer.next(context);
          });

          return () => {
            unlisten();
          };
        }).pipe(
          map((context) => context.componentProps),
          distinctUntilChanged()
        )
      )
    );
  }

  protected getInstrumentIdFromAppDatabase(scopedActorId: string): Observable<Optional<string>> {
    return this.getFlexLayoutActorProps(scopedActorId).pipe(
      map((document) => document?.instrumentId),
      distinctUntilChanged()
    );
  }

  protected getInvestorAccountId$(scopedActorId: string): Observable<Optional<string>> {
    return this.getFlexLayoutActorProps(scopedActorId).pipe(
      map((document) => document?.investorAccountId),
      distinctUntilChanged()
    );
  }

  protected unimplemented(feature: string) {
    this.logger.log(`Feature not yet implemented: ${feature}`);
  }

  // 📩 Positions subscriptions -------------------------------- /

  public watchQuery_getMontagePositionQuantity$(instrumentId: string, accountId: string): Observable<number> {
    return this.apolloClient
      .watchQuery<GetMontagePositionQuantityQuery, GetMontagePositionQuantityQueryVariables>({
        query: GetMontagePositionQuantityDocument,
        variables: { accountId, instrumentId },
        fetchPolicy: this.fetchPolicy
      })
      .pipe(map(({ data }) => cleanMaybe(data?.getPositionByAccountIdInstrumentId?.quantity, 0)));
  }

  public watchQuery_getMontagePositionQuantityWithAccountType$(
    instrumentId: string,
    accountId: string
  ): Observable<{
    quantity: number;
    accountType: InvestorAccountType | null;
  }> {
    return this.apolloClient
      .watchQuery<GetMontagePositionQuantityQuery, GetMontagePositionQuantityQueryVariables>({
        query: GetMontagePositionQuantityDocument,
        variables: { accountId, instrumentId },
        fetchPolicy: this.fetchPolicy
      })
      .pipe(
        map(({ data }) => {
          return {
            quantity: cleanMaybe(data?.getPositionByAccountIdInstrumentId?.quantity, 0),
            accountType: data?.getPositionByAccountIdInstrumentId?.account?.accountType || null
          };
        })
      );
  }

  protected _subscribe_OnMontagePositionUpdatedSubscription$(
    instrumentId: string,
    accountId: string
  ): Observable<number> {
    return this.apolloClient
      .subscribe<OnMontagePositionUpdatedSubscription, OnMontagePositionUpdatedSubscriptionVariables>({
        query: OnMontagePositionUpdatedDocument,
        variables: { accountId, instrumentId },
        fetchPolicy: this.fetchPolicy
      })
      .pipe(map(({ data }) => cleanMaybe(data?.positionDataUpdated?.position?.quantity, 0)));
  }

  protected subscribeToPositionsValuationUpdates(): Observable<
    FetchResult<OnPositionsValuationUpdatedSubscription>
  > {
    return this.apolloClient.subscribe<
      OnPositionsValuationUpdatedSubscription,
      OnPositionsValuationUpdatedSubscriptionVariables
    >({
      query: OnPositionsValuationUpdatedDocument,
      fetchPolicy: this.fetchPolicy
    });
  }

  protected positionsTreeSubscription$(): Observable<NestedTreeData<PositionRow>[]> {
    return this.positionsSubject.asObservable().pipe(
      this.getPositionsTreesFromValuationUpdate(),
      map((trees) => PositionRowTool.for(trees).getTreeDataPositionRows())
    );
  }

  protected positionsForAccountSubscription$(accountId?: string): Observable<PositionRow[]> {
    return this.positionsSubject.asObservable().pipe(
      this.getPositionsTreesFromValuationUpdate(),
      map((trees) => PositionRowTool.for(trees).getAccountPositionRows(accountId)),
      map((rows) => uniqBy(rows, ({ id }) => id))
    );
  }

  // 🧹 Cleanup ---------- /

  /**
   * Note that this will *not* actually remove the Positions subscription until all Positions windows are unsubscribed.
   */
  protected unsubscribe() {
    if (this.positionsSubject.observed) return;
    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }

  // 🔍 Watch queries and queries -------------------------------- /

  protected watchQuery_GetPositionsTreeForAccountQuery$(
    accountId?: string
  ): Observable<PositionsTreeForAccountFragment[]> {
    return this.apolloClient
      .watchQuery<GetPositionsTreeForAccountQuery, GetPositionsTreeForAccountQueryVariables>({
        query: GetPositionsTreeForAccountDocument,
        variables: {
          accountId: accountId ?? ''
        },
        fetchPolicy: this.fetchPolicy
      })
      .pipe(
        map(({ data }) => compactMap(cleanMaybe(data?.getPositionsTreeForAccount, []))),
        share()
      );
  }

  // 🛠️ Utility -------------------------------- /

  protected getInvestorAccountsDisablingOtherId$(
    otherId$: Observable<Optional<string>>,
    accountId?: string
  ): Observable<DataSourceCommon<SimpleInvestorAccount>> {
    return combineLatest([this.getInvestorAccounts$(accountId), otherId$]).pipe(
      map(([data, otherId]) => {
        if (!otherId) return data;
        const { results, ...rest } = data;
        if (!results) return data;
        return {
          results: results.map(({ id, ...accountRest }) => ({
            id,
            ...accountRest,
            isDisabled: id === otherId
          })),
          ...rest
        };
      })
    );
  }

  // 📥 Operator functions -------------------------------- /

  protected getPositionsTreesFromValuationUpdate(): OperatorFunction<
    Partial<PositionsValuationUpdatedFragment>,
    PositionsTreeForAccountFragment[]
  > {
    return (observable$) => observable$.pipe(map(({ rollup }) => compactMap(cleanMaybe(rollup, []))));
  }

  // Static ------------------------------------------------------------------------------ /

  /**
   * Use for subscriptions
   *
   * @param fromSubscription Pass subscription result
   * @returns An array of Positions trees
   */
  protected static extractTree(
    fromSubscription: OnPositionsValuationUpdatedSubscription
  ): PositionsTreeForAccountFragment[];

  /**
   * Use for queries
   *
   * @param fromQuery Pass query result
   * @returns An array of Positions trees
   */
  protected static extractTree(fromQuery: GetPositionsTreeForAccountQuery): PositionsTreeForAccountFragment[];

  // Implementation only ------- /
  protected static extractTree(
    data?: OnPositionsValuationUpdatedSubscription | GetPositionsTreeForAccountQuery
  ): PositionsTreeForAccountFragment[] {
    const possiblePositionsTree = (() => {
      if (!data) return;
      switch (data.__typename) {
        case 'Subscription':
          return data.positionsValuationUpdated?.rollup;
        case 'Query':
          return data.getPositionsTreeForAccount;
      }
    })();
    return compactMap(cleanMaybe(possiblePositionsTree, []));
  }
}

export default PositionsService;
