import { openInvestorOrderView } from '@app/generated/sdk';
import type { VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment } from '@oms/generated/frontend';
import { getOrderDialogTitle } from '../orders.utils';
import { useLeaderOrTabId } from '@app/common/workspace/workspace.hooks';
import { useCallback } from 'react';

export interface IOpenViewInvestorOrderArgs {
  windowId: string;
  investorOrderData: IOFragment;
}

export const openViewInvestorOrder = async ({ windowId, investorOrderData }: IOpenViewInvestorOrderArgs) => {
  await openInvestorOrderView(windowId, {
    componentProps: {
      id: investorOrderData.id
    },
    title: getOrderDialogTitle(investorOrderData)
  });
};

export const useOpenViewInvestorOrder = (investorOrderData?: IOFragment | null) => {
  const windowId = useLeaderOrTabId();
  return useCallback(() => {
    if (!investorOrderData) {
      console.error('investorOrderData is null or undefined');
      return;
    }
    openViewInvestorOrder({ windowId, investorOrderData }).catch((e) => console.error(e));
  }, [windowId, investorOrderData]);
};
