import { inject, injectable } from 'tsyringe';
import { scan } from 'rxjs';
import type { Observable } from 'rxjs';
import { MarketDataService } from '@app/data-access/services/marketdata/marketdata.service';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import type {
  TableServerRow,
  TableServerSubscriptionShape,
  TableServerSubscriptionInnerShape,
  TableServerRowSubscriptionVariables
} from './table-server.datasource.contracts';
import type { DocumentNode } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import { applyPatch } from 'fast-json-patch';

export interface TableServerQueryOptions<
  TData extends TableServerRow,
  TSubscription extends TableServerSubscriptionShape<TData>
> {
  /** The query to use for the table server */
  query: DocumentNode;
  /** Mapper function to find & map rows to inner data */
  getData: (result: TSubscription) => TableServerSubscriptionInnerShape<TData> | undefined;
  /** The variables to use for the table server query */
  variables: TableServerRowSubscriptionVariables;
  /** Adds ticker data to market data columns. Default: `false` */
  includeMarketData?: boolean;
  /** Market data ticker column name. Default: `instrumentDisplayCode` */
  tickerColumnName?: keyof TData;
}

export type TableServerQueryInnerData<TData> = {
  rows?: TData[];
  patch?: string;
  queryInfo: {
    totalCount: number;
    queryCount: number;
  };
};

export type TableServerQueryResult<TData> = {
  errors?: readonly GraphQLError[] | undefined;
  rows?: TData[];
  totalCount: number;
};

@injectable()
export class TableServerService {
  constructor(
    @inject(MarketDataService) private marketDataService: MarketDataService,
    @inject(ApolloClientRPC) private apolloClientRPC: ApolloClientRPC
  ) {}

  /**
   * Observable that subscribes to a table server query
   *
   * @param options {TOptions extends TableServerQueryOptions<TSubscription, TData>}
   * @returns {Observable<TableServerQueryResult<TData>>}
   */
  public query$<TData extends TableServerRow, TSubscription extends TableServerSubscriptionShape<TData>>({
    query,
    variables,
    getData,
    includeMarketData = false,
    tickerColumnName = 'instrumentDisplayCode'
  }: TableServerQueryOptions<TData, TSubscription>): Observable<TableServerQueryResult<TData>> {
    return this.apolloClientRPC
      .subscribe<TSubscription, TableServerRowSubscriptionVariables>({
        query,
        fetchPolicy: 'no-cache',
        variables
      })
      .pipe(
        scan((acc, result) => {
          // GraphQL error handling
          if (result.errors && result.errors.length) {
            return {
              ...TableServerService.EMPTY_RESULT,
              errors: result.errors
            };
          }

          // No data
          if (!result.data) {
            return TableServerService.EMPTY_RESULT;
          }

          try {
            const { patch, rows = [], queryInfo } = getData(result.data) || {};
            const totalCount = queryInfo?.totalCount || 0;

            // Apply patch to existing rows (if available)
            if (patch) {
              const prevRows = acc.rows || [];
              const newRows = applyPatch(prevRows, JSON.parse(patch)).newDocument;
              return {
                ...TableServerService.EMPTY_RESULT,
                rows: this.enrich<TData, TSubscription>({
                  includeMarketData,
                  tickerColumnName,
                  data: newRows
                }),
                totalCount
              };
            }

            // Return initial rows
            return {
              ...TableServerService.EMPTY_RESULT,
              rows: this.enrich<TData, TSubscription>({
                includeMarketData,
                tickerColumnName,
                data: rows
              }),
              totalCount
            };
          } catch (e) {
            // General error handling
            console.error(e);
            return TableServerService.EMPTY_RESULT;
          }
        }, TableServerService.EMPTY_RESULT as TableServerQueryResult<TData>)
      );
  }

  /**
   * Adds ticker data to market data columns (if enabled)
   *
   * @param data {TData[]}
   * @returns {TData[]}
   */
  private enrich<TData extends TableServerRow, TSubscription extends TableServerSubscriptionShape<TData>>({
    data,
    includeMarketData,
    tickerColumnName
  }: Pick<TableServerQueryOptions<TData, TSubscription>, 'includeMarketData' | 'tickerColumnName'> & {
    data: TData[];
  }): TData[] {
    if (!includeMarketData || !tickerColumnName) {
      return data;
    }

    return data.map((d) => {
      const ticker = d[tickerColumnName];

      if (!ticker || typeof ticker !== 'string') {
        return d;
      }

      return {
        ...d,
        ...this.marketDataService.read(ticker)
      };
    });
  }

  /**
   * Empty result object for table server queries
   */
  static EMPTY_RESULT = {
    rows: [],
    errors: [],
    totalCount: 0
  };
}
