import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import type { AnyRecord } from '../../common/type.helpers';
import type {
  FormSaveType,
  OnSanitizedValuesChangedCtx,
  OnValuesChangedCtx,
  OnValuesChangingCtx
} from './form-builder.common.types';

/**
 * Constants for form builder events
 */
export const FORM_EVENT_TYPE = {
  MOUNT: 'MOUNT',
  UNMOUNT: 'UNMOUNT',
  SUBMIT: 'SUBMIT',
  SUBMIT_SANITIZED: 'SUBMIT_SANITIZED',
  SUBMIT_FINISHED_SUCCESS: 'SUBMIT_FINISHED_SUCCESS',
  SUBMIT_FINISHED_ERRORS: 'SUBMIT_FINISHED_ERRORS',
  RESET: 'RESET',
  VALUES_CHANGING: 'VALUES_CHANGING',
  VALUES_CHANGED: 'VALUES_CHANGED',
  REMOTE_VALUES_CHANGED: 'REMOTE_VALUES_CHANGED',
  SANITIZED_VALUES_CHANGED: 'SANITIZED_VALUES_CHANGED',
  REQUEST_INITIAL_VALUES: 'REQUEST_INITIAL_VALUES'
} as const;

export type FormBuilderEventMeta = {
  formId: string;
  formSaveType: FormSaveType;
  formType?: string;
  formBuilderId: string;
  widgetId: string;
  windowId: string;
};

/**
 * Form events from renderer to form builder change
 */
export type FormBuilderEventMount = {
  type: typeof FORM_EVENT_TYPE.MOUNT;
  meta: FormBuilderEventMeta;
};

export type FormBuilderEventUnmount = {
  type: typeof FORM_EVENT_TYPE.UNMOUNT;
  meta: FormBuilderEventMeta;
};

export type FormBuilderEventSubmit<
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord = AnyRecord
> = {
  type: typeof FORM_EVENT_TYPE.SUBMIT;
  payload: {
    output: TOutputContract;
    formValues: Partial<TFormFieldValues>;
    modifiedFields: Array<string & keyof TFormFieldValues>;
  };
  meta: FormBuilderEventMeta;
};

export type FormBuilderEventSubmitFinishedSuccess<
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord = AnyRecord
> = {
  type: typeof FORM_EVENT_TYPE.SUBMIT_FINISHED_SUCCESS;
  payload: {
    output: TOutputContract;
    formValues: Partial<TFormFieldValues>;
  };
  meta: FormBuilderEventMeta;
};

export type FormBuilderEventSubmitFinishedErrors<TFormFieldValues extends AnyRecord = AnyRecord> = {
  type: typeof FORM_EVENT_TYPE.SUBMIT_FINISHED_ERRORS;
  payload: {
    errors: {
      [K in keyof TFormFieldValues]?: string;
    };
  };
  meta: FormBuilderEventMeta;
};

export type FormBuilderEventReset = {
  type: typeof FORM_EVENT_TYPE.RESET;
  meta: FormBuilderEventMeta;
};

export type FormBuilderEventValuesChanging<TFormFieldValues extends AnyRecord> = {
  type: typeof FORM_EVENT_TYPE.VALUES_CHANGING;
  payload: OnValuesChangingCtx<TFormFieldValues>;
  meta: FormBuilderEventMeta;
};

export type FormBuilderEventValuesChanged<TFormFieldValues extends AnyRecord> = {
  type: typeof FORM_EVENT_TYPE.VALUES_CHANGED;
  payload: OnValuesChangedCtx<TFormFieldValues>;
  meta: FormBuilderEventMeta;
};

export type FormBuilderEventSanitizedValuesChanged<
  TFormFieldValues extends AnyRecord,
  TOutputContract extends AnyRecord
> = {
  type: typeof FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED;
  payload: OnSanitizedValuesChangedCtx<TFormFieldValues, TOutputContract>;
  meta: FormBuilderEventMeta;
};

export type RemoteFormBuilderEventValuesChanged<TFormFieldValues extends AnyRecord> = {
  type: typeof FORM_EVENT_TYPE.REMOTE_VALUES_CHANGED;
  payload: OnValuesChangedCtx<TFormFieldValues>;
  meta: Omit<FormBuilderEventMeta, 'formBuilderId'>;
};

export type FormBuilderEventRequestInitialValues<TInputContract extends AnyRecord = AnyRecord> = {
  type: typeof FORM_EVENT_TYPE.REQUEST_INITIAL_VALUES;
  payload: {
    input: TInputContract;
  };
  meta: FormBuilderEventMeta;
};

export type FormBuilderEvent<
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord = AnyRecord
> =
  | FormBuilderEventMount
  | FormBuilderEventUnmount
  | FormBuilderEventSubmit<TOutputContract, TFormFieldValues>
  | FormBuilderEventSubmitFinishedSuccess<TOutputContract, TFormFieldValues>
  | FormBuilderEventSubmitFinishedErrors<TFormFieldValues>
  | FormBuilderEventReset
  | FormBuilderEventValuesChanging<TFormFieldValues>
  | FormBuilderEventValuesChanged<TFormFieldValues>
  | FormBuilderEventSanitizedValuesChanged<TFormFieldValues, TOutputContract>
  | RemoteFormBuilderEventValuesChanged<TFormFieldValues>
  | FormBuilderEventRequestInitialValues;

/**
 * Broadcast subject for form builder events
 */
export const formBuilderRemoteEvent$ = new BroadcastSubject<FormBuilderEvent<AnyRecord, AnyRecord>>(
  'form-builder-events'
);

export const getFormBuilderRemoteEvent$ = <
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord = AnyRecord
>() => formBuilderRemoteEvent$ as BroadcastSubject<FormBuilderEvent<TOutputContract, TFormFieldValues>>;
