import { FieldDefinition, FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { ActionButtonFormOutput } from './action-button.contracts';
import * as actionFields from './action-button.form-fields';

export const actionButtonContract = FormContract.create<ActionButtonFormOutput>()
  .fields({
    commandId: actionFields.commandId,
    color: actionFields.color,
    size: actionFields.size,
    label: actionFields.label,
    confirmation: actionFields.confirmation,
    payload: actionFields.payload,
    allowedCommands: actionFields.allowedCommands,
    allowsConfirmation: actionFields.allowsConfirmation,
    allowsLabel: actionFields.allowsLabel,
    allowsColor: actionFields.allowsColor,
    allowsSize: actionFields.allowsSize,
    locationId: actionFields.locationId,
    subSchemaKey: actionFields.subSchemaKey,
    subSchemaVersion: actionFields.subSchemaVersion
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box('action-button-container', [f.commandId, f.label, f.size, f.color], {
        sx: {
          display: 'flex',
          flexDirection: 'row',
          width: 'full'
        }
      }),
      FieldDefinition.simpleGrid('action-button-confirmation', 1, [f.confirmation], {
        sx: { margin: '4px' }
      }),
      f.payload
    ]
  }))
  .template('simple', {
    submitLabel: 'Save and close',
    showResetButton: true,
    showSubmitButton: true,
    resetLabel: 'Reset to defaults'
  });

export type ActionButtonContractType = typeof actionButtonContract;

export type ActionButtonFormValues = InferFormValuesFromFormContract<ActionButtonContractType>;
