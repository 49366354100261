import { AppDisplayGrid, type AppDisplayGridProps } from '../../../display-grid/display-grid';
import { type FC } from 'react';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import type { FieldProps, ICommonTextField } from '../../types';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { useFieldApi } from '../../helpers';

export interface IDisplayGridProps extends AppDisplayGridProps {}

export interface IDisplayGridField
  extends ICommonTextField<typeof FORM_COMPONENT_TYPE.DISPLAY_GRID, IDisplayGridProps, Validator> {}

export const DisplayGrid: FC<FieldProps<IDisplayGridField, IDisplayGridProps>> = (props) => {
  const field = useFieldApi(props);
  return <AppDisplayGrid {...(field.input?.value || {})} />;
};
