import type { PositionHistoryRow, PositionRow } from '@app/common/types/positions/positions.types';
import type { ColumnLibrary } from '@oms/frontend-vgrid';
import type { TreeDataSlice } from '@app/data-access/services/trading/positions/common/tree-grid/types/tree-data.types';
import type ServerSideRowDataHelper from '@app/data-access/services/trading/positions/common/tree-grid/helpers/server-side-row-data-helper.abstract.class';
import type { PositionsGridType } from '../types';
import positionsInstrumentColumnLibrary from './positions-instrument.column-library';
import createPositionsAccountColumnLibrary from './positions-account.column-library';
import positionsHistoryColumnLibrary from './positions-history.column-library';

type PositionsAccountGridType = Extract<PositionsGridType, 'positions-account-grid'>;

type PositionsInstrumentGridType = Extract<PositionsGridType, 'positions-instrument-grid'>;

type PositionsHistoryGridType = Extract<PositionsGridType, 'positions-history-grid'>;

/** Gets column library for Positions Account grid */
export function getColumnLibraryFor(
  gridType: PositionsAccountGridType,
  dataHelper: ServerSideRowDataHelper<PositionRow>
): ColumnLibrary<TreeDataSlice<PositionRow>>;

/** Gets column library for the Positions Instrument grid */
export function getColumnLibraryFor(gridType: PositionsInstrumentGridType): ColumnLibrary<PositionRow>;

/** Gets column library for the Positions History grid */
export function getColumnLibraryFor(gridType: PositionsHistoryGridType): ColumnLibrary<PositionHistoryRow>;

// Implementation only
export function getColumnLibraryFor(
  gridType: PositionsGridType,
  dataHelper?: ServerSideRowDataHelper<PositionRow>
):
  | ColumnLibrary<TreeDataSlice<PositionRow>> // Account grid
  | ColumnLibrary<PositionRow> // Instrument grid
  | ColumnLibrary<PositionHistoryRow> {
  switch (gridType) {
    case 'positions-account-grid':
      return createPositionsAccountColumnLibrary(dataHelper);
    case 'positions-instrument-grid':
      return positionsInstrumentColumnLibrary;
    case 'positions-history-grid':
      return positionsHistoryColumnLibrary;
  }
}

export default getColumnLibraryFor;
