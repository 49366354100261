import React from 'react';
import { AddIcon, IconButton } from '@oms/shared-frontend/ui-design-system';
import { Actions, DockLocation } from 'flexlayout-react';
import type { FlexLayoutTabSetToolbarActionComponentProps } from '@app/common/toolbar/toolbar-context.types';

export const AddNewTradingOrderMonitor: React.FC<FlexLayoutTabSetToolbarActionComponentProps> = ({
  tabSetNode
}) => {
  return (
    <IconButton
      icon={<AddIcon />}
      size="sm"
      variant="link"
      onClick={() => {
        const len = tabSetNode.getChildren().length;
        tabSetNode.getModel().doAction(
          Actions.addNode(
            {
              type: 'tab',
              name: 'Untitled',
              component: 'TRADING_ORDER_MONITOR_GRID'
            },
            tabSetNode.getId(),
            DockLocation.CENTER,
            len
          )
        );
      }}
      className="window__toolbar__action"
      aria-label="Add new TO grid"
    />
  );
};
