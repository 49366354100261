import { FormBuilder, FORM_EVENT_TYPE, FORM_RENDERER_EVENT_TYPE } from '@oms/frontend-foundation';
import { UsersService } from '@app/data-access/services/reference-data/users/users.service';
import { userPreferencesFormContract } from './user-preferences.form-contract';
import type { UserPreferencesFormContractType } from './user-preferences.form-contract';
import { sanitizeFormValuesToInput, sanitizeFormValuesToOutput } from './user-preferences.form-sanitizers';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { UserPreferences } from '@oms/generated/frontend';
import type { UserPreferencesFormInput, UserPreferencesFormOutput } from './user-preferences.types';

export const userPreferencesFormBuilder = FormBuilder.create<
  UserPreferencesFormInput,
  UserPreferencesFormOutput
>('user-preferences-montage-form')
  .contract<UserPreferencesFormContractType>(userPreferencesFormContract)
  .sanitizer((s) =>
    s
      .input(async function sanitize(_input, ctx) {
        const service = ctx.container.resolve(UsersService);
        const authService = ctx.container.resolve(AuthService);
        const userId: string = authService.getUserId() || '';
        const userPreferences = await service.getUserPreferences(userId);

        const formValues = sanitizeFormValuesToInput(userPreferences.data.getUserPreferences);

        return formValues;
      })
      .output(function sanitize(formValues) {
        const { hiddenFormInfo } = formValues;
        if (!hiddenFormInfo) return;

        const output = sanitizeFormValuesToOutput(formValues);

        switch (hiddenFormInfo.type) {
          case 'create': {
            return {
              type: 'create',
              output
            };
          }
          case 'update': {
            return {
              type: 'update',
              output
            };
          }
        }
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(UsersService);
        const authService = ctx.container.resolve(AuthService);
        const userId: string = authService.getUserId() || '';
        const output = event.payload.output;

        if (!output) {
          break;
        }
        const { orderSettings, ...montageSettings } = output.output;

        const data = {
          userId,
          userPreferences: { montagePreferences: { ...montageSettings }, orderSettings }
        };

        switch (output.type) {
          case 'create': {
            return await service.createUserPreferences(data);
          }

          case 'update': {
            return await service.ovewriteUserPreferences(data);
          }
        }
        break;
      }
      case FORM_EVENT_TYPE.RESET: {
        const defaults = sanitizeFormValuesToInput({} as UserPreferences);
        ctx.notify({
          type: FORM_RENDERER_EVENT_TYPE.SET_FIELD_VALUES,
          payload: {
            fieldValues: defaults
          }
        });
      }
    }
  });

export type UserPreferencesFormBuilderType = typeof userPreferencesFormBuilder;
export default userPreferencesFormBuilder;
