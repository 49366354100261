import { AddNewTradingOrderMonitor } from './trading-order-monitor.tabset.add-new';
import { useAddToolbarActions } from '@app/common/toolbar/hooks/use-add-toolbar-action.hook';

export const useTradingOrderMonitorToolbarActions = () => {
  useAddToolbarActions(
    [
      {
        location: 'flexlayout-tabset',
        id: 'add-new-trading-order-monitor',
        component: AddNewTradingOrderMonitor
      }
    ],
    []
  );
};
