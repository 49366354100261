import { forwardRef } from 'react';
import type { Sprinkles } from '../../system/sprinkles.css';
import { useImage } from './use-image';
import type { UseImageProps } from './use-image';
import { NativeImage } from './native-image';
import type { NativeImageProps } from './native-image';
import { omit } from '../../system/utils/objects';
import { __DEV__ } from '../../system/utils/assertion';

type ImageProps = {
  fallbackSrc?: string;
  fallback?: React.ReactElement;
  loading?: 'eager' | 'lazy';
  ignoreFallback?: boolean;
  boxSize?: string | number;
  objectFit?: Sprinkles['objectFit'];
  as?: React.ElementType;
} & Omit<NativeImageProps, 'onError'> &
  UseImageProps;

export type { ImageProps };

export const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  const {
    fallbackSrc,
    fallback,
    src,
    loading,
    ignoreFallback,
    crossOrigin,
    boxSize,
    as: Comp = NativeImage,
    style,
    objectFit,
    ...rest
  } = props;

  /**
   * Defer to native `img` tag if `loading` prop is passed
   */
  const shouldIgnore = loading != null || ignoreFallback;

  const status = useImage({
    ...props,
    ignoreFallback: shouldIgnore
  });

  const shared = {
    ref,
    ...(shouldIgnore ? rest : omit(rest, ['onError', 'onLoad'])),
    style: { ...style, objectFit }
  };

  if (boxSize && Comp === NativeImage) {
    shared.htmlWidth = boxSize;
    shared.htmlHeight = boxSize;
  }

  if (status !== 'loaded') {
    /**
     * If user passed a custom fallback component,
     * let's render it here.
     */
    if (fallback) return fallback;

    return <Comp src={fallbackSrc} {...shared} />;
  }

  return <Comp src={src} crossOrigin={crossOrigin} loading={loading} {...shared} />;
});

if (__DEV__) {
  Image.displayName = 'Image';
}
