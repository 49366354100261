import { Unsubscribe, isBroadcastChannelSimulated, uuid } from '@valstro/remote-link';
import {
  ActorRegistry,
  createActorRegistry,
  deleteLocalActor,
  deleteLocalActorProxy
} from './actor.registry';
import { BroadcastChannel, LeaderElector, createLeaderElection } from '@valstro/remote-link';
import {
  CoreEvents,
  EVENT_TYPE,
  EventsTransport,
  IEventType,
  IExtractEventPayload,
  createEventsTransport
} from './events';
import { PROCESS_ID, URL_PARAM, getStorageKey } from './constants';
import { Actor } from './actor';
import { AnyRecord, JSONParse, isDeactivatedWorkspaceURL } from './utils';
import {
  InternalActor,
  CreateActorDefinition,
  AnyActorHook,
  CreateActorOptions,
  SpawnedActorResult
} from './actor.internal';
import { PlatformAPI, PlatformRegistry, createPlatformRegistry } from './platform';
import {
  ActorHooksContext,
  ActorSchema,
  AnyActorSchema,
  UnwrapContext,
  UnwrapOperations
} from './actor.schema';
import { ActorTypeSelectorStrategy } from './actor.registry';
import { Platform } from './platform';
import { Plugin, PluginRegistry } from './plugins';
import { browserPlatform } from './platform.browser';
import { logger } from './logger';

declare global {
  interface Window {
    internalWorkspace?: Workspace;
  }
}

/**
 * Workspace Types
 */
export type AnyViewType = any;

export type WorkspaceOptions<TViewType = any> = {
  actorSelectorStrategy?: ActorTypeSelectorStrategy;
  actors: ActorSchema<any, any, TViewType>[];
  platforms?: Platform[];
  plugins?: Plugin[];
  uuidFn?: () => string;
  eventsTransport?: EventsTransport<CoreEvents>;
  rootableActorLoadingStrategy?: 'url' | 'localStorage';
  defaultLeaderProcessId?: string;
};

export type WorkspaceWindowBootOptions = {
  initialDefinition?: CreateActorDefinition;
  forceActive?: boolean;
  syncRootActor?: boolean;
};

/**
 * Workspace Hooks
 */
export const WORKSPACE_HOOK = {
  START_WINDOW_BOOT: 'startWindowBoot',
  RUN_PLUGINS: 'runPlugins',
  LEADER_ELECTION: 'leaderElection',
  WORKSPACE_DEACTIVATED: 'workspaceDeactivated',
  ROOT_ACTOR_DEF_EXTRACTION: 'rootActorDefExtraction',
  WINDOW_READY: 'windowReady',
  PRE_SPAWN_IMMEDIATE_CHILDREN: 'preSpawnImmediateChildren',
  LEADER_WINDOW_READY: 'leaderWindowReady',
  WORKSPACE_DESTROY: 'workspaceDestroy'
} as const;

export type AnyWorkspaceHookCallback = (p: any) => void | Promise<void>;

export type RunPluginsEvent = {
  type: typeof WORKSPACE_HOOK.RUN_PLUGINS;
  payload: {
    didRun: boolean;
  };
};

export type LeaderElectionEvent = {
  type: typeof WORKSPACE_HOOK.LEADER_ELECTION;
  payload: {
    isLeader: boolean;
  };
};

export type WorkspaceDeactivatedEvent = {
  type: typeof WORKSPACE_HOOK.WORKSPACE_DEACTIVATED;
  payload: undefined;
};

export type RootActorDefExtractionEvent = {
  type: typeof WORKSPACE_HOOK.ROOT_ACTOR_DEF_EXTRACTION;
  payload: {
    rootActorDefinition: CreateActorDefinition | null | undefined;
  };
};

export type WindowReadyContext = {
  rootWindowActor: Actor;
  processId: string;
  platformName: string;
  isLeader: boolean;
};

export type PreSpawnImmediateChildrenContext = Omit<WindowReadyContext, 'isLeader'>;

export type LeaderReadyContext = Omit<WindowReadyContext, 'isLeader'>;

export type WindowReadyEvent = {
  type: typeof WORKSPACE_HOOK.WINDOW_READY;
  payload: WindowReadyContext;
};

export type PreSpawnImmediateChildrenEvent = {
  type: typeof WORKSPACE_HOOK.PRE_SPAWN_IMMEDIATE_CHILDREN;
  payload: PreSpawnImmediateChildrenContext;
};

export type LeaderWindowReadyEvent = {
  type: typeof WORKSPACE_HOOK.LEADER_WINDOW_READY;
  payload: LeaderReadyContext;
};

export type WorkspaceDestroyEvent = {
  type: typeof WORKSPACE_HOOK.WORKSPACE_DESTROY;
  payload: undefined;
};

export type WorkspaceHookEvent =
  | RunPluginsEvent
  | LeaderElectionEvent
  | WorkspaceDeactivatedEvent
  | RootActorDefExtractionEvent
  | PreSpawnImmediateChildrenEvent
  | LeaderWindowReadyEvent
  | WindowReadyEvent
  | WorkspaceDestroyEvent;

/**
 * Workspace
 * The workspace is the main entry point for the application AND individual windows.
 * It's responsible for:
 *
 * - Creating the root window actor for the current window
 * - Spawning subsequent child actors
 * - Managing the actor registry
 * - Managing the platform registry
 * - Managing the plugin registry
 * - Managing the events transport
 * - Managing the leader elector
 * - Managing the hooks
 * - Managing the meta
 * - Managing the UUID generator
 * - Managing the actor cache
 */
export class Workspace<TMeta extends AnyRecord = any> {
  private _options: WorkspaceOptions;
  private _leaderActor: Actor | undefined;
  private _rootWindowActorInstance: InternalActor | undefined;
  private _rootWindowActor: Actor | undefined;
  private _initialURLCreateActorDefinition: CreateActorDefinition | undefined | null;
  private _isReady = false;
  private _isActive = false;
  private _isPartiallyReady = false;
  private _platformRegistry: PlatformRegistry;
  private _actorRegistry: ActorRegistry;
  private _leaderElectorChannel: BroadcastChannel;
  private _leaderElector: LeaderElector;
  private _isLeader = false;
  private _processId: string | undefined;
  protected _eventsTransport: EventsTransport<CoreEvents>;
  private _hooks: Map<string, Set<AnyWorkspaceHookCallback>> = new Map();
  private _beforeActorHooks: Map<string, Set<AnyActorHook>> = new Map();
  private _afterActorHooks: Map<string, Set<AnyActorHook>> = new Map();
  private _pluginRegistry: PluginRegistry;
  private _manageActorCacheUnsub: Unsubscribe | undefined;
  private _meta: Partial<TMeta> = {} as Partial<TMeta>;
  private _rootableActorLoadingStrategy: 'url' | 'localStorage';
  private _uuidFn: () => string;
  private _leaderProcessId: string;
  private _shortId: string;

  constructor(options: WorkspaceOptions) {
    window.internalWorkspace = this;
    this._options = options;
    this._rootableActorLoadingStrategy = options.rootableActorLoadingStrategy || 'url';
    this._uuidFn = options.uuidFn || uuid;
    this._leaderProcessId = options.defaultLeaderProcessId || PROCESS_ID.LEADER;
    this._shortId = this._leaderProcessId.slice(0, 4);

    // Setup the leader elector
    this._leaderElectorChannel = new BroadcastChannel(`leader-elector-${this._leaderProcessId}`);
    this._leaderElector = createLeaderElection(this._leaderElectorChannel);

    // Create a new events transport channel for the workspace
    this._eventsTransport = options?.eventsTransport || createEventsTransport<CoreEvents>('core-events');

    // Create the platform registry & register the platforms
    this._platformRegistry = createPlatformRegistry();
    this._platformRegistry.register(browserPlatform);
    this._options.platforms?.forEach((p) => this._platformRegistry.register(p));

    // Create the actor registry & register the actor schemas
    this._actorRegistry = createActorRegistry(this, this._platformRegistry);
    this._options.actors.forEach((a) => this._actorRegistry.register(a));

    // Create the plugin registry, register the plugins & run them
    this._pluginRegistry = new PluginRegistry();
    this._options.plugins?.forEach((p) => this._pluginRegistry.register(p));

    // Run the actor onRegistrationFinished hooks
    this._actorRegistry.runRegistrationFinished(this._pluginRegistry);

    // Listen to actor destroy events & remove them from the cache
    this._manageActorCache();

    logger.debug(`[Workspace: ${this._shortId}] Constructed`, {
      workspace: this
    });
  }

  /**
   * Get the workspace options
   *
   * @returns WorkspaceOptions
   */
  getWorkspaceOptions(): WorkspaceOptions {
    return this._options;
  }

  /**
   * Get the leader process ID
   *
   * @returns The default leader process ID
   */
  getLeaderProcessId(): string {
    return this._leaderProcessId;
  }

  /**
   * Get the leader actor, at the root of the application and actor tree
   * Note: This could be in a different window if the leader is in a different window
   *
   * @returns Actor
   */
  public getLeaderActor(): Actor {
    if (!this._leaderActor) {
      throw new Error('No leader actor found');
    }
    return this._leaderActor;
  }

  /**
   * Get the root window actor, at the root of the current window's actor tree
   * Note: This is always in the current window and can be undefined if the workspace is not ready
   *
   * @returns Actor | undefined
   */
  public getRootWindowActor(): Actor | undefined {
    return this._rootWindowActor;
  }

  /**
   * Get the definition of the root window actor, that is used to create the actor
   * by setting URL params from the parent actor
   *
   * @returns CreateActorDefinition | null | undefined
   */
  public getInitialURLCreateActorOptions(): CreateActorDefinition | undefined | null {
    return this._initialURLCreateActorDefinition;
  }

  /**
   * Get if the current window is ready
   * This means the workspace has been bootstrapped and is active
   *
   * @returns boolean
   */
  public getIsWindowReady(): boolean {
    return this._isReady && this._isActive;
  }

  /**
   * Get if the workspace is ready
   * This may not mean the window is ready, but the workspace has been bootstrapped
   * and could be in an active OR inactive state.
   *
   * @returns boolean
   */
  public getIsWorkspaceReady(): boolean {
    return this._isReady;
  }

  /**
   * Get if the workspace is active
   * because workspaces can be in both active and inactive (deactivated) states
   *
   * @returns boolean
   */
  public getIsWorkspaceActive(): boolean {
    return this._isActive;
  }

  /**
   * Get if the current window is the leader, and the root of the application
   *
   * @returns boolean
   */
  public getIsLeader(): boolean {
    return this._isLeader;
  }

  /**
   * Get the current platform name e.g. "browser"
   * This is determined by the platforms in the platform registry
   *
   * @returns string
   */
  public getPlatformName() {
    return this._platformRegistry.getCurrentPlatformName();
  }

  /**
   * Get the current platform info
   * This is determined by the platforms in the platform registry
   *
   * @returns PlatformInfo | Promise<PlatformInfo> | null
   */
  public getPlatformInfo() {
    return this._platformRegistry.getCurrentPlatformAPI()?.getPlatformInfo?.() || null;
  }

  /**
   * Get the current platform API
   * This is determined by the platforms in the platform registry
   * This contains all the platform specific methods
   *
   * @returns PlatformAPI | null
   */
  public getPlatformAPI(): PlatformAPI {
    return this._platformRegistry.getCurrentPlatformAPI();
  }

  /**
   * Gets the actor registry
   * This contains all the actor schemas registered with the workspace
   * This is useful for debugging, introspection & manipulating actors from plugins
   *
   * @returns  ActorRegistry
   */
  public getActorRegistry() {
    return this._actorRegistry;
  }

  /**
   * Get a plugin by it's name
   *
   * @param name - Plugin name
   * @returns Plugin
   */
  public getPlugin(name: string) {
    return this._pluginRegistry.get(name);
  }

  /**
   * Get the workspace meta (a key value store for passing data between plugins & actors)
   *
   * @returns TMeta - Workspace meta
   */
  public getMeta<T extends TMeta = TMeta>() {
    return this._meta as T;
  }

  /**
   * Get the workspace meta (a key value store for passing data between plugins & actors)
   */
  public get meta() {
    return this.getMeta();
  }

  /**
   * Update the workspace meta (a key value store for passing data between plugins & actors)
   * Note: This will merge the new meta with the existing meta
   *
   * @param meta - Partial<TMeta>
   * @returns void
   */
  public updateMeta<T extends TMeta = TMeta>(meta: Partial<T>) {
    this._meta = {
      ...this._meta,
      ...meta
    };
  }

  /**
   * Set the workspace meta (a key value store for passing data between plugins & actors)
   * Note: This will replace the existing meta
   *
   * @param meta - TMeta
   * @returns void
   */
  public setMeta<T extends TMeta = TMeta>(meta: T) {
    this._meta = meta;
  }

  /**
   * Add an actor hook
   * This is useful for running logic & manipulating arguments/return values from actor lifecycle methods
   * @example
   * ```ts
   * workspace.addActorHook('window').before('spawnChild', ([createOptions, schema]) => {
   *  createOptions.context = {
   *    ...createOptions.context,
   *    title: 'Hello World'
   *  };
   *
   *  return [createOptions, schema];
   * })
   * ```
   *
   * @param actorType - Actor type
   * @returns ActorHooksContext<UnwrapContext<T>, UnwrapOperations<T>>
   */
  public addActorHook<T extends ActorSchema<any, any>>(
    actorType: string
  ): ActorHooksContext<UnwrapContext<T>, UnwrapOperations<T>> {
    const hookBuilder: ActorHooksContext<UnwrapContext<T>, UnwrapOperations<T>> = {
      before: (key, callback) => {
        const hooks = this._beforeActorHooks.get(key.toString());
        if (hooks) {
          hooks.add({
            type: actorType,
            callback
          });
        } else {
          this._beforeActorHooks.set(
            key.toString(),
            new Set([
              {
                type: actorType,
                callback
              }
            ])
          );
        }
        return () => {
          const hooks = this._beforeActorHooks.get(key.toString());
          if (hooks) {
            hooks.delete({
              type: actorType,
              callback
            });
          }
        };
      },
      after: (key, callback) => {
        const hooks = this._afterActorHooks.get(key.toString());
        if (hooks) {
          hooks.add({
            type: actorType,
            callback
          });
        } else {
          this._afterActorHooks.set(
            key.toString(),
            new Set([
              {
                type: actorType,
                callback
              }
            ])
          );
        }

        return () => {
          const hooks = this._afterActorHooks.get(key.toString());
          if (hooks) {
            hooks.delete({
              type: actorType,
              callback
            });
          }
        };
      }
    };

    return hookBuilder;
  }

  /**
   * Add a workspace hook
   * This is useful for running logic after workspace lifecycle methods
   * Note: Although these hooks can't manipulate arguments/return values, they can still pause execution via async/await
   * @example
   * ```ts
   * workspace.addHook('windowReady', async ({ rootWindowActor }) => {
   *  await rootWindowActor.spawnChild({
   *    id: 'dashboard',
   *    type: 'widget',
   *    context: {
   *      title: 'Dashboard',
   *      componentId: 'dashboard',
   *    }
   *  });
   * })
   * ```
   *
   * @param hookType - Hook type (e.g. windowReady)
   * @param cb - Hook callback with payload
   * @returns Unsubscribe
   */
  public addHook<K extends IEventType<WorkspaceHookEvent>>(
    hookType: K,
    cb: (payload: IExtractEventPayload<WorkspaceHookEvent, K>) => Promise<void> | void
  ): Unsubscribe {
    const hooks = this._hooks.get(hookType);
    if (hooks) {
      hooks.add(cb);
    } else {
      this._hooks.set(hookType, new Set([cb]));
    }

    return () => {
      const hooks = this._hooks.get(hookType);
      if (hooks) {
        hooks.delete(cb);
      }
    };
  }

  /**
   * Listen to events in the workspace & actors
   * This is useful for running side effects after events are fired
   * Note: Unlike hooks, these can't pause execution
   *
   * @example
   * ```ts
   * workspace.listen('actor/created', ({ actor }) => {
   *    console.log('Actor created', actor);
   * });
   * ```
   *
   * @param eventType - Event type (e.g. 'actor/created')
   * @param cb - Event callback with payload
   * @returns Unsubscribe
   */
  public listen<K extends IEventType<CoreEvents>>(
    eventType: K,
    cb: (payload: IExtractEventPayload<CoreEvents, K>) => Promise<void> | void
  ): Unsubscribe {
    return this._eventsTransport.listen(eventType, cb);
  }

  /**
   * Get before actor hooks
   * This is useful for debugging, introspection & manipulating actors from plugins
   *
   * @returns Map<string, Set<AnyActorHook>>
   */
  public getBeforeActorHooks() {
    return this._beforeActorHooks;
  }

  /**
   * Get after actor hooks
   * This is useful for debugging, introspection & manipulating actors from plugins
   *
   * @returns Map<string, Set<AnyActorHook>>
   */
  public getAfterActorHooks() {
    return this._afterActorHooks;
  }

  /**
   * Destroy the workspace
   * This will tear down all actors, plugins, events & hooks
   * This is useful for testing & debugging
   *
   * @returns Promise<void>
   */
  public async destroy() {
    // Tear down actors
    if (this._rootWindowActorInstance) {
      this._rootWindowActorInstance.destroy().catch(console.error);
      await this._waitUntilNoActorPongs();
    }

    this._beforeActorHooks.clear();
    this._afterActorHooks.clear();
    this._actorRegistry.destroy();

    // Tear down plugins
    await this._pluginRegistry.destroy();
    this._actorRegistry.destroy();
    this._platformRegistry.destroy();

    await this._leaderElector.die();
    await this._eventsTransport.destroy();

    // Tear down workspace
    this._manageActorCacheUnsub?.();
    this._isActive = false;
    this._isReady = false;
    this._leaderActor = undefined;
    this._rootWindowActor = undefined;
    this._rootWindowActorInstance = undefined;
    this._initialURLCreateActorDefinition = undefined;
    this._isLeader = false;
    this._processId = undefined;
    this._meta = {} as Partial<TMeta>;
    this._uuidFn = uuid;

    // Run destroy hook
    await this._triggerHook('workspaceDestroy', undefined);
    this._hooks.clear();

    logger.debug(`[Workspace: ${this._shortId}] Destroyed`);
  }

  /**
   * Generate a UUID
   * This is useful for generating actor IDs & other unique identifiers throughout the workspace
   * Note: It can be overridden in the constructor options to use your own UUID generator
   *
   * @returns string
   */
  public generateUUID() {
    return this._uuidFn();
  }

  /**
   * Boot the workspace inside a window
   * This is an internal method that should not be called directly
   * Except by rendering layers e.g. (@valstro/workspace-react)
   * @see InternalWorkspace 

   * @param options - WorkspaceWindowBootOptions
   * @returns Promise<void>
   */
  protected async windowBoot(options?: WorkspaceWindowBootOptions) {
    logger.debug(`[Workspace: ${this._shortId}] Window booting`, { options });
    try {
      const isFirstBoot = this._isPartiallyReady === false;

      if (isFirstBoot) {
        logger.debug(`[Workspace: ${this._shortId}] Is First Boot`, {
          options
        });
        await this._runPluginsOnce();
        logger.debug(`[Workspace: ${this._shortId}] Plugins finished running`);
        this._isPartiallyReady = true;
      } else {
        logger.debug(`[Workspace: ${this._shortId}] Not first boot, ignore plugin step`, {
          options
        });
      }

      await this._triggerHook('runPlugins', {
        didRun: isFirstBoot
      });

      this._isLeader = await this._applyLeadershipOnce();

      await this._triggerHook('leaderElection', {
        isLeader: this._isLeader
      });

      logger.debug(`[Workspace: ${this._shortId}] Leader election finished`, {
        isLeader: this._isLeader
      });

      const shouldDeactivate = this._extractIsWorkspaceDeactivated(options?.forceActive);

      if (shouldDeactivate) {
        this._isReady = true;
        this._isActive = false;
        await this._triggerHook('workspaceDeactivated', undefined);
        logger.debug(`[Workspace: ${this._shortId}] Deactivated, waiting for activation`);
        return;
      }

      this._initialURLCreateActorDefinition = this._extractActorDefJSON(options?.initialDefinition);

      await this._triggerHook('rootActorDefExtraction', {
        rootActorDefinition: this._initialURLCreateActorDefinition
      });

      logger.debug(`[Workspace: ${this._shortId}] Root Actor definition extraction`, {
        rootActorDefinition: this._initialURLCreateActorDefinition
      });

      logger.debug('[Workspace] Finding matching actors for root actor definition');

      const matchingRootActors = this._actorRegistry
        .getAll()
        //Filter the actors that are NOT rootable
        .filter((a) => a.isWindowRootable)
        // Filter the actors that match the type (if it's defined)
        .filter(
          (a) =>
            !this._initialURLCreateActorDefinition?.type ||
            this._initialURLCreateActorDefinition.type === a.type
        )
        // Filter the actors that are supported by the current platform
        .filter(
          (a) =>
            a.supportedPlatforms === '*' ||
            a.supportedPlatforms.includes(this._platformRegistry.getCurrentPlatformName())
        )
        // Calculate strategy
        .map((a) => {
          const isStrategyDefined = a.selectStrategy !== undefined;
          const isValidStrategy =
            a.selectStrategy !== undefined
              ? a.selectStrategy({
                  location: 'root',
                  source: undefined,
                  isLeader: this._isLeader,
                  platformName: this._platformRegistry.getCurrentPlatformName(),
                  rootWindowActor: this._rootWindowActor,
                  createActorOptionsOrDef: this._initialURLCreateActorDefinition || undefined
                }) === true
              : true;
          return {
            ...a,
            isStrategyDefined,
            isValidStrategy
          };
        })
        // Filter the actors that have a valid strategy
        .filter((a) => a.isValidStrategy)
        // Sort the actors by strategy (if it's defined they are first)
        .sort((a, b) => {
          const aScore = a.isStrategyDefined ? 1 : 0;
          const bScore = b.isStrategyDefined ? 1 : 0;

          if (aScore === bScore) {
            return 0;
          }

          return aScore > bScore ? -1 : 1;
        });

      // Require at least one rootable actor
      if (matchingRootActors.length > 0) {
        if (matchingRootActors.length > 1) {
          console.warn(
            `Multiple rootable actors found. Using the first one.\nThis is standard behaviour when using a custom "selectStrategy" because it sorts actors by the predicate & selects the first one.\nHowever, it's worth noting, this may lead to a mismatch between which actor is trying to be created vs the one selected. Debug: `,
            {
              matchingRootActors,
              initialDefinition: this._initialURLCreateActorDefinition
            }
          );
        }

        this._setRootWindowActor(matchingRootActors[0]);

        logger.debug(
          `[Workspace: ${this._shortId}] Found ${matchingRootActors.length} matching actors for root actor definition. Using`,
          { actor: matchingRootActors[0] }
        );
      }

      // Require the root window actor to be set by at least one plugin
      if (!this._rootWindowActorInstance || !this._processId) {
        throw new Error('No root window actor set');
      }

      // Start the root actor
      if (this._rootWindowActorInstance.state === 'idle') {
        const shouldSync = !!options?.syncRootActor;

        logger.debug(`[Workspace: ${this._shortId}] Starting root actor`, {
          shouldSync
        });

        await this._rootWindowActorInstance.start({
          shouldSync
        });

        logger.debug(`[Workspace: ${this._shortId}] Finished starting root actor`);
      }

      // Create the root actor proxy
      this._rootWindowActor = Actor.getSyncLazy(
        this._rootWindowActorInstance.id,
        this._rootWindowActorInstance.getDefinition()
      );

      if (this._rootWindowActor.id === this.getLeaderProcessId()) {
        this._leaderActor = this._rootWindowActor;
      } else {
        this._leaderActor = Actor.getSyncLazy(this.getLeaderProcessId());
      }
      this._isReady = true;
      this._isActive = true;

      const sharedHookContext: Omit<WindowReadyContext, 'isLeader'> = {
        rootWindowActor: this._rootWindowActor,
        processId: this._processId,
        platformName: this._platformRegistry.getCurrentPlatformName()
      };

      if (this._isLeader) {
        logger.debug(
          `[Workspace: ${this._shortId}] Pre spawning immediate children (when leader is ready but auto-actor spawning is not)`
        );
        await this._triggerHook('preSpawnImmediateChildren', sharedHookContext);

        logger.debug(`[Workspace: ${this._shortId}] Leader spawning immediate children`);
        const actorsSpawned = await this._spawnImmediateChildren();
        logger.debug(`[Workspace: ${this._shortId}] Leader spawned immediate children`, {
          actorsSpawned
        });
      }

      logger.debug(`[Workspace: ${this._shortId}] Window ready`);
      await this._triggerHook('windowReady', {
        ...sharedHookContext,
        isLeader: this._isLeader
      });

      if (this._isLeader) {
        logger.debug(`[Workspace: ${this._shortId}] Leader window ready`);
        await this._triggerHook('leaderWindowReady', sharedHookContext);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }

    logger.debug(`[Workspace: ${this._shortId}] Window Booted`);
  }

  /**
   * Spawn actor children immediately that have "shouldImmediatelySpawn" defined
   * This is useful for actors that need to be spawned before the workspace is ready
   * e.g. utility actors like a logger/telemetry/auth/popover/command palette etc
   *
   * @returns Promise<SpawnedActorResult<AnyActorSchema>[]>
   */
  private async _spawnImmediateChildren() {
    if (!this._rootWindowActor) {
      throw new Error('No root window actor found');
    }

    const actorsToSpawn = this._actorRegistry
      .getAll()
      .filter(
        (a) =>
          a.shouldImmediatelySpawn === true ||
          typeof a.shouldImmediatelySpawn === 'object' ||
          Array.isArray(a.shouldImmediatelySpawn)
      )
      .filter(
        (a) =>
          a.supportedPlatforms === '*' ||
          a.supportedPlatforms.includes(this._platformRegistry.getCurrentPlatformName())
      )
      // TODO: Do I need to filter by strategy too?
      // TODO: Tech debt, unify the selectors
      .filter((a) => !!this._actorRegistry.getActorByType(a.type));

    const actorsGroupedByType = actorsToSpawn.reduce(
      (acc, actor) => {
        const type = actor.type;
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(actor);
        return acc;
      },
      {} as Record<string, ActorSchema[]>
    );

    const actorsSpawned: SpawnedActorResult<AnyActorSchema>[] = [];

    for (const actors of Object.values(actorsGroupedByType)) {
      const actor = actors[0];
      if (!actor) {
        continue;
      }

      if (actors.length > 1) {
        console.warn(`Multiple actors found with the same type. Spawning the first one.`, { actors });
      }

      const actorsCreateOptions: Array<CreateActorOptions<AnyRecord>> = Array.isArray(
        actor.shouldImmediatelySpawn
      )
        ? actor.shouldImmediatelySpawn.map((a) => ({
            ...a,
            type: actor.type,
            applyingSnapshot: true
          }))
        : typeof actor.shouldImmediatelySpawn === 'object'
          ? [
              {
                ...actor.shouldImmediatelySpawn,
                type: actor.type,
                applyingSnapshot: true
              }
            ]
          : [
              {
                id: this.generateUUID(),
                type: actor.type,
                children: [],
                applyingSnapshot: true
              }
            ];

      for (const createOptions of actorsCreateOptions) {
        const result = await this._rootWindowActor.spawnChild(createOptions);
        actorsSpawned.push(result);
      }
    }

    return actorsSpawned;
  }

  /**
   * Helper to run the plugins once (if they haven't already)
   * This is useful for running plugins before the workspace is ready
   *
   * @returns Promise<void>
   */
  private async _runPluginsOnce() {
    if (!this._pluginRegistry.havePluginsRun) {
      await this._pluginRegistry.runAll({
        workspace: this
      });
    }
  }

  /**
   * Trigger a hook by type
   * This is useful for running logic after workspace lifecycle methods in this class
   *
   * @param hookType - Hook type (e.g. windowReady)
   * @param payload - Hook payload
   */
  private async _triggerHook<K extends IEventType<WorkspaceHookEvent>>(
    hookType: K,
    payload: IExtractEventPayload<WorkspaceHookEvent, K>
  ) {
    const hook = this._hooks.get(hookType);

    if (hook) {
      try {
        for (const cb of hook.values()) {
          await cb(payload);
        }
      } catch (e) {
        console.error(`Hook ${hookType} failed`, e, {
          payload
        });
      }
    }
  }

  /**
   * Helper to set the root window actor
   * This is useful for setting the root window actor from the URL or from a plugin
   * Note: This will throw an error if the actor type doesn't match the URL definition type
   * This is to prevent the same actor from being re-created by copying & pasting URL
   *
   * @param actorSchema - Actor schema
   * @returns void
   */
  private _setRootWindowActor(actorSchema: ActorSchema) {
    // Prevent the actor being set if the URL definition type doesn't match the actor schema type
    if (
      this._initialURLCreateActorDefinition &&
      this._initialURLCreateActorDefinition.type !== actorSchema.type
    ) {
      throw new Error(
        `Root actor type ${actorSchema.type} does not match the type ${this._initialURLCreateActorDefinition?.type} is was defined with. This usually happens when multiple actors are selected for the root actor definition.
        
        Possible solutions:
        - Duplicate ""supportedPlatforms" in the actor schema
        - A custom "selectStrategy" (if defined) is returning multiple actors\n`
      );
    }

    // Get / create the ID.
    // If we've refreshed the page, use the previous ID
    // If we're the leader, use the leader process ID
    // Otherwise, use the ID from the URL
    const prevRootId = this._getPreviousProcessIdAfterRefresh();
    let actorId = prevRootId
      ? prevRootId
      : this._isLeader
        ? this.getLeaderProcessId()
        : this._initialURLCreateActorDefinition?.id
          ? this._initialURLCreateActorDefinition.id
          : null;

    // Track whether the actor was user created (opening a new tab manually for example)
    const userCreated = actorId === null || !!prevRootId;

    // If we don't have an ID, generate one
    if (!actorId) {
      actorId = this.generateUUID();
    }

    // Create the actor definition
    // If we've got a definition from the URL, use that
    // Otherwise make a new one based on the actor schema
    const actorDef: CreateActorDefinition = this._initialURLCreateActorDefinition
      ? {
          ...this._initialURLCreateActorDefinition,
          id: actorId,
          userCreated: userCreated
        }
      : {
          id: actorId,
          userCreated: userCreated,
          type: actorSchema.type,
          children: []
        };

    this._rootWindowActorInstance = new InternalActor(
      {
        workspace: this,
        eventsTransport: this._eventsTransport,
        actorSchema: actorSchema
      },
      actorDef
    );

    this._setProcessId(this._isLeader ? this.getLeaderProcessId() : this._rootWindowActorInstance.id);
  }

  /**
   * Helper to get the previous process ID after a refresh
   *
   * @returns string | null
   */
  private _getPreviousProcessIdAfterRefresh() {
    if (isBroadcastChannelSimulated()) {
      return null;
    }
    return window.name || null;
  }

  /**
   * Helper to set the process ID
   * This is useful for remembering the process ID after a refresh
   *
   * @param processId - Process ID
   */
  private _setProcessId(processId: string) {
    window.name = processId;
    this._processId = processId;
  }

  /**
   * Apply leadership once
   * This is useful for waiting for leadership before starting the workspace
   * Note: Leader election mechanisms are defined by the platform
   *
   * @returns Promise<boolean>
   */
  private async _applyLeadershipOnce() {
    // Check if custom leader checker
    const customLeaderElection = this._platformRegistry.getCurrentPlatformLeaderElection();

    if (customLeaderElection) {
      return await customLeaderElection(this);
    }

    // Default
    const hasLeader = await this._leaderElector.hasLeader();
    if (hasLeader) {
      return false;
    }
    await this._leaderElector.awaitLeadership();
    return true;
  }

  /**
   * Helper to extract the actor definition from the URL
   *
   * @param initialDefinition - CreateActorDefinition
   * @returns CreateActorDefinition | null
   */
  private _extractActorDefJSON(initialDefinition?: CreateActorDefinition): CreateActorDefinition | null {
    if (initialDefinition) {
      if (this._rootableActorLoadingStrategy === 'localStorage') {
        const storageKey = getStorageKey(initialDefinition.id);
        localStorage.removeItem(storageKey);
      }
      return initialDefinition;
    }

    const url = new URL(window.location.href);

    if (this._rootableActorLoadingStrategy === 'localStorage') {
      const initialURLCreateActorDefinitionId = url.searchParams.get(URL_PARAM.ACTOR_DEFINITION_ID);

      if (initialURLCreateActorDefinitionId) {
        const storageKey = getStorageKey(initialURLCreateActorDefinitionId);
        try {
          const initialURLCreateActorDefinitionStr = localStorage.getItem(storageKey);

          if (!initialURLCreateActorDefinitionStr) {
            return null;
          }

          const parseDef = JSONParse<CreateActorDefinition>(initialURLCreateActorDefinitionStr);

          // Clean up the URL. To prevent the same actor from being re-created by copying & pasting URL
          url.searchParams.delete(URL_PARAM.ACTOR_DEFINITION_ID);
          window.history.replaceState({}, '', url.toString());

          // Clean up local storage
          localStorage.removeItem(storageKey);

          return parseDef;
        } catch (e) {
          console.error(e);
          return null;
        }
      }

      return null;
    } else {
      const initialURLCreateActorDefinition = url.searchParams.get(URL_PARAM.ACTOR_DEFINITION_JSON);

      if (initialURLCreateActorDefinition) {
        try {
          const parseDef = JSONParse<CreateActorDefinition>(initialURLCreateActorDefinition);

          // Clean up the URL. To prevent the same actor from being re-created by copying & pasting URL
          url.searchParams.delete(URL_PARAM.ACTOR_DEFINITION_JSON);
          window.history.replaceState({}, '', url.toString());

          return parseDef;
        } catch (e) {
          console.error(e);
          return null;
        }
      }

      return null;
    }
  }

  /**
   * Helper to extract if the workspace is deactivated from the URL
   *
   * @param forceIsActive - boolean
   * @returns boolean
   */
  private _extractIsWorkspaceDeactivated(forceIsActive?: boolean) {
    if (forceIsActive) {
      this._removeDeactivatedURLState();
      return false;
    }
    return isDeactivatedWorkspaceURL();
  }

  /**
   * Helper to remove the deactivated URL state, so the workspace can be activated & stay activated on refresh
   *
   * @returns void
   */
  private _removeDeactivatedURLState() {
    const url = new URL(window.location.href);
    url.searchParams.delete(URL_PARAM.WORKSPACE_DEACTIVATED);
    window.history.replaceState({}, '', url.toString());
  }

  /**
   * Recursively check until no actors respond to a ping
   * Useful for waiting for all actors to be destroyed
   *
   * @param checkedCount - number of checks already performed
   * @param maxCount - maximum number of checks
   * @param checkEveryMs - milliseconds to wait between checks
   */
  private _waitUntilNoActorPongs = (checkedCount = 0, maxCount = 10, checkEveryMs = 100): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      let numberOfActors = 0;
      const pingId = this.generateUUID();
      const unlisten = this._eventsTransport.listen(EVENT_TYPE.ACTOR_PONG, (e) => {
        if (e.pingId === pingId) {
          numberOfActors++;
        }
      });

      setTimeout(() => {
        unlisten();
        if (numberOfActors === 0 || checkedCount >= maxCount) {
          resolve();
        } else {
          this._waitUntilNoActorPongs(checkedCount + 1, maxCount, checkEveryMs)
            .then(resolve)
            .catch(reject);
        }
      }, checkEveryMs);

      this._eventsTransport.emit(EVENT_TYPE.ACTOR_PING, { pingId }).catch(console.error);
    });
  };

  /**
   * Helper to manage the actor cache (by removing actors that are destroyed)
   * Note: This is only used for actors that are created in the current window
   *
   * @returns void
   */
  private _manageActorCache() {
    this._manageActorCacheUnsub = this._eventsTransport.listen('actor/before-destroy', (a) => {
      deleteLocalActor(a.id);
      deleteLocalActorProxy(a.id);
    });
  }
}

export type InternalWorkspace<T extends Workspace> = T & {
  windowBoot: (options?: WorkspaceWindowBootOptions) => Promise<void>;
  _eventsTransport: EventsTransport<CoreEvents>;
};

/**
 * Wrapper to expose internal workspace methods
 * This is useful for rendering layers e.g. (@valstro/workspace-react)
 *
 * @param workspace - Workspace (T)
 * @returns InternalWorkspace<T>
 */
export function internalWorkspace<T extends Workspace>(workspace: T): InternalWorkspace<T> {
  return workspace as InternalWorkspace<T>;
}
