import { type ReactNode, useMemo, type AllHTMLAttributes, type ElementType } from 'react';
import clsx from 'clsx';
import { sprinkles, Sprinkles } from '../../sprinkles.css';
import { __DEV__ } from '../../utils/assertion';
import { polymorphicComponent } from '../../utils/polymorphic';

export type BoxSpecificProps = {
  children?: ReactNode;
  className?: string;
  tabIndex?: number;
  sx?: Sprinkles;
  style?: React.CSSProperties;
  as?: ElementType;
};

export type BoxProps = BoxSpecificProps & Omit<AllHTMLAttributes<HTMLElement>, 'size'>;

export const Box = polymorphicComponent<'div', BoxProps>((props, ref) => {
  const { as: Component = 'div', className, children, sx = {}, ...nativeProps } = props;

  const atomicClasses = useMemo(() => sprinkles(sx), [sx]);

  return (
    <Component className={clsx(className, atomicClasses)} ref={ref} {...nativeProps}>
      {children}
    </Component>
  );
});

if (__DEV__) {
  Box.displayName = 'Box';
}
