import type { RegistryDefinitionFormWithFormKey } from '@app/app-config/registry.config';
import type {
  AnyFormBuilder,
  AnyRecord,
  FormBuilderEvent,
  FormSaveType,
  InferInfoFromFormBuilder
} from '@oms/frontend-foundation';
import { GridIdService } from '@oms/frontend-vgrid';
import type {
  ActionComponentConfig,
  ActionContext,
  GridActionBuilder,
  ComponentLocationKeys
} from '@oms/frontend-vgrid';
import type { Subscription } from 'rxjs';
import { isPromiseLike } from '@oms/shared/util';
import { PlusIcon } from '@radix-ui/react-icons';
import type { ColumnApi, GetRowIdFunc, GetRowIdParams } from '@ag-grid-community/core';
import type { BuilderCallback } from '@oms/shared/util-types';
import type { Actor } from '@valstro/workspace';
import type { FormMap } from '@app/generated/mappers';
import { openConfirmation, openFormFromDictionary } from '@app/generated/sdk';
import { asAppWindowActor } from '@app/app-config/workspace.config';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';
import { ValstroEntitlement } from '@app/common/auth/keycloak.types';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

/**
 * CRUD action types
 */
export const CRUD_WINDOW_GRID_ACTION_TYPE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
} as const;

export type CrudWindowGridActionType = keyof typeof CRUD_WINDOW_GRID_ACTION_TYPE;

/**
 * Ensure the row data matches the input values of the form builder
 */
type EnsureRowDataMatchesInputValues<
  TData extends AnyRecord,
  B extends AnyFormBuilder,
  T,
  DoNotCheckRowData extends boolean = false
> = DoNotCheckRowData extends true
  ? T
  : TData extends InferInfoFromFormBuilder<B>['inputContract']
    ? T
    : {
        error: 'Row data (TData) does not extend this form builders initial values (input contract)';
      };

/**
 * Context for the CRUD action
 */
export type CreateCrudWindowGridOptionsContext<TData extends AnyRecord> = ActionContext<TData> & {
  formWindowActor: Actor<AppWindowActorSchema>;
};

/**
 * Enrich the CRUD action with specific props
 * Note: Used to enrich the CRUD action with specific props based on the action type (e.g. DELETE)
 */
type EnrichWithCrudSpecificProps<
  TActionType extends CrudWindowGridActionType,
  TData extends AnyRecord,
  B extends AnyFormBuilder,
  T
> = TActionType extends 'DELETE'
  ? Omit<T, 'onFormEvent' | 'formKey' | 'formOverrides'> & {
      onConfirmDelete: (
        selectedRows: TData[],
        ctx: CreateCrudWindowGridOptionsContext<TData>
      ) => Promise<void> | void;
    }
  : TActionType extends 'UPDATE'
    ? T & {
        rowDataToFormInput: (rowData: TData) => InferInfoFromFormBuilder<B>['inputContract'];
      }
    : T;

/**
 * Options for the CRUD action
 */
export type CreateCrudWindowGridOptions<
  TActionType extends CrudWindowGridActionType,
  TData extends AnyRecord,
  K extends keyof FormMap,
  B extends AnyFormBuilder = FormMap[K],
  DoNotCheckRowData extends boolean = false
> = EnsureRowDataMatchesInputValues<
  TData,
  B,
  EnrichWithCrudSpecificProps<
    TActionType,
    TData,
    B,
    Pick<RegistryDefinitionFormWithFormKey<B>, 'form' | 'windowOptions'> & {
      formKey: K;
      onFormEvent?: (
        event: FormBuilderEvent<
          InferInfoFromFormBuilder<B>['outputContract'],
          InferInfoFromFormBuilder<B>['fieldValues']
        >,
        ctx: CreateCrudWindowGridOptionsContext<TData>
      ) => Promise<void> | void;
      titleGetter?: (rowDataSelection: TData[]) => string;
    }
  >,
  DoNotCheckRowData
>;

/**
 *
 * @param entityName - The name of the entity (e.g. 'Currency')
 * @returns - A function that creates a CRUD action
 */
export function crudWindowGridActionBuilder<TData extends AnyRecord>(
  entityName: string,
  requiredEntitlements?: ValstroEntitlement[]
) {
  /**
   * Create a CRUD action
   * Note: Used to create a CRUD action for the grid
   * @param type - The type of the CRUD action (e.g. 'CREATE', 'UPDATE', 'DELETE')
   * @param options - The options for the CRUD action
   * @returns - An action schema
   */
  return function creator<
    TActionType extends CrudWindowGridActionType,
    K extends keyof FormMap,
    B extends AnyFormBuilder = FormMap[K]
  >(
    type: TActionType,
    options: CreateCrudWindowGridOptions<TActionType, TData, K, B>,
    overrideCb?: (cb: BuilderCallback<GridActionBuilder<TData>>) => BuilderCallback<GridActionBuilder<TData>>
  ): BuilderCallback<GridActionBuilder<TData>> {
    const toolbarLocation: ComponentLocationKeys =
      type === 'CREATE' ? 'HorizontalToolbarLeft' : 'HorizontalToolbarRight';
    const buttonStyling: ActionComponentConfig<TData> =
      type === 'CREATE' ? { variant: 'ghost', leftIcon: <PlusIcon /> } : {};
    const buttonContent = type === 'CREATE' ? `Add ${entityName}` : type === 'UPDATE' ? `Edit` : `Delete`;
    const outsideCtx = {} as { formEventsSub?: Subscription };
    const unwrappedOptions = options as CreateCrudWindowGridOptions<TActionType, TData, K, B, true>;
    const windowOptions = options as CreateCrudWindowGridOptions<'CREATE' | 'UPDATE', TData, K, B, true>;

    const builderCb: BuilderCallback<GridActionBuilder<TData>> = (ab) =>
      ab
        .name(`${type}_${entityName}_action`)
        .lifecycles('onSelectionChanged', 'change', 'onGridPreDestroyed', 'onRowDoubleClicked')
        .onChange((ctx) => {
          const { lifecycle, api, notify } = ctx;
          const selectedRows = api.getSelectedRows() as TData[];
          const openWindowOptions: OnChangeOpenFormWindowOptions<TData, K, B> = {
            entityName,
            type,
            options: windowOptions,
            ctx,
            rowId: entityName
          };

          switch (lifecycle) {
            case 'onRowDoubleClicked': {
              if (type === 'UPDATE') {
                const getRowId = api.getGridOption('getRowId') as GetRowIdFunc<TData>;
                const rowIdOptions: GetRowIdParams<TData> = {
                  api,
                  columnApi: {} as ColumnApi,
                  context: {},
                  data: selectedRows[0],
                  level: 0,
                  parentKeys: []
                };
                const rowId = getRowId ? getRowId(rowIdOptions) : entityName;
                onChangeOpenFormWindow<TData, K, B>(outsideCtx, {
                  ...openWindowOptions,
                  rowId
                });
              }
              break;
            }
            case 'onSelectionChanged': {
              const isAuthorized = true; // TODO: Implement authorization check
              switch (type) {
                case 'UPDATE': {
                  notify({ isDisabled: !isAuthorized || selectedRows.length !== 1 });
                  break;
                }
                case 'DELETE': {
                  notify({ isDisabled: !isAuthorized || selectedRows.length === 0 });
                  break;
                }
              }
              break;
            }
            case 'change': {
              switch (type) {
                case 'CREATE':
                case 'UPDATE': {
                  onChangeOpenFormWindow<TData, K, B>(outsideCtx, openWindowOptions);
                  break;
                }
                case 'DELETE': {
                  if (selectedRows.length === 0) {
                    break;
                  }
                  openConfirmation(ctx.workspace, ctx.widgetActor.id, {
                    componentProps: {
                      message: `Are you sure you want to delete ${selectedRows.length} ${entityName} record(s)?`,
                      confirmButtonText: 'Delete',
                      cancelButtonText: 'Cancel',
                      autoClose: true
                    }
                  })
                    .then(([_dialog, api]) => {
                      api.awaitFirstEvent
                        .then((event) => {
                          if (
                            event.type === 'OK' &&
                            type === 'DELETE' &&
                            'onConfirmDelete' in unwrappedOptions
                          ) {
                            const result = unwrappedOptions.onConfirmDelete(selectedRows, {
                              ...ctx,
                              formWindowActor: asAppWindowActor(ctx.windowActor)
                            });
                            if (isPromiseLike(result)) {
                              result.catch(console.error);
                            }
                          }
                        })
                        .catch(console.error);
                    })
                    .catch(console.error);
                  break;
                }
              }
              break;
            }
            case 'onGridPreDestroyed': {
              outsideCtx.formEventsSub?.unsubscribe();
              break;
            }
          }
        })
        .access(({ appContainer }) => {
          if (requiredEntitlements) {
            const authService = appContainer.resolve(AuthService);
            return authService.hasEntitlement(requiredEntitlements);
          }
          // if no `requiredEntitlements` are provided, we allow access by default
          return true;
        })
        .menu((m) =>
          m.name(buttonContent).visible(({ gridApi }) => {
            const selectedRows = gridApi.getSelectedRows() as TData[];
            return selectedRows.length > 0;
          })
        )
        .toolbar((t) =>
          t
            .component('action-button')
            .id(`${type}_${entityName}_button`)
            .location(toolbarLocation)
            .props({
              ...buttonStyling,
              content: buttonContent,
              isDisabled: type !== 'CREATE'
            })
        );

    return overrideCb ? overrideCb(builderCb) : builderCb;
  };
}

/**
 * Form window options for the CRUD action
 * Note: Used to open a form window based on the options provided
 * see `onChangeOpenFormWindow`
 */
interface OnChangeOpenFormWindowOptions<
  TData extends AnyRecord,
  K extends keyof FormMap,
  B extends AnyFormBuilder = FormMap[K]
> {
  entityName: string;
  rowId: string;
  type: CrudWindowGridActionType;
  options: CreateCrudWindowGridOptions<'CREATE' | 'UPDATE', TData, K, B, true>;
  ctx: ActionContext<TData>;
}

/**
 * Open a form window based on the options provided
 * Note: Used on the `onChange` lifecycle of the action
 *
 * @param options - CreateCrudWindowGridOptions
 * @param ctx - ActionContext
 */
function onChangeOpenFormWindow<
  TData extends AnyRecord,
  K extends keyof FormMap,
  B extends AnyFormBuilder = FormMap[K]
>(outsideCtx: { formEventsSub?: Subscription }, _options: OnChangeOpenFormWindowOptions<TData, K, B>) {
  const { entityName, rowId, type, options, ctx } = _options;
  const {
    formKey,
    windowOptions,
    onFormEvent,
    form,
    titleGetter = () => `${type === 'CREATE' ? `Create ${entityName}` : `Edit ${entityName}`}`
  } = options;
  const { api, container, widgetActor } = ctx;
  const gridIdService = container.resolve(GridIdService);
  const formId = `${rowId}_${gridIdService.gridType}_${type}`;
  const selectedRows = api.getSelectedRows() as TData[];
  const rowData =
    selectedRows.length === 1 && type === 'UPDATE'
      ? (selectedRows[0] as InferInfoFromFormBuilder<B>['inputContract'])
      : undefined;

  const rowDataToFormInput = 'rowDataToFormInput' in options ? options.rowDataToFormInput : undefined;
  const input = rowDataToFormInput && rowData ? rowDataToFormInput(rowData) : undefined;

  openFormFromDictionary<K>(formKey, widgetActor.id, {
    form: {
      ...form,
      input,
      formSaveType: type as FormSaveType,
      formId
    },
    title: titleGetter(selectedRows),
    windowOptions
  })
    .then((form) => {
      outsideCtx.formEventsSub?.unsubscribe();
      if (onFormEvent) {
        outsideCtx.formEventsSub = form.events$.subscribe((event) => {
          const windowActor = form.window;
          const result = onFormEvent(event, { ...ctx, formWindowActor: windowActor });
          if (isPromiseLike(result)) {
            result.catch((err) => {
              console.error(err);
            });
          }
        });
      }
    })
    .catch((err) => {
      console.error(err);
    });
}
