import type { Optional } from '@oms/shared/util-types';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import type { Observable } from 'rxjs';

export type PairedValueStateObject<A, B> = {
  valueA?: A;
  valueB?: B;
};

/**
 * This is an abstract class used to hold a pair of two values we need to keep track of.
 * We may need them to track some business logic in which the two values are related.
 * That will be defined in your subclass, but this just provides the common boilerplate.
 */
export abstract class PairedValueState<A, B = A, U = A> {
  protected _valueA$: BehaviorSubject<Optional<A>> = new BehaviorSubject<Optional<A>>(undefined);
  protected _valueB$: BehaviorSubject<Optional<B>> = new BehaviorSubject<Optional<B>>(undefined);

  public get valueA(): Optional<A> {
    return this._valueA$.getValue();
  }

  public get valueA$(): Observable<Optional<A>> {
    return this._valueA$;
  }

  public get valueB(): Optional<B> {
    return this._valueB$.getValue();
  }

  public get valueB$(): Observable<Optional<B>> {
    return this._valueB$;
  }

  public get current(): PairedValueStateObject<A, B> {
    return {
      valueA: this._valueA$.getValue(),
      valueB: this._valueB$.getValue()
    };
  }

  public get current$(): Observable<PairedValueStateObject<A, B>> {
    return combineLatest([this._valueA$, this._valueB$]).pipe(
      map(([valueA, valueB]) => {
        return {
          valueA,
          valueB
        };
      })
    );
  }

  public updateA(value: Optional<A>) {
    this._valueA$.next(value);
  }

  public updateB(value: Optional<B>) {
    this._valueB$.next(value);
  }

  /** Override this in your subclass to handle mapping your update type to updates to this class's state  */
  public abstract update(updateValue: U): PairedValueStateObject<A, B>;

  public reset() {
    this._valueA$.next(undefined);
    this._valueB$.next(undefined);
  }
}

export default PairedValueState;
