import { ActorSchema, ActorSchemaBuilder, AnyRecord, CreateActorOptions } from '../../../core';
import { COMMON_ACTOR_TYPE, WidgetContext, WidgetOperations } from '../shared';

export const WIDGET_ACTOR_NAME = 'widget';

export const WidgetActorSchemaBuilder = ActorSchemaBuilder.create<WidgetContext, WidgetOperations>({
  name: WIDGET_ACTOR_NAME,
  type: COMMON_ACTOR_TYPE.WIDGET,
  supportedPlatforms: '*',
  isWindowRootable: false,
  initialContext: ({ initialContext }) => ({
    componentId: 'unknown',
    componentProps: {},
    title: 'New Widget',
    ...(initialContext || {})
  }),
  operations: ({ updateContext, getContext }) => ({
    setContext: async (context: WidgetContext) => {
      await updateContext(context);
    },
    updateContext: async (contextDelta: Partial<WidgetContext>) => {
      await updateContext({
        ...getContext(),
        ...contextDelta
      });
    },
    updateProps: async (propsDelta: Partial<AnyRecord>) => {
      await updateContext({
        componentProps: {
          ...getContext().componentProps,
          ...propsDelta
        }
      });
    },
    setProps: async (props: AnyRecord) => {
      await updateContext({
        componentProps: props
      });
    },
    setTitle: async (title: string) => {
      await updateContext({
        title
      });
    }
  })
});

export type WidgetActorSchema = typeof WidgetActorSchemaBuilder.schema;

export type AnyWidgetActorSchema = ActorSchema<WidgetContext<any>, WidgetOperations, any, any>;

/**
 * Generate a widget actor definition
 *
 * @param componentId - The component ID
 * @param props - The component props
 * @param children - The component children
 * @returns - The actor definition
 */
export function widgetActorDef<Props extends AnyRecord = AnyRecord>(
  componentId: string,
  props?: Props,
  children?: CreateActorOptions<WidgetContext<Props>>[]
): CreateActorOptions<WidgetContext<Props>> {
  return {
    type: COMMON_ACTOR_TYPE.WIDGET,
    context: {
      componentId,
      componentProps: props || ({} as Props)
    },
    children
  };
}
