import { useFieldApi, type UseFieldApiConfig, type Validator } from '@data-driven-forms/react-form-renderer';
import { Checkbox, CheckboxGroup, type CheckboxGroupProps } from '@oms/shared-frontend/ui-design-system';
import { omit } from 'lodash';
import { type FC } from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import type { ICommonField } from '../../types';

export interface FormCheckboxGroupData {
  values: { label: string; value: string | number }[];
}
export type FormCheckboxGroupProps = Omit<CheckboxGroupProps, 'value'> & FormCheckboxGroupData;
export interface ICheckboxGroupField<TValidator = Validator>
  extends ICommonField<typeof FORM_COMPONENT_TYPE.CHECKBOX_GROUP, string | number[], TValidator>,
    FormCheckboxGroupData {}

export const FormCheckboxGroup: FC<UseFieldApiConfig> = (props) => {
  const {
    meta,
    isReadOnly,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isInvalid,
    isVisible,
    requiredFieldIndicatorStyle,
    group,
    input,
    label,
    helperText,
    style
  } = useFieldApi(props);

  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const fieldWrapperProps = {
    meta,
    label,
    isReadOnly,
    isRequired,
    isDisabled,
    isInvalid,
    requiredFieldIndicatorStyle,
    helperText,
    style,
    isVisible
  };

  // TODO: group no longer seems to work

  const checkboxGroupProps = group as FormCheckboxGroupProps;
  const checkboxGroupComponent = {
    ...omit(checkboxGroupProps, 'values'),
    value: input.value,
    onChange: input.onChange,
    onFocus: input.onFocus,
    onBlur: input.onBlur
  };

  return (
    <FieldWrapper {...fieldWrapperProps}>
      <CheckboxGroup {...checkboxGroupComponent}>
        {checkboxGroupProps.values?.map((cb) => (
          <Checkbox key={cb.value} value={cb.value}>
            {cb.label}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </FieldWrapper>
  );
};
