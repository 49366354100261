import { AgGridEvent, ColumnApi, GridApi } from '@ag-grid-community/core';
import { delay } from 'tsyringe';
import { useGridContainer } from './grid.container.hook';
import { useMemo } from 'react';

export function useGridApi<TData>(): Omit<AgGridEvent<TData>, 'type' | 'context'> {
  const gridContainer = useGridContainer();
  const gridApi = gridContainer.resolve(delay(() => GridApi));
  const columnApi = gridContainer.resolve(delay(() => ColumnApi));

  return useMemo(() => ({ api: gridApi, columnApi }), [gridApi, columnApi]);
}
