import type {
  MONTAGE_BUMP_PRICE_ACTION_NAME,
  MontageBumpPrice
} from '@app/widgets/trading/montage/commands/bump-price/bump-price.action.types';
import type {
  MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME,
  MontageTargetTradingOrder
} from '@app/widgets/trading/montage/commands/target-trading-order/target-trading-order.action.types';
import type {
  CREATE_UNBOUND_TO_ACTION_NAME,
  CreateUnboundTradeOrder
} from '@app/widgets/trading/route-order/commands/create-unbound-trading-order/create-unbound-trading-order.action.types';
import type { ButtonConfigSubject } from '../events/button.config.subject';
import type { Action } from '../types/action.types';

// TODO: Codegen adding commands to the types in this file.
export type ActionCommands =
  | typeof CREATE_UNBOUND_TO_ACTION_NAME
  | typeof MONTAGE_BUMP_PRICE_ACTION_NAME
  | typeof MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME;

export type ActionCommandConfig<T extends ActionCommandContractValues> = CommandMeta &
  Pick<Action, 'confirmation'> & { payload?: T; actionContext$?: ButtonConfigSubject };

export type ActionCommandContracts = {
  [CREATE_UNBOUND_TO_ACTION_NAME]: CreateUnboundTradeOrder;
  [MONTAGE_BUMP_PRICE_ACTION_NAME]: MontageBumpPrice;
  [MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME]: MontageTargetTradingOrder;
};

export const AllActionCommandsRecord: Record<keyof ActionCommandContracts, true> = {
  create_unbound_to: true,
  montage_bump_price: true,
  montage_target_trading_order: true
};

export const ActionCommandsList = Object.keys(AllActionCommandsRecord) as readonly string[];

export type ActionCommandContractValues = ActionCommandContracts[keyof ActionCommandContracts];

export type CommandMeta = {
  allowsConfirmation: boolean;
  allowsLabel: boolean;
  allowsSize: boolean;
  allowsColor: boolean;
  commandType: CommandTypes;
};

export type CommandTypes =
  | 'DialogWithDefaults'
  | 'LaunchDialog'
  | 'LaunchWidget'
  | 'SingleCommand'
  | 'Custom';

export const COMMANDS_TO_META: Record<ActionCommands, CommandMeta> = {
  create_unbound_to: {
    allowsLabel: true,
    allowsConfirmation: true,
    allowsSize: true,
    allowsColor: true,
    commandType: 'DialogWithDefaults'
  },
  montage_bump_price: {
    allowsLabel: false,
    allowsConfirmation: false,
    allowsSize: false,
    allowsColor: false,
    commandType: 'Custom'
  },
  montage_target_trading_order: {
    allowsLabel: false,
    allowsConfirmation: false,
    allowsSize: false,
    allowsColor: false,
    commandType: 'Custom'
  }
};
