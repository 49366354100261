/**
 * Leave everything outside the current selection bounds alone.
 *  Insert "toAdd" in between preserved string value.
 *
 * @param str raw input value
 * @param toAdd new value to interpolate
 * @param caretStart Caret/selection start index
 * @param caretEnd Caret/selection end index
 * @returns string
 */
export const editString = (
  str: string,
  toAdd: string,
  caretStart: number,
  caretEnd: number = caretStart
): string => `${str.slice(0, caretStart)}${toAdd}${str.slice(caretEnd, str.length)}`;
