import { BehaviorSubject, map } from 'rxjs';
import type { CrossPrincipalFillState } from '@app/widgets/trading/cross-principal-fill/cross-principal-fill.form-common';

export const INITIAL_STATE: CrossPrincipalFillState = {
  buySideRows: [],
  sellSideRows: [],
  formValues: {}
};

export const stateMap: Map<string, BehaviorSubject<CrossPrincipalFillState>> = new Map();

export const getState$ = (formId: string) => {
  if (!stateMap.has(formId)) {
    stateMap.set(formId, new BehaviorSubject<CrossPrincipalFillState>(INITIAL_STATE));
  }

  return stateMap.get(formId) as BehaviorSubject<CrossPrincipalFillState>;
};

export const getCrossPrincipalFillOrders$ = (formId: string) => {
  return getState$(formId).pipe(
    map(({ buySideRows, sellSideRows }) => {
      return {
        sellOrders: sellSideRows,
        buyOrders: buySideRows
      };
    })
  );
};

export const clearState = (formId: string) => {
  stateMap.delete(formId);
};
