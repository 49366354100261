import type { ColumnState, FilterModel } from '@ag-grid-community/core';
import type { RxJsonSchema, RxDocument, RxCollection } from 'rxdb-v15';

export const GRID_TEMPLATE_COLLECTION_NAME = 'grid_templates';

export const gridTemplateSchema: RxJsonSchema<GridTemplateDocType> = {
  title: 'grid templates schema',
  description: 'describes a grid template',
  version: 0,
  keyCompression: false,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36
    },
    gridType: {
      type: 'string',
      maxLength: 128
    },
    name: {
      type: 'string'
    },
    state: {
      type: 'object'
    },
    createdAt: {
      type: 'string'
    },
    updatedAt: {
      type: 'string'
    },
    lastLoadedAt: {
      type: 'string'
    },
    lastDefaultedAt: {
      type: 'string'
    }
  },
  required: ['id', 'gridType', 'name', 'state', 'createdAt', 'updatedAt'],
  indexes: ['gridType']
} as const;

export type GridState = {
  columnState?: ColumnState[];
  columnGroupState?: {
    groupId: string;
    open: boolean;
  }[];
  filterModel?: FilterModel | null;
};

export type GridTemplateDocType = {
  id: string;
  gridType: string;
  name?: string;
  createdAt: string;
  updatedAt: string;
  lastLoadedAt?: string;
  lastDefaultedAt?: string;
  state: GridState;
};

export type GridTemplateDocument = RxDocument<GridTemplateDocType>;

export type GridTemplateCollection = RxCollection<GridTemplateDocType>;
