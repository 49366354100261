import { ALL_VERSIONS as actions } from '@app/actions/collections/migrations';
import { RxCollection } from 'rxdb-v15';

export const REPLICATION_MIGRATIONS = {
  actions
} as const;

export type MigrationCollectionName = keyof typeof REPLICATION_MIGRATIONS;
export const REPLICATION_MIGRATIONS_KEYS = Object.keys(REPLICATION_MIGRATIONS) as MigrationCollectionName[];
export interface MigrationCollection extends RxCollection {
  name: MigrationCollectionName;
}
