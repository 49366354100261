import {
  CommonWindowActorSchema,
  BrowserModalWindowActorSchemaBuilder,
  isUndefined,
  AnyActorSchema,
  Actor,
  internalWorkspace,
  emitCustomActorEvent,
  ClickEvent,
  ACTOR_STATE,
  COMMON_ACTOR_TYPE,
  isDefined,
  ensureInt
} from '@valstro/workspace';
import type { DraggableEvent } from 'react-draggable';
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ResizeDirection } from 're-resizable';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Rnd, RndResizeCallback, DraggableData, ResizableDelta, Position } from 'react-rnd';
import {
  ReactActorComponentProps,
  useActorState,
  ReactActorComponentChildren,
  useActorContext,
  useWorkspace
} from '../../../../core';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  CommonReactWindowActorOptions,
  CommonWindowDecoration,
  WindowDecorationContext,
  useGetWindowDecorationComponent,
  useGetWindowDecorationContext
} from '../../../common/window';
import { useGetWindowWrapperComponent } from '../../../common/window/wrapper.window.react.actor.common';

export const ModalWindowPortal: FC<PropsWithChildren> = ({ children }) => {
  return createPortal(children, document.querySelector('.workspace-portal-container') as HTMLDivElement);
};

export const ModalWindowToolbarPortal: FC<PropsWithChildren> = ({ children }) => {
  return createPortal(
    children,
    document.querySelector('.workspace-toolbar-portal-container') as HTMLDivElement
  );
};

export function ModalWindow<TOverride extends AnyActorSchema = CommonWindowActorSchema>({
  actor: _actor,
  children,
  isVisibleOverride
}: ReactActorComponentProps<TOverride> & {
  children?: React.ReactNode;
  isVisibleOverride?: boolean;
}) {
  const workspace = useWorkspace();
  const actor = useMemo(() => _actor as Actor<CommonWindowActorSchema>, [_actor]);
  const [state] = useActorState(actor);
  const context = useActorContext(actor);
  const WindowWrapper = useGetWindowWrapperComponent(actor);
  const WindowDecorationComponent = useGetWindowDecorationComponent(actor);
  const [isDragging, setDragging] = useState(false);

  const {
    width,
    height,
    x,
    y,
    initiallyCentered,
    isFocused,
    minHeight,
    minWidth,
    isDecorated,
    maxHeight,
    maxWidth,
    isMaximized,
    isResizable,
    isFullscreen,
    alwaysOnTop,
    isVisible: isVisibleProp,
    isMinimized,
    transparent,
    title,
    requestingUserAttention
  } = context;

  const isVisible = isDefined(isVisibleOverride) ? isVisibleOverride : isVisibleProp;

  const isFullscreenOrMaximized = isFullscreen || isMaximized;
  const canInteract = isFullscreenOrMaximized === false && isMinimized === false;
  const isFocusedRef = useRef(isFocused);
  isFocusedRef.current = isFocused;

  const decorationComponentRef = useRef(
    WindowDecorationComponent && !isDecorated ? WindowDecorationComponent : CommonWindowDecoration
  );

  const remotePosition = useMemo(() => {
    if (isFullscreenOrMaximized) {
      return {
        x: 0,
        y: 0
      };
    }

    if (initiallyCentered || isUndefined(x) || isUndefined(y)) {
      return {
        x: window.innerWidth / 2 - width / 2,
        y: window.innerHeight / 2 - height / 2
      };
    }

    return {
      x,
      y
    };
  }, [initiallyCentered, width, height, x, y, isFullscreenOrMaximized]);

  const remoteSize = useMemo(() => {
    if (isFullscreenOrMaximized) {
      return {
        width: window.innerWidth,
        height: window.innerHeight
      };
    }

    return {
      width,
      height
    };
  }, [width, height, isFullscreenOrMaximized]);

  const sizeRef = useRef(remoteSize);
  const positionRef = useRef(remotePosition);
  const [position, setPosition] = useState(remotePosition);
  const [size, setSize] = useState(remoteSize);
  sizeRef.current = size;
  positionRef.current = position;

  useEffect(() => {
    if (sizeRef.current.width !== remoteSize.width || sizeRef.current.height !== remoteSize.height) {
      setSize({
        width: ensureInt(remoteSize.width),
        height: ensureInt(remoteSize.height)
      });
    }
  }, [remoteSize]);

  useEffect(() => {
    if (positionRef.current.x !== remotePosition.x || positionRef.current.y !== remotePosition.y) {
      setPosition({
        x: ensureInt(remotePosition.x),
        y: ensureInt(remotePosition.y)
      });
    }
  }, [remotePosition]);

  useEffect(() => {
    const getFocusArea = () =>
      document.querySelector(`.actor-drag-wrapper[data-actor-id="${actor.id}"]`) as HTMLElement | null;

    const focusHandler = (_e: FocusEvent) => {
      actor.operations.focus({ isUserInteraction: true }).catch(console.error);
    };

    getFocusArea()?.addEventListener('mousedown', focusHandler);

    return () => {
      getFocusArea()?.removeEventListener('mousedown', focusHandler);
    };
  }, [actor.id, actor.operations]);

  const handleDragStart = useCallback(
    (e: DraggableEvent, _data: DraggableData) => {
      if (
        e.target &&
        'getAttribute' in e.target &&
        (e.target as HTMLElement).getAttribute('data-suppress-event') === 'click--inside-dragstart'
      ) {
        return;
      }

      // Fire click event, because click events get lost here and these events are useful for things like closing popovers
      const eventsTransport = internalWorkspace(workspace)._eventsTransport;
      emitCustomActorEvent<ClickEvent>(
        eventsTransport,
        {
          id: actor.id,
          children: [],
          context: {},
          name: actor.name,
          state: ACTOR_STATE.RUNNING,
          type: COMMON_ACTOR_TYPE.WINDOW,
          parentId: actor.parentId
        },
        {
          type: 'click',
          payload: {
            x: (e as MouseEvent).clientX,
            y: (e as MouseEvent).clientY,
            type: 'inside-dragstart'
          }
        }
      ).catch(console.error);

      if (!canInteract) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
    },
    [canInteract, workspace, actor]
  );

  const handleDrag = useCallback(
    (e: DraggableEvent, _data: DraggableData) => {
      if (!canInteract) {
        e.preventDefault();
        return;
      }
      setDragging(true);
    },
    [canInteract]
  );

  const handleDragStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (!canInteract) {
        e.preventDefault();
        return;
      }
      setDragging(false);
      setPosition({
        x: ensureInt(data.x),
        y: ensureInt(data.y)
      });
      actor.operations
        .setPosition(
          {
            x: ensureInt(data.x),
            y: ensureInt(data.y)
          },
          { isUserInteraction: true }
        )
        .catch((e: Error) => {
          console.error(e);
        });
    },
    [actor.operations, canInteract]
  );

  const handleResizeStop: RndResizeCallback = useCallback(
    (
      _e: MouseEvent | TouchEvent,
      _dir: ResizeDirection,
      _elementRef: HTMLElement,
      delta: ResizableDelta,
      position: Position
    ) => {
      setSize({
        width: ensureInt(size.width + delta.width),
        height: ensureInt(size.height + delta.height)
      });

      setPosition({
        x: ensureInt(position.x),
        y: ensureInt(position.y)
      });

      actor.operations
        .setSizeAndPosition(
          {
            width: ensureInt(size.width + delta.width),
            height: ensureInt(size.height + delta.height)
          },
          {
            x: ensureInt(position.x),
            y: ensureInt(position.y)
          },
          {
            isUserInteraction: true
          }
        )
        .catch(console.error);
    },
    [size, actor.operations]
  );

  const style = useMemo(() => {
    const styles: React.CSSProperties = {
      position: 'absolute',
      zIndex: alwaysOnTop ? 20 : isFocused ? 10 : 1,
      pointerEvents: isDragging || isVisible === false ? 'none' : 'all',
      visibility: isVisible ? 'visible' : 'hidden'
    };

    if (transparent) {
      styles.backgroundColor = 'transparent';
    }

    return styles;
  }, [isFocused, alwaysOnTop, isDragging, isVisible, transparent]);

  const decorationContext = useGetWindowDecorationContext(actor, context, state);

  if (isMinimized && state === 'running') {
    return (
      <ModalWindowToolbarPortal>
        <div
          className={`actor-minimized-tab ${actor.type}-minimized-tab ${actor.name}-minimized-tab${
            requestingUserAttention
              ? ` actor-requesting-user-attention actor-requesting-user-attention--${requestingUserAttention}`
              : ``
          }`}
          data-actor-id={actor.id}
          data-actor-type={actor.type}
          onClick={() => {
            actor.operations.unminimize({ isUserInteraction: true }).catch(console.error);
          }}
        >
          {title}
        </div>
      </ModalWindowToolbarPortal>
    );
  }

  switch (state) {
    case 'idle':
    case 'starting':
    case 'applying-snapshot':
    case 'failed':
    case 'destroyed':
      return null;
    default:
      return (
        <ModalWindowPortal>
          <Rnd
            key={`${actor.id}-${actor.type}`}
            id={`${actor.id}-${actor.type}`}
            className={`actor-drag-wrapper ${actor.type}-drag-wrapper ${
              actor.name
            }-drag-wrapper${isDragging ? ' actor-dragging' : ''}${
              requestingUserAttention
                ? ` actor-requesting-user-attention actor-requesting-user-attention--${requestingUserAttention}`
                : ``
            }`}
            size={size}
            position={position}
            style={style}
            minHeight={minHeight}
            minWidth={minWidth}
            maxHeight={maxHeight}
            bounds="body"
            maxWidth={maxWidth}
            enableResizing={canInteract}
            disableDragging={isResizable === false}
            dragHandleClassName={`actor-drag-handle-${actor.id}`}
            onResizeStop={handleResizeStop}
            onDragStart={handleDragStart}
            onDrag={handleDrag}
            onDragStop={handleDragStop}
            data-actor-id={actor.id}
            data-actor-type={actor.type}
          >
            <WindowWrapper>
              <WindowDecorationContext.Provider value={decorationContext}>
                <decorationComponentRef.current data-actor-id={actor.id} data-actor-type={actor.type}>
                  {children ? children : <ReactActorComponentChildren actor={actor} />}
                </decorationComponentRef.current>
              </WindowDecorationContext.Provider>
            </WindowWrapper>
          </Rnd>
        </ModalWindowPortal>
      );
  }
}

export const ReactBrowserModalWindowView: React.FC<ReactActorComponentProps<CommonWindowActorSchema>> = ({
  actor
}) => {
  return <ModalWindow actor={actor} />;
};

export type ReactBrowserModalWindowViewType = React.ComponentType<
  ReactActorComponentProps<CommonWindowActorSchema>
>;

export const ReactBrowserModalWindowActorSchemaBuilder =
  BrowserModalWindowActorSchemaBuilder.extendView(ReactBrowserModalWindowView);

export const browserModalWindowActor =
  ReactBrowserModalWindowActorSchemaBuilder.optionsCreator<CommonReactWindowActorOptions>();

export type ReactBrowserModalWindowActorSchema = typeof ReactBrowserModalWindowActorSchemaBuilder.schema;
