declare global {
  interface Window {
    workspaceDebugLoggerOptions: DebugLoggerOptions | undefined;
  }
}

export interface DebugLoggerOptions {
  enabled: boolean;
  /* default: false - uses console.debug by default */
  consoleLog?: boolean;
  onLog?: (...args: any[]) => void;
}

const defaultDebugLoggerOptions: DebugLoggerOptions = {
  enabled: false,
  consoleLog: false
};

export function debugLogger({ enabled, consoleLog }: DebugLoggerOptions) {
  window.workspaceDebugLoggerOptions = {
    enabled,
    consoleLog
  };
}

export const logger = {
  info: (...args: any[]) => {
    console.log(...args);
  },
  warn: (...args: any[]) => {
    console.warn(...args);
  },
  error: (...args: any[]) => {
    console.error(...args);
  },
  debug: (...args: any[]) => {
    const options = window.workspaceDebugLoggerOptions || defaultDebugLoggerOptions;

    if (options && options.onLog) {
      options.onLog(...args);
    }

    if (!options.enabled) {
      return;
    }

    if (options.consoleLog) {
      console.log(...args);
    } else {
      console.debug(...args);
    }
  }
};
