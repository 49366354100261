import { CommonWindowActorSchema, BrowserTabWindowActorSchemaBuilder } from '@valstro/workspace';
import {
  ReactActorComponentProps,
  useActorState,
  ReactActorComponentChildren,
  useActorClassNames
} from '../../../../core';
import { CommonReactWindowActorOptions } from '../../../common/window';
import { useGetWindowWrapperComponent } from '../../../common/window/wrapper.window.react.actor.common';

export const ReactBrowserTabWindowView: React.FC<ReactActorComponentProps<CommonWindowActorSchema>> = ({
  actor
}) => {
  const [state, failedMessage] = useActorState(actor);
  const className = useActorClassNames(actor, state);
  const WindowWrapper = useGetWindowWrapperComponent(actor);

  switch (state) {
    case 'idle':
      return <div className={className.wrapper}>Idle</div>;
    case 'starting':
      return <div className={className.wrapper}>Loading...</div>;
    case 'applying-snapshot':
      return <div className={className.wrapper}>Loading layout...</div>;
    case 'failed':
      return (
        <div className={className.wrapper}>
          <div className={className.box}>
            <div className={className.title}>Error</div>
            {failedMessage && <div className={className.message}>{failedMessage}</div>}
          </div>
        </div>
      );
    case 'destroyed':
      return (
        <div className={className.wrapper}>
          <div className={className.box}>
            <div className={className.title}>Window Disconnected</div>
            <div className={className.message}>Please refresh to re-connect</div>
          </div>
        </div>
      );
    default:
      return (
        <WindowWrapper>
          <div className={className.wrapper}>
            <ReactActorComponentChildren actor={actor} />
          </div>
        </WindowWrapper>
      );
  }
};

export type ReactBrowserTabWindowViewType = React.ComponentType<
  ReactActorComponentProps<CommonWindowActorSchema>
>;

const ReactBrowserTabWindowActorSchemaBuilder =
  BrowserTabWindowActorSchemaBuilder.extendView(ReactBrowserTabWindowView);

export const browserTabWindowActor =
  ReactBrowserTabWindowActorSchemaBuilder.optionsCreator<
    Pick<CommonReactWindowActorOptions, 'wrapperComponent'>
  >();

export type ReactBrowserTabWindowActorSchema = typeof ReactBrowserTabWindowActorSchemaBuilder.schema;
