import { useLayoutEffect } from 'react';
import { Actions } from 'flexlayout-react';
import { reactFlexLayoutEvents } from '@valstro/workspace-react';

export const useSelectTabOnLayoutEffect = (scopedActorId: string, tab: string) => {
  useLayoutEffect(() => {
    reactFlexLayoutEvents
      .emit('doAction', {
        flexLayoutActorId: scopedActorId,
        action: Actions.selectTab(tab)
      })
      .catch(console.error);
  }, [tab, scopedActorId]);
};
