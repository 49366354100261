import { GetInstrumentDocument, LookupBySymbolSimpleDocument } from '@oms/generated/frontend';
import type {
  InstrumentDetailsFragment,
  InstrumentDetailsSimpleFragment,
  LookupBySymbolSimpleQuery,
  LookupBySymbolSimpleQueryVariables,
  GetInstrumentQuery,
  GetInstrumentQueryVariables
} from '@oms/generated/frontend';
import { cleanMaybe } from '@oms/shared/util';
import type { AdvancedSelectQueryFn, ComboBoxItem } from '@oms/frontend-foundation';
import {
  convertInstrument,
  isInstrumentDetailsFragment,
  isInstrumentDetailsSimpleFragment
} from '@app/common/types/instrument/instrument.util';
import type { AnyInstrumentInput } from '@app/common/types/instrument/instrument.types';
import { typeaheadQuery } from '../../utils/typeahead.util';

export type AdvancedSelectInstrumentFieldValue = ComboBoxItem;
export type AdvancedSelectInstrumentFieldValueSimple = ComboBoxItem;

export const instrumentMapper = (instrument: AnyInstrumentInput): AdvancedSelectInstrumentFieldValue => {
  const instrumentDetails: InstrumentDetailsFragment = (() => {
    if (isInstrumentDetailsFragment(instrument)) return instrument;
    if (isInstrumentDetailsSimpleFragment(instrument))
      return convertInstrument.simpleDetails(instrument).toDetails();
    return convertInstrument.simple(instrument).toDetails();
  })();
  return {
    type: 'item',
    id: instrumentDetails.id,
    label: instrumentDetails.mappings?.displayCode ?? instrumentDetails.longName,
    sublabel: instrumentDetails.longName,
    value: instrumentDetails.id
  };
};

export const simpleInstrumentMapper = (
  instrument: AnyInstrumentInput
): AdvancedSelectInstrumentFieldValueSimple => {
  const instrumentDetails: InstrumentDetailsSimpleFragment = (() => {
    if (isInstrumentDetailsFragment(instrument))
      return convertInstrument.details(instrument).toSimpleDetails();
    if (isInstrumentDetailsSimpleFragment(instrument)) return instrument;
    return convertInstrument.simple(instrument).toSimpleDetails();
  })();
  return {
    type: 'item',
    id: instrumentDetails.id,
    label: instrumentDetails.mappings?.displayCode ?? instrumentDetails.longName,
    sublabel: instrumentDetails.longName,
    value: instrumentDetails.id
  };
};

export const lookupInstrumentsQuery: AdvancedSelectQueryFn = typeaheadQuery<
  unknown,
  LookupBySymbolSimpleQuery,
  LookupBySymbolSimpleQueryVariables,
  GetInstrumentQuery,
  GetInstrumentQueryVariables
>({
  lookupQuery: (inputValue) => ({
    query: LookupBySymbolSimpleDocument,
    variables: { symbol: inputValue },
    fetchPolicy: 'cache-and-network'
  }),
  lookupMapper: (data) => {
    const instruments = cleanMaybe(data.instrumentBySymbol, []);
    const results = (instruments as AnyInstrumentInput[]).map(instrumentMapper);
    return results;
  },
  getOneQuery: (id) => ({
    query: GetInstrumentDocument,
    variables: { id }
  }),
  getOneMapper: (data) => {
    const instrument = cleanMaybe(data.instrument, null);
    if (instrument) {
      return instrumentMapper(instrument as unknown as AnyInstrumentInput);
    }
    return null;
  }
});

export const lookupInstrumentsSimpleQuery: AdvancedSelectQueryFn = typeaheadQuery<
  unknown,
  LookupBySymbolSimpleQuery,
  LookupBySymbolSimpleQueryVariables,
  GetInstrumentQuery,
  GetInstrumentQueryVariables
>({
  lookupQuery: (inputValue) => ({
    query: LookupBySymbolSimpleDocument,
    variables: { symbol: inputValue },
    fetchPolicy: 'cache-and-network'
  }),
  lookupMapper: (data) => {
    const instruments = cleanMaybe(data.instrumentBySymbol, []);
    const results = (instruments as AnyInstrumentInput[]).map(simpleInstrumentMapper);
    return results;
  },
  getOneQuery: (id) => ({
    query: GetInstrumentDocument,
    variables: { id }
  }),
  getOneMapper: (data) => {
    const instrument = cleanMaybe(data.instrument, null);
    if (instrument) {
      return instrumentMapper(instrument as unknown as AnyInstrumentInput);
    }
    return null;
  }
});
