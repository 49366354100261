import { invoke } from '@tauri-apps/api/tauri';
import { Monitor, PhysicalPosition, PhysicalSize, WebviewWindow, appWindow } from '@tauri-apps/api/window';
import { ensureInt, isDefined } from '../../common/shared';

export function getActiveMonitor(): Promise<Monitor> {
  return new Promise<Monitor>((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error('Timeout waiting for getActiveMonitor'));
    }, 1000);

    invoke<Monitor>('plugin:workspace-actor|get_active_monitor')
      .then((m) => {
        clearTimeout(timeout);
        resolve({
          ...m,
          position: new PhysicalPosition(m.position.x, m.position.y),
          size: new PhysicalSize(m.size.width, m.size.height)
        });
      })
      .catch((e) => {
        clearTimeout(timeout);
        console.error(e);
        reject(e);
      });
  });
}

export function getMousePosition(): Promise<PhysicalPosition> {
  return new Promise<PhysicalPosition>((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error('Timeout waiting for getMousePosition'));
    }, 1000);

    invoke<[number, number]>('plugin:workspace-actor|get_mouse_position')
      .then((p) => {
        clearTimeout(timeout);
        resolve(new PhysicalPosition(p[0], p[1]));
      })
      .catch((e) => {
        clearTimeout(timeout);
        console.error(e);
        reject(e);
      });
  });
}

export function centerInActiveMonitor(
  webviewOrLabel?: string | WebviewWindow,
  width?: number,
  height?: number
): Promise<void> {
  return new Promise((resolve, reject) => {
    const webview =
      webviewOrLabel === undefined
        ? appWindow
        : typeof webviewOrLabel === 'string'
          ? WebviewWindow.getByLabel(webviewOrLabel)
          : webviewOrLabel;

    if (!webview) {
      reject(new Error(`No webview with label ${webviewOrLabel} found`));
    }

    const timeout = setTimeout(() => {
      reject(new Error('Timeout waiting for centerInActiveMonitor'));
    }, 1000);

    invoke<[number, number]>('plugin:workspace-actor|get_window_active_monitor_center_coords', {
      label: webview?.label as string,
      pre_width: ensureInt(isDefined(width) ? width : 0),
      pre_height: ensureInt(isDefined(height) ? height : 0)
    })
      .then(([x, y]) => {
        clearTimeout(timeout);
        webview
          ?.setPosition(new PhysicalPosition(x, y))
          .then(() => {
            resolve();
          })
          .catch(reject);
      })
      .catch((e) => {
        clearTimeout(timeout);
        console.error(e);
        reject(e);
      });
  });
}
