import { CellBadgeClassEnum, PROGRESS_RENDERER } from '@oms/frontend-vgrid';
import type { ColumnBuilder } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { mapOrderType } from '@app/common/mappers/map-order-type';
import type { AnyRecord, UnknownRecord } from '@oms/frontend-foundation';
import {
  OrderSettleType,
  OrderSide,
  OrderSideType,
  OrderType,
  TimeInForce,
  VisibilityReason,
  OrderCapacity,
  TradingOrderCapacity,
  OrderEntryType
} from '@oms/generated/frontend';
import type {
  TagFragment,
  VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment
} from '@oms/generated/frontend';
import { FormatLargeVal } from '@oms/shared/util';
import type { ICellRendererParams, ColumnGroupShowType } from '@ag-grid-community/core';
import { mapOrderEntryType } from '@app/common/mappers/map-order-entry-type';
import { mapOrderTagsToString } from '@app/common/mappers/map-order-tags';
import { mapOrderVisibilityReason } from '@app/common/mappers/map-order-visibility-reason';
import { mapSettleType } from '@app/common/mappers/map-settle-type';
import { mapTif } from '@app/common/mappers/map-tif';
import type { Paths } from '@oms/shared/util-types';
import get from 'lodash/get';
import { SideTypeCellRenderer } from '@app/common/grids/cell-renderers/side-type-cell/side-type-cell.renderer';
import { getDurationInMMSS } from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';
import {
  sharedCurrencyCol,
  sharedDateCol,
  sharedDateTimeCol,
  sharedIdCol,
  sharedPriceCol,
  sharedQuantityCol,
  sharedTextCol,
  sharedTimestampCol,
  sharedUserCol
} from './generic-cols';
import { SideOnlyCellRenderer } from '../cell-renderers/side-type-cell/side-only-cell.renderer';

export const OWNER_ID_FIELD_NAME = 'owner.id';
export const ORDER_VISBILITY_FIELD_NAME = 'reason';
export const STATUS_FIELD_NAME = 'status';

export const sharedOrderTypeCol = <T extends ColumnBuilder<any>>(c: T) =>
  c
    .field('orderType')
    .header('Order Type')
    .width(110)
    .maxWidth(220)
    .minWidth(120)
    .filter('agSetColumnFilter')
    .filterParams<OrderType>({
      valueFormatter: ({ value }) => mapOrderType(value) as OrderType,
      values: Object.values(OrderType)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapOrderType(data.orderType, 'Unknown')));

export const sharedLimitPriceCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath = 'limitPrice',
  orderTypePath?: string
) =>
  sharedPriceCol(c, fieldPath)
    .header(t('app.common.grids.limitPrice'))
    .shortHeader(t('app.common.grids.limitPrice', { ns: 'short' }))
    .cell((c) =>
      c
        .valueFormatter((params) => {
          const formattedNo = FormatLargeVal(params, 2, true);
          if (formattedNo !== '') {
            return formattedNo;
          }

          // If we don't have orderTypePath, construct it from the field path and replace the last element with 'orderType'
          const effectiveOrderTypePath =
            orderTypePath || fieldPath.substring(0, fieldPath.lastIndexOf('.')) + 'orderType';
          const orderType = get(params.data, effectiveOrderTypePath, null) as string | null;
          return !orderType ? OrderType.Market : mapOrderType(orderType, 'Unknown').toUpperCase();
        })
        .valueGetter((params) => {
          const limitPrice = get(params.data, fieldPath, null);
          if (!limitPrice) {
            return '';
          }

          const number = Number(limitPrice);
          if (isNaN(number)) {
            return '';
          } else {
            return number;
          }
        })
    );

export const sharedAveragePriceCol = <T extends AnyRecord>(c: ColumnBuilder<T>, fieldPath: string) =>
  sharedPriceCol(c, fieldPath)
    .header(t('app.common.grids.averagePrice'))
    .shortHeader(t('app.common.grids.averagePrice', { ns: 'short' }));

export const sharedCapacityCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: keyof T | Paths<T, 3> = 'capacity'
) =>
  sharedTextCol(c, fieldPath)
    .header(t('app.orders.tradingOrderMonitor.capacity'))
    .shortHeader(t('app.orders.tradingOrderMonitor.capacity', { ns: 'short' }))
    .width(95)
    .maxWidth(180)
    .filter('agSetColumnFilter')
    .filterParams<OrderCapacity>({
      values: [OrderCapacity.Agency, OrderCapacity.Principal, OrderCapacity.RisklessPrincipal]
    });

export const sharedTradingOrderCapacityCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: keyof T | Paths<T, 3> = 'capacity'
) =>
  sharedTextCol(c, fieldPath)
    .header(t('app.orders.tradingOrderMonitor.capacity'))
    .shortHeader(t('app.orders.tradingOrderMonitor.capacity', { ns: 'short' }))
    .width(95)
    .maxWidth(180)
    .filter('agSetColumnFilter')
    .filterParams<TradingOrderCapacity>({
      values: [TradingOrderCapacity.Agency, TradingOrderCapacity.Principal]
    });

export const sharedSideCol = <T extends AnyRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'sideType',
  showPlaceholder: boolean = false
) =>
  c
    .field(field)
    .header(t('app.orders.orderMonitor.side'))
    .shortHeader(t('app.orders.orderMonitor.side', { ns: 'short' }))
    .width(82)
    .maxWidth(500)
    .minWidth(75)
    .filter('agSetColumnFilter')
    .filterParams<OrderSideType>({
      values: [
        OrderSideType.Buy,
        OrderSideType.Sell,
        OrderSideType.Short,
        OrderSideType.Exempt,
        OrderSideType.Btc
      ]
    })
    .cell((cb) =>
      cb.renderer((cellRendererParams: ICellRendererParams<T>) =>
        SideTypeCellRenderer(cellRendererParams, showPlaceholder)
      )
    );

export const sharedSideOnlyCol = <T extends AnyRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'side'
) =>
  c
    .field(field)
    .header(t('app.trades.side'))
    .filter('agSetColumnFilter')
    .width(82)
    .maxWidth(500)
    .minWidth(75)
    .filterParams<OrderSide>({
      values: [OrderSide.Buy, OrderSide.Sell]
    })
    .cell((cb) => cb.renderer(SideOnlyCellRenderer));

export const sharedSettleCurrencyCol = <T extends UnknownRecord>(c: ColumnBuilder<T>) =>
  sharedCurrencyCol(c, 'settleCurrency')
    .header(t('app.orders.orderMonitor.settleCurrency'))
    .shortHeader(t('app.orders.orderMonitor.settleCurrency', { ns: 'short' }))
    .hide();

export const sharedSettleTypeCol = <T extends AnyRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'settleType'
) =>
  c
    .field(field)
    .header(t('app.orders.orderMonitor.settleType'))
    .shortHeader(t('app.orders.orderMonitor.settleType', { ns: 'short' }))
    .hide()
    .width(110)
    .filter('agSetColumnFilter')
    .filterParams<OrderSettleType>({
      values: Object.values(OrderSettleType),
      valueFormatter: ({ value }) => mapSettleType(value)
    })
    .cell((c) =>
      c
        .valueFormatter(({ data }) => {
          const settleType = get(data, field, null);
          return mapSettleType(settleType);
        })
        .filterValueGetter(({ data }) => {
          const settleType = get(data, field, null);
          return mapSettleType(settleType);
        })
    );

export const sharedInvestorAccountCol = <T extends AnyRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3>
) =>
  sharedTextCol(c, field)
    .header(t('app.orders.orderMonitor.investorAccount'))
    .shortHeader(t('app.orders.orderMonitor.investorAccount', { ns: 'short' }))
    .filter(false)
    .sortable(false)
    .minWidth(90)
    .width(210);

export const sharedSettleDateCol = <T extends AnyRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'settleDate'
) =>
  sharedDateCol(c, field)
    .header(t('app.orders.orderMonitor.settleDate'))
    .shortHeader(t('app.orders.orderMonitor.settleDate', { ns: 'short' }))
    .hide();

export const sharedExpiryDateTimeCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  field = 'expiryDateTime'
) =>
  sharedDateTimeCol(c, field)
    .header(t('app.orders.orderMonitor.expiryDateTime'))
    .shortHeader(t('app.orders.orderMonitor.expiryDateTime', { ns: 'short' }))
    .hide();

export const sharedOrderTifCol = <T extends { timeInForce?: string | null }>(c: ColumnBuilder<T>) =>
  c
    .field('timeInForce')
    .header(t('app.orders.orderMonitor.timeInForce'))
    .shortHeader(t('app.orders.orderMonitor.timeInForce', { ns: 'short' }))
    .width(80)
    .maxWidth(120)
    .minWidth(75)
    .filter('agSetColumnFilter')
    .filterParams<TimeInForce>({
      values: Object.values(TimeInForce),
      valueFormatter: ({ value }) => mapTif(value, 'Unknown')
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapTif(data?.timeInForce, 'Unknown')));

export const sharedOrderTifDurationCol = <T extends AnyRecord>(c: ColumnBuilder<T>) =>
  c
    .field('tifDuration')
    .shortHeader(t('app.orders.orderMonitor.tifDuration', { ns: 'short' }))
    .header(t('app.orders.orderMonitor.tifDuration'))
    .width(80)
    .maxWidth(120)
    .minWidth(75)
    .cell((c) => c.valueFormatter(({ data }) => getDurationInMMSS(data?.tifDuration) as string));

export const sharedOrderStatusWithoutMapperCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = STATUS_FIELD_NAME
) =>
  c
    .field(field)
    .header(t('app.orders.orderMonitor.status'))
    .shortHeader(t('app.orders.orderMonitor.status', { ns: 'short' }))
    .width(110)
    .maxWidth(220)
    .minWidth(100);

export const sharedTransmittedTimestampCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'transmittedTimestamp'
) =>
  sharedTimestampCol(c, field)
    .header(t('app.orders.orderMonitor.transmittedTimestamp'))
    .shortHeader(t('app.orders.orderMonitor.transmittedTimestamp', { ns: 'short' }));

export const sharedUpdatedTimestampCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'updatedTimestamp'
) =>
  sharedTimestampCol(c, field)
    .header(t('app.orders.orderMonitor.updatedTimestamp'))
    .shortHeader(t('app.orders.orderMonitor.updatedTimestamp', { ns: 'short' }));

export const sharedCreatedTimestampCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'createdTimestamp'
) =>
  sharedTimestampCol(c, field)
    .header(t('app.orders.orderMonitor.createdTimestamp'))
    .shortHeader(t('app.orders.orderMonitor.createdTimestamp', { ns: 'short' }));

export const sharedOrderVisibilityReasonCol = <
  T extends { [ORDER_VISBILITY_FIELD_NAME]?: IOFragment[typeof ORDER_VISBILITY_FIELD_NAME] }
>(
  c: ColumnBuilder<T>
) =>
  c
    .field(ORDER_VISBILITY_FIELD_NAME)
    .header(t('app.orders.orderMonitor.visibilityReason'))
    .shortHeader(t('app.orders.orderMonitor.visibilityReason', { ns: 'short' }))
    .width(130)
    .filter('agSetColumnFilter')
    .filterParams<VisibilityReason>({
      values: Object.values(VisibilityReason),
      valueFormatter: ({ value }) => mapOrderVisibilityReason(value)
    })
    .hide()
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapOrderVisibilityReason(data?.reason)));

export const sharedOrderTagsCol = <T extends { orderTags?: IOFragment['orderTags'] }>(c: ColumnBuilder<T>) =>
  c
    .field('orderTags')
    .header(t('app.orders.orderMonitor.orderTags'))
    .shortHeader(t('app.orders.orderMonitor.orderTags', { ns: 'short' }))
    .hide()
    .width(110)
    .filter(false)
    .sortable(false)
    .cell((c) => c.valueFormatter(({ data }) => mapOrderTagsToString(data?.orderTags as TagFragment[])));

export const sharedOwnerCol = <T extends AnyRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'owner'
) =>
  sharedUserCol(c, field)
    .header(t('app.orders.orderMonitor.owner'))
    .shortHeader(t('app.orders.orderMonitor.owner', { ns: 'short' }))
    .hide()
    .width(180)
    .filter(false)
    .sortable(false);

export const sharedOwnerIdCol = <T extends AnyRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'owner.id'
) =>
  sharedIdCol(c, field)
    .header(t('app.orders.orderMonitor.ownerId'))
    .shortHeader(t('app.orders.orderMonitor.ownerId', { ns: 'short' }))
    .width(110)
    .floatingFilter(false)
    .filter('agTextColumnFilter')
    .filterParams({ filterOptions: ['equals'] })
    .hide();

export const sharedCustomerNotesCol = <T extends { customerNotes?: IOFragment['customerNotes'] }>(
  c: ColumnBuilder<T>
) =>
  sharedTextCol(c, 'customerNotes')
    .header(t('app.orders.orderMonitor.customerNotes'))
    .shortHeader(t('app.orders.orderMonitor.customerNotes', { ns: 'short' }))
    .hide();

export const sharedTradeCurrencyCol = <T extends { tradeCurrency?: IOFragment['tradeCurrency'] }>(
  c: ColumnBuilder<T>
) =>
  sharedCurrencyCol(c, 'tradeCurrency')
    .header(t('app.orders.orderMonitor.tradeCurrency'))
    .shortHeader(t('app.orders.orderMonitor.tradeCurrency', { ns: 'short' }))
    .hide();

export const sharedRepresentativeCodeCol = <T extends AnyRecord>(c: ColumnBuilder<T>) =>
  sharedTextCol(c, 'representativeCode.code')
    .header(t('app.orders.orderMonitor.representativeCode'))
    .shortHeader(t('app.orders.orderMonitor.representativeCode', { ns: 'short' }))
    .hide();

export const createQuantityCol = <T extends AnyRecord>(
  c: ColumnBuilder<T>,
  headerName: string,
  shortHeaderName: string,
  fieldName: keyof T,
  columnGroupShow?: ColumnGroupShowType | undefined
): ColumnBuilder<T> =>
  sharedQuantityCol(c, fieldName)
    .header(headerName)
    .shortHeader(shortHeaderName)
    .columnGroupShow(columnGroupShow)
    .cell((c) => c.renderer(fieldName === 'executedQuantity' ? PROGRESS_RENDERER : undefined));

export const sharedTodayExecutedQuantityCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: keyof T | Paths<T, 3> = 'todayExecutedQuantity'
) =>
  sharedQuantityCol(c, fieldPath)
    .header(t('app.orders.orderMonitor.todayExecutedQuantity'))
    .shortHeader(t('app.orders.orderMonitor.todayExecutedQuantity', { ns: 'short' }))
    .minWidth(120)
    .width(140)
    .maxWidth(220)
    .hide();

export const sharedTodayAveragePriceCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: keyof T | Paths<T, 3> = 'todayAveragePrice'
) =>
  sharedPriceCol(c, fieldPath)
    .header(t('app.orders.orderMonitor.todayAveragePrice'))
    .shortHeader(t('app.orders.orderMonitor.todayAveragePrice', { ns: 'short' }))
    .width(130)
    .maxWidth(190)
    .hide();

export const sharedOrderEntryTypeCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  field: keyof T | Paths<T, 3> = 'orderEntryType',
  defaultVal?: OrderEntryType | string
) =>
  c
    .field(field)
    .header(t('app.orders.orderMonitor.orderEntryType'))
    .shortHeader(t('app.orders.orderMonitor.orderEntryType', { ns: 'short' }))
    .cell((c) =>
      c.valueFormatter(({ data }) => mapOrderEntryType(data?.orderEntryType as string, defaultVal))
    )
    .filter('agSetColumnFilter')
    .filterParams<OrderEntryType>({
      values: Object.values(OrderEntryType),
      valueFormatter: ({ value }) => mapOrderEntryType(value, defaultVal)
    });
