import type { VisibleTradingOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import {
  Box,
  DISPLAY_FIELD_COMPONENT_TYPE,
  DisplayGrid,
  DISPLAY_GRID_CUSTOM_COMPONENT_TYPE
} from '@oms/shared-frontend/ui-design-system';
import type { DisplayGridProps } from '@oms/shared-frontend/ui-design-system';
import { useMemo } from 'react';

const getDisplayGridItems = (
  tradingOrder: VisibleTradingOrderInfoWithAllocationsFragment
): DisplayGridProps['items'] => {
  return [
    {
      component: {
        type: DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.Stats,
        items: [
          {
            label: 'Filled',
            value: tradingOrder.executedQuantity ?? 0,
            format: 'quantity'
          },
          {
            label: 'Avg Price',
            value: tradingOrder.averagePrice || '',
            format: 'price'
          }
        ],
        width: '100%'
      },
      rowSpan: 4
    },
    {
      label: 'Leaves Quantity',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: tradingOrder.leavesQuantity ?? 0,
        format: 'quantity'
      }
    },
    {
      label: 'Arrival Price',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Order VWAP',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Close',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Open Price',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Previous Close',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Instrument VWAP',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    }
  ];
};

const gridProps: DisplayGridProps['gridProps'] = {
  columns: 3,
  rows: 4,
  columnGap: 10,
  rowGap: 3
};

export const TradingOrderSummary = ({
  tradingOrder
}: {
  tradingOrder: VisibleTradingOrderInfoWithAllocationsFragment;
}) => {
  const displayGridItems = useMemo(() => {
    return getDisplayGridItems(tradingOrder);
  }, [tradingOrder]);

  return (
    <Box sx={{ padding: 5 }}>
      <DisplayGrid items={displayGridItems} gridProps={gridProps} labelSize="small" />
    </Box>
  );
};
