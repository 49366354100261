/**
 * An enum specifying what style of indicator should be used for required fields.
 * - `default`: Always use whatever is set as the system default.
 * - `asterisk`: Always place an asterisk to the right of the field label to specify it's required.
 * - `none`: Opt-out of any required indicator.
 */
export type RequiredFieldIndicatorStyle = 'default' | 'asterisk' | 'none';

export type ResolvedRequiredFieldIndicatorStyle = Exclude<RequiredFieldIndicatorStyle, 'default'>;

export const defaultRequiredFieldIndicatorStyle: ResolvedRequiredFieldIndicatorStyle = 'asterisk';

export const resolveRequiredFieldIndicatorStyle = (
  style?: RequiredFieldIndicatorStyle
): ResolvedRequiredFieldIndicatorStyle =>
  typeof style === 'undefined' || style === 'default' ? defaultRequiredFieldIndicatorStyle : style;

export default RequiredFieldIndicatorStyle;
