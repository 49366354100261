import type { ICellRendererParams } from '@ag-grid-community/core';
import type { FC } from 'react';
import type { InlineActionComponentProps } from '../../../models/actions.model';
import { ActionButton } from './action.button';

export const INLINE_ACTION_BUTTON = 'inline-action-button';

export const InlineActionButtonCell: FC<ICellRendererParams<any, any> & InlineActionComponentProps<any>> = ({
  node,
  action
}) => {
  return <ActionButton {...action} instanceId={node.id} />;
};
