import {
  format,
  formatNumeric,
  FormatterFunctionParams,
  FormatType,
  FormatValue,
  NumericFormatType
} from '@oms/shared/util';
import { FC } from 'react';

/** @deprecated Use `FormatterProps` instead */
export type FormatProps = {
  type: FormatType;
  args?: Omit<FormatterFunctionParams, 'value'>;
  value: FormatValue;
};

/** @deprecated Use `FormatterProps` instead */
export type NumericFormatProps = {
  type: NumericFormatType;
  value: FormatValue;
  decimalPlaces?: number;
  thousandDelimiter?: string;
  decimalDelimiter?: string;
  hasDelimiters?: boolean;
  trimTrailingZeros?: boolean;
};

/** @deprecated Use `Formatter` instead */
export const Format: FC<FormatProps> = ({ type, value, args = {} }) => {
  return <>{format({ ...args, type, value })}</>;
};

/** @deprecated Use `Formatter` instead */
export const FormatNumeric: FC<NumericFormatProps> = ({
  type,
  value,
  decimalPlaces,
  thousandDelimiter,
  decimalDelimiter,
  hasDelimiters,
  trimTrailingZeros
}) => {
  return (
    <>
      {formatNumeric({
        type,
        value,
        decimalPlaces,
        thousandDelimiter,
        decimalDelimiter,
        hasDelimiters,
        trimTrailingZeros
      })}
    </>
  );
};
