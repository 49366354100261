import { CustomGraphQLError } from './custom-error';
import { ErrorTypeEnum } from './errors.types';

export type IAsyncError = CustomGraphQLError<ErrorTypeEnum.ASYNC_ERROR, string>;

export class AsyncError extends CustomGraphQLError<ErrorTypeEnum.ASYNC_ERROR, string> {
  constructor(message: string, code?: string) {
    super(message);
    this.extensions = {
      type: ErrorTypeEnum.ASYNC_ERROR,
      code: code || ErrorTypeEnum.ASYNC_ERROR,
      data: message
    };
  }
}
