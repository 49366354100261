import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { type FC, useEffect, useState } from 'react';
import { useFieldApi } from '../../helpers';
import { type DdfComponentProps } from './component.types';
import type { FieldProps } from '../../types';

export const DdfComponent: FC<FieldProps<DdfComponentProps>> = (props) => {
  const { renderForm } = useFormApi();
  const { componentType: _componentType, input, ...rest } = useFieldApi<DdfComponentProps>(props);
  const [componentType, setComponentType] = useState<string>(_componentType);

  useEffect(() => {
    if (_componentType !== componentType) {
      setComponentType(_componentType);
    }
  }, [_componentType, componentType]);

  return (
    <>
      {renderForm([
        {
          ...rest,
          component: componentType,
          name: input.name,
          initialValue: input.value
        }
      ])}
    </>
  );
};
