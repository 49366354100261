import { GridApi } from '@ag-grid-community/core';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { EventHandler } from './event.handler';
import { EventSource, GridEventType } from './event.source';
import { GridIdService } from '../services/grid-id.service';
import { isEqual } from 'lodash';
import { RowSelection, RowSelectionBroadcastFnContext } from '../models/row.selection.model';
import { VGridContextInstance } from '../models/v.context.model';
import { VGridInstance } from '../models/v.instance.model';

@scoped(Lifecycle.ContainerScoped)
export class RowSelectionEventHandler implements EventHandler<any> {
  private rowSelection: RowSelection<any>;
  private context: VGridContextInstance;
  private idService: GridIdService;
  private prevSelectedRows: any[];
  private isFirstRender: boolean;

  public name = 'row-selection-event-handler';

  constructor(
    @inject(VGridInstance.RowSelection) rowSelection: RowSelection<any>,
    @inject(VGridInstance.Context) context: VGridContextInstance,
    @inject(GridIdService) idService: GridIdService
  ) {
    this.rowSelection = rowSelection;
    this.context = context;
    this.idService = idService;
    this.prevSelectedRows = [];
    this.isFirstRender = true;
  }

  public addEvents(eventSource: EventSource<GridEventType, any>) {
    let storeFired = false;
    eventSource
      .add('onSelectionChanged', (e) => {
        this._broadcastSelectedRows(e.api as GridApi<any>); // Need to cast to avoid ESLint server crash
      })
      .add('onRowDataUpdated', (e) => {
        // We need to conditionally broadcast again the selected rows when rows are updated
        // for cases where the side panel needs to be updated with the new data (in case it's
        // not subscribed to the same source).
        // For example in Pending modifications, where the side panel is not subscribed to
        // modifications, but to investor orders.
        this._broadcastSelectedRows(e.api as GridApi<any>);
      })
      .add('onFirstDataRendered', (e) => this._selectRowsOnFirstDataRender(e.api))
      .add('onStoreUpdated', (e) => {
        if (storeFired) {
          return;
        }
        this._selectRowsOnFirstDataRender(e.api);
        this._broadcastSelectedRows(e.api as GridApi<any>, { checkViewport: true });
        storeFired = true;
      })
      .add('onStoreRefreshed', (e) => {
        this._broadcastSelectedRows(e.api as GridApi<any>, { checkViewport: true });
      });
  }

  private _broadcastSelectedRows(
    api: GridApi<any>,
    options: { checkViewport?: boolean } = { checkViewport: false }
  ) {
    if (!this.rowSelection.broadcast) {
      return;
    }

    const checkViewport = !!options.checkViewport;
    let selectedData = api.getSelectedRows();
    if (checkViewport) {
      const rowsInView = api.getRenderedNodes();
      selectedData = selectedData.filter((data) => rowsInView.some((row) => row.data === data));
    }

    // Since we rebroadcast when row update but we don't know if the selected
    // rows were updated, we only rebroadcast if the selected rows contents were updated
    if (isEqual(this.prevSelectedRows, selectedData)) {
      return;
    }
    this.prevSelectedRows = structuredClone(selectedData);

    const cxt: RowSelectionBroadcastFnContext = {
      flexLayoutActor: this.context.flexLayoutActor,
      gridId: this.idService.gridId,
      gridType: this.context.gridType,
      tabNodeId: this.context.flexLayoutTabNode?.getId() || null,
      tabSetNodeId: this.context.flexLayoutTabSetNode?.getId() || null,
      scopedActorId: this.context.scopedActorId,
      widgetActor: this.context.widgetActor,
      windowActor: this.context.windowActor
    };

    this.rowSelection.broadcast(selectedData, cxt);
  }

  private _selectRowsOnFirstDataRender(api: GridApi) {
    if (!this.rowSelection.selectedRowIndexesOnFirstDataRender || !this.isFirstRender) {
      return;
    }
    const nodes = api.getRenderedNodes();
    if (!nodes?.length) {
      return;
    }

    const nodesToSelect = nodes.filter((node) => {
      if (node.rowIndex !== null) {
        return this.rowSelection.selectedRowIndexesOnFirstDataRender?.includes(node.rowIndex);
      }
      return false;
    });

    if (nodesToSelect) {
      nodesToSelect.forEach((node) => {
        node.setSelected(true);
      });

      this.isFirstRender = false;
    }
  }

  public removeEvents() {}
}
