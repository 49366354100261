import { type RuleGroup } from './rule.types';

export const isRuleGroup = (ruleOrGroup: Record<string, any>): boolean => {
  return !!ruleOrGroup && !!ruleOrGroup.rules && ruleOrGroup.rules.length > 0;
};

export const findRuleByPath = (path: number[], root: RuleGroup): any | RuleGroup | undefined => {
  let target: RuleGroup = root;
  let level = 0;

  while (level < path.length && target && isRuleGroup(target)) {
    target = target.rules[path[level]] as RuleGroup;

    level++;
  }

  return target;
};

export const convertRules = <TRuleGroup = any, TRule = any, TRuleGroupResult = any, TRuleResult = any>(
  rules: TRuleGroup,
  isGroup: (rule: TRuleGroup | TRule) => boolean,
  createGroup: (grp: TRuleGroup) => TRuleGroupResult,
  iterator: (grp: TRuleGroup) => (TRuleGroup | TRule)[],
  mapRule: (rule: TRule) => TRuleResult,
  addRuleOrGroup: (resultGroup: TRuleGroupResult, ruleOrGroup: TRuleResult | TRuleGroupResult) => void
) => {
  const group = createGroup(rules);

  const arr = iterator(rules);

  (arr || []).forEach((r) => {
    if (isGroup(r)) {
      const newGroup = convertRules(r as TRuleGroup, isGroup, createGroup, iterator, mapRule, addRuleOrGroup);
      addRuleOrGroup(group, newGroup);
    } else {
      addRuleOrGroup(group, mapRule(r as TRule));
    }
  });

  return group;
};
