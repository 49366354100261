import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { AnyRecord, CommonWindowActorSchema } from '@valstro/workspace';
import {
  DEFAULT_POPOVER_OPTIONS,
  OpenPopoverOptions,
  POPOVER_EVENT,
  UpdatePopoverComponentPropsEvent
} from '@valstro/workspace-plugin-popover';
import { useMergedRef } from './use-merge-ref';
import { useRootActor, useWorkspace } from '@valstro/workspace-react';
import { usePopoverTransport } from './popover.hooks';

type OpenPopoverOptionProps<T extends AnyRecord = AnyRecord> = Omit<
  OpenPopoverOptions<T>,
  'rectWithScaling' | 'innerPosition' | 'windowId' | 'popoverId'
>;

export type PopoverTriggerProps<T extends AnyRecord = AnyRecord> =
  React.ComponentPropsWithoutRef<'button'> & {
    /**
     * The label of the popover. Used for accessibility.
     */
    ariaLabel: string;
    ariaType?: React.AriaAttributes['aria-haspopup'];
    popoverTestId?: string;
    asChild?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    /**
     * Allow for a custom popover ID to be passed in to identify the popover externally.
     */
    popoverId?: string;
    /**
     * Set props update method. This is used when updating the popovers component props by passing in a new object.
     * If the method is set to 'set', the new props will replace the old props.
     * If the method is set to 'merge', the new props will be merged with the old props.
     * @default 'set'
     */
    componentPropsUpdateMethod?: UpdatePopoverComponentPropsEvent['payload']['method'];
    /**
     * Custom trigger selector. This is used to identify the trigger element when opening the popover
     */
    customTriggerSelector?: string;
  } & OpenPopoverOptionProps<T>;

type OpenPopFnOptions = { shouldToggle: boolean };

export const PopoverTrigger = React.forwardRef<HTMLButtonElement, PopoverTriggerProps>(
  (props, forwardedRef) => {
    const workspace = useWorkspace();
    const actor = useRootActor<CommonWindowActorSchema>();
    const internalRef = useRef<HTMLElement>();
    const popoverEventsTransport = usePopoverTransport();
    const popoverEventsTransportRef = useRef(popoverEventsTransport);
    const {
      popoverId: _popoverId,
      componentPropsUpdateMethod = 'set',
      ariaLabel,
      ariaType = 'dialog',
      popoverTestId,
      asChild,
      onClick,
      onOpenChange: _onOpenChange,
      open: _open,
      alignOffset,
      align,
      side,
      sideOffset,
      width,
      minWidth,
      height,
      minHeight,
      trigger = DEFAULT_POPOVER_OPTIONS.trigger,
      enterDelay = DEFAULT_POPOVER_OPTIONS.enterDelay,
      exitDelay = DEFAULT_POPOVER_OPTIONS.exitDelay,
      autoSizeWidthToTrigger = DEFAULT_POPOVER_OPTIONS.autoSizeWidthToTrigger,
      autoSizePaddingWidth,
      autoSizePaddingHeight,
      closingStrategy,
      autoSizeHeight,
      focusOnOpen,
      transparent,
      componentId,
      componentProps,
      autoSizeWidth,
      customTriggerSelector,
      autoSizeTriggerResizePixelSensitivity,
      ...buttonProps
    } = props;
    const popoverId = useRef<string>(props.popoverId || workspace.generateUUID());

    const isControlled = _open !== undefined;
    const isControlledPrevOpenRef = useRef<boolean | undefined>(isControlled ? _open : undefined);

    const label = ariaLabel || buttonProps['aria-label'] || 'Popover';

    const popoverOptions: OpenPopoverOptionProps = {
      alignOffset,
      align,
      side,
      sideOffset,
      width,
      minWidth,
      height,
      minHeight,
      trigger,
      closingStrategy,
      focusOnOpen,
      transparent,
      enterDelay,
      exitDelay,
      componentId,
      componentProps,
      ariaLabel: label,
      popoverTestId,
      autoSizeWidth,
      autoSizeHeight,
      autoSizeWidthToTrigger,
      autoSizePaddingWidth,
      autoSizePaddingHeight,
      autoSizeTriggerResizePixelSensitivity
    };

    const popoverOptionsRef = useRef<OpenPopoverOptionProps>(popoverOptions);
    popoverOptionsRef.current = popoverOptions;

    const Component = (asChild ? Slot : 'button') as 'button';

    const internalOpenRef = useRef(false);
    const [internalOpen, setInternalOpen] = React.useState(false);

    const openPopover = useCallback(
      async (options: OpenPopFnOptions = { shouldToggle: false }) => {
        const el = customTriggerSelector
          ? document.querySelector(customTriggerSelector)
          : internalRef.current;

        if (!el) return;

        const { x, y, scaleFactor } = await actor.operations.getInnerPosition();
        const rect = el.getBoundingClientRect();

        const evType = options.shouldToggle ? POPOVER_EVENT.TOGGLE_POPOVER : POPOVER_EVENT.OPEN_POPOVER;

        const _width = autoSizeWidthToTrigger
          ? rect.width * scaleFactor
          : width !== undefined
            ? width * scaleFactor
            : undefined;

        const _height = height !== undefined ? height * scaleFactor : undefined;

        const _minWidth = minWidth !== undefined && minWidth > 0 ? minWidth * scaleFactor : 0;
        const _minHeight = minHeight !== undefined && minHeight > 0 ? minHeight * scaleFactor : 0;

        const widthWithMinWidth = _width !== undefined && _width <= _minWidth ? _minWidth : _width;
        const heightWithMinHeight = _height !== undefined && _height <= _minHeight ? _minHeight : _height;

        popoverEventsTransportRef.current
          .emit(evType, {
            ...popoverOptionsRef.current,
            alignOffset: alignOffset !== undefined ? alignOffset * scaleFactor : undefined,
            sideOffset: sideOffset !== undefined ? sideOffset * scaleFactor : undefined,
            autoSizeWidth,
            autoSizeHeight,
            width: widthWithMinWidth,
            height: heightWithMinHeight,
            autoSizePaddingWidth:
              autoSizePaddingWidth !== undefined ? autoSizePaddingWidth * scaleFactor : undefined,
            autoSizePaddingHeight:
              autoSizePaddingHeight !== undefined ? autoSizePaddingHeight * scaleFactor : undefined,
            autoSizeTriggerResizePixelSensitivity:
              autoSizeTriggerResizePixelSensitivity !== undefined
                ? autoSizeTriggerResizePixelSensitivity * scaleFactor
                : undefined,
            windowId: actor.id,
            popoverId: popoverId.current,
            innerPosition: {
              x,
              y
            },
            rectWithScaling: {
              y: rect.top * scaleFactor,
              x: rect.left * scaleFactor,
              width: rect.width * scaleFactor,
              height: rect.height * scaleFactor
            }
          })
          .catch(console.error);
      },
      [
        customTriggerSelector,
        actor.operations,
        actor.id,
        alignOffset,
        sideOffset,
        autoSizeWidth,
        autoSizeHeight,
        autoSizeWidthToTrigger,
        width,
        minWidth,
        height,
        minHeight,
        autoSizePaddingWidth,
        autoSizePaddingHeight,
        autoSizeTriggerResizePixelSensitivity
      ]
    );

    const closePopover = useCallback(async () => {
      popoverEventsTransportRef.current
        .emit(POPOVER_EVENT.CLOSE_POPOVER, {
          popoverId: popoverId.current,
          windowId: actor.id
        })
        .catch(console.error);
    }, [actor]);

    const handleMouseEnter = () => {
      if (trigger === 'hover') {
        openPopover().catch(console.error);
      }
    };

    const handleMouseLeave = () => {
      // Cancel open if it's a hover trigger & popover is not open as we leave the element
      if (trigger === 'hover' && internalOpenRef.current === false) {
        closePopover().catch(console.error);
      }
    };

    /**
     * Keep internal open state in sync with the popover state
     */
    useLayoutEffect(() => {
      const openedUnlisten = popoverEventsTransportRef.current.listen(
        POPOVER_EVENT.OPENED_POPOVER,
        (data) => {
          if (data.popoverId === popoverId.current) {
            internalOpenRef.current = true;
            isControlledPrevOpenRef.current = true;
            setInternalOpen(true);
            if (_onOpenChange) {
              _onOpenChange(true);
            }
          }
        }
      );

      const closedUnlisten = popoverEventsTransportRef.current.listen(
        POPOVER_EVENT.CLOSED_POPOVER,
        (data) => {
          if (data.popoverId === popoverId.current) {
            internalOpenRef.current = false;
            isControlledPrevOpenRef.current = false;
            setInternalOpen(false);
            if (_onOpenChange) {
              _onOpenChange(false);
            }
          }
        }
      );

      return () => {
        openedUnlisten();
        closedUnlisten();
      };
    }, [_onOpenChange]);

    /**
     * Handle open state changes if controlled
     */
    useLayoutEffect(() => {
      if (isControlled) {
        if (_open && _open !== isControlledPrevOpenRef.current) {
          isControlledPrevOpenRef.current = true;
          openPopover().catch((e) => {
            console.error(e);
            isControlledPrevOpenRef.current = false;
          });
        } else if (_open !== isControlledPrevOpenRef.current) {
          closePopover().catch(console.error);
          isControlledPrevOpenRef.current = false;
        }
      }
    }, [trigger, _onOpenChange, isControlled, _open, openPopover, closePopover]);

    /**
     * Update component props
     */
    useLayoutEffect(() => {
      if (componentProps) {
        popoverEventsTransportRef.current
          .emit(POPOVER_EVENT.POPOVER_COMPONENT_PROPS_UPDATE, {
            popoverId: popoverId.current,
            windowId: actor.id,
            method: componentPropsUpdateMethod,
            componentProps: componentProps
          })
          .catch(console.error);
      }
    }, [actor.id, componentProps, componentPropsUpdateMethod]);

    return (
      <Component
        {...buttonProps}
        aria-label={label}
        aria-haspopup={ariaType || buttonProps['aria-haspopup']}
        aria-expanded={internalOpen ? 'true' : 'false'}
        data-state={internalOpen ? 'open' : 'closed'}
        data-popover-id={popoverId.current}
        data-popover-trigger-id={popoverId.current}
        ref={useMergedRef(forwardedRef, internalRef)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={(event) => {
          if (trigger === 'click') {
            openPopover({
              shouldToggle: true
            }).catch(console.error);
          }
          onClick?.(event);
        }}
      />
    );
  }
);
