import { DependencyContainer } from 'tsyringe';
import { ComponentLocation } from './v.component.model';
import { ActionsService } from '../services/actions.service';
import { AnyRecord } from '@oms/frontend-foundation';

export type ToolbarLocation = Exclude<ComponentLocation, ComponentLocation.Selection>;

export const ToolbarLocationRecord: Record<ToolbarLocationKeys, true> = {
  HorizontalToolbarLeft: true,
  HorizontalToolbarRight: true,
  LeftVerticalToolbar: true,
  RightVerticalToolbar: true
};

export type ToolbarLocationKeys = Exclude<keyof typeof ComponentLocation, 'Selection'>;

export type OnToolbarHotkeyEvent<TData extends AnyRecord = AnyRecord> = {
  location: ToolbarLocationKeys;
  container: DependencyContainer;
  actionService: ActionsService<TData>;
};

export type ToolbarStrategy = 'hidden' | 'visible' | 'has-components';

export type ToolbarStrategies = Record<ToolbarLocationKeys, ToolbarStrategy>;

export type ToolbarStrategyConfig = ToolbarStrategy | Partial<ToolbarStrategies>;
