import React, { useMemo, type ReactNode } from 'react';
import { ToggleButton as ToggleButtonComp } from '@oms/shared-frontend/ui-design-system';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { useToggleFieldApi } from './util.internal';
import { omit } from 'lodash';
import type { FieldProps, ICommonButtonField } from '../../types';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type UseFieldApiConfig, type Validator } from '@data-driven-forms/react-form-renderer';

export interface IToggleButtonField<TValidator = Validator>
  extends ICommonButtonField<typeof FORM_COMPONENT_TYPE.TOGGLE_BUTTON, boolean, TValidator> {}

export const ToggleButton: React.FC<FieldProps<IToggleButtonField>> = (fieldApiConfig) => {
  const {
    buttonProps,
    meta,
    input,
    autoFocus,
    isDisabled,
    hidden,
    label,
    ariaLabel,
    isInvalid,
    helperText,
    isRequired,
    isReadOnly,
    requiredFieldIndicatorStyle,
    hideFormControls
  } = useToggleFieldApi<any, ReactNode>(fieldApiConfig as UseFieldApiConfig);

  // Let's spread the props here so we can pass only what's necessary for an input
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { onBlur, onChange, onFocus, value } = input || {};

  const { buttonContent, ...filteredButtonProps } = omit(
    buttonProps,
    'type',
    'value',
    'onChange',
    'isDisabled'
  );

  const content = useMemo(() => {
    if (typeof buttonContent !== 'undefined') return buttonContent;
    return label;
  }, [buttonContent, label]);

  const wrapperLabel = useMemo(() => {
    switch (typeof label) {
      case 'string':
        return label;
      case 'number':
        return label.toString();
      default:
        return undefined;
    }
  }, [label]);

  return (
    <FieldWrapper
      meta={meta}
      hideFormControls={hideFormControls}
      label={wrapperLabel}
      aria-label={ariaLabel}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      hidden={hidden}
      autoFocus={autoFocus}
      helperText={helperText}
    >
      <ToggleButtonComp
        // eslint-disable-next-line react/jsx-no-bind
        onPressedChange={(pressed) => onChange?.(pressed)}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        pressed={value}
        onFocus={onFocus}
        onBlur={onBlur}
        aria-label={ariaLabel}
        {...filteredButtonProps}
      >
        {content}
      </ToggleButtonComp>
    </FieldWrapper>
  );
};
