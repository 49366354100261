import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import type { FixatdlFormComponentType, fixatdlFormComponentTypes } from '../fixatdl-component.mappers';
import { useFieldApi, useEnhancedFormApi } from '@oms/frontend-foundation';
import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import type { FIXatdlField, RadioButtonProps } from '../../fixatdl-form.types';
import { FormControl, FormErrorMessage, Radio } from '@oms/shared-frontend/ui-design-system';

const radioButton: FixatdlFormComponentType = 'radio-button';

export const RadioButton: FC<FieldProps<RadioButtonProps>> = React.memo(
  ({ initialValue, checkedEnumRef, uncheckedEnumRef, radioGroup = '', ...props }) => {
    const {
      meta,
      label,
      input,
      isReadOnly,
      isRequired,
      isDisabled: _isDisabled,
      forceIsDisabled,
      isInvalid
    } = useFieldApi<ICommonField<fixatdlFormComponentTypes.RADIO_BUTTON, boolean>>(props);

    const formApi = useEnhancedFormApi();
    const formApiRef = useRef(formApi);
    formApiRef.current = formApi;

    const onChangeRef = useRef(input.onChange);
    onChangeRef.current = input.onChange;

    const { name } = props;

    const [checked, setChecked] = useState(initialValue);

    useEffect(() => {
      // as value comes as undefined, we need to get it from the form state
      const sub = formApi.get$({ values: true, fields: [input.name] }).subscribe(({ values }) => {
        const value = values[input.name];
        const isChecked = typeof value === 'boolean' ? value : value === checkedEnumRef ? true : false;

        setChecked(isChecked);
      });

      return () => {
        sub.unsubscribe();
      };
    }, [checkedEnumRef, formApi, input.name, initialValue]);

    useEffect(() => {
      if (!input.value) {
        onChangeRef.current(initialValue ? checkedEnumRef : uncheckedEnumRef);
      }
    }, [checkedEnumRef, initialValue, uncheckedEnumRef, input.value]);

    const findCheckedButtonFromGroup = useCallback(
      (fields: FIXatdlField[]): RadioButtonProps => {
        const values = formApi.getState().values;

        for (const field of fields) {
          if (
            field.component === radioButton &&
            field.radioGroup === radioGroup &&
            field.checkedEnumRef === values[field.name]
          ) {
            return field;
          }

          if ('fields' in field) {
            const checkedButton = findCheckedButtonFromGroup(field.fields);

            if (checkedButton) {
              return checkedButton;
            }
          }
        }

        return {} as RadioButtonProps;
      },
      [formApi, radioGroup]
    );

    const handleChange = useCallback(() => {
      //get radio button that belongs to the same group and is currently checked
      const checkedButton = findCheckedButtonFromGroup(formApi.schema.fields as FIXatdlField[]);

      if (checkedButton.name) {
        //update its state to the uncheckedEnumRef value
        formApiRef.current.change(checkedButton.name, checkedButton.uncheckedEnumRef);
      }

      //update state of the clicked button to the checkedEnumRef value
      formApiRef.current.change(name, checkedEnumRef);
    }, [findCheckedButtonFromGroup, formApi.schema.fields, name, checkedEnumRef]);

    const isDisabled = forceIsDisabled || _isDisabled;
    const error = meta.touched && meta.error ? (meta.error as string) : '';
    const extraProps = { isReadOnly, isRequired, isDisabled, isInvalid };

    return (
      <FormControl {...extraProps} sx={{ marginTop: 3 }}>
        <Radio
          {...input}
          sx={{ paddingRight: 2 }}
          onChange={handleChange}
          radioGroup={radioGroup}
          name={radioGroup}
          isDefaultChecked={checked}
          isChecked={checked}
        >
          {label}
        </Radio>
        {error && <FormErrorMessage>{error}&nbsp;</FormErrorMessage>}
      </FormControl>
    );
  },
  isEqual
);
