import type { ActionDefFactory } from '@oms/frontend-vgrid';
import type { AllActionContextEventsRecord } from '@app/actions/events/types';
import { COMMANDS_TO_FORMS } from '@app/actions/types/action.types';
import { FORM_MAP } from '@app/generated/mappers';
import { AppWorkspace } from '@app/app-config/workspace.config';
import type { RouteOrderFormOutput } from '@app/widgets/trading/route-order/route-order.form-common';
import { GQLResult, extractGQLEnvelope } from '@oms/frontend-foundation';
import type { FormRendererEventBase, GQLMutationEnvelopeShape } from '@oms/frontend-foundation';
import type { FeedbackWrapper } from '@oms/frontend-foundation';
import { CREATE_UNBOUND_TO_ACTION_NAME } from './create-unbound-trading-order.action.types';
import type {
  ActionCommandConfig,
  ActionCommandContracts
} from '@app/actions/commands/command.registry.types';
import type { RouteOrderFormValues } from '../../route-order.form-contract';
import { openMontageCreateTradingOrder } from '../../route-order.form-open';

// how do we allow users to use either the actionContext, or the selected items in the grid
// i.e. montage would cancel ios via actionContext$; other grids might look at the selected rows.
export const CREATE_UNBOUND_TO_ACTION: ActionDefFactory = (b) =>
  b
    .name(CREATE_UNBOUND_TO_ACTION_NAME)
    .toolbar((t) =>
      t
        .location('LeftVerticalToolbar')
        .component('action-button')
        .id('left_create_unbound_to_button')
        .props({ variant: 'primary', content: 'Unbound TO' })
    )
    .lifecycles('change')
    .onChange<ActionCommandConfig<ActionCommandContracts['create_unbound_to']>>(async (e) => {
      const { config: createToConfig, notify, container } = e;
      const { payload, actionContext$, allowsConfirmation = false, confirmation = true } = createToConfig;
      const configFormValues = payload;
      const shouldCallMutation = allowsConfirmation && !confirmation;

      const gridActionFormValueOverrides: AllActionContextEventsRecord['create_unbound_to'] =
        actionContext$?.getCommand('create_unbound_to');

      let feedback: GQLMutationEnvelopeShape<any>['feedback'] = [];

      notify({ isLoading: true });

      const tradingOrderCategory = (gridActionFormValueOverrides || configFormValues)?.tradingOrderCategory;
      const instrumentId = (gridActionFormValueOverrides || configFormValues)?.instrument?.id || '';

      const initialValues: RouteOrderFormValues = {
        ...gridActionFormValueOverrides,
        ...configFormValues,
        tradingOrderCategory,
        instrument: {
          id: instrumentId
        },
        hiddenMode: {
          type: 'create',
          location: 'montage'
        },
        matchedInvestorOrderIds: []
      };

      if (shouldCallMutation) {
        const appWorkspace = container.resolve(AppWorkspace);
        const formId = COMMANDS_TO_FORMS['create_unbound_to'] as 'ROUTE_ORDER';
        const formBuilder = FORM_MAP[formId];
        const form = formBuilder.build();
        const formOutput = await form.sanitizer.output(initialValues, {
          ...form,
          formId: form.formBuilderId,
          formSaveType: 'UNKNOWN',
          container
        });

        if (formOutput) {
          const apiResult = await form.change(
            {
              type: 'SUBMIT',
              payload: {
                formValues: configFormValues || {},
                output: formOutput,
                modifiedFields: []
              },
              meta: {
                ...form,
                formId: form.formBuilderId,
                formSaveType: 'UNKNOWN',
                windowId: '',
                widgetId: ''
              }
            },
            {
              notify: function (
                _event: FormRendererEventBase<RouteOrderFormOutput, RouteOrderFormValues>
              ): void {},
              container,
              workspace: appWorkspace
            }
          );

          if (apiResult instanceof GQLResult) {
            apiResult.mapSync(
              (data) => {
                const envelope = extractGQLEnvelope(data);
                if (envelope?.feedback?.length) feedback = envelope?.feedback;
              },
              {
                ENVELOPE_FEEDBACK_ERROR: (errors) => {
                  if (errors.length) feedback = errors;
                }
              },
              (remainingErrors) => {
                feedback = feedback || [];
                feedback.push(
                  ...remainingErrors.map(
                    (e: Error) =>
                      ({
                        code: e.name,
                        level: 'Error',
                        message: e.message
                      }) as FeedbackWrapper
                  )
                );
              }
            );
          }
        } else {
          feedback.push({
            code: 'MISSING_REQUIRED_FIELDS',
            level: 'Error',
            message: 'Missing required fields.'
          });
        }
      }

      if (feedback.length || !shouldCallMutation) {
        openMontageCreateTradingOrder(e.workspace, {
          form: {
            initialValues,
            initialFeedback: feedback
          }
        });
      }
      notify({ isLoading: false });
    });
