import type { AllControls, ControlQuery, ListItem } from '@valstro/fixatdl-core';
import type { PartialBy } from '@oms/shared/util-types';
import type { StrategyQuery } from '@valstro/fixatdl-core';
import type { FixAtdlListItem } from '@oms/generated/frontend';
import type { FIXatdlField } from '@app/forms/fixatdl/fixatdl-form.types';
import type { FieldProps } from '@oms/frontend-foundation';

export type AtdlFieldPartial = PartialBy<
  FieldProps<FIXatdlField>,
  'onBlur' | 'onChange' | 'onFocus' | 'value'
>;

export type ControlTransformer<TControl = AllControls> = (
  control: TControl,
  query: StrategyQuery,
  formValues: Record<string, any>
) => AtdlFieldPartial;

export const getListItems = (items: ListItem[] | undefined | null): FixAtdlListItem[] => {
  const itemsOnControl = items || [];

  const missingUiRep = (rep?: string) => rep == undefined || rep == null;
  return itemsOnControl.map((i) => ({
    enumID: i.enumId,
    uiRep: missingUiRep(i.uiRep) ? i.enumId : i.uiRep
  }));
};

export const getInitValue = (control: ControlQuery, formVals: Record<string, any>): any => {
  const paramQuery = control.parameter();

  formVals[control.control.id] = formVals[control.control.id] || control.initialValue();

  if (!paramQuery) {
    return formVals[control.control.id];
  }

  const rawParam = formVals[paramQuery.parameter.name];
  const param = paramQuery.findEnum(String(rawParam))?.enumId || rawParam;

  return param || formVals[control.control.id];
};
