import React, { useCallback, useMemo } from 'react';
import { formBuilderRemoteEvent$, FormRenderer } from '@oms/frontend-foundation';
import type { Field } from '@oms/frontend-foundation';
import { container } from 'tsyringe';
import type { AllFieldsDialogProps } from './all-fields-form.dialog.config';
import type { AnyRecord, OnValuesChanged } from '@oms/frontend-foundation';
import {
  useCurrentAppWidgetActorProps,
  useCurrentAppWindow,
  useCurrentAppWidget
} from '@app/common/workspace/workspace.hooks';

const templateProps = { showSubmitButton: false, sx: { backgroundColor: 'layout.level1' } } as const;

export const AllFieldsFormDialogComponent: React.FC = () => {
  const [{ fields, formId, formType, initialValues }] = useCurrentAppWidgetActorProps<AllFieldsDialogProps>();
  const window = useCurrentAppWindow();
  const widget = useCurrentAppWidget();

  const schema = useMemo(
    () => ({
      // Annoyingly, the app Field is sufficently different from the Field type in the foundation that we need to cast it
      // even though they are the same
      fields: (fields as unknown as Field[]) || []
    }),
    [fields]
  );

  // Forward the values change to the main form
  const onValuesChanged: OnValuesChanged<AnyRecord> = useCallback(
    (ctx) => {
      formBuilderRemoteEvent$.next({
        type: 'REMOTE_VALUES_CHANGED',
        payload: ctx,
        meta: {
          formId,
          formType,
          formSaveType: 'UNKNOWN',
          widgetId: widget.id,
          windowId: window.id
        }
      });
    },
    [window.id, widget.id, formId, formType]
  );

  if (initialValues === undefined) {
    return null;
  }

  return (
    <FormRenderer
      initialValues={initialValues}
      schema={schema}
      formId={formId + '_all-fields'}
      formType={formType}
      container={container}
      template="simple"
      templateProps={templateProps}
      onValuesChanged={onValuesChanged}
    />
  );
};

export default AllFieldsFormDialogComponent;
