import { createContext, useContext } from 'react';
import { type AnyRecord } from '../../../../../common/type.helpers';
import type { FormOptions } from '../../../../types';
import type { FormBuilderSanitizerDefinition } from '../../../form-builder-sanitizer.class';
import { type FormContract, type InferFormValuesFromFormContract } from '../../../form-contract.class';
import type { AnyFieldDefinition } from '../../../form-field-definition.class';
import type { FormBuilderContext } from '../../../form-builder.common.types';

export type FormBuilderTemplateContextProps<
  TInputContract extends AnyRecord,
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord
> = FormBuilderContext<TFormFieldValues> & {
  sanitizer?: FormBuilderSanitizerDefinition<TInputContract, TOutputContract, TFormFieldValues>;
  formApi: FormOptions<TFormFieldValues>;
};

export const FormBuilderTemplateContext = createContext<FormBuilderTemplateContextProps<any, any, any>>(
  {} as FormBuilderTemplateContextProps<any, any, any>
);

export function useFormBuilderTemplate<
  TInputContract extends AnyRecord,
  TOutputContract extends AnyRecord,
  TFormContract extends FormContract<TOutputContract, Record<string, AnyFieldDefinition>> = FormContract<
    TOutputContract,
    Record<string, AnyFieldDefinition>
  >,
  TFormFieldValues extends AnyRecord = InferFormValuesFromFormContract<TFormContract>
>(): FormBuilderTemplateContextProps<TInputContract, TOutputContract, TFormFieldValues> {
  return useContext(FormBuilderTemplateContext) as FormBuilderTemplateContextProps<
    TInputContract,
    TOutputContract,
    TFormFieldValues
  >;
}
