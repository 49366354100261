import { Flex, SimpleGrid, Text } from '@oms/shared-frontend/ui-design-system';

interface SummaryItemProps {
  label: string;
  value: string;
}

const SummaryItem = (props: SummaryItemProps) => {
  return (
    <Flex direction="column">
      <Text type="gridTiny" sx={{ color: 'text.minor' }}>
        {props.label}
      </Text>
      <Text type="gridBase" sx={{ color: 'text.primary' }}>
        {props.value}
      </Text>
    </Flex>
  );
};

export const InvestorOrderTradingOrdersStatsGroupHeader = () => {
  return (
    <SimpleGrid
      style={{
        minHeight: 48,
        width: '100%',
        paddingRight: 'calc(var(--ag-cell-horizontal-padding) * 2 - 1px)',
        paddingLeft:
          'calc((var(--ag-cell-horizontal-padding) - 1px * 2) + (var(--ag-cell-widget-spacing) + 16px))'
      }}
      sx={{ alignItems: 'center', bgColor: 'layout.level2' }}
      columns={5}
    >
      <SummaryItem label="Primary Close" value="12.50 +0.75 600" />
      <SummaryItem label="VWAP" value="12.00 -0.1 400" />
      <SummaryItem label="Arrival" value="12.25 +0.1 300" />
      <SummaryItem label="Last Fill Time" value="Sep 7 11:11:11am EST" />
      <SummaryItem label="Avg Fill Size" value="175" />
    </SimpleGrid>
  );
};
