import clsx from 'clsx';
import { useFormControl } from './form-control';
import * as styles from './css/form-helper-text.css';
import { formLabelRecipe } from './css/form-label.css';
import { textEllipsis } from '../../system/styles.css';
import { Box, type BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

export type FormHelperTextProps = {} & BoxProps;

export const FormHelperText = polymorphicComponent<'div', FormHelperTextProps>((props, ref) => {
  const { as = 'div', children, className, id, ...rest } = props;
  const formControl = useFormControl({});

  const classes = clsx(
    styles.formHelperText,
    formLabelRecipe({ size: formControl.size }),
    formControl.clipFormLabels && textEllipsis,
    className
  );

  return (
    <Box {...rest} as={as} ref={ref} className={classes} id={id || formControl.helpTextId}>
      {children}
    </Box>
  );
});

if (__DEV__) {
  FormHelperText.displayName = 'FormHelperText';
}
