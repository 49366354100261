import {
  type AllConditions,
  type AnyConditions,
  type ConditionProperties,
  type NestedCondition,
  type RuleProperties,
  type TopLevelCondition
} from 'json-rules-engine';
import { isEmpty } from 'lodash';
import { type RuleGroup } from '../../components/rule-builder/rule.types';

export const jsonRuleGroupAdder = (grp: TopLevelCondition, item: ConditionProperties) => {
  const topLevelCondition = (grp as AllConditions).all ? 'all' : 'any';
  if (topLevelCondition === 'all') {
    (grp as AllConditions).all.push(item);
  } else {
    (grp as AnyConditions).any.push(item);
  }
};

export const isJsonRuleGroup = (grp: NestedCondition) =>
  !!((grp as AllConditions).all || (grp as AnyConditions).any);

export const jsonRuleGroupIterator = (grp: NestedCondition) => {
  if (isEmpty(grp)) {
    return [];
  }

  const combinator = (grp as AllConditions).all ? 'and' : 'or';
  return combinator === 'and' ? (grp as AllConditions).all : (grp as AnyConditions).any;
};

export const ruleGroupFactory = (grp: TopLevelCondition) => {
  const combinator = (grp as AllConditions).all ? 'and' : 'or';
  const group: RuleGroup = {
    combinator,
    rules: []
  };
  return group;
};

export const jsonRuleGroupFactory = (grp: RuleGroup) => {
  const comb = grp.combinator;
  const jsonRule: RuleProperties['conditions'] = comb === 'and' ? { all: [] } : { any: [] };

  return jsonRule;
};
