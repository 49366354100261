import { useCallback, useEffect, useMemo } from 'react';
import { useService } from '@oms/frontend-foundation';
import type { DataSourceCommon } from '@oms/frontend-foundation';
import { PositionsService } from '../positions.service';
import type { Observable } from 'rxjs';
import type { NestedTreeData } from '../common/tree-grid/types/tree-data.types';
import type { PositionRow } from '@app/common/types/positions/positions.types';

export const usePositionsSubscription = (): void => {
  const positionsService = useService(PositionsService);

  useEffect(() => {
    const { unsubscribe } = positionsService.subscribe();
    return unsubscribe;
  }, [positionsService]);
};

export const usePositionsTreeSubscription = (): Observable<DataSourceCommon<NestedTreeData<PositionRow>>> => {
  const positionsService = useService(PositionsService);

  usePositionsSubscription();

  return useMemo(() => positionsService.positionsTree$(), [positionsService]);
};

export const usePositionsForAccountSubscription = (): ((
  accountId?: string
) => Observable<DataSourceCommon<PositionRow>>) => {
  const positionsService = useService(PositionsService);

  usePositionsSubscription();

  return useCallback(
    (accountId?: string) => positionsService.positionsForAccount$(accountId),
    [positionsService]
  );
};
