import type { DependencyContainer } from 'tsyringe';
import { IS_TAURI } from './workspace.constants';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { ProcessState } from '@app/data-access/memory/process-id.subject';

export const getLeaderOrTabId = (containerOrWorkspace: DependencyContainer | AppWorkspace): string => {
  const container =
    containerOrWorkspace instanceof AppWorkspace
      ? containerOrWorkspace.getMeta()?.container
      : containerOrWorkspace;
  if (!container) {
    throw new Error('Container not found');
  }
  const workspace = container.resolve(AppWorkspace);
  const processService = container.resolve(ProcessState);
  const processId = IS_TAURI
    ? workspace.getLeaderProcessId()
    : processService.processId || workspace.getLeaderProcessId();
  return processId;
};
