import type { ICellRendererParams } from '@ag-grid-community/core';
import { IconButton } from '@oms/shared-frontend/ui-design-system';
import { omit } from 'lodash';
import { type FC, MouseEventHandler, useCallback } from 'react';
import { useInlineActionProps } from '../../../hooks/action.events.hook';
import type { InlineActionComponentProps } from '../../../models/actions.model';

export const INLINE_ICON_ACTION_BUTTON = 'inline-icon-action-button';

export const InlineIconActionButtonCell: FC<
  ICellRendererParams<any, any> & InlineActionComponentProps<any>
> = (props) => {
  const actionProps = useInlineActionProps<any, any>(props);

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    actionProps.onChange({
      state: actionProps,
      actionId: actionProps.id,
      lifecycle: 'change'
    });
  }, [actionProps]);

  if (actionProps.isVisible === false) {
    return null;
  }

  return (
    <IconButton
      {...(omit(actionProps, 'onChange', 'content', 'actionType', 'isVisible', 'instanceId') as any)}
      onClick={onClick}
    >
      {actionProps.content}
    </IconButton>
  );
};
