export const robotoFlex = '"Roboto Flex", system-ui, sans-serif';
export const robotoMono = '"Roboto Mono", Courier, monospace';
export const comicSans = '"Comic Sans MS", "Comic Sans", cursive;';

export const typography = {
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em'
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem'
  },
  fontWeights: {
    thin: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700'
  },
  fonts: {
    brand: robotoFlex,
    heading: robotoFlex,
    base: robotoFlex,
    mono: robotoMono
  },
  // Created using https://nekocalc.com/px-to-rem-converter - based on base size of 12px = 1rem
  fontSizes: {
    base: '12px',
    xs: '0.66rem',
    sm: '0.83rem',
    md: '1rem',
    lg: '1.167rem', // 14px
    subheading: '1.5rem', // Sub heading
    h6: '2rem',
    h5: '2.67rem',
    h4: '3.33rem',
    h3: '4rem',
    h2: '4.68rem',
    h1: '5.33rem',
    inherit: 'inherit'
  }
};

export type Typography = typeof typography;

export default typography;
