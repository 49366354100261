import { ICellRendererParams } from '@ag-grid-community/core';
import { mergeWith } from 'lodash';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { filter } from 'rxjs';
import { ActionsService } from '../services/actions.service';
import { useGridContainer } from './grid.container.hook';
import type { AnyRecord } from '@oms/frontend-foundation';
import { ActionComponentProps, InlineActionComponentProps } from '../models/actions.model';
import isEqual from 'lodash/isEqual';

export const useActionsService = <TData extends AnyRecord>(): ActionsService<TData> => {
  const gridContainer = useGridContainer();

  // TODO: Breaks ESLint server
  const actionsService: ActionsService<TData> = useMemo(() => {
    const service = gridContainer.resolve(ActionsService);
    return service as ActionsService<TData>;
  }, [gridContainer]);

  return actionsService;
};

export const useActionId = <
  TData extends AnyRecord,
  TActionProps extends ActionComponentProps<TData> = ActionComponentProps<TData>
>(
  props: TActionProps
): string | undefined => {
  const actionsService = useActionsService<TData>();
  const [actionId, setActionId] = useState<string>();

  useEffect(() => {
    if (!actionsService.find(props.id)) {
      setActionId(
        actionsService.register({
          instanceId: props.instanceId,
          name: props.name,
          actionType: props.actionType,
          id: '',
          rowData: props?.rowData
        })
      );
    }
    return () => {
      // Cleaning up actions to avoid memory leaks
      actionsService.remove(actionId);
    };
  }, [actionsService, props.actionType, props.id, props.instanceId, props.name]);

  return actionId;
};

function overrideArrays(objValue: unknown, srcValue: unknown) {
  if (Array.isArray(objValue)) {
    return srcValue;
  }
}

export const useActionProps = <
  TData extends AnyRecord,
  TActionProps extends ActionComponentProps<TData> = ActionComponentProps<TData>
>(
  props: TActionProps
): TActionProps => {
  const [isInit, setInit] = useState(false);
  const initialRowDataRef = useRef(props.rowData as TData);
  const actionId = useActionId<TData>(props);
  const currentProps = useRef<TActionProps>({ ...props });
  const actionsService = useActionsService<TData>();
  const actionsObs$ = useMemo(() => actionsService.actions$(), [actionsService]);
  const [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    const actionsEventSub = actionsObs$
      .pipe(filter((e) => e.actionId === actionId && e.source === 'user'))
      .subscribe((msg) => {
        const previousProps = currentProps.current;
        currentProps.current = mergeWith(
          {},
          currentProps.current,
          msg.state,
          overrideArrays
        ) as unknown as TActionProps;

        if (!isEqual(currentProps.current, previousProps)) {
          setRender(!render);
        }
      });

    if (!isInit && actionId && actionsService.find(actionId)) {
      const ctx = {
        actionId,
        state: currentProps.current,
        lifecycle: 'init',
        event: {},
        data: [initialRowDataRef.current]
      };
      currentProps.current.onChange(ctx as any);
      setInit(true);
    }

    return () => {
      actionsEventSub.unsubscribe();
    };
  }, [actionId, actionsObs$, actionsService, render, isInit]);

  // When inline split button cell render is rerendering because row data has changed
  // we detect this here and fire a refresh event to re-evaluate the actions to display
  useLayoutEffect(() => {
    if (props.rowData === currentProps.current.rowData || !isInit || !actionId) {
      return;
    }
    currentProps.current.rowData = props.rowData;
    const ctx = {
      actionId,
      state: currentProps.current,
      lifecycle: 'refresh',
      event: {},
      data: [currentProps.current.rowData]
    };
    currentProps.current.onChange(ctx as any);
  }, [actionId, props.rowData, isInit]);

  return {
    ...currentProps.current,
    isVisible: isInit && currentProps.current.isVisible,
    id: actionId
  };
};

export const useInlineActionProps = <
  TData extends AnyRecord,
  TActionProps extends ActionComponentProps<TData> = ActionComponentProps<TData>
>(
  props: ICellRendererParams<TData> & InlineActionComponentProps<TData, TActionProps>
) => {
  return useActionProps<TData, TActionProps>({ ...props.action, instanceId: props.node.id });
};
