import { MonitorInfo } from './platform';

export async function getBrowserMonitors(): Promise<MonitorInfo[]> {
  // Browser supports only a single monitor
  return [
    {
      width: window.innerWidth,
      height: window.innerHeight,
      name: '',
      x: 0,
      y: 0,
      scaleFactor: 1
    }
  ];
}

export async function getCurrentBrowserMonitorIndex(): Promise<number> {
  // This used to call getBrwoserMonitors which used window.getScreenDetails()
  // to retrieve the dimentions. That is why both functions are async.
  // Using getScreenDetails required window-management permission which is
  // chrome specific and required monkey-patching playwright in testkit.
  // Now that's removed, there is a bug that prevents click triggered popovers
  // from displaying if this function returns immediately, so we wait for the
  // next tick.
  // TODO: Fix in the future, since performance here can be improved.
  await new Promise<void>((resolve) => setTimeout(() => resolve(), 0));
  return 0;
}
