import { type FC, type LegacyRef, useMemo } from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Select as SelectComponent } from '@oms/shared-frontend/ui-design-system';
import type { FieldProps, ICommonSelectField } from '../../types';
import { useFieldApi, useFieldFocus } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import * as styles from './select.component.css';

export interface ISelectField<TValue = string | number, TValidator = Validator>
  extends ICommonSelectField<typeof FORM_COMPONENT_TYPE.SELECT, TValue, TValidator> {}

export const Select: FC<FieldProps<ISelectField>> = (props) => {
  const {
    meta,
    label,
    wrapperSx = {},
    sx = {},
    input: { name, onBlur, onChange, onFocus, value: _value },
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    requiredFieldIndicatorStyle,
    isFeatureField,
    isPrimaryField,
    isVisible,
    hideFormControls,
    options = [],
    style = {}
  } = useFieldApi<ISelectField>(props);

  // If this is a 2-option select with Y/N values, assign those from true/false.
  const value = useMemo(() => {
    if (options.length === 2 && options[0].value === 'Y' && options[1].value === 'N') {
      if (_value === true) {
        return 'Y';
      }
      if (_value === false) {
        return 'N';
      }
    }
    return _value;
  }, [_value, options]);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  // Combine field/input props
  const fieldProps = { name, onBlur, onChange, onFocus, value, hidden: isVisible === false, style, sx };

  // Combine field wrapper props
  const fieldWrapperProps = {
    meta,
    label,
    isReadOnly,
    isRequired,
    sx: wrapperSx,
    isDisabled,
    isInvalid,
    requiredFieldIndicatorStyle,
    helperText,
    hideFormControls,
    isFeatureField,
    isPrimaryField,
    isVisible
  };

  // Handle selectAllOnFocus & autoFocus
  const [inputRef] = useFieldFocus<HTMLSelectElement>(props);

  return (
    <FieldWrapper {...fieldWrapperProps}>
      <SelectComponent {...fieldProps} ref={inputRef as any as LegacyRef<HTMLSelectElement>}>
        {!value && (
          <option value="" disabled hidden>
            {isFeatureField || isPrimaryField ? '' : 'Select'}
          </option>
        )}
        {options.map(({ value, label, isDisabled }) => (
          <option key={value} className={styles.option} value={value} disabled={isDisabled}>
            {label}
          </option>
        ))}
      </SelectComponent>
    </FieldWrapper>
  );
};
