import type { RxJsonSchema, RxDocument, RxCollection, RxCollectionCreator } from 'rxdb-v15';

export const ADDITIONAL_FIELDS_COLLECTION_NAME = 'additional_fields';

export const additionalFieldsSchema: RxJsonSchema<AdditionalFieldsDocType> = {
  title: 'DDF Additional Fields schema',
  description: 'Stores Additional Fields state',
  version: 0,
  keyCompression: false,
  primaryKey: 'formType',
  type: 'object',
  properties: {
    formType: {
      type: 'string',
      maxLength: 128
    },
    selectedFields: {
      type: 'array',
      items: {
        type: 'string'
      }
    }
  },
  required: []
} as const;

export type AdditionalFieldsDocType = {
  formType: string;
  selectedFields: string[];
};

export type AdditionalFieldsDocument = RxDocument<AdditionalFieldsDocType>;

export type AdditionalFieldsCollection = RxCollection<AdditionalFieldsDocType>;

export const ADDITIONAL_FIELDS_COLLECTION: RxCollectionCreator<any> = {
  schema: additionalFieldsSchema
};
