import { Unsubscribe } from '@valstro/remote-link';
import type { Workspace } from './workspace';
import { logger } from './logger';

export type PluginFunctionContext<TWorkspace extends Workspace = Workspace> = {
  workspace: TWorkspace;
};

type PromiseUnsubscribe = () => Promise<void>;

export type PluginFn<TWorkspace extends Workspace = Workspace> = (
  context: PluginFunctionContext<TWorkspace>
) => Promise<PromiseUnsubscribe | Unsubscribe | void> | (PromiseUnsubscribe | Unsubscribe | void);

export type PluginOptions<TWorkspace extends Workspace = Workspace> = {
  name: string;
  pluginFn: PluginFn<TWorkspace>;
};

/**
 * Workspace Plugin
 * - Plugins are used to extend the functionality of the workspace
 * - Plugins are run in the order they are registered
 */
export class Plugin<TWorkspace extends Workspace = Workspace> {
  /**
   * Unique name of the plugin (used for registration & identification)
   */
  name: string;

  /**
   * Plugin function that is run when the plugin is registered
   * - The plugin function is passed the workspace API
   * - The plugin function should return an unsubscribe function
   * - The unsubscribe function is run when the plugin is unregistered
   * - The plugin function can be async
   * - The plugin function will only be run once
   */
  pluginFn: PluginFn<TWorkspace>;

  private constructor(readonly options: PluginOptions<TWorkspace>) {
    this.name = options.name;
    this.pluginFn = options.pluginFn;
  }

  /**
   * Create a new plugin
   *
   * @param options - PluginOptions
   * @returns
   */
  static create<TWorkspace extends Workspace = Workspace>(options: PluginOptions<TWorkspace>) {
    return new Plugin<TWorkspace>(options);
  }

  /**
   * Run the plugin (internal)
   * Note: This should only be called by the workspace
   *
   * @param context - PluginFunctionContext<TWorkspace>
   * @returns Promise<Unsubscribe>
   */
  async run(context: PluginFunctionContext<TWorkspace>) {
    return await this.pluginFn(context);
  }
}

type RegisteredPlugin = {
  plugin: Plugin;
  order: number;
};

export class PluginRegistry {
  private _plugins: Map<string, RegisteredPlugin> = new Map();
  private _pluginUnsbs: (PromiseUnsubscribe | Unsubscribe)[] = [];
  private _havePluginsRun = false;

  get havePluginsRun() {
    return this._havePluginsRun;
  }

  register(plugin: Plugin) {
    this._plugins.set(plugin.name, {
      plugin: plugin,
      order: this._plugins.size
    });
    logger.debug(`[Plugin Registry] Registered plugin "${plugin.name}"`, {
      plugin,
      order: this._plugins.size
    });
    return this;
  }

  unregister(name: string) {
    this._plugins.delete(name);
    return this;
  }

  get(name: string): Plugin | null {
    return this._plugins.get(name)?.plugin || null;
  }

  getAll(): Plugin[] {
    return Array.from(this._plugins.values())
      .sort((p) => p.order)
      .map((p) => p.plugin);
  }

  async runAll(context: PluginFunctionContext) {
    if (this._plugins.size === 0) {
      return;
    }
    const result = await Promise.all(
      [...this.getAll()].map((plugin) => {
        logger.debug(`[Plugin Registry] Running plugin "${plugin.name}"`, {
          plugin
        });
        return plugin.run(context);
      })
    );
    this._pluginUnsbs = result.filter((r) => !!r) as (PromiseUnsubscribe | Unsubscribe)[];
    this._havePluginsRun = true;
  }

  async destroy() {
    for (const unsub of this._pluginUnsbs) {
      await unsub();
    }

    this._pluginUnsbs = [];
    this._plugins.clear();
    this._havePluginsRun = false;
  }
}

export const createPluginsRegistry = () => {
  return new PluginRegistry();
};
