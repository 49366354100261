import { useMemo } from 'react';
import type {
  ActionToolbarLocation,
  WindowToolbarActionArgs,
  FlexLayoutTabSetToolbarActionArgs
} from '../toolbar-context.types';
import { useToolbar } from './use-toolbar.hook';

export const useVisibleToolbarActions = <
  T extends ActionToolbarLocation,
  TReturn = T extends 'window' ? WindowToolbarActionArgs : FlexLayoutTabSetToolbarActionArgs
>(
  location: T
): TReturn[] => {
  const { visibleToolbarActions } = useToolbar();

  return useMemo(() => {
    const filtered = visibleToolbarActions
      .filter((action) => action.location === location)
      .flatMap((action) => action);
    return filtered as TReturn[];
  }, [location, visibleToolbarActions]);
};
