import type { Level1IntegrationEvent } from '@oms/generated/frontend';

export const MARKETDATA_EVENT_TYPE = {
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  LEVEL_ONE_UPDATE: 'LEVEL_ONE_UPDATE'
} as const;

export type ActionType<P> = {
  type: string;
  payload: P;
};

export interface RemoteMarketDataSubEvent {
  type: typeof MARKETDATA_EVENT_TYPE.SUBSCRIBE;
  payload: {
    tickers: string[];
    fromProcessId: string;
    fromCompId: string;
  };
}

export interface RemoteMarketDataUnsubEvent {
  type: typeof MARKETDATA_EVENT_TYPE.UNSUBSCRIBE;
  payload: {
    tickers: string[];
    fromProcessId: string;
    fromCompId: string;
  };
}

export interface RemoteMarketDataUpdateEvent {
  type: typeof MARKETDATA_EVENT_TYPE.LEVEL_ONE_UPDATE;
  payload: {
    ticker: string;
    data: Level1IntegrationEvent;
  };
}

export type RemoteMarketDataEventUnion =
  | RemoteMarketDataSubEvent
  | RemoteMarketDataUnsubEvent
  | RemoteMarketDataUpdateEvent;
