/**
 * Converts from pascal case string to a human readable industry standard naming convention
 * eg: StreetSideTradeCancel -> street-side-trade:cancel
 */
export const fromPascalToIndustrySpec = (entitlement: string): string => {
  const newStr = entitlement.replace(/([A-Z][a-z]*)/g, '-$1').toLowerCase();
  const lastHyphenIndex = newStr.lastIndexOf('-');
  return newStr.slice(1, lastHyphenIndex) + ':' + newStr.slice(lastHyphenIndex + 1);
};

/**
 * Converts from pascal case string to a human readable string
 * @param role
 * @returns
 */
export const fromPascalToHumanReadable = (role: string): string => role.replace(/(?<!^)([A-Z])/g, ' $1');

/**
 * Produces an object of {
 *  AccountLimitManage: account-limit:manage
 * }
 */
export const convertToKeyVal = (entitlements: string[]): Record<string, string> =>
  entitlements.reduce((acc: Record<string, string>, entitlement: string) => {
    return { ...acc, [entitlement]: fromPascalToIndustrySpec(entitlement) };
  }, {});

export const convertEntitlementEnumToKeyVal = <Key extends string>(
  entitlementEnum: Record<Key, string>
): Record<string, string> => convertToKeyVal(Object.keys(entitlementEnum));

/**
 * Produces a list of entitlements
 * i.e. [ 'account-limit:manage' ]
 */
export const convertToEntitlementList = <T extends string[]>(entitlements: T): string[] =>
  entitlements.map((entitlement) => fromPascalToIndustrySpec(entitlement));

/**
 * Produces the map of the relationship between composite roles and its entitlements
 */
export const produceEntitlementsMap = <Key extends string, Value extends string>(
  roleEntitlementsMap: Record<Key, Value>,
  GEN_TYPES_OG: Object = {}
): Record<string, string[]> => {
  const outputMap: Record<string, string[]> = {};

  return Object.keys(roleEntitlementsMap).reduce((acc, iter) => {
    const compositeRoleStr = fromPascalToHumanReadable(iter);
    const compositeVal = roleEntitlementsMap[iter as keyof typeof roleEntitlementsMap] as string;
    if (compositeRoleStr && compositeVal) {
      const innerEntitlements = GEN_TYPES_OG[compositeVal as keyof typeof GEN_TYPES_OG] as Object;
      // entitlements as a string[]
      const strValues: string[] = innerEntitlements
        ? Object.values<string>(innerEntitlements as unknown as never).map(fromPascalToIndustrySpec)
        : [];

      const entitlementsForKey: string[] = Array.from(
        // dedupe entries
        new Set<string>([...(acc[compositeRoleStr] || []), ...strValues])
      );
      return { ...acc, ...{ [compositeRoleStr]: entitlementsForKey } };
    } else {
      return outputMap;
    }
  }, outputMap);
};
