import { useRef } from 'react';
import { useVGrid, VGrid, ROW_SELECTION_COUNT_ACTION_TYPE, toClientsideGridData } from '@oms/frontend-vgrid';
import { ExchangesService } from '@app/data-access/services/reference-data/exchanges/exchanges.service';
import { useService } from '@oms/frontend-foundation';
import type { ExchangeFragment } from '@oms/generated/frontend';
import { exchangesColumnLibrary } from './exchanges-grid.columns';
import { createAction, updateAction, deleteAction } from './exchanges-grid.actions';

export interface ExchangesGridPropsWidgetComponentProps {}

const ExchangesGridWidgetComponent = () => {
  const exchangesService = useService(ExchangesService);
  const query$ = useRef(exchangesService.watchAll$().pipe(toClientsideGridData()));

  const gridProps = useVGrid<ExchangeFragment>(
    'exchanges',
    (builder) =>
      builder
        .sideBar()
        .columnLibrary(exchangesColumnLibrary)
        .rowSelection((c) => c.multiple())
        .datasource((d) => d.source(query$.current).rowId((r) => r.data.id))
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(createAction)
                .action(updateAction)
                .action(deleteAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    []
  );

  return <VGrid {...gridProps} />;
};

export default ExchangesGridWidgetComponent;
