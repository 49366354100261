import { ReactTauriWindowActorSchemaBuilder, ReactTauriWindowView } from '@valstro/workspace-react';
import type { ReactActorComponentProps } from '@valstro/workspace-react';
import { commonCommandPaletteSchemaOverrides } from './command-palette.common.actor';
import { CommonCommandPaletteComponent } from './command-palette.component';
import { COMMON_PLATFORM_NAME } from '@valstro/workspace';
import { listen } from '@tauri-apps/api/event';
import { COMMON_COMMAND_PALETTE } from '@app/common/command-palette/command-palette.contracts';
import type {
  CommandPaletteContext,
  CommandPaletteOperations,
  CommonCommandPaletteActorSchema,
  CommonCommandPaletteActorSchemaOptions
} from '@app/common/command-palette/command-palette.contracts';
import { appWindow } from '@tauri-apps/api/window';
import type { Subscription } from 'rxjs';

export const TAURI_COMMAND_PALETTE_ACTOR_NAME = 'tauri-command-palette';

const View: React.FC<ReactActorComponentProps<CommonCommandPaletteActorSchema>> = ({ actor }) => {
  return (
    <ReactTauriWindowView<CommonCommandPaletteActorSchema> actor={actor}>
      <CommonCommandPaletteComponent actor={actor} />
    </ReactTauriWindowView>
  );
};

const TauriCommandPaletteActorSchemaBuilder = ReactTauriWindowActorSchemaBuilder.extend<
  CommandPaletteContext,
  CommandPaletteOperations
>((prevSchema) => ({
  ...commonCommandPaletteSchemaOverrides(prevSchema),
  type: COMMON_COMMAND_PALETTE.TYPE,
  name: TAURI_COMMAND_PALETTE_ACTOR_NAME,
  supportedPlatforms: [COMMON_PLATFORM_NAME.TAURI],
  events: async (api, workspace) => {
    const commonSchema = commonCommandPaletteSchemaOverrides(prevSchema);
    const unsubEvents = await commonSchema?.events?.(api, workspace);

    const actor = workspace
      .getActorRegistry()
      .getActorByName<CommonCommandPaletteActorSchema>(api.getDefinition().name);

    const open$ = actor?.meta?.open$;

    let closeTimeout: NodeJS.Timeout | null = null;
    let globalOpenSub: Subscription | null = null;

    // Cancel the close timeout when the user focuses launcher window
    if (open$) {
      globalOpenSub = open$.subscribe(() => {
        if (closeTimeout) {
          clearTimeout(closeTimeout);
          closeTimeout = null;
        }
      });
    }

    // Close the palette when the user blurs the window
    const globalBlurUnsub = await listen('tauri://blur', (e) => {
      if (e.windowLabel.includes(COMMON_COMMAND_PALETTE.BASE_ID)) {
        if (closeTimeout) {
          clearTimeout(closeTimeout);
          closeTimeout = null;
        }
        closeTimeout = setTimeout(() => {
          api.operations.closePalette().catch(console.error);
        }, 100);
      }
    });

    return () => {
      unsubEvents?.();
      globalBlurUnsub();
      globalOpenSub?.unsubscribe();
    };
  },
  selectStrategy: (ctx) => {
    // When selecting the root actor, select this actor if the tauri window ID matches the pre-defined command palette window ID
    if (ctx.location === 'root' && ctx.platformName === COMMON_PLATFORM_NAME.TAURI) {
      return appWindow.label.includes(COMMON_COMMAND_PALETTE.BASE_ID);
    }

    return true;
  }
})).extendView(View);

export const tauriCommandPaletteActor =
  TauriCommandPaletteActorSchemaBuilder.optionsCreator<CommonCommandPaletteActorSchemaOptions>();

export type TauriCommandPaletteActorSchema = typeof TauriCommandPaletteActorSchemaBuilder.schema;
