import { logger } from '../misc';

const l = logger.as('caseToLabel');

type CaseType = 'camel' | 'pascal' | 'snake' | 'kebab';

const preformat = (value: string, caseType: CaseType): string => {
  switch (caseType) {
    case 'camel':
    case 'pascal':
      return value.replace(/([a-z])([A-Z])/g, '$1 $2');
    default:
      return value;
  }
};

const getSplitChar = (caseType: CaseType): string => {
  switch (caseType) {
    case 'snake':
      return '_';
    case 'kebab':
      return '-';
    default:
      return ' ';
  }
};

type WordFormat = 'lower' | 'upper' | 'capitalized' | 'as-is';

interface CaseToLabelFormatOptions {
  capitalizeThreshold?: number;
  onlyCapitalizeFirst?: boolean;
  specialFormat?: Record<string, WordFormat>;
}

const capitalize = (input: string): string => input.slice(0, 1).toUpperCase() + input.slice(1).toLowerCase();

const _caseToLabel = (value: string, caseType: CaseType, options?: CaseToLabelFormatOptions): string => {
  try {
    const { capitalizeThreshold, onlyCapitalizeFirst, specialFormat = {} } = options ?? {};
    const specialFormatMap = new Map<string, WordFormat>();
    Object.entries(specialFormat).forEach(([word, wordFormat]) => {
      specialFormatMap.set(word.toLowerCase(), wordFormat);
    });
    return preformat(value, caseType)
      .split(getSplitChar(caseType))
      .map((segment, index) => {
        const specialFormat = specialFormatMap.get(segment.toLowerCase());
        if (specialFormat) {
          switch (specialFormat) {
            case 'lower':
              return segment.toLowerCase();
            case 'upper':
              return segment.toUpperCase();
            case 'capitalized':
              return capitalize(segment);
            default:
              return segment;
          }
        }
        return (capitalizeThreshold && segment.length < capitalizeThreshold) ||
          (onlyCapitalizeFirst && index > 0)
          ? segment.toLowerCase()
          : capitalize(segment);
      })
      .join(' ');
  } catch (e) {
    l.error(e);
    return value;
  }
};

export const caseToLabel = Object.assign(_caseToLabel, {
  camel: (camelCaseValue: string, options?: CaseToLabelFormatOptions): string =>
    _caseToLabel(camelCaseValue, 'camel', options),
  pascal: (pascalCaseValue: string, options?: CaseToLabelFormatOptions): string =>
    _caseToLabel(pascalCaseValue, 'pascal', options),
  snake: (snakeCaseValue: string, options?: CaseToLabelFormatOptions): string =>
    _caseToLabel(snakeCaseValue, 'snake', options),
  kebab: (kebabCaseValue: string, options?: CaseToLabelFormatOptions): string =>
    _caseToLabel(kebabCaseValue, 'kebab', options)
});

export default caseToLabel;
