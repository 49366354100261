import { FormBuilder } from '@oms/frontend-foundation';
import orderVwapFormContract from './order-vwap.form-contract';
import type { OrderVwapFormContractType, OrderVwapFormValues } from './order-vwap.form-contract';
import type { OrderVwapFormInput, OrderVwapFormOutput } from './order-vwap.form-common';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';

export const orderVwapFormBuilder = FormBuilder.create<OrderVwapFormInput, OrderVwapFormOutput>(
  'order-vwap-form'
)
  .contract<OrderVwapFormContractType>(orderVwapFormContract)
  .type('order-vwap')
  .sanitizer((s) =>
    s
      .input(async (input, ctx): Promise<OrderVwapFormValues> => {
        const { investorOrderId } = input;

        if (!investorOrderId) {
          return {};
        }

        const investorOrderService = ctx.container.resolve(InvestorOrdersService);

        const instrument = await investorOrderService.getInstrumnentById(investorOrderId);

        if (!instrument) {
          return {};
        }

        return {
          investorOrderId,
          instrument: {
            id: instrument.id,
            label: instrument.mappings?.displayCode,
            value: {
              sourceId: investorOrderId
            }
          }
        };
      })
      .output((_) => undefined)
  )
  .change((_) => {});

export default orderVwapFormBuilder;
