import { AppDatabase } from '@app/data-access/offline/app-database';
import type { DependencyContainer } from 'tsyringe';

// For a given form, out of any "additional fields", return the "selected fields" that appear in the main form.
export const getSelectedAdditionalFields = async (
  formType: string | undefined,
  container: DependencyContainer
) => {
  const appDatabase = container.resolve(AppDatabase);
  const result = await appDatabase.offline.additional_fields.findOne(formType || 'unknown').exec();
  return result?.selectedFields ?? [];
};
