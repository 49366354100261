export type OverflowedElement = Pick<
  HTMLElement,
  'offsetHeight' | 'offsetWidth' | 'scrollHeight' | 'scrollWidth'
>;

export const isElementOverflowedByWidth = (
  e: Pick<OverflowedElement, 'offsetWidth' | 'scrollWidth'>
): boolean => {
  return e.offsetWidth < e.scrollWidth;
};

export const isElementOverflowedByHeight = (
  e: Pick<OverflowedElement, 'offsetHeight' | 'scrollHeight'>
): boolean => {
  return e.offsetHeight < e.scrollHeight;
};

export const isElementOverflowed = (e: OverflowedElement): boolean => {
  return isElementOverflowedByWidth(e) || isElementOverflowedByHeight(e);
};
