import {
  FormBuilderTemplateContext,
  type FormBuilderTemplateContextProps
} from './form-builder-template.context';
import { useMemo } from 'react';
import { type AnyRecord } from '../../../../../common/type.helpers';
import type { EnhancedFormOptions } from '../../../../types';
import {
  type OnValuesChanging,
  type OnSanitizedValuesChanged,
  type OnValuesChanged
} from '../../../form-builder.common.types';
import {
  useHandleExternalFormEvents,
  useHandleFormApi,
  useHandleFormValuesChanged
} from './form-builder-template.wrapper.hooks';
import { formRendererEvent$ } from '../../../form-builder.events.renderer';

export type FormBuilderTemplateWrapperProps<
  TInputContract extends AnyRecord,
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord
> = Omit<FormBuilderTemplateContextProps<TInputContract, TOutputContract, TFormFieldValues>, 'formApi'> & {
  setFormApi: (formApi: EnhancedFormOptions<TFormFieldValues>) => void;
  onValuesChanging?: OnValuesChanging<TFormFieldValues>;
  onValuesChanged?: OnValuesChanged<TFormFieldValues>;
  onSanitizedValuesChanged?: OnSanitizedValuesChanged<TFormFieldValues, TOutputContract>;
};

/**
 * Helper component to wrap Form Builder templates & components with the necessary context
 * and hooks to form events & handle callbacks (setFormApi, onValuesChanging, onValuesChanged, onSanitizedValuesChanged) from the parent component (the renderer)
 *
 * @param templateProps - {FormBuilderTemplateWrapperProps}
 * @returns The FormBuilderTemplateWrapper component with the context provider
 */
export function FormBuilderTemplateWrapper<
  TInputContract extends AnyRecord,
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord
>({
  children,
  container,
  setFormApi,
  schema,
  sanitizer,
  onValuesChanging,
  onValuesChanged,
  onSanitizedValuesChanged,
  formType,
  formSaveType,
  formId,
  initialFeedback
}: React.PropsWithChildren<
  FormBuilderTemplateWrapperProps<TInputContract, TOutputContract, TFormFieldValues>
>) {
  // Get the formApi & run the setFormApi callback from the parent component
  const formApi = useHandleFormApi<TFormFieldValues>(setFormApi);

  // Handle external form events & update internal form values to reflect the external changes
  useHandleExternalFormEvents<TFormFieldValues>(formRendererEvent$, formApi, formId);

  const eventContext = useMemo(
    () => ({
      container,
      schema,
      formType,
      formSaveType,
      formId
    }),
    [container, schema, formType, formSaveType, formId]
  );

  // Handle form values changing & run the callbacks when appropriate
  useHandleFormValuesChanged<TFormFieldValues, TOutputContract>(
    formId,
    formApi,
    eventContext,
    sanitizer,
    onValuesChanging,
    onValuesChanged,
    onSanitizedValuesChanged
  );

  // Build the context to provide to the FormBuilderTemplate & any children
  const context: FormBuilderTemplateContextProps<TInputContract, TOutputContract, TFormFieldValues> = useMemo(
    () => ({
      container,
      formApi,
      schema,
      sanitizer,
      initialFeedback,
      setFormApi,
      formId,
      formType,
      formSaveType
    }),
    [container, formApi, schema, sanitizer, setFormApi, formId, formType, formSaveType, initialFeedback]
  );

  // Render the children with the context
  return (
    <FormBuilderTemplateContext.Provider value={context}>{children}</FormBuilderTemplateContext.Provider>
  );
}
