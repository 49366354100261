import React, { useCallback, useEffect, useRef } from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { useFieldApi } from '../../helpers';
import type { FieldProps } from '../../types';
import { type INumberFormatField } from './number-format.type';
import {
  NumberFormat as NumberFormatComponent,
  type OnNumberFormatValueChange
} from '@oms/shared-frontend/ui-design-system';

export const NumberFormat: React.FC<FieldProps<INumberFormatField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onBlur, onChange, onFocus, value },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    hideFormControls,
    style,
    sx,
    wrapperSx = {},
    format,
    selectAllOnFocus = true,
    decimalScale,
    thousandSeparator,
    textAlign,
    fixedDecimalScale,
    allowLeadingZeros,
    allowNegative,
    decimalSeparator,
    suffix,
    prefix,
    thousandsGroupStyle,
    allowedDecimalSeparators,
    autoFocus,
    initialValue,
    placeholder
  } = useFieldApi<INumberFormatField>(props);

  const ref = useRef<HTMLInputElement>(null);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const onInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(event);
      }
      if (selectAllOnFocus) {
        ref.current?.select();
      }
    },
    [onFocus, selectAllOnFocus]
  );

  useEffect(() => {
    if (selectAllOnFocus && autoFocus) {
      ref?.current?.select();
    }
  }, [autoFocus, selectAllOnFocus]);

  const onValueChange: OnNumberFormatValueChange = useCallback(
    (values) => {
      onChange(values.floatValue);
    },
    [onChange]
  );

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      helperText={helperText}
      hideFormControls={hideFormControls}
      isPrimaryField={isPrimaryField}
      isVisible={isVisible}
      sx={wrapperSx}
    >
      <NumberFormatComponent
        ref={ref}
        name={name}
        onBlur={onBlur}
        onFocus={onInputFocus}
        onValueChange={onValueChange}
        value={value || initialValue}
        style={style}
        sx={sx}
        type={format}
        decimalScale={decimalScale}
        thousandSeparator={thousandSeparator}
        textAlign={textAlign}
        fixedDecimalScale={fixedDecimalScale}
        allowLeadingZeros={allowLeadingZeros}
        allowNegative={allowNegative}
        decimalSeparator={decimalSeparator}
        suffix={suffix}
        prefix={prefix}
        thousandsGroupStyle={thousandsGroupStyle}
        allowedDecimalSeparators={allowedDecimalSeparators}
        placeholder={placeholder}
      />
    </FieldWrapper>
  );
};
