import clsx from 'clsx';
import { useFormControl } from './form-control';
import * as styles from './css/form-label.css';
import { textEllipsis } from '../../system/styles.css';
import { resolveRequiredFieldIndicatorStyle } from './required-field-indicator-style';
import { BoxProps, Box } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

export type FormLabelProps = {
  isDisabled?: boolean;
  children?: React.ReactNode;
  htmlFor?: string;
  hasAsterisk?: boolean;
  /**
   * Optionally override inner CSS styles / theming
   */
  overrideStyles?: React.CSSProperties;
} & BoxProps;

export const FormLabel = polymorphicComponent<'label', FormLabelProps>((props, ref) => {
  const {
    as = 'label',
    children,
    title,
    sx = {},
    className,
    htmlFor,
    id,
    style = {},
    overrideStyles,
    hasAsterisk,
    ...rest
  } = props;

  const {
    clipFormLabels,
    id: _id,
    isDisabled,
    isRequired,
    labelId,
    requiredFieldIndicatorStyle,
    size,
    variant
  } = useFormControl(rest);

  const isFeature = variant === 'feature';

  const showRequiredAsterisk = ((): boolean => {
    if (hasAsterisk) return true;
    if (!isRequired) return false;
    switch (resolveRequiredFieldIndicatorStyle(requiredFieldIndicatorStyle)) {
      case 'asterisk':
        return true;
      case 'none':
        return false;
    }
  })();

  const classes = clsx(
    styles.formLabelRecipe({ size, variant }),
    isDisabled && styles.formLabelDisabled,
    showRequiredAsterisk ? styles.formLabelRequiredAsterisk : undefined,
    clipFormLabels && textEllipsis,
    className
  );

  if (isFeature) {
    return (
      <Box
        {...rest}
        as={as}
        ref={ref}
        className={classes}
        sx={{
          ...sx
        }}
        style={{
          ...style,
          ...overrideStyles
        }}
        htmlFor={htmlFor || _id}
        id={id || labelId}
        title={title}
        data-title={title}
      />
    );
  }

  return (
    <Box
      {...rest}
      as={as}
      ref={ref}
      className={classes}
      sx={{
        ...sx
      }}
      style={{
        ...overrideStyles
      }}
      htmlFor={htmlFor || _id}
      id={id || labelId}
    >
      {children}
    </Box>
  );
});

if (__DEV__) {
  FormLabel.displayName = 'FormLabel';
}
