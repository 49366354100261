import { useMemo } from 'react';
import clsx from 'clsx';
import * as styles from './css/progress-bar.css';
import radii from '../../theme/foundations/radius';
import { FormatPct, FormatLargeNumber } from '@oms/shared/util';
import { ProgressBarProps, RatioTextRenderer } from './progress-bar.types';
import { Tooltip } from '../tooltip/tooltip';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

/**
 * By default, render NUMERATOR / DENOMINATOR on one line, then formatted % value below
 */
const DefaultOutputTextRenderer: RatioTextRenderer = ({ numerator, denominator }) => {
  const { formattedStr, pctStr } = useMemo(() => {
    const result = {
      formattedStr: '-',
      pctVal: 0,
      pctStr: FormatPct(0, 0, true, true, true)
    };

    const _numeratorStr: string = FormatLargeNumber(numerator, 0, true);
    const _denominatorStr: string = FormatLargeNumber(denominator, 0, true);
    const _formattedStr = `${_numeratorStr} / ${_denominatorStr}`;
    const _pctVal: number = denominator !== undefined && denominator !== 0 ? numerator / denominator : 0;
    const _pctStr: string = FormatPct(_pctVal * 100, 0, true, true, true);

    result.formattedStr = _formattedStr;
    result.pctVal = _pctVal;
    result.pctStr = _pctStr;

    return result;
  }, [numerator, denominator]);

  return (
    <div className={clsx(styles.overlayText)} id="default-adv-text-renderer">
      <Tooltip label={formattedStr}>{pctStr}</Tooltip>
    </div>
  );
};

/**
 * Inline progress bar. To use in ag-grid, use ProgressRenderer.
 */
export const ProgressBar = polymorphicComponent<'div', ProgressBarProps>((props: ProgressBarProps, ref) => {
  if (props && props.type === 'advanced') {
    // case: advanced renderer
    const pctVal: number =
      props.denominator !== undefined && props.denominator !== 0
        ? (props.numerator / props.denominator) * 100
        : 0;

    if (props.hideIfZero && props.denominator !== undefined && props.denominator === 0) {
      // case: 0
      return <div>&nbsp;</div>;
    } else {
      // case: valid values

      const OutputTextRenderer = props.outputTextRenderer
        ? props.outputTextRenderer
        : DefaultOutputTextRenderer;

      return (
        <div
          ref={ref}
          data-id="denominator-bar"
          className={clsx({
            [styles.denominatorBar]: true
          })}
        >
          <div
            data-id="numerator-bar"
            className={clsx({
              [styles.numeratorBar]: true,
              [styles.numeratorBarComplete]: pctVal === 100
            })}
            style={{
              width: `${pctVal}%`,
              borderRadius: pctVal === 100 ? radii.sm : `${radii.sm} ${radii.none} ${radii.none} ${radii.sm}`
            }}
          >
            &nbsp;
          </div>
          <OutputTextRenderer numerator={props.numerator} denominator={props.denominator} />
        </div>
      );
    }
  } else if (props && props.type === 'simple_pct') {
    // case: simple renderer
    if (props.hideIfZero && props.percentage !== undefined && props.percentage === 0) {
      // case: 0
      return <div ref={ref}>&nbsp;</div>;
    } else {
      // case: valid value
      const stringValue: string = FormatPct(props.percentage, 0, true, true, true);

      return (
        <div
          ref={ref}
          id="denominator-bar"
          className={clsx({
            [styles.denominatorBar]: true
          })}
        >
          <div
            id="numerator-bar"
            className={clsx({
              [styles.numeratorBar]: true,
              [styles.numeratorBarComplete]: stringValue === '100%'
            })}
            style={{
              width: stringValue,
              borderRadius:
                stringValue === '100%' ? radii.sm : `${radii.sm} ${radii.none} ${radii.none} ${radii.sm}`
            }}
          >
            &nbsp;
          </div>
          <div className={clsx(styles.overlayText)}>{stringValue}</div>
        </div>
      );
    }
  } else {
    // case: error
    return <div ref={ref}>Error</div>;
  }
});

if (__DEV__) {
  ProgressBar.displayName = 'ProgressBar';
}
