import React, { createContext, useContext } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import type {
  DialogProps as DialogPrimitiveProps,
  DialogContentProps as DialogPrimitiveContentProps,
  DialogTriggerProps as DialogPrimitiveTriggerProps,
  DialogCloseProps as DialogPrimitiveCloseProps
} from '@radix-ui/react-dialog';
import { type BoxProps, Box } from '../../system/components/box/box';
import { CloseButton } from '../button/close-button';
import { Flex, type FlexProps } from '../../layout/flex/flex';
import { ConditionalWrapper } from '../../system/components/conditional-wrapper/conditional-wrapper';
import clsx from 'clsx';
import * as styles from './dialog.css';
import type { DialogContentSizes } from './dialog.css';
import { Heading, HeadingProps } from '../../layout/heading/heading';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { __DEV__ } from '../../system/utils/assertion';

export const DialogRoot = DialogPrimitive.Root;
export type DialogRootProps = DialogPrimitiveProps;
export const DialogPortal = DialogPrimitive.DialogPortal;
export const DialogOverlay = DialogPrimitive.Overlay;

export const DialogTrigger = React.forwardRef<HTMLButtonElement, DialogPrimitiveTriggerProps>(
  ({ asChild = true, children, ...rest }, ref) => (
    <DialogPrimitive.Trigger ref={ref} asChild={asChild} {...rest}>
      {children}
    </DialogPrimitive.Trigger>
  )
);

export const DialogClose = React.forwardRef<HTMLButtonElement, DialogPrimitiveCloseProps>(
  ({ asChild = true, children, ...rest }, ref) => (
    <DialogPrimitive.Close ref={ref} asChild={asChild} {...rest}>
      {children}
    </DialogPrimitive.Close>
  )
);

/**
 * Dialog content context
 * - Used to pass size and whether we have a close button to child components
 */
type DialogContentContext = {
  size: DialogContentSizes;
  hasClose?: boolean;
};

export const DialogContentContext = createContext<DialogContentContext>({
  size: 'md'
});

export const useDialogContext = () => useContext<DialogContentContext>(DialogContentContext);

/**
 * DialogContent
 */
export type DialogContentProps = {
  hasClose?: boolean;
  hasOverlay?: boolean;
  stayMounted?: boolean;
  isOverlayOpen?: boolean;
  size?: DialogContentSizes;
} & DialogPrimitiveContentProps &
  FlexProps;

export const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(
  (
    {
      stayMounted = false,
      children,
      sx = {},
      isOverlayOpen,
      hasClose = true,
      hasOverlay = true,
      size = 'md',
      className,
      ...props
    },
    ref
  ) => {
    const { p, padding } = sx;
    const pad = p !== undefined ? p : padding !== undefined ? padding : 0;

    return (
      <DialogContentContext.Provider value={{ size, hasClose }}>
        <ConditionalWrapper
          condition={hasOverlay}
          // eslint-disable-next-line react/jsx-no-bind
          wrapper={(c) => (
            <DialogPrimitive.DialogPortal forceMount={stayMounted ? true : undefined}>
              {stayMounted ? (
                <div data-state={isOverlayOpen ? 'open' : 'closed'} className={styles.dialogOverlay} />
              ) : (
                <DialogOverlay className={styles.dialogOverlay} />
              )}
              {c}
            </DialogPrimitive.DialogPortal>
          )}
        >
          <DialogPrimitive.Content
            asChild={true}
            forceMount={stayMounted ? true : undefined}
            ref={ref}
            {...props}
          >
            <Flex
              sx={{ p: pad, ...sx }}
              className={clsx(styles.dialogContent, styles.dialogContentSizes[size], className)}
            >
              {children}
              {hasClose && (
                <DialogClose asChild>
                  <CloseButton
                    size="sm"
                    style={{
                      position: 'absolute',
                      top: '0.5rem',
                      right: '0.5rem'
                    }}
                  />
                </DialogClose>
              )}
            </Flex>
          </DialogPrimitive.Content>
        </ConditionalWrapper>
      </DialogContentContext.Provider>
    );
  }
);

export type DialogHeaderProps = {} & BoxProps;
export const DialogHeader = polymorphicComponent<'div', DialogHeaderProps>((props, ref) => {
  const { children, sx = {}, ...rest } = props;

  return (
    <Box
      ref={ref}
      className={clsx(styles.dialogHeader)}
      sx={{
        ...sx
      }}
      {...rest}
    >
      {children}
    </Box>
  );
});

export type DialogTitleProps = {} & HeadingProps;
export const DialogTitle = polymorphicComponent<'div', DialogTitleProps>((props, ref) => {
  const { children, sx = {}, ...rest } = props;

  return (
    <Heading
      type="mediumB"
      ref={ref}
      sx={{
        ...sx
      }}
      className={clsx(styles.dialogTitle)}
      {...rest}
    >
      {children}
    </Heading>
  );
});

export type DialogDescriptionProps = {} & BoxProps;
export const DialogDescription = polymorphicComponent<'div', DialogDescriptionProps>((props, ref) => {
  const { children, sx = {}, ...rest } = props;

  return (
    <Box
      ref={ref}
      className={styles.dialogText}
      sx={{
        ...sx
      }}
      {...rest}
    >
      {children}
    </Box>
  );
});

export type DialogBodyProps = {} & BoxProps;
export const DialogBody = polymorphicComponent<'div', DialogBodyProps>((props, ref) => {
  const { children, sx = {}, ...rest } = props;

  return (
    <Box ref={ref} sx={{ flexGrow: 1, ...sx }} className={clsx(styles.dialogBody)} {...rest}>
      {children}
    </Box>
  );
});

export type DialogFooterProps = {} & FlexProps;

export const DialogFooter = polymorphicComponent<'footer', DialogFooterProps>((props, ref) => {
  const { children, sx = {}, ...rest } = props;
  const { justifyContent = 'flex-start' } = sx;

  return (
    <Flex
      ref={ref}
      className={clsx(styles.dialogFooter)}
      sx={{
        ...sx,
        justifyContent
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
});

if (__DEV__) {
  DialogTrigger.displayName = 'DialogTrigger';
  DialogContent.displayName = 'DialogContent';
  DialogHeader.displayName = 'DialogHeader';
  DialogFooter.displayName = 'DialogFooter';
  DialogBody.displayName = 'DialogBody';
  DialogTitle.displayName = 'DialogTitle';
  DialogDescription.displayName = 'DialogDescription';
}
