import {
  useClosestAppFlexLayoutActorProps,
  useCurrentAppWidgetActorProps
} from '@app/common/workspace/workspace.hooks';
import type { AnyRecord } from '@valstro/workspace';
import { useMemo } from 'react';

/**
 * A hook that returns the props of the closest app flex layout actor or the current app widget actor.
 * If both are undefined, an error is thrown.
 *
 * @template TProps - The type of the props, extending AnyRecord.
 * @returns {TProps} The props of the closest app flex layout actor or the current app widget actor.
 * @throws {Error} If both flexProps and widgetProps are undefined.
 * @throws {Error} If the resolved props do not match the expected TProps type.
 */
export function useScopedProps<TProps extends AnyRecord = AnyRecord>() {
  const [flexProps] = useClosestAppFlexLayoutActorProps<TProps>();
  const [widgetProps] = useCurrentAppWidgetActorProps<TProps>();

  return useMemo(() => {
    if (!flexProps && !widgetProps) {
      throw new Error('Both flexProps and widgetProps are undefined');
    }

    const props: TProps = flexProps || widgetProps;

    if (typeof props !== 'object' || props === null) {
      throw new Error('Props do not match the expected TProps type');
    }

    return props;
  }, [flexProps, widgetProps]);
}
