export interface LabelFormatComponents {
  code?: string;
  description?: string;
}

export type LabelFormattingFunction = (components: LabelFormatComponents) => string;

export const codeOnlyLabelFormatter: LabelFormattingFunction = ({ code, description }) =>
  code ?? description ?? '';

export const defaultLabelFormatter: LabelFormattingFunction = ({ code, description }) => {
  if (code && description) return [code, description].join(' - ');
  return description ?? code ?? '';
};

export const parentheticalLabelFormatter: LabelFormattingFunction = ({ code, description }) => {
  if (code && description) return `${description} (${code})`;
  return description ?? code ?? '';
};

export interface LabelFormatOptions {
  formatter?: LabelFormattingFunction;
  fallback?: string;
}

/**
 * Formats a label composed from multiple components
 * @param components - The parts making up the label including a code and longer description
 * @param options - Options object to override defaults
 * @returns The formatted string to use as a label
 */
export const formatLabel = (components: LabelFormatComponents, options?: LabelFormatOptions): string => {
  const { formatter = defaultLabelFormatter, fallback } = options ?? {};
  const formatted = formatter(components);
  if (formatted.length === 0 && fallback) return fallback;
  return formatted;
};
