import { WebSocketSubject } from 'rxjs/webSocket';
import { retry, timer } from 'rxjs';
import type { Observable } from 'rxjs';
import type { WebSocket } from 'ws';
import { Logger } from '@oms/shared/util';
import type { SipData } from '@oms/shared/regnms';
import type { Level2IntegrationEvent } from '../trading/montage/montage.types';
import type { FactSetQuery } from './level2-websocket.types';
import { LEVEL2_WEBSOCKET_URL, SIPQUOTES_WEBSOCKET_URL } from './level2-websocket.constants';

class TypedWebSocketSubject<Event, OnSubscribe, OnUnsubscribe = OnSubscribe> extends WebSocketSubject<Event> {
  constructor(urlConfigOrSource: ConstructorParameters<typeof WebSocketSubject<Event>>[0]) {
    super(urlConfigOrSource);
  }

  public override multiplex(
    onSubscribe: () => OnSubscribe,
    onUnsubscribe: () => OnUnsubscribe,
    filterEvents: (event: Event) => boolean
  ): Observable<Event> {
    return super.multiplex(onSubscribe, onUnsubscribe, filterEvents);
  }
}

type Level2EventTypes = {
  standard: Level2IntegrationEvent;
  sip: SipData;
};

type Level2OnSubscribeTypes = {
  standard: FactSetQuery[];
  sip: string;
};

type Level2OnUnsubscribeTypes = {
  standard: FactSetQuery[];
  sip: string;
};

export type Level2DataType = keyof Level2EventTypes;

const LEVEL2_URL_MAP: Record<Level2DataType, string> = {
  standard: LEVEL2_WEBSOCKET_URL,
  sip: SIPQUOTES_WEBSOCKET_URL
} as const;

const LEVEL2_LOG_LABEL_MAP: Record<Level2DataType, string> = {
  standard: 'Level2WebSocketSubject.Standard',
  sip: 'Level2WebSocketSubject.SIP'
} as const;

interface Level2WebSocketSubjectOptions {
  useMockWebSocket?: boolean;
}

export const getMockWebSocketUrl = (type: Level2DataType): string => `${LEVEL2_URL_MAP[type]}/mock`;

export class Level2WebSocketSubject<Type extends Level2DataType> extends TypedWebSocketSubject<
  Level2EventTypes[Type],
  Level2OnSubscribeTypes[Type],
  Level2OnUnsubscribeTypes[Type]
> {
  protected logger: Logger;

  public readonly type: Type;
  public readonly url: string;

  public constructor(type: Type, options?: Level2WebSocketSubjectOptions) {
    const logger = Logger.labeled(LEVEL2_LOG_LABEL_MAP[type]);
    const url = options?.useMockWebSocket ? getMockWebSocketUrl(type) : LEVEL2_URL_MAP[type];
    super({
      url,
      openObserver: {
        next: (event) => {
          const url = (event.target as WebSocket | null)?.url;
          logger.debug(`📖 Opened websocket at "${url ?? 'unknown URL'}":`, event);
        },
        complete: () => {
          logger.debug('✔️ Websocket open complete!');
        },
        error: (e) => {
          logger.error('❌ Error on websocket open:', (e as Error).message);
        }
      },
      closeObserver: {
        next: (event) => {
          const url = (event.target as WebSocket | null)?.url;
          logger.debug(`📕 Closed websocket at "${url ?? 'unknown URL'}":`, event);
        },
        complete: () => {
          logger.debug('✔️ Websocket close complete!');
        },
        error: (e) => {
          logger.error('❌ Error on websocket close:', e);
        }
      }
    });
    this.type = type;
    this.url = url;
    this.logger = logger;
  }

  public override multiplex(
    onSubscribe: () => Level2OnSubscribeTypes[Type],
    onUnsubscribe: () => Level2OnUnsubscribeTypes[Type],
    filterEvents: (event: Level2EventTypes[Type]) => boolean
  ): Observable<Level2EventTypes[Type]> {
    return super.multiplex(onSubscribe, onUnsubscribe, filterEvents).pipe(
      retry({
        delay: (_error, retryIndex) => timer(Math.pow(2, retryIndex) * 1000)
      })
    );
  }
}
