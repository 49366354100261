import { FC, PropsWithChildren, useCallback, MouseEvent } from 'react';
import { IconButton } from '../button/icon-button';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { Box } from '../../system/components/box/box';

export interface OnCountChangeEvent extends MouseEvent<HTMLButtonElement> {
  countType: 'up' | 'down';
}

export interface IconCounterProps {
  onCountChange?: (e: OnCountChangeEvent) => void;
}

export const IconCounter: FC<PropsWithChildren<IconCounterProps>> = ({ onCountChange, children }) => {
  const onUpClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onCountChange?.({ ...e, countType: 'up' });
    },
    [onCountChange]
  );

  const onDownClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onCountChange?.({ ...e, countType: 'down' });
    },
    [onCountChange]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: 'full',
        justifyContent: 'space-between'
      }}
    >
      <IconButton
        aria-label="down"
        variant="secondary"
        size="sm"
        onClick={onDownClick}
        icon={<ChevronDownIcon />}
      />
      <Box
        sx={{
          backgroundColor: 'layout.level3',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        {children}
      </Box>
      <IconButton
        aria-label="up"
        variant="secondary"
        size="sm"
        onClick={onUpClick}
        icon={<ChevronUpIcon />}
      />
    </Box>
  );
};
