import type { OrderSizeRow, OrderSettingsForm } from './order-settings.types';
import {
  createPriceFrom,
  createPriceTo,
  createOrderSize
} from '@app/forms/common/fields/price-range/price-range.fields';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { FieldContract, FieldDefinition, FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import { VALIDATE_PRICE_TO_VALUE } from './validators/validate-price-to-value.validator';
import type { OrderSettingsProfile } from '@oms/generated/frontend';

const rowContract = FieldContract.create<OrderSizeRow, AdditionalFormFieldUnion>();

const rowId = rowContract.field('id', 'text-field').options({
  isVisible: false
});
export const priceFrom = createPriceFrom(rowContract, 'priceFrom');
export const priceTo = createPriceTo(rowContract, 'priceTo', {
  validate: [{ type: VALIDATE_PRICE_TO_VALUE }]
});
export const orderSize = createOrderSize(rowContract, 'orderSize', {
  validate: [{ type: 'required' }, { type: 'min-number-value', value: 1, message: 'Min 1' }]
});

const fieldArraySchema = FormContract.create<OrderSizeRow>()
  .fields({
    priceFrom: priceFrom,
    priceTo: priceTo,
    orderSize: orderSize,
    id: rowId
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.simpleGrid('rows-wrapper', 3, [f.id, f.priceFrom, f.priceTo, f.orderSize], {
        isInArray: 'rows'
      })
    ]
  }));

export type InnerRowValues = InferFormValuesFromFormContract<typeof fieldArraySchema>;

const profileContract = FieldContract.create<OrderSettingsProfile>();
const rows = profileContract.field('rows', 'field-array').options({
  form: {
    fields: fieldArraySchema.build().schema.fields
  },
  button: {
    buttonText: 'Add order size',
    name: 'addOrderSize'
  }
});

const profileId = profileContract.field('id', 'text-field').options({
  isVisible: false
});
const description = profileContract.field('description', 'text-field').options({
  label: 'Description',
  validate: [{ type: 'required' }]
});

const profileSchema = FormContract.create<OrderSettingsProfile>()
  .fields({
    id: profileId,
    description: description,
    rows: rows
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.simpleGrid('profile-wrapper', 3, [f.id, f.description], { isInArray: 'orderSettings' }),
      FieldDefinition.simpleGrid('rows-wrapper', 1, [f.rows], { isInArray: 'orderSettings' })
    ]
  }));

export type InnerProfileFormValues = InferFormValuesFromFormContract<typeof profileSchema>;

const orderSettingsContract = FieldContract.create<OrderSettingsForm>();

export const orderSettingsField = orderSettingsContract.field('orderSettings', 'field-array').options({
  style: {
    height: 'auto',
    maxHeight: '900px',
    flexGrow: 'initial'
  },
  form: {
    fields: profileSchema.build().schema.fields
  },
  button: {
    buttonText: 'Add order size profile',
    name: 'addOrderSizeProfile'
  }
});

export const orderSettings = FormContract.create<OrderSettingsForm>().fields({
  orderSettings: orderSettingsField
});

export type InnerOrderSettingsFormValues = InferFormValuesFromFormContract<typeof orderSettings>;
