import { COMMON_PLATFORM_NAME, ActorSchemaBuilder, ActorOperationOptions } from '../../../core';
import { getCurrentBrowserMonitorIndex } from '../../../core/platform.browser.util';
import {
  WindowContext,
  WindowOperations,
  COMMON_ACTOR_TYPE,
  WinSizeOptions,
  UserAttentionType,
  WinPositionOptions,
  CommonWindowActorSchemaOptions,
  WindowResizeOrigin,
  WindowResizeDir,
  resizeAroundOrigin
} from '../../common';
import { BROWSER_TAB_WINDOW_ACTOR_NAME } from './tab.window.actor.browser';

export const BROWSER_MODAL_WINDOW_ACTOR_NAME = 'browser-modal-window';

export const BrowserModalWindowActorSchemaBuilder = ActorSchemaBuilder.create<
  WindowContext,
  WindowOperations,
  any,
  CommonWindowActorSchemaOptions
>({
  name: BROWSER_MODAL_WINDOW_ACTOR_NAME,
  type: COMMON_ACTOR_TYPE.WINDOW,
  supportedPlatforms: [COMMON_PLATFORM_NAME.BROWSER],
  isWindowRootable: false,
  selectStrategy: (ctx) => {
    const windowContext = ctx.createActorOptionsOrDef?.context || ({} as WindowContext);

    if (windowContext['isBrowserTab'] === true) {
      return false;
    }

    return true;
  },
  initialContext: async ({ initialContext, schema }) => {
    const defaultContext = schema.meta?.defaultContext || {};

    const defaultSize = {
      width: defaultContext.width ? defaultContext.width : 600,
      height: defaultContext.height ? defaultContext.height : 400
    } as const;

    const width = initialContext.width ?? defaultSize.width;
    const height = initialContext.width ?? defaultSize.width;

    const final: WindowContext = {
      title: document.title,
      x: window.innerWidth / 2 - width / 2,
      y: window.innerHeight / 2 - height / 2,
      width: defaultSize.width,
      height: defaultSize.height,
      isMaximized: false,
      isMinimized: false,
      isVisible: true,
      isPinnable: false,
      isFocused: false,
      isDecorated: true,
      isFullscreen: false,
      scaleFactor: 1,
      skipTaskbar: false,
      alwaysOnTop: false,
      isPinned: false,
      currentMonitorIndex: await getCurrentBrowserMonitorIndex(),
      requestingUserAttention: null,
      isClosable: true,
      isMaximizable: true,
      isMinimizable: true,
      isResizable: true,
      meta: {},
      ...defaultContext,
      ...(initialContext || {}),
      initiallyCentered: false,
      initiallyUseLogicalPixels: false
    };

    const { x, y } = ensureInsideWindow(final.x, final.y, final.width, final.height);

    final.x = x;
    final.y = y;

    return final;
  },
  sync: ({ isFocus, operations }) => {
    if (isFocus) {
      operations.focus().catch(console.error);
    }
  },
  operations: ({ updateContext, destroy, getContext }) => ({
    setTitle: async (title: string, ops?: ActorOperationOptions) => {
      await updateContext({ title }, ops);
    },
    setPinned: async (isPinned: boolean, ops?: ActorOperationOptions) => {
      await updateContext({ isPinned }, ops);
    },
    // TODO: Unify pixel conversion
    setPosition: async ({ x: _x, y: _y }: WinPositionOptions, ops?: ActorOperationOptions) => {
      const { width, height } = getContext();
      const { x, y } = ensureInsideWindow(_x, _y, width, height);
      await updateContext(
        {
          x,
          y,
          isMaximized: false,
          isMinimized: false,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    setSize: async ({ width, height }: WinSizeOptions, ops?: ActorOperationOptions) => {
      const { x: _x, y: _y } = getContext();
      const { x, y } = ensureInsideWindow(_x, _y, width, height);
      await updateContext(
        {
          width,
          height,
          x,
          y,
          isMaximized: false,
          isMinimized: false,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    setSizeAndPosition: async (
      size: WinSizeOptions,
      position: WinPositionOptions,
      ops?: ActorOperationOptions
    ) => {
      const { x, y } = ensureInsideWindow(position.x, position.y, size.width, size.height);
      await updateContext(
        {
          width: size.width,
          height: size.height,
          x,
          y,
          isMaximized: false,
          isMinimized: false,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    resizeAroundOrigin: async (
      newSize: WinSizeOptions,
      origin: WindowResizeOrigin,
      direction?: WindowResizeDir,
      ops?: ActorOperationOptions
    ) => {
      const { width: currentWidth, height: currentHeight } = getContext();
      const { x: currentX, y: currentY } = getContext();
      const currentPos = { x: currentX || 0, y: currentY || 0 };
      const currentSize = { width: currentWidth, height: currentHeight };

      const ctx = resizeAroundOrigin(currentPos, currentSize, newSize, origin, direction);

      await updateContext(ctx, ops);
    },
    center: async (ops?: ActorOperationOptions) => {
      const { width, height } = getContext();
      const { x, y } = ensureInsideWindow(
        window.innerWidth / 2 - width / 2,
        window.innerHeight / 2 - height / 2,
        width,
        height
      );

      await updateContext(
        {
          x,
          y,
          isMaximized: false,
          isMinimized: false,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    close: async () => {
      const ctx = getContext();
      if (ctx.isClosable === false || ctx.isPinned === true) {
        return;
      }
      await destroy();
    },
    destroy: async () => {
      await destroy();
    },
    centerInActiveMonitor: async (ops?: ActorOperationOptions) => {
      const { width, height } = getContext();
      const { x, y } = ensureInsideWindow(
        window.innerWidth / 2 - width / 2,
        window.innerHeight / 2 - height / 2,
        width,
        height
      );

      await updateContext(
        {
          x,
          y,
          isMaximized: false,
          isMinimized: false,
          currentMonitorIndex: await getCurrentBrowserMonitorIndex()
        },
        ops
      );
    },
    focus: async (ops?: ActorOperationOptions) => {
      await updateContext(
        {
          isFocused: true
        },
        ops
      );
    },
    hide: async (ops?: ActorOperationOptions) => {
      await updateContext(
        {
          isVisible: false
        },
        ops
      );
    },
    toggleMaximize: async (ops?: ActorOperationOptions) => {
      await updateContext(
        {
          isMaximized: !getContext().isMaximized,
          isMinimized: false
        },
        ops
      );
    },
    maximize: async (ops?: ActorOperationOptions) => {
      await updateContext(
        {
          isMaximized: true,
          isMinimized: false
        },
        ops
      );
    },
    minimize: async (ops?: ActorOperationOptions) => {
      await updateContext(
        {
          isMaximized: false,
          isFullscreen: false,
          isMinimized: true
        },
        ops
      );
    },
    unmaximize: async (ops?: ActorOperationOptions) => {
      await updateContext(
        {
          isMaximized: false
        },
        ops
      );
    },
    unminimize: async (ops?: ActorOperationOptions) => {
      await updateContext(
        {
          isMinimized: false
        },
        ops
      );
    },
    show: async (ops?: ActorOperationOptions) => {
      await updateContext({ isVisible: true }, ops);
    },
    setAlwaysOnTop: async (alwaysOnTop: boolean, ops?: ActorOperationOptions) => {
      await updateContext({ alwaysOnTop }, ops);
    },
    setDecorated: async (isDecorated: boolean) => {
      await updateContext({ isDecorated });
    },
    setFullscreen: async (isFullscreen: boolean, ops?: ActorOperationOptions) => {
      await updateContext({ isFullscreen }, ops);
    },
    setMaximizable: async (isMaximizable: boolean) => {
      await updateContext({ isMaximizable });
    },
    setMinimizable: async (isMinimizable: boolean) => {
      await updateContext({ isMinimizable });
    },
    setResizable: async (isResizable: boolean) => {
      await updateContext({ isResizable });
    },
    setSkipTaskbar: async (skipTaskbar: boolean) => {
      await updateContext({ skipTaskbar });
    },
    setMaxSize: async ({ width, height }: WinSizeOptions) => {
      await updateContext({
        maxWidth: width,
        maxHeight: height
      });
    },
    setMinSize: async ({ width, height }: WinSizeOptions) => {
      await updateContext({
        maxWidth: width,
        maxHeight: height
      });
    },
    requestUserAttention: async (type: UserAttentionType) => {
      await updateContext({ requestingUserAttention: type });
    },
    focusAll: async () => {
      // Not supported
    },
    setMeta: async (meta) => {
      await updateContext({ meta });
    },
    updateMeta: async (meta) => {
      const nextMeta = { ...getContext().meta, ...meta };
      await updateContext({ meta: nextMeta });
    },
    getInnerPosition: async () => {
      return {
        x: 0,
        y: 0,
        scaleFactor: 1
      };
    }
  }),
  events: ({ workspace, getDefinition, updateContext, operations }) => {
    const currentId = getDefinition().id;

    /**
     * Focus on created
     */
    const unsubscribeOnCreated = workspace.listen('actor/created', (e) => {
      if (e.id === currentId) {
        operations.focus().catch(console.error);
      }
    });
    /**
     * Unfocus on other modal focus when .focus() is called
     * So it will be on top
     */
    const unsubscribeOtherModalFocus = workspace.listen('actor/operation/performed', async (op) => {
      if (
        (op.operationName === 'focus' ||
          op.operationName === 'unminimize' ||
          op.operationName === 'toggleMaximize') &&
        currentId !== op.id
      ) {
        await updateContext({ isFocused: false });
      }
    });
    return () => {
      unsubscribeOnCreated();
      unsubscribeOtherModalFocus();
    };
  },
  onRegistrationFinished: ({ actorRegistry }) => {
    if (!actorRegistry.hasActorByName(BROWSER_TAB_WINDOW_ACTOR_NAME)) {
      throw new Error(
        `${BROWSER_MODAL_WINDOW_ACTOR_NAME} requires ${BROWSER_TAB_WINDOW_ACTOR_NAME} to be registered`
      );
    }
  }
});

export type BrowserModalWindowActorSchema = typeof BrowserModalWindowActorSchemaBuilder.schema;

/**
 * Ensure a modal is always inside the window:
 * - The top is never off the top of the screen
 * - The left is never more than 50% off the left of the screen
 * - The right is never more than 50% off the right of the screen
 * - The bottom is never off more than 50% off the bottom of the screen
 *
 * @param x
 * @param y
 * @param width
 * @param height
 * @returns { x: number, y: number }
 */
function ensureInsideWindow(
  x?: number,
  y?: number,
  width?: number,
  height?: number
): { x?: number; y?: number } {
  if (!x || !y || !width || !height) {
    return { x, y };
  }

  const { innerWidth, innerHeight } = window;
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const maxX = innerWidth - halfWidth;
  const maxY = innerHeight - halfHeight;
  const minX = 0 - halfWidth;
  const minY = 0;

  if (x > maxX) {
    x = maxX;
  }

  if (x < minX) {
    x = minX;
  }

  if (y > maxY) {
    y = maxY;
  }

  if (y < minY) {
    y = minY;
  }

  return { x, y };
}
