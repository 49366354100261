import { useCallback, useState, useLayoutEffect, useMemo } from 'react';
import type { Workspace } from '@valstro/workspace';
import { Actor, PROCESS_ID } from '@valstro/workspace';
import { useCurrentActor, useLeaderWindow, useWorkspace } from '@valstro/workspace-react';
import { useCurrentAppWindow } from '@app/common/workspace/workspace.hooks';
import type {
  RegistryDefinitionComponent,
  RegistryDefinitionComponentWithCompKey,
  RegistryDefinitionDialogWithCompKey,
  RegistryDefinitionLayout,
  RegistryDefinitionLayoutWithComp,
  RegistryDefinitionDialog,
  RegistryDefinitionFormWithFormKey
} from '@app/app-config/registry.config';
import { Component } from '@app/common/registry/component.open';
import { Layout } from '@app/common/registry/layout.open';
import { Form } from '@app/common/registry/form.open';
import type {
  ComponentDictEnumKey,
  ComponentDictTypeMap,
  DialogDictEnumKey,
  DialogDictTypeMap,
  LayoutDictEnumKey,
  LayoutDictTypeMap
} from './common';
import {
  COMPONENT_DICT_ENUM,
  COMPONENT_DICTIONARY,
  DIALOG_DICT_ENUM,
  DIALOG_DICTIONARY,
  LAYOUT_DICT_ENUM,
  LAYOUT_DICTIONARY,
  FORM_DICTIONARY
} from './common';
import type { FORM_MAP } from './mappers';
import { FormBuilderRenderer, useInterpretFormBuilderId } from '@oms/frontend-foundation';
import type { FormRendererProps } from '@oms/frontend-foundation';
import type {
  FoundationWorkspace,
  AnyFormBuilder,
  InferInfoFromFormBuilder as InferB
} from '@oms/frontend-foundation';
import { DialogService, useDialogService, DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import type { OpenDialogApi, DialogEventType } from '@app/common/registry/dialog.open';
import { getDialogId } from '@app/common/registry/common.open';

export type OpenFrom = 'leader' | 'currentWindow' | 'current' | Actor;

export type PartialComponentDefinition<T> = Partial<
  Pick<RegistryDefinitionComponent, 'title' | 'windowOptions' | 'windowId'>
> & {
  componentProps?: T;
};

export const openComponentFromDictionary = <K extends ComponentDictEnumKey>(
  key: K,
  parentActorOrId: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap[K]> = {}
): Promise<Component<ComponentDictTypeMap[K]>> => {
  const definition = COMPONENT_DICTIONARY[key];

  if (definition === undefined) {
    throw new Error('Component definition not found for key: ' + key);
  }

  const enrichedDefinition = {
    ...definition,
    ...overrides,
    windowOptions: {
      ...definition.windowOptions,
      ...overrides.windowOptions
    },
    componentKey: key,
    type: 'component'
  } as RegistryDefinitionComponentWithCompKey<ComponentDictTypeMap[K]>;

  return Component.open<ComponentDictTypeMap[K]>(enrichedDefinition, parentActorOrId);
};

export const useOpenComponentFromDictionary = <K extends ComponentDictEnumKey>(): ((
  key: K,
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap[K]>
) => Promise<Component<ComponentDictTypeMap[K]>>) => {
  const leaderWindow = useLeaderWindow();
  const currentWindow = useCurrentAppWindow();
  const currentActor = useCurrentActor();
  return useCallback(
    (
      key: K,
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap[K]> = {}
    ) => {
      const parentActor =
        from instanceof Actor
          ? from
          : from === 'current'
            ? currentActor
            : from === 'currentWindow'
              ? currentWindow
              : leaderWindow;

      return openComponentFromDictionary<K>(key, parentActor, overrides);
    },
    [leaderWindow, currentWindow, currentActor]
  );
};

export const openExampleComponent = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['EXAMPLE_COMPONENT']> = {}
): Promise<Component<ComponentDictTypeMap['EXAMPLE_COMPONENT']>> => {
  return openComponentFromDictionary<'EXAMPLE_COMPONENT'>(
    COMPONENT_DICT_ENUM.EXAMPLE_COMPONENT,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenExampleComponent = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['EXAMPLE_COMPONENT']>
) => Promise<Component<ComponentDictTypeMap['EXAMPLE_COMPONENT']>>) => {
  const open = useOpenComponentFromDictionary<'EXAMPLE_COMPONENT'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['EXAMPLE_COMPONENT']> = {}
    ) => {
      return open('EXAMPLE_COMPONENT', from, overrides);
    },
    [open]
  );
};

export const openTimeAndSales = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['TIME_AND_SALES']> = {}
): Promise<Component<ComponentDictTypeMap['TIME_AND_SALES']>> => {
  return openComponentFromDictionary<'TIME_AND_SALES'>(
    COMPONENT_DICT_ENUM.TIME_AND_SALES,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenTimeAndSales = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['TIME_AND_SALES']>
) => Promise<Component<ComponentDictTypeMap['TIME_AND_SALES']>>) => {
  const open = useOpenComponentFromDictionary<'TIME_AND_SALES'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['TIME_AND_SALES']> = {}
    ) => {
      return open('TIME_AND_SALES', from, overrides);
    },
    [open]
  );
};

export const openCurrenciesGrid = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['CURRENCIES_GRID']> = {}
): Promise<Component<ComponentDictTypeMap['CURRENCIES_GRID']>> => {
  return openComponentFromDictionary<'CURRENCIES_GRID'>(
    COMPONENT_DICT_ENUM.CURRENCIES_GRID,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenCurrenciesGrid = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['CURRENCIES_GRID']>
) => Promise<Component<ComponentDictTypeMap['CURRENCIES_GRID']>>) => {
  const open = useOpenComponentFromDictionary<'CURRENCIES_GRID'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['CURRENCIES_GRID']> = {}
    ) => {
      return open('CURRENCIES_GRID', from, overrides);
    },
    [open]
  );
};

export const openExchangesGrid = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['EXCHANGES_GRID']> = {}
): Promise<Component<ComponentDictTypeMap['EXCHANGES_GRID']>> => {
  return openComponentFromDictionary<'EXCHANGES_GRID'>(
    COMPONENT_DICT_ENUM.EXCHANGES_GRID,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenExchangesGrid = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['EXCHANGES_GRID']>
) => Promise<Component<ComponentDictTypeMap['EXCHANGES_GRID']>>) => {
  const open = useOpenComponentFromDictionary<'EXCHANGES_GRID'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['EXCHANGES_GRID']> = {}
    ) => {
      return open('EXCHANGES_GRID', from, overrides);
    },
    [open]
  );
};

export const openAboutValstro = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['ABOUT_VALSTRO']> = {}
): Promise<Component<ComponentDictTypeMap['ABOUT_VALSTRO']>> => {
  return openComponentFromDictionary<'ABOUT_VALSTRO'>(
    COMPONENT_DICT_ENUM.ABOUT_VALSTRO,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenAboutValstro = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['ABOUT_VALSTRO']>
) => Promise<Component<ComponentDictTypeMap['ABOUT_VALSTRO']>>) => {
  const open = useOpenComponentFromDictionary<'ABOUT_VALSTRO'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['ABOUT_VALSTRO']> = {}
    ) => {
      return open('ABOUT_VALSTRO', from, overrides);
    },
    [open]
  );
};

export const openSystemLauncher = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_LAUNCHER']> = {}
): Promise<Component<ComponentDictTypeMap['SYSTEM_LAUNCHER']>> => {
  return openComponentFromDictionary<'SYSTEM_LAUNCHER'>(
    COMPONENT_DICT_ENUM.SYSTEM_LAUNCHER,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenSystemLauncher = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_LAUNCHER']>
) => Promise<Component<ComponentDictTypeMap['SYSTEM_LAUNCHER']>>) => {
  const open = useOpenComponentFromDictionary<'SYSTEM_LAUNCHER'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_LAUNCHER']> = {}
    ) => {
      return open('SYSTEM_LAUNCHER', from, overrides);
    },
    [open]
  );
};

export const openSystemPlaceholder = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_PLACEHOLDER']> = {}
): Promise<Component<ComponentDictTypeMap['SYSTEM_PLACEHOLDER']>> => {
  return openComponentFromDictionary<'SYSTEM_PLACEHOLDER'>(
    COMPONENT_DICT_ENUM.SYSTEM_PLACEHOLDER,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenSystemPlaceholder = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_PLACEHOLDER']>
) => Promise<Component<ComponentDictTypeMap['SYSTEM_PLACEHOLDER']>>) => {
  const open = useOpenComponentFromDictionary<'SYSTEM_PLACEHOLDER'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_PLACEHOLDER']> = {}
    ) => {
      return open('SYSTEM_PLACEHOLDER', from, overrides);
    },
    [open]
  );
};

export const openSystemRemoteForm = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_REMOTE_FORM']> = {}
): Promise<Component<ComponentDictTypeMap['SYSTEM_REMOTE_FORM']>> => {
  return openComponentFromDictionary<'SYSTEM_REMOTE_FORM'>(
    COMPONENT_DICT_ENUM.SYSTEM_REMOTE_FORM,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenSystemRemoteForm = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_REMOTE_FORM']>
) => Promise<Component<ComponentDictTypeMap['SYSTEM_REMOTE_FORM']>>) => {
  const open = useOpenComponentFromDictionary<'SYSTEM_REMOTE_FORM'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_REMOTE_FORM']> = {}
    ) => {
      return open('SYSTEM_REMOTE_FORM', from, overrides);
    },
    [open]
  );
};

export const openSystemImportSnapshot = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_IMPORT_SNAPSHOT']> = {}
): Promise<Component<ComponentDictTypeMap['SYSTEM_IMPORT_SNAPSHOT']>> => {
  return openComponentFromDictionary<'SYSTEM_IMPORT_SNAPSHOT'>(
    COMPONENT_DICT_ENUM.SYSTEM_IMPORT_SNAPSHOT,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenSystemImportSnapshot = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_IMPORT_SNAPSHOT']>
) => Promise<Component<ComponentDictTypeMap['SYSTEM_IMPORT_SNAPSHOT']>>) => {
  const open = useOpenComponentFromDictionary<'SYSTEM_IMPORT_SNAPSHOT'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_IMPORT_SNAPSHOT']> = {}
    ) => {
      return open('SYSTEM_IMPORT_SNAPSHOT', from, overrides);
    },
    [open]
  );
};

export const openSystemLayouts = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_LAYOUTS']> = {}
): Promise<Component<ComponentDictTypeMap['SYSTEM_LAYOUTS']>> => {
  return openComponentFromDictionary<'SYSTEM_LAYOUTS'>(
    COMPONENT_DICT_ENUM.SYSTEM_LAYOUTS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenSystemLayouts = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_LAYOUTS']>
) => Promise<Component<ComponentDictTypeMap['SYSTEM_LAYOUTS']>>) => {
  const open = useOpenComponentFromDictionary<'SYSTEM_LAYOUTS'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_LAYOUTS']> = {}
    ) => {
      return open('SYSTEM_LAYOUTS', from, overrides);
    },
    [open]
  );
};

export const openSystemSnapshots = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_SNAPSHOTS']> = {}
): Promise<Component<ComponentDictTypeMap['SYSTEM_SNAPSHOTS']>> => {
  return openComponentFromDictionary<'SYSTEM_SNAPSHOTS'>(
    COMPONENT_DICT_ENUM.SYSTEM_SNAPSHOTS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenSystemSnapshots = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_SNAPSHOTS']>
) => Promise<Component<ComponentDictTypeMap['SYSTEM_SNAPSHOTS']>>) => {
  const open = useOpenComponentFromDictionary<'SYSTEM_SNAPSHOTS'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['SYSTEM_SNAPSHOTS']> = {}
    ) => {
      return open('SYSTEM_SNAPSHOTS', from, overrides);
    },
    [open]
  );
};

export const openEventDetails = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['EVENT_DETAILS']> = {}
): Promise<Component<ComponentDictTypeMap['EVENT_DETAILS']>> => {
  return openComponentFromDictionary<'EVENT_DETAILS'>(
    COMPONENT_DICT_ENUM.EVENT_DETAILS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenEventDetails = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['EVENT_DETAILS']>
) => Promise<Component<ComponentDictTypeMap['EVENT_DETAILS']>>) => {
  const open = useOpenComponentFromDictionary<'EVENT_DETAILS'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['EVENT_DETAILS']> = {}
    ) => {
      return open('EVENT_DETAILS', from, overrides);
    },
    [open]
  );
};

export const openInvestorOrderMonitorGrid = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['INVESTOR_ORDER_MONITOR_GRID']> = {}
): Promise<Component<ComponentDictTypeMap['INVESTOR_ORDER_MONITOR_GRID']>> => {
  return openComponentFromDictionary<'INVESTOR_ORDER_MONITOR_GRID'>(
    COMPONENT_DICT_ENUM.INVESTOR_ORDER_MONITOR_GRID,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenInvestorOrderMonitorGrid = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['INVESTOR_ORDER_MONITOR_GRID']>
) => Promise<Component<ComponentDictTypeMap['INVESTOR_ORDER_MONITOR_GRID']>>) => {
  const open = useOpenComponentFromDictionary<'INVESTOR_ORDER_MONITOR_GRID'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['INVESTOR_ORDER_MONITOR_GRID']> = {}
    ) => {
      return open('INVESTOR_ORDER_MONITOR_GRID', from, overrides);
    },
    [open]
  );
};

export const openMappableOrders = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['MAPPABLE_ORDERS']> = {}
): Promise<Component<ComponentDictTypeMap['MAPPABLE_ORDERS']>> => {
  return openComponentFromDictionary<'MAPPABLE_ORDERS'>(
    COMPONENT_DICT_ENUM.MAPPABLE_ORDERS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenMappableOrders = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['MAPPABLE_ORDERS']>
) => Promise<Component<ComponentDictTypeMap['MAPPABLE_ORDERS']>>) => {
  const open = useOpenComponentFromDictionary<'MAPPABLE_ORDERS'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['MAPPABLE_ORDERS']> = {}
    ) => {
      return open('MAPPABLE_ORDERS', from, overrides);
    },
    [open]
  );
};

export const openNotifications = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['NOTIFICATIONS']> = {}
): Promise<Component<ComponentDictTypeMap['NOTIFICATIONS']>> => {
  return openComponentFromDictionary<'NOTIFICATIONS'>(
    COMPONENT_DICT_ENUM.NOTIFICATIONS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenNotifications = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['NOTIFICATIONS']>
) => Promise<Component<ComponentDictTypeMap['NOTIFICATIONS']>>) => {
  const open = useOpenComponentFromDictionary<'NOTIFICATIONS'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['NOTIFICATIONS']> = {}
    ) => {
      return open('NOTIFICATIONS', from, overrides);
    },
    [open]
  );
};

export const openOrderVwap = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['ORDER_VWAP']> = {}
): Promise<Component<ComponentDictTypeMap['ORDER_VWAP']>> => {
  return openComponentFromDictionary<'ORDER_VWAP'>(
    COMPONENT_DICT_ENUM.ORDER_VWAP,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenOrderVwap = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['ORDER_VWAP']>
) => Promise<Component<ComponentDictTypeMap['ORDER_VWAP']>>) => {
  const open = useOpenComponentFromDictionary<'ORDER_VWAP'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['ORDER_VWAP']> = {}
    ) => {
      return open('ORDER_VWAP', from, overrides);
    },
    [open]
  );
};

export const openUserPreferencesV2 = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['USER_PREFERENCES_V2']> = {}
): Promise<Component<ComponentDictTypeMap['USER_PREFERENCES_V2']>> => {
  return openComponentFromDictionary<'USER_PREFERENCES_V2'>(
    COMPONENT_DICT_ENUM.USER_PREFERENCES_V2,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenUserPreferencesV2 = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['USER_PREFERENCES_V2']>
) => Promise<Component<ComponentDictTypeMap['USER_PREFERENCES_V2']>>) => {
  const open = useOpenComponentFromDictionary<'USER_PREFERENCES_V2'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['USER_PREFERENCES_V2']> = {}
    ) => {
      return open('USER_PREFERENCES_V2', from, overrides);
    },
    [open]
  );
};

export const openStrategyParameters = (
  parentActorOrId?: Actor | string,
  overrides: PartialComponentDefinition<ComponentDictTypeMap['STRATEGY_PARAMETERS']> = {}
): Promise<Component<ComponentDictTypeMap['STRATEGY_PARAMETERS']>> => {
  return openComponentFromDictionary<'STRATEGY_PARAMETERS'>(
    COMPONENT_DICT_ENUM.STRATEGY_PARAMETERS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenStrategyParameters = (): ((
  from: OpenFrom,
  overrides?: PartialComponentDefinition<ComponentDictTypeMap['STRATEGY_PARAMETERS']>
) => Promise<Component<ComponentDictTypeMap['STRATEGY_PARAMETERS']>>) => {
  const open = useOpenComponentFromDictionary<'STRATEGY_PARAMETERS'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialComponentDefinition<ComponentDictTypeMap['STRATEGY_PARAMETERS']> = {}
    ) => {
      return open('STRATEGY_PARAMETERS', from, overrides);
    },
    [open]
  );
};

export type PartialDialogDefinition<T> = Partial<
  Pick<RegistryDefinitionDialog, 'title' | 'windowOptions'>
> & {
  componentProps?: T;
};

export const openDialogFromDictionary = <K extends DialogDictEnumKey>(
  appWorkspace: FoundationWorkspace,
  key: K,
  parentActorOrId: Actor | string,
  overrides: PartialDialogDefinition<DialogDictTypeMap[K]> = {}
): Promise<OpenDialogApi<DialogDictTypeMap[K]>> => {
  const definition = DIALOG_DICTIONARY[key];

  if (definition === undefined) {
    throw new Error('Dialog definition not found for key: ' + key);
  }

  const container = appWorkspace.meta?.container;

  if (!container) {
    throw new Error('Container not found in workspace meta');
  }

  const dialogService = container.resolve(DialogService);

  const enrichedDefinition = {
    ...definition,
    ...overrides,
    windowOptions: {
      ...definition.windowOptions,
      ...overrides.windowOptions
    },
    componentKey: key,
    type: 'dialog'
  } as RegistryDefinitionDialogWithCompKey<DialogDictTypeMap[K]>;

  return dialogService.open<DialogDictTypeMap[K]>(enrichedDefinition, parentActorOrId);
};

export const useOpenDialogFromDictionary = <K extends DialogDictEnumKey>(key: K) => {
  const workspace = useWorkspace();
  const currentActor = useCurrentActor();
  const dialogService = useDialogService();
  const dialogId = getDialogId(key as string, currentActor);
  const [state, setState] = useState<DialogEventType>(DIALOG_EVENT_TYPE.UNOPENED);

  useLayoutEffect(() => {
    const subscription = dialogService.listen(dialogId).events$.subscribe((event) => {
      setState(event.type);
    });
    return () => subscription.unsubscribe();
  }, [dialogId, dialogService]);

  const openDialog = useCallback(
    (overrides: PartialDialogDefinition<DialogDictTypeMap[K]> = {}) => {
      return openDialogFromDictionary<K>(workspace, key, currentActor, overrides);
    },
    [workspace, currentActor, key]
  );

  return useMemo(() => {
    const triggerProps = {
      'data-dialog-id': dialogId,
      'data-dialog-state': state
    };

    return [openDialog, triggerProps] as const;
  }, [openDialog, dialogId, state]);
};

export const openConfirmation = <T extends Workspace>(
  appWorkspace: T,
  parentActorOrId?: Actor | string,
  overrides: PartialDialogDefinition<DialogDictTypeMap['CONFIRMATION']> = {}
): Promise<OpenDialogApi<DialogDictTypeMap['CONFIRMATION']>> => {
  return openDialogFromDictionary<'CONFIRMATION'>(
    appWorkspace,
    DIALOG_DICT_ENUM.CONFIRMATION,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenConfirmation = () => {
  return useOpenDialogFromDictionary<'CONFIRMATION'>('CONFIRMATION');
};

export const openRejectWithReason = <T extends Workspace>(
  appWorkspace: T,
  parentActorOrId?: Actor | string,
  overrides: PartialDialogDefinition<DialogDictTypeMap['REJECT_WITH_REASON']> = {}
): Promise<OpenDialogApi<DialogDictTypeMap['REJECT_WITH_REASON']>> => {
  return openDialogFromDictionary<'REJECT_WITH_REASON'>(
    appWorkspace,
    DIALOG_DICT_ENUM.REJECT_WITH_REASON,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenRejectWithReason = () => {
  return useOpenDialogFromDictionary<'REJECT_WITH_REASON'>('REJECT_WITH_REASON');
};

export const openAllFields = <T extends Workspace>(
  appWorkspace: T,
  parentActorOrId?: Actor | string,
  overrides: PartialDialogDefinition<DialogDictTypeMap['ALL_FIELDS']> = {}
): Promise<OpenDialogApi<DialogDictTypeMap['ALL_FIELDS']>> => {
  return openDialogFromDictionary<'ALL_FIELDS'>(
    appWorkspace,
    DIALOG_DICT_ENUM.ALL_FIELDS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenAllFields = () => {
  return useOpenDialogFromDictionary<'ALL_FIELDS'>('ALL_FIELDS');
};

export type PartialLayoutDefinition<T> = Partial<
  Pick<RegistryDefinitionLayout, 'title' | 'windowOptions' | 'jsonModel' | 'windowId'>
> & {
  componentProps?: T;
};

export const openLayoutFromDictionary = <K extends LayoutDictEnumKey>(
  key: K,
  parentActorOrId: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap[K]> = {}
): Promise<Layout<LayoutDictTypeMap[K]>> => {
  const definition = LAYOUT_DICTIONARY[key];

  if (definition === undefined) {
    throw new Error('Layout definition not found for key: ' + key);
  }

  const enrichedDefinition = {
    ...definition,
    ...overrides,
    windowOptions: {
      ...definition.windowOptions,
      ...overrides.windowOptions
    },
    type: 'layout'
  } as RegistryDefinitionLayoutWithComp<LayoutDictTypeMap[K]>;

  return Layout.open<LayoutDictTypeMap[K]>(enrichedDefinition, parentActorOrId);
};

export const useOpenLayoutFromDictionary = <K extends LayoutDictEnumKey>(): ((
  key: K,
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap[K]>
) => Promise<Layout<LayoutDictTypeMap[K]>>) => {
  const leaderWindow = useLeaderWindow();
  const currentWindow = useCurrentAppWindow();
  const currentActor = useCurrentActor();
  return useCallback(
    (key: K, from: OpenFrom = 'leader', overrides: PartialLayoutDefinition<LayoutDictTypeMap[K]> = {}) => {
      const parentActor =
        from instanceof Actor
          ? from
          : from === 'current'
            ? currentActor
            : from === 'currentWindow'
              ? currentWindow
              : leaderWindow;

      return openLayoutFromDictionary<K>(key, parentActor, overrides);
    },
    [leaderWindow, currentWindow, currentActor]
  );
};

export const openExampleLayout = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['EXAMPLE_LAYOUT']> = {}
): Promise<Layout<LayoutDictTypeMap['EXAMPLE_LAYOUT']>> => {
  return openLayoutFromDictionary<'EXAMPLE_LAYOUT'>(
    LAYOUT_DICT_ENUM.EXAMPLE_LAYOUT,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenExampleLayout = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['EXAMPLE_LAYOUT']>
) => Promise<Layout<LayoutDictTypeMap['EXAMPLE_LAYOUT']>>) => {
  const open = useOpenLayoutFromDictionary<'EXAMPLE_LAYOUT'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialLayoutDefinition<LayoutDictTypeMap['EXAMPLE_LAYOUT']> = {}
    ) => {
      return open('EXAMPLE_LAYOUT', from, overrides);
    },
    [open]
  );
};

export const openInvestorOrderMonitor = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['INVESTOR_ORDER_MONITOR']> = {}
): Promise<Layout<LayoutDictTypeMap['INVESTOR_ORDER_MONITOR']>> => {
  return openLayoutFromDictionary<'INVESTOR_ORDER_MONITOR'>(
    LAYOUT_DICT_ENUM.INVESTOR_ORDER_MONITOR,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenInvestorOrderMonitor = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['INVESTOR_ORDER_MONITOR']>
) => Promise<Layout<LayoutDictTypeMap['INVESTOR_ORDER_MONITOR']>>) => {
  const open = useOpenLayoutFromDictionary<'INVESTOR_ORDER_MONITOR'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialLayoutDefinition<LayoutDictTypeMap['INVESTOR_ORDER_MONITOR']> = {}
    ) => {
      return open('INVESTOR_ORDER_MONITOR', from, overrides);
    },
    [open]
  );
};

export const openInvestorOrderView = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['INVESTOR_ORDER_VIEW']> = {}
): Promise<Layout<LayoutDictTypeMap['INVESTOR_ORDER_VIEW']>> => {
  return openLayoutFromDictionary<'INVESTOR_ORDER_VIEW'>(
    LAYOUT_DICT_ENUM.INVESTOR_ORDER_VIEW,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenInvestorOrderView = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['INVESTOR_ORDER_VIEW']>
) => Promise<Layout<LayoutDictTypeMap['INVESTOR_ORDER_VIEW']>>) => {
  const open = useOpenLayoutFromDictionary<'INVESTOR_ORDER_VIEW'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialLayoutDefinition<LayoutDictTypeMap['INVESTOR_ORDER_VIEW']> = {}
    ) => {
      return open('INVESTOR_ORDER_VIEW', from, overrides);
    },
    [open]
  );
};

export const openMontage = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['MONTAGE']> = {}
): Promise<Layout<LayoutDictTypeMap['MONTAGE']>> => {
  return openLayoutFromDictionary<'MONTAGE'>(
    LAYOUT_DICT_ENUM.MONTAGE,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenMontage = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['MONTAGE']>
) => Promise<Layout<LayoutDictTypeMap['MONTAGE']>>) => {
  const open = useOpenLayoutFromDictionary<'MONTAGE'>();
  return useCallback(
    (from: OpenFrom = 'leader', overrides: PartialLayoutDefinition<LayoutDictTypeMap['MONTAGE']> = {}) => {
      return open('MONTAGE', from, overrides);
    },
    [open]
  );
};

export const openNewOrders = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['NEW_ORDERS']> = {}
): Promise<Layout<LayoutDictTypeMap['NEW_ORDERS']>> => {
  return openLayoutFromDictionary<'NEW_ORDERS'>(
    LAYOUT_DICT_ENUM.NEW_ORDERS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenNewOrders = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['NEW_ORDERS']>
) => Promise<Layout<LayoutDictTypeMap['NEW_ORDERS']>>) => {
  const open = useOpenLayoutFromDictionary<'NEW_ORDERS'>();
  return useCallback(
    (from: OpenFrom = 'leader', overrides: PartialLayoutDefinition<LayoutDictTypeMap['NEW_ORDERS']> = {}) => {
      return open('NEW_ORDERS', from, overrides);
    },
    [open]
  );
};

export const openPendingModifications = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['PENDING_MODIFICATIONS']> = {}
): Promise<Layout<LayoutDictTypeMap['PENDING_MODIFICATIONS']>> => {
  return openLayoutFromDictionary<'PENDING_MODIFICATIONS'>(
    LAYOUT_DICT_ENUM.PENDING_MODIFICATIONS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenPendingModifications = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['PENDING_MODIFICATIONS']>
) => Promise<Layout<LayoutDictTypeMap['PENDING_MODIFICATIONS']>>) => {
  const open = useOpenLayoutFromDictionary<'PENDING_MODIFICATIONS'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialLayoutDefinition<LayoutDictTypeMap['PENDING_MODIFICATIONS']> = {}
    ) => {
      return open('PENDING_MODIFICATIONS', from, overrides);
    },
    [open]
  );
};

export const openRepairQueue = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['REPAIR_QUEUE']> = {}
): Promise<Layout<LayoutDictTypeMap['REPAIR_QUEUE']>> => {
  return openLayoutFromDictionary<'REPAIR_QUEUE'>(
    LAYOUT_DICT_ENUM.REPAIR_QUEUE,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenRepairQueue = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['REPAIR_QUEUE']>
) => Promise<Layout<LayoutDictTypeMap['REPAIR_QUEUE']>>) => {
  const open = useOpenLayoutFromDictionary<'REPAIR_QUEUE'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialLayoutDefinition<LayoutDictTypeMap['REPAIR_QUEUE']> = {}
    ) => {
      return open('REPAIR_QUEUE', from, overrides);
    },
    [open]
  );
};

export const openTradingOrderMonitor = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['TRADING_ORDER_MONITOR']> = {}
): Promise<Layout<LayoutDictTypeMap['TRADING_ORDER_MONITOR']>> => {
  return openLayoutFromDictionary<'TRADING_ORDER_MONITOR'>(
    LAYOUT_DICT_ENUM.TRADING_ORDER_MONITOR,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenTradingOrderMonitor = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['TRADING_ORDER_MONITOR']>
) => Promise<Layout<LayoutDictTypeMap['TRADING_ORDER_MONITOR']>>) => {
  const open = useOpenLayoutFromDictionary<'TRADING_ORDER_MONITOR'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialLayoutDefinition<LayoutDictTypeMap['TRADING_ORDER_MONITOR']> = {}
    ) => {
      return open('TRADING_ORDER_MONITOR', from, overrides);
    },
    [open]
  );
};

export const openTradingOrderMonitorTableServer = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['TRADING_ORDER_MONITOR_TABLE_SERVER']> = {}
): Promise<Layout<LayoutDictTypeMap['TRADING_ORDER_MONITOR_TABLE_SERVER']>> => {
  return openLayoutFromDictionary<'TRADING_ORDER_MONITOR_TABLE_SERVER'>(
    LAYOUT_DICT_ENUM.TRADING_ORDER_MONITOR_TABLE_SERVER,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenTradingOrderMonitorTableServer = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['TRADING_ORDER_MONITOR_TABLE_SERVER']>
) => Promise<Layout<LayoutDictTypeMap['TRADING_ORDER_MONITOR_TABLE_SERVER']>>) => {
  const open = useOpenLayoutFromDictionary<'TRADING_ORDER_MONITOR_TABLE_SERVER'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialLayoutDefinition<LayoutDictTypeMap['TRADING_ORDER_MONITOR_TABLE_SERVER']> = {}
    ) => {
      return open('TRADING_ORDER_MONITOR_TABLE_SERVER', from, overrides);
    },
    [open]
  );
};

export const openTradingOrderView = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['TRADING_ORDER_VIEW']> = {}
): Promise<Layout<LayoutDictTypeMap['TRADING_ORDER_VIEW']>> => {
  return openLayoutFromDictionary<'TRADING_ORDER_VIEW'>(
    LAYOUT_DICT_ENUM.TRADING_ORDER_VIEW,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenTradingOrderView = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['TRADING_ORDER_VIEW']>
) => Promise<Layout<LayoutDictTypeMap['TRADING_ORDER_VIEW']>>) => {
  const open = useOpenLayoutFromDictionary<'TRADING_ORDER_VIEW'>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialLayoutDefinition<LayoutDictTypeMap['TRADING_ORDER_VIEW']> = {}
    ) => {
      return open('TRADING_ORDER_VIEW', from, overrides);
    },
    [open]
  );
};

export const openPositions = (
  parentActorOrId?: Actor | string,
  overrides: PartialLayoutDefinition<LayoutDictTypeMap['POSITIONS']> = {}
): Promise<Layout<LayoutDictTypeMap['POSITIONS']>> => {
  return openLayoutFromDictionary<'POSITIONS'>(
    LAYOUT_DICT_ENUM.POSITIONS,
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenPositions = (): ((
  from: OpenFrom,
  overrides?: PartialLayoutDefinition<LayoutDictTypeMap['POSITIONS']>
) => Promise<Layout<LayoutDictTypeMap['POSITIONS']>>) => {
  const open = useOpenLayoutFromDictionary<'POSITIONS'>();
  return useCallback(
    (from: OpenFrom = 'leader', overrides: PartialLayoutDefinition<LayoutDictTypeMap['POSITIONS']> = {}) => {
      return open('POSITIONS', from, overrides);
    },
    [open]
  );
};

type PartialFormDefinitionBase<T extends AnyFormBuilder> = Partial<
  Pick<
    RegistryDefinitionFormWithFormKey<InferB<T>['inputContract']>,
    'title' | 'windowOptions' | 'form' | 'windowId'
  >
>;

export type PartialFormDefinition<T extends AnyFormBuilder, HasFormValuesSchema extends boolean = false> = {
  form?: PartialFormDefinitionBase<T>['form'] & {
    initialValues?: HasFormValuesSchema extends true ? InferB<T>['fieldValues'] : never;
  };
} & PartialFormDefinitionBase<T>;

export const openFormFromDictionary = <
  K extends keyof typeof FORM_MAP,
  HasFormValuesSchema extends boolean = false
>(
  formKey: K,
  parentActorOrId: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)[K], HasFormValuesSchema> = {}
): Promise<Form<(typeof FORM_MAP)[K]>> => {
  const definition = FORM_DICTIONARY[formKey as keyof typeof FORM_DICTIONARY];

  if (definition === undefined) {
    throw new Error('Form definition not found for key: ' + formKey.toString());
  }

  const enrichedDefinition = {
    ...definition,
    ...overrides,
    form: {
      ...definition.form,
      ...overrides.form
    },
    windowOptions: {
      ...definition.windowOptions,
      ...overrides.windowOptions
    },
    formKey,
    type: 'form'
  } as RegistryDefinitionFormWithFormKey<InferB<(typeof FORM_MAP)[K]>['inputContract']>;

  return Form.open<(typeof FORM_MAP)[K]>(enrichedDefinition, parentActorOrId, overrides.form?.initialValues);
};

export const useOpenFormFromDictionary = <
  K extends keyof typeof FORM_MAP,
  HasFormValuesSchema extends boolean = false
>(): ((
  key: K,
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)[K], HasFormValuesSchema>
) => Promise<Form<(typeof FORM_MAP)[K]>>) => {
  const leaderWindow = useLeaderWindow();
  const currentWindow = useCurrentAppWindow();
  const currentActor = useCurrentActor();
  return useCallback(
    (
      key: K,
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)[K], HasFormValuesSchema> = {}
    ) => {
      const parentActor =
        from instanceof Actor
          ? from
          : from === 'current'
            ? currentActor
            : from === 'currentWindow'
              ? currentWindow
              : leaderWindow;

      return openFormFromDictionary<K, HasFormValuesSchema>(key, parentActor, overrides);
    },
    [leaderWindow, currentWindow, currentActor]
  );
};

export function RemoteFormRenderer<
  K extends keyof typeof FORM_MAP,
  HasFormValuesSchema extends boolean = false
>({
  formKey,
  input,
  initialValues,
  ...props
}: Required<PartialFormDefinition<(typeof FORM_MAP)[K], HasFormValuesSchema>>['form'] & {
  formKey: K;
  initialValues?: Partial<InferB<(typeof FORM_MAP)[K]>['fieldValues']>;
} & Pick<
    FormRendererProps<
      InferB<(typeof FORM_MAP)[K]>['inputContract'],
      InferB<(typeof FORM_MAP)[K]>['outputContract'],
      InferB<(typeof FORM_MAP)[K]>['formContract'],
      InferB<(typeof FORM_MAP)[K]>['fieldValues']
    >,
    'onValuesChanging' | 'onValuesChanged'
  >): JSX.Element {
  const rendererProps = useInterpretFormBuilderId(
    formKey as string,
    input as InferB<(typeof FORM_MAP)[K]>['inputContract'],
    initialValues,
    props
  );
  return <FormBuilderRenderer {...rendererProps} />;
}

export const openActionButton = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['ACTION_BUTTON'], false> = {}
): Promise<Form<(typeof FORM_MAP)['ACTION_BUTTON']>> => {
  return openFormFromDictionary<'ACTION_BUTTON', false>(
    'ACTION_BUTTON',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenActionButton = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['ACTION_BUTTON'], false>
) => Promise<Form<(typeof FORM_MAP)['ACTION_BUTTON']>>) => {
  const open = useOpenFormFromDictionary<'ACTION_BUTTON', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['ACTION_BUTTON'], false> = {}
    ) => {
      return open('ACTION_BUTTON', from, overrides);
    },
    [open]
  );
};

export const ActionButton = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['ACTION_BUTTON'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['ACTION_BUTTON']>['inputContract'],
        InferB<(typeof FORM_MAP)['ACTION_BUTTON']>['outputContract'],
        InferB<(typeof FORM_MAP)['ACTION_BUTTON']>['formContract'],
        InferB<(typeof FORM_MAP)['ACTION_BUTTON']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'ACTION_BUTTON', false> formKey="ACTION_BUTTON" {...propOverrides} />;
};

export const openActionButtonLayout = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT'], false> = {}
): Promise<Form<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT']>> => {
  return openFormFromDictionary<'ACTION_BUTTON_LAYOUT', false>(
    'ACTION_BUTTON_LAYOUT',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenActionButtonLayout = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT'], false>
) => Promise<Form<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT']>>) => {
  const open = useOpenFormFromDictionary<'ACTION_BUTTON_LAYOUT', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT'], false> = {}
    ) => {
      return open('ACTION_BUTTON_LAYOUT', from, overrides);
    },
    [open]
  );
};

export const ActionButtonLayout = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT']>['inputContract'],
        InferB<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT']>['outputContract'],
        InferB<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT']>['formContract'],
        InferB<(typeof FORM_MAP)['ACTION_BUTTON_LAYOUT']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return (
    <RemoteFormRenderer<'ACTION_BUTTON_LAYOUT', false> formKey="ACTION_BUTTON_LAYOUT" {...propOverrides} />
  );
};

export const openExampleForm = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['EXAMPLE_FORM'], false> = {}
): Promise<Form<(typeof FORM_MAP)['EXAMPLE_FORM']>> => {
  return openFormFromDictionary<'EXAMPLE_FORM', false>(
    'EXAMPLE_FORM',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenExampleForm = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['EXAMPLE_FORM'], false>
) => Promise<Form<(typeof FORM_MAP)['EXAMPLE_FORM']>>) => {
  const open = useOpenFormFromDictionary<'EXAMPLE_FORM', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['EXAMPLE_FORM'], false> = {}
    ) => {
      return open('EXAMPLE_FORM', from, overrides);
    },
    [open]
  );
};

export const ExampleForm = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['EXAMPLE_FORM'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['EXAMPLE_FORM']>['inputContract'],
        InferB<(typeof FORM_MAP)['EXAMPLE_FORM']>['outputContract'],
        InferB<(typeof FORM_MAP)['EXAMPLE_FORM']>['formContract'],
        InferB<(typeof FORM_MAP)['EXAMPLE_FORM']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'EXAMPLE_FORM', false> formKey="EXAMPLE_FORM" {...propOverrides} />;
};

export const openCurrencyForm = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['CURRENCY_FORM'], false> = {}
): Promise<Form<(typeof FORM_MAP)['CURRENCY_FORM']>> => {
  return openFormFromDictionary<'CURRENCY_FORM', false>(
    'CURRENCY_FORM',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenCurrencyForm = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['CURRENCY_FORM'], false>
) => Promise<Form<(typeof FORM_MAP)['CURRENCY_FORM']>>) => {
  const open = useOpenFormFromDictionary<'CURRENCY_FORM', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['CURRENCY_FORM'], false> = {}
    ) => {
      return open('CURRENCY_FORM', from, overrides);
    },
    [open]
  );
};

export const CurrencyForm = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['CURRENCY_FORM'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['CURRENCY_FORM']>['inputContract'],
        InferB<(typeof FORM_MAP)['CURRENCY_FORM']>['outputContract'],
        InferB<(typeof FORM_MAP)['CURRENCY_FORM']>['formContract'],
        InferB<(typeof FORM_MAP)['CURRENCY_FORM']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'CURRENCY_FORM', false> formKey="CURRENCY_FORM" {...propOverrides} />;
};

export const openExchangeForm = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['EXCHANGE_FORM'], false> = {}
): Promise<Form<(typeof FORM_MAP)['EXCHANGE_FORM']>> => {
  return openFormFromDictionary<'EXCHANGE_FORM', false>(
    'EXCHANGE_FORM',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenExchangeForm = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['EXCHANGE_FORM'], false>
) => Promise<Form<(typeof FORM_MAP)['EXCHANGE_FORM']>>) => {
  const open = useOpenFormFromDictionary<'EXCHANGE_FORM', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['EXCHANGE_FORM'], false> = {}
    ) => {
      return open('EXCHANGE_FORM', from, overrides);
    },
    [open]
  );
};

export const ExchangeForm = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['EXCHANGE_FORM'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['EXCHANGE_FORM']>['inputContract'],
        InferB<(typeof FORM_MAP)['EXCHANGE_FORM']>['outputContract'],
        InferB<(typeof FORM_MAP)['EXCHANGE_FORM']>['formContract'],
        InferB<(typeof FORM_MAP)['EXCHANGE_FORM']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'EXCHANGE_FORM', false> formKey="EXCHANGE_FORM" {...propOverrides} />;
};

export const openSaveSnapshotForm = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM'], false> = {}
): Promise<Form<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM']>> => {
  return openFormFromDictionary<'SAVE_SNAPSHOT_FORM', false>(
    'SAVE_SNAPSHOT_FORM',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenSaveSnapshotForm = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM'], false>
) => Promise<Form<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM']>>) => {
  const open = useOpenFormFromDictionary<'SAVE_SNAPSHOT_FORM', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM'], false> = {}
    ) => {
      return open('SAVE_SNAPSHOT_FORM', from, overrides);
    },
    [open]
  );
};

export const SaveSnapshotForm = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM']>['inputContract'],
        InferB<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM']>['outputContract'],
        InferB<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM']>['formContract'],
        InferB<(typeof FORM_MAP)['SAVE_SNAPSHOT_FORM']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'SAVE_SNAPSHOT_FORM', false> formKey="SAVE_SNAPSHOT_FORM" {...propOverrides} />;
};

export const openCrossPrincipalFill = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL'], false> = {}
): Promise<Form<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL']>> => {
  return openFormFromDictionary<'CROSS_PRINCIPAL_FILL', false>(
    'CROSS_PRINCIPAL_FILL',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenCrossPrincipalFill = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL'], false>
) => Promise<Form<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL']>>) => {
  const open = useOpenFormFromDictionary<'CROSS_PRINCIPAL_FILL', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL'], false> = {}
    ) => {
      return open('CROSS_PRINCIPAL_FILL', from, overrides);
    },
    [open]
  );
};

export const CrossPrincipalFill = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL']>['inputContract'],
        InferB<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL']>['outputContract'],
        InferB<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL']>['formContract'],
        InferB<(typeof FORM_MAP)['CROSS_PRINCIPAL_FILL']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return (
    <RemoteFormRenderer<'CROSS_PRINCIPAL_FILL', false> formKey="CROSS_PRINCIPAL_FILL" {...propOverrides} />
  );
};

export const openInvestorOrderEntry = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY'], false> = {}
): Promise<Form<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY']>> => {
  return openFormFromDictionary<'INVESTOR_ORDER_ENTRY', false>(
    'INVESTOR_ORDER_ENTRY',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenInvestorOrderEntry = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY'], false>
) => Promise<Form<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY']>>) => {
  const open = useOpenFormFromDictionary<'INVESTOR_ORDER_ENTRY', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY'], false> = {}
    ) => {
      return open('INVESTOR_ORDER_ENTRY', from, overrides);
    },
    [open]
  );
};

export const InvestorOrderEntry = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY']>['inputContract'],
        InferB<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY']>['outputContract'],
        InferB<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY']>['formContract'],
        InferB<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return (
    <RemoteFormRenderer<'INVESTOR_ORDER_ENTRY', false> formKey="INVESTOR_ORDER_ENTRY" {...propOverrides} />
  );
};

export const openPrincipleFillForm = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM'], false> = {}
): Promise<Form<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM']>> => {
  return openFormFromDictionary<'PRINCIPLE_FILL_FORM', false>(
    'PRINCIPLE_FILL_FORM',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenPrincipleFillForm = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM'], false>
) => Promise<Form<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM']>>) => {
  const open = useOpenFormFromDictionary<'PRINCIPLE_FILL_FORM', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM'], false> = {}
    ) => {
      return open('PRINCIPLE_FILL_FORM', from, overrides);
    },
    [open]
  );
};

export const PrincipleFillForm = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM']>['inputContract'],
        InferB<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM']>['outputContract'],
        InferB<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM']>['formContract'],
        InferB<(typeof FORM_MAP)['PRINCIPLE_FILL_FORM']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return (
    <RemoteFormRenderer<'PRINCIPLE_FILL_FORM', false> formKey="PRINCIPLE_FILL_FORM" {...propOverrides} />
  );
};

export const openRouteOrder = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['ROUTE_ORDER'], true> = {}
): Promise<Form<(typeof FORM_MAP)['ROUTE_ORDER']>> => {
  return openFormFromDictionary<'ROUTE_ORDER', true>(
    'ROUTE_ORDER',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenRouteOrder = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['ROUTE_ORDER'], true>
) => Promise<Form<(typeof FORM_MAP)['ROUTE_ORDER']>>) => {
  const open = useOpenFormFromDictionary<'ROUTE_ORDER', true>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['ROUTE_ORDER'], true> = {}
    ) => {
      return open('ROUTE_ORDER', from, overrides);
    },
    [open]
  );
};

export const RouteOrder = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['ROUTE_ORDER'], true>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['ROUTE_ORDER']>['inputContract'],
        InferB<(typeof FORM_MAP)['ROUTE_ORDER']>['outputContract'],
        InferB<(typeof FORM_MAP)['ROUTE_ORDER']>['formContract'],
        InferB<(typeof FORM_MAP)['ROUTE_ORDER']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'ROUTE_ORDER', true> formKey="ROUTE_ORDER" {...propOverrides} />;
};

export const openTradeModify = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['TRADE_MODIFY'], false> = {}
): Promise<Form<(typeof FORM_MAP)['TRADE_MODIFY']>> => {
  return openFormFromDictionary<'TRADE_MODIFY', false>(
    'TRADE_MODIFY',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenTradeModify = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['TRADE_MODIFY'], false>
) => Promise<Form<(typeof FORM_MAP)['TRADE_MODIFY']>>) => {
  const open = useOpenFormFromDictionary<'TRADE_MODIFY', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['TRADE_MODIFY'], false> = {}
    ) => {
      return open('TRADE_MODIFY', from, overrides);
    },
    [open]
  );
};

export const TradeModify = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['TRADE_MODIFY'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['TRADE_MODIFY']>['inputContract'],
        InferB<(typeof FORM_MAP)['TRADE_MODIFY']>['outputContract'],
        InferB<(typeof FORM_MAP)['TRADE_MODIFY']>['formContract'],
        InferB<(typeof FORM_MAP)['TRADE_MODIFY']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'TRADE_MODIFY', false> formKey="TRADE_MODIFY" {...propOverrides} />;
};

export const openUserPreferencesForm = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['USER_PREFERENCES_FORM'], false> = {}
): Promise<Form<(typeof FORM_MAP)['USER_PREFERENCES_FORM']>> => {
  return openFormFromDictionary<'USER_PREFERENCES_FORM', false>(
    'USER_PREFERENCES_FORM',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenUserPreferencesForm = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['USER_PREFERENCES_FORM'], false>
) => Promise<Form<(typeof FORM_MAP)['USER_PREFERENCES_FORM']>>) => {
  const open = useOpenFormFromDictionary<'USER_PREFERENCES_FORM', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['USER_PREFERENCES_FORM'], false> = {}
    ) => {
      return open('USER_PREFERENCES_FORM', from, overrides);
    },
    [open]
  );
};

export const UserPreferencesForm = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['USER_PREFERENCES_FORM'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['USER_PREFERENCES_FORM']>['inputContract'],
        InferB<(typeof FORM_MAP)['USER_PREFERENCES_FORM']>['outputContract'],
        InferB<(typeof FORM_MAP)['USER_PREFERENCES_FORM']>['formContract'],
        InferB<(typeof FORM_MAP)['USER_PREFERENCES_FORM']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return (
    <RemoteFormRenderer<'USER_PREFERENCES_FORM', false> formKey="USER_PREFERENCES_FORM" {...propOverrides} />
  );
};

export const openRejectInvestorOrder = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER'], false> = {}
): Promise<Form<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER']>> => {
  return openFormFromDictionary<'REJECT_INVESTOR_ORDER', false>(
    'REJECT_INVESTOR_ORDER',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenRejectInvestorOrder = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER'], false>
) => Promise<Form<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER']>>) => {
  const open = useOpenFormFromDictionary<'REJECT_INVESTOR_ORDER', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER'], false> = {}
    ) => {
      return open('REJECT_INVESTOR_ORDER', from, overrides);
    },
    [open]
  );
};

export const RejectInvestorOrder = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER']>['inputContract'],
        InferB<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER']>['outputContract'],
        InferB<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER']>['formContract'],
        InferB<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return (
    <RemoteFormRenderer<'REJECT_INVESTOR_ORDER', false> formKey="REJECT_INVESTOR_ORDER" {...propOverrides} />
  );
};

export const openApplySettlementFxRate = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE'], false> = {}
): Promise<Form<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE']>> => {
  return openFormFromDictionary<'APPLY_SETTLEMENT_FX_RATE', false>(
    'APPLY_SETTLEMENT_FX_RATE',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenApplySettlementFxRate = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE'], false>
) => Promise<Form<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE']>>) => {
  const open = useOpenFormFromDictionary<'APPLY_SETTLEMENT_FX_RATE', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE'], false> = {}
    ) => {
      return open('APPLY_SETTLEMENT_FX_RATE', from, overrides);
    },
    [open]
  );
};

export const ApplySettlementFxRate = (
  propOverrides: Required<
    PartialFormDefinition<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE'], false>
  >['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE']>['inputContract'],
        InferB<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE']>['outputContract'],
        InferB<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE']>['formContract'],
        InferB<(typeof FORM_MAP)['APPLY_SETTLEMENT_FX_RATE']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return (
    <RemoteFormRenderer<'APPLY_SETTLEMENT_FX_RATE', false>
      formKey="APPLY_SETTLEMENT_FX_RATE"
      {...propOverrides}
    />
  );
};

export const openCancelExecutions = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['CANCEL_EXECUTIONS'], false> = {}
): Promise<Form<(typeof FORM_MAP)['CANCEL_EXECUTIONS']>> => {
  return openFormFromDictionary<'CANCEL_EXECUTIONS', false>(
    'CANCEL_EXECUTIONS',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenCancelExecutions = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['CANCEL_EXECUTIONS'], false>
) => Promise<Form<(typeof FORM_MAP)['CANCEL_EXECUTIONS']>>) => {
  const open = useOpenFormFromDictionary<'CANCEL_EXECUTIONS', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['CANCEL_EXECUTIONS'], false> = {}
    ) => {
      return open('CANCEL_EXECUTIONS', from, overrides);
    },
    [open]
  );
};

export const CancelExecutions = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['CANCEL_EXECUTIONS'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['CANCEL_EXECUTIONS']>['inputContract'],
        InferB<(typeof FORM_MAP)['CANCEL_EXECUTIONS']>['outputContract'],
        InferB<(typeof FORM_MAP)['CANCEL_EXECUTIONS']>['formContract'],
        InferB<(typeof FORM_MAP)['CANCEL_EXECUTIONS']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'CANCEL_EXECUTIONS', false> formKey="CANCEL_EXECUTIONS" {...propOverrides} />;
};

export const openOrderVwapForm = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['ORDER_VWAP_FORM'], false> = {}
): Promise<Form<(typeof FORM_MAP)['ORDER_VWAP_FORM']>> => {
  return openFormFromDictionary<'ORDER_VWAP_FORM', false>(
    'ORDER_VWAP_FORM',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenOrderVwapForm = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['ORDER_VWAP_FORM'], false>
) => Promise<Form<(typeof FORM_MAP)['ORDER_VWAP_FORM']>>) => {
  const open = useOpenFormFromDictionary<'ORDER_VWAP_FORM', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['ORDER_VWAP_FORM'], false> = {}
    ) => {
      return open('ORDER_VWAP_FORM', from, overrides);
    },
    [open]
  );
};

export const OrderVwapForm = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['ORDER_VWAP_FORM'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['ORDER_VWAP_FORM']>['inputContract'],
        InferB<(typeof FORM_MAP)['ORDER_VWAP_FORM']>['outputContract'],
        InferB<(typeof FORM_MAP)['ORDER_VWAP_FORM']>['formContract'],
        InferB<(typeof FORM_MAP)['ORDER_VWAP_FORM']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'ORDER_VWAP_FORM', false> formKey="ORDER_VWAP_FORM" {...propOverrides} />;
};

export const openRejectPendingModification = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION'], false> = {}
): Promise<Form<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION']>> => {
  return openFormFromDictionary<'REJECT_PENDING_MODIFICATION', false>(
    'REJECT_PENDING_MODIFICATION',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenRejectPendingModification = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION'], false>
) => Promise<Form<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION']>>) => {
  const open = useOpenFormFromDictionary<'REJECT_PENDING_MODIFICATION', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION'], false> = {}
    ) => {
      return open('REJECT_PENDING_MODIFICATION', from, overrides);
    },
    [open]
  );
};

export const RejectPendingModification = (
  propOverrides: Required<
    PartialFormDefinition<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION'], false>
  >['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION']>['inputContract'],
        InferB<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION']>['outputContract'],
        InferB<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION']>['formContract'],
        InferB<(typeof FORM_MAP)['REJECT_PENDING_MODIFICATION']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return (
    <RemoteFormRenderer<'REJECT_PENDING_MODIFICATION', false>
      formKey="REJECT_PENDING_MODIFICATION"
      {...propOverrides}
    />
  );
};

export const openBulkRepairTrade = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['BULK_REPAIR_TRADE'], false> = {}
): Promise<Form<(typeof FORM_MAP)['BULK_REPAIR_TRADE']>> => {
  return openFormFromDictionary<'BULK_REPAIR_TRADE', false>(
    'BULK_REPAIR_TRADE',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenBulkRepairTrade = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['BULK_REPAIR_TRADE'], false>
) => Promise<Form<(typeof FORM_MAP)['BULK_REPAIR_TRADE']>>) => {
  const open = useOpenFormFromDictionary<'BULK_REPAIR_TRADE', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['BULK_REPAIR_TRADE'], false> = {}
    ) => {
      return open('BULK_REPAIR_TRADE', from, overrides);
    },
    [open]
  );
};

export const BulkRepairTrade = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['BULK_REPAIR_TRADE'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['BULK_REPAIR_TRADE']>['inputContract'],
        InferB<(typeof FORM_MAP)['BULK_REPAIR_TRADE']>['outputContract'],
        InferB<(typeof FORM_MAP)['BULK_REPAIR_TRADE']>['formContract'],
        InferB<(typeof FORM_MAP)['BULK_REPAIR_TRADE']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'BULK_REPAIR_TRADE', false> formKey="BULK_REPAIR_TRADE" {...propOverrides} />;
};

export const openEnableQuote = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['ENABLE_QUOTE'], false> = {}
): Promise<Form<(typeof FORM_MAP)['ENABLE_QUOTE']>> => {
  return openFormFromDictionary<'ENABLE_QUOTE', false>(
    'ENABLE_QUOTE',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenEnableQuote = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['ENABLE_QUOTE'], false>
) => Promise<Form<(typeof FORM_MAP)['ENABLE_QUOTE']>>) => {
  const open = useOpenFormFromDictionary<'ENABLE_QUOTE', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['ENABLE_QUOTE'], false> = {}
    ) => {
      return open('ENABLE_QUOTE', from, overrides);
    },
    [open]
  );
};

export const EnableQuote = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['ENABLE_QUOTE'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['ENABLE_QUOTE']>['inputContract'],
        InferB<(typeof FORM_MAP)['ENABLE_QUOTE']>['outputContract'],
        InferB<(typeof FORM_MAP)['ENABLE_QUOTE']>['formContract'],
        InferB<(typeof FORM_MAP)['ENABLE_QUOTE']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'ENABLE_QUOTE', false> formKey="ENABLE_QUOTE" {...propOverrides} />;
};

export const openMontageFiltersForm = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM'], false> = {}
): Promise<Form<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM']>> => {
  return openFormFromDictionary<'MONTAGE_FILTERS_FORM', false>(
    'MONTAGE_FILTERS_FORM',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenMontageFiltersForm = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM'], false>
) => Promise<Form<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM']>>) => {
  const open = useOpenFormFromDictionary<'MONTAGE_FILTERS_FORM', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM'], false> = {}
    ) => {
      return open('MONTAGE_FILTERS_FORM', from, overrides);
    },
    [open]
  );
};

export const MontageFiltersForm = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM']>['inputContract'],
        InferB<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM']>['outputContract'],
        InferB<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM']>['formContract'],
        InferB<(typeof FORM_MAP)['MONTAGE_FILTERS_FORM']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return (
    <RemoteFormRenderer<'MONTAGE_FILTERS_FORM', false> formKey="MONTAGE_FILTERS_FORM" {...propOverrides} />
  );
};

export const openMockUserSettings = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['MOCK_USER_SETTINGS'], false> = {}
): Promise<Form<(typeof FORM_MAP)['MOCK_USER_SETTINGS']>> => {
  return openFormFromDictionary<'MOCK_USER_SETTINGS', false>(
    'MOCK_USER_SETTINGS',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenMockUserSettings = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['MOCK_USER_SETTINGS'], false>
) => Promise<Form<(typeof FORM_MAP)['MOCK_USER_SETTINGS']>>) => {
  const open = useOpenFormFromDictionary<'MOCK_USER_SETTINGS', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['MOCK_USER_SETTINGS'], false> = {}
    ) => {
      return open('MOCK_USER_SETTINGS', from, overrides);
    },
    [open]
  );
};

export const MockUserSettings = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['MOCK_USER_SETTINGS'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['MOCK_USER_SETTINGS']>['inputContract'],
        InferB<(typeof FORM_MAP)['MOCK_USER_SETTINGS']>['outputContract'],
        InferB<(typeof FORM_MAP)['MOCK_USER_SETTINGS']>['formContract'],
        InferB<(typeof FORM_MAP)['MOCK_USER_SETTINGS']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'MOCK_USER_SETTINGS', false> formKey="MOCK_USER_SETTINGS" {...propOverrides} />;
};

export const openMontageBumpPrice = (
  parentActorOrId?: Actor | string,
  overrides: PartialFormDefinition<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE'], false> = {}
): Promise<Form<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE']>> => {
  return openFormFromDictionary<'MONTAGE_BUMP_PRICE', false>(
    'MONTAGE_BUMP_PRICE',
    parentActorOrId || PROCESS_ID.LEADER,
    overrides
  );
};

export const useOpenMontageBumpPrice = (): ((
  from: OpenFrom,
  overrides?: PartialFormDefinition<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE'], false>
) => Promise<Form<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE']>>) => {
  const open = useOpenFormFromDictionary<'MONTAGE_BUMP_PRICE', false>();
  return useCallback(
    (
      from: OpenFrom = 'leader',
      overrides: PartialFormDefinition<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE'], false> = {}
    ) => {
      return open('MONTAGE_BUMP_PRICE', from, overrides);
    },
    [open]
  );
};

export const MontageBumpPrice = (
  propOverrides: Required<PartialFormDefinition<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE'], false>>['form'] &
    Pick<
      FormRendererProps<
        InferB<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE']>['inputContract'],
        InferB<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE']>['outputContract'],
        InferB<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE']>['formContract'],
        InferB<(typeof FORM_MAP)['MONTAGE_BUMP_PRICE']>['fieldValues']
      >,
      'onValuesChanging' | 'onValuesChanged'
    >
): JSX.Element => {
  return <RemoteFormRenderer<'MONTAGE_BUMP_PRICE', false> formKey="MONTAGE_BUMP_PRICE" {...propOverrides} />;
};
