import { useCallback } from 'react';
import { InvestorOrderAuditTrailService } from '@app/data-access/services/trading/investor-orders/investor-order-audit-trail.service';
import { useService } from '@oms/frontend-foundation';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { auditTrailColumnLibrary } from './investor-order-view.audit-trail.columns';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';
import {
  viewEventDetailsAction,
  inlineViewEventDetailsAction
} from '@app/common/grids/actions/view-event-details.action';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';

export const InvestorOrderAuditTrailGrid = ({
  investorOrderId
}: {
  investorOrderId: VisibleInvestorOrderInfoWithAllocationsFragment['id'];
}) => {
  const auditTrailService = useService(InvestorOrderAuditTrailService);

  const builder = useCallback(
    (b: GridBuilder<OrderAuditTrail>) =>
      b
        .columnLibrary(auditTrailColumnLibrary)
        .datasource((d) =>
          d.source(auditTrailService.getAuditTrail$(investorOrderId)).rowId((r) => r.data.id)
        )
        .rowSelection((c) => c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0]))
        .injectEvents([GridConfigEventHandler])
        .sideBar()
        .actions((a) =>
          a
            .schema((s) => s.action(viewEventDetailsAction).action(inlineViewEventDetailsAction))
            .settings((s) => s.fromFile({}))
        ),
    [auditTrailService, investorOrderId]
  );

  const props = useVGrid('investor-order-audit-trail', builder, [auditTrailService]);
  return <VGrid {...props} />;
};

export default InvestorOrderAuditTrailGrid;
