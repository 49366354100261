import { Lifecycle, scoped } from 'tsyringe';
import { type AnyRecord, ContextMenuService, type SimpleContextMenuItem } from '@oms/frontend-foundation';
import type { CustomContextMenuItem } from '../models/custom.context-menu.model';

@scoped(Lifecycle.ContainerScoped)
export class CustomContextMenuService<TData extends AnyRecord> extends ContextMenuService {
  public getAllCustom(): CustomContextMenuItem<TData>[] {
    return this.getAll() as CustomContextMenuItem<TData>[];
  }

  public upsertCustom(context: CustomContextMenuItem<TData> | CustomContextMenuItem<TData>[]) {
    const items = Array.isArray(context) ? context : [context];
    this.add(items);
    for (const item of items) {
      this.update(item.id, item);
    }
  }

  public getGridContextMenuItems(data?: TData[]): SimpleContextMenuItem[] {
    return this.getAllCustom().map(({ callback, ...def }) => {
      let item: SimpleContextMenuItem = def;

      if (callback) {
        item = {
          ...item,
          ...callback(data),
          callback // forcefully add callback back to the item, so it doesn't get lost on each iteration
        } as SimpleContextMenuItem;
      }

      return item;
    });
  }
}
