import { FieldTypeEnum, InputTypeEnum } from './tabbable.service.types';

export function isAlphaKeyOnly(key: string) {
  return key && key.length === 1 && /^[A-Za-z]+$/.test(key);
}

export function isNumberKeyOnly(key: string) {
  return key && key.length === 1 && /[0-9]/.test(key);
}

export function isNumericKeyOnly(key: string) {
  return key && key.length === 1 && /[0-9.-]/.test(key);
}

export function isNumericKeyWithShortcutsOnly(key: string) {
  return key && key.length === 1 && /[0-9.mkb-]/.test(key);
}

export function isTimeKeyOnly(key: string) {
  return key && key.length === 1 && /[0-9ap]/.test(key);
}

export function getFieldType($element: Element) {
  let fieldType = FieldTypeEnum.ALPHANUMERIC;

  if (
    $element.tagName === InputTypeEnum.INPUT &&
    ($element.getAttribute('type') === 'number' || $element.getAttribute('type') === 'date')
  ) {
    fieldType = FieldTypeEnum.NUMBER;
  }

  if ($element.tagName === InputTypeEnum.INPUT && $element.getAttribute('type') === 'time') {
    fieldType = FieldTypeEnum.TIME;
  }

  if ($element.tagName === InputTypeEnum.INPUT && $element.getAttribute('data-type') === 'numeric') {
    const shortcuts = $element.getAttribute('data-shortcuts');
    fieldType = shortcuts === 'true' ? FieldTypeEnum.NUMERIC_SHORTCUTS : FieldTypeEnum.NUMERIC;
  }

  if ($element.tagName === InputTypeEnum.SELECT) {
    const $options = $element.querySelectorAll('option');
    const isSelectNumbersOnly = Array.from($options).every(($option) => !isNaN($option.text as any));
    fieldType = isSelectNumbersOnly ? FieldTypeEnum.NUMBER : FieldTypeEnum.ALPHANUMERIC;
  }

  return fieldType;
}

export function selectOptionByNumber($select: HTMLSelectElement, optionNumber: number) {
  const $options = Array.from($select.options);
  const $option = $options[optionNumber - 1];
  if ($option) {
    $select.selectedIndex = optionNumber - 1;

    const changeEvent = new Event('change', { bubbles: true });
    $select.dispatchEvent(changeEvent);
  }
}
