import { openRejectPendingModification } from '@app/generated/sdk';
import type { ActionContext, ActionComponentConfig } from '@oms/frontend-vgrid';
import type { VisibleModificationInfoFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';

export const rejectPendingModificationOnChange = async (
  ctx: ActionContext<VisibleModificationInfoFragment, ActionComponentConfig<VisibleModificationInfoFragment>>
) => {
  const { lifecycle, data } = ctx;
  const selectedRow = data[0];

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openRejectPendingModification(PROCESS_ID.LEADER, {
      form: {
        formId: `reject-pending-modification-${id}`,
        input: {
          modificationId: id
        }
      }
    });
  }
};
