import clsx from 'clsx';
import type { InputProps } from '../input/input';
import * as styles from './css/textarea.css';
import { forwardRef } from 'react';
import { __DEV__ } from '../../system/utils/assertion';
import { Box } from '../../system/components/box/box';
import { useFormControl } from '../form-control/form-control';
import { useAutoFocus } from '../../hooks/use-auto-focus';

type OmitProps = 'type';

export type TextareaProps = {} & Omit<InputProps, OmitProps>;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { className, children: _, id, autoFocus, ...rest } = props;
  const formControl = useFormControl(props);
  const mergedRef = useAutoFocus(ref, autoFocus);
  return (
    <Box
      as="textarea"
      ref={mergedRef}
      className={clsx(styles.textarea, className)}
      {...rest}
      id={id || formControl.id}
    />
  );
});

if (__DEV__) {
  Textarea.displayName = 'Textarea';
}
