import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type {
  AnyRecord,
  FieldContract,
  IAdvancedMultiSelectField,
  ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import type { TagTypeEnum } from '@oms/generated/frontend';

export const createOrderTagsField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  filters?: TagTypeEnum[],
  overrides?: ModifiedFieldForOptions<IAdvancedMultiSelectField>
) =>
  fc.field(key, 'advanced-multi-select').advancedSelectMultiOptions({
    label: 'Order Tags',
    query: ADVANCED_SELECT_QUERY_ENUM.ORDER_TAGS_WATCH_QUERY,
    queryProps: {
      filters: filters
    },
    ...overrides
  });
