import { FC, useCallback } from 'react';
import { useActionProps } from '../../../hooks/action.events.hook';
import type { ActionComponentProps } from '../../../models/actions.model';
import { IconCounter, type IconCounterProps } from '@oms/shared-frontend/ui-design-system';
import { type AnyRecord } from '@valstro/workspace';
import { OnCountChangeEvent } from '@oms/shared-frontend/ui-design-system';

export type IconCounterActionProps = ActionComponentProps<AnyRecord> & Pick<OnCountChangeEvent, 'countType'>;

export const IconCounterButton: FC<IconCounterActionProps> = (props) => {
  const actionProps = useActionProps<any, IconCounterActionProps>(props);
  const { onChange, isVisible, content } = actionProps;

  const onCountChange: IconCounterProps['onCountChange'] = useCallback(
    (e: OnCountChangeEvent) => {
      onChange({
        state: { ...actionProps, countType: e.countType } as IconCounterActionProps,
        actionId: actionProps.id,
        lifecycle: 'change'
      });
    },
    [actionProps]
  );

  if (isVisible === false) {
    return null;
  }

  return <IconCounter onCountChange={onCountChange}>{content}</IconCounter>;
};
