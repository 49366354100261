import {
  FormBuilderSimpleTemplate,
  type UniqueFormBuilderSimpleTemplateProps
} from './simple/form-builder-simple.template';

export const FORM_BUILDER_TEMPLATE = {
  SIMPLE: 'simple'
} as const;

export const FORM_BUILDER_TEMPLATE_MAPPER = {
  [FORM_BUILDER_TEMPLATE.SIMPLE]: FormBuilderSimpleTemplate
} as const;

export type FormBuilderTemplateProps = {
  [FORM_BUILDER_TEMPLATE.SIMPLE]: UniqueFormBuilderSimpleTemplateProps;
};

export type GeneralFormBuilderTemplateMapper = {
  [key: string]: React.FC<any>;
};

export type FormBuilderTemplateMapperKey = keyof FormBuilderTemplateProps;

export type FormBuilderPartialTemplateProps = Partial<FormBuilderTemplateProps>;

export type FormBuilderPartialTemplatePropsByKey<K extends FormBuilderTemplateMapperKey> = Partial<
  FormBuilderTemplateProps[K]
>;
