import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import {
  actionButtonColorOptions,
  actionButtonCommands,
  actionButtonSizeOptions
} from '../utils/button.action.schema';
import type { ActionButtonFormOutput } from './action-button.contracts';
import { FieldContract } from '@oms/frontend-foundation';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import type { IActionCommandField } from '../action-command/action-command.field';

const actionButtonContract = FieldContract.create<
  ActionButtonFormOutput,
  IActionCommandField | AdditionalFormFieldUnion
>();

export const label = actionButtonContract.field('label', 'text-field').options({
  ...makeCommonRequiredProps(),
  label: 'Label',
  wrapperSx: { width: 'full', margin: '4px' },
  condition: {
    when: 'allowsLabel',
    is: true,
    then: { visible: true },
    else: { visible: false }
  }
});

export const color = actionButtonContract.field('color', 'select').options({
  ...makeCommonRequiredProps(),
  options: actionButtonColorOptions(),
  wrapperSx: { width: 'half', margin: '4px' },
  label: 'Color',
  condition: {
    when: 'allowsColor',
    is: true,
    then: { visible: true },
    else: { visible: false }
  }
});

export const size = actionButtonContract.field('size', 'select').options({
  ...makeCommonRequiredProps(),
  label: 'Size',
  wrapperSx: { width: 'half', margin: '4px' },
  options: actionButtonSizeOptions(),
  condition: {
    when: 'allowsSize',
    is: true,
    then: { visible: true },
    else: { visible: false }
  }
});

export const commandId = actionButtonContract.field('commandId', 'select').options({
  ...makeCommonRequiredProps(),
  label: 'Command',
  wrapperSx: { width: 'full', margin: '4px' },
  options: actionButtonCommands(),
  actions: {
    resolveProps: ['buttonConfigAllowedCommands']
  }
});

export const locationId = actionButtonContract.field('locationId', 'hidden-field');

export const confirmation = actionButtonContract.field('confirmation', 'checkbox').options({
  label: 'No confirmation',
  condition: {
    when: 'allowsConfirmation',
    is: true,
    then: { visible: true },
    else: { visible: false }
  }
});

export const payload = actionButtonContract.field('payload', 'action-command');

export const allowedCommands = actionButtonContract.field('allowedCommands', 'hidden-field');

export const allowsConfirmation = actionButtonContract
  .field('allowsConfirmation', 'hidden-field')
  .options({});

export const allowsLabel = actionButtonContract.field('allowsLabel', 'hidden-field').options({});

export const allowsSize = actionButtonContract.field('allowsSize', 'hidden-field').options({});

export const allowsColor = actionButtonContract.field('allowsColor', 'hidden-field').options({});

export const subSchemaKey = actionButtonContract.field('subSchemaKey', 'hidden-field').options({});

export const subSchemaVersion = actionButtonContract.field('subSchemaVersion', 'hidden-field').options({});
