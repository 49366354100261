/* eslint-disable no-barrel-files/no-barrel-files */
// !!! DO NOT MODIFY THIS FILE, UNLESS YOU KNOW WHAT YOU ARE DOING !!!
// !! This file is modified by the ui-gen:sub-schema-migration

import {
  createRxDbCollectionMigrationStrategies,
  getLatestRxDBMigrationVersion
} from '@oms/frontend-schemas';
import version0 from './actions.collection.v-0.initial';

export const ALL_VERSIONS = [version0];

export const LATEST_VERSION = getLatestRxDBMigrationVersion(ALL_VERSIONS);

export const LATEST_VERSION_NUMBER = LATEST_VERSION.version;

export const MIGRATION_STRATEGIES = createRxDbCollectionMigrationStrategies(ALL_VERSIONS);
