import * as Toggle from '@radix-ui/react-toggle';
import type { ToggleProps as RadixToggleProps } from '@radix-ui/react-toggle';
import { Button, ButtonProps } from '../button/button';
import { useControllableState } from '../../hooks/use-controllable';

type OmittedProps = 'type' | 'value' | 'onChange'; // Both RadixToggleProps and ButtonProps have props with conflicting types. Omit those.
type FilteredRadixToggleProps = Omit<RadixToggleProps, OmittedProps>;
type FilteredButtonProps = Omit<ButtonProps, OmittedProps>;
type BaseToggleButtonProps = FilteredRadixToggleProps & FilteredButtonProps;

export interface ToggleButtonProps extends BaseToggleButtonProps {
  value?: string | number;
  onPressedChange?: (pressed: boolean) => void;
  onValueChange?: (pressed: boolean, value: string | number) => void;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  defaultPressed,
  pressed,
  onPressedChange,
  onValueChange,
  disabled,
  children,
  className,
  value,
  ...buttonProps
}) => {
  const [_pressed, _onPressedChange] = useControllableState({
    value: pressed,
    defaultValue: defaultPressed,
    onChange: onPressedChange
  });

  return (
    <Toggle.Root
      asChild={true}
      defaultPressed={defaultPressed}
      pressed={_pressed}
      onPressedChange={_onPressedChange}
      disabled={disabled}
      value={value}
    >
      <Button
        {...buttonProps}
        style={{ width: 'fit-content', ...(buttonProps.style ? buttonProps.style : {}) }}
      >
        {children}
      </Button>
    </Toggle.Root>
  );
};
