export enum CellBadgeClassEnum {
  Capital = 'ag-cell-badge--capital'
}

export enum RowStatusClassEnum {
  // Legacy row states. TODO: Consider removing once they're all no longer in use.
  NoAction = 'ag-row-order-status ag-row-order-status--no-action',
  PendingAction = 'ag-row-order-status ag-row-order-status--pending-action',
  TakeAction = 'ag-row-order-status ag-row-order-status--take-action',
  Disabled = 'ag-row-order-status ag-row-order-status--disabled',
  Error = 'ag-row-order-status ag-row-order-status--error',
  Warn = 'ag-row-order-status ag-row-order-status--warn',
  // Current row states.
  Pending = 'ag-row-status-color ag-row-status-color--blue',
  Hazard = 'ag-row-status-color ag-row-status-color--red',
  NoExecutedQuantity = 'ag-row-status-color ag-row-status-color--dark-grey',
  HasExecutedQuantity = 'ag-row-status-color ag-row-status-color--light-grey'
}
