import { useRef, useLayoutEffect, useCallback } from 'react';
import { useService } from '@oms/frontend-foundation';
import type {
  CommandPaletteItem,
  CommandPaletteUnregisterContextItems
} from '@app/common/command-palette/command-palette.contracts';
import { CommandPaletteService } from './command-palette.service';
import { isEqual } from 'lodash';

/**
 * A hook to get the command palette service.
 *
 * @example
 * ```tsx
 * const commandPaletteService = useCommandPaletteService();
 *
 * useEffect(() => {
 *  commandPaletteService.registerItems([...]).catch(console.error);
 * }, [commandPaletteService]);
 * ```
 *
 * @returns The command palette service
 */
export function useCommandPaletteService() {
  return useService(CommandPaletteService);
}

/**
 * A hook to lazily register and unregister context items with the command palette service
 * by returning a tuple of functions to register and unregister context items.
 *
 * @example
 * ```tsx
 * const [registerContextItems, unregisterContextItems] = useCommandPaletteContextLazy('my-context');
 *
 * useEffect(() => {
 *  registerContextItems([...]).catch(console.error);
 *  return () => {
 *    unregisterContextItems().catch(console.error);
 *  };
 * }, [registerContextItems, unregisterContextItems]);
 * ```
 *
 * @param contextName - The name of the context to register
 * @returns A tuple of functions to register and unregister context items
 */
export function useCommandPaletteContextLazy(contextName: string) {
  const commandPaletteService = useCommandPaletteService();

  return useCallback(
    async (contextItems: CommandPaletteItem[]) => {
      return await commandPaletteService.registerContext(contextName, contextItems);
    },
    [commandPaletteService, contextName]
  );
}

/**
 * A hook to register and unregister context items with the command palette service.
 * This will automatically unregister the previous context items when the context items change.
 *
 * @example
 * ```tsx
 * // With this hook, you don't need to worry about unregistering the context items in unmount
 * // So in most cases, you can just register the context items and forget about it
 * // like: useCommandPaletteContext('my-context', [...]);
 * const unregisterContextItems = useCommandPaletteContext('my-context', [...]);
 *
 * // This is a contrived example, but you can unregister the context items whenever you want
 * // but keep in mind that the context items will be automatically unregistered when the component unmounts anyway
 * const handleSomeEvent = useCallback(async () => {
 *  await unregisterContextItems();
 * }, [unregisterContextItems]);
 * ```
 *
 * @param contextName - The name of the context to register
 * @param contextItems - The items to register. Note, this will be compared by lodash's `isEqual`, so it's safe to pass in a new array every render.
 * @returns A function to unregister the context items (Note, unmounting will automatically unregister the context items anyway)
 */
export function useCommandPaletteContext(contextName: string, contextItems: CommandPaletteItem[]) {
  const prevContextItemsRef = useRef<CommandPaletteItem[]>([]);
  const registerContextItems = useCommandPaletteContextLazy(contextName);
  const unregisterContextItemsRef = useRef<CommandPaletteUnregisterContextItems | undefined>(undefined);

  useLayoutEffect(() => {
    async function init() {
      if (isEqual(prevContextItemsRef.current, contextItems)) {
        return;
      }

      prevContextItemsRef.current = contextItems;

      if (unregisterContextItemsRef.current) {
        await unregisterContextItemsRef.current();
      }

      unregisterContextItemsRef.current = await registerContextItems(contextItems);
    }

    init().catch(console.error);

    return () => {
      if (unregisterContextItemsRef.current) {
        unregisterContextItemsRef.current().catch(console.error);
      }
    };
  }, [registerContextItems, contextItems]);

  return useCallback(async () => {
    if (unregisterContextItemsRef.current) {
      await unregisterContextItemsRef.current();
    }
  }, []);
}
