import { useCallback } from 'react';
import type { UserPreferencesProps, UserPreferenceTab } from './user-preferences.widget.config';
import { useCurrentWidgetActorProps } from '@valstro/workspace-react';
import { Box, Flex, TabContent, TabsList, TabTrigger } from '@oms/shared-frontend/ui-design-system';
import { USER_PREFERENCE_REGISTRY } from './preferences/user-preferences.registery';
import { Tabs } from '@oms/shared-frontend/ui-design-system';
import { UserAvatar } from '@app/containers/user-avatar/user-avatar.container';

const tabs = Object.entries(USER_PREFERENCE_REGISTRY);

export const UserPreferencesWidgetComponent = () => {
  const [props, setProps] = useCurrentWidgetActorProps<UserPreferencesProps>();
  const { tabId = 'montage_settings' } = props || {};

  const setTabId = useCallback((tabId: string) => {
    setProps({ tabId: tabId as UserPreferenceTab }).catch(console.error);
  }, []);

  return (
    <Tabs
      orientation="vertical"
      value={tabId}
      onValueChange={setTabId}
      sx={{ position: 'relative', height: 'full' }}
    >
      <Flex
        style={{ width: 275 }}
        sx={{
          position: 'relative',
          height: 'full',
          p: 3,
          backgroundColor: 'layout.level1',
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderColor: 'border.minimal'
        }}
      >
        <Flex sx={{ flexDirection: 'column' }}>
          <Box sx={{ width: 'full', mb: 6, mt: 3, ml: 2 }}>
            <UserAvatar showInfo={true} size="lg" />
          </Box>
          <TabsList sx={{ position: 'relative' }}>
            {tabs.map(([key, { label }]) => (
              <TabTrigger key={key} value={key}>
                {label}
              </TabTrigger>
            ))}
          </TabsList>
        </Flex>
      </Flex>
      {tabs.map(([key, { component: Component }]) => (
        <TabContent key={key} value={key} sx={{ position: 'relative', height: 'full', flexGrow: 1 }}>
          <Component />
        </TabContent>
      ))}
    </Tabs>
  );
};

export default UserPreferencesWidgetComponent;
