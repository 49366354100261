import type { SingleSpinnerControl } from '@valstro/fixatdl-core';
import type { ControlTransformer } from './control.transformer.types';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const singleSpinnerControl: ControlTransformer<SingleSpinnerControl> = (
  singleSpinner,
  _query,
  formValues
) => {
  return {
    component: fixatdlFormComponentTypes.SINGLE_SPINNER,
    name: singleSpinner.id,
    label: singleSpinner.label,
    initialValue: AtdlFormats.coerceValue(formValues[singleSpinner.id]),
    increment: singleSpinner.increment,
    incrementPolicy: singleSpinner.incrementPolicy
  };
};
