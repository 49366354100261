import { useContext } from 'react';
import { ToolbarContext } from '../toolbar-context';

export const useToolbar = () => {
  const toolbar = useContext(ToolbarContext);

  if (!toolbar) {
    throw new Error('useAddToolbarAction must be used within a ToolbarProvider');
  }

  return toolbar;
};
