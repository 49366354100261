import {
  type AnyRecord,
  listenForElm,
  useInWindowContextMenuApi,
  useService
} from '@oms/frontend-foundation';
import { CustomContextMenuService } from '../services/custom-context-menu.service';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { VGridProps } from '../components/vgrid.component';
import type { CellContextMenuEvent } from '@ag-grid-community/core';
import { useGridContainer } from './grid.container.hook';
import { useActionsService } from './action.events.hook';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { useVGridId } from './vgrid-id.hook';
import { useGridApi } from './grid.api.hook';
import { uniqBy } from 'lodash';

export function useCustomContextMenu<TData extends AnyRecord>({
  gridType,
  viewId,
  hasCustomContextMenu,
  onCellContextMenu: onCellContextMenuProp
}: VGridProps<TData>) {
  const gridId = useVGridId(gridType, viewId);
  const { api } = useGridApi();
  const gridContainer = useGridContainer();
  const customContextMenuService = useService(CustomContextMenuService, gridContainer);
  const contextMenuApi = useInWindowContextMenuApi();
  const isOpenRef = useRef(false);
  const openRowIdRef = useRef<string | undefined>();
  const actionsService = useActionsService<TData>();
  const actionsObs$ = useMemo(() => actionsService.actions$(), [actionsService]);

  // Track if the context menu is open or closed
  useEffect(() => {
    const sub = contextMenuApi.$.pipe(
      map((i) => i.isOpen),
      distinctUntilChanged()
    ).subscribe((isOpen) => {
      isOpenRef.current = isOpen;
      if (!isOpen) {
        openRowIdRef.current = undefined;
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [contextMenuApi]);

  // Subscribe to grid events and update the context menu
  useEffect(() => {
    const actionsEventSub = actionsObs$
      .pipe(
        filter((e) => e.source === 'grid' && isOpenRef.current === true),
        debounceTime(100)
      )
      .subscribe(() => {
        if (!openRowIdRef.current || !api) {
          return;
        }
        const rowData = api.getRowNode(openRowIdRef.current)?.data;
        if (!rowData) {
          return;
        }
        const allSelectedRowData = api.getSelectedNodes().flatMap((n) => (n.data ? [n.data] : []));
        const enrichedItems = customContextMenuService.getGridContextMenuItems(allSelectedRowData);
        contextMenuApi.setContext(enrichedItems);
      });

    return () => {
      actionsEventSub.unsubscribe();
    };
  }, [actionsObs$, actionsService, customContextMenuService, api]);

  // Callback for the context menu
  const onContextMenuCallback = useCallback(
    (e: CellContextMenuEvent<TData>) => {
      const event = e.event as PointerEvent | undefined;
      if (!event || !e.data) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      const getRowId = e.api.getGridOption('getRowId');
      const currentSelection = e.api.getSelectedNodes();
      const isMultiSelect = e.api.getSelectedNodes().length > 1;
      const isOutsideSelection = !currentSelection.some((n) => n.rowIndex === e.rowIndex);
      const shouldClearSelection = !isMultiSelect || isOutsideSelection;

      e.node.setSelected(true, shouldClearSelection);

      const allSelectedRowData = uniqBy(
        [...e.api.getSelectedNodes().flatMap((n) => (n.data ? [n.data] : [])), e.data],
        (d) =>
          getRowId
            ? getRowId({
                api: e.api,
                context: e.context,
                data: d,
                level: 0,
                columnApi: e.columnApi
              })
            : d?.id
      );

      const enrichedItems = customContextMenuService.getGridContextMenuItems(allSelectedRowData);
      contextMenuApi.open(event, enrichedItems);

      openRowIdRef.current = getRowId
        ? getRowId({
            api: e.api,
            context: e.context,
            data: e.data,
            level: 0,
            columnApi: e.columnApi
          })
        : undefined;
    },
    [contextMenuApi]
  );

  // Attach the context menu to the grid container in-case there are no rows
  useEffect(() => {
    let element: HTMLElement | null = null;
    let observer: MutationObserver | null = null;

    const handler = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const enrichedItems = customContextMenuService.getGridContextMenuItems();
      contextMenuApi.open(event, enrichedItems);
    };

    if (!hasCustomContextMenu) {
      return;
    }

    observer = listenForElm<HTMLElement>(
      `.vgrid-id-${gridId} .ag-center-cols-viewport`,
      (elm) => {
        element = elm;
        element.addEventListener('contextmenu', handler);
      },
      { once: true }
    );

    return () => {
      observer?.disconnect();
      if (element) {
        element.removeEventListener('contextmenu', handler);
      }
    };
  }, [hasCustomContextMenu]);

  return useMemo(
    () => (hasCustomContextMenu ? onContextMenuCallback : onCellContextMenuProp),
    [hasCustomContextMenu, onContextMenuCallback, onCellContextMenuProp]
  );
}
