import { TextField } from '@oms/frontend-foundation';
import type { FieldProps } from '@oms/frontend-foundation';
import { isEqual } from 'lodash';
import React from 'react';
import type { FC } from 'react';
import type { HiddenFieldProps } from '../../fixatdl-form.types';

export const HiddenField: FC<FieldProps<HiddenFieldProps>> = React.memo((props) => {
  return <TextField {...props} type="hidden" component="text-field"></TextField>;
}, isEqual);
