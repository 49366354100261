import { t } from '@oms/codegen/translations';
import {
  ActionContext,
  ActionComponentConfig,
  ActionDefFactory,
  INLINE_SPLIT_BUTTON_ACTION,
  SplitButtonActionComponentConfig
} from '@oms/frontend-vgrid';
import { openEventDetails } from '@app/generated/sdk';
import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';

export const isVisible = (rowData: OrderAuditTrail[] | undefined): boolean =>
  !!rowData && rowData.length === 1 && !!rowData[0] && !!rowData[0].eventDetails;

const getTitle = () => t('app.orders.viewEventDetails.viewEventDetails');

export const viewEventDetailsOnChange = async (
  ctx: ActionContext<OrderAuditTrail, ActionComponentConfig<OrderAuditTrail>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openEventDetails(getLeaderOrTabId(ctx.appContainer), {
      componentProps: {
        eventDetails: selectedRow.eventDetails
      },
      title: t('app.orders.viewEventDetails.eventDetails', {
        event: selectedRow?.event || '',
        suffix: selectedRow?.event ? '-' : ''
      })
    });
  }
};

export const viewEventDetailsAction: ActionDefFactory<OrderAuditTrail> = (builder) =>
  builder
    .name('view_event_details')
    .customMenu((m) => m.name(getTitle()).visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<OrderAuditTrail>>(viewEventDetailsOnChange);

export const inlineViewEventDetailsAction: ActionDefFactory<OrderAuditTrail> = (builder) =>
  builder
    .name('inline_view_event_details_split_button')
    .inline((col) =>
      col
        .header(t('app.orders.actions'))
        .initialWidth(140)
        .filter(false)
        .cell((c) =>
          c.renderer(INLINE_SPLIT_BUTTON_ACTION, {
            actionsConfig: [
              {
                id: 'inline_view_event_details',
                title: getTitle()
              }
            ],
            variant: 'secondary'
          })
        )
    )
    .lifecycles('change', 'init')
    .onChange<SplitButtonActionComponentConfig<OrderAuditTrail>>(async (ctx) => {
      const { state, lifecycle } = ctx;

      const selectedId = state?.selectedId;
      const selectedRow = ctx.data[0];

      const isEventDetailsDisabled = !(selectedRow && selectedRow.eventDetails);

      switch (lifecycle) {
        case 'init': {
          ctx.notify({ isDisabled: isEventDetailsDisabled });
          break;
        }

        case 'change': {
          if (selectedId === 'inline_view_event_details') {
            await viewEventDetailsOnChange(ctx);
          } else {
            throw new Error(`onChange not defined for ${selectedId || 'selectedId'}`);
          }
        }
      }
    });
