import { openTradingOrderView } from '@app/generated/sdk';
import { useCallback } from 'react';
import { useLeaderOrTabId } from '@app/common/workspace/workspace.hooks';
import type { TradingOrderRow } from '@app/widgets/trading/trading-order-monitor-table-server/trading-order-monitor.contracts';
import { getOrderDialogTitle } from '../orders.table-server.utils';

export interface IOpenViewTradingOrderArgs {
  windowId: string;
  tradingOrderData: TradingOrderRow;
}

export const openViewTradingOrder = async ({ windowId, tradingOrderData }: IOpenViewTradingOrderArgs) => {
  await openTradingOrderView(windowId, {
    componentProps: {
      id: tradingOrderData.id
    },
    title: getOrderDialogTitle(tradingOrderData)
  });
};

export const useOpenViewTradingOrder = (tradingOrderData?: TradingOrderRow | null) => {
  const windowId = useLeaderOrTabId();

  return useCallback(() => {
    if (!tradingOrderData) {
      console.error('tradingOrderData is null or undefined');
      return;
    }
    openViewTradingOrder({ windowId, tradingOrderData }).catch((e) => console.error(e));
  }, [windowId, tradingOrderData]);
};
