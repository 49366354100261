import { ReactTauriWindowActorSchemaBuilder, ReactTauriWindowView } from '@valstro/workspace-react';
import {
  PopoverContext,
  PopoverOperations,
  commonTauriPopoverSchemaOverrides
} from '@valstro/workspace-plugin-popover';
import { ReactPopoverActorSchemaOptions, ReactPopoverActorViewType } from './popover.contracts';
import { PopoverComponent } from './popover.component';

export const REACT_TAURI_POPOVER_ACTOR_NAME = 'react-tauri-popover-window';

const View: ReactPopoverActorViewType = ({ actor }) => {
  return (
    <ReactTauriWindowView<ReactTauriPopoverActorSchema> actor={actor}>
      <PopoverComponent actor={actor} />
    </ReactTauriWindowView>
  );
};

export const ReactTauriPopoverActorSchemaBuilder = ReactTauriWindowActorSchemaBuilder.extend<
  PopoverContext,
  PopoverOperations,
  ReactPopoverActorSchemaOptions
>((prevSchema) => ({
  ...prevSchema,
  ...commonTauriPopoverSchemaOverrides(prevSchema),
  name: REACT_TAURI_POPOVER_ACTOR_NAME
})).extendView(View);

export const reactTauriPopoverActor =
  ReactTauriPopoverActorSchemaBuilder.optionsCreator<ReactPopoverActorSchemaOptions>();

export type ReactTauriPopoverActorSchema = typeof ReactTauriPopoverActorSchemaBuilder.schema;
