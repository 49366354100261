/* eslint-disable */
import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export enum CoverageAdmin {
  /** Allows users to manage Coverage data from within the Coverage Widget, including approval of any account coverage changes based on access rights */
  CoverageManage = 'CoverageManage',
  /** Allows users to view Coverage data from within the Coverage Widget */
  CoverageView = 'CoverageView'
}

export enum DefaultComplianceAndRiskUser {
  /** Allows users to view Account limits related information as well as perform all Account limit related actions. Ex: Create Limit, Modify limit, delete limit */
  AccountLimitManage = 'AccountLimitManage',
  /** Allows users to view Account limit related information */
  AccountLimitView = 'AccountLimitView',
  /** Allows users to view Account Reference Data */
  AccountView = 'AccountView',
  /** A User can View/Create/Modify the CapitalThreshold Fields */
  AggregationUnitManage = 'AggregationUnitManage',
  /** A User can view the CapitalThreshold Fields */
  AggregationUnitView = 'AggregationUnitView',
  /** Allows users to view Borrowable Stock related information as well as perform all Borrowable Stock related actions. Ex: Create New Borrowable stock and quantity, Modify Borrowable Quantity, Delete Borrowable Record */
  BorrowableStockListManage = 'BorrowableStockListManage',
  /** Allows users to view Borrowable Stock related information */
  BorrowableStockListView = 'BorrowableStockListView',
  /** Allows users to view Current Worst Case related information as well as perform all Current Worst Case related actions. Ex: Create Limit, Modify limit */
  CapitalThresholdManage = 'CapitalThresholdManage',
  /** Allows users to view Current Worst Case related information */
  CapitalThresholdView = 'CapitalThresholdView',
  /** Allows users to view Charge Schedule related information */
  ChargeScheduleView = 'ChargeScheduleView',
  /** Allows users to view Coverage data from within the Coverage Widget */
  CoverageView = 'CoverageView',
  /** Allows users to view Firm Preferred Capacity data */
  FirmPreferredCapacityView = 'FirmPreferredCapacityView',
  /** Allows users to view Instrument Coverage related information */
  InstrumentCoverageView = 'InstrumentCoverageView',
  /** Allows users to view Instrument Restriction related information as well as perform all Instrument Restriction related actions. Ex: Create Instrument Restriction, Modify Instrument Restriction, Delete Instrument Restriction */
  InstrumentRestrictionManage = 'InstrumentRestrictionManage',
  /** Allows users to view Instrument Restriction related information */
  InstrumentRestrictionView = 'InstrumentRestrictionView',
  /** Allows users to view list data */
  ListsView = 'ListsView',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view Position-related information based on visibility rights */
  PositionView = 'PositionView',
  /** Allows users to view reference data */
  ReferenceDataView = 'ReferenceDataView',
  /** Allows users to view representative codes */
  RepresentativeCodesView = 'RepresentativeCodesView',
  /** Allows users to view Execution Venue Risk Controls related as well as perform all Execution Venue Risk Controls related actions. Ex: Create Limit, Modify limit */
  RiskControlsManage = 'RiskControlsManage',
  /** Allows users to view Execution Venue Risk Controls related information */
  RiskControlsView = 'RiskControlsView',
  /** Allows users to view Routing Rules */
  RoutingRulesView = 'RoutingRulesView',
  /** Allows users to view Scales */
  ScalesView = 'ScalesView',
  /** Allows users to view Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView',
  /** Allows users to view Users */
  UsersView = 'UsersView'
}

export enum DefaultExecutionTraderManager {
  /** Allows users to Apply FX Rates */
  ApplyFxRate = 'ApplyFxRate',
  /** Allows users to view Current Worst Case related information as well as perform all Current Worst Case related actions. Ex: Create Limit, Modify limit */
  CapitalThresholdManage = 'CapitalThresholdManage',
  /** Allows users to view Current Worst Case related information */
  CapitalThresholdView = 'CapitalThresholdView',
  /** Allows users to Force Cancel a Trading Order */
  ForceCancel = 'ForceCancel',
  /** Allows users to view repair queue */
  RepairQueueView = 'RepairQueueView',
  /** Allows users to Manage Routing Rules */
  RoutingRulesManage = 'RoutingRulesManage',
  /** Allows users to view Routing Rules */
  RoutingRulesView = 'RoutingRulesView',
  /** Allows a user to cancel a market facing trade */
  StreetSideTradeCancel = 'StreetSideTradeCancel',
  /** Allows users to view Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view Alleged Trade Reports no matter their visibility to an Account */
  TradeReportAllegeManage = 'TradeReportAllegeManage',
  /** Allows users to Manage Alleged Trade Reports no matter their visibility to an Account */
  TradeReportAllegeView = 'TradeReportAllegeView',
  /** Allows users to view TRF/ORF related information as well as perform all TRF/ORF related actions. Ex: Modify TRF Report, Create TRF Report, Cancel Trade */
  TradeReportManage = 'TradeReportManage',
  /** Allows users to view TRF/ORF related information no matter their Visibility to the Trade that the TRF/ORF Report resulted from */
  TradeReportView = 'TradeReportView',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView',
  /** Allows users to view failed electronic orders where the Investor Account is unresolvable */
  UnresolvedFailedOrderView = 'UnresolvedFailedOrderView'
}

export enum DefaultExecutionTraderUser {
  /** Allows users to Apply FX Rates */
  ApplyFxRate = 'ApplyFxRate',
  /** Allows users to Force Cancel a Trading Order */
  ForceCancel = 'ForceCancel',
  /** Allows users to view order-related information (Order View) as well as perform all order actions based on access rights. Ex: Create Order, Amend Order, Cancel Order, Route Order, sending order summary */
  OrderManage = 'OrderManage',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view Position-related information as well as perform all Position-related actions Ex: Position Mark, Position Adjust, Position Transfer */
  PositionManage = 'PositionManage',
  /** Allows users to view Position-related information based on visibility rights */
  PositionView = 'PositionView',
  /** Allows users to view repair queue */
  RepairQueueView = 'RepairQueueView',
  /** Allows users to view Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView'
}

export enum DefaultMiddleOfficeUser {
  /** Allows users to view Account Reference Data (Reference Data Account View) as well as perform all account data maintenance related actions. (Ex: Create Account, Inactivate Account) */
  AccountManage = 'AccountManage',
  /** Allows users to view Account Reference Data */
  AccountView = 'AccountView',
  /** Allows users to view order-related information (Order View) as well as perform all order actions based on access rights. Ex: Create Order, Amend Order, Cancel Order, Route Order, sending order summary */
  OrderManage = 'OrderManage',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view Position-related information as well as perform all Position-related actions Ex: Position Mark, Position Adjust, Position Transfer */
  PositionManage = 'PositionManage',
  /** Allows users to view Position-related information based on visibility rights */
  PositionView = 'PositionView',
  /** Allows users to view Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView'
}

export enum DefaultProgramTraderManager {
  /** Allows users to view/manage Montage-related information, including related market data based on visibility rights */
  MontageManage = 'MontageManage',
  /** Allows users to view order-related information (Order View) as well as perform all order actions based on access rights. Ex: Create Order, Amend Order, Cancel Order, Route Order, sending order summary */
  OrderManage = 'OrderManage',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view Position-related information as well as perform all Position-related actions Ex: Position Mark, Position Adjust, Position Transfer */
  PositionManage = 'PositionManage',
  /** Allows users to view Position-related information based on visibility rights */
  PositionView = 'PositionView',
  /** Allows users to view Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView'
}

export enum DefaultProgramTraderUser {
  /** Allows users to view/manage Montage-related information, including related market data based on visibility rights */
  MontageManage = 'MontageManage',
  /** Allows users to view order-related information (Order View) as well as perform all order actions based on access rights. Ex: Create Order, Amend Order, Cancel Order, Route Order, sending order summary */
  OrderManage = 'OrderManage',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view Position-related information as well as perform all Position-related actions Ex: Position Mark, Position Adjust, Position Transfer */
  PositionManage = 'PositionManage',
  /** Allows users to view Position-related information based on visibility rights */
  PositionView = 'PositionView',
  /** Allows users to view Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView'
}

export enum DefaultReportBuiderAdmin {
  /** Allows users the full access to Report Builder for their personal reports */
  ReportBuilderManage = 'ReportBuilderManage',
  /** Allows users the full access to Report Builder for all users and all firm reports */
  ReportBuilderManageUserAndFirmManage = 'ReportBuilderManageUserAndFirmManage',
  /** Allows users to view Reports Dashboard, Scheduled Dashboard, and Status Dashboard */
  ReportBuilderView = 'ReportBuilderView'
}

export enum DefaultReportBuilder {
  /** Allows users the full access to Report Builder for their personal reports */
  ReportBuilderManage = 'ReportBuilderManage',
  /** Allows users to view Reports Dashboard, Scheduled Dashboard, and Status Dashboard */
  ReportBuilderView = 'ReportBuilderView'
}

export enum DefaultSalesTraderManager {
  /** Allows users to manage Montage-related information */
  MontageManage = 'MontageManage',
  /** Allows users to view order-related information (Order View) as well as perform all order actions based on access rights. Ex: Create Order, Amend Order, Cancel Order, Route Order, sending order summary */
  OrderManage = 'OrderManage',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view Position-related information based on visibility rights */
  PositionView = 'PositionView',
  /** Allows users to view Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView'
}

export enum DefaultSalesTraderUser {
  /** Allows users to view Montage-related information, including related market data based on visibility rights */
  MontageManage = 'MontageManage',
  /** Allows users to view order-related information (Order View) as well as perform all order actions based on access rights. Ex: Create Order, Amend Order, Cancel Order, Route Order, sending order summary */
  OrderManage = 'OrderManage',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView'
}

export enum DefaultSupportUser {
  /** Allows users to view Account limits related information as well as perform all Account limit related actions. Ex: Create Limit, Modify limit, delete limit */
  AccountLimitManage = 'AccountLimitManage',
  /** Allows users to view Account limit related information */
  AccountLimitView = 'AccountLimitView',
  /** Allows users to view Account Reference Data (Reference Data Account View) as well as perform all account data maintenance related actions. (Ex: Create Account, Inactivate Account) */
  AccountManage = 'AccountManage',
  /** Allows users to view Account Reference Data */
  AccountView = 'AccountView',
  /** A User can View/Create/Modify the CapitalThreshold Fields */
  AggregationUnitManage = 'AggregationUnitManage',
  /** A User can view the CapitalThreshold Fields */
  AggregationUnitView = 'AggregationUnitView',
  /** Allows users to view Borrowable Stock related information as well as perform all Borrowable Stock related actions. Ex: Create New Borrowable stock and quantity, Modify Borrowable Quantity, Delete Borrowable Record */
  BorrowableStockListManage = 'BorrowableStockListManage',
  /** Allows users to view Borrowable Stock related information */
  BorrowableStockListView = 'BorrowableStockListView',
  /** Allows users to view Current Worst Case related information as well as perform all Current Worst Case related actions. Ex: Create Limit, Modify limit */
  CapitalThresholdManage = 'CapitalThresholdManage',
  /** Allows users to view Current Worst Case related information */
  CapitalThresholdView = 'CapitalThresholdView',
  /** Allows users to view Charge Schedule related information as well as perform all Charge Schedule related actions. Ex: Create Limit, Modify limit */
  ChargeScheduleManage = 'ChargeScheduleManage',
  /** Allows users to view Charge Schedule related information */
  ChargeScheduleView = 'ChargeScheduleView',
  /** Allows users to manage Firm Preferred Capacity data */
  FirmPreferredCapacityManage = 'FirmPreferredCapacityManage',
  /** Allows users to view Firm Preferred Capacity data */
  FirmPreferredCapacityView = 'FirmPreferredCapacityView',
  /** Allows users to view, create, modify, and delete Instrument Coverage related data */
  InstrumentCoverageManage = 'InstrumentCoverageManage',
  /** Allows users to view Instrument Coverage related information */
  InstrumentCoverageView = 'InstrumentCoverageView',
  /** Allows users to view Instrument Restriction related information as well as perform all Instrument Restriction related actions. Ex: Create Instrument Restriction, Modify Instrument Restriction, Delete Instrument Restriction */
  InstrumentRestrictionManage = 'InstrumentRestrictionManage',
  /** Allows users to view Instrument Restriction related information */
  InstrumentRestrictionView = 'InstrumentRestrictionView',
  /** Allows users to view/manage list data */
  ListsManage = 'ListsManage',
  /** Allows users to view list data */
  ListsView = 'ListsView',
  /** Allows users to manage Montage-related information */
  MontageManage = 'MontageManage',
  /** Allows users to view order-related information (Order View) as well as perform all order actions based on access rights. Ex: Create Order, Amend Order, Cancel Order, Route Order, sending order summary */
  OrderManage = 'OrderManage',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view Position-related information as well as perform all Position-related actions Ex: Position Mark, Position Adjust, Position Transfer */
  PositionManage = 'PositionManage',
  /** Allows users to view Position-related information based on visibility rights */
  PositionView = 'PositionView',
  /** Allows users to view reference data */
  ReferenceDataView = 'ReferenceDataView',
  /** Allows users to view repair queue */
  RepairQueueView = 'RepairQueueView',
  /** Allows users to manage representative codes */
  RepresentativeCodesManage = 'RepresentativeCodesManage',
  /** Allows users to view representative codes */
  RepresentativeCodesView = 'RepresentativeCodesView',
  /** Allows users to view Execution Venue Risk Controls related as well as perform all Execution Venue Risk Controls related actions. Ex: Create Limit, Modify limit */
  RiskControlsManage = 'RiskControlsManage',
  /** Allows users to view Execution Venue Risk Controls related information */
  RiskControlsView = 'RiskControlsView',
  /** Allows users to Manage Routing Rules */
  RoutingRulesManage = 'RoutingRulesManage',
  /** Allows users to view Routing Rules */
  RoutingRulesView = 'RoutingRulesView',
  /** Allows users to Manage Scales */
  ScalesManage = 'ScalesManage',
  /** Allows users to view Scales */
  ScalesView = 'ScalesView',
  /** Allows users to view trade counter-parties */
  TradeCounterpartiesView = 'TradeCounterpartiesView',
  /** Allows users to view Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView',
  /** Allows users to manage Users */
  UsersManage = 'UsersManage',
  /** Allows users to view Users */
  UsersView = 'UsersView'
}

export type Entitlements = {
  __typename?: 'Entitlements';
  data: Scalars['JSON'];
};

export type Query = {
  __typename?: 'Query';
  allEntitlements: Entitlements;
};

export enum ReferenceDataAdmin {
  /** Allows users to view Account limits related information as well as perform all Account limit related actions. Ex: Create Limit, Modify limit, delete limit */
  AccountLimitManage = 'AccountLimitManage',
  /** Allows users to view Account limit related information */
  AccountLimitView = 'AccountLimitView',
  /** Allows users to view Account Reference Data (Reference Data Account View) as well as perform all account data maintenance related actions. (Ex: Create Account, Inactivate Account) */
  AccountManage = 'AccountManage',
  /** Allows users to view Account Reference Data */
  AccountView = 'AccountView',
  /** A User can View/Create/Modify the CapitalThreshold Fields */
  AggregationUnitManage = 'AggregationUnitManage',
  /** A User can view the CapitalThreshold Fields */
  AggregationUnitView = 'AggregationUnitView',
  /** Allows users to view Borrowable Stock related information as well as perform all Borrowable Stock related actions. Ex: Create New Borrowable stock and quantity, Modify Borrowable Quantity, Delete Borrowable Record */
  BorrowableStockListManage = 'BorrowableStockListManage',
  /** Allows users to view Borrowable Stock related information */
  BorrowableStockListView = 'BorrowableStockListView',
  /** Allows users to view Current Worst Case related information as well as perform all Current Worst Case related actions. Ex: Create Limit, Modify limit */
  CapitalThresholdManage = 'CapitalThresholdManage',
  /** Allows users to view Current Worst Case related information */
  CapitalThresholdView = 'CapitalThresholdView',
  /** Allows users to view Charge Schedule related information as well as perform all Charge Schedule related actions. Ex: Create Limit, Modify limit */
  ChargeScheduleManage = 'ChargeScheduleManage',
  /** Allows users to view Charge Schedule related information */
  ChargeScheduleView = 'ChargeScheduleView',
  /** Allows users to manage Firm Preferred Capacity data */
  FirmPreferredCapacityManage = 'FirmPreferredCapacityManage',
  /** Allows users to view Firm Preferred Capacity data */
  FirmPreferredCapacityView = 'FirmPreferredCapacityView',
  /** Allows users to view, create, modify, and delete Instrument Coverage related data */
  InstrumentCoverageManage = 'InstrumentCoverageManage',
  /** Allows users to view Instrument Coverage related information */
  InstrumentCoverageView = 'InstrumentCoverageView',
  /** Allows users to view Instrument Restriction related information as well as perform all Instrument Restriction related actions. Ex: Create Instrument Restriction, Modify Instrument Restriction, Delete Instrument Restriction */
  InstrumentRestrictionManage = 'InstrumentRestrictionManage',
  /** Allows users to view Instrument Restriction related information */
  InstrumentRestrictionView = 'InstrumentRestrictionView',
  /** Allows users to view/manage list data */
  ListsManage = 'ListsManage',
  /** Allows users to view list data */
  ListsView = 'ListsView',
  /** Allows users to manage reference data Ex: Add Users, Assign Access Rights, Trade Counterparties, Firm Preferred Capacity */
  ReferenceDataManage = 'ReferenceDataManage',
  /** Allows users to view reference data */
  ReferenceDataView = 'ReferenceDataView',
  /** Allows users to manage representative codes */
  RepresentativeCodesManage = 'RepresentativeCodesManage',
  /** Allows users to view representative codes */
  RepresentativeCodesView = 'RepresentativeCodesView',
  /** Allows users to view Execution Venue Risk Controls related as well as perform all Execution Venue Risk Controls related actions. Ex: Create Limit, Modify limit */
  RiskControlsManage = 'RiskControlsManage',
  /** Allows users to view Execution Venue Risk Controls related information */
  RiskControlsView = 'RiskControlsView',
  /** Allows users to Manage Routing Rules */
  RoutingRulesManage = 'RoutingRulesManage',
  /** Allows users to view Routing Rules */
  RoutingRulesView = 'RoutingRulesView',
  /** Allows users to Manage Scales */
  ScalesManage = 'ScalesManage',
  /** Allows users to view Scales */
  ScalesView = 'ScalesView',
  /** Allows users to manage trade counter-parties */
  TradeCounterpartiesManage = 'TradeCounterpartiesManage',
  /** Allows users to view trade counter-parties */
  TradeCounterpartiesView = 'TradeCounterpartiesView',
  /** Allows users to manage Users */
  UsersManage = 'UsersManage',
  /** Allows users to view Users */
  UsersView = 'UsersView'
}

/**
 * This is the master list of composite roles
 * Make sure to add a comment before the composite role, this will be used to generate the description of the role
 */
export enum RoleEntitlementsMap {
  /** Default Valstro Account Coverage Admin Role */
  CoverageAdmin = 'CoverageAdmin',
  /** Default Valstro Compliance and Risk User Role */
  DefaultComplianceAndRiskUser = 'DefaultComplianceAndRiskUser',
  /** Default Valstro Execution Trader Manager Role */
  DefaultExecutionTraderManager = 'DefaultExecutionTraderManager',
  /** Default Valstro Execution Trader User Role */
  DefaultExecutionTraderUser = 'DefaultExecutionTraderUser',
  /** Default Valstro Middle Office User Role */
  DefaultMiddleOfficeUser = 'DefaultMiddleOfficeUser',
  /** Default Valstro Program Trader Manager Role */
  DefaultProgramTraderManager = 'DefaultProgramTraderManager',
  /** Default Valstro Program Trader User Role */
  DefaultProgramTraderUser = 'DefaultProgramTraderUser',
  /** Default Valstro Report Builder Admin Role */
  DefaultReportBuiderAdmin = 'DefaultReportBuiderAdmin',
  /** Default Valstro Report Builder Role */
  DefaultReportBuilder = 'DefaultReportBuilder',
  /** Default Valstro Sales Trader Manager Role */
  DefaultSalesTraderManager = 'DefaultSalesTraderManager',
  /** Default Valstro Sales Trader User Role */
  DefaultSalesTraderUser = 'DefaultSalesTraderUser',
  /** Default Valstro Support User Role */
  DefaultSupportUser = 'DefaultSupportUser',
  /** Default Valstro Reference Data Admin Role */
  ReferenceDataAdmin = 'ReferenceDataAdmin',
  /** Default Valstro Super User Role */
  SuperUser = 'SuperUser'
}

export enum SuperUser {
  /** Allows users to view Account limits related information as well as perform all Account limit related actions. Ex: Create Limit, Modify limit, delete limit */
  AccountLimitManage = 'AccountLimitManage',
  /** Allows users to view Account limit related information */
  AccountLimitView = 'AccountLimitView',
  /** Allows users to view Account Reference Data (Reference Data Account View) as well as perform all account data maintenance related actions. (Ex: Create Account, Inactivate Account) */
  AccountManage = 'AccountManage',
  /** Allows users to view Account Reference Data */
  AccountView = 'AccountView',
  /** A User can View/Create/Modify the CapitalThreshold Fields */
  AggregationUnitManage = 'AggregationUnitManage',
  /** A User can view the CapitalThreshold Fields */
  AggregationUnitView = 'AggregationUnitView',
  /** A User can Apply FX Rate */
  ApplyFxRate = 'ApplyFxRate',
  /** Allows users to view Borrowable Stock related information as well as perform all Borrowable Stock related actions. Ex: Create New Borrowable stock and quantity, Modify Borrowable Quantity, Delete Borrowable Record */
  BorrowableStockListManage = 'BorrowableStockListManage',
  /** Allows users to view Borrowable Stock related information */
  BorrowableStockListView = 'BorrowableStockListView',
  /** Allows users to view Current Worst Case related information as well as perform all Current Worst Case related actions. Ex: Create Limit, Modify limit */
  CapitalThresholdManage = 'CapitalThresholdManage',
  /** Allows users to view Current Worst Case related information */
  CapitalThresholdView = 'CapitalThresholdView',
  /** Allows users to view Charge Schedule related information as well as perform all Charge Schedule related actions. Ex: Create Limit, Modify limit */
  ChargeScheduleManage = 'ChargeScheduleManage',
  /** Allows users to view Charge Schedule related information */
  ChargeScheduleView = 'ChargeScheduleView',
  /** Allows users to manage Coverage data from within the Coverage Widget, including approval of any account coverage changes based on access rights */
  CoverageManage = 'CoverageManage',
  /** Allows users to view Coverage data from within the Coverage Widget */
  CoverageView = 'CoverageView',
  /** Allows users to view Estimated Commission data */
  EstimatedCommissionView = 'EstimatedCommissionView',
  /** Allows users to manage Firm Preferred Capacity data */
  FirmPreferredCapacityManage = 'FirmPreferredCapacityManage',
  /** Allows users to view Firm Preferred Capacity data */
  FirmPreferredCapacityView = 'FirmPreferredCapacityView',
  /** Allows users to Force Cancel trades */
  ForceCancel = 'ForceCancel',
  /** Allows users to view, create, modify, and delete Instrument Coverage related data */
  InstrumentCoverageManage = 'InstrumentCoverageManage',
  /** Allows users to view Instrument Coverage related information */
  InstrumentCoverageView = 'InstrumentCoverageView',
  /** Allows users to view Instrument Restriction related information as well as perform all Instrument Restriction related actions. Ex: Create Instrument Restriction, Modify Instrument Restriction, Delete Instrument Restriction */
  InstrumentRestrictionManage = 'InstrumentRestrictionManage',
  /** Allows users to view Instrument Restriction related information */
  InstrumentRestrictionView = 'InstrumentRestrictionView',
  /** Allows users to view/manage list data */
  ListsManage = 'ListsManage',
  /** Allows users to view list data */
  ListsView = 'ListsView',
  /** Allows users to view/manage Montage-related information, including related market data based on visibility rights */
  MontageManage = 'MontageManage',
  /** Allows users to view order-related information (Order View) as well as perform all order actions based on access rights. Ex: Create Order, Amend Order, Cancel Order, Route Order, sending order summary */
  OrderManage = 'OrderManage',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view Position-related information as well as perform all Position-related actions Ex: Position Mark, Position Adjust, Position Transfer */
  PositionManage = 'PositionManage',
  /** Allows users to view Position-related information based on visibility rights */
  PositionView = 'PositionView',
  /** Allows users to view Program-related information (Program View) as well as perform all Program-related actions based on access rights. Ex: Create Program, Amend Program, Cancel Program */
  ProgramManage = 'ProgramManage',
  /** Allows users to view Program-related information (aka Portfolio) based on visibility rights */
  ProgramView = 'ProgramView',
  /** Allows users to manage reference data Ex: Add Users, Assign Access Rights, Trade Counterparties, Firm Preferred Capacity */
  ReferenceDataManage = 'ReferenceDataManage',
  /** Allows users to view reference data */
  ReferenceDataView = 'ReferenceDataView',
  /** Allows users to view repair queue */
  RepairQueueView = 'RepairQueueView',
  /** Allows users the full access to Report Builder for their personal reports */
  ReportBuilderManage = 'ReportBuilderManage',
  /** Allows users the full access to Report Builder for all users and all firm reports */
  ReportBuilderManageUserAndFirmManage = 'ReportBuilderManageUserAndFirmManage',
  /** Allows users to view Reports Dashboard, Scheduled Dashboard, and Status Dashboard */
  ReportBuilderView = 'ReportBuilderView',
  /** Allows users to manage representative codes */
  RepresentativeCodesManage = 'RepresentativeCodesManage',
  /** Allows users to view representative codes */
  RepresentativeCodesView = 'RepresentativeCodesView',
  /** Allows users to view Execution Venue Risk Controls related as well as perform all Execution Venue Risk Controls related actions. Ex: Create Limit, Modify limit */
  RiskControlsManage = 'RiskControlsManage',
  /** Allows users to view Execution Venue Risk Controls related information */
  RiskControlsView = 'RiskControlsView',
  /** Allows users to Manage Routing Rules */
  RoutingRulesManage = 'RoutingRulesManage',
  /** Allows users to view Routing Rules */
  RoutingRulesView = 'RoutingRulesView',
  /** Allows users to Manage Scales */
  ScalesManage = 'ScalesManage',
  /** Allows users to view Scales */
  ScalesView = 'ScalesView',
  /** Allows a user to cancel a market facing trade */
  StreetSideTradeCancel = 'StreetSideTradeCancel',
  /** Allows users to manage trade counter-parties */
  TradeCounterpartiesManage = 'TradeCounterpartiesManage',
  /** Allows users to view trade counter-parties */
  TradeCounterpartiesView = 'TradeCounterpartiesView',
  /** Allows users to manage Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view Alleged Trade Reports no matter their visibility to an Account */
  TradeReportAllegeManage = 'TradeReportAllegeManage',
  /** Allows users to Manage Alleged Trade Reports no matter their visibility to an Account */
  TradeReportAllegeView = 'TradeReportAllegeView',
  /** Allows users to view TRF/ORF related information as well as perform all TRF/ORF related actions. Ex: Modify TRF Report, Create TRF Report, Cancel Trade */
  TradeReportManage = 'TradeReportManage',
  /** Allows users to view TRF/ORF related information no matter their Visibility to the Trade that the TRF/ORF Report resulted from */
  TradeReportView = 'TradeReportView',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView',
  /** Allows users to manage Unresolved Failed Orders */
  UnresolvedFailedOrderManage = 'UnresolvedFailedOrderManage',
  /** Allows users to View Unresolved Failed Orders */
  UnresolvedFailedOrderView = 'UnresolvedFailedOrderView',
  /** Allows users to manage Users */
  UsersManage = 'UsersManage',
  /** Allows users to view Users */
  UsersView = 'UsersView'
}

/**
 * Valstro entitlements
 * This is where you need to add and or remove any entitlements, add a comment before the entitlement the comment will be used as the description of the entitlement
 */
export enum ValstroEntitlements {
  /** Allows users to view Account limits related information as well as perform all Account limit related actions. Ex: Create Limit, Modify limit, delete limit */
  AccountLimitManage = 'AccountLimitManage',
  /** Allows users to view Account limit related information */
  AccountLimitView = 'AccountLimitView',
  /** Allows users to view Account Reference Data (Reference Data Account View) as well as perform all account data maintenance related actions. (Ex: Create Account, Inactivate Account) */
  AccountManage = 'AccountManage',
  /** Allows users to view Account Reference Data */
  AccountView = 'AccountView',
  /** A User can View/Create/Modify the CapitalThreshold Fields */
  AggregationUnitManage = 'AggregationUnitManage',
  /** A User can view the CapitalThreshold Fields */
  AggregationUnitView = 'AggregationUnitView',
  /** A User can Apply FX Rate */
  ApplyFxRate = 'ApplyFxRate',
  /** Allows users to view Borrowable Stock related information as well as perform all Borrowable Stock related actions. Ex: Create New Borrowable stock and quantity, Modify Borrowable Quantity, Delete Borrowable Record */
  BorrowableStockListManage = 'BorrowableStockListManage',
  /** Allows users to view Borrowable Stock related information */
  BorrowableStockListView = 'BorrowableStockListView',
  /** Allows users to view Current Worst Case related information as well as perform all Current Worst Case related actions. Ex: Create Limit, Modify limit */
  CapitalThresholdManage = 'CapitalThresholdManage',
  /** Allows users to view Current Worst Case related information */
  CapitalThresholdView = 'CapitalThresholdView',
  /** Allows users to view Charge Schedule related information as well as perform all Charge Schedule related actions. Ex: Create Limit, Modify limit */
  ChargeScheduleManage = 'ChargeScheduleManage',
  /** Allows users to view Charge Schedule related information */
  ChargeScheduleView = 'ChargeScheduleView',
  /** Allows users to manage Coverage data from within the Coverage Widget, including approval of any account coverage changes based on access rights */
  CoverageManage = 'CoverageManage',
  /** Allows users to view Coverage data from within the Coverage Widget */
  CoverageView = 'CoverageView',
  /** Allows users to view Estimated Commission data */
  EstimatedCommissionView = 'EstimatedCommissionView',
  /** Allows users to manage Firm Preferred Capacity data */
  FirmPreferredCapacityManage = 'FirmPreferredCapacityManage',
  /** Allows users to view Firm Preferred Capacity data */
  FirmPreferredCapacityView = 'FirmPreferredCapacityView',
  /** Allows users to Force Cancel trades */
  ForceCancel = 'ForceCancel',
  /** Allows users to view, create, modify, and delete Instrument Coverage related data */
  InstrumentCoverageManage = 'InstrumentCoverageManage',
  /** Allows users to view Instrument Coverage related information */
  InstrumentCoverageView = 'InstrumentCoverageView',
  /** Allows users to view Instrument Restriction related information as well as perform all Instrument Restriction related actions. Ex: Create Instrument Restriction, Modify Instrument Restriction, Delete Instrument Restriction */
  InstrumentRestrictionManage = 'InstrumentRestrictionManage',
  /** Allows users to view Instrument Restriction related information */
  InstrumentRestrictionView = 'InstrumentRestrictionView',
  /** Allows users to view/manage list data */
  ListsManage = 'ListsManage',
  /** Allows users to view list data */
  ListsView = 'ListsView',
  /** Allows users to view/manage Montage-related information, including related market data based on visibility rights */
  MontageManage = 'MontageManage',
  /** Allows users to view order-related information (Order View) as well as perform all order actions based on access rights. Ex: Create Order, Amend Order, Cancel Order, Route Order, sending order summary */
  OrderManage = 'OrderManage',
  /** Allows users to view order-related information based on visibility rights */
  OrderView = 'OrderView',
  /** Allows users to view Position-related information as well as perform all Position-related actions Ex: Position Mark, Position Adjust, Position Transfer */
  PositionManage = 'PositionManage',
  /** Allows users to view Position-related information based on visibility rights */
  PositionView = 'PositionView',
  /** Allows users to view Program-related information (Program View) as well as perform all Program-related actions based on access rights. Ex: Create Program, Amend Program, Cancel Program */
  ProgramManage = 'ProgramManage',
  /** Allows users to view Program-related information (aka Portfolio) based on visibility rights */
  ProgramView = 'ProgramView',
  /** Allows users to manage reference data Ex: Add Users, Assign Access Rights, Trade Counterparties, Firm Preferred Capacity */
  ReferenceDataManage = 'ReferenceDataManage',
  /** Allows users to view reference data */
  ReferenceDataView = 'ReferenceDataView',
  /** Allows users to view repair queue */
  RepairQueueView = 'RepairQueueView',
  /** Allows users the full access to Report Builder for their personal reports */
  ReportBuilderManage = 'ReportBuilderManage',
  /** Allows users the full access to Report Builder for all users and all firm reports */
  ReportBuilderManageUserAndFirmManage = 'ReportBuilderManageUserAndFirmManage',
  /** Allows users to view Reports Dashboard, Scheduled Dashboard, and Status Dashboard */
  ReportBuilderView = 'ReportBuilderView',
  /** Allows users to manage representative codes */
  RepresentativeCodesManage = 'RepresentativeCodesManage',
  /** Allows users to view representative codes */
  RepresentativeCodesView = 'RepresentativeCodesView',
  /** Allows users to view Execution Venue Risk Controls related as well as perform all Execution Venue Risk Controls related actions. Ex: Create Limit, Modify limit */
  RiskControlsManage = 'RiskControlsManage',
  /** Allows users to view Execution Venue Risk Controls related information */
  RiskControlsView = 'RiskControlsView',
  /** Allows users to Manage Routing Rules */
  RoutingRulesManage = 'RoutingRulesManage',
  /** Allows users to view Routing Rules */
  RoutingRulesView = 'RoutingRulesView',
  /** Allows users to Manage Scales */
  ScalesManage = 'ScalesManage',
  /** Allows users to view Scales */
  ScalesView = 'ScalesView',
  /** Allows a user to cancel a market facing trade */
  StreetSideTradeCancel = 'StreetSideTradeCancel',
  /** Allows users to manage trade counter-parties */
  TradeCounterpartiesManage = 'TradeCounterpartiesManage',
  /** Allows users to view trade counter-parties */
  TradeCounterpartiesView = 'TradeCounterpartiesView',
  /** Allows users to manage Trade-related information (Trade View) as well as perform any Trade-related actions Ex: Enter Trade, Amend Trade, Cancel non-street side Trade, cancel executions */
  TradeManage = 'TradeManage',
  /** Allows users to view Alleged Trade Reports no matter their visibility to an Account */
  TradeReportAllegeManage = 'TradeReportAllegeManage',
  /** Allows users to Manage Alleged Trade Reports no matter their visibility to an Account */
  TradeReportAllegeView = 'TradeReportAllegeView',
  /** Allows users to view TRF/ORF related information as well as perform all TRF/ORF related actions. Ex: Modify TRF Report, Create TRF Report, Cancel Trade */
  TradeReportManage = 'TradeReportManage',
  /** Allows users to view TRF/ORF related information no matter their Visibility to the Trade that the TRF/ORF Report resulted from */
  TradeReportView = 'TradeReportView',
  /** Allows users to view trade-related information based on visibility rights */
  TradeView = 'TradeView',
  /** Allows users to manage Unresolved Failed Orders */
  UnresolvedFailedOrderManage = 'UnresolvedFailedOrderManage',
  /** Allows users to View Unresolved Failed Orders */
  UnresolvedFailedOrderView = 'UnresolvedFailedOrderView',
  /** Allows users to manage Users */
  UsersManage = 'UsersManage',
  /** Allows users to view Users */
  UsersView = 'UsersView'
}


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    


export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;