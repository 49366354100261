import type { PartitionValue } from '@oms/shared/util-types';

export const partitionMontageBy = <TData, TSelector>(
  dataset: TData[],
  comparator: (a: TData, b: TData) => number,
  selector: (d: TData) => TSelector,
  partitions: number = 3,
  result: Map<TSelector, PartitionValue<TData>> = new Map<TSelector, PartitionValue<TData>>()
): Map<TSelector, PartitionValue<TData>> => {
  if (!dataset || !dataset.length) return result;

  dataset.sort(comparator);

  let currentMax = dataset[0];
  let partitionCount = 1;
  let idx = 0;
  do {
    const comp = comparator(currentMax, dataset[idx]);
    const key = selector(dataset[idx]);
    const val = result.has(key)
      ? (result.get(key) as PartitionValue<TData>)
      : { data: [], rank: partitionCount - 1 };
    val.data.push(dataset[idx]);

    if (comp === 0) {
      result.set(key, val);
    } else {
      partitionCount++;
      currentMax = dataset[idx];
      val.rank++;
      result.set(key, val);
    }

    idx++;
  } while (partitionCount < partitions && idx < dataset.length);

  return result;
};
