import type { GridApi } from '@ag-grid-community/core';
import type { ActionDefFactory } from '@oms/frontend-vgrid';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import type PositionsInstrumentGridService from '../services/positions-instrument-grid.service';

export const GROUP_BY_INSTRUMENT_ACTION = 'group_by_instrument';

export const groupByInstrumentAction =
  (service: PositionsInstrumentGridService): ActionDefFactory<PositionRow> =>
  (builder) =>
    builder
      .name(GROUP_BY_INSTRUMENT_ACTION)
      .toolbar((t) =>
        t
          .component('action-switch')
          .id('group_by_instrument_button')
          .location('HorizontalToolbarLeft')
          .props({
            content: 'Group by instrument' // TODO t()
          })
      )
      .onChange(({ lifecycle, api }) => {
        switch (lifecycle) {
          case 'init':
            if (!service.isReady) service.init(api as GridApi<PositionRow>);
            break;
          case 'change':
            service.toggleIsGroupedByInstrument();
        }
      });

export default groupByInstrumentAction;
