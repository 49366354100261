import { useMemo } from 'react';
import type { ButtonVariants } from './css/button.css';
import * as styles from './css/button-group.css';
import clsx from 'clsx';
import { Stack } from '../../layout/stack/stack';
import type { StackProps } from '../../layout/stack/stack';
import { BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { createContext } from '../../system/utils/create-context';

export type ButtonGroupProps<Content = string> = ButtonVariants &
  StackProps &
  BoxProps & {
    buttonContent?: Content;
  };

const [ButtonGroupProvider, useButtonGroup] = createContext<ButtonGroupProps>({
  strict: false,
  name: 'ButtonGroupContext'
});

export { useButtonGroup };

/**
 * Check for possible group button props and use them as fallbacks.
 * @param [variants] - Varients passed to the primary button by props. Hint: You can pass button props.
 * @param [options] - Options, such as the ability to suppress defaults (allowing undefineds in the output)
 * @returns Merged style variants (single button, group and defaults)
 */
export function useButtonGroupStyle(
  variants?: ButtonVariants,
  options?: { suppressDefaults?: boolean }
): ButtonVariants {
  const group = useButtonGroup();
  const { size, variant, palette, shape, elevation } = variants ?? {};
  const { suppressDefaults } = options ?? {};
  return useMemo(
    () => ({
      size: size ?? group?.size ?? (suppressDefaults ? undefined : 'sm'),
      variant: variant ?? group?.variant ?? (suppressDefaults ? undefined : 'primary'),
      palette: palette ?? group?.palette ?? (suppressDefaults ? undefined : 'standard'),
      shape: shape ?? group?.shape ?? (suppressDefaults ? undefined : 'rectangle'),
      elevation: elevation ?? group?.elevation ?? (suppressDefaults ? undefined : 'standard')
    }),
    [size, variant, palette, shape, elevation, group, suppressDefaults]
  );
}

export const ButtonGroup = polymorphicComponent<'div', ButtonGroupProps>((props, ref) => {
  const {
    as: component = 'div',
    spacing = 0,
    children,
    shape,
    variant,
    size,
    palette,
    className,
    ...rest
  } = props;

  const context = useMemo(() => ({ shape, variant, palette, size }), [shape, variant, palette, size]);

  const hasSpacing = spacing !== 0; // TODO: DV: Storybook injects a string, need it to be a number
  const hasGroupShadow = (!variant || variant === 'primary' || variant === 'secondary') && !hasSpacing; // TODO: Does this make sense for new types?

  return (
    <ButtonGroupProvider value={context}>
      <Stack
        as={component}
        spacing={spacing}
        {...rest}
        className={clsx(
          styles.buttonGroup,
          !hasSpacing && styles.buttonGroupZeroSpacing,
          hasGroupShadow && styles.buttonGroupShadow,
          className
        )}
        ref={ref}
      >
        {children}
      </Stack>
    </ButtonGroupProvider>
  );
});

if (__DEV__) {
  ButtonGroup.displayName = 'ButtonGroup';
}
