import type { EditableDropdownListControl } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { getListItems } from './control.transformer.types';
import type { ControlTransformer } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const editableDropdownListControl: ControlTransformer<EditableDropdownListControl> = (
  editableDropdownListControl,
  _query,
  formValues
) => {
  return {
    component: fixatdlFormComponentTypes.EDITABLE_DROPDOWN_LIST,
    name: editableDropdownListControl.id,
    label: editableDropdownListControl.label,
    initialValue: AtdlFormats.coerceValue(formValues[editableDropdownListControl.id]),
    options: getListItems(editableDropdownListControl.listItems)
  };
};
