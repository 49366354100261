import { ValstroEntitlements } from '@oms/generated/frontend';
import type { ExchangeFragment } from '@oms/generated/frontend';
import { crudWindowGridActionBuilder } from '@app/common/grids/actions/crud-window.actions';
import { ExchangesService } from '@app/data-access/services/reference-data/exchanges/exchanges.service';

const exchangeActionBuilder = crudWindowGridActionBuilder<ExchangeFragment>('Exchange', [
  ValstroEntitlements.ReferenceDataManage
]);

export const createAction = exchangeActionBuilder('CREATE', {
  formKey: 'EXCHANGE_FORM',
  titleGetter: () => 'Create Exchange'
});

export const updateAction = exchangeActionBuilder('UPDATE', {
  formKey: 'EXCHANGE_FORM',
  rowDataToFormInput: (rowData: ExchangeFragment) => ({
    id: rowData.id
  }),
  titleGetter: (rows: ExchangeFragment[]) => `Edit ${rows[0].id ?? ''}`
});

export const deleteAction = exchangeActionBuilder('DELETE', {
  titleGetter: (rows) => `Edit ${rows[0].id ?? ''}`,
  onConfirmDelete: async (selectedRows, ctx) => {
    const exchangesService = ctx.appContainer.resolve(ExchangesService);
    await exchangesService.delete(selectedRows.map((row) => row.id));
  }
});
