import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import type { TradingOrderRow } from '../trading-order-monitor.contracts';
import { openViewTradingOrder } from '@app/common/types/orders/trading-order/open.view.trading-order.table-server';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';

function isVisible(data: TradingOrderRow[]) {
  return data.length === 1;
}

export const viewTradingOrderOnChange = async (
  ctx: ActionContext<TradingOrderRow, ActionComponentConfig<TradingOrderRow>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openViewTradingOrder({ windowId: getLeaderOrTabId(ctx.appContainer), tradingOrderData: data[0] });
  }
};

export const viewTradingOrderAction: ActionDefFactory<TradingOrderRow> = (builder) =>
  builder
    .name('view_trading_order')
    .toolbar((t) =>
      t.component('action-button').id('view_trading_order_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'View'
      })
    )
    .customMenu((m) => m.name('View Order').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<TradingOrderRow>>(viewTradingOrderOnChange);
