import { OrderSideType } from '@oms/generated/frontend';
import type { RequestPrincipalFillMutation, RequestPrincipalFillEnrichedData } from '@oms/generated/frontend';
import type { PrincipalFillFormOutput } from './principal-fill.contracts';
import type { PrincipalFillFormValues } from './principal-fill.form-contract';
import { isEmpty } from 'lodash';

const sideMap = {
  [OrderSideType.Buy]: OrderSideType.Sell,
  [OrderSideType.Btc]: OrderSideType.Sell,
  [OrderSideType.Sell]: OrderSideType.Buy,
  [OrderSideType.Short]: OrderSideType.Buy,
  [OrderSideType.Exempt]: OrderSideType.Buy
};

export const reverseMapSideType = (sideType: OrderSideType | null | undefined) => {
  if (!sideType) {
    return null;
  }
  return sideMap[sideType];
};

export const enrichFieldValues = async (
  response: RequestPrincipalFillMutation['requestPrincipalFill'],
  output: PrincipalFillFormOutput | null | undefined
): Promise<Partial<PrincipalFillFormValues> | null> => {
  const enrichedData = response?.enrichedData;
  if (!enrichedData) {
    return null;
  }

  const formValues: Partial<PrincipalFillFormValues> = {};
  const keys = Object.keys(enrichedData) as (keyof RequestPrincipalFillEnrichedData)[];

  for (const key of keys) {
    switch (key) {
      case 'firmAccount': {
        const currentFirmAccountId = output?.firmAccount?.id;
        if (!enrichedData.firmAccount || enrichedData.firmAccount?.id === currentFirmAccountId) {
          break;
        }

        const { id, name } = enrichedData.firmAccount;

        formValues.firmAccount = {
          id,
          label: name
        };

        break;
      }
      case 'capacity': {
        if (enrichedData.capacity && output?.capacity !== enrichedData.capacity) {
          formValues.capacity = enrichedData.capacity;
        }
        break;
      }
      case 'price': {
        if (enrichedData.price && output?.price !== enrichedData.price) {
          formValues.price = enrichedData.price;
        }
        break;
      }
      case 'quantity': {
        if (enrichedData.quantity && output?.quantity !== enrichedData.quantity) {
          formValues.quantity = enrichedData.quantity;
        }
        break;
      }
      case 'locate': {
        if (enrichedData.locate && output?.locate !== enrichedData.locate) {
          formValues.locate = enrichedData.locate;
        }
        break;
      }
      case 'regNmsApplicability': {
        if (
          enrichedData.regNmsApplicability &&
          formValues.regNmsApplicability !== enrichedData.regNmsApplicability
        ) {
          formValues.regNmsApplicability = enrichedData.regNmsApplicability;
        }
      }
    }
  }

  return isEmpty(formValues) ? null : formValues;
};
