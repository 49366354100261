import { type FC, useCallback, useMemo } from 'react';
import type { AlertBannerStackItem, AlertBannerStackProps } from './types';
import AlertBanner from './alert-banner.component';
import { Box } from '../../system/components/box/box';
import { combineAlertsByLevel, useClassName } from './util.internal';

export const AlertBannerStack: FC<AlertBannerStackProps> = ({
  alerts,
  filter,
  config,
  combine,
  alertLevelOverridesForCombined,
  sx,
  variant = 'md'
}) => {
  const className = useClassName(variant);
  const itemClassName = useClassName('stackItem');

  const filteredAlerts = useMemo(() => {
    if (!filter) return alerts;
    return alerts.filter(filter);
  }, [alerts, filter]);

  const combinedAlerts = useMemo(() => {
    if (!combine) return filteredAlerts;
    return combineAlertsByLevel(filteredAlerts, alertLevelOverridesForCombined);
  }, [filteredAlerts, combine, alertLevelOverridesForCombined]);

  const getKey = useCallback((alertBannerStackItem: AlertBannerStackItem, index: number) => {
    const { level, messages: message } = alertBannerStackItem;
    return `${index}-${level}-${message}`;
  }, []);

  return combinedAlerts.length ? (
    <Box className={className} sx={sx}>
      {combinedAlerts.map((alertBannerStackItem, index) => (
        <Box key={getKey(alertBannerStackItem, index)} className={itemClassName}>
          <AlertBanner {...alertBannerStackItem} config={config} />
        </Box>
      ))}
    </Box>
  ) : null;
};

export default AlertBannerStack;
