import { Lifecycle, scoped, inject } from 'tsyringe';
import { Subscription } from 'rxjs';
import { RowDataUpdatedEvent } from '@ag-grid-community/core';
import {
  EventHandler,
  EventSource,
  GridEventType,
  VGridContextInstance,
  VGridInstance
} from '@oms/frontend-vgrid';

export const createCloseOnEmptyEventHandler = (autoClose: boolean = false) => {
  @scoped(Lifecycle.ContainerScoped)
  class CloseOnEmptyEventHandler implements EventHandler {
    public name = 'close-on-empty';
    private subscriptions: Subscription[] = [];
    private hasData: boolean = false;

    constructor(@inject(VGridInstance.Context) private context: VGridContextInstance) {}

    public addEvents(eventSource: EventSource<GridEventType>): void {
      eventSource.add('onRowDataUpdated', (e: RowDataUpdatedEvent) => this.onRowDataUpdatedCallback(e));
    }

    public onRowDataUpdatedCallback(e: RowDataUpdatedEvent): void {
      const allRowData = e.api.getDisplayedRowCount();
      const shouldClose = autoClose && this.hasData && allRowData === 0;
      if (shouldClose) {
        this.closeOnEmpty();
      } else {
        this.hasData = allRowData > 0;
      }
    }

    public closeOnEmpty(): void {
      this.context.windowActor.operations.close().catch(console.error);
    }

    public removeEvents(): void {
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.subscriptions = [];
    }
  }

  return CloseOnEmptyEventHandler;
};
