import { FC } from 'react';
import { Text } from '../../layout/text/text';
import { TextEllipser } from '../text-ellipser/text-ellipser';
import { DISPLAY_FIELD_COMPONENT_TYPE } from '../display-field/display-field.contracts';

export type LinkProps = {
  type: typeof DISPLAY_FIELD_COMPONENT_TYPE.Link;
  text: string;
};

export const Link: FC<LinkProps> = ({ text }) => {
  return (
    <TextEllipser sx={{ color: 'BrandBlue.300' }}>
      <Text>{text}</Text>
    </TextEllipser>
  );
};
