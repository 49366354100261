import type { ComponentType } from 'react';
import type { AnyRecord } from '@oms/frontend-foundation';
import { VComponent, ComponentLocationKeys, ComponentLocation } from '../models/v.component.model';
import { COMPONENT_REGISTRY, ComponentRegistryProps } from '../renderers/components';

export class ComponentBuilder<TData extends AnyRecord, TProps extends AnyRecord = AnyRecord> {
  private _component: VComponent<TProps>;

  constructor(component: Partial<VComponent<TProps>> = {}) {
    this._component = component as VComponent<TProps>;
  }

  public id(id: string): ComponentBuilder<TData, TProps> {
    this._component.id = id;
    return this;
  }

  public component<
    K extends keyof ComponentRegistryProps<TData>,
    P extends AnyRecord = ComponentRegistryProps<TData>[K]
  >(componentKey: K): ComponentBuilder<TData, P> {
    const component = COMPONENT_REGISTRY.get(componentKey);
    if (!component) {
      throw new Error(`Component ${componentKey} not found`);
    }
    return new ComponentBuilder<TData, P>({
      ...(this._component as VComponent<P>),
      component
    });
  }

  public customComponent<TCustomProps extends AnyRecord = AnyRecord>(
    component: ComponentType<TCustomProps>
  ): ComponentBuilder<TData, TCustomProps> {
    return new ComponentBuilder<TData, TCustomProps>({
      ...(this._component as VComponent<TCustomProps>),
      component
    });
  }

  public props(props: Partial<TProps>): ComponentBuilder<TData, TProps> {
    this._component.props = props;
    return this;
  }

  public location(location: ComponentLocationKeys): ComponentBuilder<TData, TProps> {
    this._component.location = ComponentLocation[location];
    return this;
  }

  public build(): VComponent<TProps> {
    if (!this._component.id) {
      throw new Error('Component must have an id.');
    }

    return this._component as VComponent<TProps>;
  }
}
