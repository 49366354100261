import type { ActionCommandContracts } from '@app/actions/commands/command.registry.types';
import type { CommandRenderCallback } from '@app/actions/grids/command.render.strategy.types';

export const bumpPriceRenderer: CommandRenderCallback<ActionCommandContracts['montage_bump_price']> = (
  ctx
) => {
  const { actionContext$, ...action } = ctx;
  const { label, payload } = action;

  return payload?.bumpPrice || label;
};
