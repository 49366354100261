import { createContext, useContext } from 'react';
import { Workspace as WorkspaceInstance } from '@valstro/workspace';

export interface ReactWorkspaceContext<TWorkspace extends WorkspaceInstance = WorkspaceInstance> {
  workspace: TWorkspace;
}

export const ReactWorkspaceContext = createContext<ReactWorkspaceContext>({} as ReactWorkspaceContext);

/**
 * Use the current workspace
 * Useful for running & accessing workspace APIs
 *
 * @returns Workspace - The current workspace
 */
export const useWorkspace = <TWorkspace extends WorkspaceInstance = WorkspaceInstance>() =>
  (useContext(ReactWorkspaceContext) as ReactWorkspaceContext<TWorkspace>).workspace;
