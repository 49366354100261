import { Fragment } from 'react';
import type { RefObject, FC } from 'react';
import { Flex } from '@oms/shared-frontend/ui-design-system';
import { DepthGrid } from './depth-grid/depth.grid';
import { useMontageInstrumentDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-details.hook';
import { useSyncDepthScroll } from './use-sync-depth-scroll';
import { useLevel2Data } from './depth-grid/hooks/use-level2-data.hook';
import {
  DATA_AVAILABLE_ON_DESKTOP_APPLICATION_ONLY,
  FORCE_CONNECT_TO_SIDECAR,
  useShouldConnectToSidecar
} from '@app/tauri/useShouldConnectToSidecar.hook';
import { useService } from '@oms/frontend-foundation';
import { LocalStorageService } from '@app/data-access/services/system/storage/local-storage.service';
import { getEnvVar } from '@app/common/env/env.util';
import { PLEASE_SELECT_AN_INSTRUMENT } from '@app/containers/matched-investor-orders/matched-investor-orders-checkbox-group';

interface DepthGridPanesProps {
  bidRef: RefObject<HTMLDivElement>;
  askRef: RefObject<HTMLDivElement>;
}

const DepthGridPanes: FC<DepthGridPanesProps> = ({ bidRef, askRef }) => {
  const level2RawData$ = useLevel2Data();

  return (
    <Fragment>
      <Flex direction="column" sx={{ height: 'full', width: 'full', marginRight: 2 }} ref={bidRef}>
        <DepthGrid level2RawData$={level2RawData$} type="bid" />
      </Flex>
      <Flex direction="column" sx={{ height: 'full', width: 'full' }} ref={askRef}>
        <DepthGrid level2RawData$={level2RawData$} type="ask" />
      </Flex>
    </Fragment>
  );
};

export const MontageDepthGrid: FC = () => {
  const [ref, bidRef, askRef] = useSyncDepthScroll();
  const instrumentDetails = useMontageInstrumentDetails();

  const isDevMode: boolean = getEnvVar('NODE_ENV') === 'development';

  const localStorageService = useService(LocalStorageService);

  const shouldConnectToSidecar = useShouldConnectToSidecar(
    localStorageService.getBool(FORCE_CONNECT_TO_SIDECAR) || isDevMode
  );

  return (
    <Flex
      ref={ref}
      direction="row"
      sx={{ height: 'full', width: 'full', overflow: 'hidden', borderRadius: 'base' }}
    >
      {instrumentDetails && shouldConnectToSidecar ? (
        <DepthGridPanes bidRef={bidRef} askRef={askRef} />
      ) : (
        <Flex sx={{ height: 'full', width: 'full' }} align="center" justify="center">
          <p>
            {!instrumentDetails ? PLEASE_SELECT_AN_INSTRUMENT : DATA_AVAILABLE_ON_DESKTOP_APPLICATION_ONLY}
          </p>
        </Flex>
      )}
    </Flex>
  );
};
