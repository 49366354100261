import { getOperationName } from '@apollo/client/utilities';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import type { DataSourceCommon } from '@oms/frontend-foundation';
import type { CountriesQuery, CountriesQueryVariables, CountryFragment } from '@oms/generated/frontend';
import { CountriesDocument } from '@oms/generated/frontend';
import type { Observable } from 'rxjs';
import { catchError, map, of } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

const COUNTRIES_QUERY_NAME = getOperationName(CountriesDocument);

@testScoped
@singleton()
export class CountriesService {
  public static refetchQueryName = COUNTRIES_QUERY_NAME;
  public static refetchQueries = COUNTRIES_QUERY_NAME ? [COUNTRIES_QUERY_NAME] : [];

  constructor(@inject(ApolloClientRPC) private apolloClient: ApolloClientRPC) {}

  watchAll$(id?: string): Observable<DataSourceCommon<CountryFragment>> {
    return this.apolloClient
      .watchQuery<CountriesQuery, CountriesQueryVariables>({
        query: CountriesDocument,
        variables: {
          offset: 0,
          first: 1000,
          filter: id
            ? {
                id: { includesInsensitive: id }
              }
            : undefined
        }
      })
      .pipe(
        map((res) => {
          const data = res.data;
          const results = data.countries?.nodes ? (data.countries.nodes as CountryFragment[]) : [];
          return { isFetching: false, results };
        }),
        catchError((e) => {
          console.error(e);
          return of({ isFetching: false, results: [], error: e as Error });
        })
      );
  }
}
