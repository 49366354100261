import { CommonPopoverActorSchema, EMPTY_POPOVER_CTX } from './popover.contracts';

/**
 * Common Schema
 */
export type CommonPopoverActorSchemaOverrides = (
  prev: Omit<CommonPopoverActorSchema, 'view'>
) => Partial<Omit<CommonPopoverActorSchema, 'view'>>;

export const commonPopoverSchemaOverrides: CommonPopoverActorSchemaOverrides = (prevSchema) => ({
  ...prevSchema,
  ignoreFromTakeSnapshot: true,
  ignoreFromApplySnapshot: true,
  syncOnStart: false,
  sync: () => {
    // noop. Do not sync.
  },
  initialContext: async (ctx) => ({
    ...(await prevSchema.initialContext(ctx)),
    title: 'Popover',
    alwaysOnTop: true,
    isVisible: false,
    width: 400,
    height: 400,
    isClosable: false,
    skipTaskbar: true,
    isMaximizable: false,
    isMinimizable: false,
    ...EMPTY_POPOVER_CTX
  })
});
