import { useMemo } from 'react';
import { parseISO, format } from 'date-fns';
import type {
  VisibleInvestorOrderInfoFragment,
  VisibleInvestorOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import {
  Box,
  DISPLAY_FIELD_COMPONENT_TYPE,
  DisplayGrid,
  DISPLAY_GRID_CUSTOM_COMPONENT_TYPE
} from '@oms/shared-frontend/ui-design-system';
import type { DisplayGridProps } from '@oms/shared-frontend/ui-design-system';

type GridPopoverExecutionsProps = {
  dailyExecutions?: VisibleInvestorOrderInfoFragment['dailyExecutions'];
  totalExecutedQty?: VisibleInvestorOrderInfoFragment['executedQuantity'];
  totaAveragePrice?: VisibleInvestorOrderInfoFragment['averagePrice'];
};

const gridProps: DisplayGridProps['gridProps'] = {
  columns: 3,
  rows: 4,
  columnGap: 10,
  rowGap: 3
};

const gridPopoverExecutionsProps: DisplayGridProps['gridProps'] = {
  columns: 1,
  rows: 1,
  columnGap: 0,
  rowGap: 2
};

const formatMonthAndDay = (dateString: string): string => {
  const date = parseISO(dateString);
  const formattedDate = format(date, 'MMM dd');
  return formattedDate;
};

const gridPopoverDailyExecutions = ({
  dailyExecutions,
  totalExecutedQty,
  totaAveragePrice
}: GridPopoverExecutionsProps): DisplayGridProps['items'] => {
  if (!dailyExecutions?.length) {
    return [];
  }

  const dailyExecutionRows: DisplayGridProps['items'] = dailyExecutions?.map((execution) => {
    const { averagePrice, executedQuantity, executionDate } = execution;

    const today = new Date().toISOString().split('T')[0];

    return {
      component: {
        label: executionDate === today ? 'Today' : `${formatMonthAndDay(executionDate)}`,
        type: DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.DailyStats,
        items: [
          {
            value: executedQuantity,
            format: 'quantity'
          },
          {
            value: averagePrice,
            format: 'decimal'
          }
        ]
      }
    };
  });

  const totalRowStyle = {
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderColor: 'text.semiMinor',
    paddingTop: '3',
    paddingInline: '3',
    marginLeft: '-3',
    marginRight: '-3'
  } as Record<string, string>;

  return [
    ...dailyExecutionRows,
    {
      component: {
        label: 'Total',
        type: DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.DailyStats,
        sx: totalRowStyle,
        items: [
          {
            value: totalExecutedQty || 0,
            format: 'quantity'
          },
          {
            value: totaAveragePrice || 0,
            format: 'decimal'
          }
        ]
      }
    }
  ];
};

const getDisplayGridItems = (
  values: VisibleInvestorOrderInfoFragment,
  isToday?: boolean
): DisplayGridProps['items'] => {
  const items = gridPopoverDailyExecutions({
    dailyExecutions: values.dailyExecutions || [],
    totalExecutedQty: values.executedQuantity || 0,
    totaAveragePrice: values.averagePrice || 0
  });

  return [
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover,
        popoverContent: {
          gridProps: gridPopoverExecutionsProps,
          items,
          style: { minWidth: '21rem' },
          hasClose: false,
          testId: 'io-view-daily-executions-summary-popover'
        },
        value: {
          component: {
            type: DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.Stats,
            items: [
              {
                label: isToday ? 'Filled today' : 'Filled total',
                value: isToday ? values.todayExecutedQuantity || 0 : values.executedQuantity || 0,
                format: 'quantity'
              },
              {
                label: isToday ? 'Avg price today' : 'Avg price total',
                sublabel: values.tradeCurrency ? values.tradeCurrency : '',
                value: isToday ? values.todayAveragePrice || 0 : values.averagePrice || 0,
                format: 'decimal'
              }
            ],
            isTextActive: !!items.length,
            width: '100%'
          },
          testId: ''
        }
      },
      rowSpan: 4
    },
    {
      label: 'Leaves Quantity',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: values.leavesQuantity || 0,
        format: 'quantity'
      }
    },
    {
      label: 'Working/Open',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: [values.workingQuantity || 0, values.openQuantity || 0],
        format: 'quantity',
        display: 'fraction'
      }
    },
    {
      label: 'Order VWAP',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Arrival Price',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Open Price',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Close',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Instrument VWAP',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    },
    {
      label: 'Previous Close',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: '' // TODO: not yet available
      }
    }
  ];
};

export const InvestorOrderSummary = ({
  investorOrder,
  isToday
}: {
  investorOrder: VisibleInvestorOrderInfoWithAllocationsFragment;
  isToday: boolean;
}) => {
  const displayGridItems = useMemo(() => {
    return getDisplayGridItems(investorOrder, isToday);
  }, [investorOrder, isToday]);

  return (
    <Box sx={{ padding: 5 }}>
      <DisplayGrid items={displayGridItems} gridProps={gridProps} labelSize="small" />
    </Box>
  );
};
