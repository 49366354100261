import { createRxDatabase, removeRxDatabase } from 'rxdb-v15';
import type { RxDatabase, RxStorage, RxPlugin } from 'rxdb-v15';
import { useWorkspace } from '@valstro/workspace-react';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { getRxStorageDexie } from 'rxdb-v15/plugins/storage-dexie';
import { OFFLINE_DB_COLLECTIONS_MAP } from './collections';
import type { OfflineDatabaseCollections } from './collections';
import { addRxPlugin } from 'rxdb-v15';
import { RxDBDevModePlugin } from 'rxdb-v15/plugins/dev-mode';
import { RxDBQueryBuilderPlugin } from 'rxdb-v15/plugins/query-builder';
import { observeNewCollections } from 'rxdb-hooks-v5';
import { RxDBMigrationPlugin } from 'rxdb-v15/plugins/migration-schema';
import { RxDBUpdatePlugin } from 'rxdb-v15/plugins/update';
import { RxDBCleanupPlugin } from 'rxdb-v15/plugins/cleanup';
import { RxDBLeaderElectionPlugin } from 'rxdb-v15/plugins/leader-election';
import { getEnvVar } from '@app/common/env/env.util';

addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(observeNewCollections as RxPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBCleanupPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

if (getEnvVar('NODE_ENV') === 'development') {
  addRxPlugin(RxDBDevModePlugin);
}

export type OfflineDatabase = RxDatabase<OfflineDatabaseCollections>;

export interface OfflineDatabaseOptions {
  storage?: RxStorage<any, any>;
  ignoreDuplicate?: boolean;
  name?: string;
}

export async function createOfflineDatabase(options: OfflineDatabaseOptions = {}) {
  const storage = options.storage || getRxStorageDexie();
  const name = options.name || 'app-offline';
  const db = await createRxDatabase<OfflineDatabaseCollections>({
    name,
    storage,
    ignoreDuplicate: options.ignoreDuplicate || false
  });
  return { db, storage };
}

export async function addOfflineDatabaseCollections(db: OfflineDatabase) {
  await db.addCollections(OFFLINE_DB_COLLECTIONS_MAP);
}

export function useOfflineDatabase() {
  const workspace = useWorkspace<AppWorkspace>();
  if (!workspace.getMeta()?.offlineDatabase) {
    throw new Error('App database not ready');
  }

  return workspace.getMeta().offlineDatabase;
}

export function getOfflineDatabase(workspace: AppWorkspace) {
  const db = workspace.getMeta().offlineDatabase;
  if (!db) {
    throw new Error('App database not ready');
  }
  return db;
}

export const destroyAndClearDatabase = async (db: OfflineDatabase, storage: RxStorage<any, any>) => {
  await db.destroy();
  await removeRxDatabase(db.name, storage);
};
