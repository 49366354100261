import { FormContract, FieldDefinition, FieldContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { RejectPendingModificationFormSchemaInput } from './reject-pending-modification.form-builder.config';
import type { RejectInvestorOrderModificationMutationVariables } from '@oms/generated/frontend';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';

// Common
export type RejectPendingModificationFormInput = RejectPendingModificationFormSchemaInput;
export type RejectPendingModificationFormOutput = RejectInvestorOrderModificationMutationVariables;

// Fields
const ofc = FieldContract.create<RejectPendingModificationFormOutput, AdditionalFormFieldUnion>();

export const idField = ofc.field('modificationId', 'hidden-field');

export const reasonField = ofc.field('rejectReason', 'text-field').options({
  label: 'Reject reason',
  isRequired: true,
  validate: [{ type: 'required' }]
});

// Form contract
export const rejectPendingModificationFormContract =
  FormContract.create<RejectPendingModificationFormOutput>()
    .fields({
      modificationId: idField,
      rejectReason: reasonField
    })
    .schema((f) => ({
      fields: [FieldDefinition.box('wrapper', [f.modificationId, f.rejectReason])]
    }))
    .template('simple', {
      submitLabel: 'Reject',
      cancelLabel: 'Cancel',
      formFieldsSx: { px: 4, pb: 2, marginTop: 4 }
    });

export type RejectPendingModificationFormContractType = typeof rejectPendingModificationFormContract;

export type RejectPendingModificationFormValues =
  InferFormValuesFromFormContract<RejectPendingModificationFormContractType>;
