import { PropsWithChildren, forwardRef, useCallback, useEffect, useRef } from 'react';
import { useMergedRef } from './use-merge-ref';
import {
  Actor,
  CommonWindowActorSchema,
  WindowResizeDir,
  WindowResizeOrigin,
  isDefined
} from '@valstro/workspace';

export interface ResizableWindowWrapperProps {
  origin?: WindowResizeOrigin;
  direction?: WindowResizeDir;
  windowActor: Actor<CommonWindowActorSchema>;
  className?: string;
  style?: React.CSSProperties;
  scaleFactor?: number;
  paddingWidth?: number;
  paddingHeight?: number;
  /**
   * The sensitivity of the resize trigger. If set to more than 0, the resize will only trigger if the
   * new size difference is more than the sensitivity value.
   */
  triggerResizePixelSensitivity?: number;
}

export const ResizableWindowWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ResizableWindowWrapperProps>
>(
  (
    {
      children,
      className,
      direction = 'both',
      origin,
      windowActor,
      style = {},
      paddingWidth = 4,
      paddingHeight = 4,
      triggerResizePixelSensitivity = 0,
      scaleFactor = 1
    },
    ref
  ) => {
    const prevElWidth = useRef<number>(0);
    const prevElHeight = useRef<number>(0);

    const actualWidthRef = useRef<number | undefined>(undefined);
    const actualHeightRef = useRef<number | undefined>(undefined);

    const internalRef = useRef<HTMLDivElement>(null);

    const findChildElement = useCallback(() => {
      if (!internalRef.current) {
        return null;
      }

      return internalRef.current?.children[0] as HTMLElement | null;
    }, []);

    useEffect(() => {
      const unlisten = windowActor.listen('context', (ctx) => {
        actualWidthRef.current = ctx.width;
        actualHeightRef.current = ctx.height;
      });

      return () => {
        unlisten();
      };
    }, [windowActor]);

    /**
     * Find the relevant elements for the resizable window wrapper
     * and listen to resize events on the container
     * and resize the window accordingly
     */
    useEffect(() => {
      if (!internalRef.current) {
        return;
      }

      const dynamicChildElement = findChildElement();

      if (!dynamicChildElement) {
        return;
      }

      const observer = new ResizeObserver(() => {
        if (!windowActor || !origin) {
          return;
        }

        const paddingWidthAdjusted = paddingWidth * scaleFactor;
        const paddingHeightAdjusted = paddingHeight * scaleFactor;

        // using actualWidthRef.current in favor of below
        // const prevWidthWithPadAndScaling =
        //   prevElWidth.current + paddingWidthAdjusted;

        // const prevHeightWithPadAndScaling =
        //   prevElHeight.current + paddingHeightAdjusted;

        const triggerResizePixelSensitivityAdjusted = triggerResizePixelSensitivity * scaleFactor;

        const elWidthAdjusted = dynamicChildElement.clientWidth * scaleFactor;
        const elHeightAdjusted = dynamicChildElement.clientHeight * scaleFactor;

        const widthDiff = Math.abs(elWidthAdjusted - prevElWidth.current);
        const heightDiff = Math.abs(elHeightAdjusted - prevElHeight.current);

        if (prevElWidth.current !== elWidthAdjusted) {
          prevElWidth.current = elWidthAdjusted;
        }

        if (prevElHeight.current !== elHeightAdjusted) {
          prevElHeight.current = elHeightAdjusted;
        }

        const widthWithPadAndScaling = elWidthAdjusted + paddingWidthAdjusted;
        const heightWithPadAndScaling = elHeightAdjusted + paddingHeightAdjusted;

        const shouldResizeWidth = widthDiff > triggerResizePixelSensitivityAdjusted;

        const shouldResizeHeight = heightDiff > triggerResizePixelSensitivityAdjusted;

        const actualPrevWidth = isDefined(actualWidthRef.current)
          ? actualWidthRef.current
          : widthWithPadAndScaling;

        const actualPrevHeight = isDefined(actualHeightRef.current)
          ? actualHeightRef.current
          : heightWithPadAndScaling;

        if (!shouldResizeWidth && !shouldResizeHeight) {
          return;
        }

        windowActor.operations
          .resizeAroundOrigin(
            {
              width: shouldResizeWidth ? widthWithPadAndScaling : actualPrevWidth,
              height: shouldResizeHeight ? heightWithPadAndScaling : actualPrevHeight
            },
            origin,
            direction
          )
          .catch((e) => {
            console.error(e);
          });
      });

      observer.observe(dynamicChildElement);

      return () => {
        observer.disconnect();
      };
    }, [
      windowActor,
      direction,
      origin,
      paddingWidth,
      paddingHeight,
      triggerResizePixelSensitivity,
      findChildElement,
      scaleFactor
    ]);

    return (
      <div
        ref={useMergedRef(internalRef, ref)}
        style={style}
        className={`resizable-window-wrapper${className ? ` ${className}` : ''}`}
      >
        {children}
      </div>
    );
  }
);
