import { useEffect, useRef, useState } from 'react';
import {
  Actor,
  ActorSchema,
  WindowReadyContext,
  Workspace as WorkspaceInstance,
  internalWorkspace,
  isPromiseLike
} from '@valstro/workspace';
import { ReactActorComponentSelector } from './react-actor-component-selector';
import { ReactWorkspaceContext } from './react-workspace.context';

export type ReactWorkspaceStateType = 'loading' | 'ready' | 'failed' | 'deactivated';

export type ReactWorkspaceState = {
  type: ReactWorkspaceStateType;
  failedMessage?: string;
  rootActor?: Actor<ActorSchema>;
  leaderActor?: Actor;
};

export type ReactWorkspaceRendererProps = {
  workspace: WorkspaceInstance;
  actor?: Actor;
  actorId?: string;
  defaultRenderer: () => React.ReactNode;
};

export type ReactLoadingRendererProps = {
  defaultRenderer: () => React.ReactNode;
};

export type ReactFailedRendererProps = {
  failedMessage?: string;
  defaultRenderer: () => React.ReactNode;
};

export type ReactDeactivatedRendererProps = {
  defaultRenderer: () => React.ReactNode;
};
export interface ReactWorkspaceProps {
  workspace: WorkspaceInstance;
  workspaceRenderer?: (props: ReactWorkspaceRendererProps) => React.ReactNode;
  loadingRenderer?: (props: ReactLoadingRendererProps) => React.ReactNode;
  failedRenderer?: (props: ReactFailedRendererProps) => React.ReactNode;
  deactivatedRenderer?: (props: ReactDeactivatedRendererProps) => React.ReactNode;
  onReady?: (state: WindowReadyContext) => Promise<void> | void;
}

export function ReactWorkspace(props: ReactWorkspaceProps) {
  const onReadyRef = useRef(props.onReady);
  const workspaceRef = useRef(internalWorkspace(props.workspace));
  const isBootingRef = useRef(false);
  const [state, setState] = useState<ReactWorkspaceState>({ type: 'loading' });

  useEffect(() => {
    const unsubscribeOnWindowReady = workspaceRef.current.addHook('windowReady', (ctx) => {
      if (onReadyRef.current) {
        const result = onReadyRef.current?.(ctx);
        if (isPromiseLike(result)) {
          result.catch((e) => {
            setState({ type: 'failed', failedMessage: e.message });
          });
        }
      }
      setState({
        type: 'ready',
        rootActor: ctx.rootWindowActor
      });
    });

    const unsubscribeOnWorkspaceDeactivated = workspaceRef.current.addHook('workspaceDeactivated', () => {
      setState({ type: 'deactivated' });
    });

    // Prevent double booting on strict mode
    if (isBootingRef.current) {
      return;
    }

    isBootingRef.current = true;

    workspaceRef.current.windowBoot().catch((e) => {
      setState({ type: 'failed', failedMessage: e.message });
    });

    return () => {
      unsubscribeOnWindowReady();
      unsubscribeOnWorkspaceDeactivated();
    };
  }, []);

  if (state.type === 'loading') {
    const defaultRenderer = () => {
      return <div className="workspace workspace--starting">Loading...</div>;
    };
    return props.loadingRenderer ? (
      <>
        {props.loadingRenderer({
          defaultRenderer
        })}
      </>
    ) : (
      <>{defaultRenderer()}</>
    );
  }

  if (state.type === 'failed') {
    const defaultRenderer = () => {
      return (
        <div className="workspace workspace--failed">
          <div className="workspace__box">
            <div className="workspace__title">Error</div>
            <div className="workspace__message">{state.failedMessage}</div>
          </div>
        </div>
      );
    };

    return props.failedRenderer ? (
      <>
        {props.failedRenderer({
          failedMessage: state.failedMessage,
          defaultRenderer
        })}
      </>
    ) : (
      <>{defaultRenderer()}</>
    );
  }

  if (state.type === 'deactivated') {
    const defaultRenderer = () => {
      return (
        <div className="workspace workspace--deactivated">
          <div className="workspace__box">
            <div className="workspace__title">Deactivated</div>
            <div className="workspace__message">This window's workspace has been deactivated</div>
          </div>
        </div>
      );
    };

    return props.deactivatedRenderer ? (
      <>
        {props.deactivatedRenderer({
          defaultRenderer
        })}
      </>
    ) : (
      <>{defaultRenderer()}</>
    );
  }

  if (!state.rootActor || !workspaceRef.current.getRootWindowActor()) {
    throw new Error('Root window actor not found');
  }

  const defaultRenderer = () => {
    return (
      <div className="workspace-root">
        <div
          className="workspace-portal-container"
          style={{
            position: 'fixed',
            inset: '0px',
            pointerEvents: 'none',
            zIndex: 100
          }}
        ></div>
        <div
          className="workspace-toolbar-portal-container"
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: 'var(--workspace-toolbar-height, 40px)',
            display: 'flex',
            flexDirection: 'row',
            pointerEvents: 'none',
            zIndex: 125
          }}
        ></div>
        <div className="workspace workspace--running">
          <ReactActorComponentSelector actor={state.rootActor} actorId={state?.rootActor?.id || ''} />
        </div>
      </div>
    );
  };

  return (
    <ReactWorkspaceContext.Provider value={{ workspace: workspaceRef.current }}>
      {props.workspaceRenderer ? (
        <>
          {props.workspaceRenderer({
            workspace: workspaceRef.current,
            actor: state.rootActor,
            actorId: state.rootActor?.id,
            defaultRenderer
          })}
        </>
      ) : (
        <>{defaultRenderer()}</>
      )}
    </ReactWorkspaceContext.Provider>
  );
}
