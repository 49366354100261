import type { DropDownListControl } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { getListItems } from './control.transformer.types';
import type { ControlTransformer } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const dropDownListControl: ControlTransformer<DropDownListControl> = (
  dropDownListControl,
  _query,
  formValues
) => {
  return {
    component: fixatdlFormComponentTypes.DROPDOWN_LIST,
    name: dropDownListControl.id,
    label: dropDownListControl.label,
    initialValue: AtdlFormats.coerceValue(formValues[dropDownListControl.id]),
    options: getListItems(dropDownListControl.listItems)
  };
};
