import React, { useCallback, useEffect } from 'react';
import { useMontageInstrumentTrackingEnabled } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-tracking-enabled.hook';
import useSetMontageState from '@app/data-access/services/trading/montage/hooks/use-set-montage-state.hook';
import type { InstrumentFollowToggleStateProps } from '@app/data-access/services/system/instrument-follow/instrument-follow.toggle-state.registry';

export const MONTAGE_INSTRUMENT_FOLLOW_STATE_KEY = 'montage';

export const InstrumentFollowMontageToggleState: React.FC<InstrumentFollowToggleStateProps> = ({
  children,
  onPressedChange: _onPressedChange
}) => {
  const instrumentTrackingEnabled = useMontageInstrumentTrackingEnabled();
  const setMontageState = useSetMontageState();

  // Sync the pressed state with the parent component
  useEffect(() => {
    if (_onPressedChange && instrumentTrackingEnabled !== undefined) {
      _onPressedChange(instrumentTrackingEnabled);
    }
  }, [instrumentTrackingEnabled]);

  const onPressedChange = useCallback(
    (pressed: boolean) => {
      setMontageState({
        instrumentTrackingEnabled: pressed
      }).catch(console.error);
    },
    [setMontageState]
  );

  return children({ pressed: instrumentTrackingEnabled, onPressedChange });
};
