import { RemoteProxy, Unsubscribe, createProxy } from '@valstro/remote-link';
import {
  ActorDefinition,
  ApplyActorRootSnapshotDefinition,
  CreateActorDefinition,
  CreateActorOptions,
  ActorSnapshotDefinition,
  ActorStateType,
  InternalActor,
  SpawnedActorResult
} from './actor.internal';
import { ActorSchema, AnyActorSchema, UnwrapContext, UnwrapOperations } from './actor.schema';
import { getLocalActor, getLocalActorProxy, setLocalActor } from './actor.registry';
import { internalWorkspace } from './workspace';

type ActorEventType<T extends ActorSchema> = {
  state: ActorStateType;
  context: UnwrapContext<T>;
  children: CreateActorDefinition[];
  failedMessage: string;
};

type ActorEventOptions = {
  /** First emit the current value and run the callback with it, then subscribe to changes */
  emitInitial?: boolean;
};

/**
 * Actor class
 * Provides a typed interface to an actor instance
 * Internally, it uses a RemoteProxy to communicate with the actor
 *
 * @template T - The actor schema
 * @param T - The actor schema
 * @returns - The actor class
 * @public
 * @example
 * ```ts
 * const actor = await Actor.get<CommonWindowActorSchema>('window-id');
 * ```
 * @example
 * ```ts
 * const actor = await Actor.getSyncLazy<CommonWindowActorSchema>('window-id');
 * actor.name; // '' (initially empty, will be fetched asyncronously behind the scenes)
 * actor.operations.setTitle('Hello World');
 * ```
 * @example
 * ```ts
 * const actor = await Actor.create<CommonWindowActorSchema>({
 *  parentId: 'main',
 *  type: 'window',
 *  context: {
 *    width: 500,
 *    height: 500
 *  }
 * });
 * ```
 * @example
 * ```ts
 * const currentContext = await actor.context(); // { title: "Untitled", ...}
 *
 * actor.listen('context', (context) => {
 *  console.log('Context Changed', context); // { title: "Hello World", ...}
 * });
 *
 * actor.operations.setTitle('Hello World');
 * ```
 */
export class Actor<T extends AnyActorSchema = AnyActorSchema> {
  private _id: string;
  private _name: string;
  private _type: string;
  private _parentId: string | undefined;
  private _isReady = false;
  private _initialDefinition: ActorDefinition<UnwrapContext<T>>;

  private constructor(
    private readonly _createDef: ActorDefinition<UnwrapContext<T>>,
    private _proxy: RemoteProxy<InternalActor>,
    private _isLazy = false
  ) {
    this._id = this._createDef.id;
    this._name = this._createDef.name || '';
    this._type = this._createDef.type || '';
    this._parentId = this._createDef.parentId || '';
    this._initialDefinition = this._createDef;
    if (this._isLazy) {
      if (this._type && this._name) {
        this._isReady = true;
      } else {
        this._fetchLazy().catch(console.error);
      }
    } else {
      this._isReady = true;
    }
    setLocalActor(this._id, this);
  }

  /**
   * Actor ID (unique to each actor instance)
   */
  public get id() {
    return this._id;
  }

  /**
   * Actor name (unique to each actor's schema)
   * Note: Could be empty temporarily if the actor was created using "getSyncLazy" @see getSyncLazy
   */
  public get name() {
    return this._name;
  }

  /**
   * Actor type
   * Note: Could be empty temporarily if the actor was created using "getSyncLazy" @see getSyncLazy
   */
  public get type() {
    return this._type;
  }

  /**
   * Actor parent ID
   * This is the ID of the actor that spawned this actor
   * If the actor is a root actor, this will be the leader process ID
   * If the actor is root actor AND leader process, this will be undefined
   * Note: Could be empty temporarily if the actor was created using "getSyncLazy" @see getSyncLazy
   */
  public get parentId() {
    return this._parentId;
  }

  /**
   * Actor definition
   * Note: Could be mostly empty temporarily if the actor was created using "getSyncLazy" @see getSyncLazy
   */
  public get initialDefinition() {
    return this._initialDefinition;
  }

  /**
   * Actor operations - Typed interface to the actor's operations (defined in the actor schema)
   */
  public get operations(): UnwrapOperations<T> {
    return new Proxy(
      {},
      {
        get: (_target, prop) => {
          const key = prop as keyof UnwrapOperations<T>;
          return async (...args: Parameters<UnwrapOperations<T>[typeof key]>[]) => {
            if (!this._isReady) {
              await this.waitForReady();
            }
            return await this._proxy.operations?.[key]?.call(...args);
          };
        },
        set: () => {
          return true;
        }
      }
    ) as UnwrapOperations<T>;
  }

  /**
   * Actor proxy - The actor's remote proxy
   * This is useful to access the actor's raw proxy
   * Try to avoid using this, and use the typed interface instead
   */
  public get proxy(): RemoteProxy<InternalActor> {
    return this._proxy;
  }

  /**
   * Get an actor by ID
   * Wait/check for the actor's existence & get its definition
   * @example
   * ```ts
   * const actor = await Actor.get<CommonWindowActorSchema>('window-id');
   * actor.name; // '' (initially empty, will be fetched asyncronously behind the scenes)
   * actor.operations.setTitle('Hello World');
   * ```
   *
   * @param id - The actor id
   * @param timeout - The timeout in milliseconds to wait for the actor to exist
   * @returns Actor - Interface/portal to an actor instance
   */
  public static async get<T extends AnyActorSchema>(id: string, timeout?: number): Promise<Actor<T>> {
    const existingActor = getLocalActor(id);
    if (existingActor) {
      return existingActor;
    }

    const existingProxy = getLocalActorProxy(id);
    const proxy = existingProxy
      ? existingProxy
      : createProxy<InternalActor>(id, {
          awaitConfirmation: false
        });

    return new Promise<Actor<T>>((resolve, reject) => {
      const timeoutId = timeout
        ? setTimeout(() => {
            reject(`Actor ${id} not found`);
          }, timeout)
        : undefined;
      proxy.getDefinition
        .call()
        .then((definition) => {
          clearTimeout(timeoutId);
          resolve(new Actor<T>(definition as ActorDefinition<UnwrapContext<T>>, proxy));
        })
        .catch((e) => {
          clearTimeout(timeoutId);
          reject(e);
        });
    });
  }

  /**
   * Get an actor by ID, but don't wait for it to exist
   * This is useful to optimistically get an actor, making working with actors you know exist, easier.
   * @example
   * ```ts
   * const actor = Actor.getSyncLazy<CommonWindowActorSchema>('window-id');
   * await actor.operations.setTitle('Hello World');
   * ```
   *
   * @param id - The actor id
   * @returns Actor - Interface/portal to an actor instance
   */
  public static getSyncLazy<T extends AnyActorSchema>(
    id: string,
    definition?: ActorDefinition<UnwrapContext<T>>
  ): Actor<T> {
    const existingActor = getLocalActor(id);
    if (existingActor && existingActor.id === id) {
      return existingActor;
    }

    const existingProxy = getLocalActorProxy(id);
    const proxy = existingProxy
      ? existingProxy
      : createProxy<InternalActor>(id, {
          awaitConfirmation: false
        });
    return new Actor<T>(definition ? definition : ({ id } as ActorDefinition<UnwrapContext<T>>), proxy, true);
  }

  /**
   * Create a new actor
   * @example
   * ```ts
   * const actor = await Actor.create<CommonWindowActorSchema>({
   *  parentId: 'main',
   *  type: 'window',
   *  context: {
   *    width: 500,
   *    height: 500
   *  }
   * });
   * ```
   *
   * @param options - The actor options
   * @returns Actor - Interface/portal to an actor instance
   */
  public static async create<T extends AnyActorSchema>(
    options: Omit<CreateActorOptions<UnwrapContext<T>>, 'parentId'> & {
      parentId: string;
    }
  ) {
    const parent = await this.get<ActorSchema>(options.parentId);
    return parent.spawnChild(options);
  }

  /**
   * Get the actor's state (starting, running, stopped, failed)
   * @example
   * ```ts
   * const state = await windowActor.state(); // starting | running | stopped | failed
   * ```
   *
   * @returns ActorStateType - The actor state (starting, running, stopped, failed)
   */
  public async state(): Promise<ActorStateType> {
    if (!this._isReady) {
      await this.waitForReady();
    }
    return this._proxy.state.get();
  }

  /**
   *  Get the actor's context (defined in the actor schema)
   * @example
   * ```ts
   * const context = await windowActor.context(); // { width: 500, height: 500, ... }
   * ```
   *
   * @returns The actor context (defined in the actor schema)
   */
  public async context(): Promise<UnwrapContext<T>> {
    if (!this._isReady) {
      await this.waitForReady();
    }
    return this._proxy.context.get() as Promise<UnwrapContext<T>>;
  }

  /**
   * Get the actor's children
   * @example
   * ```ts
   * const children = await windowActor.children(); // [{ id: 'child-id', name: 'child-name', type: 'widget', ...}]
   * ```
   *
   * @returns ChildActorDefinition<AnyRecord>[] - The actor's children
   */
  public async children(): Promise<InternalActor['children']> {
    if (!this._isReady) {
      await this.waitForReady();
    }
    return this._proxy.children.get();
  }

  /**
   * Run the actor's sync function (defined in the actor schema)
   * This is useful to syncronise the actor's context with its rendering
   * @example
   * ```ts
   * await windowActor.sync();
   * ```
   *
   * @returns Promise<void>
   */
  public async sync(
    contextDelta?: Partial<UnwrapContext<T>>,
    { isFocus }: { isFocus?: boolean } = { isFocus: false }
  ): Promise<void | UnwrapContext<T>> {
    if (!this._isReady) {
      await this.waitForReady();
    }
    return (await this._proxy.sync.call(contextDelta, isFocus)) as Promise<void | UnwrapContext<T>>;
  }

  /**
   * Destroy the actor
   * This will destroy the actor and all its children
   * @example
   * ```ts
   * await windowActor.destroy();
   * ```
   *
   * @returns Promise<void>
   */
  public async destroy(): Promise<void> {
    if (!this._isReady) {
      await this.waitForReady();
    }
    return await this._proxy.destroy.call();
  }

  /**
   * Get the current actor definition
   * This will return the actor's current definition (a mini snapshot of the actor)
   * @example
   * ```ts
   * const definition = await windowActor.getDefinition(); // { id: 'window-id', name: 'window-name', type: 'window-type', ... }
   * ```
   *
   * @returns ActorDefinition - The actor definition
   */
  public async getDefinition(): Promise<ActorDefinition<UnwrapContext<T>>> {
    if (!this._isReady) {
      await this.waitForReady();
    }
    return (await this._proxy.getDefinition.call()) as ActorDefinition<UnwrapContext<T>>;
  }

  /**
   * Spawn a child actor
   * @example
   * ```ts
   * const widgetActor = await windowActor.spawnChild<WidgetActorSchema>({ type: 'widget', context: { componentId: 'MARKET_DATA' } }); // Actor<WidgetActorSchema>
   * ```
   *
   * @returns Actor<AnyActorSchema> - The child actor
   */
  public async spawnChild<T extends AnyActorSchema>(
    options: CreateActorOptions<UnwrapContext<T>>
  ): Promise<SpawnedActorResult<T>> {
    if (!this._isReady) {
      await this.waitForReady();
    }
    const result = await this._proxy.spawnChild.call(options);
    const [definition] = result;
    const actor = await Actor.get<T>(definition.id);
    return [definition, actor] as SpawnedActorResult<T>;
  }

  /**
   * Destroy a child actor
   * @example
   * ```ts
   * await windowActor.destroyChild('widget-id');
   * ```
   *
   * @returns Promise<void>
   */
  public async destroyChild(id: string) {
    if (!this._isReady) {
      await this.waitForReady();
    }
    return await this._proxy.destroyChild.call(id);
  }

  /**
   * Apply a snapshot to the actor
   * This will apply a snapshot to the actor, updating its state, context and children
   * By first syncing the actor's context, then recursively destroying and re-creating its children
   * @param snapshot - ApplyActorRootSnapshotDefinition
   * @example
   * ```ts
   * await windowActor.applySnapshot({
   *  context: { title: 'Hello World', width: 500, height: 400 },
   *  children: [
   *    { type: 'widget', context: { componentId: 'MARKET_DATA' } },
   *    { type: 'widget', context: { componentId: 'NEWS' } },
   *  ]
   * });
   * ```
   *
   * @returns Promise<void>
   */
  public async applySnapshot(snapshot: ApplyActorRootSnapshotDefinition<UnwrapContext<T>>): Promise<void> {
    if (!this._isReady) {
      await this.waitForReady();
    }
    return await this._proxy.applySnapshot.call(snapshot);
  }

  /**
   * Take a snapshot of the actor (and its children)
   * This will get the actor's current state, context and children (recursively)
   * @example
   * ```ts
   * const snapshot = await windowActor.takeSnapshot(); // { id:'window-id', context: { ... }, children: [{ id: 'widget-id', ...}], ... }
   * ```
   *
   * @returns Promise<void>
   */
  public async takeSnapshot(): Promise<ActorSnapshotDefinition> {
    if (!this._isReady) {
      await this.waitForReady();
    }
    return await this._proxy.takeSnapshot.call();
  }

  /**
   * Subscribe to an event or change in the actor
   * Note: "emitInitial" will fetch the value from the actor and run the callback with it, then subscribe to changes
   * @example
   * ```ts
   * const unsubscribe = windowActor.listen('context', (context) => {
   *  console.log('Context Changed', context); // { title: "Hello World", ...}
   * });
   *
   * windowActor.operations.setTitle('Hello World');
   *
   * unsubscribe();
   * ```
   *
   * @param type - The event type
   * @param cb - The callback (value => void)
   * @param options - The event options (emitInitial: boolean)
   * @returns Unsubscribe - The unsubscribe function
   */
  public listen<K extends keyof ActorEventType<T>>(
    type: K,
    cb: (value: ActorEventType<T>[K]) => void,
    options?: ActorEventOptions
  ): Unsubscribe {
    const workspace = window['internalWorkspace'];
    if (!workspace) {
      throw new Error('Workspace not found. Please ensure you are running in a workspace before listening.');
    }
    const { _eventsTransport } = internalWorkspace(workspace);
    let queuedUpdates: ActorEventType<T>[K][] = [];
    let initialValue: undefined | ActorEventType<T>[K];
    if (options?.emitInitial) {
      this._proxy[type]
        .get()
        .then((v) => {
          initialValue = v as ActorEventType<T>[K];
          if (queuedUpdates.length) {
            queuedUpdates.forEach((v) => cb(v));
            queuedUpdates = [];
            return;
          }

          cb(initialValue);
        })
        .catch(console.error);
    }

    switch (type) {
      case 'failedMessage':
      case 'state': {
        return _eventsTransport.listen('actor/state/changed', (ev) => {
          if (ev.id === this.id) {
            if (type === 'state') {
              cb(ev.state as ActorEventType<T>[K]);
            } else if (type === 'failedMessage') {
              cb(ev.failedMessage as ActorEventType<T>[K]);
            }
          }
        });
      }
      case 'context': {
        return _eventsTransport.listen('actor/context/changed', (ev) => {
          if (ev.id === this.id) {
            cb(ev.context as ActorEventType<T>[K]);
          }
        });
      }
      case 'children': {
        return _eventsTransport.listen('actor/children/changed', (ev) => {
          if (ev.id === this.id) {
            cb(ev.children as ActorEventType<T>[K]);
          }
        });
      }
      default: {
        throw new Error(`Invalid event type: ${type}`);
      }
    }
  }

  /**
   * Subscribe to an event or change in the actor once
   * Note: "emitInitial" will fetch the value from the actor and run the callback with it, then subscribe to changes
   * @example
   * ```ts
   * windowActor.listenOnce('context', (context) => {
   *  console.log('Context Changed', context); // { title: "Hello World", ...}
   * });
   *
   * windowActor.operations.setTitle('Hello World');
   * windowActor.operations.setTitle('Goodbye World'); // This will not trigger the callback
   * ```
   *
   * @param type - The event type
   * @param cb - The callback (value => void)
   * @param options - The event options (emitInitial: boolean)
   * @returns Unsubscribe - The unsubscribe function
   */
  public listenOnce<K extends keyof ActorEventType<T>>(
    type: K,
    cb: (value: ActorEventType<T>[K]) => void,
    options?: ActorEventOptions
  ): Unsubscribe {
    const unsubscribe = this.listen(
      type,
      (value) => {
        cb(value);
        unsubscribe();
      },
      options
    );

    return unsubscribe;
  }

  /**
   * Fetch the actor's proxy lazily
   * This is useful to optimistically get an actor, making working with actors you know exist, easier.
   * @private
   * @returns Promise<void>
   */
  private async _fetchLazy() {
    const definition = await this._proxy.getDefinition.call();
    this._id = definition.id;
    this._name = definition.name;
    this._type = definition.type;
    this._parentId = definition.parentId;
    this._isReady = true;
  }

  private async waitForReady() {
    if (this._isReady) {
      return;
    }

    return new Promise<void>((resolve, reject) => {
      let timeout: NodeJS.Timeout | undefined = undefined;
      const interval = setInterval(() => {
        if (this._isReady) {
          clearTimeout(timeout);
          clearInterval(interval);
          resolve();
        }
      }, 25);

      timeout = setTimeout(() => {
        clearInterval(interval);
        reject(`Actor ${this.id} never became ready`);
      }, 10_000);
    });
  }
}
