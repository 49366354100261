import type { Maybe } from '@oms/shared/util-types';
import { t } from '@oms/codegen/translations';
import type { FieldApi } from '@data-driven-forms/react-form-renderer';

export interface OptionalRequired<Field extends string = string> {
  fields?: Field[];
  message?: string;
}

export const optionalRequired: (
  setup: OptionalRequired
) => (value: any, allValues: Record<string, any>, fieldApi: FieldApi<any>) => Maybe<string> = ({
  fields = [],
  message = t('app.common.requiredField')
}) => {
  const fieldSet = new Set(fields);
  return (_value, allValues) => {
    const valid = (val: any) => typeof val !== 'undefined' && val !== null && val !== '';
    const notRequired = Object.entries(allValues || {})
      .filter(([field]) => fieldSet.has(field))
      .some(([_, val]) => valid(val));
    return notRequired ? null : message;
  };
};
