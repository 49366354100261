import { CreateActorDefinition } from '../../../core';
import { WindowContext, ensureInt, isDefined } from '../../common';
import {
  appWindow,
  LogicalPosition,
  LogicalSize,
  PhysicalPosition,
  PhysicalSize,
  UserAttentionType,
  WebviewWindow,
  WindowOptions,
  availableMonitors,
  Monitor
} from '@tauri-apps/api/window';
import { centerInActiveMonitor } from './window.actor.tauri.cargo';
import { logger } from '../../../core';

export async function createTauriWindowFromDef(
  url: string,
  options: CreateActorDefinition<WindowContext>
): Promise<WebviewWindow> {
  const { id, context } = options || {};
  const windowOptions: WindowOptions = {
    url,
    alwaysOnTop: !!context?.alwaysOnTop,
    title: context?.title,
    width: isDefined(context) && isDefined(context?.width) ? ensureInt(context.width) : undefined,
    height: isDefined(context) && isDefined(context?.height) ? ensureInt(context.height) : undefined,
    x: isDefined(context) && isDefined(context?.x) ? ensureInt(context.x) : undefined,
    y: isDefined(context) && isDefined(context?.y) ? ensureInt(context.y) : undefined,
    resizable: context?.isResizable,
    maximizable: context?.isMaximizable,
    minimizable: context?.isMinimizable,
    fullscreen: context?.isFullscreen,
    transparent: context?.transparent,
    visible: context?.isVisible,
    focus: context?.isFocused,
    center: context?.initiallyCentered,
    closable: context?.isClosable,
    decorations: context?.isDecorated,
    maxHeight: isDefined(context) && isDefined(context?.maxHeight) ? ensureInt(context.maxHeight) : undefined,
    minHeight: isDefined(context) && isDefined(context?.minHeight) ? ensureInt(context.minHeight) : undefined,
    maxWidth: isDefined(context) && isDefined(context?.maxWidth) ? ensureInt(context.maxWidth) : undefined,
    minWidth: isDefined(context) && isDefined(context?.minWidth) ? ensureInt(context.minWidth) : undefined,
    maximized: context?.isMaximized,
    skipTaskbar: context?.skipTaskbar
  };

  return createTauriWindow(id, windowOptions, {
    isMinimized: context?.isMaximized
  });
}

export function createTauriWindow(
  label: string,
  options: WindowOptions,
  extraOptions?: {
    isMinimized?: boolean;
    initiallyUseLogicalPixels?: boolean;
    hideUtilReady?: boolean;
  }
): Promise<WebviewWindow> {
  return new Promise<WebviewWindow>((resolve, reject) => {
    const hideUntilReady = !!extraOptions?.hideUtilReady;
    const window = new WebviewWindow(label, {
      ...options,
      visible: hideUntilReady ? false : options.visible
    });
    resolve(window);

    window
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .once('tauri://created', async () => {
        logger.debug('[TauriWindowActor] Window created', label, {
          options,
          extraOptions
        });

        const promises: Array<Promise<any>> = [];

        if (extraOptions?.isMinimized) {
          promises.push(window.minimize());
        }

        if (options.skipTaskbar) {
          promises.push(window.setSkipTaskbar(true));
        } else {
          promises.push(window.setSkipTaskbar(false));
        }

        if (
          extraOptions?.initiallyUseLogicalPixels === false &&
          isDefined(options.x) &&
          isDefined(options.y)
        ) {
          promises.push(window.setPosition(new PhysicalPosition(ensureInt(options.x), ensureInt(options.y))));
        }

        if (
          extraOptions?.initiallyUseLogicalPixels === false &&
          isDefined(options.width) &&
          isDefined(options.height)
        ) {
          promises.push(
            window.setSize(new PhysicalSize(ensureInt(options.width), ensureInt(options.height)))
          );
        }

        try {
          if (promises.length > 0) {
            if (hideUntilReady) {
              await Promise.all(promises);
            } else {
              Promise.all(promises).catch(console.error);
            }
          }

          if (hideUntilReady && options.visible !== false) {
            await window.show();
          }

          resolve(window);
        } catch (e) {
          console.error(e);
          try {
            await window.show();
            resolve(window);
          } catch (e) {
            window.close().catch(console.error);
            reject(e);
          }
        }
      })
      .catch(reject);
    window
      .once('tauri://error', (e) => {
        console.error(e);
        reject(e);
      })
      .catch(reject);
  });
}

export async function syncContextToTauriWindow(
  ctx: WindowContext,
  updateContextFn: (ctx: Partial<WindowContext>) => Promise<void>
) {
  const [
    title,
    position,
    size,
    isMaximized,
    isMinimized,
    isVisible,
    isFocused,
    isDecorated,
    isFullscreen,
    scaleFactor,
    isMaximizable,
    isMinimizable,
    isResizable,
    isClosable,
    currentMonitorIndex
  ] = await Promise.all([
    appWindow.title(),
    appWindow.outerPosition(),
    appWindow.outerSize(),
    appWindow.isMaximized(),
    appWindow.isMinimized(),
    appWindow.isVisible(),
    appWindow.isFocused(),
    appWindow.isDecorated(),
    appWindow.isFullscreen(),
    appWindow.scaleFactor(),
    appWindow.isMaximizable(),
    appWindow.isMinimizable(),
    appWindow.isResizable(),
    appWindow.isClosable(),
    getTauriMonitorIndex()
  ]);

  const delta: Partial<WindowContext> = {};
  if (ctx.title !== title) {
    delta.title = title;
  }
  if (ctx.x !== position.x) {
    delta.x = position.x;
  }
  if (ctx.y !== position.y) {
    delta.y = position.y;
  }
  if (ctx.width !== size.width) {
    delta.width = size.width;
  }
  if (ctx.height !== size.height) {
    delta.height = size.height;
  }
  if (ctx.isMaximized !== isMaximized) {
    delta.isMaximized = isMaximized;
  }
  if (ctx.isMinimized !== isMinimized) {
    delta.isMinimized = isMinimized;
  }
  if (ctx.isVisible !== isVisible) {
    delta.isVisible = isVisible;
  }
  if (ctx.isFocused !== isFocused) {
    delta.isFocused = isFocused;
  }
  if (ctx.isDecorated !== isDecorated) {
    delta.isDecorated = isDecorated;
  }
  if (ctx.isFullscreen !== isFullscreen) {
    delta.isFullscreen = isFullscreen;
  }
  if (ctx.scaleFactor !== scaleFactor) {
    delta.scaleFactor = scaleFactor;
  }
  if (ctx.isMaximizable !== isMaximizable) {
    delta.isMaximizable = isMaximizable;
  }
  if (ctx.isMinimizable !== isMinimizable) {
    delta.isMinimizable = isMinimizable;
  }
  if (ctx.isResizable !== isResizable) {
    delta.isResizable = isResizable;
  }
  if (ctx.isClosable !== isClosable) {
    delta.isClosable = isClosable;
  }
  if (ctx.currentMonitorIndex !== currentMonitorIndex) {
    delta.currentMonitorIndex = currentMonitorIndex;
  }
  if (isDefined(delta.x) || isDefined(delta.y)) {
    delta.initiallyCentered = false;
  }
  delta.initiallyUseLogicalPixels = false;
  if (Object.keys(delta).length > 0) {
    await updateContextFn(delta);
  }
}

export async function syncTauriWindowToContext(webview: WebviewWindow, context: Partial<WindowContext>) {
  const promises: Promise<any>[] = [];
  const importantPromises: [string, Promise<any>][] = [];
  const postImportantPromises: [string, Promise<any>][] = [];

  if (context.title) {
    promises.push(webview.setTitle(context.title));
  }

  if (context.isDecorated) {
    promises.push(webview.setDecorations(true));
  }

  if (context.isDecorated === false) {
    promises.push(webview.setDecorations(false));
  }

  if (context.isClosable) {
    promises.push(webview.setClosable(true));
  }

  if (context.isClosable === false) {
    promises.push(webview.setClosable(false));
  }

  if (context.isResizable) {
    promises.push(webview.setResizable(true));
  }

  if (context.isResizable === false) {
    promises.push(webview.setResizable(false));
  }

  if (context.isMaximizable) {
    promises.push(webview.setMaximizable(true));
  }

  if (context.isMaximizable === false) {
    promises.push(webview.setMaximizable(false));
  }

  if (context.isMinimizable) {
    promises.push(webview.setMinimizable(true));
  }

  if (context.isMinimizable === false) {
    promises.push(webview.setMinimizable(false));
  }

  if (context.isFullscreen) {
    promises.push(webview.setFullscreen(true));
  }

  if (context.isFullscreen === false) {
    promises.push(webview.setFullscreen(false));
  }

  if (context.skipTaskbar) {
    promises.push(webview.setSkipTaskbar(true));
  }

  if (context.skipTaskbar === false) {
    promises.push(webview.setSkipTaskbar(false));
  }

  if (context.alwaysOnTop) {
    promises.push(webview.setAlwaysOnTop(true));
  }

  if (context.alwaysOnTop === false) {
    promises.push(webview.setAlwaysOnTop(false));
  }

  if (context.requestingUserAttention) {
    promises.push(
      webview.requestUserAttention(
        context.requestingUserAttention === 'info'
          ? UserAttentionType.Informational
          : UserAttentionType.Critical
      )
    );
  }

  if (isDefined(context.maxWidth) && isDefined(context.maxHeight)) {
    const size = context.initiallyUseLogicalPixels
      ? new LogicalSize(ensureInt(context.maxWidth), ensureInt(context.maxHeight))
      : new PhysicalSize(ensureInt(context.maxWidth), ensureInt(context.maxHeight));
    promises.push(webview.setMaxSize(size));
  }

  if (isDefined(context.minWidth) && isDefined(context.minHeight)) {
    const size = context.initiallyUseLogicalPixels
      ? new LogicalSize(ensureInt(context.minWidth), ensureInt(context.minHeight))
      : new PhysicalSize(ensureInt(context.minWidth), ensureInt(context.minHeight));
    promises.push(webview.setMinSize(size));
  }

  const hasCoOrds = isDefined(context.x) && isDefined(context.y);
  const center = hasCoOrds ? false : context.initiallyCentered === false ? false : true;

  let size: PhysicalSize | LogicalSize | undefined;

  if (isDefined(context.width) && isDefined(context.height)) {
    size = context.initiallyUseLogicalPixels
      ? new LogicalSize(ensureInt(context.width), ensureInt(context.height))
      : new PhysicalSize(ensureInt(context.width), ensureInt(context.height));
    importantPromises.push(['size', webview.setSize(size)]);
  }

  if (isDefined(context.x) && isDefined(context.y) && !center) {
    const pos = context.initiallyUseLogicalPixels
      ? new LogicalPosition(ensureInt(context.x), ensureInt(context.y))
      : new PhysicalPosition(ensureInt(context.x), ensureInt(context.y));
    importantPromises.push(['co-ords position', webview.setPosition(pos)]);
  }

  if (center) {
    postImportantPromises.push(['center', centerInActiveMonitor(webview, context.width, context.height)]);
  }

  if (context.isMaximized) {
    promises.push(webview.maximize());
  }

  if (context.isMaximized === false) {
    promises.push(webview.unmaximize());
  }

  if (context.isMinimized) {
    promises.push(webview.minimize());
  }

  if (context.isMinimized === false) {
    promises.push(webview.unminimize());
  }

  if (context.isVisible) {
    await Promise.all([...importantPromises.map((p) => p[1]), ...postImportantPromises.map((p) => p[1])]);
    await webview.show();

    // If the window is focused, we need to focus it after showing it
    if (context.isFocused) {
      webview.setFocus().catch(console.error);
    }
  } else {
    Promise.all([
      webview.hide(),
      ...importantPromises.map((p) => p[1]),
      ...postImportantPromises.map((p) => p[1])
    ]).catch(console.error);
  }

  Promise.all(promises).catch(console.error);
}

function calculatePercentageInsideMonitor(
  windowSize: PhysicalSize,
  windowPos: PhysicalPosition,
  monitor: Monitor
): number {
  // Compute overlap dimensions
  const xOverlap = Math.max(
    0,
    Math.min(windowPos.x + windowSize.width, monitor.position.x + monitor.size.width) -
      Math.max(windowPos.x, monitor.position.x)
  );
  const yOverlap = Math.max(
    0,
    Math.min(windowPos.y + windowSize.height, monitor.position.y + monitor.size.height) -
      Math.max(windowPos.y, monitor.position.y)
  );

  // Compute area of overlap
  const overlapArea = xOverlap * yOverlap;

  // Compute area of the window
  const windowArea = windowSize.width * windowSize.height;

  // Return percentage of the window that is inside the monitor
  return (overlapArea / windowArea) * 100;
}

export async function getWebviewMonitor(webview?: WebviewWindow, monitors?: Monitor[]): Promise<Monitor> {
  webview = webview || appWindow;
  const windowSizePromise = webview.outerSize();
  const windowPosPromise = webview.outerPosition();
  const [windowSize, windowPos] = await Promise.all([windowSizePromise, windowPosPromise]);
  return getMonitorByWinPerc(windowSize, windowPos, monitors);
}

export async function getMonitorByWinPerc(
  windowSize: PhysicalSize,
  windowPos: PhysicalPosition,
  _monitors?: Monitor[]
): Promise<Monitor> {
  const monitors = _monitors ? _monitors : await availableMonitors();

  let maxPercentage = 0;
  let activeMonitor = monitors[0];

  for (const monitor of monitors) {
    const percentage = calculatePercentageInsideMonitor(windowSize, windowPos, monitor);
    if (percentage > maxPercentage) {
      maxPercentage = percentage;
      activeMonitor = monitor;
    }
  }

  if (!activeMonitor) {
    throw new Error('No active monitor found');
  }

  return activeMonitor;
}

export async function getTauriMonitorIndex() {
  const monitors = await availableMonitors();
  const currentMonitor = await getWebviewMonitor();
  const index = monitors.findIndex((m) => m.name === currentMonitor.name);
  return index === -1 ? 0 : index;
}
