import {
  Actor,
  AnyActorSchema,
  ClickEvent,
  CoreEvents,
  EventsTransport,
  Unsubscribe,
  Workspace,
  internalWorkspace,
  isDefined,
  listenToCustomActorEvent,
  logger
} from '@valstro/workspace';
import {
  CommonPopoverActorSchema,
  COMMON_POPOVER,
  POPOVER_DEFAULT_BUFFER_SIZE,
  ClosingStrategy,
  DEFAULT_POPOVER_OPTIONS,
  PopoverOpenedBounds
} from './popover.contracts';
import {
  ClosePopoverEvent,
  OpenPopoverEvent,
  POPOVER_EVENT,
  PopoverEvents,
  popoverEventsTransport
} from './popover.events';
import { getIntersectionBounds } from './popover.util';

type PopoverId = string;
type InnerPopoverId = string;

type PopoverActor = {
  popoverId: PopoverId;
  actor: Actor<CommonPopoverActorSchema>;
};

export interface PopoverManagerOptions {
  bufferSize: number;
  leaderOnly: boolean;
  eventsTransport?: EventsTransport<PopoverEvents>;
}

export interface OpenPopover {
  popoverId: PopoverId;
  innerPopoverId: InnerPopoverId;
  closingStrategy: ClosingStrategy;
  events?: Unsubscribe;
}

export interface QueuedPopover {
  events: Unsubscribe;
  timeout: NodeJS.Timeout;
}

export class PopoverManager<T extends Workspace = Workspace> {
  private _popovers: Map<PopoverId, PopoverActor>;
  private _rootActor: Actor<AnyActorSchema>;
  private _availablePopovers: Set<PopoverId> = new Set();
  private _openPopovers: OpenPopover[] = [];
  private _openQueuedPopovers: Map<InnerPopoverId, QueuedPopover> = new Map();
  private _unlisteners: Unsubscribe[] = [];
  private _eventsTransport: EventsTransport<CoreEvents>;
  public popoverEventsTransport: EventsTransport<PopoverEvents>;

  constructor(
    private _workspace: T,
    private _options: PopoverManagerOptions = {
      bufferSize: POPOVER_DEFAULT_BUFFER_SIZE,
      leaderOnly: true,
      eventsTransport: popoverEventsTransport
    }
  ) {
    const rootActor = this._workspace.getRootWindowActor();
    if (!rootActor) {
      throw new Error('Root actor not found');
    }
    this._eventsTransport = internalWorkspace(_workspace)._eventsTransport;
    this.popoverEventsTransport = _options.eventsTransport || popoverEventsTransport;
    this._rootActor = rootActor;
    this._popovers = new Map();
    this._listenToEvents();
  }

  public async initialize() {
    const promises: Promise<void>[] = [];
    // Init syncronously otherwise, we get issues
    for (let i = 0; i < this._options.bufferSize; i++) {
      promises.push(this.initializeOne(i));
    }

    const results = await Promise.allSettled(promises);

    // Log any errors
    results.forEach((result) => {
      if (result.status === 'rejected') {
        console.error(result.reason);
      }
    });
  }

  public async initializeSync() {
    // Init syncronously to avoid issues
    for (let i = 0; i < this._options.bufferSize; i++) {
      try {
        await this.initializeOne(i);
      } catch (error) {
        console.error(error);
      }
    }
  }

  private async initializeOne(i: number) {
    const popoverId = COMMON_POPOVER.getId(this._rootActor.id, i);
    const [, _actor] = await this._rootActor.spawnChild({
      id: popoverId,
      type: COMMON_POPOVER.TYPE,
      context: {
        isDecorated: false,
        transparent: true,
        alwaysOnTop: true,
        isVisible: false
      }
    });
    const actor = _actor as Actor<CommonPopoverActorSchema>;
    this._popovers.set(popoverId, {
      popoverId,
      actor
    });
    this._availablePopovers.add(popoverId);
    logger.debug(`[Popover Plugin] Created & initialized popover window ${i}`);
  }

  private _listenToEvents() {
    this._unlisteners.push(
      this.popoverEventsTransport.listen(POPOVER_EVENT.TOGGLE_POPOVER, (event) =>
        this._handleOpenPopoverEvent(event)
      )
    );

    this._unlisteners.push(
      this.popoverEventsTransport.listen(POPOVER_EVENT.OPEN_POPOVER, (event) =>
        this._handleOpenPopoverEvent(event)
      )
    );

    this._unlisteners.push(
      this.popoverEventsTransport.listen(POPOVER_EVENT.CLOSE_POPOVER, (event) =>
        this._handleClosePopoverEvent(event)
      )
    );
  }

  private async _handleOpenPopoverEvent(payload: OpenPopoverEvent['payload'], shouldToggle = false) {
    if (this._options.leaderOnly === false && payload.windowId !== this._rootActor.id) {
      return;
    }
    await this._openAvailablePopover(payload, shouldToggle);
  }

  private async _handleClosePopoverEvent(payload: ClosePopoverEvent['payload']) {
    if (this._options.leaderOnly === false && payload.windowId !== this._rootActor.id) {
      return;
    }

    await this._closeOpenPopover(payload.popoverId);
  }

  private async _openAvailablePopover(payload: OpenPopoverEvent['payload'], shouldToggle = false) {
    // Check if there is an open popover with the same inner popover ID
    // If so, discard it (close it) to mimick toggling behavior
    const matchingOpenPopover = this._getOpenPopover(payload.popoverId);
    if (matchingOpenPopover) {
      if (shouldToggle) {
        await this._discardPopover(matchingOpenPopover);
      }
      return null;
    }

    const popoverActor = this._getAvailablePopover();

    // If there are no available popovers, repurpose an existing popover
    if (!popoverActor) {
      console.warn('No available popovers, repurposing existing popover');
      return this._repurposeOpenPopover(payload);
    }

    if (payload.enterDelay && payload.trigger === 'hover') {
      return this._queueOpenPopover(popoverActor, payload, payload.enterDelay);
    }

    return this._tryOpenPopover(popoverActor, payload);
  }

  private _repurposeOpenPopover(payload: OpenPopoverEvent['payload']) {
    const openPopover = this._openPopovers[0]; // Repurpose the oldest popover

    if (!openPopover) {
      console.error('No open popovers to repurpose');
      return null;
    }

    const popoverActor = this._popovers.get(openPopover.popoverId);

    if (!popoverActor) {
      console.error('No open popovers actors to repurpose');
      return null;
    }

    // Remove the popover from the open list
    this._openPopovers = this._openPopovers.filter((p) => p.popoverId !== openPopover.popoverId);

    if (payload.enterDelay && payload.trigger === 'hover') {
      return this._queueOpenPopover(popoverActor, payload, payload.enterDelay);
    }

    return this._tryOpenPopover(popoverActor, payload);
  }

  private async _queueOpenPopover(
    popover: PopoverActor,
    payload: OpenPopoverEvent['payload'],
    enterDelay: number
  ) {
    const innerPopoverId = payload.popoverId;
    const existingQueuedPopover = this._openQueuedPopovers.get(innerPopoverId);
    if (existingQueuedPopover) {
      clearTimeout(existingQueuedPopover.timeout);
    }

    let unsubEvents: Unsubscribe | null = null;

    const timeout = setTimeout(() => {
      this._openQueuedPopovers.delete(innerPopoverId);
      unsubEvents?.();
      this._tryOpenPopover(popover, payload).catch((error) => {
        console.error(error);
      });
    }, enterDelay);

    unsubEvents = this.popoverEventsTransport.listen(POPOVER_EVENT.CLOSE_POPOVER, (payload) => {
      if (payload.popoverId === innerPopoverId) {
        clearTimeout(timeout);
        this._openQueuedPopovers.delete(innerPopoverId);
        unsubEvents?.();
      }
    });

    this._openQueuedPopovers.set(innerPopoverId, {
      timeout,
      events: unsubEvents
    });
  }

  private async _tryOpenPopover(popover: PopoverActor, payload: OpenPopoverEvent['payload']) {
    const popoverId = popover.popoverId; // Represents the popover window ID
    const innerPopoverId = payload.popoverId; // Represents the inner popover ID (a unique ID for each popover trigger in the comp tree)

    // Remove the popover from the available list and add it to the open list
    this._availablePopovers.delete(popoverId);

    // Add it to the open popovers list with the new inner popover ID
    const closingStrategy = payload.closingStrategy || DEFAULT_POPOVER_OPTIONS.closingStrategy;

    const newPopover: OpenPopover = {
      popoverId,
      innerPopoverId,
      closingStrategy
    };

    this._openPopovers = [
      ...this._openPopovers.filter((openPopover) => openPopover.popoverId !== popoverId),
      newPopover
    ];

    try {
      // Open the popover
      const bounds = await popover.actor.operations.openPopover(payload);

      // Listen to events (to close the popover on certain conditions/closingStrategies)
      newPopover.events = this._createPopoverEvents(popover, payload, closingStrategy, bounds);

      // Emit the opened popover event
      this.popoverEventsTransport.emit(POPOVER_EVENT.OPENED_POPOVER, payload).catch(console.error);
    } catch (error) {
      // If the popover fails to open, add it back to the available list
      this._availablePopovers.add(popoverId);
      // Find, unlisten & remove it from the open list
      const openPopoverIndex = this._openPopovers.findIndex(
        (openPopover) => openPopover.popoverId === popoverId
      );
      const openPopover = this._openPopovers[openPopoverIndex];
      if (openPopover) {
        openPopover.events?.(); // Unsubscribe from events
        this._openPopovers.splice(openPopoverIndex, 1); // Remove it from the open list
      }
      // Rethrow the error
      throw error;
    }

    return popover;
  }

  private async _closeOpenPopover(innerPopoverId: string) {
    const openPopover = this._getOpenPopover(innerPopoverId);

    if (openPopover) {
      await this._discardPopover(openPopover);
    }
  }

  private async _discardPopover(actor: PopoverActor) {
    await actor.actor.operations.discardPopover();
    this._availablePopovers.add(actor.popoverId);

    const openPopoverIndex = this._openPopovers.findIndex(
      (openPopover) => openPopover.popoverId === actor.popoverId
    );

    const openPopover = this._openPopovers[openPopoverIndex];

    if (openPopover) {
      openPopover.events?.(); // Unsubscribe from events
      this._openPopovers.splice(openPopoverIndex, 1);
      this.popoverEventsTransport
        .emit(POPOVER_EVENT.CLOSED_POPOVER, {
          popoverId: openPopover.innerPopoverId,
          windowId: this._rootActor.id
        })
        .catch(console.error);
    }
  }

  private _getOpenPopover(innerPopoverId: string) {
    const popovers = this._openPopovers;
    const matchingPopover = popovers.find(({ innerPopoverId: innerId }) => innerId === innerPopoverId);

    if (!matchingPopover) {
      return null;
    }

    const popover = this._popovers.get(matchingPopover.popoverId);
    return popover || null;
  }

  private _getAvailablePopover() {
    const popoverIds = Array.from(this._availablePopovers.values());
    const popoverId = popoverIds.length > 0 ? popoverIds[0] : null;
    const popover = popoverId ? this._popovers.get(popoverId) : null;
    return popover || null;
  }

  private _createPopoverEvents(
    popover: PopoverActor,
    payload: OpenPopoverEvent['payload'],
    closingStrategy: ClosingStrategy,
    popoverBounds: PopoverOpenedBounds
  ): Unsubscribe {
    const popoverId = popover.popoverId; // Represents the popover window ID
    const innerPopoverId = payload.popoverId; // Represents the inner popover ID (a unique ID for each popover trigger in the comp tree)

    let unlistens: Unsubscribe[] = [];
    let timer: NodeJS.Timeout | null = null;

    /**
     * Subscribe to popover size changes
     */
    unlistens.push(
      popover.actor.listen('context', (ctx) => {
        popoverBounds.height = ctx.height;
        popoverBounds.width = ctx.width;
        if (isDefined(ctx.x) && isDefined(ctx.y)) {
          popoverBounds.x = ctx.x;
          popoverBounds.y = ctx.y;
        }
      })
    );

    /**
     * If the closing strategy is hover-outside, we need to check if the mouse is outside the popover
     */
    if (closingStrategy === 'hover-outside') {
      let queueClose: NodeJS.Timeout | null = null;

      // Creator fn to create a timer to close the popover
      const createCloseTimer = () =>
        setTimeout(() => {
          this._closeOpenPopover(innerPopoverId).catch(console.error);
        }, payload.exitDelay);

      // Check if the mouse is outside the popover every 100ms
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      timer = setInterval(async () => {
        try {
          const openPopover = this._getOpenPopover(innerPopoverId);
          if (openPopover) {
            // Get the mouse position
            const mousePos = await openPopover.actor.operations.getMousePosition();

            // Check if the mouse is outside the popover
            const { isOutsideElement, isOutsidePopover } = getIntersectionBounds(
              payload,
              popoverBounds,
              mousePos
            );

            if (isOutsidePopover && isOutsideElement) {
              // If the mouse is outside the popover, queue a close (if exit delay)
              if (payload.exitDelay) {
                if (!queueClose) {
                  queueClose = createCloseTimer();
                }
              } else {
                // If no exit delay, close the popover
                this._closeOpenPopover(innerPopoverId).catch(console.error);
              }
            } else {
              // If the mouse is inside the popover, clear the close queue
              // And create a new close queue (if exit delay)
              if (queueClose && payload.exitDelay) {
                clearTimeout(queueClose);
                queueClose = createCloseTimer();
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      }, 100);
    }

    /**
     * Listen to click events to close the popover
     */
    switch (closingStrategy) {
      case 'hover-outside':
      case 'click-outside-ex-trigger':
      case 'click-outside': {
        unlistens.push(
          listenToCustomActorEvent<ClickEvent>(this._eventsTransport, 'click', (event) => {
            // If it's a dragstart op, then we're dragging a window, and we should close the popover regardless
            if (event.event.payload.type === 'inside-dragstart') {
              this._closeOpenPopover(innerPopoverId).catch(console.error);
              return;
            }

            // If window is origin or popover...
            const isPopoverWindow = event.actor.id === payload.windowId || event.actor.id === popoverId;

            // If the click originated from the popover AND it's a click outside the component, then close the popover
            if (
              isPopoverWindow &&
              closingStrategy !== 'click-outside-ex-trigger' &&
              event.event.payload.type === 'outside-component'
            ) {
              this._closeOpenPopover(innerPopoverId).catch(console.error);
              return;
            }

            // If the click originated from the popover AND it's a click inside the component, then do nothing
            if (!isPopoverWindow) {
              return;
            }

            // Get the mouse position
            const mousePos = event.event.payload;
            const { isOutsideElement, isOutsidePopover } = getIntersectionBounds(
              payload,
              popoverBounds,
              mousePos
            );

            // Check if the mouse is outside the popover
            const canClickOutside =
              closingStrategy === 'hover-outside'
                ? isOutsideElement
                : closingStrategy === 'click-outside' ||
                  (closingStrategy === 'click-outside-ex-trigger' && isOutsideElement);

            // If the mouse is outside the popover, close it
            if (isOutsidePopover && canClickOutside) {
              this._closeOpenPopover(innerPopoverId).catch(console.error);
            }
          })
        );
        break;
      }
      case 'click-anywhere': {
        unlistens.push(
          listenToCustomActorEvent<ClickEvent>(this._eventsTransport, 'click', () => {
            this._closeOpenPopover(innerPopoverId).catch(console.error);
          })
        );
        break;
      }
    }

    /**
     * Listen to delta updates to the popover context
     */
    unlistens.push(
      this.popoverEventsTransport.listen(POPOVER_EVENT.POPOVER_COMPONENT_PROPS_UPDATE, (payload) => {
        if (payload.popoverId === innerPopoverId) {
          if (payload.method === 'set') {
            popover.actor.operations.setComponentProps(payload.componentProps).catch(console.error);
          } else {
            popover.actor.operations.updateComponentProps(payload.componentProps).catch(console.error);
          }
        }
      })
    );

    return () => {
      unlistens.forEach((unlisten) => unlisten());
      unlistens = [];
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }

  destroy() {
    this._popovers.forEach((popover) => {
      popover.actor.destroy().catch(() => {
        // TODO: This throws after timeout on workspace destroy
        // noop
      });
    });
    this._unlisteners.forEach((unlistener) => unlistener());
    this._unlisteners = [];
  }
}
