import type { GetInitials } from './avatar.types';

/**
 * Get initials from full or partial name
 *
 * @param name name input
 * @returns capitalized initials
 */
export const defaultGetInitials: GetInitials = ({ name, count, isFullName }, options) => {
  const { initials = 'single', fallbackName = '', ignoreCount } = options ?? {};
  if (typeof count === 'number' && count !== 1 && !ignoreCount) return `${count}`;
  if (!name || name.length === 0) {
    return fallbackName.length > 1
      ? defaultGetInitials({ name: fallbackName, count, isFullName }, options)
      : fallbackName;
  }
  if (isFullName) return initials === 'single' ? name.charAt(0).toUpperCase() : name;
  const firstInitial = name.charAt(0).toUpperCase();
  switch (initials) {
    case 'single':
      return firstInitial;
    default: {
      const { separator = ' ', joiner = '' } = options ?? {};
      const split = name.split(separator);
      if (split.length > 1) {
        const lastInitial = split[split.length - 1]?.charAt(0).toUpperCase();
        if (!lastInitial) return firstInitial;
        return [firstInitial, lastInitial].join(joiner);
      } else {
        return firstInitial;
      }
    }
  }
};
