import { compactMap } from '@oms/shared/util';
import { type Observable, map } from 'rxjs';
import {
  type AdvancedSelectParams,
  type AdvancedSelectQueryReturnWatchAll,
  type ResultMapper
} from './query-mapper.types';
import type { DataSourceCommon, ICrudService } from '../../../../common/data-access.common';
import type { ComboBoxItemUnion } from '../../../../combo-box/combo-box.types';
import type { AnyRecord } from '@valstro/workspace';

export type AdvancedSelectPredicate<TData> = (data: TData) => boolean;

export type GetQueryObservableFn<DSourceT extends AnyRecord> = () => Observable<DataSourceCommon<DSourceT>>;

export type GetQueryObservableFnWithParams<DSourceT extends AnyRecord, ValueT = unknown> = (
  params: AdvancedSelectParams<ValueT>
) => Observable<DataSourceCommon<DSourceT>>;

export type FromCrudService<DSourceT extends AnyRecord> = { fromService: ICrudService<DSourceT> };

export type FromQueryWithParams<DSourceT extends AnyRecord, ValueT = unknown> = {
  withParams: GetQueryObservableFnWithParams<DSourceT, ValueT>;
};

const isFromQueryWithParams = <DSourceT extends AnyRecord, ValueT = unknown>(
  input: object
): input is FromQueryWithParams<DSourceT, ValueT> => {
  if (input === null) return false;
  return typeof (input as Partial<FromQueryWithParams<any, any>>).withParams !== 'undefined';
};

const isFromCrudService = <DSourceT extends AnyRecord>(input: object): input is FromCrudService<DSourceT> => {
  if (input === null) return false;
  return typeof (input as Partial<FromCrudService<any>>).fromService !== 'undefined';
};

type AdvancedSelectQueryReturnArgs<DSourceT extends AnyRecord, ValueT = unknown> = {
  queryFn: GetQueryObservableFn<DSourceT> | FromCrudService<DSourceT> | FromQueryWithParams<DSourceT, ValueT>;
  resultMapper: ResultMapper<DSourceT, ValueT>;
  additionalItems?: Array<ComboBoxItemUnion<ValueT>>;
  filter?: AdvancedSelectPredicate<DSourceT>;
  sortResults?: Parameters<Array<DSourceT>['sort']>[0];
  type?: 'watchAll';
};

export const getAdvancedSelectQueryReturn = <DSourceT extends AnyRecord, ValueT = unknown>({
  queryFn,
  resultMapper,
  additionalItems,
  filter,
  sortResults,
  type = 'watchAll'
}: AdvancedSelectQueryReturnArgs<DSourceT, ValueT>): AdvancedSelectQueryReturnWatchAll<ValueT> => ({
  type,
  query: (params) =>
    (() => {
      if (typeof queryFn === 'function') {
        return queryFn();
      } else if (isFromQueryWithParams(queryFn)) {
        return queryFn.withParams(params);
      } else if (isFromCrudService(queryFn)) {
        return queryFn.fromService.watchAll$();
      } else {
        throw new Error('Invalid queryFn');
      }
    })().pipe(
      map(({ results: _results, isFetching, error }) => {
        if (sortResults && Array.isArray(_results)) {
          _results.sort(sortResults as (a: AnyRecord, b: AnyRecord) => number);
        }

        const results = compactMap(_results as DSourceT[], (result: DSourceT) => {
          if (filter && !filter(result)) return;
          return resultMapper(result);
        });

        if (additionalItems) {
          results.push(...additionalItems);
        }

        return {
          isFetching,
          error,
          results
        };
      })
    )
});
