import i18n from 'i18next';
import {
  omsAppTranslationResources,
  OMS_APP_TRANSLATION_DEFAULT_NS,
  OMS_APP_TRANSLATION_SHORT_NS,
  OMS_APP_TRANSLATION_DEFAULT
} from '@oms/codegen/translations';
import { initReactI18next } from 'react-i18next';
import { createLogger } from '@oms/shared/util';

export const i18nLogger = createLogger({ label: 'I18n' });

export function initI18n() {
  i18n
    .use(initReactI18next)
    .init({
      fallbackLng: OMS_APP_TRANSLATION_DEFAULT,
      lng: OMS_APP_TRANSLATION_DEFAULT,
      ns: [OMS_APP_TRANSLATION_DEFAULT_NS, OMS_APP_TRANSLATION_SHORT_NS],
      defaultNS: OMS_APP_TRANSLATION_DEFAULT_NS,
      resources: omsAppTranslationResources,
      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
    })
    .then(() => {
      i18nLogger.log('i18n initialized');
    })
    .catch((err) => {
      i18nLogger.error(err);
    });
}
