import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type { BulkRepairTradeFormInput, BulkRepairTradeFormOutput } from './bulk-repair-trade.form-common';
import { bulkRepairTradeContract } from './bulk-repair-trade.form-contract';
import type {
  BulkRepairTradeFormContractType,
  BulkRepairTradeFormValues
} from './bulk-repair-trade.form-contract';
import TradeRepairRequestService from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import { getSelectedAdditionalFields } from '@app/forms/common/utils/field.utils';

// Bulk Repair Trade Form Builder
export const bulkRepairTradeBuilder = FormBuilder.create<BulkRepairTradeFormInput, BulkRepairTradeFormOutput>(
  'bulk-repair-trade'
)
  .contract<BulkRepairTradeFormContractType>(bulkRepairTradeContract)
  .type('route-order')
  .sanitizer((s) =>
    s
      .input(function sanitize(input) {
        let bulkRepairTradeFormValues: Partial<BulkRepairTradeFormValues> = {
          hiddenFormInfo: {
            tradeRepairRequestIds: input?.tradeRepairRequestIds || []
          }
        };

        return bulkRepairTradeFormValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues?.hiddenFormInfo) {
          return;
        }

        return {
          hiddenFormInfo: formValues.hiddenFormInfo,
          tradeCounterpartyId: formValues.tradeCounterparty?.id || '',
          tags: formValues.tradeTags?.map((tag) => tag?.id) || []
        };
      })
  )
  .change(async (event, ctx) => {
    const selectedFields = await getSelectedAdditionalFields(event.meta.formType, ctx.container);

    const tradeRepairService = ctx.container.resolve(TradeRepairRequestService);

    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const { output } = event.payload;

        // Create a 'trade' object which the mutation expects as RepairTradeFields type.
        // If the field in question is a "selected field" shown on the main form,
        // and there's no field value, force the value to be blank.
        const tradeObj = {
          ...(selectedFields.includes('tradeCounterparty')
            ? { tradeCounterpartyId: output.tradeCounterpartyId }
            : {}),
          ...(selectedFields.includes('tradeTags') ? { tags: JSON.stringify(output.tags) || '[]' } : {})
        };

        // Build an array of RepairTradeInput
        const repaiTradesRequests = output.hiddenFormInfo.tradeRepairRequestIds.map((id: string) => ({
          tradeRepairRequestId: id,
          trade: tradeObj
        }));

        return await tradeRepairService.repairTradesRequest(repaiTradesRequests);
      }
    }
  });

export default bulkRepairTradeBuilder;
export type BulkRepairTradeFormBuilderType = typeof bulkRepairTradeBuilder;
