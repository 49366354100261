import { Lifecycle, scoped, inject } from 'tsyringe';
import { GridIdService } from '@oms/frontend-vgrid';
import type { EventHandler, EventSource, GridEvent } from '@oms/frontend-vgrid';
import type { ColumnState, GridApi } from '@ag-grid-community/core';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import debounce from 'lodash/debounce';
import { GridConfigService } from './grid-config.service';
import type {
  GridState,
  GridTemplateDocument
} from '@app/data-access/offline/collections/grid-templates.collection';
import type { GridsDocument } from '@app/data-access/offline/collections/grids.collection';
import { createLogger } from '@oms/shared/util';
import isEqual from 'lodash/isEqual';
import { AppWorkspace } from '@app/app-config/workspace.config';

// TODO: Switch to advancedFilterModel globally (new module) (requires changes to server side grid)

const l = createLogger({ label: 'GridConfigEventHandler' });

/**
 * Event handler for grid config
 * - Loads the current template from the client database
 * - Applies the template to the grid
 * - Listens for changes to the grid and saves them to a template
 */
@scoped(Lifecycle.ContainerScoped)
export class GridConfigEventHandler implements EventHandler {
  private prevGridState: GridState | null = null;
  private gridDoc: GridsDocument | null = null;
  private currentTemplateDoc: GridTemplateDocument | null = null;
  private state: 'hydrating' | 'first-load' | 'ready' = 'hydrating';
  private gridId: string;
  private _api: GridApi | null = null;
  public name = 'offline-grid';
  static readonly defaultColStateProps: Partial<ColumnState> = {
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null
  };

  constructor(
    @inject(GridIdService) private gridIdService: GridIdService,
    @inject(GridConfigService) private gridService: GridConfigService,
    @inject(AppWorkspace) private workspace: AppWorkspace
  ) {
    this.gridId = gridIdService.gridId;
  }

  public addEvents(eventSource: EventSource<keyof GridEvent>): void {
    // Error handling for hydration
    const handleFailedHydration = (e: unknown) => {
      this.state = 'ready';
      l.error(this.gridId, 'Failed to hydrate grid state');
      console.error(e);
      openMessageDialog('Failed to hydrate grid state', this.workspace).catch(console.error);
    };

    // Hydrate server side grid on grid ready
    eventSource.add('onGridReady', (e) => {
      this._api = e.api;
      if (this.isServerSide) {
        this.hydrate().catch(handleFailedHydration);
      }
    });

    // Hydrate client side grid on first data rendered (to get all the data & thus set filter values)
    eventSource.add('onFirstDataRendered', (e) => {
      this._api = e.api;
      if (this.isServerSide === false) {
        this.hydrate().catch(handleFailedHydration);
      }
    });

    // Save grid state on various events
    eventSource.add('onFilterChanged', () => this.save());
    eventSource.add('onSortChanged', () => this.save());
    eventSource.add('onColumnValueChanged', () => this.save());
    eventSource.add('onGridColumnsChanged', () => this.save());
    eventSource.add('onColumnMoved', () => this.save());
    eventSource.add('onColumnPinned', () => this.save());
    eventSource.add('onColumnResized', () => this.save());
    eventSource.add('onColumnPivotChanged', () => this.save());
    eventSource.add('onColumnRowGroupChanged', () => this.save());
  }

  public removeEvents(): void {}

  private async hydrate(): Promise<void> {
    const result = await this.gridService.find(this.gridId);
    if (!result) {
      this.gridDoc = await this.createNewGrid();
      this.state = 'ready';
      this.prevGridState = this.getAgGridState();
      return;
    }

    l.log('Found existing grid');

    const { currentGridTemplateDoc, doc } = result;
    let state = doc.toMutableJSON().state;
    if (currentGridTemplateDoc) {
      state = currentGridTemplateDoc.state;
    }
    const { filterModel, columnGroupState, columnState } = state;

    if (columnGroupState !== undefined) {
      this.api.setColumnGroupState(columnGroupState);
    }

    if (columnState !== undefined) {
      this.api.applyColumnState({
        state: columnState,
        applyOrder: true
      });
    }

    if (filterModel !== undefined) {
      this.api.setFilterModel(filterModel);
    }

    this.gridDoc = doc;
    this.currentTemplateDoc = currentGridTemplateDoc;
    this.prevGridState = this.getAgGridState();
    this.state = 'first-load';
  }

  private updateGridState = async () => {
    if (this.state === 'first-load') {
      this.state = 'ready';
      return;
    }

    const state = this.getAgGridState();

    if (!state || isEqual(this.prevGridState, state)) {
      return;
    }

    this.prevGridState = state;

    if (!this.gridDoc) {
      this.gridDoc = await this.createNewGrid();
    }

    await this.gridService.updateDoc(this.gridDoc, {
      state
    });
  };

  private onStateUpdatedDebounced = debounce(this.updateGridState, 500);

  private save = () => {
    if (this.state === 'hydrating') {
      return;
    }

    this.onStateUpdatedDebounced()?.catch(console.error);
  };

  private createNewGrid() {
    return this.gridService.create({
      gridId: this.gridId,
      widgetActorId: this.gridIdService.widgetId,
      state: {}
    });
  }

  private getAgGridState() {
    if (this.api.isDestroyed()) return null;
    const columnState = this.stripDefaultColumnState(this.api.getColumnState());
    const columnGroupState = this.api.getColumnGroupState();
    const filterModel = this.api.getFilterModel();

    const state: GridState = {
      columnState,
      columnGroupState,
      filterModel
    };

    return state;
  }

  private get api(): GridApi {
    if (!this._api) {
      throw new Error('Grid API is not set');
    }
    return this._api;
  }

  private get isServerSide(): boolean {
    return this.api.getGridOption('serverSideDatasource') !== undefined;
  }

  // Strip default column state values to reduce the size of the state
  private stripDefaultColumnState(columnState: ColumnState[]): ColumnState[] {
    return columnState.map((col) => {
      const strippedCol: ColumnState = { ...col };
      for (const key in GridConfigEventHandler.defaultColStateProps) {
        if (
          strippedCol[key as keyof ColumnState] ===
          GridConfigEventHandler.defaultColStateProps[key as keyof ColumnState]
        ) {
          delete strippedCol[key as keyof ColumnState];
        }
      }
      return strippedCol;
    });
  }
}
