import { AddNewInvestorOrderMonitor } from './investor-order-monitor.tabset.add-new';
import { useAddToolbarActions } from '@app/common/toolbar/hooks/use-add-toolbar-action.hook';

export const useInvestorOrderMonitorToolbarActions = () => {
  useAddToolbarActions(
    [
      {
        location: 'flexlayout-tabset',
        id: 'add-new-investor-order-monitor',
        component: AddNewInvestorOrderMonitor
      }
    ],
    []
  );
};
