import type { UserPreferenceTab } from '../user-preferences.widget.config';
import { MockUserSettings } from '@app/generated/sdk';

export type UserPreferenceRegistryItem = {
  label: string;
  component: React.ComponentType;
};

export type UserPreferenceRegistry = Record<UserPreferenceTab, UserPreferenceRegistryItem>;

export const USER_PREFERENCE_REGISTRY: UserPreferenceRegistry = {
  montage_settings: {
    label: 'Montage settings',
    component: MockUserSettings
  },
  market_data_settings: {
    label: 'Market data settings',
    component: MockUserSettings
  },
  order_settings: {
    label: 'Order settings',
    component: MockUserSettings
  },
  notification_settings: {
    label: 'Notification settings',
    component: MockUserSettings
  },
  my_profile: {
    label: 'My profile',
    component: MockUserSettings
  },
  manage_layouts: {
    label: 'Manage layouts',
    component: MockUserSettings
  },
  appearance: {
    label: 'Appearance',
    component: MockUserSettings
  },
  about: {
    label: 'About',
    component: MockUserSettings
  }
};
