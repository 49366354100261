import { OrderVwapForm } from '@app/generated/sdk';
import { useCallback, useMemo, useState } from 'react';
import type { OrderVwapFormValues } from './form/order-vwap.form-contract';
import { useWorkspaceContainer } from '@oms/frontend-foundation';
import type { OnValuesChanging } from '@oms/frontend-foundation';
import { Box, Button, Flex, Spinner } from '@oms/shared-frontend/ui-design-system';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import type { OrderVwapProps } from './order-vwap.widget.config';
import type { OrderVwapFormOutput } from './form/order-vwap.form-common';
import { orderVwapFormValuesToOutput } from './form/order-vwap.form-builder.sanitizer';

function OrderVwapWidgetComponent() {
  const [input, { investorOrder }, onValuesChanging] = useOrderVwapFormState();

  if (!input) {
    return <Spinner fillArea />;
  }

  return (
    <Flex sx={{ flexGrow: 1, width: 'full', flexDirection: 'column' }}>
      <Box sx={{ position: 'relative' }} style={{ height: 50 }}>
        <OrderVwapForm onValuesChanging={onValuesChanging} input={input} />
      </Box>
      <Box sx={{ backgroundColor: 'layout.level2', flexGrow: 1, px: 3, py: 3 }}>
        <pre>Investor Order: {investorOrder?.id}</pre>
      </Box>
      <Flex sx={{ justifyContent: 'flex-end', marginTop: 'auto', backgroundColor: 'layout.level1', p: 3 }}>
        <Button aria-label="refresh order vwap">Refresh</Button>
      </Flex>
    </Flex>
  );
}

function useOrderVwapFormState() {
  const [props] = useCurrentAppWidgetActorProps<OrderVwapProps>();
  const { investorOrderId } = props || {};
  const [state, setState] = useState<Partial<OrderVwapFormOutput>>({});
  const container = useWorkspaceContainer();

  const onValuesChanging: OnValuesChanging<OrderVwapFormValues> = useCallback(
    ({ formValues }) => {
      orderVwapFormValuesToOutput(formValues, container)
        .then((output) => {
          if (output) {
            setState(output);
          }
        })
        .catch((error) => {
          console.error('Failed to convert form values to output', error);
        });
    },
    [container]
  );

  const input = useMemo(() => {
    return investorOrderId ? { investorOrderId } : null;
  }, [investorOrderId]);

  return [input, state, onValuesChanging] as const;
}

export default OrderVwapWidgetComponent;
