import { forwardRef } from 'react';
import clsx from 'clsx';
import * as styles from '../checkbox/css/checkbox.css';
import type { CheckboxProps } from '../checkbox/checkbox';
import { inputOffsetOutline } from '../../system/styles.css';
import { Box } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { dataAttr } from '../../system/utils/dom';
import { useFormControl } from '../form-control/form-control';

type OmittedProps = 'isIndeterminate' | 'shape';

export interface RadioProps extends Omit<CheckboxProps, OmittedProps> {}

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    id,
    name,
    value,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedby,
    isDefaultChecked,
    isChecked,
    size = 'sm',
    onChange,
    children,
    className,
    isInvalid: _isInvalid,
    isReadOnly: _isReadOnly,
    sx = {},
    style = {},
    ...rest
  } = props;

  const { isDisabled, isInvalid, isReadOnly } = useFormControl(props);

  return (
    <Box
      as="label"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        verticalAlign: 'top',
        ...sx
      }}
      style={style}
      className={clsx(isDisabled && styles.checkboxLabelDisabled, className)}
      {...rest}
    >
      <Box
        as="input"
        type="radio"
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedby}
        id={id}
        ref={ref}
        name={name}
        value={value}
        onChange={isReadOnly ? undefined : onChange}
        defaultChecked={isReadOnly ? undefined : isDefaultChecked}
        checked={isChecked}
        disabled={isDisabled}
        aria-disabled={dataAttr(isDisabled)}
        readOnly={isReadOnly}
        aria-readonly={dataAttr(isReadOnly)}
        aria-invalid={dataAttr(isInvalid)}
        className={clsx('check-input', styles.checkboxRecipe({ size }), inputOffsetOutline)}
      />
      {children && <span className={clsx(styles.checkboxLabelRecipe({ size }))}>{children}</span>}
    </Box>
  );
});
if (__DEV__) {
  Radio.displayName = 'Radio';
}
