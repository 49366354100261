import type { InstrumentDetails } from '@oms/generated/frontend';

export const mapInstrument = (
  instrument:
    | InstrumentDetails
    | {
        longName: string;
        mappings: { displayCode?: string | null };
      }
): string => {
  return instrument.mappings.displayCode || instrument.longName;
};
