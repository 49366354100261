import type { RxJsonSchema, RxDocument, RxCollection, RxCollectionCreator } from 'rxdb-v15';
import type { ActorScoped } from '../types';

export const EXAMPLE_LAYOUT_COLLECTION_NAME = 'example_layouts';

export const exampleLayoutSchema: RxJsonSchema<ExampleLayoutDocType> = {
  title: 'example layout schema',
  description:
    'describes a simplistic & hypothetical layout state for a widget like portfolio manage or montage to be used as an example',
  version: 0,
  keyCompression: false,
  primaryKey: 'scopedActorId',
  type: 'object',
  properties: {
    // Making scopedActorId the primary key to avoid duplicates, as we can only have one state per example layout opened
    scopedActorId: {
      type: 'string',
      maxLength: 128
    },
    selectedSymbol: {
      type: 'string',
      maxLength: 32
    },
    testFieldToSimuateMigrationV0: {
      type: 'string'
    }
  },
  required: [],
  indexes: ['selectedSymbol']
} as const;

// Note: const schemaTyped = toTypedRxJsonSchema(exampleLayoutSchemaLiteral); && ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>; would auto generate the type for you
// But for perf reasons, I personally prefer to manually create the type, as it's a one time thing and it's easier to read and understand
// Another approach would be to define our schema in a separate file and use: json-schema-to-typescript module.
// https://rxdb.info/tutorials/typescript.html#declare-the-types
export type ExampleLayoutDocType = ActorScoped & {
  selectedSymbol?: string;
  testFieldToSimuateMigrationV0?: string;
};

export type ExampleLayoutDocument = RxDocument<ExampleLayoutDocType>;

export type ExampleLayoutCollection = RxCollection<ExampleLayoutDocType>;

export const EXAMPLE_LAYOUT_COLLECTION: RxCollectionCreator<any> = {
  schema: exampleLayoutSchema
  /**
   * This is a migration strategy, it's used to transform data from one version to another
   *
   * To simulate a migration:
   * 1. Change "testFieldToSimuateMigrationV0" to "testFieldToSimuateMigrationV1" in the schema to simulate a breaking change
   * 2. Try loading the app, you'll prompted that data is not compatible and you'll need to reset... DON'T RESET.
   * 3. Now change the "version" in the schema to "1" and uncomment the migrationStrategies below
   * 4. Now you can load the app without
   * -------------------------------------
   */
  // migrationStrategies: {
  //   // 1 means, this transforms data from version 0 to version 1
  //   1: function (oldDoc) {
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  //     oldDoc.testFieldToSimuateMigrationV1 = oldDoc.testFieldToSimuateMigrationV0;
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  //     return oldDoc;
  //   }
  // }
};
