import type { DraggableItem, DraggableSeparator, LayoutAction } from '../types';
import { DragModel } from './drag.model';

export const separator: DraggableSeparator = { isSeparator: true };

function addDraggableAction(action: LayoutAction, list: DraggableItem[]): void {
  const newAction = { ...action };
  list.push(newAction);
  if (newAction.separator) {
    newAction.separator = false;
    list.push(separator);
  }
}

export const formatActionInput = (actions: LayoutAction[]) => {
  const model: DraggableItem[] = [];
  let i = 0;
  do {
    if (!actions[i]) {
      i += 1;
    } else if (actions[i].parentId) {
      // create a group
      const group: DraggableItem[] = [];
      do {
        addDraggableAction(actions[i + group.length], group);
      } while (actions[i].parentId === actions[i + group.length]?.parentId);
      model.push(group);

      // skip to the end of the group
      i += group.length;
    } else {
      addDraggableAction(actions[i], model);
      i += 1;
    }
  } while (i < actions.length);

  return model;
};

export const validateDragModel = (dragModel: DraggableItem[]): boolean => {
  // cannot start with a separator
  if ('isSeparator' in dragModel[0]) return false;

  return dragModel.every((item) => {
    if (Array.isArray(item)) {
      return validateDragModel(item);
    } else {
      return true;
    }
  });
};

export function formatActionOutput(
  actions: DraggableItem[],
  formattedOutput: LayoutAction[] = [],
  parentId: string | undefined = undefined,
  flatPosition = { i: 0 }
): LayoutAction[] {
  actions.forEach((action) => {
    if ('isSeparator' in action && action.isSeparator) {
      const lastAction = formattedOutput.at(-1);
      if (lastAction) {
        lastAction.separator = true;
      }
    } else if (Array.isArray(action)) {
      // groups may only begin with an action
      while (!('id' in action[0])) {
        action.shift();
      }
      if (action.length === 0) {
        return;
      }
      formatActionOutput(action, formattedOutput, action[0].id, flatPosition);
    } else {
      formattedOutput.push({
        ...action,
        parentId,
        order: flatPosition.i++,
        separator: false
      } as LayoutAction);
    }
  });
  return formattedOutput;
}

export const dragModel = new DragModel(
  {
    input: formatActionInput,
    output: formatActionOutput
  },
  validateDragModel
);
