import type { AnyKey, AnyRecord, Maybe, OneOrMore } from '@oms/shared/util-types';
import { asArray } from '@oms/shared/util';
import type { SplitColumnFilters } from '../../types/grid-filter.types';
import type { NestedTreeData } from '../../types/tree-data.types';
import type { FilterTreeDataOptions } from '../internal/types';
import { extractItemKey } from '../tree-data/tree-data-key.util';
import { getFilteredIdSetFromNestedData } from './grid-tree-data-filtering.internal.util';

// 🥅 Grid tree data filtering utils -------------------------------------------------------------- /
// Utils to filter nested tree data for the ag-Grid server side render model

/**
 * Use to apply filtering for the ag-Grid auto column to nested data.
 *
 * ### Rules:
 *  - Parents are considered selected with ALL children are in the filter
 *  - Otherwise, parents of selected nodes should NOT be included in the filter
 *
 * **NOTE:** There is Valstro-specific tree filtering logic in this, and may not be appropriate for all tree data filtering.
 *
 * @param data One or more (object by itself or an array of) nested objects (with possible children)
 * @param pathKeys One more more path keys (each item's full hierarchy path), supplied by ag-Grid for the auto column filter
 * @param options.getKey - Specify how to get the key value (id, etc.) from the data
 * @param options.separator - Specify how levels of the `pathKey` key are separated (defaults to ".")
 * @param options.convertString - A callback to set convert hierarchy value in case hierarchy key isn't a string
 * @returns An array of nested data with filtering applied
 */
export const filterTreeData = <TData extends AnyRecord, Key extends AnyKey = string>(
  data: OneOrMore<NestedTreeData<TData>>,
  pathKeys?: Maybe<OneOrMore<Maybe<Key>>>,
  options?: FilterTreeDataOptions<TData, Key>
): NestedTreeData<TData>[] => {
  const { getKey } = options ?? {};
  if (typeof pathKeys === 'undefined' || pathKeys === null) return asArray(data);
  const filteredIdSet = getFilteredIdSetFromNestedData(pathKeys, data, options);
  const filterRecursive = (data: OneOrMore<NestedTreeData<TData>>): Set<NestedTreeData<TData>> =>
    asArray(data).reduce((includedItemSet, { children, ...rest }) => {
      const item = rest as TData;
      const itemKey = extractItemKey(item, { getKey });
      const isInFilter = itemKey ? filteredIdSet.has(itemKey) : false;
      const filteredChildren = children ? filterRecursive(children) : undefined;
      if (isInFilter) {
        includedItemSet.add(filteredChildren?.size ? { ...item, children: [...filteredChildren] } : item);
      } else if (filteredChildren?.size) {
        filteredChildren.forEach((filteredChild) => {
          includedItemSet.add(filteredChild);
        });
      }
      return includedItemSet;
    }, new Set<NestedTreeData<TData>>());
  return [...filterRecursive(data)];
};

/**
 * Use to apply filtering for the ag-Grid auto column and all other columns to nested data.
 *
 * ### Rules:
 *  - Parents are considered selected with ALL children are in the filter
 *  - Otherwise, parents of selected nodes should NOT be included in the filter
 *
 * **NOTE:** There is Valstro-specific tree filtering logic in this, and may not be appropriate for all tree data filtering.
 *
 * @param data One or more (object by itself or an array of) nested objects (with possible children)
 * @param filters Supply the filter models from ag-Grid, first passing through the `splitColumnFilters` util
 * @param options.getKey - Specify how to get the key value (id, etc.) from the data
 * @param options.separator - Specify how levels of the `pathKey` key are separated (defaults to ".")
 * @param options.convertString - A callback to set convert hierarchy value in case hierarchy key isn't a string
 * @returns An array of nested data with filtering applied
 */
export const applyFilterModels = <TData extends AnyRecord>(
  data: OneOrMore<NestedTreeData<TData>>,
  filters: SplitColumnFilters<TData>,
  options?: FilterTreeDataOptions<TData, string>
): NestedTreeData<TData>[] => {
  const pathKeys = filters.autoColumn?.values;
  const filteredTreeData = filterTreeData(data, pathKeys, options);
  const processRecursive = (data: OneOrMore<NestedTreeData<TData>>): NestedTreeData<TData>[] =>
    asArray(data).reduce((filteredValues, item) => {
      const { children, ...rest } = item;
      const dataItem = rest as TData;
      const shouldInclude = filters.columns.reduce((shouldInclude, filter) => {
        // Once we find a negative filter, we don't need to check others
        if (!shouldInclude) return false;
        const { key, ...filterModel } = filter;
        return filterModel.values.includes(dataItem[key] as string);
      }, true);
      const filteredChildren = children?.length ? processRecursive(children) : undefined;
      if (shouldInclude) {
        filteredValues.push(
          filteredChildren?.length ? { ...dataItem, children: filteredChildren } : dataItem
        );
      } else if (filteredChildren?.length) {
        filteredValues.push(...filteredChildren);
      }
      return filteredValues;
    }, [] as NestedTreeData<TData>[]);
  return processRecursive(filteredTreeData);
};
