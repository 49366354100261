import type React from 'react';
import type { FieldProps, ICommonField } from '../../types';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import type { Validator } from '@data-driven-forms/react-form-renderer';

export interface IHiddenField<TValue = any, TValidator = Validator>
  extends ICommonField<typeof FORM_COMPONENT_TYPE.HIDDEN_FIELD, TValue, TValidator> {}

export const HiddenField: React.FC<FieldProps<IHiddenField>> = (_props) => {
  return null;
};
