import { useLayoutEffect } from 'react';
import { ColorMode } from './color-mode-provider';
import { darkThemeClassName, lightThemeClassName } from '../../theme/theme.css';
import { darkMode, lightMode } from '../../system/sprinkles.css';

type ColorModeClass = string | undefined;

function setColorModeScript(initialValue: ColorModeClass) {
  const mql = window.matchMedia('(prefers-color-scheme: dark)');
  const systemPreference = mql.matches ? darkThemeClassName : lightThemeClassName;

  let persistedPreference: ColorModeClass;

  try {
    persistedPreference = localStorage.getItem('valstro-theme-pref') as ColorMode;
  } catch (error) {
    console.warn(
      'Valstro: localStorage is not available. Color mode persistence might not work as expected',
      error
    );
  }

  const isInStorage = typeof persistedPreference === 'string';

  let colorModeClass: ColorModeClass;

  if (isInStorage) {
    colorModeClass = persistedPreference === 'dark' ? darkThemeClassName : lightThemeClassName;
  } else {
    colorModeClass = initialValue === 'system' ? systemPreference : initialValue;
  }

  if (colorModeClass) {
    const root = document.documentElement;
    root.style.setProperty('--valstro-color-mode', colorModeClass === darkThemeClassName ? 'dark' : 'light');
    document.documentElement.classList.add(colorModeClass);
    document.documentElement.classList.add(colorModeClass === darkThemeClassName ? darkMode : lightMode);
    document.body.classList.add(colorModeClass);
    document.body.classList.add(colorModeClass === darkThemeClassName ? darkMode : lightMode);
  }
}

interface ColorModeScriptProps {
  initialColorMode?: ColorModeClass;
  /**
   * Optional nonce that will be passed to the created `<script>` tag.
   */
  nonce?: string;
}

/**
 * Script to add to the root of your application when using localStorage,
 * to help prevent flash of color mode that can happen during page load.
 */
export const ColorModeScript = (props: ColorModeScriptProps) => {
  const { initialColorMode = darkThemeClassName } = props;
  const html = `(${String(setColorModeScript)})('${initialColorMode}')`;

  useLayoutEffect(() => {
    setColorModeScript(initialColorMode);
  }, []);

  return <script nonce={props.nonce} dangerouslySetInnerHTML={{ __html: html }} />;
};
