import React, { useCallback } from 'react';
import { Button } from '@oms/shared-frontend/ui-design-system';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import type { FieldProps, ICommonButtonField } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';

export interface IResetButtonField extends ICommonButtonField<typeof FORM_COMPONENT_TYPE.RESET_BUTTON> {}

export const ResetButton: React.FC<FieldProps<IResetButtonField>> = (props) => {
  const { restart } = useFormApi();
  const {
    style = {},
    isFeatureField = false,
    isPrimaryField = false,
    size
  } = useFieldApi<IResetButtonField>(props);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      restart();
    },
    [restart]
  );

  return (
    <FieldWrapper isFeatureField={isFeatureField} isPrimaryField={isPrimaryField}>
      <Button
        palette={props.buttonPalette}
        variant="secondary"
        onClick={handleClick}
        size={isFeatureField || isPrimaryField ? 'lg' : size}
        style={{ width: isFeatureField || isPrimaryField ? '100%' : 'fit-content', ...style }}
      >
        {props.buttonText ? props.buttonText : 'Reset'}
      </Button>
    </FieldWrapper>
  );
};
