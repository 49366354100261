import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

/**
 * ag-grid renderer an empty group
 */
export const emptyGroupRenderer: React.FC<ICellRendererParams> = ({ value }) => (
  <span className="ag-cell-wrapper ag-cell-expandable ag-row-group">
    <span className="ag-group-expanded ag-hidden">
      <span className="ag-icon ag-icon-tree-open" unselectable="on" role="presentation"></span>
    </span>
    <span className="ag-group-contracted ag-invisible">
      <span className="ag-icon ag-icon-tree-closed" unselectable="on" role="presentation"></span>
    </span>
    <span className="ag-group-checkbox ag-invisible"></span>
    <span className="ag-group-value">{value}</span>
    <span className="ag-group-child-count"></span>
  </span>
);
