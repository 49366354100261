import type { Level1IntegrationEvent } from '@oms/generated/frontend';
import type { Subscription } from 'rxjs';
import { inject, injectable } from 'tsyringe';
import get from 'lodash/get';
import omit from 'lodash/omit';
import type { GridEventType, EventHandler, EventSource } from '@oms/frontend-vgrid';
import { MarketDataService } from '../marketdata.service';
import type {
  ColDef,
  GridApi,
  GridReadyEvent,
  ColumnVisibleEvent,
  RowDataUpdatedEvent
} from '@ag-grid-community/core';
import { IMarketDataService, MarketData } from '../marketdata.common';

interface MarketDataRowCache {
  data: Level1IntegrationEvent;
  nodes: Set<string>;
}

interface GridSettings {
  tickerColumnMap: Record<string, ColDef>;
  tickerData: Record<string, MarketDataRowCache>;
  tickerSub$?: Subscription;
  rowCount: number;
  tickerId: string;
}

@injectable()
export class MarketDataEventHandler implements EventHandler {
  public name = 'market-data-event-handler';

  private marketDataService: IMarketDataService;

  constructor(@inject(MarketDataService) marketDataService: IMarketDataService) {
    this.marketDataService = marketDataService;
  }

  private gridSettings: GridSettings = {
    tickerData: {} as Record<string, MarketDataRowCache>,
    tickerSub$: undefined,
    rowCount: 0,
    tickerId: ''
  } as any;

  private visibleTickerColumns(gridApi: GridApi, columnMap: Record<string, ColDef>): Set<string> {
    const result = new Set<string>();

    gridApi
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .filter((c) => !!columnMap[c])
      .forEach((c) => {
        result.add(c);
      });

    return result;
  }

  private readTickerStream(gridApi: GridApi, settings: GridSettings): Subscription {
    const tickers = Object.keys(settings.tickerData);

    tickers.forEach((t) => {
      const dataToUpdate: any[] = [];
      settings.tickerData[t].nodes.forEach((n) => {
        const node = gridApi.getRowNode(n);

        if (!node?.data) return;
        const level1 = this.marketDataService.read(t)?.level1;

        if (level1) {
          dataToUpdate.push({ ...node.data, ...omit(level1, '__typename', 'instrument') });
        }
      });

      if (gridApi.getGridOption('rowModelType') === 'serverSide') {
        gridApi.applyServerSideTransactionAsync({
          update: dataToUpdate
        });
      } else {
        gridApi.applyTransactionAsync({
          update: dataToUpdate
        });
      }
    });

    const newSub$ = this.marketDataService.observe(...tickers).subscribe((t) => {
      if (!t.data) return;

      settings.tickerData[t.ticker].data = t.data.level1;

      const dataToUpdate: any[] = [];

      settings.tickerData[t.ticker].nodes.forEach((n) => {
        const node = gridApi.getRowNode(n);
        if (!node?.data) return;
        const level1 = t.data?.level1 || {};
        dataToUpdate.push({ ...node.data, ...omit(level1, '__typename', 'instrument') });
      });

      if (gridApi.getGridOption('rowModelType') === 'serverSide') {
        gridApi.applyServerSideTransactionAsync({
          update: dataToUpdate
        });
      } else {
        gridApi.applyTransactionAsync({
          update: dataToUpdate
        });
      }
    });

    settings.tickerSub$?.unsubscribe();

    return newSub$;
  }

  private updateTickerRowCache(
    gridApi: GridApi,
    settings: GridSettings,
    forceRowDataChange: boolean = false
  ): { tickerChange: boolean; rowsChanged: any[] } {
    const currentRowCount = gridApi.getDisplayedRowCount();
    const existingTickers = new Set<string>(Object.keys(settings.tickerData));

    const rowDataChange = forceRowDataChange === true || settings.rowCount !== currentRowCount;
    let tickerDataChange = false;
    const rowsChanged: any[] = [];
    settings.rowCount = currentRowCount;

    const visibleTickerColumns = this.visibleTickerColumns(gridApi, this.gridSettings.tickerColumnMap);

    if (rowDataChange || visibleTickerColumns.size > 0) {
      gridApi.forEachNode((r) => {
        if (!r || !r.data) return;

        const data = r.data;
        const ticker = get(data, this.gridSettings.tickerId);

        if (!ticker) return;

        const missingMarketDataFields = this.marketDataService.read(ticker)
          ? Array.from(visibleTickerColumns.values()).some((f) => !data[f])
          : false;

        if (missingMarketDataFields) {
          rowsChanged.push(data);
          //console.log(`${ticker} is missing market data fields.`);
        }

        if (rowDataChange) {
          // console.log('Updating node cache.');
          if (!settings.tickerData[ticker]) {
            tickerDataChange = true;
            settings.tickerData[ticker] = {
              nodes: new Set<string>(),
              data: new MarketData()
            } as MarketDataRowCache;
          }

          if (!settings.tickerData[ticker].nodes.has(r.id || '')) {
            rowsChanged.push(r.data);
          }

          settings.tickerData[ticker].nodes.add(r.id || '');
          existingTickers.delete(ticker);
        }
      });

      if (rowDataChange) {
        tickerDataChange = tickerDataChange || existingTickers.size > 0;
        for (const removedTicker of existingTickers) {
          delete settings.tickerData[removedTicker];
        }
      }
    }

    return { tickerChange: tickerDataChange, rowsChanged };
  }

  private loadColumns(gridApi: GridApi): void {
    let seenTickerId = '';
    this.gridSettings.tickerColumnMap = gridApi
      .getAllGridColumns()
      ?.map((c) => c.getColDef())
      .filter((c) => {
        const def = c as ColDef;
        const refData = def.refData;
        const tickerId = refData && refData['tickerId'];
        if (!tickerId) return false;

        if (seenTickerId && seenTickerId !== tickerId) {
          console.warn(
            '[VGrid]: There are multiple ticker ids configured for this grid. Only this most recent one will be used. This is not recommended. Please re-configure.',
            seenTickerId,
            tickerId
          );
        }

        this.gridSettings.tickerId = tickerId || '';
        seenTickerId = this.gridSettings.tickerId;

        return !!tickerId;
      })
      .reduce(
        (prev, curr) => {
          const col = curr as ColDef;

          if (col && col.colId) {
            prev[col.colId] = curr;
          }

          return prev;
        },
        {} as Record<string, ColDef>
      );
  }

  public onGridReady(e: GridReadyEvent): void {
    this.loadColumns(e.api);
    this.updateTickerRowCache(e.api, this.gridSettings);

    if (this.visibleTickerColumns(e.api, this.gridSettings.tickerColumnMap).size > 0) {
      this.gridSettings.tickerSub$ = this.readTickerStream(e.api, this.gridSettings);
    }
  }

  public destroy(): void {
    this.gridSettings.tickerSub$?.unsubscribe();
  }

  public onColumnVisible(e: ColumnVisibleEvent): void {
    const visibleTickerColumns = this.visibleTickerColumns(e.api, this.gridSettings.tickerColumnMap).size > 0;
    if (
      visibleTickerColumns &&
      e.visible &&
      (!this.gridSettings.tickerSub$ || this.gridSettings?.tickerSub$?.closed)
    ) {
      // Creating ticker stream
      this.gridSettings.tickerSub$ = this.readTickerStream(e.api, this.gridSettings);
    }

    if (!visibleTickerColumns) {
      this.gridSettings.tickerSub$?.unsubscribe();
    }
  }

  public onRowDataUpdated(e: RowDataUpdatedEvent, forceRowDataChange = false): void {
    if (!this.gridSettings.tickerColumnMap) {
      this.loadColumns(e.api);
    }

    const { tickerChange, rowsChanged } = this.updateTickerRowCache(
      e.api,
      this.gridSettings,
      forceRowDataChange
    );

    if (!tickerChange && !rowsChanged.length) {
      return;
    }

    const visibleTickerColumns = this.visibleTickerColumns(e.api, this.gridSettings.tickerColumnMap).size > 0;

    if (!visibleTickerColumns) {
      this.gridSettings.tickerSub$?.unsubscribe();
      return;
    }

    if (visibleTickerColumns && tickerChange) {
      this.gridSettings.tickerSub$ = this.readTickerStream(e.api, this.gridSettings);
    }

    if (rowsChanged.length) {
      rowsChanged.forEach((r) => {
        const marketData = this.marketDataService.read(r.ticker);
        if (marketData && marketData.level1) {
          Object.assign(r, marketData.level1);
        }
      });

      if (e.api.getGridOption('rowModelType') === 'serverSide') {
        e.api.applyServerSideTransactionAsync({ update: rowsChanged });
      } else {
        e.api.applyTransactionAsync({ update: rowsChanged });
      }
    }
  }

  public addEvents(eventSource: EventSource<GridEventType>): void {
    eventSource.add('onGridReady', (e) => this.onGridReady(e));
    eventSource.add('onRowDataUpdated', (e) => this.onRowDataUpdated(e));
    eventSource.add('onPaginationChanged', (e) => this.onRowDataUpdated(e));
    eventSource.add('onColumnVisible', (e) => this.onColumnVisible(e));
    eventSource.add('onStoreUpdated', (e) => {
      this.onRowDataUpdated(e, true);
    });
  }

  public removeEvents(): void {
    this.destroy();
  }
}
