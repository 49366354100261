import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { type FormState, type FormSubscriber } from 'final-form';
import { get, isEqual, some } from 'lodash';
import { Observable, distinctUntilChanged } from 'rxjs';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { AnyFormValues, EnhancedFormSubscription, FormOptions } from '../../../types';

const convertFormSubscriptionToObservable = <T extends AnyFormValues = AnyFormValues>(
  formApi: FormOptions<T>,
  args: EnhancedFormSubscription<T>
) => {
  return new Observable<FormState<T, Partial<T>>>((observer) => {
    const unsubscribe = formApi.subscribe((formState) => {
      observer.next(formState);
    }, args);

    return () => {
      unsubscribe();
    };
  });
};

const fieldsChanged = <T extends AnyFormValues>(obj1: T, obj2: T, fieldsToCheck: (keyof T)[]) => {
  return some(fieldsToCheck, (field) => !isEqual(get(obj1, field), get(obj2, field)));
};

const shouldNotifySubscribers = <T extends AnyFormValues = AnyFormValues>(
  prevState: FormState<T, Partial<T>>,
  newState: FormState<T, Partial<T>>,
  args: EnhancedFormSubscription<T>
) => {
  return !(
    prevState === null ||
    fieldsChanged(prevState.values, newState.values, args.fields || []) ||
    !args.fields ||
    (newState.active && args.fields.includes(newState.active))
  );
};

export const useEnhancedFormApi = <T extends AnyFormValues>() => {
  const formApiRef = useRef(useFormApi() as FormOptions<T>);
  const initialValuesRef = useRef<Partial<T>>(formApiRef.current.getState().values);
  const modifiedFields = useRef<Partial<Record<keyof T, boolean>>>({});

  useEffect(() => {
    const unsubscribe = formApiRef.current.subscribe(
      ({ values }) => {
        // It's not enough to check the keys in values, we won't detect clearing
        // a select field like representativeCode by clicking the 'x'
        // getState().modified will return all the fields
        const fields = Object.keys(formApiRef.current.getState().modified ?? {});
        fields.forEach((field: keyof T) => {
          if (
            !modifiedFields.current[field] &&
            // We compare JSON.stringify instead of using isEqual because isEquaal does not
            // ignores undefined fields that exist in one and not the other which fails for
            // combobox items such as order tags that includes undefined sublabel etc.
            JSON.stringify(values[field]) !== JSON.stringify(initialValuesRef.current[field])
          ) {
            modifiedFields.current[field] = true;
          }
        });
      },
      { values: true }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const getStateObservable = useMemo(
    () => (args: EnhancedFormSubscription<T>) =>
      convertFormSubscriptionToObservable(formApiRef.current, args).pipe(
        distinctUntilChanged((prev, curr) => shouldNotifySubscribers(prev, curr, args))
      ),
    []
  );

  const subscribe = useMemo(
    () => (subscriber: FormSubscriber<T, Partial<T>>, args: EnhancedFormSubscription<T>) => {
      const { unsubscribe } = getStateObservable(args).subscribe((formState) => {
        subscriber(formState);
      });

      return () => unsubscribe;
    },
    [getStateObservable]
  );

  const createStateObservable = useMemo(
    () => (args: EnhancedFormSubscription<T>) => {
      return getStateObservable(args);
    },
    [getStateObservable]
  );

  const change = useCallback(<F extends keyof T>(name: F, value?: T[F]) => {
    formApiRef.current.change(String(name), value);
    initialValuesRef.current[name] = value;
    modifiedFields.current[name] = false;
  }, []);

  const getModifiedFields = useCallback(
    () => Object.keys(modifiedFields.current ?? {}).filter((key) => modifiedFields.current[key]),
    []
  );

  return useMemo(() => {
    return {
      ...formApiRef.current,
      subscribe,
      change,
      getModifiedFields,
      get$: createStateObservable
    };
  }, [subscribe, createStateObservable]);
};
