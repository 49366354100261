import { createContext, useContext } from 'react';
import { type ComboBoxItemUnion, type ComboBoxProps } from './combo-box.types';

export interface ComboBoxContext {
  setInputValue?: ComboBoxProps['setInputValue'];
  selectedItems?: ComboBoxProps['selectedItems'];
  setSelectedItems?: ComboBoxProps['setSelectedItems'];
  setItems?: ComboBoxProps['setItems'];
  submitInstantly?: ComboBoxProps['submitInstantly'];
  strategy: ComboBoxProps['strategy'];
  onSubmit?: ComboBoxProps['onSubmit'];
  onItemClick?: ComboBoxProps['onItemClick'];
  prevItemsRef?: React.MutableRefObject<ComboBoxItemUnion<any>[]>;
}

export const ComboBoxContext = createContext({} as ComboBoxContext);

export const useComboBoxContext = () => useContext(ComboBoxContext);
