import { TradeThroughDetails, SipData, SipQuote, OrderSide, SipQuoteDetail } from './shared-regnms-types';
import Decimal from 'decimal.js';

const emptyTradeThroughDetails: TradeThroughDetails = {
  tradeThrough: undefined,
  side: undefined,
  quantity: undefined,
  averagePrice: undefined,
  bids: [],
  asks: [],
  malformed: true
};

/**
 * Given SipData and a price on an Agency Cross or Principal Fill,
 * we can determine the regulatory NMS Trade Through Obligation values.
 *
 * @param sipData SipData
 * @param providedPrice number
 * @returns TradeThroughDetails that includes regulatory NMS Trade Through Obligation values
 */
export function getTradeThroughDetails(
  sipData: SipData | undefined,
  providedPrice?: number
): TradeThroughDetails {
  if (!sipData) return emptyTradeThroughDetails;
  if (sipData.askPrice == null || sipData.bidPrice == null) return emptyTradeThroughDetails;
  if (sipData.askPrice <= 0 || sipData.bidPrice <= 0) return emptyTradeThroughDetails;
  if (providedPrice == null) return emptyTradeThroughDetails;

  const tradeThrough: boolean | undefined = setTradeThrough(
    providedPrice,
    sipData.askPrice,
    sipData.bidPrice
  );
  const side: OrderSide | undefined = setSide(providedPrice, sipData.askPrice, sipData.bidPrice);

  const { quantity, averagePrice, bids, asks, malformed } = calculateTradeThroughObligation(
    providedPrice,
    sipData,
    tradeThrough,
    side
  );

  return {
    tradeThrough,
    side,
    quantity,
    averagePrice,
    bids,
    asks,
    malformed
  };
}

/**
 * We need to know whether there is a Trade Through Obligation or not based
 * on the Agency Price or Principal Fill's provided price against the
 * SipData prices. If data is missing, we cannot determine.
 *
 * @param providedPrice number
 * @param param1 number (SipData askPrice and BidPrice)
 * @returns boolean | undefined
 */
function setTradeThrough(providedPrice: number, askPrice: number, bidPrice: number): boolean | undefined {
  const isPriceLessThanBidPrice: boolean = providedPrice < bidPrice;
  const isPriceGreaterThanAskPrice: boolean = providedPrice > askPrice;
  if (isPriceLessThanBidPrice && isPriceGreaterThanAskPrice) return undefined;

  //Scenario (AC1): Return if there is a Trade Through Obligation or not
  const tradeThrough: boolean = isPriceLessThanBidPrice || isPriceGreaterThanAskPrice;

  return tradeThrough;
}

/**
 * We need to know the side the Agency Cross or Principal Fill will take when
 * dealing with the Trade Through Obligation. If data is missing, we cannot determine.
 *
 * @param providedPrice number
 * @param param1 number (SipData askPrice and bidPrice)
 * @returns OrderSide | undefined
 */
function setSide(providedPrice: number, askPrice: number, bidPrice: number): OrderSide | undefined {
  const isPriceLessThanBidPrice: boolean = providedPrice < bidPrice;
  const isPriceGreaterThanAskPrice: boolean = providedPrice > askPrice;
  if (isPriceLessThanBidPrice && isPriceGreaterThanAskPrice) return undefined;

  if (isPriceLessThanBidPrice) {
    //Scenario (AC2): Return side is “Sell” when price is less than bidPrice
    return OrderSide.Sell;
  } else if (isPriceGreaterThanAskPrice) {
    //Scenario (AC3): Return side is “Buy” when price is greater than askPrice
    return OrderSide.Buy;
  } else {
    //Scenario (AC4): Return side is “null” when price is neither less than bidPrice or greater than askPrice
    return undefined;
  }
}

/**
 * Once we have the provided price and the side of the Trade Through Details,
 * we can calculate the The Trade Through Obligation. This includes
 * the total quantity, weighted average price, and all applicable bids or asks.
 * If data is missing, we cannot calculate and will return an empty
 * Trade Through Obligation with malformed as true.
 *
 *
 * SipQuote[] (asks and buys) valid states:
 *
 * [{
 *  exchangeId: string
 *  quantity: number > 0
 *  price: number > 0
 * }]
 *
 * [{
 *  exchangeId: string
 *  quantity: number > 0
 *  price: number > 0
 * },
 * {
 *  exchangeId: string
 * }]
 *
 *
 * SipQuote[] (asks and buys) invalid states:
 *
 * [{
 *  exchangeId: string
 *  quantity: number <= 0
 *  price: number <= 0
 * }]
 *
 * [{
 *  exchangeId: string
 *  price: number
 * }]
 *
 * [{
 *  exchangeId: string
 *  quantity: number
 * }]
 *
 * [{
 *    exchangeId
 * }]
 *
 *
 * @param providedPrice number
 * @param param1 SipData[] (SipData bids and asks)
 * @param side OrderSide
 * @returns { quantity?: number; averagePrice?: number; bids?: SipQuote[]; asks?: SipQuote[]; malformed: boolean; }
 */
function calculateTradeThroughObligation(
  providedPrice: number,
  { bids, asks }: SipData,
  tradeThrough?: boolean,
  side?: OrderSide
): {
  quantity?: number;
  averagePrice?: number;
  bids: SipQuoteDetail[];
  asks: SipQuoteDetail[];
  malformed: boolean;
} {
  const emptyTradeThroughObligation = {
    quantity: undefined,
    averagePrice: undefined,
    bids: [],
    asks: [],
    malformed: true
  };

  //If tradeThrough and side are undefined or null, then the data was malformed and we cannot calculate.
  if (tradeThrough == null && !side) return emptyTradeThroughObligation;
  //If there is no tradeThrough obligation, then we do not calculate and we do not bother validating the sipData
  if (!tradeThrough) return { ...emptyTradeThroughObligation, malformed: false };

  let averagePriceNumerator: Decimal = new Decimal(0);
  let totalQuantity: Decimal = new Decimal(0);
  const sipQuotes: SipQuote[] = side === OrderSide.Buy ? asks : bids;
  if (sipQuotes.length == 0) return emptyTradeThroughObligation;

  const filteredAsks: SipQuoteDetail[] = [];
  const filteredBids: SipQuoteDetail[] = [];

  for (const sipQuote of sipQuotes) {
    const price = sipQuote.price;
    const quantity = sipQuote.quantity;

    //SipQuote with null price and null quantity is a valid state, so we ignore it for calculation.
    if (price == null && quantity == null) continue;
    //SipQuote with price or quantity without the other (one is null and the other is not) is an invalid state and we cannot perform the calculation.
    if (price == null || quantity == null) return emptyTradeThroughObligation;
    //SipQuote with non-positive values for either quantity or price is an invalid state and we cannot perform the calculation.
    if (price <= 0 || quantity <= 0) return emptyTradeThroughObligation;

    const sipQuoteQuantity = new Decimal(quantity);
    const sipQuotePrice = new Decimal(price);

    //when Side = Buy, Provided Price > Ask Price
    //when Side = Sell, Provided Price < Bid Price
    if (side === OrderSide.Buy && providedPrice > price) {
      //Scenario (AC10): List of quotes which need TOs when Side = Buy and Provided Price > Ask Price
      filteredAsks.push({
        exchangeId: sipQuote.exchangeId,
        price,
        quantity
      });
      averagePriceNumerator = averagePriceNumerator.add(sipQuoteQuantity.mul(sipQuotePrice));
      //Scenario (AC6): Return quantity of trade through obligation when Side = Buy Provided Price > Ask Price
      totalQuantity = totalQuantity.add(sipQuoteQuantity);
    } else if (side === OrderSide.Sell && providedPrice < price) {
      //Scenario (AC9): List of quotes which need TOs when Side = Sell and Provided Price < Bid Price
      filteredBids.push({
        exchangeId: sipQuote.exchangeId,
        price,
        quantity
      });
      averagePriceNumerator = averagePriceNumerator.add(sipQuoteQuantity.mul(sipQuotePrice));
      //Scenario (AC5): Return quantity of trade through obligation when Side = Sell and Provided Price < Bid Price
      totalQuantity = totalQuantity.add(sipQuoteQuantity);
    }
  }

  if (totalQuantity.equals(0)) return emptyTradeThroughObligation;

  //Scenario (AC7): Return Avg Price of trade through obligation when Side = Sell
  //Scenario (AC8): Return Avg Price of trade through obligation when Side = Buy
  const averagePrice: number = +averagePriceNumerator.div(totalQuantity).toFixed(4);

  return {
    quantity: totalQuantity.toNumber(),
    averagePrice,
    bids: filteredBids,
    asks: filteredAsks,
    malformed: false
  };
}
