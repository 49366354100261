import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { TradingOrderStatus } from '@oms/generated/frontend';
import type { VisibleTradingOrderInfoFragment as TOFragment, FeedbackWrapper } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { openConfirmation } from '@app/generated/sdk';
import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';
import { t } from '@oms/codegen/translations';
import { convertAlert } from '@oms/frontend-foundation';
import type { FoundationWorkspace } from '@oms/frontend-foundation';
import type { Maybe } from '@oms/shared/util-types';

function eligibleForForceCancel(status: Maybe<TradingOrderStatus>): boolean {
  return (
    status === TradingOrderStatus.Active ||
    status === TradingOrderStatus.ExpiryPending ||
    status === TradingOrderStatus.ExpiryRejected ||
    status === TradingOrderStatus.ExpiryRequested ||
    status === TradingOrderStatus.ModifyPending ||
    status === TradingOrderStatus.ModifyRequested ||
    status === TradingOrderStatus.PendingCancel ||
    status === TradingOrderStatus.RouteRequested
  );
}

/**
 * Open a Confirmation Dialog with the relevant errors to display, handle the "OK" button
 * click to send the BulkForceCancelTradingOrders mutation, and handle any failures by recursion.
 */
async function openConfirmationAndListen(
  orderIds: string[],
  errors: FeedbackWrapper[],
  tradingOrderService: TradingOrdersService,
  workspace: FoundationWorkspace
) {
  const numOrders = orderIds.length;
  // Convert the FeedbackWrapper[] into AlertBannerStackItem[]
  const alerts = errors.map((item) =>
    convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
  );

  // Invoke the confirmation dialog with the relevant info.
  const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
    title: `Force cancel ${numOrders} trading order${numOrders > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      alerts: alerts,
      message: `You must verify with the external venue that you have an out. Are you sure you want to force cancel ${numOrders} trading order${
        numOrders > 1 ? 's' : ''
      }?`,
      confirmButtonText: t('app.common.retry'),
      cancelButtonText: t('app.common.no')
    },
    windowOptions: {
      width: 450,
      height: 260 // Account for the errors
    }
  });

  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      const resp = await tradingOrderService.forceCancelTradingOrders(orderIds);

      if (resp.isSuccess() === false) {
        // The mutation failed. Invoke another confirmation.
        const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];
        await openConfirmationAndListen(orderIds, feedbackWrappers, tradingOrderService, workspace);
      }

      break;
    }
  }
}

function getEligibleRowsLength(data: TOFragment[]) {
  return data.filter((selectedRow) => eligibleForForceCancel(selectedRow?.status)).length;
}

function isVisible(rowData: TOFragment[]): boolean {
  return getEligibleRowsLength(rowData) > 0;
}

export const forceCancelTradingOrderOnChange = async (
  ctx: ActionContext<TOFragment, ActionComponentConfig<TOFragment>>
) => {
  const { lifecycle, data, workspace } = ctx;
  const eligibleRows = getEligibleRowsLength(data);

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    const tradingOrderService = ctx.appContainer.resolve(TradingOrdersService);

    const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
      title: `Force cancel ${eligibleRows} trading order${eligibleRows > 1 ? 's' : ''}`,
      componentProps: {
        autoClose: true,
        message: `You must verify with the external venue that you have an out. Are you sure you want to force cancel ${eligibleRows} trading order${
          eligibleRows > 1 ? 's' : ''
        }?`,
        confirmButtonText: t('app.common.yes'),
        cancelButtonText: t('app.common.no')
      },
      windowOptions: {
        width: 450,
        height: 200
      }
    });
    const event = await api.awaitFirstEvent;
    switch (event.type) {
      case DIALOG_EVENT_TYPE.OK: {
        // By the time the user clicks past the confirmation dialog, the count of selected rows
        // that are active may have changed.
        const eligibleTOIds = data
          .filter((selectedRow) => eligibleForForceCancel(selectedRow.status))
          .map((row) => row.id);

        const result = await tradingOrderService.forceCancelTradingOrders(eligibleTOIds);

        if (result.isSuccess() === false) {
          const feedbackWrappers: FeedbackWrapper[] = result.errors as unknown as FeedbackWrapper[];

          await openConfirmationAndListen(eligibleTOIds, feedbackWrappers, tradingOrderService, workspace);
        }

        break;
      }
    }
  }
};

export const forceCancelTradingOrderAction: ActionDefFactory<TOFragment> = (builder) =>
  builder
    .name('force_cancel_trading_order')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('force_cancel_trading_order_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Force Cancel'
        })
    )
    .customMenu((m) => m.name('Force Cancel').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<TOFragment>>(forceCancelTradingOrderOnChange);
