import { useAddToolbarAction } from '@app/common/toolbar/hooks/use-add-toolbar-action.hook';
import { MontageTypeCarouselToggle } from './window-header/montage-type-carousel-toggle.component';
import { useMontageLayout } from './use-montage-layout.hooks';

export const useMontageToolbarCarousel = () => {
  const { layout, flexLayoutActorId } = useMontageLayout();
  useAddToolbarAction(
    {
      location: 'window',
      id: 'montage-carousel',
      component: () => <MontageTypeCarouselToggle layout={layout} flexLayoutActorId={flexLayoutActorId} />
    },
    [layout, flexLayoutActorId]
  );
};
