import { mapCommissionTypeToRateType, mapRateTypeToCommissionType } from '@app/common/mappers/map-rate-type';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type {
  AddOrdersWithChargesMutation,
  AddOrdersWithChargesMutationVariables,
  ChargeDetail,
  CommissionType,
  GetChargeIdByNameQuery,
  GetChargeIdByNameQueryVariables,
  GetOrderByIdQuery,
  GetOrderByIdQueryVariables,
  InputMaybe,
  ModifyInvestorOrderMutation,
  ModifyInvestorOrderMutationVariables,
  OrderInputWithCharges,
  OrderModificationInput,
  RateType
} from '@oms/generated/frontend';
import {
  AddOrdersWithChargesDocument,
  GetChargeIdByNameDocument,
  GetOrderByIdDocument,
  ModifyInvestorOrderDocument
} from '@oms/generated/frontend';
import { cleanMaybe, convertToNumber, Logger } from '@oms/shared/util';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class InvestorOrderEntryService {
  protected logger: Logger;
  protected name: string = 'InvestorOrderEntryService';

  constructor(@inject(GQLResponse) private gqlResponse: GQLResponse) {
    this.logger = Logger.labeled(this.name);
  }

  public getById(id: string) {
    const query = this.gqlResponse.wrapQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>({
      query: GetOrderByIdDocument,
      variables: {
        id
      }
    });

    return query.exec();
  }

  public async create(mutationInput: OrderInputWithCharges, dryRun = false) {
    const rateTypeInput: InputMaybe<CommissionType> = mutationInput.order.commType;
    const rateValueInput = mutationInput.order.commission;

    const charges: ChargeDetail[] = await this.buildChargeDetails(rateTypeInput, rateValueInput);

    const mutation = this.gqlResponse.wrapMutate<
      AddOrdersWithChargesMutation,
      AddOrdersWithChargesMutationVariables
    >({
      mutation: AddOrdersWithChargesDocument,
      variables: {
        orders: [
          {
            ...mutationInput,
            charges
          }
        ],
        dryRun
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public async update(mutationInput: OrderModificationInput, dryRun = false) {
    const rateTypeInput: InputMaybe<RateType> = mutationInput.commissionRateType;
    const rateValueInput = mutationInput.commissionRateValue;
    const charges: ChargeDetail[] = await this.buildChargeDetails(
      mapRateTypeToCommissionType(rateTypeInput ?? undefined),
      rateValueInput
    );

    const mutation = this.gqlResponse.wrapMutate<
      ModifyInvestorOrderMutation,
      ModifyInvestorOrderMutationVariables
    >({
      mutation: ModifyInvestorOrderDocument,
      variables: {
        modification: {
          ...mutationInput,
          charges
        },
        dryRun
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public async getChargeId(chargeName: string): Promise<string | undefined> {
    const query = this.gqlResponse
      .wrapQuery<GetChargeIdByNameQuery, GetChargeIdByNameQueryVariables>({
        query: GetChargeIdByNameDocument,
        variables: {
          chargeName
        }
      })
      .exec();

    return (await query).mapTo(
      ({ data }) => cleanMaybe(data.getChargeByName?.id),
      (errors) => {
        errors.forEach((error, index) => {
          this.logger.scope(['getChargeId', `${index}`]).error(error);
        });
        return undefined;
      }
    );
  }

  private async buildChargeDetails(
    rateTypeInput: InputMaybe<CommissionType>,
    rateValueInput: InputMaybe<number>
  ): Promise<ChargeDetail[]> {
    const charges: ChargeDetail[] = [];

    if (rateTypeInput != null && rateValueInput != null) {
      const chargeId = await this.getChargeId('Commission');
      const rateType = mapCommissionTypeToRateType(rateTypeInput);
      const rateValue = convertToNumber(rateValueInput);

      if (chargeId)
        charges.push({
          chargeId,
          rateType,
          rateValue
        });
    }

    return charges;
  }
}
