import { PROCESS_ID } from '@valstro/workspace';
import { openRejectWithReason } from '@app/generated/sdk';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { InvestorOrderRepairRequestService } from '@app/data-access/services/trading/repair-requests/investor-order-repair-requests.service';
import type { ActionContext, ActionComponentConfig, ActionDefFactory } from '@oms/frontend-vgrid';
import type { IoRepairRequestFragment } from '@oms/generated/frontend';

function isVisible(rowData: IoRepairRequestFragment[]) {
  return rowData.length === 1 && !!rowData[0].id;
}

const rejectRepairQueueInvestorOrdersOnChange = async (
  ctx: ActionContext<IoRepairRequestFragment, ActionComponentConfig<IoRepairRequestFragment>>
) => {
  const { appContainer, lifecycle, data, workspace } = ctx;
  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change' && selectedRow?.id) {
    const ioRepairRequestService = appContainer.resolve(InvestorOrderRepairRequestService);

    try {
      const [_, api] = await openRejectWithReason(workspace, PROCESS_ID.LEADER);
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          ctx.notify({ isLoading: true, rowData: selectedRow });
          const result = await ioRepairRequestService.rejectWithReason(
            selectedRow.id,
            event.payload as string
          );

          if (result.isSuccess()) {
            ctx.notify({ isLoading: false, rowData: selectedRow });
          } else {
            ctx.notify({ isLoading: false, rowData: selectedRow });
            const msgs = result?.errors.map((e) => e.message).join(', ');
            openMessageDialog(`Error: ${msgs}`, workspace).catch(console.error);
            throw new Error(msgs);
          }
          break;
        }
      }
    } catch (e) {
      ctx.notify({ isLoading: false, rowData: selectedRow });
      openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
      console.error(e);
    }
  }
};

export const rejectRepairQueueInvestorOrdersAction: ActionDefFactory<IoRepairRequestFragment> = (builder) =>
  builder
    .name('reject_repair_queue_investor_orders')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('reject_repair_queue_investor_orders_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Reject'
        })
    )
    .customMenu((m) => m.name('Reject').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<IoRepairRequestFragment>>(rejectRepairQueueInvestorOrdersOnChange);
