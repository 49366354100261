import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import type { Optional } from '@oms/shared/util-types';
import { Box } from '@oms/shared-frontend/ui-design-system';
import type { Sprinkles } from '@oms/shared-frontend/ui-design-system';
import { HomeIcon, TargetIcon } from '@radix-ui/react-icons';
import type { DepthCellBackgroundColor, DepthCellIcon, DepthCellRendererParams } from './depth.cell.types';
import {
  getBackgroundColor,
  getCellTextColor,
  getDepthCellRowFlagType,
  getDepthCellType,
  getRowFlagColor
} from './depth.cell.util';

export const DepthCellRenderer: FC<DepthCellRendererParams> = ({
  valueFormatted,
  value,
  targetState,
  pricePartitionMap$,
  eParentOfValue,
  node,
  colDef
}) => {
  const { isMarketMaker, source, targetingIndex: tradingOrderIndex } = node.data ?? {};

  const cellType = useMemo(() => getDepthCellType(colDef?.field), [colDef?.field]);

  // State ------------------------------------------------------------ /
  const [targetIndex, setTargetIndex] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState<DepthCellBackgroundColor>('inherit');

  const isTarget = useMemo(() => tradingOrderIndex === targetIndex, [targetIndex, tradingOrderIndex]);

  // ------------------------------------------------------------ /

  useEffect(() => {
    // Subscription for trading order target rows
    if (cellType !== 'primary' || source !== 'unboundTradingOrder') return;
    const subscription = targetState.$.subscribe(({ index }) => {
      setTargetIndex(index);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [cellType, source, targetState]);

  useEffect(() => {
    // Subscription for price partition map to set row background
    const subscription = pricePartitionMap$?.subscribe((pricePartitionMap) => {
      setBackgroundColor(getBackgroundColor(node.data, pricePartitionMap));
    });
    return () => {
      subscription?.unsubscribe();
    };
  }, [cellType, node.data, pricePartitionMap$]);

  // ------------------------------------------------------------ /

  const cellValue = useMemo(() => String(valueFormatted || value), [valueFormatted, value]);

  // Styling ------------------------------------------------------------ /

  const primaryCellIcons = useMemo((): Optional<DepthCellIcon[]> => {
    if (cellType !== 'primary') return undefined;
    const icons: DepthCellIcon[] = [];
    if (isMarketMaker && !icons.some(({ type }) => type === 'home')) {
      icons.push({ type: 'home', icon: HomeIcon });
    }
    if (isTarget && !icons.some(({ type }) => type === 'target')) {
      icons.push({ type: 'target', icon: TargetIcon });
    }
    return icons.length ? icons : undefined;
  }, [cellType, isMarketMaker, isTarget]);

  const memoizedCellIcons = useMemo(() => {
    if (!primaryCellIcons) return null;
    return primaryCellIcons.map(({ type, icon: Icon }) => (
      <Box key={type} sx={{ marginLeft: 1, alignSelf: 'center' }}>
        <Icon />
      </Box>
    ));
  }, [primaryCellIcons]);

  const rowFlagStyle = useMemo((): Sprinkles => {
    if (cellType !== 'primary') return {};

    const flagType = getDepthCellRowFlagType(node.data);
    if (!flagType) return {};

    return {
      borderLeftWidth: '2px',
      borderLeftStyle: 'solid',
      borderColor: getRowFlagColor(flagType),
      fontWeight: 'mediumB',
      color: 'white'
    };
  }, [cellType, node.data]);

  const cellTextColor = useMemo(() => getCellTextColor(cellType, node.data), [cellType, node.data]);

  useLayoutEffect(() => {
    // Make cell take size of parent
    const { borderWidth, padding } = eParentOfValue.style;
    const zero = '0px';
    if (borderWidth !== zero) {
      eParentOfValue.style.borderWidth = zero;
    }
    if (padding !== zero) {
      eParentOfValue.style.padding = zero;
    }
  }, [eParentOfValue.style]);

  // ------------------------------------------------------------ /

  return (
    <Box
      sx={{
        backgroundColor,
        margin: 0,
        padding: 1,
        ...rowFlagStyle
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'row'
        }}
      >
        <Box
          sx={{
            color: cellTextColor
          }}
        >
          {cellValue}
        </Box>
        {memoizedCellIcons}
      </Box>
    </Box>
  );
};

export default DepthCellRenderer;
