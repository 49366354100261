import { mapPrimaryBenchmarkType } from '@app/common/mappers/map-primary-benchmark-type';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { comboBoxItemsFrom } from '@oms/frontend-foundation';
import type {
  AnyRecord,
  FieldContract,
  ISelectField,
  ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import { PrimaryBenchmark } from '@oms/generated/frontend';

export const createPrimaryBenchmarkField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<ISelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'select').options<ISelectField<any>>({
    label: 'Primary Benchmark',
    options: comboBoxItemsFrom.enum(PrimaryBenchmark, { label: (value) => mapPrimaryBenchmarkType(value) }),
    ...overrides
  });
