import { strategyValidationErrors$ } from '../fixatdl-strategy-field/fixatdl-strategy-field.state';

export const VALIDATE_ROUTE_ORDER_STRATEGY = 'validateRouteOrderStrategy';

export const validateRouteOrderStrategy = () => (value: any, allValues: any) => {
  if ((allValues.venue || allValues.destinationType) && allValues.hiddenStrategyOptions?.length && !value) {
    return 'This field is required';
  }

  if (strategyValidationErrors$.getValue()?.length) {
    return 'Invalid parameters';
  }

  return null;
};
