import { CaretDownIcon } from '@radix-ui/react-icons';
import { SyntheticEvent, useCallback } from 'react';
import { Flex } from '../../layout/flex/flex';
import { CloseButton } from './close-button';
import { Icon } from '../icon/icon';
import { Button, ButtonProps } from './button';
import { ButtonGroup, ButtonGroupProps } from './button-group';
import * as styles from './css/split-button.css';
import { IconButton } from './icon-button';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger
} from '../dropdown-menu/dropdown-menu';

export type SplitButtonActions = {
  title: string;
  action: () => void;
}[];

export type SplitButtonSpecificProps = {
  actions?: SplitButtonActions;
  isLoading?: ButtonProps['isLoading'];
  isDisabled?: ButtonProps['isDisabled'];
  loadingText?: string;
  isFullWidth?: ButtonProps['isFullWidth'];
};

export type SplitButtonProps = SplitButtonSpecificProps & ButtonGroupProps;

const capitalize = (title: string) =>
  title
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ');

export const SplitButton = polymorphicComponent<'button', SplitButtonProps>((props, _ref) => {
  const {
    sx = {},
    actions = [],
    isLoading,
    isDisabled,
    palette,
    variant = 'primary',
    loadingText,
    isFullWidth
  } = props;

  const [primaryAction, ...secondaryActions] = actions;
  const dropdownTriggerDisabled = isLoading || isDisabled;
  const isDismiss = actions.length === 1 && actions[0]?.title === '✕';

  const onClickAction = useCallback((action: () => void) => {
    return (event: SyntheticEvent) => {
      event.stopPropagation();
      if (typeof action === 'function') {
        return action();
      }
    };
  }, []);

  if (!primaryAction) {
    return null;
  }

  return (
    <Flex sx={{ width: !isDismiss && isFullWidth ? 'full' : 'auto' }}>
      {isDismiss ? (
        <CloseButton onClick={onClickAction(primaryAction.action)} style={{ color: 'grey' }} />
      ) : (
        <ButtonGroup variant={variant} spacing={0} style={{ width: '100%' }}>
          <Button
            isDisabled={isDisabled || isLoading}
            sx={sx}
            style={{ justifyContent: 'flex-start' }}
            onClick={onClickAction(primaryAction.action)}
            palette={palette}
          >
            {isLoading && loadingText ? capitalize(loadingText) : capitalize(primaryAction.title)}
          </Button>
          {secondaryActions?.length > 0 && (
            <DropdownMenuRoot>
              <DropdownMenuTrigger
                data-testid={
                  dropdownTriggerDisabled ? 'split-button-menu-trigger-disabled' : 'split-button-menu-trigger'
                }
                asChild
              >
                <IconButton
                  isDisabled={dropdownTriggerDisabled}
                  variant={variant}
                  aria-label="toggle-dropdown"
                  icon={<Icon label="Expand" as={CaretDownIcon} />}
                />
              </DropdownMenuTrigger>
              {!dropdownTriggerDisabled && (
                <DropdownMenuPortal>
                  <DropdownMenuContent className={styles.buttonDropdownMenu}>
                    {secondaryActions.map((option) => (
                      <DropdownMenuItem
                        key={option.title}
                        onClick={onClickAction(option.action)}
                        className={styles.buttonDropdownMenuItem}
                      >
                        {capitalize(option.title)}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenuPortal>
              )}
            </DropdownMenuRoot>
          )}
        </ButtonGroup>
      )}
    </Flex>
  );
});

if (__DEV__) {
  SplitButton.displayName = 'SplitButton';
}
