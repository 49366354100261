import { DestinationType } from '@oms/generated/frontend';
import type { ExecutionVenueFragment } from '@oms/generated/frontend';
import { getAdvancedSelectQueryReturn } from '@oms/frontend-foundation';
import type { AdvancedSelectQueryFn, ComboBoxItem, IAdvancedSelectField } from '@oms/frontend-foundation';

import type { Observable } from 'rxjs';
import type { DataSourceCommon } from '@oms/frontend-foundation';
import VenuesService from '@app/data-access/services/reference-data/venue/venues.service';

export type VenuesComboboxData = ExecutionVenueFragment;
export type VenuesComboboxValue = string;
export type VenuesComboboxField = IAdvancedSelectField;

export const venueResultMapper = (venue: VenuesComboboxData): ComboBoxItem => {
  const { id, executionVenueDescription } = venue;
  return {
    type: 'item',
    id: id ?? '',
    value: id ?? '',
    label: id ?? '',
    sublabel: executionVenueDescription
  };
};

type VenueQueryType =
  | 'allVenues'
  | 'allVenuesWithDestinationTypes'
  | 'userVenues'
  | 'userVenuesWithDestinationTypes';
type VenueQuerySetup = Parameters<
  typeof getAdvancedSelectQueryReturn<VenuesComboboxData, VenuesComboboxValue>
>[0];
type VenueQueryFn = VenueQuerySetup['queryFn'];

const makeWatchAllVenuesQuery = (type?: VenueQueryType): AdvancedSelectQueryFn<VenuesComboboxValue> => {
  return (container) => {
    const venueService = container.resolve(VenuesService);
    type Query = () => Observable<DataSourceCommon<ExecutionVenueFragment>>;
    const queryFn: VenueQueryFn = (() => {
      switch (type) {
        case 'userVenues':
        case 'userVenuesWithDestinationTypes':
          return venueService.watchAllVenuesForUser$.bind(venueService) as Query;
        case 'allVenues':
        case 'allVenuesWithDestinationTypes':
          return venueService.watchAllAvailableVenues$.bind(venueService) as Query;

        default:
          return { fromService: venueService };
      }
    })();

    const setup: VenueQuerySetup = (() => {
      const venueSetup: VenueQuerySetup = {
        queryFn,
        resultMapper: venueResultMapper
      };
      if (type === 'allVenuesWithDestinationTypes' || type === 'userVenuesWithDestinationTypes') {
        venueSetup.additionalItems = [
          {
            type: 'item',
            id: 'trader',
            label: 'Trader',
            value: DestinationType.Trader
          },
          {
            type: 'item',
            id: 'primary-trader',
            label: 'Primary Trader',
            value: DestinationType.PrimaryTrader
          }
        ];
      }
      return venueSetup;
    })();

    return getAdvancedSelectQueryReturn<VenuesComboboxData, VenuesComboboxValue>(setup);
  };
};

export const watchAllVenuesQuery = makeWatchAllVenuesQuery('allVenues');

export const watchAllVenuesWithDestinationTypesQuery = makeWatchAllVenuesQuery(
  'userVenuesWithDestinationTypes'
);

export const watchAllVenuesForUserQuery = makeWatchAllVenuesQuery('userVenues');

export const watchAllVenuesForUserWithDestinationTypesQuery = makeWatchAllVenuesQuery(
  'userVenuesWithDestinationTypes'
);
