import { RemoteProxy } from '@valstro/remote-link';
import type { Actor } from './actor';
import type { CreateActorDefinition, CreateActorOptions, InternalActor } from './actor.internal';
import { ActorSchema, AnyActorSchema } from './actor.schema';
import { PlatformRegistry } from './platform';
import { PluginRegistry } from './plugins';
import { Workspace } from './workspace';
import { logger } from './logger';

export type ActorRegistryRegistryOptions = {
  actorTypeSelectorStrategy: ActorTypeSelectorStrategy;
  checkEntityCallback?: (entity: ActorSchema) => boolean;
  currentPlatformName?: string;
};

type ActorName = string;
export class ActorRegistry<TViewType = any> {
  private _platformName: string;
  private _actors: Map<ActorName, ActorSchema> = new Map();
  private _options: ActorRegistryRegistryOptions;

  constructor(
    protected _workspace: Workspace,
    protected platformRegistry: PlatformRegistry,
    options: ActorRegistryRegistryOptions = {
      actorTypeSelectorStrategy: defaultActorTypeSelectorStrategy
    }
  ) {
    this._options = options;
    this._platformName = this._options.currentPlatformName ?? this.platformRegistry.getCurrentPlatformName();
  }

  register(actorSchema: AnyActorSchema) {
    if (this._options.checkEntityCallback && !this._options.checkEntityCallback(actorSchema)) {
      throw new Error(`Entity "${actorSchema.name}" is not valid`);
    }

    this._actors.set(actorSchema.name, actorSchema);
    logger.debug(`[Actor Registry: ${actorSchema.name}] Registered actor schema`, { actorSchema });
    return this;
  }

  getAll(): ActorSchema[] {
    return Array.from(this._actors.values());
  }

  hasActor(actorSchema: ActorSchema): boolean {
    return this._actors.has(actorSchema.name);
  }

  getActor<T extends AnyActorSchema = ActorSchema>(actorSchema: T): T | undefined {
    return this._actors.get(actorSchema.name) as T | undefined;
  }

  getActorByName<T extends AnyActorSchema = ActorSchema>(name: string): T | undefined {
    return this._actors.get(name) as T | undefined;
  }

  hasActorByName(name: string): boolean {
    return this._actors.has(name);
  }

  // TODO: Perf improvement. Memoize this
  getActorByType<T extends AnyActorSchema = ActorSchema>(
    type: string,
    source?: CreateActorDefinition,
    createOptions?: CreateActorOptions
  ): T | undefined {
    return this._options.actorTypeSelectorStrategy({
      registeredActors: Array.from(this._actors.values()),
      actorType: type,
      isLeader: this._workspace.getIsLeader(),
      workspace: this._workspace,
      rootWindowActor: this._workspace.getRootWindowActor(),
      currentPlatformName: this._platformName,
      isWindowReady: this._workspace.getIsWindowReady(),
      createActorOptions: createOptions,
      source
    }) as T | undefined;
  }

  hasActorByType(type: string, source?: CreateActorDefinition): boolean {
    return !!this.getActorByType(type, source);
  }

  getViewByName(name: string): TViewType | undefined {
    const actor = this.getActorByName(name);
    if (!actor || !actor.view) {
      return;
    }
    return actor.view;
  }

  runRegistrationFinished(pluginRegistry: PluginRegistry) {
    for (const actor of this._actors.values()) {
      if (actor.onRegistrationFinished) {
        actor.onRegistrationFinished({
          workspace: this._workspace,
          actorRegistry: this,
          platformRegistry: this.platformRegistry,
          pluginRegistry: pluginRegistry,
          platformName: this._platformName
        });
        logger.debug(`[Actor Registry] Running "onRegistrationFinished" for actor "${actor.name}"`, {
          actor
        });
      }
    }
  }

  destroy() {
    this._actors.clear();
  }
}

/**
 * Helper to create an Actor Registry
 */
export const createActorRegistry = <TViewType = any>(
  workspace: Workspace,
  platformRegistry: PlatformRegistry,
  options: ActorRegistryRegistryOptions = {
    actorTypeSelectorStrategy: defaultActorTypeSelectorStrategy
  }
) => {
  return new ActorRegistry<TViewType>(workspace, platformRegistry, options);
};

/**
 * Actor Type Selector Strategy
 */
export type ActorTypeSelectorStrategyContext = {
  actorType: string;
  registeredActors: ActorSchema[];
  workspace: Workspace;
  isLeader: boolean;
  isWindowReady: boolean;
  rootWindowActor?: Actor;
  currentPlatformName: string;
  source?: CreateActorDefinition;
  createActorOptions?: CreateActorOptions;
};

export type ActorTypeSelectorStrategy = (context: ActorTypeSelectorStrategyContext) => ActorSchema;

export function defaultActorTypeSelectorStrategy({
  registeredActors,
  actorType,
  createActorOptions,
  currentPlatformName,
  isLeader,
  source,
  workspace,
  rootWindowActor
}: ActorTypeSelectorStrategyContext): ReturnType<ActorTypeSelectorStrategy> {
  const matchingActors = registeredActors
    .filter(
      (a) =>
        a.type === actorType &&
        (a.supportedPlatforms === '*' || a.supportedPlatforms.includes(currentPlatformName))
    )
    .map((a) => {
      const isStrategyDefined = a.selectStrategy !== undefined;
      const isValidStrategy =
        a.selectStrategy !== undefined
          ? a.selectStrategy({
              location: 'child',
              source,
              isLeader,
              platformName: currentPlatformName,
              rootWindowActor: rootWindowActor,
              createActorOptionsOrDef:
                createActorOptions || workspace.getInitialURLCreateActorOptions() || undefined
            }) === true
          : true;
      return {
        ...a,
        isStrategyDefined,
        isValidStrategy
      };
    })
    .filter((a) => a.isValidStrategy)
    .sort((a, b) => {
      const aScore = a.isStrategyDefined ? 1 : 0;
      const bScore = b.isStrategyDefined ? 1 : 0;

      if (aScore === bScore) {
        return 0;
      }

      return aScore > bScore ? -1 : 1;
    });

  if (!matchingActors.length) {
    throw new Error(
      `Actor type "${actorType}" not found in the actor registery when trying to find actor "${
        source?.name || source?.id || 'unknown'
      }".
      
      Possible issues:
      
      - The actor is not registered
      - The actor type "${actorType}" does not match anything in the registry
      - The actor is not supported (defined with "supportedPlatforms: [...]") for the platform "${currentPlatformName}"
      - The select strategy returned true (if you've defined a custom one with "selectStrategy").\n\n`
    );
  }

  return matchingActors[0];
}

/**
 * Store local actor proxies in a registry
 * Used to prevent creating multiple proxies for the same instance & save some memory & time
 */
const localActorProxiesRegistry = new Map<string, RemoteProxy<InternalActor>>();

/**
 * Set a local actor proxy in the registry
 *
 * @param id - string
 * @param proxy - RemoteProxy<InternalActor>
 */
export function setLocalActorProxy(id: string, proxy: RemoteProxy<InternalActor>) {
  localActorProxiesRegistry.set(id, proxy);
}

/**
 * Get a local actor proxy from the registry
 *
 * @param id - string
 * @returns void
 */
export function getLocalActorProxy(id: string) {
  const actor = localActorProxiesRegistry.get(id);
  if (!actor?.id) {
    return undefined;
  }
  return actor;
}

/**
 * Delete a local actor proxy from the registry
 *
 * @param id - string
 * @returns void
 */
export function deleteLocalActorProxy(id: string) {
  return localActorProxiesRegistry.delete(id);
}

/**
 * Store local actor proxies in a registry
 * Used to prevent creating multiple proxies for the same instance & save some memory & time
 */
const localActorRegistry = new Map<string, Actor<any>>();

/**
 * Set a local actor proxy in the registry
 *
 * @param id - string
 * @param proxy - RemoteProxy<InternalActor>
 */
export function setLocalActor(id: string, actor: Actor<any>) {
  localActorRegistry.set(id, actor);
}

/**
 * Get a local actor proxy from the registry
 *
 * @param id - string
 * @returns void
 */
export function getLocalActor(id: string) {
  const actor = localActorRegistry.get(id);
  if (!actor?.id) {
    return undefined;
  }
  return actor;
}

/**
 * Delete a local actor proxy from the registry
 *
 * @param id - string
 * @returns void
 */
export function deleteLocalActor(id: string) {
  return localActorRegistry.delete(id);
}
