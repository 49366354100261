import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';
import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type {
  RouteOrderFormInput,
  RouteOrderFormOutput
} from '@app/widgets/trading/route-order/route-order.form-common';
import type {
  RouteOrderFormContractType,
  RouteOrderFormValues
} from '@app/widgets/trading/route-order/route-order.form-contract';
import { routeOrderContract } from '@app/widgets/trading/route-order/route-order.form-contract';
import { DestinationType, OrderSettleType, TimeInForce } from '@oms/generated/frontend';
import type {
  ModifyTradingOrderInput,
  AddTradingOrderEnrichedData,
  OrderRouteCreateInput
} from '@oms/generated/frontend';
import { RouteOrderService } from '@app/data-access/services/trading/routing/route-order.service';
import {
  getOrderInfoFromInput,
  getInitialCommonRouteModifyFieldValues,
  sanitizeFormValuesToCommonOutput,
  getInitialModifyOnlyFieldValues
} from './route-order.form-sanitizers';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { CreateTradingOrderOutput } from '@app/common/types/orders/orders.types';
import { createStrategyInput } from './fixatdl-strategy-field/fixatdl-strategy-field.util';
import { getDurationInSeconds } from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';

/**
 * Route Order Form Builder
 * - Create Trading Order
 * - Modify Trading Order
 * - Route Investor Order
 */
export const routeOrderBuilder = FormBuilder.create<RouteOrderFormInput, RouteOrderFormOutput>('route-order')
  .contract<RouteOrderFormContractType>(routeOrderContract)
  .type('route-order')
  .sanitizer((s) =>
    s
      .input(async function sanitize(input, ctx) {
        const orderInfo = await getOrderInfoFromInput(input, ctx.container);

        let orderFormValues: Partial<RouteOrderFormValues> = {};

        switch (orderInfo.type) {
          case 'route': {
            orderFormValues = {
              ...getInitialCommonRouteModifyFieldValues(orderInfo.investorOrder),
              hiddenMode: {
                type: 'route',
                investorOrderId: orderInfo.investorOrder.id,
                investorOrderType: orderInfo.investorOrder.orderType,
                investorOrderLimitPrice: orderInfo.investorOrder.limitPrice,
                investorOrderQuantity: orderInfo.investorOrder.quantity
              }
            };
            break;
          }
          case 'modify': {
            orderFormValues = {
              ...getInitialCommonRouteModifyFieldValues(orderInfo.tradingOrder),
              ...getInitialModifyOnlyFieldValues(orderInfo),
              hiddenMode: {
                type: 'modify',
                tradingOrderId: orderInfo.tradingOrder.id,
                location: orderInfo.location
              }
            };
            break;
          }
          case 'create': {
            // defaults
            if (!orderFormValues.settlementType) {
              orderFormValues.settlementType = OrderSettleType.Regular;
            }

            if (!orderFormValues.firmAccount) {
              const authService = ctx.container.resolve(AuthService);
              const userId = authService.getUserId() || undefined;

              if (userId) {
                const tradingOrderService = ctx.container.resolve(TradingOrdersService);
                const firmAccount = await tradingOrderService.getDefaultFirmAccount(userId);
                if (firmAccount) {
                  orderFormValues.firmAccount = {
                    id: firmAccount.id
                  };
                }
              }
            }

            orderFormValues.hiddenMode = {
              type: 'create',
              location: orderInfo.location
            };

            break;
          }
          default: {
            console.error('Invalid order type');
            orderFormValues.hiddenMode = {
              type: 'create'
            };
          }
        }

        return orderFormValues;
      })
      .output(function sanitize(formValues) {
        const { hiddenMode } = formValues;

        // Sanitize form values to common output for create, modify, and route
        const {
          destinationType,
          customerNotes,
          venueNotes,
          instrument,
          limitPrice,
          locate,
          orderTags,
          orderType,
          quantity,
          sideType,
          venueDestination,
          priceOption
        } = sanitizeFormValuesToCommonOutput(formValues);

        // Don't return an output if any of the required fields are missing
        // We do this, because ON_SANITIZE is called on every change and also contains "output" / "undefined"
        // However, we need to allow a missing destination type through as it needs to be picked up
        // in SANITIZED_VALUES_CHANGED so that the errors can be cleared if venue gets removed
        if (!instrument) return;
        if (!sideType) return;
        if (!quantity) return;
        if (!hiddenMode) return;
        if (!venueDestination && hiddenMode.type !== 'modify') return;

        let extraTifProperties: Partial<
          Pick<OrderRouteCreateInput, 'timeInForce' | 'gtdTimestamp' | 'tifDuration' | 'gtdUseMarketEndTime'>
        > = {};

        if (formValues?.timeInForce?.id) {
          extraTifProperties.timeInForce = formValues?.timeInForce?.id;
        }

        if (formValues?.timeInForce?.id === TimeInForce.Gtd) {
          extraTifProperties.gtdTimestamp = formValues?.timeInForce?.subValue;
        }
        if (formValues?.timeInForce?.id === TimeInForce.Duration) {
          extraTifProperties.tifDuration = getDurationInSeconds(formValues?.timeInForce?.subValue);
        }

        const commonOutput: Partial<OrderRouteCreateInput> = {
          quantity,
          limitPrice,
          orderType,
          orderTags,
          locate,
          ...extraTifProperties
        };

        // Handle output for create, modify, and route
        switch (hiddenMode.type) {
          case 'create': {
            const investorOrderIds = formValues.matchedInvestorOrderIds || [];
            const strategyValue = formValues?.strategy?.value;
            const tradingAccount = formValues.firmAccount
              ? {
                  id: formValues.firmAccount.id
                }
              : undefined;

            const output: CreateTradingOrderOutput = {
              investorOrderIds,
              tradingOrder: {
                ...commonOutput,
                instrument,
                sideType,
                destinationType,
                venueDestination,
                tradingAccount,
                settleType: formValues.settlementType,
                settleDate:
                  formValues.settlementType === OrderSettleType.Future
                    ? formValues.settlementDate
                    : undefined,
                strategy: createStrategyInput(strategyValue),
                category: formValues.tradingOrderCategory,
                venueNotes,
                customerNotes
              },
              priceOption
            };

            return {
              type: 'create',
              output
            };
          }
          case 'modify': {
            const tradingOrderId = hiddenMode.tradingOrderId;
            const strategyValue = formValues?.strategy?.value;
            const output: ModifyTradingOrderInput = {
              ...commonOutput,
              id: tradingOrderId,
              customerNotes,
              venueNotes,
              strategy: createStrategyInput(strategyValue)
            };
            return {
              type: 'modify',
              output
            };
          }
          case 'route': {
            // Use a separate variable for orderType to avoid overwriting a sanitized form orderType needed for output
            const originalOrderType = hiddenMode.investorOrderType;
            const orderId = hiddenMode.investorOrderId;
            const { venue, trader } = formValues || {};

            if (!originalOrderType || !orderId) throw new Error('Order Type & Order ID are required');

            if (!commonOutput.quantity) {
              throw new Error('Quantity is required');
            }

            const destinationId: string | undefined | null =
              destinationType === DestinationType.Venue
                ? venue?.id
                : destinationType === DestinationType.Trader
                  ? trader?.id
                  : undefined;

            const strategyValue = formValues?.strategy?.value;
            const output = {
              orderRoute: {
                ...commonOutput,
                limitPrice:
                  commonOutput.limitPrice && commonOutput.limitPrice > 0 ? commonOutput.limitPrice : null,
                quantity: commonOutput.quantity,
                orderType: originalOrderType,
                orderId,
                destinationType: destinationType || DestinationType.Venue,
                destinationId,
                customerNotes,
                venueNotes,
                strategy: createStrategyInput(strategyValue)
              },
              priceOption
            };
            return {
              type: 'route',
              output
            };
          }
        }
      })
  )
  .effect(({ formApi, container }) => {
    let prevInstrument: RouteOrderFormValues['instrument'] | undefined;
    let defaultFirmAccountId: string = '';

    // TODO: DV: This should be moved the the sanitized values changed event.
    // TODO: DV: Also, why isn't this apart of enrichment?
    // If the form is in create mode, we don't need to do anything
    const values = formApi.getState().values;
    if (values?.hiddenMode?.type === 'create') {
      const subscriptionInstrument = formApi.get$({ fields: ['instrument'], values: true }).subscribe((e) => {
        if (prevInstrument !== e.values.instrument) {
          const authService = container.resolve(AuthService);
          const userId = authService.getUserId() || undefined;
          const instrument = e.values.instrument || undefined;

          if (userId || instrument) {
            const tradingOrderService = container.resolve(TradingOrdersService);
            tradingOrderService
              .getDefaultFirmAccount(userId, instrument?.id)
              .then((firmAccount) => {
                if (!e.values.firmAccount || e.values.firmAccount.id === defaultFirmAccountId) {
                  formApi.change('firmAccount', firmAccount);
                  defaultFirmAccountId = firmAccount?.id || '';
                }
              })
              .catch((e) => {
                console.error('Error fetching default firm account', e);
              });
          }
        }
        prevInstrument = e.values.instrument;
      });

      return () => {
        subscriptionInstrument.unsubscribe();
      };
    }
    // Re-evaluate strategy on instrument change
    const subscription = formApi.get$({ fields: ['instrument'], values: true }).subscribe((e) => {
      if (prevInstrument !== e.values.instrument) {
        prevInstrument && formApi.change('venue', undefined);
        prevInstrument = e.values.instrument;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  })
  .change(async (event, ctx) => {
    const routeOrderService = ctx.container.resolve(RouteOrderService);
    const tradingOrderService = ctx.container.resolve(TradingOrdersService);

    switch (event.type) {
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        const output = event.payload.output;
        const enrichmentIgnoreList = ['orderTags'].filter((f) =>
          event.payload.modifiedFields.includes(f as keyof RouteOrderFormValues)
        );

        if (!output) {
          break;
        }
        switch (output.type) {
          case 'create': {
            const result = await tradingOrderService.create(
              {
                tradingOrder: output.output.tradingOrder,
                investorOrderIds: output.output.investorOrderIds,
                enrichmentIgnoreList
              },
              { dryRun: true }
            );

            if (result.isSuccess()) {
              const response = result.value.data?.addTradingOrder;
              const enrichedData = response?.enrichedData;

              const toEntryVals = Object.entries(enrichedData ?? {}).reduce<Partial<RouteOrderFormValues>>(
                (formVals, entry) => {
                  const [key, val] = entry;
                  const enrichedKeys = key as keyof AddTradingOrderEnrichedData;
                  if (enrichmentIgnoreList.includes(key)) {
                    return formVals;
                  }

                  switch (enrichedKeys) {
                    case 'locate':
                      const locate = val as AddTradingOrderEnrichedData['locate'];
                      formVals['locate'] = locate || undefined;
                      break;
                    case 'orderTags':
                      const tags = val as AddTradingOrderEnrichedData['orderTags'];
                      formVals.orderTags =
                        tags && tags.length
                          ? tags.map((tag) => ({
                              id: tag.id
                            }))
                          : undefined;
                      break;
                  }
                  return formVals;
                },
                {}
              );

              if (Object.keys(toEntryVals).length) {
                ctx.notify({
                  type: 'SET_FIELD_VALUES',
                  payload: { fieldValues: toEntryVals }
                });
              }
            }
            return result;
          }
          case 'modify': {
            return await tradingOrderService.modify(output.output, { dryRun: true });
          }
          case 'route': {
            const { priceOption, orderRoute } = output.output;

            return await routeOrderService.routeInvestorOrder(orderRoute, {
              dryRun: true,
              priceOption: priceOption || undefined
            });
          }
        }
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        const output = event.payload.output;
        const enrichmentIgnoreList = ['orderTags'].filter((f) =>
          event.payload.modifiedFields.includes(f as keyof RouteOrderFormValues)
        );
        switch (output.type) {
          case 'create': {
            return await tradingOrderService.create(
              {
                tradingOrder: output.output.tradingOrder,
                investorOrderIds: output.output.investorOrderIds,
                enrichmentIgnoreList
              },
              {
                dryRun: false,
                priceOption: output.output.priceOption || undefined
              }
            );
          }
          case 'modify': {
            return await tradingOrderService.modify(output.output);
          }
          case 'route': {
            const { priceOption, orderRoute } = output.output;

            return await routeOrderService.routeInvestorOrder(orderRoute, {
              dryRun: false,
              priceOption: priceOption || undefined
            });
          }
        }
      }
      case FORM_EVENT_TYPE.SUBMIT_FINISHED_SUCCESS: {
        const { type } = event.payload.output;
        if (type === 'create') {
          ctx.notify({
            type: 'RESET'
          });
          break;
        }
      }
    }
  });

export default routeOrderBuilder;
export type RouteOrderFormBuilderType = typeof routeOrderBuilder;
