import { COMMON_PLATFORM_NAME } from '@valstro/workspace';

export type ShortcutConfig = {
  keys: string;
};

export type ShortcutsConfig = {
  [platformName: string]: ShortcutConfig;
};

export const SHORTCUT = {
  ESCAPE: 'ESCAPE',
  FOCUS_ALL: 'FOCUS_ALL',
  OPEN_COMMAND_PALETTE: 'OPEN_COMMAND_PALETTE'
} as const;

export type ShortcutUnion = (typeof SHORTCUT)[keyof typeof SHORTCUT];

export const SHORTCUT_CONFIG: Record<string, ShortcutsConfig> = {
  OPEN_COMMAND_PALETTE: {
    [COMMON_PLATFORM_NAME.BROWSER]: {
      keys: `Control+\\` // For browser use backslash directly in the string
    },
    [COMMON_PLATFORM_NAME.TAURI]: {
      keys: `Control+Backslash` // For Tauri, use the key name
    }
  }
};
