/**
 * Returns the size of the object in bytes (roughly)
 * @see https://stackoverflow.com/a/11900218
 *
 * @param object
 * @returns {number} The size of the object in bytes
 */
export function roughSizeOfObject(object: any): number {
  const objectList: any = [];
  const stack = [object];
  let bytes = 0;

  while (stack.length) {
    const value = stack.pop();

    switch (typeof value) {
      case 'boolean':
        bytes += 4;
        break;
      case 'string':
        bytes += value.length * 2;
        break;
      case 'number':
        bytes += 8;
        break;
      case 'object':
        if (!objectList.includes(value)) {
          objectList.push(value);
          for (const prop in value) {
            if (value.hasOwnProperty(prop)) {
              stack.push(value[prop]);
            }
          }
        }
        break;
    }
  }

  return bytes;
}
