import { GridApi } from '@ag-grid-community/core';
import { Lifecycle, scoped } from 'tsyringe';
import { EventHandler } from './event.handler';
import { EventSource, GridEventType } from './event.source';

@scoped(Lifecycle.ContainerScoped)
export class FilterModelEventHandler implements EventHandler<any> {
  private _hasDataLoaded = false;

  public name = 'initial-filters-event-handler';

  private _setInitialFilters(api: GridApi<any>) {
    const initialFilters = {};
    const currentFilterModel = api.getFilterModel();
    const filterModel = {
      ...currentFilterModel,
      ...initialFilters
    };
    api.setFilterModel(filterModel);
    api.onFilterChanged();
  }

  public addEvents(eventSource: EventSource<GridEventType, any>) {
    eventSource.add('onRowDataUpdated', (e) => {
      if (this._hasDataLoaded) {
        return;
      }

      this._setInitialFilters(e.api);
      this._hasDataLoaded = true;
    });
  }

  public removeEvents() {}
}
