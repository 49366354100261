import { useCallback } from 'react';
import clsx from 'clsx';
import { IconButton, IconButtonProps } from '../button/icon-button';
import { SunIcon, MoonIcon } from '@radix-ui/react-icons';
import { useColorMode } from './use-color-mode';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { __DEV__ } from '../../system/utils/assertion';

type OmittedProps = 'icon' | 'aria-label';

export type ColorModeToggleProps = {
  isRound?: boolean;
  onChange?: (mode: string) => void;
} & Omit<IconButtonProps, OmittedProps>;

export const ColorModeToggle = polymorphicComponent<'button', ColorModeToggleProps>((props, ref) => {
  const { colorMode, setColorMode } = useColorMode();
  const { children, isRound, className, onChange, ...rest } = props;

  /**
   * Handle on click event, in-case we want to control this comp externally
   */
  const onClickHandler = useCallback(() => {
    const nextMode = colorMode === 'dark' ? 'light' : 'dark';

    // Allow component to be externally controlled.
    if (onChange) {
      onChange(nextMode);
    } else {
      // Update internal state
      setColorMode(nextMode);
    }
  }, [setColorMode, onChange, colorMode]);

  return (
    <IconButton
      isRound={isRound}
      ref={ref}
      aria-label="Toggle color mode"
      className={clsx(className)}
      icon={colorMode === 'light' ? <SunIcon /> : <MoonIcon />}
      onClick={onClickHandler}
      {...rest}
    />
  );
});

if (__DEV__) {
  IconButton.displayName = 'ColorModeToggle';
}
