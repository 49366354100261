import { isEqual } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import type { FC } from 'react';
import { mapFixAtdlAdvancedListOptions, mapOptionToComboboxItem } from '../../utils';
import type { FixAtdlListItem, EditableDropdownListProps } from '../../fixatdl-form.types';
import { useFieldApi, AdvancedSelectField } from '@oms/frontend-foundation';
import type { ICommonField, ComboBoxItem, FieldProps } from '@oms/frontend-foundation';

const convertInputToString = (item: ComboBoxItem | string): string | undefined => {
  if (typeof item === 'object' && item !== null) {
    if ('value' in item) {
      return String(item.value);
    } else {
      throw new Error("Object must contain property 'value'");
    }
  }
  if (['string', 'boolean', 'number'].includes(typeof item)) {
    return String(item);
  }
  throw new Error(`Received unexpected value ${item}`);
};

export const EditableDropdownList: FC<FieldProps<EditableDropdownListProps>> = React.memo(
  ({ initialValue, options = [], ...props }) => {
    const { input } = useFieldApi<ICommonField<string>>(props);

    const onChangeRef = useRef(input.onChange);
    onChangeRef.current = input.onChange;

    const initialComboboxItem = useMemo(() => {
      const option = options.find((o) => o.enumID === initialValue);

      if (!option) {
        console.error(`Provided initial value '${initialValue}' doesn't match any option`);
        return undefined;
      }

      return mapOptionToComboboxItem(option);
    }, [initialValue, options]);

    const handleChange = useCallback((items: ComboBoxItem[]) => {
      onChangeRef.current(items[0]?.value || undefined);
    }, []);

    const comboboxConvertedValue: ComboBoxItem<string>[] | undefined = useMemo(() => {
      const value = convertInputToString(input.value) || initialValue;
      const option = options.find((o: FixAtdlListItem) => o.enumID === value);

      if (!option) {
        console.error(`Provided initial value '${value}' doesn't match any option`);
        return;
      }
      if (!option.uiRep.trim()) {
        return;
      }

      return [mapOptionToComboboxItem(option)];
    }, [initialValue, input.value, options]);

    return (
      <AdvancedSelectField
        {...props}
        initialValue={initialComboboxItem}
        options={mapFixAtdlAdvancedListOptions(options)}
        allowAnyValue={true}
        onChange={handleChange}
        value={comboboxConvertedValue}
        component="advanced-select"
      />
    );
  },
  isEqual
);
