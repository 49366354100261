import { type MutableRefObject, type Ref, type RefCallback, useCallback } from 'react';

export const useMergedRef = <T>(...refs: Ref<T>[]): RefCallback<T> => {
  return useCallback((node: T) => {
    for (const ref of refs) {
      if (ref) {
        if (typeof ref === 'function') {
          ref(node);
        } else {
          (ref as MutableRefObject<T>).current = node;
        }
      }
    }
  }, refs);
};
