import { sharedDefaultCol, sharedQuantityCol } from '@app/common/grids/columns/generic-cols';
import { ColumnBuilderCallback, ColumnLibrary, CellBadgeClassEnum } from '@oms/frontend-vgrid';
import {
  sharedDateTimeCol,
  sharedTextCol,
  sharedPriceCol,
  sharedUserCol
} from '@app/common/grids/columns/generic-cols';
import {
  sharedOrderEntryTypeCol,
  sharedOrderStatusWithoutMapperCol
} from '@app/common/grids/columns/order-cols';
import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';
import { InvestorOrderStatus } from '@oms/generated/frontend';
import { mapOrderStatus } from '@app/common/mappers/map-order-status';

const defaultAuditTrailCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedDefaultCol<OrderAuditTrail>(c).minWidth(100);

const eventTimestampCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedDateTimeCol(c, 'eventTimestamp').header('Timestamp').sort('desc').minWidth(200);

const eventCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedTextCol(c, 'event').header('Event').width(220);

const sourceCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedOrderEntryTypeCol(c).header('Source').width(140);

const enteredByCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedUserCol(c, 'user').header('User / Entered By').width(200);

const orderStatusCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedOrderStatusWithoutMapperCol(c)
    .header('Order Status')
    .width(200)
    .filter('agSetColumnFilter')
    .filterParams<InvestorOrderStatus>({
      values: Object.values(InvestorOrderStatus),
      valueFormatter: ({ value }) => mapOrderStatus(value)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapOrderStatus(data?.status)));

const bidPriceCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedPriceCol(c, 'bidPrice').header('Bid');

const askPriceCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedPriceCol(c, 'askPrice').header('Ask');

const lastTradePriceCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedPriceCol(c, 'lastTradePrice').header('Last Trd').shortHeader('Last');

const cumulativeVolumeCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedQuantityCol(c, 'cumulativeVolume').header('Cumulative Volume').shortHeader('Vol');

export const auditTrailColumnLibrary: ColumnLibrary<OrderAuditTrail> = {
  defaultColumn: defaultAuditTrailCol,
  columns: [
    eventTimestampCol,
    eventCol,
    bidPriceCol,
    askPriceCol,
    lastTradePriceCol,
    cumulativeVolumeCol,
    sourceCol,
    enteredByCol,
    orderStatusCol
  ]
};
