import { PopoverAutocompleteComponent } from './autocomplete/popover.autocomplete.component';
import { PopoverAppDisplayGridComponent } from './display-grid/popover.display-grid';
import { POPOVER } from './popover.config';
import { PopoverTooltipComponent } from './tooltip/popover.tooltip';

export const POPOVER_COMPONENT = {
  [POPOVER.TOOLTIP]: PopoverTooltipComponent,
  [POPOVER.AUTOCOMPLETE]: PopoverAutocompleteComponent,
  [POPOVER.DISPLAY_GRID]: PopoverAppDisplayGridComponent
} as const;
