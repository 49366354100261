import { useRef } from 'react';
import type { BehaviorSubject, Observable } from 'rxjs';
import { useVGrid, VGrid, toClientsideGridData } from '@oms/frontend-vgrid';
import type { IOVisKey } from '@app/widgets/trading/investor-order-monitor/grid-services/investor-order-monitor.visibility.filters';
import { useService } from '@oms/frontend-foundation';
import { NotificationsService } from '@app/data-access/services/trading/notifications/notifications.service';
import type { Notification } from './notifications.contracts';
import { notificationsColumnLibrary } from './notifications.columns';
import type { SortType } from './notifications.widget';
import { clearNotificationsAction } from './grid-actions/clear.notifications.action';
import { inlineNotificationsActions } from './grid-actions/inline.notifications.action';
import { createNotificationsEventHandler } from './event-handlers/notifications.event-handler';
import {
  NotificationHasExecutedQuantity,
  NotificationHazard,
  NotificationNoExecutedQuantity,
  NotificationPending
} from './notifications.utils';

export const NOTIFICATIONS_GRID = 'notifications-grid';

// This is the common grid used within the Notifications widget. Each of our 3 grids (my alerts, team alerts, all alerts)
// are variants of this grid.
export const NotificationsGridWidget = ({
  variant$,
  sort$
}: {
  variant$: Observable<IOVisKey>;
  sort$: BehaviorSubject<SortType>;
}) => {
  const datasourceService = useService(NotificationsService);
  const query$ = useRef(datasourceService.watchClient$(variant$, sort$).pipe(toClientsideGridData()));

  const gridProps = useVGrid<Notification>(
    NOTIFICATIONS_GRID,
    (b) =>
      b
        .columnLibrary(notificationsColumnLibrary)
        .datasource((d) => d.source(query$.current).rowId(({ data }) => data?.id)) // Using mock data for now
        .headerHeight(0) // Make the header height 0 to hide it.
        .rowHeight(60)
        .injectEvents([createNotificationsEventHandler(variant$, sort$)])
        .rowSelection((c) => c.multiple()) // TODO: not yet fully implemented. Need to handle double clicks?
        .rowStateRules({
          pending: (params) => NotificationPending(params.data),
          noExecutedQuantity: (params) => NotificationNoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => NotificationHasExecutedQuantity(params.data),
          hazard: (params) => NotificationHazard(params.data)
        })
        .actions((a) =>
          a
            .schema((s) => s.action(clearNotificationsAction).action(inlineNotificationsActions))
            .settings((s) => s.fromFile({}))
        ),
    // .sideBar(), // show/hide sidebar during Dev
    // .context not yet implemented
    [variant$, sort$]
  );

  return <VGrid {...gridProps} />;
};

export default NotificationsGridWidget;
