import type { DepthCellBackgroundColor } from './depth.cell.types';

type Rank = number;

export const rankToColorMap = new Map<Rank, DepthCellBackgroundColor>([
  [0, 'layout.level4'],
  [1, 'layout.level3'],
  [2, 'layout.level2'],
  [-1, 'layout.level1']
]);
