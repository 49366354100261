import { useMemo } from 'react';
import type { MontageLayoutProps } from '@app/widgets/trading/montage/montage.layout.config';
import { useClosestFlexLayoutActorProps } from '@valstro/workspace-react';

export const useMontageState = (): MontageLayoutProps => {
  const [props] = useClosestFlexLayoutActorProps<MontageLayoutProps>();
  const { instrumentId, instrumentTrackingEnabled, investorAccountId } = props || {};
  return useMemo(
    () => ({ instrumentId, instrumentTrackingEnabled, investorAccountId }),
    [instrumentId, instrumentTrackingEnabled, investorAccountId]
  );
};
