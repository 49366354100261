import type { AnyRecord } from '@oms/frontend-foundation';
import type { RowSelectionBroadcastFnContext, RowSelectionBroadcastFn } from '@oms/frontend-vgrid';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import { useMemo } from 'react';
import { filter, map } from 'rxjs';
import { useScopedActorId } from '../offline/hooks/use-scoped-actor-id.hook';
import { useObservableState } from 'observable-hooks';

// ------ Constants ---------------------------------------------------

export const GRID_SELECTION_EVENT_TYPE = {
  GRID_SELECTION: 'GRID_SELECTION'
} as const;

// ------ Types ---------------------------------------------------
export type GridEventContext = {
  gridType: string;
  gridId: string;
  scopedActorId: string;
  tabNodeId: string | null;
  tabSetNodeId: string | null;
  windowActorId: string;
  widgetActorId: string;
  flexLayoutActorId: string | null;
};

export type GridEventWrapper<T> = T & {
  meta: GridEventContext;
};

export type GridSelectionEvent<
  TData extends AnyRecord,
  TCustomPayload extends AnyRecord = AnyRecord
> = GridEventWrapper<{
  type: typeof GRID_SELECTION_EVENT_TYPE.GRID_SELECTION;
  payload: {
    selectedRows: TData[];
  };
  customPayload?: TCustomPayload;
}>;

export type GridEventUnion<TData extends AnyRecord> = GridSelectionEvent<TData>;

/**
 * Event bus
 */
export const gridEvent$ = new BroadcastSubject<GridEventUnion<AnyRecord>>('grid-events');

export type BroadcastGridCustomPayloadFn<TData extends AnyRecord, TCustomPayload extends AnyRecord> = (
  selectedRows: TData[],
  ctx: GridEventContext
) => TCustomPayload | undefined | void;

/**
 * Re-useable function to broadcast grid selection event
 *
 * @example
 * ```ts
 * .rowSelection((c) =>
 *  c.multiple().broadcast(
 *    broadcastGridSelection<IOFragment>((...args) => ({ customPayload: 'hello' })) // Note: You don't need to pass the custom payload function if you don't need it.
 *  )
 * )
 * ```
 *
 * @param customPayloadFn - Custom payload function
 * @returns RowSelectionBroadcastFn
 */
export const broadcastGridSelection =
  <TData extends AnyRecord, TCustomPayload extends AnyRecord = AnyRecord>(
    customPayloadFn?: BroadcastGridCustomPayloadFn<TData, TCustomPayload>
  ): RowSelectionBroadcastFn<TData> =>
  (selectedRows: TData[], ctx: RowSelectionBroadcastFnContext) => {
    const meta: GridEventContext = {
      gridType: ctx.gridType,
      gridId: ctx.gridId,
      scopedActorId: ctx.scopedActorId,
      tabNodeId: ctx.tabNodeId,
      tabSetNodeId: ctx.tabSetNodeId,
      windowActorId: ctx.windowActor.id,
      widgetActorId: ctx.widgetActor.id,
      flexLayoutActorId: ctx.flexLayoutActor?.id || null
    };
    const customPayload = customPayloadFn ? customPayloadFn(selectedRows, meta) : undefined;
    gridEvent$.next({
      type: GRID_SELECTION_EVENT_TYPE.GRID_SELECTION,
      payload: {
        selectedRows
      },
      customPayload: customPayload || undefined,
      meta
    });
  };

// ------ Selectors ---------------------------------------------------

export const getGridSelectionEvent$ = <
  TData extends AnyRecord,
  TCustomPayload extends AnyRecord = AnyRecord
>() => {
  return gridEvent$.pipe(
    filter(
      (event): event is GridSelectionEvent<TData, TCustomPayload> =>
        event.type === GRID_SELECTION_EVENT_TYPE.GRID_SELECTION
    )
  );
};

export type GetGridSelectionEventByStrategyOptions =
  | {
      strategy: 'scoped';
      scopedActorId: string;
      gridType: string;
    }
  | {
      strategy: 'global';
      gridType: string;
    };

export type GetGridSelectionStrategy = GetGridSelectionEventByStrategyOptions['strategy'];

export const getGridSelectionEventByStrategy$ = <
  TData extends AnyRecord,
  TCustomPayload extends AnyRecord = AnyRecord
>(
  options: GetGridSelectionEventByStrategyOptions
) => {
  return getGridSelectionEvent$<TData, TCustomPayload>().pipe(
    filter((e) => {
      if (options.strategy === 'scoped') {
        return e.meta.scopedActorId === options.scopedActorId && e.meta.gridType === options.gridType;
      }
      return e.meta.gridType === options.gridType;
    })
  );
};

// ------ Hooks ---------------------------------------------------

export function useGridSelection$<TData extends AnyRecord, TCustomPayload extends AnyRecord = AnyRecord>(
  gridType: string,
  strategy: 'scoped' | 'global'
) {
  const scopedActorId = useScopedActorId();
  return useMemo(
    () =>
      getGridSelectionEventByStrategy$<TData, TCustomPayload>({
        gridType,
        scopedActorId,
        strategy
      }),
    [gridType, strategy, scopedActorId]
  );
}

/**
 * Get the selected rows event from a grid that's broadcasting its selected rows
 * Note: This is useful over `useGridSelectionRows` if you need the meta data / custom payload from the event
 *
 * @example
 * ```ts
 * const selectedRowsEvent = useGridSelectionEvent<IOFragment>('investor-order-monitor', 'scoped');
 * ```
 *
 * @param gridType - The grid type to filter the event by
 * @param strategy - The strategy for scoping the event. `scoped` = scoped to the parent actor (flex layout), `global` = from any grid anywhere
 * @returns The grid selection event
 */
export function useGridSelectionEvent<TData extends AnyRecord, TCustomPayload extends AnyRecord = AnyRecord>(
  gridType: string,
  strategy: 'scoped' | 'global'
) {
  const $ = useGridSelection$<TData, TCustomPayload>(gridType, strategy);
  return useObservableState($, null);
}

/**
 * Get the selected rows from a grid that's broadcasting its selected rows
 * Note: This is different from `useGridSelectionEvent` as it returns the selected rows directly without the meta data
 *
 * @example
 * ```ts
 * const selectedRows = useGridSelectionRows<IOFragment>('investor-order-monitor', 'scoped');
 * ```
 *
 * @param gridType - The grid type to filter the event by
 * @param strategy - The strategy for scoping the event. `scoped` = scoped to the parent actor (flex layout), `global` = from any grid anywhere
 * @returns The grid selection event
 */
export function useGridSelectionRows<TData extends AnyRecord, TCustomPayload extends AnyRecord = AnyRecord>(
  gridType: string,
  strategy: 'scoped' | 'global'
) {
  const $ = useGridSelection$<TData, TCustomPayload>(gridType, strategy);
  const selectedRows$ = useMemo(() => $.pipe(map((e) => e.payload.selectedRows)), [$]);
  return useObservableState(selectedRows$, [] as TData[]);
}
