import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, BoxProps } from '../../system/components/box/box';
import { isElementOverflowed } from '../../utils/overflow';
import { Text } from '../../layout/text/text';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import * as styles from './text-ellipser.css';

export type TextEllipserProps = React.PropsWithChildren<{
  behaviour?: 'none' | 'clip' | 'ellipsis' | 'customString' | 'lineClamp';
  customString?: string;
  lineClamp?: string;
}> &
  BoxProps;

export const TextEllipser: FC<TextEllipserProps> = React.memo(
  ({ behaviour = 'ellipsis', customString, children, lineClamp, sx, ...rest }) => {
    const [textContent, setTextContent] = useState<string>('');
    const [isTextOverflowed, setIsTextOverflowed] = useState<boolean | null>(null);
    const ref = useRef<HTMLElement>();
    const resizeObserverRef = useRef(
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        const entry = entries.filter((entry) => ref.current === entry.target)?.[0];
        if (entry) {
          setIsTextOverflowed(isElementOverflowed(entry.target as HTMLElement));
        }
      })
    );

    useEffect(() => {
      if (ref.current) {
        setTextContent(ref.current.textContent?.trim() || '');
        setIsTextOverflowed(isElementOverflowed(ref.current));
      }
    }, [ref, children]);

    useEffect(() => {
      if (ref.current) {
        const element = ref.current;
        const resizeObserver = resizeObserverRef.current;
        resizeObserver.observe(element);
        return () => {
          resizeObserver.unobserve(element);
        };
      }
      return () => {};
    }, [ref, behaviour]);

    const title = (isTextOverflowed && textContent) || '';

    if (
      behaviour === 'ellipsis' ||
      behaviour === 'clip' ||
      behaviour === 'none' ||
      behaviour === 'lineClamp'
    ) {
      return (
        <Box
          ref={ref}
          className={styles[behaviour]}
          title={title}
          sx={sx}
          style={{
            ...(behaviour === 'lineClamp' && !!lineClamp
              ? assignInlineVars({ [styles.lineClampValue]: lineClamp })
              : null)
          }}
          {...rest}
        >
          {children}
        </Box>
      );
    }

    // customString
    return (
      <Box className={styles.flex} title={title} sx={sx}>
        <Box className={styles.clip} {...rest} ref={ref}>
          {children}
        </Box>
        {isTextOverflowed && <Text className={styles.nowrap}>{customString}</Text>}
      </Box>
    );
  }
);
