import { ServerError as ServerErrorType, ServerParseError as ServerParseErrorType } from '@apollo/client';
import { ErrorTypeEnum } from './errors.types';

export class ServerError extends Error implements ServerErrorType {
  public response: ServerErrorType['response'];
  public result: ServerErrorType['result'];
  public statusCode: ServerErrorType['statusCode'];
  public type = ErrorTypeEnum.SERVER_ERROR;
  public code = ErrorTypeEnum.SERVER_PARSE_ERROR;

  constructor(message: string, error: ServerErrorType) {
    super(message);
    Object.setPrototypeOf(this, ServerError.prototype);
    this.name = error.name;
    this.response = error.response;
    this.result = error.result;
    this.statusCode = error.statusCode;
  }
}

export class ServerParseError extends Error implements ServerParseErrorType {
  public response: ServerParseErrorType['response'];
  public bodyText: ServerParseErrorType['bodyText'];
  public statusCode: ServerParseErrorType['statusCode'];
  public type = ErrorTypeEnum.SERVER_PARSE_ERROR;
  public code = ErrorTypeEnum.SERVER_PARSE_ERROR;

  constructor(message: string, error: ServerParseErrorType) {
    super(message);
    Object.setPrototypeOf(this, ServerParseError.prototype);
    this.name = error.name;
    this.response = error.response;
    this.bodyText = error.bodyText;
    this.statusCode = error.statusCode;
  }
}
