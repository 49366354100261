import { ActorOperationOptions, ActorSchema } from '../../../core/actor.schema';
import type { AnyRecord } from '../../../core/utils';

export const COMMON_ACTOR_TYPE = {
  WINDOW: 'window',
  WIDGET: 'widget'
} as const;

/**
 * Helper Types
 */
export type WinCoOrdPixel = number;
export type WinCoOrdPhysicalPixel = number;
export type AnyWinCoOrd = number | string;

export type WinPixelType = 'physical' | 'logical';

export type WinPositionOptions = {
  x: WinCoOrdPixel;
  y: WinCoOrdPixel;
  /**
   * Use logical pixels instead of physical pixels
   * @default false
   */
  useLogicalPixels?: boolean;
};

export type WinSizeOptions = {
  width: WinCoOrdPixel;
  height: WinCoOrdPixel;
  useLogicalPixels?: boolean;
};

export const USER_ATTENTION_TYPE = {
  INFO: 'info',
  CRITICAL: 'critical'
} as const;

export type UserAttentionType = (typeof USER_ATTENTION_TYPE)[keyof typeof USER_ATTENTION_TYPE];

/**
 * Actor Window Contracts
 */
export type WindowContext<TMeta extends AnyRecord = AnyRecord> = {
  title: string;
  isTitleEditable?: boolean;
  x?: WinCoOrdPhysicalPixel;
  y?: WinCoOrdPhysicalPixel;
  width: WinCoOrdPhysicalPixel;
  height: WinCoOrdPhysicalPixel;
  isMaximized: boolean;
  isMinimized: boolean;
  isMaximizable: boolean;
  isMinimizable: boolean;
  isResizable: boolean;
  isClosable: boolean;
  isVisible: boolean;
  isFocused?: boolean;
  isDecorated: boolean;
  isFullscreen: boolean;
  alwaysOnTop: boolean;
  isPinned: boolean;
  isPinnable: boolean;
  skipTaskbar: boolean;
  scaleFactor: number;
  currentMonitorIndex: number;
  requestingUserAttention: UserAttentionType | null;
  isBrowserTab?: boolean; // browser-only property
  transparent?: boolean;
  minWidth?: WinCoOrdPhysicalPixel;
  maxWidth?: WinCoOrdPhysicalPixel;
  minHeight?: WinCoOrdPhysicalPixel;
  maxHeight?: WinCoOrdPhysicalPixel;
  initiallyCentered?: boolean;
  initiallyUseLogicalPixels?: boolean;
  theme?: string | null;
  tauriLabel?: string;
  meta: TMeta; // Meta data
};

export type SimplePosition = {
  x: WinCoOrdPixel;
  y: WinCoOrdPixel;
};

export type SimplePositionWithScaling = SimplePosition & {
  scaleFactor: number;
};

export type SimpleSize = {
  width: WinCoOrdPixel;
  height: WinCoOrdPixel;
};

export type WindowResizeOrigin =
  | 'top-left'
  | 'top-right'
  | 'top-center'
  | 'center'
  | 'bottom-center'
  | 'bottom-left';

export type WindowResizeDir = 'horizontal' | 'vertical' | 'both';

export type WindowOperations<TMeta extends AnyRecord = AnyRecord> = {
  setTitle: (title: string, options?: ActorOperationOptions) => Promise<void>;
  setPinned: (isPinned: boolean, options?: ActorOperationOptions) => Promise<void>;
  setPosition: (position: WinPositionOptions, options?: ActorOperationOptions) => Promise<void>;
  setSize: (size: WinSizeOptions, options?: ActorOperationOptions) => Promise<void>;
  setSizeAndPosition: (
    size: WinSizeOptions,
    position: WinPositionOptions,
    options?: ActorOperationOptions
  ) => Promise<void>;
  resizeAroundOrigin: (
    size: WinSizeOptions,
    origin: WindowResizeOrigin,
    direction?: WindowResizeDir,
    options?: ActorOperationOptions
  ) => Promise<void>;
  center: (options?: ActorOperationOptions) => Promise<void>;
  centerInActiveMonitor: (options?: ActorOperationOptions) => Promise<void>;
  setMaximizable: (isMaximizable: boolean) => Promise<void>;
  setMinimizable: (isMinimizable: boolean) => Promise<void>;
  setResizable: (isResizable: boolean) => Promise<void>;
  setDecorated: (isDecorated: boolean) => Promise<void>;
  setFullscreen: (isFullscreen: boolean, options?: ActorOperationOptions) => Promise<void>;
  setAlwaysOnTop: (alwaysOnTop: boolean, options?: ActorOperationOptions) => Promise<void>;
  setSkipTaskbar: (skipTaskbar: boolean, options?: ActorOperationOptions) => Promise<void>;
  setMaxSize: (size: WinSizeOptions, options?: ActorOperationOptions) => Promise<void>;
  setMinSize: (size: WinSizeOptions, options?: ActorOperationOptions) => Promise<void>;
  toggleMaximize: (options?: ActorOperationOptions) => Promise<void>;
  maximize: (options?: ActorOperationOptions) => Promise<void>;
  unmaximize: (options?: ActorOperationOptions) => Promise<void>;
  minimize: (options?: ActorOperationOptions) => Promise<void>;
  unminimize: (options?: ActorOperationOptions) => Promise<void>;
  show: (options?: ActorOperationOptions) => Promise<void>;
  hide: (options?: ActorOperationOptions) => Promise<void>;
  focus: (options?: ActorOperationOptions) => Promise<void>;
  close: (options?: ActorOperationOptions) => Promise<void>;
  destroy: (options?: ActorOperationOptions) => Promise<void>;
  requestUserAttention: (type: UserAttentionType) => Promise<void>;
  focusAll: (options?: ActorOperationOptions) => Promise<void>;
  setMeta: (meta: TMeta) => Promise<void>;
  updateMeta: (meta: Partial<TMeta>) => Promise<void>;
  getInnerPosition: () => Promise<SimplePositionWithScaling>;
};

export type CommonWindowActorSchemaOptions<TCompView = any> = {
  defaultContext?: Partial<WindowContext>;
  windowDecorationComponent?: TCompView;
  wrapperComponent?: TCompView;
};

export type CommonWindowActorSchema<
  TCompView = any,
  TContextMeta extends AnyRecord = AnyRecord
> = ActorSchema<
  WindowContext<TContextMeta>,
  WindowOperations,
  TCompView,
  CommonWindowActorSchemaOptions<TCompView>
>;

/**
 * Actor Widget Contracts
 */
export type WidgetContext<Props extends AnyRecord = AnyRecord> = {
  title?: string;
  componentId: string;
  componentProps: Props;
  version?: number;
};

export type WidgetOperations<Props extends AnyRecord = AnyRecord> = {
  setContext: (context: WidgetContext<Props>) => Promise<void>;
  updateContext: (contextDelta: Partial<WidgetContext<Props>>) => Promise<void>;
  setTitle: (title: string) => Promise<void>;
  updateProps: (propsDelta: Partial<Props>) => Promise<void>;
  setProps: (props: Props) => Promise<void>;
};

export type WidgetActorComponents<TViewType = any> = Record<string, TViewType>;

export type WidgetActorSchemaOptions<TCompView = any> =
  | {
      widgetComponentsMap: WidgetActorComponents<TCompView>;
      dynamicWidgetComponentsMap?: Promise<{
        widgetComponentsMap: WidgetActorComponents<TCompView>;
      }>;
    }
  | {
      widgetComponentsMap?: WidgetActorComponents<TCompView>;
      dynamicWidgetComponentsMap?: Promise<{
        widgetComponentsMap: WidgetActorComponents<TCompView>;
      }>;
    };

export type CommonWidgetActorSchema<Props extends AnyRecord = AnyRecord> = ActorSchema<
  WidgetContext<Props>,
  WidgetOperations<Props>,
  any,
  WidgetActorSchemaOptions<any>
>;

/**
 * Custom Actor Events
 */
export type ClickEvent = {
  type: 'click';
  payload: {
    type: 'inside' | 'outside' | 'inside-dragstart' | 'outside-component';
    x: number;
    y: number;
  };
};
