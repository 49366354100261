import type { UnknownRecord } from '@oms/frontend-foundation';
import type { ColumnBuilder, ColumnBuilderField } from '@oms/frontend-vgrid';
import { sharedNumericCol, sharedPriceCol } from './generic-cols';

export const sharedPositionsNumeric = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => sharedNumericCol(c, fieldPath).format('number-position');

export const sharedPositionsPrice = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => sharedPriceCol(c, fieldPath).format('price-position-based-on-one');
