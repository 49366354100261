import React from 'react';
import type { InstrumentFollowToggleButtonProps } from './instrument-follow.toggle-button';
import {
  MONTAGE_INSTRUMENT_FOLLOW_STATE_KEY,
  InstrumentFollowMontageToggleState
} from '@app/widgets/trading/montage/montage-toolbar/montage-filters.form/montage-filters.follow.toggle-state';
import {
  ORDER_VWAP_FOLLOW_STATE_KEY,
  FollowOrderVwapToggleState
} from '@app/widgets/trading/order-vwap/form/order-vwap.follow.toggle-state';

export interface InstrumentFollowToggleStateProps
  extends Pick<InstrumentFollowToggleButtonProps, 'onPressedChange'> {
  children: (
    props: Pick<InstrumentFollowToggleButtonProps, 'onPressedChange' | 'pressed'>
  ) => React.ReactNode;
}

export type InstrumentFollowRegistryKey =
  | typeof MONTAGE_INSTRUMENT_FOLLOW_STATE_KEY
  | typeof ORDER_VWAP_FOLLOW_STATE_KEY;

export type InstrumentFollowRegistry = Record<
  InstrumentFollowRegistryKey,
  React.FC<InstrumentFollowToggleStateProps>
>;

export const INSTRUMENT_TRACKING_STATE_REGISTRY: InstrumentFollowRegistry = {
  [MONTAGE_INSTRUMENT_FOLLOW_STATE_KEY]: InstrumentFollowMontageToggleState,
  [ORDER_VWAP_FOLLOW_STATE_KEY]: FollowOrderVwapToggleState
};
