import type { UserPreferencesContractFormValues } from '../../user-preferences.form-contract';
import { orderSettingsFieldIndexes } from '../order-settings.util';

export const VALIDATE_PRICE_TO_VALUE = 'validatePriceToValue';

const message = {
  leaveBlankIfLastRow: 'Leave blank if last row',
  tooLow: 'Too low',
  orderSettingsNotFound: 'Order settings not found',
  profileNotFound: 'Profile not found'
};

export const validatePriceToValue =
  () =>
  (value: number, allValues?: UserPreferencesContractFormValues, meta?: any): string | undefined => {
    const { name } = meta;
    const [profileIndex, rowIndex] = orderSettingsFieldIndexes(name);

    const orderSettings = allValues?.orderSettings;

    if (!orderSettings) {
      return message.orderSettingsNotFound;
    }

    const profile = orderSettings[profileIndex];

    if (!profile) {
      return message.profileNotFound;
    }

    const rows = profile.rows;

    if (!rows) {
      return message.profileNotFound;
    }

    const row = rows[rowIndex];

    if (!row) {
      return message.profileNotFound;
    }

    const priceFrom = row.priceFrom;

    const isLastRow = rowIndex === rows.length - 1;

    if (isLastRow && typeof value === 'number' && value !== Number.POSITIVE_INFINITY) {
      return message.leaveBlankIfLastRow;
    }

    if (!isLastRow && typeof value === 'number' && value === Number.POSITIVE_INFINITY) {
      return message.leaveBlankIfLastRow;
    }

    if (!isLastRow && typeof value === 'undefined') {
      return message.tooLow;
    }
    if (!isLastRow && typeof value === 'number' && value === -1) {
      return message.tooLow;
    }
    if (priceFrom && value <= priceFrom) {
      return message.tooLow;
    }
  };
