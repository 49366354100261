import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

export const UPPER_CASE_CELL_RENDERER = 'uppercaseRenderer';

/**
 * Render a value as an uppercase string
 */
export const uppercaseRenderer: React.FC<ICellRendererParams> = ({ value }) => (
  <span>{value?.toUpperCase()}</span>
);
