import { inject, Lifecycle, scoped } from 'tsyringe';
import { VGridInstance } from '../models/v.instance.model';
import type { VGridContextInstance } from '../models/v.context.model';

export const DEFAULT_VIEW_ID = 'default';

export function generateGridId(widgetId: string, gridType: string, viewId?: string) {
  return hash(`${widgetId}${gridType}${viewId || DEFAULT_VIEW_ID}`).toString();
}

@scoped(Lifecycle.ContainerScoped)
export class GridIdService {
  private _context: VGridContextInstance;

  constructor(@inject(VGridInstance.Context) context: VGridContextInstance) {
    this._context = context;
  }

  get gridType(): string {
    return this._context.gridType;
  }

  get gridViewId(): string {
    return DEFAULT_VIEW_ID; // fix this for now. Can use later to show multiple grids of the same type in the same widget
  }

  get windowId(): string {
    return this._context.windowActor.id;
  }

  get widgetId(): string {
    return this._context.widgetActor.id;
  }

  get flexLayoutActorId(): string | null {
    return this._context.flexLayoutActor ? this._context.flexLayoutActor.id : null;
  }

  get tabNodeId(): string | null {
    return this._context.flexLayoutTabNode?.getId() || null;
  }

  get scopedActorId(): string {
    return this._context.scopedActorId;
  }

  get processId(): string {
    return this._context.windowActor.id;
  }

  get gridId(): string {
    return this._context.gridId || generateGridId(this.widgetId, this.gridType, this.gridViewId);
  }

  get allGridIdentifiers() {
    return {
      gridId: this.gridId,
      widgetId: this.widgetId,
      windowId: this.windowId,
      gridType: this.gridType,
      viewId: this.gridViewId,
      flexLayoutActorId: this.flexLayoutActorId,
      scopedActorId: this.scopedActorId
    };
  }

  public getGridEl = () => {
    return document.querySelector(`.vgrid-id-${this.gridId}`) as HTMLElement | null;
  };

  public getGridViewportEl = () => {
    return document.querySelector(`.vgrid-id-${this.gridId} .ag-center-cols-viewport`) as HTMLElement | null;
  };
}

// Cheap and cheerful string hashing function
// https://github.com/MatthewBarker/hash-string/blob/master/source/hash-string.js
function hash(text: string) {
  let hash = 5381,
    index = text.length;

  while (index) {
    hash = (hash * 33) ^ text.charCodeAt(--index);
  }

  return hash >>> 0;
}
