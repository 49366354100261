import { CustomGraphQLError } from './custom-error';
import { ErrorTypeEnum } from './errors.types';

export type IAsyncTimeoutError = CustomGraphQLError<ErrorTypeEnum.ASYNC_TIMEOUT_ERROR, string>;

export class AsyncTimeoutError extends CustomGraphQLError<ErrorTypeEnum.ASYNC_TIMEOUT_ERROR, string> {
  constructor(message: string = 'Async operation timeout', code?: string) {
    super(message);
    this.extensions = {
      type: ErrorTypeEnum.ASYNC_TIMEOUT_ERROR,
      code: code || ErrorTypeEnum.ASYNC_TIMEOUT_ERROR,
      data: message
    };
  }
}
