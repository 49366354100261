import { Logger } from '../misc';
import { DefaultJoiSchema } from '../validation';
import { FormatterFunction } from './types';

// Enable to see debug logs
const l = Logger.labeled('formatEmailAddress').disable();

export const makeFormatEmailAddress =
  (fallbackWithInput?: boolean): FormatterFunction =>
  ({ value }) => {
    const input = typeof value === 'string' ? value : '';
    const fallback = fallbackWithInput ? input : '';
    const { value: emailAddress, error } = DefaultJoiSchema.emailAddress.validate(input);
    if (error) {
      l.debug(error);
      return fallback;
    }
    return emailAddress ?? fallback;
  };

export default makeFormatEmailAddress;
