import { AnyRecord } from '@oms/frontend-foundation';
import isFunction from 'lodash/isFunction';
import merge from 'lodash/merge';
import { GridActionBuilder } from './grid.action.builder';
import {
  DefaultActionDefs,
  ActionDefFactory,
  ActionSchema,
  ActionDef,
  ActionDefKey
} from '../models/actions.model';

/**
 * Converts action schemas into action action definitions that can be used by the ActionsService.
 * @param registry Registry of actions.
 * @param schemas List of schemas -> 'from_registry', ['from_registry', b => b.name('override')], b => b.name('new_action')
 * @returns A list of action definitions.
 */
export const compileActions = <TData extends AnyRecord, TActionDefs extends string = DefaultActionDefs>(
  registry: Record<TActionDefs, ActionDefFactory>,
  ...schemas: ActionSchema<TData, TActionDefs>[]
): ActionDef<TData>[] => {
  const defs: ActionDef<TData>[] = [];

  schemas.forEach((schema) => {
    let action!: ActionDef<TData> | undefined;
    if (isFunction(schema)) {
      const builder = new GridActionBuilder<TData>();
      const extBuilder = schema(builder);
      action = extBuilder.build();
    } else if (Array.isArray(schema)) {
      const cb = registry[schema[0] as TActionDefs] as ActionDefFactory<TData>;
      const cb2 = schema[1];
      const builder = new GridActionBuilder<TData>();
      const extBuilder = cb(builder);
      const action1 = extBuilder.build();
      const builder2 = new GridActionBuilder<TData>();
      const extBuilder2 = cb2(builder2);
      const action2 = extBuilder2.build();
      action = merge({}, action1, action2);
    } else {
      const cb = (registry as Record<ActionDefKey<TActionDefs>, ActionDefFactory>)[
        schema
      ] as ActionDefFactory<TData>;
      const builder = new GridActionBuilder<TData>();
      const extBuilder = cb(builder);
      action = extBuilder.build();
    }

    if (!action.name) {
      throw new Error('An action requires a name.');
    }

    if (action.name.includes('-')) {
      throw new Error('Action names cannot have dashes.');
    }
    defs.push(action);
  });

  return defs;
};
