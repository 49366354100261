import { RepairCategory } from '@oms/generated/frontend';
import type { Maybe } from '@oms/shared/util-types';

const categoryMapping = {
  [RepairCategory.IoCreate]: 'Create',
  [RepairCategory.IoModify]: 'Modify',
  [RepairCategory.IoCancel]: 'Cancel',
  [RepairCategory.TradeCreate]: 'Create',
  [RepairCategory.TradeModify]: 'Modify'
};

export const mapRepairCategory = (
  value?: Maybe<RepairCategory> | string,
  defaultVal = value || ''
): string => {
  return value && categoryMapping[value as RepairCategory]
    ? categoryMapping[value as RepairCategory]
    : defaultVal;
};
