import type { AnyKey, AnyRecord, OneOrMore } from '@oms/shared/util-types';
import { asArray } from '@oms/shared/util';
import type { NestedTreeData, TreeDataSlice } from '../../types/tree-data.types';
import type { FlattenNestedTreeDataOptions } from '../internal/types';
import { extractItemKey } from './tree-data-key.util';
import { extractChildren } from './tree-data-children.util';
import { addHierarchy } from './tree-data-hierarchy.util';

// 🥞 Flatten nested tree data utils -------------------------------------------------------- /
// Utils to flatten nested tree data for the ag-Grid server side rendering model

/**
 * Flattens nested tree data to a slice for server-side grid rendering
 *
 * @param data - A single item nested tree data object
 * @param parentHierarchy - An array of the parent hierarchy above the item
 * @param options.getKey - Specify how to get the key value (id, etc.) from the data
 * @param options.separator - Specify how levels of the `pathKey` key are separated (defaults to ".")
 * @param options.getHid - Specify a custom function get generate the HID key (a default will be used if omitted)
 * @returns A flatten slice for the tree node
 */
export const flattenNestedTreeDataNodeToSlice = <TData extends AnyRecord, S extends AnyKey = string>(
  data: NestedTreeData<TData>,
  parentHierarchy: S[] = [],
  options?: FlattenNestedTreeDataOptions<TData, S>
): TreeDataSlice<TData, S> => {
  const { getKey, separator, getHid } = options ?? {};
  const { item, children } = extractChildren(data);
  const key = extractItemKey(item, { getKey }) ?? ('' as S);
  const itemWithHierarchyInfo = addHierarchy(
    item,
    {
      getHierarchy: () => [...parentHierarchy, key],
      getHid
    },
    { separator }
  );
  return {
    ...itemWithHierarchyInfo,
    id: key,
    hasChildren: !!children?.length
  };
};

/**
 * Flattens nested tree data to an array of slices for server-side grid rendering
 *
 * @param data - One or more nested tree data objects
 * @param parentHierarchy - An array of the parent hierarchy above the item
 * @param options.getKey - Specify how to get the key value (id, etc.) from the data
 * @param options.separator - Specify how levels of the `pathKey` key are separated (defaults to ".")
 * @param options.getHid - Specify a custom function get generate the HID key (a default will be used if omitted)
 * @returns An array of flatten slices for the tree node
 */
export const flattenNestedTreeDataToSlices = <TData extends AnyRecord, S extends AnyKey = string>(
  data: OneOrMore<NestedTreeData<TData>>,
  parentHierarchy: S[] = [],
  options?: FlattenNestedTreeDataOptions<TData, S>
): TreeDataSlice<TData, S>[] => {
  return asArray(data).reduce(
    (rowData, dataItem) => {
      const slice = flattenNestedTreeDataNodeToSlice(dataItem, parentHierarchy, options);
      rowData.push(slice);
      if (dataItem.children) {
        rowData.push(...flattenNestedTreeDataToSlices(dataItem.children, slice.hierarchy, options));
      }
      return rowData;
    },
    [] as TreeDataSlice<TData, S>[]
  );
};
