import { BehaviorSubject } from 'rxjs';
import type { PartitionValue } from '@oms/shared/util-types';
import type { MontageItem } from '../montage.types';
import { montageSortOrderComparator } from './montage.utils';
import { partitionMontageBy } from './price.partition';

export type PricePartitionMapOptions = {
  comparator?: Parameters<typeof partitionMontageBy<MontageItem, string>>[1];
  selector?: Parameters<typeof partitionMontageBy<MontageItem, string>>[2];
  partitions?: number;
};

export class PricePartitionMapSubject extends BehaviorSubject<Map<string, PartitionValue<MontageItem>>> {
  public constructor(pricePartitionMap?: Map<string, PartitionValue<MontageItem>>) {
    super(pricePartitionMap || new Map<string, PartitionValue<MontageItem>>());
  }

  public nextFromItems(items: MontageItem[], options?: PricePartitionMapOptions) {
    const { comparator = montageSortOrderComparator, selector, partitions = 3 } = options || {};
    return this.next(partitionMontageBy(items, comparator, selector || (({ id }) => id), partitions));
  }
}

export default PricePartitionMapSubject;
