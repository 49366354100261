import { InvestorOrderStatus } from '@oms/generated/frontend';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { useVGrid, VGrid, CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { InvestorOrderMonitorDatasourceService } from '../investor-order-monitor/investor-order-monitor.datasource.service';
import { newOrdersColumnLibrary } from './new-orders.columns';
import { inlineNewOrdersActions } from './grid-actions/inline.new-orders.action';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import { useScopedProps } from '@app/data-access/hooks/use-scoped-props.hook';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { createAlertsEventHandler } from '@app/common/grids/event-handlers/alerts.event-handler';
import { createCloseOnEmptyEventHandler } from '@app/common/grids/event-handlers/close-on-empty.event-handler';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';
import type { NewOrdersLayoutProps } from './new-orders.layout.config';

export const NEW_ORDERS = 'new-orders';

export const NewOrdersGridWidget = () => {
  const { autoCloseOnEmpty } = useScopedProps<NewOrdersLayoutProps>() ?? {};
  const datasourceService = useService(InvestorOrderMonitorDatasourceService);

  const gridProps = useVGrid<VisibleInvestorOrderInfoWithAllocationsFragment>(
    NEW_ORDERS,
    (b) =>
      b
        .columnLibrary(newOrdersColumnLibrary)
        .datasource((d) =>
          d
            .source(
              datasourceService.createServerSideDatasource({
                filterOverrides: {
                  status: {
                    in: [InvestorOrderStatus.Unaccepted]
                  }
                }
              })
            )
            .rowId((r) => r.data.id || '')
        )
        .rowSelection((c) => c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0]))
        .rowStateRules({
          pending: (params) => IOPending(params.data),
          noExecutedQuantity: (params) => IONoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data)
        })
        .sideBar()
        .injectEvents([
          createAlertsEventHandler<VisibleInvestorOrderInfoWithAllocationsFragment>('reason'),
          createCloseOnEmptyEventHandler(autoCloseOnEmpty)
        ])
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(inlineNewOrdersActions)
                .action(refreshServersideCustomMenuAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default NewOrdersGridWidget;
