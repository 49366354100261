import { t } from '@oms/codegen/translations';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { formatNumber } from '@oms/shared/util';
import type { Notification } from './notifications.contracts';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';

const MINUTES_PER_HOUR = 60;
const MINUTES_PER_DAY = 1440;

const justNowLabel = t('app.notifications.grid.elapsedTime.justNow');
const oneMinAgo = t('app.notifications.grid.elapsedTime.oneMinAgo');
const someMinsAgo = (minutes: number) => t('app.notifications.grid.elapsedTime.someMinAgo', { minutes });
const oneHourAgo = t('app.notifications.grid.elapsedTime.oneHourAgo');
const someHoursAgo = (hours: number) => t('app.notifications.grid.elapsedTime.someHoursAgo', { hours });
const oneDayAgo = t('app.notifications.grid.elapsedTime.oneDayAgo');
const someDaysAgo = (days: number) => t('app.notifications.grid.elapsedTime.someDaysAgo', { days });

export const getElapsedTime = (createdAt: string) => {
  const pastTime = new Date(createdAt);
  const currentTime = new Date();
  const elapsedMinutes = Math.floor((currentTime.getTime() - pastTime.getTime()) / 60000);
  if (elapsedMinutes < 1) {
    return justNowLabel;
  } else if (elapsedMinutes < MINUTES_PER_HOUR) {
    return elapsedMinutes === 1 ? oneMinAgo : someMinsAgo(elapsedMinutes);
  } else if (elapsedMinutes > MINUTES_PER_HOUR && elapsedMinutes < MINUTES_PER_DAY) {
    const hoursAgo = Math.floor(elapsedMinutes / MINUTES_PER_HOUR);
    return hoursAgo === 1 ? oneHourAgo : someHoursAgo(hoursAgo);
  } else {
    const daysAgo = Math.floor(elapsedMinutes / MINUTES_PER_DAY);
    return daysAgo === 1 ? oneDayAgo : someDaysAgo(daysAgo);
  }
};

export const getNotificationBody = (notification: Notification) => {
  const { name, resourceFields } = notification;

  // TODO: Add fields validation for other types
  switch (name) {
    case 'IO New':
    case 'IO Failed':
    case 'IO Cancel Request':
    case 'IO Modify Request':
    case 'IO Failed - Modify': {
      const { account, instrument, side, quantity, price } = resourceFields;
      return `${account} ${instrument} ${side} ${formatNumber(Number(quantity))} @ ${formatNumber(
        Number(price)
      )}`;
    }
    case 'Failed Trade':
    case 'Failed Trade - Modify':
    case 'Capital Threshold Warning': {
      const { side, instrument, quantity, price, tradeCounterparty } = resourceFields;
      return `${side} ${instrument} ${formatNumber(Number(quantity))} @ ${formatNumber(
        Number(price)
      )} ${tradeCounterparty}`;
    }
    default:
      return `Default body for ${name}`;
  }
};

// Determines if the given Notification row should have the "Pending" row state.
export function NotificationPending(rowData: Notification | undefined): boolean {
  switch (rowData?.name) {
    case 'IO New': {
      // This is an IO_NEW. Use the investorOrder object to determine "Pending" row state.
      return IOPending(rowData.investorOrder as VisibleInvestorOrderInfoWithAllocationsFragment);
    }
    case 'IO Cancel Request':
    case 'IO Modify Request':
    case 'IO Failed':
    case 'IO Failed - Modify':
    case 'Capital Threshold Warning':
    case 'Failed Trade':
    case 'Failed Trade - Modify':
    case 'Unmatched Trade Report':
    case 'Trade Report Failed (rejected)':
    case 'TO Create Rejected':
    case 'TO Modify Rejected':
    case 'TO Cancel Rejected':
    case 'TO Unsolicited Cancelled - Partial Fills':
    case 'Trade Request (OTC)': {
      // TODO: For other notification types, extract the relevant object (TO, Execution, etc.)
      // for the "Pending" row state rule.
      return false;
    }
    default:
      return false;
  }
}

// Determines if the given Notification row should have the "NoExecutedQuantity" row state.
export function NotificationNoExecutedQuantity(rowData: Notification | undefined): boolean {
  switch (rowData?.name) {
    case 'IO New': {
      // This is an IO_NEW. Use the investorOrder object to determine "NoExecutedQuantity" row state.
      return IONoExecutedQuantity(rowData.investorOrder as VisibleInvestorOrderInfoWithAllocationsFragment);
    }
    case 'IO Cancel Request':
    case 'IO Modify Request':
    case 'IO Failed':
    case 'IO Failed - Modify':
    case 'Capital Threshold Warning':
    case 'Failed Trade':
    case 'Failed Trade - Modify':
    case 'Unmatched Trade Report':
    case 'Trade Report Failed (rejected)':
    case 'TO Create Rejected':
    case 'TO Modify Rejected':
    case 'TO Cancel Rejected':
    case 'TO Unsolicited Cancelled - Partial Fills':
    case 'Trade Request (OTC)': {
      // TODO: For other notification types, extract the relevant object (TO, Execution, etc.)
      // for the "NoExecutedQuantity" row state rule.
      return false;
    }
    default:
      return false;
  }
}

// Determines if the given Notification row should have the "HasExecutedQuantity" row state.
export function NotificationHasExecutedQuantity(rowData: Notification | undefined): boolean {
  switch (rowData?.name) {
    case 'IO New': {
      // This is an IO_NEW. Use the investorOrder object to determine "HasExecutedQuantity" row state.
      return IOHasExecutedQuantity(rowData.investorOrder as VisibleInvestorOrderInfoWithAllocationsFragment);
    }
    case 'IO Cancel Request':
    case 'IO Modify Request':
    case 'IO Failed':
    case 'IO Failed - Modify':
    case 'Capital Threshold Warning':
    case 'Failed Trade':
    case 'Failed Trade - Modify':
    case 'Unmatched Trade Report':
    case 'Trade Report Failed (rejected)':
    case 'TO Create Rejected':
    case 'TO Modify Rejected':
    case 'TO Cancel Rejected':
    case 'TO Unsolicited Cancelled - Partial Fills':
    case 'Trade Request (OTC)': {
      // TODO: For other notification types, extract the relevant object (TO, Execution, etc.)
      // for the "HasExecutedQuantity" row state rule.
      return false;
    }
    default:
      return false;
  }
}

// Determines if the given Notification row should have the "Hazard" row state.
export function NotificationHazard(rowData: Notification | undefined): boolean {
  switch (rowData?.name) {
    // Fow now only set the "Hazard" row state for Failed Orders and Trades.
    case 'IO Failed':
    case 'IO Failed - Modify':
    case 'Failed Trade':
    case 'Failed Trade - Modify': {
      return true;
    }
    case 'IO Cancel Request':
    case 'IO Modify Request':
    case 'IO New':
    case 'Capital Threshold Warning':
    case 'Unmatched Trade Report':
    case 'Trade Report Failed (rejected)':
    case 'TO Create Rejected':
    case 'TO Modify Rejected':
    case 'TO Cancel Rejected':
    case 'TO Unsolicited Cancelled - Partial Fills':
    case 'Trade Request (OTC)':
    default:
      return false;
  }
}
