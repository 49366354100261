import { CommonPopoverActorSchema } from '@valstro/workspace-plugin-popover';
import { WindowResizeOrigin, emitCustomActorEvent } from '@valstro/workspace';
import { ReactActorComponentProps, ResizableWindowWrapper, useWorkspace } from '@valstro/workspace-react';
import { useCurrentPopoverDialog, useReactPopoverActorComponent } from './popover.hooks';
import { Actor, ClickEvent, CommonWindowActorSchema, internalWorkspace } from '@valstro/workspace';
import { useCallback, useEffect, useRef, useState } from 'react';

export const PopoverComponent: React.FC<ReactActorComponentProps<CommonPopoverActorSchema>> = ({ actor }) => {
  const evListenerRef = useRef<() => void | undefined>();
  const [popoverEl, setPopoverEl] = useState<HTMLDivElement | null>(null);
  const {
    renderedAlign,
    renderedSide,
    autoSizeHeight,
    autoSizeWidth,
    autoSizePaddingWidth,
    autoSizePaddingHeight,
    autoSizeTriggerResizePixelSensitivity,
    windowId,
    popoverId,
    isOpen,
    scaleFactor
  } = useCurrentPopoverDialog();
  const workspace = useWorkspace();
  const component = useReactPopoverActorComponent(actor);
  const compClassName = `popover-component side-${renderedSide} align-${renderedAlign}`;

  const setRef = useCallback((ref: HTMLDivElement) => {
    setPopoverEl(ref);
  }, []);

  useEffect(() => {
    async function init() {
      const actorDef = await actor.getDefinition();
      const { _eventsTransport } = internalWorkspace(workspace);

      const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as Element;
        if (popoverEl && !popoverEl.contains(target)) {
          emitCustomActorEvent<ClickEvent>(_eventsTransport, actorDef, {
            type: 'click',
            payload: {
              type: 'outside-component',
              x: event.clientX * scaleFactor,
              y: event.clientY * scaleFactor
            }
          }).catch(console.error);
        }
      };

      document.addEventListener('click', handleClickOutside);

      evListenerRef.current = () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }

    if (isOpen && popoverEl) {
      init().catch(console.error);
    } else {
      if (evListenerRef.current) {
        evListenerRef.current();
      }
    }

    return () => {
      if (evListenerRef.current) {
        evListenerRef.current();
      }
    };
  }, [actor, windowId, popoverId, workspace, isOpen, popoverEl, scaleFactor]);

  if (!renderedSide || !renderedAlign) {
    return null;
  }

  if (!autoSizeHeight && !autoSizeWidth) {
    return (
      <div ref={setRef} className={compClassName}>
        {component}
      </div>
    );
  }

  const origin: WindowResizeOrigin | undefined =
    renderedAlign === 'center' && renderedSide === 'bottom'
      ? 'top-center'
      : renderedAlign === 'center' && renderedSide === 'top'
        ? 'bottom-center'
        : renderedAlign === 'start' && renderedSide === 'top'
          ? 'bottom-left'
          : renderedAlign === 'start' && renderedSide === 'bottom'
            ? 'top-left'
            : renderedAlign === 'end' && renderedSide === 'top'
              ? 'top-right'
              : undefined;

  const direction =
    autoSizeHeight && autoSizeWidth
      ? 'both'
      : autoSizeHeight
        ? 'vertical'
        : autoSizeWidth
          ? 'horizontal'
          : 'both';

  return (
    <ResizableWindowWrapper
      ref={setRef}
      className={compClassName}
      paddingWidth={autoSizePaddingWidth}
      paddingHeight={autoSizePaddingHeight}
      origin={origin}
      direction={direction}
      // TODO: fix this type. For whatever reason, the type is not being inferred correctly. We probably need to coerce the type
      windowActor={actor as unknown as Actor<CommonWindowActorSchema>}
      triggerResizePixelSensitivity={autoSizeTriggerResizePixelSensitivity}
      scaleFactor={scaleFactor}
    >
      {component}
    </ResizableWindowWrapper>
  );
};
