import type { DepartmentTypes, DeskTypes } from '@oms/generated/frontend';
import { cleanMaybe, compactMap } from '@oms/shared/util';
import type { Optional, Valued } from '@oms/shared/util-types';
import { BaseUserDefaultsObject } from './types';
import type {
  UserAggregationUnit,
  UserOrderTag,
  UserTradingEntity,
  UserCountry,
  ExtendedUserDefaultsObject,
  UserAccount
} from './types';

export class UserDefaults
  implements BaseUserDefaultsObject, ExtendedUserDefaultsObject, Valued<BaseUserDefaultsObject>
{
  readonly #userDefaults: BaseUserDefaultsObject;

  // 🏗️ Constructor ------------------------------------------------------- /

  public constructor(userDefaults: BaseUserDefaultsObject) {
    this.#userDefaults = userDefaults;
  }

  // Conversion ------------------------------------------------------- /

  public get value(): BaseUserDefaultsObject {
    return this.#userDefaults;
  }

  public toObject(): BaseUserDefaultsObject {
    return this.value;
  }

  public toExtendedObject(): ExtendedUserDefaultsObject {
    return {
      __typename: this.__typename,
      id: this.id,
      departmentType: this.departmentType,
      receivingDeskType: this.receivingDeskType,
      aggregationUnit: this.aggregationUnit,
      orderTags: this.orderTags,
      tradingEntity: this.tradingEntity,
      country: this.country,
      firmAccount: this.firmAccount,
      intermediaryAccount: this.intermediaryAccount
    };
  }

  // 😐 Base ------------------------------------------------------- /

  public get __typename() {
    return cleanMaybe(this.#userDefaults.__typename, 'UserDefaults');
  }

  public get id(): string {
    return this.#userDefaults.id;
  }

  public get departmentType(): Optional<DepartmentTypes> {
    return cleanMaybe(this.#userDefaults.departmentType);
  }

  public get receivingDeskType(): Optional<DeskTypes> {
    return cleanMaybe(this.#userDefaults.receivingDeskType);
  }

  public get aggregationUnit(): Optional<UserAggregationUnit> {
    return cleanMaybe(this.#userDefaults.aggregationUnit);
  }

  public get orderTags(): UserOrderTag[] {
    return compactMap(cleanMaybe(this.#userDefaults.orderTags, []), (tag) => tag);
  }

  public get tradingEntity(): Optional<UserTradingEntity> {
    return cleanMaybe(this.#userDefaults.tradingEntity);
  }

  public get country(): Optional<UserCountry> {
    return cleanMaybe(this.#userDefaults.country);
  }

  public get firmAccount(): Optional<UserAccount> {
    return cleanMaybe(this.#userDefaults.firmAccount);
  }

  public get intermediaryAccount(): Optional<UserAccount> {
    return cleanMaybe(this.#userDefaults.intermediaryAccount);
  }

  // 🧊 Static ------------------------------------------------------- /

  public static fromPartial(partialUserDefaults?: Partial<BaseUserDefaultsObject>): UserDefaults {
    if (partialUserDefaults && partialUserDefaults instanceof UserDefaults) return partialUserDefaults;
    const { id = '', ...rest } = partialUserDefaults ?? {};
    const userDefaults: BaseUserDefaultsObject = { id, ...rest };
    return new UserDefaults(userDefaults);
  }

  public static extend(baseUserDefaults?: Partial<BaseUserDefaultsObject>): ExtendedUserDefaultsObject {
    return UserDefaults.fromPartial(baseUserDefaults).toExtendedObject();
  }
}

export default UserDefaults;
