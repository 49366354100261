import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

export const TEXT_RENDERER = 'textRenderer';

/**
 * standard text renderer
 */
export const TextRenderer: React.FC<ICellRendererParams> = (params) => {
  return <span>{params.valueFormatted || params.value}</span>;
};
