import { parseJSON } from '@oms/shared/util';
import type { AuthClientState } from './keycloak.types';
import { DEFAULT_AUTH_STATE, PERSISTED_AUTH_STATE_KEY } from './auth.contracts';

export function persistAuthState(authState: AuthClientState) {
  if (localStorage) {
    localStorage.setItem(PERSISTED_AUTH_STATE_KEY, JSON.stringify(authState));
  }
}

export function getPersistedAuthState(): AuthClientState {
  if (localStorage) {
    const str = localStorage.getItem(PERSISTED_AUTH_STATE_KEY);
    const parsed = str ? parseJSON<AuthClientState>(str) : DEFAULT_AUTH_STATE;
    return parsed || DEFAULT_AUTH_STATE;
  }

  return DEFAULT_AUTH_STATE;
}

export function hasTokenExpired(expiry: null | number) {
  if (!expiry) {
    return true;
  }

  const now = new Date().getTime() / 1000;
  return now > expiry;
}

export function hasTokenNearlyExpired(expiry: null | number) {
  if (!expiry) {
    return true;
  }
  const nearlyExpired = expiry - 1000 * 60;
  const now = new Date().getTime() / 1000;
  return now > nearlyExpired;
}

export function getValidPersistedAuthState(): AuthClientState {
  const authState = getPersistedAuthState();
  const isExpired = hasTokenExpired(authState.expiry);
  return !isExpired ? authState : DEFAULT_AUTH_STATE;
}
