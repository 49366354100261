import React, { useEffect } from 'react';
import type { FORM_COMPONENT_TYPE } from '../../../../forms/form-builder/common/form.contracts';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import type { FieldProps, ICommonTextField } from '@oms/frontend-foundation';
import type { InvestorOrderInfoFragment } from '@oms/generated/frontend';
import type { PrincipalFillFormValues } from '../principal-fill.form-contract';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { BehaviorSubject } from 'rxjs';
import { convertToNumber } from '@oms/shared/util';
import { principalFillColumnLibrary } from './principal-fill-column-library';
import { useEnhancedFormApi } from '@oms/frontend-foundation';

export interface IPrincipalFillGrid<TValidator = Validator>
  extends ICommonTextField<
    typeof FORM_COMPONENT_TYPE.PRINCIPAL_FILL,
    InvestorOrderInfoFragment[],
    TValidator
  > {}

export type PrincipalFillGridRow = Partial<InvestorOrderInfoFragment> & { fillQuantity: number };

const gridState$ = new BehaviorSubject<PrincipalFillGridRow[]>([]);

export const PrincipalFillGrid: React.FC<FieldProps<IPrincipalFillGrid>> = () => {
  const formApi = useEnhancedFormApi<PrincipalFillFormValues>();

  const updateGridState = (formValues: PrincipalFillFormValues) => {
    const state =
      formValues.investorOrders?.map((order) => {
        return {
          ...order,
          fillQuantity: convertToNumber(formValues.quantity)
        };
      }) || [];

    gridState$.next(state);
  };

  useEffect(() => {
    formApi.get$({ values: true }).subscribe(({ values }) => {
      updateGridState(values);
    });
  }, [formApi]);

  const gridProps = useVGrid<PrincipalFillGridRow>(
    'principal-fill',
    (builder) =>
      builder
        .columnLibrary(principalFillColumnLibrary)
        .datasource((d) => d.source(gridState$).rowId((r) => r.data.id || '')),
    []
  );

  return <VGrid {...gridProps} />;
};

export interface CurrenciesGridPropsWidgetComponentProps {}
