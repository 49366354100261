import { useEffect, useMemo, useState } from 'react';
import type { DeepReadonly, RxCollection, RxDocument } from 'rxdb-v15';
import { MemoryDatabaseCollections, OfflineDatabaseCollections } from '@app/data-access/offline/collections';
import { useOfflineDatabase } from '@app/data-access/offline/offline-database';
import { useMemoryDatabase } from '@app/data-access/offline/memory-database';
import type { QueryConstructor } from 'rxdb-hooks-v5';

export function useOfflineCollection(collectionKey: keyof OfflineDatabaseCollections) {
  const db = useOfflineDatabase();
  return useMemo(() => db.collections[collectionKey], [db, collectionKey]);
}

export function useMemoryCollection(collectionKey: keyof MemoryDatabaseCollections) {
  const db = useMemoryDatabase();
  db.collections.snapshots.find({});
  return useMemo(() => db.collections[collectionKey], [db, collectionKey]);
}

export function useRxData<Doc, K extends string>(
  collectionKey: K,
  queryConstructor: QueryConstructor<Doc>,
  collections: Record<K, RxCollection<any>>
) {
  const [documents, setDocuments] = useState<RxDocument<Doc>[] | undefined>();
  const [data, setData] = useState<DeepReadonly<Doc>[] | undefined>();
  const [isFetching, setIsFetching] = useState(true);

  const collection = useMemo(() => collections[collectionKey], [collections, collectionKey]);

  if (!collection) {
    throw new Error(`Collection ${collectionKey} not found`);
  }

  const query = useMemo(() => {
    // Fixes mixed declaration types from both libraries
    //@ts-ignore
    return queryConstructor(collection);
  }, [queryConstructor, collection]);

  useEffect(() => {
    const q = query ? query : collection.find();
    const subscription = (q.$.subscribe as any)((result: RxDocument<Doc> | RxDocument<Doc>[] | undefined) => {
      setIsFetching(false);
      if (Array.isArray(result)) {
        setDocuments(result);
        setData(result.map((doc) => doc.toJSON()));
      } else {
        setDocuments(result ? [result] : []);
        setData(result ? [result.toJSON()] : []);
      }
    });

    return () => subscription.unsubscribe();
  }, [collections]);

  return { documents, isFetching, data, collection };
}

export function useRxDocument<Doc, K extends string>(
  collectionKey: K,
  queryConstructor: QueryConstructor<Doc>,
  collections: Record<K, RxCollection<any>>
) {
  const { documents, isFetching, collection } = useRxData(collectionKey, queryConstructor, collections);

  return useMemo(() => {
    if (!documents || documents.length === 0) {
      return { document: undefined, data: undefined, isFetching };
    }

    const document = documents[0];
    return {
      document,
      data: document?.toJSON(),
      isFetching,
      collection
    };
  }, [documents, isFetching, collection]);
}

export function useOfflineRxData<
  Doc,
  K extends keyof OfflineDatabaseCollections = keyof OfflineDatabaseCollections
>(collectionKey: K, queryConstructor: QueryConstructor<Doc>) {
  const db = useOfflineDatabase();
  return useRxData(collectionKey, queryConstructor, db.collections);
}

export function useMemoryRxData<
  Doc,
  K extends keyof MemoryDatabaseCollections = keyof MemoryDatabaseCollections
>(collectionKey: K, queryConstructor: QueryConstructor<Doc>) {
  const db = useMemoryDatabase();
  return useRxData(collectionKey, queryConstructor, db.collections);
}

export function useOfflineRxDocument<
  Doc,
  K extends keyof OfflineDatabaseCollections = keyof OfflineDatabaseCollections
>(collectionKey: K, queryConstructor: QueryConstructor<Doc>) {
  const db = useOfflineDatabase();
  return useRxDocument(collectionKey, queryConstructor, db.collections);
}

export function useMemoryRxDocument<
  Doc,
  K extends keyof MemoryDatabaseCollections = keyof MemoryDatabaseCollections
>(collectionKey: K, queryConstructor: QueryConstructor<Doc>) {
  const db = useMemoryDatabase();
  return useRxDocument(collectionKey, queryConstructor, db.collections);
}
