import { FieldContract } from '@oms/frontend-foundation';
import { TagTypeEnum } from '@oms/generated/frontend';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { createTagsField } from '@app/forms/common/fields/tags-field/tags.common';
import type { BulkRepairTradeFieldContract } from './bulk-repair-trade.form-common';
import { createTradeCounterpartyField } from '@app/forms/common/fields/trade-counterparty-field/trade-counterparty-field.common';

const fc = FieldContract.create<BulkRepairTradeFieldContract, AdditionalFormFieldUnion>();

// ----------- Hidden fields --------------------------------------
// ----------------------------------------------------------------
export const hiddenFormInfo = fc.field('hiddenFormInfo', 'hidden-field');

// ----------- All fields -----------------------------------------
// ----------------------------------------------------------------

export const tradeCounterparty = createTradeCounterpartyField(fc, 'tradeCounterparty', {
  label: 'Trade counterparty'
});

export const tradeTags = createTagsField(fc, 'tradeTags', {
  label: 'Trade tags',
  queryProps: {
    filters: [TagTypeEnum.Trade]
  }
});
