import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { useFieldApi } from '../../helpers';
import type { FieldProps } from '../../types';
import { PriceInput as PriceInputComponent } from '@oms/shared-frontend/ui-design-system';
import type { IPriceInputField } from './price-input.type';
import { useCallback, useRef, useEffect } from 'react';

export const PriceInput: React.FC<FieldProps<IPriceInputField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onChange, value, onFocus, onBlur },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    hideFormControls,
    style,
    selectAllOnFocus = true,
    autoFocus,
    sx,
    wrapperSx = {},
    initialValue,
    placeholder,
    shortcuts
  } = useFieldApi(props);

  const ref = useRef<HTMLInputElement>(null);

  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const onInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus]
  );

  const onValueChange = useCallback(
    (value: string | number) => {
      onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    if (selectAllOnFocus && autoFocus) {
      ref?.current?.select();
    }
  }, [autoFocus, selectAllOnFocus]);

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      helperText={helperText}
      hideFormControls={hideFormControls}
      isPrimaryField={isPrimaryField}
      isVisible={isVisible}
      sx={wrapperSx}
    >
      <PriceInputComponent
        ref={ref}
        placeholder={placeholder}
        name={name}
        onValueChange={onValueChange}
        value={value || initialValue}
        style={style}
        sx={sx}
        shortcuts={shortcuts}
        onBlur={onBlur}
        onFocus={onInputFocus}
      />
    </FieldWrapper>
  );
};
