import type { Field, UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import type { EnhancedFormOptions } from '@oms/frontend-foundation';
import { RepairCategory } from '@oms/generated/frontend';
import type { InvestorOrderEntryValues } from '../investor-order-entry.form-contract';

export const IO_RUNWAY_DISABLE_ACTION = 'ioRunwayDisableAction';

export type IORunwayDisable = { checkParentTradingOrder?: boolean };

export const ioRunwayDisableAction =
  (opts: IORunwayDisable | undefined) =>
  (
    _field: Field,
    _fieldApi: UseFieldApiConfig,
    formOptions: EnhancedFormOptions<InvestorOrderEntryValues>
  ) => {
    const { checkParentTradingOrder = false } = opts || {};
    const { getState } = formOptions;
    const { values } = getState();
    const disabledForType =
      values.hiddenFormInfo?.type === 'update' ||
      (values.hiddenFormInfo?.type === 'repair' &&
        values.hiddenFormInfo.repairIO.category === RepairCategory.IoModify);
    const isDisabled = disabledForType && (checkParentTradingOrder ? !!values.parentTradingOrderId : true);
    return {
      isDisabled
    };
  };
