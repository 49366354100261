import { isEqual } from 'lodash';
import React from 'react';
import type { FC } from 'react';
import * as styles from './slider.css';
import { Box } from '@oms/shared-frontend/ui-design-system';
import type { SliderProps } from '../../fixatdl-form.types';
import { TextField } from '@oms/frontend-foundation';
import type { FieldProps } from '@oms/frontend-foundation';

export const Slider: FC<FieldProps<SliderProps>> = React.memo(({ increment, labels, ...props }) => {
  const innerLabelWidth = labels ? 1 / (labels.length === 1 ? 1 : labels.length - 1) : 1;

  const getLabelWidth = (index: number) => {
    if (!labels) {
      return;
    }

    return index === 0 || index === labels.length - 1 ? innerLabelWidth / 2 : innerLabelWidth;
  };

  const getLabelContentPosition = (index: number) => {
    if (!labels) {
      return;
    }

    if (index === 0) {
      return 'flex-start';
    }

    if (index === labels.length - 1) {
      return 'flex-end';
    }

    return 'center';
  };

  return (
    <Box sx={{ marginBottom: 4 }}>
      <TextField
        {...props}
        type="range"
        step={increment}
        list="values"
        style={{ appearance: 'auto', padding: 0 }}
        component="text-field"
      />

      {labels && (
        <>
          <datalist id="values" className={styles.datalist}>
            {labels.map((label, index) => {
              return (
                <option key={'option-' + label || index} value={index * (100 / (labels.length - 1))}></option>
              );
            })}
          </datalist>
          <Box style={{ display: 'flex' }} sx={{ marginTop: '-2' }}>
            {labels.map((label, index) => {
              return (
                <Box
                  key={'label-' + label || index}
                  sx={{
                    display: 'flex',
                    justifyContent: getLabelContentPosition(index)
                  }}
                  style={{
                    flex: getLabelWidth(index)
                  }}
                  data-testid="label"
                >
                  {label}
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
}, isEqual);
