import { useService } from '@oms/frontend-foundation';
import { InstrumentFollowSubject } from './instrument-follow.subject';
import type { InstrumentFollowEvent } from './instrument-follow.contracts';
import { useEffect } from 'react';
import { debounceTime, filter } from 'rxjs';

export const useInstrumentFollowSubject$ = () => useService(InstrumentFollowSubject);

export const useOnInstrumentFollowEvent = (
  callback: (event: InstrumentFollowEvent) => void,
  ...sourceTypes: InstrumentFollowEvent['sourceType'][]
) => {
  const subject$ = useInstrumentFollowSubject$();

  useEffect(() => {
    const subscription = subject$
      .pipe(
        filter((v) => sourceTypes.length === 0 || sourceTypes.includes(v.sourceType)),
        debounceTime(250)
      )
      .subscribe(callback);

    return () => {
      subscription.unsubscribe();
    };
  }, [subject$, callback, ...sourceTypes]);
};
