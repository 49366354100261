import {
  VALIDATE_ROUTE_ORDER_MINIMAL_QUANTITY,
  VALIDATE_ROUTE_ORDER_QUANTITY,
  validateRouteOrderMinimalQuantity,
  validateRouteOrderQuantity
} from '@app/widgets/trading/route-order/validators/route-order-quantity-field.validators';
import {
  VALIDATE_ROUTE_ORDER_LIMIT_PRICE,
  VALIDATE_ROUTE_ORDER_PRICE,
  validateRouteOrderLimitPrice,
  validateRouteOrderPrice
} from '@app/widgets/trading/route-order/validators/route-order-price-field.validators';
import {
  VALIDATE_ROUTE_ORDER_STRATEGY,
  validateRouteOrderStrategy
} from './route-order-strategy-field.validators';

export const ROUTE_ORDER_VALIDATOR_MAPPER = {
  [VALIDATE_ROUTE_ORDER_PRICE]: validateRouteOrderPrice,
  [VALIDATE_ROUTE_ORDER_LIMIT_PRICE]: validateRouteOrderLimitPrice,
  [VALIDATE_ROUTE_ORDER_QUANTITY]: validateRouteOrderQuantity,
  [VALIDATE_ROUTE_ORDER_MINIMAL_QUANTITY]: validateRouteOrderMinimalQuantity,
  [VALIDATE_ROUTE_ORDER_STRATEGY]: validateRouteOrderStrategy
};
