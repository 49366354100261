import { AnyInstance } from './types';
import { IS_PROXY, isWritable } from './utils';

export type ObservableEmitFn = (path: string[], value: any, previousValue: any, parentValues?: any[]) => void;

export type Observable<T, K extends keyof T = never> = Pick<T, Exclude<keyof T, K>>;

/**
 * Creates an observable object which emits changes when a property is set/deleted
 *
 * @param obj - Any object
 * @param emit - Callback function to emit changes
 * @param path - Path to the property
 * @param parentValues - Parent values
 * @returns Observable<T> - Observable object
 */
export function createObservable<T extends AnyInstance, K extends keyof T>(
  obj: T,
  emit: ObservableEmitFn,
  ignoreProperties: K[] = [],
  path: string[] = [],
  parentValues: any[] = []
) {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // Return if it's not an object
  }

  parentValues.push(obj);

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (!isWritable(obj, i.toString())) {
        continue;
      }

      obj[i] = createObservable(obj[i], emit, ignoreProperties, path.concat([i.toString()]), [
        ...parentValues
      ]);
    }
  } else {
    for (const key in obj) {
      if (!isWritable(obj, key)) {
        continue;
      }

      if (path.length === 0 && ignoreProperties.includes(key as unknown as K)) {
        continue;
      }

      // TODO: fix this "any"
      const value = obj[key];
      (obj as any)[key] = createObservable(value, emit, ignoreProperties, path.concat([key]), [
        ...parentValues
      ]);
    }
  }

  return new Proxy(obj, {
    get(target, key) {
      if (key === IS_PROXY) {
        return true;
      }
      return Reflect.get(target, key);
    },
    set(target, key, value) {
      if (!isWritable(target, key.toString())) {
        return false;
      }

      const keyStr = key.toString();
      const previousValue = target[keyStr];
      const nextPath = path.concat([keyStr]);

      if (typeof value === 'object') {
        value = createObservable(value, emit, ignoreProperties, nextPath);
      }

      target[keyStr as keyof T] = value;

      // If first level property is inc. in ignoreProperties, don't emit
      if (
        (path.length === 0 && ignoreProperties.includes(keyStr as K)) ||
        ignoreProperties.includes(path[0] as K)
      ) {
        return true;
      }

      emit(nextPath, value, previousValue, parentValues);
      return true;
    },
    deleteProperty(target, key) {
      if (Reflect.has(target, key)) {
        const previousValue = Reflect.get(target, key);
        const result = Reflect.deleteProperty(target, key);
        if (result) {
          emit(path.concat([key.toString()]), undefined, previousValue, parentValues);
        }
        return result;
      }
      return false;
    }
  }) as Observable<T, K>;
}
