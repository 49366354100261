import clsx from 'clsx';
import * as styles from './css/badge.css';
import type { BadgeVariants } from './css/badge.css';
import { Sprinkles } from '../../system/sprinkles.css';
import { Box, BoxProps } from '../../system/components/box/box';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { __DEV__ } from '../../system/utils/assertion';

export type BadgeProps = {
  fontSize?: Sprinkles['fontSize'];
} & BadgeVariants &
  BoxProps;

export const Badge = polymorphicComponent<'div', BadgeProps>((props, ref) => {
  const {
    className,
    as = 'span',
    shape,
    fontSize,
    palette = 'neutral',
    emphasis = 'high',
    sx = {},
    ...rest
  } = props;
  return (
    <Box
      as={as}
      ref={ref}
      className={clsx(styles.badgeRecipe({ shape, palette, emphasis }), className)}
      sx={{
        fontSize,
        ...sx
      }}
      {...rest}
    />
  );
});

if (__DEV__) {
  Badge.displayName = 'Badge';
}
