import { FC } from 'react';
import { FormatType } from '@oms/shared/util';
import type { Valued } from '@oms/shared/util-types';
import { Flex, FlexProps } from '../../layout/flex/flex';
import { Text } from '../../layout/text/text';
import { DISPLAY_GRID_CUSTOM_COMPONENT_TYPE } from '../display-grid/display-grid';
import { Numeric } from '../display-field/components/numeric/numeric';
import { DISPLAY_FIELD_COMPONENT_TYPE } from '../display-field/display-field.contracts';

export type DailyStatItem = Valued<string | number> & { format?: FormatType };

export type DailyStatsProps = {
  type?: typeof DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.DailyStats;
  label?: string;
  items: DailyStatItem[];
  sx?: FlexProps['sx'];
};

export const DailyStats: FC<DailyStatsProps> = ({ items, label, sx }) => {
  return (
    <Flex sx={sx}>
      {label && <Text>{label}</Text>}
      <Flex justify="flex-end" sx={{ flex: 1 }}>
        {items.map((item, index) => {
          const isFirstItem = index === 0;
          return (
            <Flex
              style={{
                width: '5.5rem',
                textOverflow: 'ellipsis',
                ...(isFirstItem ? { textAlign: 'right' } : null)
              }}
              key={`${item.value}-${index}`}
            >
              <Numeric
                type={DISPLAY_FIELD_COMPONENT_TYPE.Numeric}
                textProps={{ sx: { marginRight: 2 } }}
                format={item.format}
                value={item.value}
              />
              {isFirstItem && items.length > 1 && <Text style={{ marginRight: '4px' }}>@</Text>}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
