import {
  CellRendererSelectorResult,
  ColDef,
  ICellRendererParams,
  IDetailCellRendererParams
} from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';
import { GridReadyEvent } from '@ag-grid-community/core';
import isFunction from 'lodash/isFunction';
import { ColumnBuilder } from './column.builder';
import type { ColumnBuilderCallback, ColumnLibrary } from './grid.builder.types';
import { AnyRecord } from '@oms/frontend-foundation';
import { emptyGroupRenderer } from '../renderers/cell-renderers/empty-group-renderer';

export class MasterDetailBuilder<TData extends AnyRecord, TMasterDetail extends AnyRecord> {
  private _onGridReady: (e: GridReadyEvent<TData>) => void = () => {};
  private _masterDetailGridSettings: AgGridReactProps<TData> = {
    masterDetail: true,

    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: [],
        suppressColumnVirtualisation: true
      }
    }
  };

  private _groupByColumnId: string = '';
  private _groupByColDefOverrides: ColDef<TData> = {};

  renderer(
    renderer: AgGridReactProps<TData>['detailCellRenderer']
  ): MasterDetailBuilder<TData, TMasterDetail> {
    this._masterDetailGridSettings.detailCellRenderer = renderer;
    return this;
  }

  height(height: AgGridReactProps<TData>['detailRowHeight']): MasterDetailBuilder<TData, TMasterDetail> {
    this._masterDetailGridSettings.detailRowHeight = height;
    return this;
  }

  getRowHeight(
    getRowHeight: Required<AgGridReactProps<TData>>['getRowHeight']
  ): MasterDetailBuilder<TData, TMasterDetail> {
    this._masterDetailGridSettings.getRowHeight = (params) => {
      const isDetailRow = params.node.detail;

      // for all rows that are not detail rows, return nothing
      if (!isDetailRow) {
        return undefined;
      }

      return getRowHeight(params);
    };
    return this;
  }

  isRowMaster(
    isRowMaster: AgGridReactProps<TData>['isRowMaster']
  ): MasterDetailBuilder<TData, TMasterDetail> {
    this._masterDetailGridSettings.isRowMaster = isRowMaster;
    return this;
  }

  detailRowAutoHeight(): MasterDetailBuilder<TData, TMasterDetail> {
    this._masterDetailGridSettings.detailRowAutoHeight = true;
    return this;
  }

  shouldShowRowGroup(
    shouldShowRowGroup: (data: TData) => boolean
  ): MasterDetailBuilder<TData, TMasterDetail> {
    this._groupByColDefOverrides = {
      cellRenderer: undefined,
      cellRendererSelector: (params: ICellRendererParams<any>) => {
        const shouldShow = shouldShowRowGroup(params.data);
        if (!shouldShow) {
          return {
            component: emptyGroupRenderer
          } as CellRendererSelectorResult;
        }
        return {
          component: 'agGroupCellRenderer'
        } as CellRendererSelectorResult;
      }
    };
    return this;
  }

  colId(colId: string): MasterDetailBuilder<TData, TMasterDetail> {
    this._groupByColumnId = colId;
    this._groupByColDefOverrides = {
      cellRenderer: 'agGroupCellRenderer',
      ...this._groupByColDefOverrides
    };
    return this;
  }

  public params(
    params: Partial<IDetailCellRendererParams<TData, TMasterDetail>>
  ): MasterDetailBuilder<TData, TMasterDetail> {
    const currentParams = this._masterDetailGridSettings.detailCellRendererParams;
    this._masterDetailGridSettings.detailCellRendererParams = {
      ...currentParams,
      ...params,
      detailGridOptions: {
        ...currentParams.detailGridOptions,
        ...params.detailGridOptions
      }
    };
    return this;
  }

  public column(
    arg: ColumnBuilderCallback<TMasterDetail>,
    builder?: ColumnBuilder<TMasterDetail>
  ): MasterDetailBuilder<TData, TMasterDetail> {
    const columnDef = this.createColDef(arg, builder);
    this._masterDetailGridSettings.detailCellRendererParams.detailGridOptions.columnDefs.push(columnDef);

    return this;
  }

  public columns(
    ...builders: ColumnBuilderCallback<TMasterDetail>[]
  ): MasterDetailBuilder<TData, TMasterDetail> {
    builders.forEach((b) => {
      this.column(b);
    });
    return this;
  }

  public defaultColumn(
    arg: ColumnBuilderCallback<TMasterDetail>,
    builder?: ColumnBuilder<TMasterDetail>
  ): MasterDetailBuilder<TData, TMasterDetail> {
    const columnDef: ColDef<TMasterDetail> = this.createColDef(arg, builder);
    this._masterDetailGridSettings.detailCellRendererParams.detailGridOptions.defaultColDef = {
      ...columnDef
    };

    return this;
  }

  public columnLibrary({
    defaultColumn,
    columns
  }: ColumnLibrary<TMasterDetail>): MasterDetailBuilder<TData, TMasterDetail> {
    if (defaultColumn) {
      this.defaultColumn(defaultColumn);
    }
    this.columns(...columns);
    return this;
  }

  public refreshStrategy(
    value: IDetailCellRendererParams['refreshStrategy']
  ): MasterDetailBuilder<TData, TMasterDetail> {
    this._masterDetailGridSettings.detailCellRendererParams.refreshStrategy = value;
    return this;
  }

  public getDetailRowData(
    cb: IDetailCellRendererParams<TData, TMasterDetail>['getDetailRowData']
  ): MasterDetailBuilder<TData, TMasterDetail> {
    this._masterDetailGridSettings.detailCellRendererParams.getDetailRowData = cb;
    return this;
  }

  public onGridReady(cb: (e: GridReadyEvent<TData>) => void): MasterDetailBuilder<TData, TMasterDetail> {
    this._onGridReady = cb;
    return this;
  }

  public build() {
    if (this._groupByColumnId === '') {
      throw new Error('colId is required');
    }

    // Run popups in the body & call the onGridReady callback if provided
    this._masterDetailGridSettings.detailCellRendererParams.detailGridOptions.onGridReady = (
      e: GridReadyEvent<TData>
    ) => {
      const body = document.querySelector('body') as HTMLElement;
      e.api.setPopupParent(body);
      if (this._onGridReady) {
        this._onGridReady(e);
      }
    };

    return {
      masterDetailGridSettings: this._masterDetailGridSettings,
      groupByColumnId: this._groupByColumnId,
      groupByColDefOverrides: this._groupByColDefOverrides
    };
  }

  private createColDef(
    arg: ColumnBuilderCallback<TMasterDetail>,
    builder?: ColumnBuilder<TMasterDetail>
  ): ColDef<TMasterDetail> {
    const builderResult: ColumnBuilder<TMasterDetail> | ColumnBuilder<TMasterDetail> = isFunction(arg)
      ? arg(builder || new ColumnBuilder<TMasterDetail>())
      : ColumnBuilder.of<TMasterDetail>({
          ...(builder ? builder.build() : {})
        } as ColDef<TMasterDetail>);

    return builderResult.build();
  }
}
