import { InvestorOrderExecutionsService } from '@app/data-access/services/trading/investor-orders/investor-order-executions.service';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { cancelExecutionsFormContract } from './cancel-executions.form-contract';
import type {
  CancelExecutionsFormInput,
  CancelExecutionsFormContractType,
  CancelExecutionsFormValues,
  CancelExecutionsFormOutput
} from './cancel-executions.form-contract';
import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

export const cancelExecutionsFormBuilder = FormBuilder.create<
  CancelExecutionsFormInput,
  CancelExecutionsFormOutput
>('cancel-exections-form')
  .contract<CancelExecutionsFormContractType>(cancelExecutionsFormContract)
  .type('cancelExecutions')
  .sanitizer((s) =>
    s
      .input(async function sanitize(input, { container }) {
        if (!input.orderId) {
          throw new Error('Can not reject an investor order without an id');
        }

        const ioService = container.resolve(InvestorOrdersService);
        const service = container.resolve(InvestorOrderExecutionsService);
        const authService = container.resolve(AuthService);
        const userId = authService.getUserId();

        if (!userId) {
          throw new Error('Could not determine user ID');
        }

        const orderInfo = await ioService.getById(input.orderId);

        const instrumentId = orderInfo.isSuccess()
          ? orderInfo.value?.data?.visibleInvestorOrder?.instrument?.id
          : undefined;

        const defaultAccounts =
          userId || instrumentId ? await service.getDefaultAccounts(userId, instrumentId) : undefined;

        const formValues: CancelExecutionsFormValues = {
          orderId: input.orderId,
          executionIds: input.executionIds,
          tradingOrderIntermediaryAccountId: defaultAccounts?.intermediaryAccount?.id
            ? {
                id: defaultAccounts.intermediaryAccount.id
              }
            : undefined,
          investorOrderAccountId: defaultAccounts?.firmAccount?.id
            ? {
                id: defaultAccounts.firmAccount.id
              }
            : undefined
        };

        return formValues;
      })
      .output(function sanitize(formValues) {
        if (
          !formValues.orderId ||
          !formValues.executionIds?.length ||
          !formValues.investorOrderAccountId?.id ||
          !formValues.tradingOrderIntermediaryAccountId?.id
        ) {
          return;
        }

        const output: CancelExecutionsFormOutput = {
          orderId: formValues.orderId,
          executionIds: formValues.executionIds,
          investorOrderAccountId: formValues.investorOrderAccountId?.id,
          tradingOrderIntermediaryAccountId: formValues.tradingOrderIntermediaryAccountId?.id
        };

        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(InvestorOrderExecutionsService);

        return await service.cancelExecutions(event.payload.output);
      }
    }
  });

export type CancelExecutionsFormBuilderType = typeof cancelExecutionsFormBuilder;

export default cancelExecutionsFormBuilder;
