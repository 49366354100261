import type { ScaleRateType } from '@oms/generated/frontend';
import { scaleRateTypeMapping } from './order-enums';

export const mapScaleRateType = (
  value?: ScaleRateType | string | null,
  defaultValue: string = value || ''
): string => {
  return value && scaleRateTypeMapping[value as ScaleRateType]
    ? scaleRateTypeMapping[value as ScaleRateType]
    : defaultValue;
};
