import type { SliderControl } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { getListItems } from './control.transformer.types';
import type { ControlTransformer } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const sliderControl: ControlTransformer<SliderControl> = (sliderControl, _query, formValues) => {
  return {
    component: fixatdlFormComponentTypes.SLIDER,
    name: sliderControl.id,
    label: sliderControl.label,
    initialValue: AtdlFormats.coerceValue(formValues[sliderControl.id]),
    options: getListItems(sliderControl.listItems)
  };
};
