import type { SingleSelectListControl } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { getListItems } from './control.transformer.types';
import type { ControlTransformer } from './control.transformer.types';
import { AtdlFormats } from '@valstro/fixatdl-core';

export const singleSelectListControl: ControlTransformer<SingleSelectListControl> = (
  singleSelectListControl,
  _query,
  formValues
) => {
  return {
    component: fixatdlFormComponentTypes.SINGLE_SELECT_LIST,
    name: singleSelectListControl.id,
    label: singleSelectListControl.label,
    initialValue: AtdlFormats.coerceValue(formValues[singleSelectListControl.id]),
    options: getListItems(singleSelectListControl.listItems)
  };
};
