import { CustomGraphQLError } from './custom-error';
import { ErrorTypeEnum } from './errors.types';

export type IGeneralError = CustomGraphQLError<ErrorTypeEnum.GENERAL_ERROR, string>;

export class GeneralError extends CustomGraphQLError<ErrorTypeEnum.GENERAL_ERROR, string> {
  constructor(message: string, code?: string) {
    super(message);
    this.extensions = {
      type: ErrorTypeEnum.GENERAL_ERROR,
      code: code || ErrorTypeEnum.GENERAL_ERROR,
      data: message
    };
  }
}
