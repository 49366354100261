import { useState } from 'react';
import { ConfirmationDialogDisplay } from '@app/components/dialogs/confirmation/confirmation.dialog.display.component';
import { useCurrentDialogApi, DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import type { ConfirmationDialogProps } from './confirmation.dialog.config';

export const ConfirmationDialogComponent: React.FC = () => {
  const [props, api] = useCurrentDialogApi<ConfirmationDialogProps>();
  const { autoClose = true, hideCancelButton = false, ...rest } = props;
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const close = () => {
    if (autoClose) {
      api.close().catch((e) => {
        console.error(e);
        setIsDisabled(false);
      });
    }
  };

  const onConfirm = () => {
    api.emit({
      type: DIALOG_EVENT_TYPE.OK
    });

    setIsDisabled(true);
    close();
  };

  const onCancel = () => {
    api.emit({
      type: DIALOG_EVENT_TYPE.CANCEL
    });

    setIsDisabled(true);
    close();
  };

  return (
    <ConfirmationDialogDisplay
      {...rest}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isConfirmDisabled={isDisabled}
      isCancelDisabled={isDisabled}
      hideCancelButton={hideCancelButton}
    />
  );
};

export default ConfirmationDialogComponent;
