import { AssertMutuallyAssignable, Expect } from '@oms/frontend-schemas';
import { z } from 'zod';
import { createVersionedSchema } from '@oms/frontend-schemas';
import type {
  WorkspaceAuthWindowActorDef,
  WorkspaceCommandPaletteWindowActorDef,
  WorkspaceFlexLayoutActorDef,
  WorkspaceWidgetActorDef,
  WorkspaceWindowActorDef
} from './type.helpers';
import versionedFlexLayoutSchema0 from '../flex-layout/flex_layout.no-collection.schema.v-0';
// -------- HELPERS -----------------------

const actorName = z.union([
  z.literal('widget'),
  z.literal('tauri-window'),
  z.literal('browser-modal-window'),
  z.literal('browser-tab-window'),
  z.literal('react-flex-layout'),
  z.literal('browser-command-palette'),
  z.literal('browser-auth-window'),
  z.literal('tauri-auth-window'),
  z.literal('tauri-command-palette')
]);
const windowType = z.union([z.literal('dialog'), z.literal('window')]);
const widgetType = z.string();
const widgetCategory = z.string();
const subSchemaMetaInfo = z.object({
  widgetType: widgetType.optional(),
  widgetCategory: widgetCategory.optional(),
  subSchemaInvalid: z.boolean().optional()
});
const requiredEntitlements = z.array(z.string()); // needed in order to ensure that the types don't break in `form.open.ts`

// -------- WIDGET ACTOR SCHEMAS -----------------------

const widgetComponentProps = z.record(z.any());
const widgetComponentMeta = z.intersection(subSchemaMetaInfo, z.record(z.any()));

const baseWidgetActorContext = z.object({
  title: z.string().optional(),
  componentId: z.string(),
  componentProps: widgetComponentProps,
  version: z.number().optional(),
  meta: widgetComponentMeta.optional()
});

const baseWidgetActorDef = z.object({
  id: z.string(),
  name: actorName,
  type: z.literal('widget'),
  context: baseWidgetActorContext
});

export type WIDGET_SCHEMA_ASSERTION_DO_NOT_TOUCH = Expect<
  AssertMutuallyAssignable<WorkspaceWidgetActorDef, z.infer<typeof baseWidgetActorDef>>
>;

// -------- WINDOW ACTOR SCHEMAS -----------------------

const windowMeta = z.intersection(
  z
    .object({
      windowType,
      widgetType,
      widgetCategory,
      requiredEntitlements
    })
    .deepPartial(),
  z.record(z.any())
);

const baseWindowActorContext = z.object({
  title: z.string(),
  isTitleEditable: z.boolean().optional(),
  x: z.number().optional(),
  y: z.number().optional(),
  width: z.number(),
  height: z.number(),
  isMaximized: z.boolean(),
  isMinimized: z.boolean(),
  isMaximizable: z.boolean(),
  isMinimizable: z.boolean(),
  isResizable: z.boolean(),
  isClosable: z.boolean(),
  isVisible: z.boolean(),
  isFocused: z.boolean().optional(),
  isDecorated: z.boolean(),
  isFullscreen: z.boolean(),
  alwaysOnTop: z.boolean(),
  isPinned: z.boolean(),
  isPinnable: z.boolean(),
  skipTaskbar: z.boolean(),
  scaleFactor: z.number(),
  currentMonitorIndex: z.number(),
  requestingUserAttention: z.union([z.literal('info'), z.literal('critical'), z.null()]),
  isBrowserTab: z.boolean().optional(),
  transparent: z.boolean().optional(),
  minWidth: z.number().optional(),
  maxWidth: z.number().optional(),
  minHeight: z.number().optional(),
  maxHeight: z.number().optional(),
  initiallyCentered: z.boolean().optional(),
  initiallyUseLogicalPixels: z.boolean().optional(),
  theme: z.string().nullable().optional(),
  tauriLabel: z.string().optional(),
  meta: windowMeta
});

const baseWindowActor = z.object({
  id: z.string(),
  name: actorName,
  type: z.literal('window'),
  context: baseWindowActorContext
});

export type WIN_SCHEMA_ASSERTION_DO_NOT_TOUCH = Expect<
  AssertMutuallyAssignable<WorkspaceWindowActorDef, z.infer<typeof baseWindowActor>>
>;

const baseAuthWindowActor = z.object({
  id: z.string(),
  name: actorName,
  type: z.literal('auth-window'),
  context: baseWindowActorContext
});

export type AUTH_WIN_SCHEMA_ASSERTION_DO_NOT_TOUCH = Expect<
  AssertMutuallyAssignable<WorkspaceAuthWindowActorDef, z.infer<typeof baseAuthWindowActor>>
>;

const baseCommandPaletteWindowActor = z.object({
  id: z.string(),
  name: actorName,
  type: z.literal('cmdp-window'),
  context: baseWindowActorContext
});

export type CMD_P_WIN_SCHEMA_ASSERTION_DO_NOT_TOUCH = Expect<
  AssertMutuallyAssignable<
    WorkspaceCommandPaletteWindowActorDef,
    z.infer<typeof baseCommandPaletteWindowActor>
  >
>;

// -------- FLEX LAYOUT ACTOR SCHEMAS -----------------------

const flexLayoutComponentProps = z.record(z.any());
const flexLayoutMeta = z.intersection(subSchemaMetaInfo, z.record(z.any()));

const baseFlexLayoutActor = z.object({
  id: z.string(),
  name: actorName,
  type: z.literal('react-flex-layout'),
  context: versionedFlexLayoutSchema0._dataSchema.extend({
    componentId: z.string().optional(),
    componentProps: flexLayoutComponentProps,
    version: z.number().optional(),
    headerComponent: z.string().optional(),
    footerComponent: z.string().optional(),
    meta: flexLayoutMeta.optional()
  })
});

export type FLEX_SCHEMA_ASSERTION_DO_NOT_TOUCH = Expect<
  AssertMutuallyAssignable<WorkspaceFlexLayoutActorDef, z.infer<typeof baseFlexLayoutActor>>
>;

// -------- LAYOUT SCHEMA -----------------------

const baseActorsUnionSchema = z.union([
  baseWidgetActorDef,
  baseWindowActor,
  baseAuthWindowActor,
  baseFlexLayoutActor,
  baseCommandPaletteWindowActor
]);

type RecursiveActorSnapshotDef = z.infer<typeof baseActorsUnionSchema> & {
  children: RecursiveActorSnapshotDef[];
};

const actorSnapshotDefSchema: z.ZodType<RecursiveActorSnapshotDef> = z.intersection(
  baseActorsUnionSchema,
  z.object({
    children: z.lazy(() => actorSnapshotDefSchema.array())
  })
);

export default createVersionedSchema('SNAPSHOT', {
  version: 0,
  schema: actorSnapshotDefSchema
});
