import { useMemo } from 'react';
import { Box, type BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import mapValues from 'lodash/mapValues';
import type { ResponsiveValue, GridTemplateRowCols, Sprinkles } from '../../system/sprinkles.css';

type ResponsiveGridTemplate = ResponsiveValue<GridTemplateRowCols>;

export const MAX_COLUMN_SIZE = 24;
export type GridColValues =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | typeof MAX_COLUMN_SIZE
  | 'none';
export type GridProps = {
  gap?: Sprinkles['gap'];
  columns: ResponsiveValue<GridColValues>;
  rows?: ResponsiveValue<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 'none'>;
  rowGap?: Sprinkles['rowGap'];
  columnGap?: Sprinkles['columnGap'];
} & BoxProps;

export const Grid = polymorphicComponent<'div', GridProps>((props, ref) => {
  const { as, children, columns, rows = 1, gap, columnGap, rowGap, sx = {}, ...rest } = props;

  const gridTemplateProps = useMemo(() => {
    let gridTemplateColumns = columns as ResponsiveGridTemplate;
    let gridTemplateRows = rows as ResponsiveGridTemplate;

    if (columns !== 'none') {
      gridTemplateColumns =
        typeof columns === 'object'
          ? (mapValues(columns, (col) => `repeat(${col}, minmax(0px, 1fr))`) as ResponsiveGridTemplate)
          : (`repeat(${columns}, minmax(0px, 1fr))` as ResponsiveGridTemplate);
    }

    gridTemplateRows =
      typeof rows === 'object'
        ? (mapValues(rows, (row) => `repeat(${row}, minmax(0px, 1fr))`) as ResponsiveGridTemplate)
        : (`repeat(${rows}, minmax(0px, 1fr))` as ResponsiveGridTemplate);

    return {
      gridTemplateColumns,
      gridTemplateRows
    };
  }, [columns, rows]);

  return (
    <Box
      as={as}
      sx={{ ...gridTemplateProps, display: 'grid', gap, columnGap, rowGap, ...sx }}
      {...rest}
      ref={ref}
    >
      {children}
    </Box>
  );
});

if (__DEV__) {
  Grid.displayName = 'Grid';
}
