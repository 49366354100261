import clsx from 'clsx';
import { useState } from 'react';
import type { ElementType, MouseEventHandler, ReactNode } from 'react';
import { Box } from '@oms/shared-frontend/ui-design-system';

export const Option = ({
  as = 'option',
  children,
  className,
  onClick
}: {
  as?: ElementType & string;
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      as={as}
      className={clsx('ag-menu-option', className, {
        'ag-menu-option-active': isHovered
      })}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};
