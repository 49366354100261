import type { ComponentType } from 'react';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { withUpdatedData, useUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps } from './with-updated-data';
import { InvestorOrdersSubscriptionService } from '@app/data-access/services/trading/investor-orders/investor-orders.subscriptions.service';

export type UpdatedInvestorOrder = UpdatedData<VisibleInvestorOrderInfoWithAllocationsFragment>;

export const withUpdatedInvestorOrder = (
  Component: ComponentType<UpdatedInvestorOrder>,
  props: UpdatedDataProps = {}
) => {
  return withUpdatedData<VisibleInvestorOrderInfoWithAllocationsFragment>(
    Component,
    InvestorOrdersSubscriptionService,
    props
  );
};

export const useUpdatedInvestorOrder = (id: string | null | undefined) => {
  return useUpdatedData<VisibleInvestorOrderInfoWithAllocationsFragment>(
    InvestorOrdersSubscriptionService,
    id
  );
};
