import { Actor } from '@valstro/workspace';
import type { RegistryDefinitionLayoutWithComp } from '@app/app-config/registry.config';
import { BaseWindowOpenable, internalOpenInWindowActor } from './common.open';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';

/**
 * Layout
 * -----------------------------------------------------------------------------
 */
export class Layout<_TProps> extends BaseWindowOpenable<unknown> {
  /**
   * Get a layout by id.
   *
   * @param id - Layout id
   * @returns Layout
   */
  static async get(id: string) {
    const actor = await Actor.get<AppWindowActorSchema>(id);
    if (actor.id === undefined) {
      throw new Error('Actor is undefined');
    }
    return new Layout(actor);
  }

  /**
   * Open a layout using the layout definition.
   *
   * @param definition - Layout definition
   * @param parentActorOrId - Parent actor or parent actor id
   * @returns Layout
   */
  static async open<TProps>(
    definition: RegistryDefinitionLayoutWithComp<TProps>,
    parentActorOrId: Actor | string,
    lazy?: boolean
  ) {
    const [_, actor] = await internalOpenInWindowActor<TProps>(definition, parentActorOrId, lazy);
    if (actor.id === undefined) {
      throw new Error('Actor is undefined');
    }
    return new Layout(actor);
  }
}
