import { PendingModificationsService } from '../pending-modifications.datasource.service';
import { rejectPendingModificationFormContract } from './reject-pending-modification.form-contract';
import type {
  RejectPendingModificationFormInput,
  RejectPendingModificationFormContractType,
  RejectPendingModificationFormValues,
  RejectPendingModificationFormOutput
} from './reject-pending-modification.form-contract';
import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';

export const rejectPendingModificationFormBuilder = FormBuilder.create<
  RejectPendingModificationFormInput,
  RejectPendingModificationFormOutput
>('reject-pending-modification-form')
  .contract<RejectPendingModificationFormContractType>(rejectPendingModificationFormContract)
  .type('rejectPendingModification')
  .sanitizer((s) =>
    s
      .input(function sanitize(input) {
        if (!input.modificationId) {
          throw new Error('Can not reject a pending modification without a modification id');
        }

        const formValues: RejectPendingModificationFormValues = {
          modificationId: input.modificationId
        };

        return formValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.modificationId) return;

        const output: RejectPendingModificationFormOutput = {
          modificationId: formValues.modificationId,
          rejectReason: formValues.rejectReason
        };

        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(PendingModificationsService);
        return await service.rejectModification(
          event.payload.output.modificationId,
          event.payload.output.rejectReason ?? undefined
        );
      }
    }
  });

export type RejectPendingModificationFormBuilderType = typeof rejectPendingModificationFormBuilder;

export default rejectPendingModificationFormBuilder;
