import type { FC } from 'react';
import { Actions, DockLocation } from 'flexlayout-react';
import { AddIcon, IconButton } from '@oms/shared-frontend/ui-design-system';
import type { FlexLayoutTabSetToolbarActionComponentProps } from '@app/common/toolbar/toolbar-context.types';
import config from '../positions-account-grid.widget.config';

export const AddNewPositionsAccountTabButton: FC<FlexLayoutTabSetToolbarActionComponentProps> = ({
  tabSetNode
}) => {
  return (
    <IconButton
      icon={<AddIcon />}
      size="sm"
      variant="link"
      onClick={() => {
        const index = tabSetNode.getChildren().length;
        tabSetNode.getModel().doAction(
          Actions.addNode(
            {
              type: 'tab',
              name: `Accounts ${index + 1}`,
              component: config.key // 'POSITIONS_ACCOUNT_GRID'
            },
            tabSetNode.getId(),
            DockLocation.CENTER,
            index
          )
        );
      }}
      className="window__toolbar__action"
      aria-label="Add new positions accounts tab"
    />
  );
};

export default AddNewPositionsAccountTabButton;
