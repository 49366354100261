import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { MarketDataService } from '@app/data-access/services/marketdata/marketdata.service';
import type {
  IServerSideSubscriptionDataSource,
  IServerSideSubscriptionService,
  ServerSideAddedEvent,
  ServerSideEvent,
  ServerSideUpdateEvent,
  ServerSideViewportStore
} from '@app/data-access/services/trading/server-side-subscriptions/server-side-subscriptions.service';
import {
  ServerSideSubscriptionsObservable,
  hasNoRelayFilters
} from '@app/data-access/services/trading/server-side-subscriptions/server-side-subscriptions.service';
import type {
  GetTradingOrdersQuery,
  GetTradingOrdersQueryVariables,
  OnTradingOrderAddedSubscription,
  OnTradingOrderUpdatedSubscription,
  VisibleTradingOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import {
  GetTradingOrdersDocument,
  OnTradingOrderAddedDocument,
  OnTradingOrderUpdatedDocument,
  VisibleTradingOrdersOrderBy
} from '@oms/generated/frontend';
import type { Dispatch, SetStateAction } from 'react';
import { EMPTY, map } from 'rxjs';
import { inject, injectable, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

type ITradingOrderDataSource = IServerSideSubscriptionDataSource<
  VisibleTradingOrderInfoWithAllocationsFragment,
  GetTradingOrdersQueryVariables
>;

@injectable()
export class TradingOrderSubcriptionDatasource implements ITradingOrderDataSource {
  constructor(
    @inject(MarketDataService) private marketDataService: MarketDataService,
    @inject(ApolloClientRPC) private apolloClientRPC: ApolloClientRPC<NormalizedCacheObject>
  ) {}

  public async get(variables?: GetTradingOrdersQueryVariables) {
    const result = await this.apolloClientRPC.query<GetTradingOrdersQuery, GetTradingOrdersQueryVariables>({
      query: GetTradingOrdersDocument,
      variables,
      fetchPolicy: 'network-only',
      context: {
        fetchOptions: {
          // TODO: Need to implement this in ApolloClientRPC
          // signal: abortController.signal
        }
      }
    });
    const totalCount = result.data.visibleTradingOrders?.totalCount || 0;
    const nodes = result.data?.visibleTradingOrders?.nodes || [];
    const nodesWithMarketData = nodes.map((d) => {
      // Merge market data into results if it exists to avoid flash
      const ticker = d?.instrument?.mappings?.displayCode || '';
      const marketData = ticker ? this.marketDataService.read(ticker) : undefined;
      const rowDataWithMarketData = {
        ...(marketData ? marketData.level1 : {}),
        ...d
      };
      return rowDataWithMarketData as VisibleTradingOrderInfoWithAllocationsFragment;
    });
    return {
      error: null,
      isFetching: false,
      nodes: nodesWithMarketData,
      totalCount
    };
  }

  public get added$() {
    return this.apolloClientRPC
      .subscribe<OnTradingOrderAddedSubscription>({
        query: OnTradingOrderAddedDocument
      })
      .pipe(
        map((result) => {
          const add = result.data?.tradingOrderAdded;
          const addEvent: ServerSideAddedEvent<VisibleTradingOrderInfoWithAllocationsFragment> = {
            type: 'add',
            data: add?.tradingOrder as VisibleTradingOrderInfoWithAllocationsFragment
          };
          return addEvent;
        })
      );
  }

  public get updated$() {
    return this.apolloClientRPC
      .subscribe<OnTradingOrderUpdatedSubscription>({
        query: OnTradingOrderUpdatedDocument
      })
      .pipe(
        map((result) => {
          const update = result.data?.tradingOrderUpdated;
          const updateEvent: ServerSideUpdateEvent<VisibleTradingOrderInfoWithAllocationsFragment> = {
            type: 'update',
            data: update?.tradingOrder as VisibleTradingOrderInfoWithAllocationsFragment
          };
          return updateEvent;
        })
      );
  }

  public get removed$() {
    return EMPTY;
  }

  public getRowId(row: VisibleTradingOrderInfoWithAllocationsFragment): string {
    return row.id;
  }

  public eventsTransformer(
    event: ServerSideEvent<VisibleTradingOrderInfoWithAllocationsFragment>,
    variables?: GetTradingOrdersQueryVariables
  ): ServerSideEvent<VisibleTradingOrderInfoWithAllocationsFragment> {
    switch (event.type) {
      case 'add': {
        const orderByArr =
          variables?.orderBy && Array.isArray(variables.orderBy) && variables.orderBy.length > 0
            ? variables.orderBy
            : variables?.orderBy && !Array.isArray(variables.orderBy)
              ? [variables.orderBy]
              : [];

        const hasUpdatedTimestampDesc = orderByArr.includes(VisibleTradingOrdersOrderBy.UpdatedTimestampDesc);

        const hasCreatedAtDesc = orderByArr.includes(VisibleTradingOrdersOrderBy.CreatedTimestampDesc);

        const hasNoMoreThanTwoOrderBy = orderByArr.length <= 2;

        const isOnlyOrderedByValidatedTimestampDescAndOrCreatedAtDesc =
          (hasUpdatedTimestampDesc || hasCreatedAtDesc) && hasNoMoreThanTwoOrderBy;

        const canAddDataToTheTop =
          isOnlyOrderedByValidatedTimestampDescAndOrCreatedAtDesc && hasNoRelayFilters(variables);

        // TODO: I could be smarter here and check more filters to see if I can apply the data to the top
        // TODO: However, we'd need a delta from the BE event PLUS BE need to hoist / implement this anyway.

        if (canAddDataToTheTop) {
          return {
            ...event,
            index: 0 // Add to the top of the list
          };
        } else {
          return {
            type: 'refetch'
          };
        }
      }
      default: {
        return event;
      }
    }
  }
}

type ITradingOrderSubscription = IServerSideSubscriptionService<
  VisibleTradingOrderInfoWithAllocationsFragment,
  GetTradingOrdersQueryVariables
>;

@testScoped
@singleton()
export class TradingOrdersSubscriptionService implements ITradingOrderSubscription {
  constructor(
    @inject(TradingOrderSubcriptionDatasource)
    private tradingOrderSubscriptionsDatasource: ITradingOrderDataSource
  ) {}

  public query$(variables?: GetTradingOrdersQueryVariables) {
    return new ServerSideSubscriptionsObservable<VisibleTradingOrderInfoWithAllocationsFragment>(
      this.tradingOrderSubscriptionsDatasource,
      variables
    );
  }

  public track(
    orderId: VisibleTradingOrderInfoWithAllocationsFragment['id'],
    setOrder: Dispatch<SetStateAction<VisibleTradingOrderInfoWithAllocationsFragment | undefined>>,
    setError: Dispatch<SetStateAction<Error | undefined>>
  ) {
    const subscription = this.query$({
      filter: {
        id: {
          equalTo: orderId
        }
      }
    }).subscribe(
      ({ error, nodes }: ServerSideViewportStore<VisibleTradingOrderInfoWithAllocationsFragment>) => {
        setError(error || undefined);
        if (!error && nodes.length === 0) {
          setError(new Error('Trading order not found'));
        }
        setOrder(nodes[0]);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }

  public dispose() {}
}
