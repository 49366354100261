import { LAYOUT_ENUM } from '@app/generated/common';
import type { ComponentEnumKey, LayoutEnumKey } from '@app/generated/common';

type VerticalFlexLayoutPeripheralMapper = Partial<Record<LayoutEnumKey, ComponentEnumKey>>;

export const FLEX_LAYOUT_HEADER_MAPPER: VerticalFlexLayoutPeripheralMapper = {
  [LAYOUT_ENUM.MONTAGE]: 'MONTAGE_TOOLBAR'
} as const;

export const FLEX_LAYOUT_FOOTER_MAPPER: VerticalFlexLayoutPeripheralMapper = {} as const;
