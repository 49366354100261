import {
  Actor,
  AnyActorSchema,
  CommonWindowActorSchema,
  TauriWindowActorSchemaBuilder
} from '@valstro/workspace';
import { ReactActorComponentProps, useActorClassNames, useActorState } from '../../core';
import { ReactActorComponentChildren } from '../../core/react-actor-component-children';
import { useMemo } from 'react';
import { CommonReactWindowActorOptions } from '../common/window';
import { useGetWindowWrapperComponent } from '../common/window/wrapper.window.react.actor.common';
import { WindowDecorationProvider } from '../common/window/decoration.window.react.actor.common';

export function ReactTauriWindowView<TOverride extends AnyActorSchema = CommonWindowActorSchema>({
  actor: _actor,
  children
}: ReactActorComponentProps<TOverride> & {
  children?: React.ReactNode;
}) {
  const actor = useMemo(() => _actor as Actor<CommonWindowActorSchema>, [_actor]);
  const [state, failedMessage] = useActorState(actor);
  const className = useActorClassNames(actor, state);
  const WindowWrapper = useGetWindowWrapperComponent(actor);

  switch (state) {
    case 'idle':
      return <div className={className.wrapper}>Idle</div>;
    case 'starting':
      return <div className={className.wrapper}>Loading...</div>;
    case 'applying-snapshot':
      return <div className={className.wrapper}>Loading layout...</div>;
    case 'failed':
      return (
        <div className={className.wrapper}>
          <div className={className.box}>
            <div className={className.title}>Error</div>
            {failedMessage && <div className={className.message}>{failedMessage}</div>}
          </div>
        </div>
      );
    case 'destroyed':
      return (
        <div className={className.wrapper}>
          <div className={className.box}>
            <div className={className.title}>Window Disconnected</div>
            <div className={className.message}>Please refresh to re-connect</div>
          </div>
        </div>
      );
    default:
      return (
        <WindowWrapper>
          <WindowDecorationProvider actor={actor} state={state}>
            {children ? children : <ReactActorComponentChildren actor={actor} />}
          </WindowDecorationProvider>
        </WindowWrapper>
      );
  }
}

export type ReactTauriWindowViewType = React.ComponentType<ReactActorComponentProps<CommonWindowActorSchema>>;

export const ReactTauriWindowActorSchemaBuilder =
  TauriWindowActorSchemaBuilder.extendView(ReactTauriWindowView);

export const tauriWindowActor =
  ReactTauriWindowActorSchemaBuilder.optionsCreator<CommonReactWindowActorOptions>();

export type ReactTauriWindowActorSchema = typeof ReactTauriWindowActorSchemaBuilder.schema;
