import {
  CellClassParams,
  CellClassRules,
  ColDef,
  GroupCellRendererParams,
  ICellRendererParams,
  ValueFormatterFunc,
  ValueGetterFunc
} from '@ag-grid-community/core';
import { ComponentType } from 'react';
import { AnyRecord } from '@oms/frontend-foundation';
import { CellBadgeClassEnum } from '../models/row-states.model';
import { DefaultCellRenderers } from '../renderers/cell-renderers/default-renderers';
import { DEFAULT_VALUE_FORMATTERS, DefaultFormatters } from '../formatters/default.formatters';

// https://www.ag-grid.com/react-data-grid/components/#grid-provided-components
export type AgGridRenderers =
  | 'agAnimateShowChangeCellRenderer'
  | 'agAnimateSlideCellRenderer'
  | 'agGroupCellRenderer'
  | 'agLoadingCellRenderer'
  | '';

export type CellRenderer = ComponentType<any> | AgGridRenderers | DefaultCellRenderers;

export type CellRendererParamKeys<TData extends AnyRecord> = keyof ICellRendererParams<TData>;

export type GroupCellRenderer<TData extends AnyRecord> = Partial<
  Omit<GroupCellRendererParams<TData>, 'innerRenderer' | CellRendererParamKeys<TData>> & {
    innerRenderer: CellRenderer;
  }
>;

export interface CellDef<TData extends AnyRecord> {
  groupCellParams?: GroupCellRenderer<TData>;
  valueFormatter?: ValueFormatterFunc<TData>;
  valueGetter?: ValueGetterFunc<TData>;
  filterValueGetter?: ValueGetterFunc<TData>;
  cellRenderer?: CellRenderer;
  cellRendererParams?: ColDef<TData>['cellRendererParams'];
  cellClassRules?: CellClassRules<TData>;
}

export class CellBuilder<TData extends AnyRecord> {
  private _cellDef: CellDef<TData>;

  constructor(cellDef: CellDef<TData> = {}) {
    this._cellDef = cellDef;
  }

  public valueFormatter<TValue = unknown>(formatter: ValueFormatterFunc<TData, TValue>): CellBuilder<TData> {
    this._cellDef.valueFormatter = (params) => {
      params.value = formatter(params);
      return params.value;
    };
    return this;
  }

  public valueFormatterRegistry(key: DefaultFormatters): CellBuilder<TData> {
    const formatter = DEFAULT_VALUE_FORMATTERS[key] as ValueFormatterFunc<TData>;
    if (formatter) {
      return this.valueFormatter(formatter);
    }
    return this;
  }

  public valueGetter(valueGetter: ValueGetterFunc<TData>): CellBuilder<TData> {
    this._cellDef.valueGetter = valueGetter;
    return this;
  }

  public filterValueGetter(filterValueGetter: ValueGetterFunc<TData>): CellBuilder<TData> {
    if (this._cellDef.filterValueGetter) {
      console.warn('filterValueGetter is already set, overwriting it with', filterValueGetter);
    }
    this._cellDef.filterValueGetter = filterValueGetter;
    return this;
  }

  public dependsOn(columnIds: Array<keyof TData>): CellBuilder<TData> {
    this.valueFormatter(({ data }) =>
      columnIds
        .map((field) => {
          const strValue = JSON.stringify(data?.[field]);
          // We depend on the field value being serializable.
          // JSON.stringify will transform unserialisable stuff to null, so throw
          // if it's mapped to null, but not originally null or undefined
          if (data?.[field] !== null && data?.[field] !== undefined && strValue === 'null') {
            throw new Error('Cell can not depend on unserializable column values');
          }
          return strValue;
        })
        .join('')
    );
    return this;
  }

  public cellClassRules(value: ColDef<TData>['cellClassRules']): CellBuilder<TData> {
    this._cellDef.cellClassRules = {
      ...(this._cellDef.cellClassRules || {}),
      ...value
    };
    return this;
  }

  public badge(
    badgeClass: CellBadgeClassEnum,
    formatter: (data: TData | undefined, value?: any) => string,
    condition: (params: CellClassParams<TData>) => boolean = () => true
  ): CellBuilder<TData> {
    this.valueFormatter((params) => formatter(params.data, params.value));
    this.filterValueGetter((params) => formatter(params.data));
    return this.cellClassRules({
      [`${badgeClass}`]: condition
    });
  }

  public buySell(): CellBuilder<TData> {
    this.cellClassRules({
      'ag-cell-badge--buy': (params) => !!params.value && parseFloat(`${params.value}`) > 0,
      'ag-cell-badge--sell': (params) => !!params.value && parseFloat(`${params.value}`) < 0
    });
    return this;
  }

  public renderer<TParams extends AnyRecord = AnyRecord>(
    cellRenderer?: CellRenderer,
    params?: TParams
  ): CellBuilder<TData> {
    if (cellRenderer) {
      this._cellDef.cellRenderer = cellRenderer;
      this._cellDef.cellRendererParams = params;
    }
    return this;
  }

  public grouped(params: GroupCellRenderer<TData>): CellBuilder<TData> {
    this._cellDef.groupCellParams = params;
    this._cellDef.cellRenderer = 'agGroupCellRenderer';
    return this;
  }

  public clone(): CellBuilder<TData> {
    return new CellBuilder<TData>({ ...this._cellDef });
  }

  public build(): CellDef<TData> {
    return this._cellDef;
  }
}
