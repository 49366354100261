import { useClosestAppFlexLayoutActor } from '@app/common/workspace/workspace.hooks';
import type { MontageLayout } from '@app/data-access/services/trading/montage/montage.types';
import { useActorContext } from '@valstro/workspace-react';
import { useMemo } from 'react';

export const useMontageLayout = () => {
  const flexLayoutActor = useClosestAppFlexLayoutActor();

  if (!flexLayoutActor) {
    throw new Error('unable to find a flexLayout actor in the hierarchy');
  }

  const context = useActorContext(flexLayoutActor);

  if (!context?.layout?.id) {
    throw new Error('unable to find a layout id in the flexLayout actor context');
  }

  const layout: MontageLayout = useMemo(() => context.layout.id as MontageLayout, [context.layout.id]);

  return useMemo(() => ({ layout, flexLayoutActorId: flexLayoutActor.id }), [layout, flexLayoutActor.id]);
};
