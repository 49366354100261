import type { FC } from 'react';
import { useActionProps } from '../../../hooks/action.events.hook';
import type { ActionComponentProps } from '../../../models/actions.model';
import { Box, Text } from '@oms/shared-frontend/ui-design-system';
import { omit } from 'lodash';

export const ActionText: FC<ActionComponentProps<any>> = (props) => {
  const actionProps = useActionProps<any, ActionComponentProps<any>>(props);

  if (actionProps.isVisible === false) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifySelf: 'center',
        alignSelf: 'center',
        marginLeft: 2,
        marginRight: 2
      }}
    >
      <Text
        {...(omit(
          actionProps,
          'onChange',
          'content',
          'actionType',
          'isVisible',
          'instanceId',
          'notify'
        ) as any)}
      >
        {actionProps.content}
      </Text>
    </Box>
  );
};
