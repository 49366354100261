import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { sharedLimitPriceCol } from '@app/common/grids/columns/order-cols';
import type { MontageItem, MontageItemType } from '@app/data-access/services/trading/montage/montage.types';
import type PricePartitionMapSubject from '@app/data-access/services/trading/montage/utils/price-partition-subject.class';
import type MontageTargetState from '@app/data-access/services/trading/montage/utils/montage-target-state.class';
import type { DepthPriceColumnHeaderSpecificParams } from './depth.price.column.header';
import DepthCellRenderer from './renderers/depth.cell.renderer';
import type { DepthCellSpecificParams } from './renderers/depth.cell.types';
import { getHeaderFor } from './renderers/depth.cell.util';
import DepthPriceColumnHeader from './depth.price.column.header';

export interface DepthGridConfig {
  type: MontageItemType;
  targetState: MontageTargetState;
  pricePartitionMap$?: PricePartitionMapSubject;
}

type DepthGridColumnConfig = (config: DepthGridConfig) => ColumnBuilderCallback<MontageItem>;

type DepthGridLibraryConfig = (config: DepthGridConfig) => ColumnLibrary<MontageItem>;

export const defaultColumn: ColumnBuilderCallback<MontageItem> = (cb) =>
  cb.resizable(true).sortable(false).maxWidth(150);

export const counterpartyId: DepthGridColumnConfig =
  ({ targetState, pricePartitionMap$ }) =>
  (cb) =>
    cb
      .colId('counterparty')
      .field('counterpartyId')
      .header('CPTY')
      .cell((c) =>
        c
          .valueFormatter(({ data }) => data?.counterpartyId || 'CPTY')
          .renderer<DepthCellSpecificParams>(DepthCellRenderer, { targetState, pricePartitionMap$ })
      );

export const limitPrice: DepthGridColumnConfig =
  ({ type, targetState, pricePartitionMap$ }) =>
  (cb) =>
    sharedLimitPriceCol(cb)
      .colId('limitPrice')
      .field('limitPrice')
      .header(getHeaderFor(type))
      .shortHeader(getHeaderFor(type))
      .cell((c) =>
        c.renderer<DepthCellSpecificParams>(DepthCellRenderer, { targetState, pricePartitionMap$ })
      )
      .headerComp(DepthPriceColumnHeader)
      .headerParams<DepthPriceColumnHeaderSpecificParams>({ type });

export const size: DepthGridColumnConfig =
  ({ targetState, pricePartitionMap$ }) =>
  (cb) =>
    cb
      .colId('size')
      .field('size')
      .header('Size')
      .format('number')
      .cell((c) =>
        c.renderer<DepthCellSpecificParams>(DepthCellRenderer, { targetState, pricePartitionMap$ })
      );

/**
 * Creates grid columns for order depth grids.
 * @param config.type - Specify if for the `bid` or the `ask` grid.
 * @param config.orderStreams - Pass the montage order streams object
 * @returns Grid columns depending on whether the grid is bid or ask.
 */
export const depthGridColumns: DepthGridLibraryConfig = (config) => {
  return {
    defaultColumn,
    columns: [counterpartyId(config), limitPrice(config), size(config)]
  };
};
