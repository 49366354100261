import { useCallback, useMemo, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../../../popover/popover';
import { GridProps } from '../../../../layout/grid/grid';
import { DisplayField, DisplayFieldProps } from '../../display-field';
import { DISPLAY_FIELD_COMPONENT_TYPE } from '../../display-field.contracts';
import {
  DISPLAY_GRID_CUSTOM_COMPONENT_TYPE,
  DisplayCompShape,
  DisplayGrid,
  DisplayGridItemProps,
  displayGridComponentMapper,
  isCustomComponent,
  DisplayGridProps
} from '../../../display-grid/display-grid';
import { BoxProps, Box } from '../../../../system/components/box/box';
import { StatsProps } from '../../../stats/stats';

export type InWindowPopoverDisplayGridProps = {
  gridProps: GridProps;
  items: DisplayGridProps['items'];
  style?: BoxProps['style'];
  hasClose?: boolean;
  testId?: string;
};

export type InWindowPopoverProps<TFieldComps extends DisplayCompShape = undefined> = {
  type: typeof DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover;
  popoverContent: InWindowPopoverDisplayGridProps;
  value: DisplayGridItemProps<TFieldComps>;
};

export function InWindowPopover<TFieldComps extends DisplayCompShape = undefined>({
  value,
  popoverContent
}: InWindowPopoverProps<TFieldComps>) {
  const [isOpen, setIsOpen] = useState(false);
  const { items, gridProps, style, testId } = popoverContent;
  const hasClose = popoverContent?.hasClose ?? true;
  const handleOnOpenChange = useCallback((open: boolean) => setIsOpen(open), []);

  const customComponent = useMemo(
    () => (component: DisplayFieldProps<TFieldComps>['component']) => {
      const { type } = component;

      switch (type) {
        case DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.Stats: {
          return displayGridComponentMapper[type](component as StatsProps);
        }
        default:
          return null;
      }
    },
    []
  );

  return (
    <Popover open={isOpen} onOpenChange={handleOnOpenChange}>
      <PopoverTrigger>
        <Box>
          {isCustomComponent<TFieldComps>(value) ? (
            customComponent(value.component)
          ) : (
            <DisplayField
              label={value.label}
              layout={value.layout}
              component={value.component as DisplayFieldProps<TFieldComps>['component']}
              key={value.label}
              labelMargin={value.labelMargin}
              testId={value.testId}
            />
          )}
        </Box>
      </PopoverTrigger>
      {!!items.length && (
        <PopoverContent hasClose={hasClose} style={style} data-testid={testId}>
          <DisplayGrid items={items} gridProps={gridProps} />
        </PopoverContent>
      )}
    </Popover>
  );
}
