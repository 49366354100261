import type { Notification } from '@app/widgets/trading/notifications/notifications.contracts';
import { IO_VIS_KEY } from '@app/widgets/trading/investor-order-monitor/grid-services/investor-order-monitor.visibility.filters';
import type { IOVisKey } from '@app/widgets/trading/investor-order-monitor/grid-services/investor-order-monitor.visibility.filters';

export const NOTIFICATION_VISIBILITY = IO_VIS_KEY; //{
//   MINE: 'MINE',
//   COVERAGE_PRIMARY: 'COVERAGE_PRIMARY',
//   COVERAGE_TEAM: 'COVERAGE_TEAM',
//   COVERAGE_TEAM_WITH_PRIMARY: 'COVERAGE_TEAM_WITH_PRIMARY',
//   ALL: 'ALL'
// } as const;

export type NotificationVisibility = IOVisKey; //typeof NOTIFICATION_VISIBILITY[keyof typeof NOTIFICATION_VISIBILITY];

export type NotificationEvent = {
  notification: Notification;
  userPreferences: Record<string, unknown>;
  visibility: NotificationVisibility;
  error: any;
};

export type NotificationPreferenses = Record<string, unknown>;

export type NotificationSignal = NotificationVisibility;
// export type NotificationSignal = {
//   visibility: NotificationVisibility;
// };
