import type { ExecutionInfoFragment } from '@oms/generated/frontend';
import {
  sharedDateTimeCol,
  sharedDefaultCol,
  sharedIdCol,
  sharedPriceCol,
  sharedQuantityCol,
  sharedUserCol
} from '@app/common/grids/columns/generic-cols';
import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import {
  sharedSideCol,
  sharedSettleTypeCol,
  sharedSettleDateCol,
  sharedCapacityCol
} from '@app/common/grids/columns/order-cols';

type ExecutionColumnBuilder = ColumnBuilderCallback<ExecutionInfoFragment>;

const defaultCol: ExecutionColumnBuilder = (c) => sharedDefaultCol<ExecutionInfoFragment>(c);

const idCol: ExecutionColumnBuilder = (c) =>
  sharedIdCol<ExecutionInfoFragment>(c, 'id').filter('agTextColumnFilter').hide();

const executedPriceCol: ExecutionColumnBuilder = (c) =>
  sharedPriceCol(c, 'executedPrice').header(t('app.trades.tradePrice'));

const sideCol: ExecutionColumnBuilder = (c) =>
  sharedSideCol<ExecutionInfoFragment>(c, 'trade.tradingOrder.sideType').header(t('app.trades.side'));

const executedQuantityCol: ExecutionColumnBuilder = (c) =>
  sharedQuantityCol(c, 'executedQuantity').header(t('app.trades.tradeQuantity'));

const capacityCol: ExecutionColumnBuilder = (c) => sharedCapacityCol(c);

const tradeCounterpartyIdCol: ExecutionColumnBuilder = (c) =>
  c.field('trade.tradeCounterpartyId').header(t('tradeCounterPartyId')).width(168);

const lastMarketCol: ExecutionColumnBuilder = (c) =>
  c.field('trade.lastMarket').header(t('app.trades.lastMarket')).width(120);

const executionDateTimeCol: ExecutionColumnBuilder = (c) =>
  sharedDateTimeCol(c, 'executionDateTime').header(t('app.trades.tradeDateTime'));

const enteredByCol: ExecutionColumnBuilder = (c) =>
  sharedUserCol(c, 'trade.enteredBy').header(t('app.trades.enteredBy'));

const rawLiquidityIndicatorCol: ExecutionColumnBuilder = (c) =>
  c
    .field('trade.rawLiquidityIndicator')
    .header(t('app.trades.rawLiquidityIndicator'))
    .shortHeader(t('app.trades.rawLiquidityIndicator', { ns: 'short' }))
    .width(180);

const normalizedLiquidityIndicatorCol: ExecutionColumnBuilder = (c) =>
  c
    .field('trade.normalizedLiquidityIndicator')
    .header(t('app.trades.normalizedLiquidityIndicator'))
    .shortHeader(t('app.trades.normalizedLiquidityIndicator', { ns: 'short' }))
    .width(180);

const tradeIdCol: ExecutionColumnBuilder = (c) => c.field('trade.id').header(t('app.trades.tradeId')).hide();

const settleTypeCol: ExecutionColumnBuilder = (c) =>
  sharedSettleTypeCol<ExecutionInfoFragment>(c, 'trade.settleType');

const settleDateCol: ExecutionColumnBuilder = (c) =>
  sharedSettleDateCol<ExecutionInfoFragment>(c, 'trade.settleDate');

const settleFXRateCol: ExecutionColumnBuilder = (c) =>
  sharedPriceCol<ExecutionInfoFragment>(c, 'settleFxRate')
    .header(t('app.orders.orderMonitor.settleFxRate'))
    .shortHeader(t('app.orders.orderMonitor.settleFxRate', { ns: 'short' }))
    .format('price-fx')
    .width(120)
    .hide();

const tradeContraAccountCol: ExecutionColumnBuilder = (c) =>
  c
    .field('trade.tradeContraAccount.name')
    .header(t('app.trades.tradeContraAccount'))
    .shortHeader(t('app.trades.tradeContraAccount', { ns: 'short' }))
    .width(180);

export const investorOrderExecutionsColumnLibrary: ColumnLibrary<ExecutionInfoFragment> = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    executedPriceCol,
    sideCol,
    executedQuantityCol,
    capacityCol,
    tradeCounterpartyIdCol,
    tradeContraAccountCol,
    lastMarketCol,
    executionDateTimeCol,
    enteredByCol,
    normalizedLiquidityIndicatorCol,
    rawLiquidityIndicatorCol,
    tradeIdCol,
    settleTypeCol,
    settleDateCol,
    settleFXRateCol
  ]
};
