import { Actor, PROCESS_ID } from '@valstro/workspace';
import type { Workspace } from '@valstro/workspace';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';

declare global {
  interface Window {
    workspace: Workspace;
    Actor: typeof Actor;
  }
}

/**
 * Exposes Workspace and Actor to the window object
 * for debugging purposes
 *
 * @param workspace - Workspace
 */
export function workspaceDebugger<T extends Workspace>(workspace: T) {
  window['Actor'] = Actor;
  window['workspace'] = workspace;
  return workspace;
}

/**
 * Focuses all windows
 *
 * @returns void
 */
export async function focusAll() {
  const leaderActor = await Actor.get<AppWindowActorSchema>(PROCESS_ID.LEADER);
  leaderActor.operations.focusAll().catch(console.error);
}

/**
 * Checks if the page was accessed by reload
 */
export const pageAccessedByReload =
  (window &&
    window.performance &&
    window.performance.navigation &&
    window.performance.navigation.type === 1) ||
  (window &&
    window.performance &&
    window.performance.getEntriesByType &&
    typeof window.performance.getEntriesByType === 'function' &&
    window.performance
      .getEntriesByType('navigation')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      .map((nav) => (nav as any).type)
      .includes('reload'));
