import { UIUnit, DEFAULT_BASE_PIXELS, formatUnit, pixelsToRem } from '../../utils/convert';
import { robotoFlex, robotoMono, comicSans } from './typography';

type PXSizes = 10 | 12 | 14 | 16 | 18 | 20 | 24 | 26 | 32 | 40 | 48 | 56 | 64;
type TypographyWeight = 400 | 500 | 600;
type TypographyFont = typeof robotoFlex | typeof robotoMono | typeof comicSans;
type TextTransform = 'none' | 'uppercase';

interface TypographyStyleSetup {
  baseInPx?: PXSizes;
  sizeInPx?: PXSizes;
  lineHeightInPx?: PXSizes;
  weight?: TypographyWeight;
  font?: TypographyFont;
  allCaps?: boolean;
}

export interface TypographyStyleOutput extends Record<string, string> {
  base: string;
  fontSize: string;
  lineHeight: string;
  fontWeight: string;
  fontFamily: TypographyFont;
  textTransform: TextTransform;
}

export class TypographyStyleDefinition {
  readonly base: Record<UIUnit, string>;
  readonly fontSize: Record<UIUnit, string>;
  readonly lineHeight: Record<UIUnit, string>;
  readonly fontWeight: string;
  readonly fontFamily: TypographyFont;
  readonly textTransform: TextTransform;

  constructor(setup: TypographyStyleSetup) {
    const {
      baseInPx = DEFAULT_BASE_PIXELS,
      sizeInPx,
      lineHeightInPx,
      weight = 500,
      font = robotoFlex,
      allCaps
    } = setup;
    const build = (inPx: number): Record<UIUnit, string> => ({
      [UIUnit.Pixels]: formatUnit(inPx, UIUnit.Pixels),
      [UIUnit.Rem]: formatUnit(pixelsToRem(inPx, baseInPx), UIUnit.Rem)
    });
    this.base = build(baseInPx);
    const size = sizeInPx ?? baseInPx;
    this.fontSize = build(size);
    this.lineHeight = build(lineHeightInPx ?? size);
    this.fontWeight = `${weight}`;
    this.fontFamily = font;
    this.textTransform = allCaps ? 'uppercase' : 'none';
  }

  static px(setup: TypographyStyleSetup): TypographyStyleOutput {
    return new TypographyStyleDefinition(setup).px;
  }

  static rem(setup: TypographyStyleSetup): TypographyStyleOutput {
    return new TypographyStyleDefinition(setup).rem;
  }

  get px(): TypographyStyleOutput {
    return {
      base: this.base.px,
      fontSize: this.fontSize.px,
      lineHeight: this.lineHeight.px,
      fontWeight: this.fontWeight,
      fontFamily: this.fontFamily,
      textTransform: this.textTransform
    };
  }

  get rem(): TypographyStyleOutput {
    return {
      base: this.base.px, // Always output base in px
      fontSize: this.fontSize.rem,
      lineHeight: this.fontSize.px === this.lineHeight.px ? '1.1' : this.lineHeight.rem, // Prevents text being cut off.
      fontWeight: this.fontWeight,
      fontFamily: this.fontFamily,
      textTransform: this.textTransform
    };
  }
}

export enum TypographyStyle {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  // -
  SubheadingR = 'subheadingR',
  SubheadingB = 'subheadingB',
  // -
  LargeR = 'largeR',
  LargeB = 'largeB',
  MediumR = 'mediumR',
  MediumB = 'mediumB',
  BaseR = 'baseR',
  BaseB = 'baseB',
  SmallR = 'smallR',
  SmallB = 'smallB',
  // -
  CapsXL = 'capsXL',
  CapsM = 'capsM',
  CapsS = 'capsS',
  // -
  GridLarge = 'gridLarge',
  GridMedium = 'gridMedium',
  GridBase = 'gridBase',
  GridTiny = 'gridTiny'
}

export type TypographyStyleUnion = `${TypographyStyle}`;

export type CommonTypographyStyleUnion =
  | `${TypographyStyle.CapsS}`
  | `${TypographyStyle.CapsM}`
  | `${TypographyStyle.CapsXL}`
  | `${TypographyStyle.BaseR}`
  | `${TypographyStyle.BaseB}`
  | `${TypographyStyle.MediumR}`
  | `${TypographyStyle.MediumB}`
  | `${TypographyStyle.LargeR}`
  | `${TypographyStyle.LargeB}`;

export type HeadingTypographyStyleUnion =
  | CommonTypographyStyleUnion
  | `${TypographyStyle.H1}`
  | `${TypographyStyle.H2}`
  | `${TypographyStyle.H3}`
  | `${TypographyStyle.H4}`
  | `${TypographyStyle.H5}`
  | `${TypographyStyle.H6}`
  | `${TypographyStyle.H6}`
  | `${TypographyStyle.SubheadingR}`
  | `${TypographyStyle.SubheadingB}`;

export type TextTypographyStyleUnion =
  | CommonTypographyStyleUnion
  | `${TypographyStyle.GridBase}`
  | `${TypographyStyle.GridLarge}`
  | `${TypographyStyle.GridMedium}`
  | `${TypographyStyle.GridTiny}`
  | `${TypographyStyle.SmallR}`
  | `${TypographyStyle.SmallB}`;

export const typographyStyle: Record<TypographyStyle, TypographyStyleOutput> = {
  [TypographyStyle.H1]: TypographyStyleDefinition.rem({ sizeInPx: 64, lineHeightInPx: 64 }),
  [TypographyStyle.H2]: TypographyStyleDefinition.rem({ sizeInPx: 56, lineHeightInPx: 64 }),
  [TypographyStyle.H3]: TypographyStyleDefinition.rem({ sizeInPx: 48, lineHeightInPx: 56 }),
  [TypographyStyle.H4]: TypographyStyleDefinition.rem({ sizeInPx: 40, lineHeightInPx: 48 }),
  [TypographyStyle.H5]: TypographyStyleDefinition.rem({ sizeInPx: 32, lineHeightInPx: 40 }),
  [TypographyStyle.H6]: TypographyStyleDefinition.rem({ sizeInPx: 24, lineHeightInPx: 32 }),
  // -
  [TypographyStyle.SubheadingR]: TypographyStyleDefinition.rem({
    sizeInPx: 18,
    lineHeightInPx: 26,
    weight: 400
  }),
  [TypographyStyle.SubheadingB]: TypographyStyleDefinition.rem({
    sizeInPx: 18,
    lineHeightInPx: 26,
    weight: 600
  }),
  // -
  [TypographyStyle.LargeR]: TypographyStyleDefinition.rem({ sizeInPx: 16, lineHeightInPx: 24, weight: 400 }),
  [TypographyStyle.LargeB]: TypographyStyleDefinition.rem({ sizeInPx: 16, lineHeightInPx: 24, weight: 600 }),
  [TypographyStyle.MediumR]: TypographyStyleDefinition.rem({ sizeInPx: 14, lineHeightInPx: 20, weight: 400 }),
  [TypographyStyle.MediumB]: TypographyStyleDefinition.rem({ sizeInPx: 14, lineHeightInPx: 20, weight: 600 }),
  [TypographyStyle.BaseR]: TypographyStyleDefinition.rem({ sizeInPx: 12, lineHeightInPx: 16, weight: 400 }),
  [TypographyStyle.BaseB]: TypographyStyleDefinition.rem({ sizeInPx: 12, lineHeightInPx: 16, weight: 600 }),
  [TypographyStyle.SmallR]: TypographyStyleDefinition.rem({ sizeInPx: 10, lineHeightInPx: 12, weight: 400 }),
  [TypographyStyle.SmallB]: TypographyStyleDefinition.rem({ sizeInPx: 10, lineHeightInPx: 12, weight: 600 }),
  // -
  [TypographyStyle.CapsXL]: TypographyStyleDefinition.rem({
    sizeInPx: 16,
    lineHeightInPx: 24,
    allCaps: true
  }),
  [TypographyStyle.CapsM]: TypographyStyleDefinition.rem({ sizeInPx: 14, lineHeightInPx: 20, allCaps: true }),
  [TypographyStyle.CapsS]: TypographyStyleDefinition.rem({ sizeInPx: 12, lineHeightInPx: 16, allCaps: true }),
  // -
  [TypographyStyle.GridLarge]: TypographyStyleDefinition.rem({
    sizeInPx: 16,
    lineHeightInPx: 24,
    weight: 400,
    font: robotoMono
  }),
  [TypographyStyle.GridMedium]: TypographyStyleDefinition.rem({
    sizeInPx: 14,
    lineHeightInPx: 20,
    weight: 400,
    font: robotoMono
  }),
  [TypographyStyle.GridBase]: TypographyStyleDefinition.rem({
    sizeInPx: 12,
    lineHeightInPx: 16,
    weight: 400,
    font: robotoMono
  }),
  [TypographyStyle.GridTiny]: TypographyStyleDefinition.rem({
    sizeInPx: 10,
    lineHeightInPx: 12,
    weight: 400,
    font: robotoMono
  })
};

export default typographyStyle;
