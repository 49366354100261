import { type AnyRecord } from '../common/type.helpers';
import { CustomGraphQLError } from '@oms/shared/oms-common';

export const FormValidationFeedbackLevel = {
  Error: 'Error',
  Info: 'Info',
  Warning: 'Warning'
} as const;

export type FormValidationFeedbackLevelType =
  (typeof FormValidationFeedbackLevel)[keyof typeof FormValidationFeedbackLevel];

export type FeedbackWrapper = {
  code: string;
  level: FormValidationFeedbackLevelType;
  message: string;
};

export type GQLMutationEnvelopeShape<TEnrichedData> = {
  __typename?: string;
  operationId?: string | null | undefined;
  feedback?: Array<FeedbackWrapper> | null | undefined;
  enrichedData?: TEnrichedData | null | undefined;
};

/**
 * Extracts the envelope from a mutation result (if it exists & has feedback errors)
 * otherwise returns null
 *
 * @param result - The result of a mutation
 * @returns - The envelope of the mutation result
 */
export function extractGQLEnvelope<TEnrichedData = any>(
  result: AnyRecord
): GQLMutationEnvelopeShape<TEnrichedData> | null {
  for (const key in result) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const value = result[key];
    if (
      value &&
      typeof value === 'object' &&
      value !== null &&
      '__typename' in value &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      value['__typename'].includes('Envelope') &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      !!value?.feedback
    ) {
      return value as GQLMutationEnvelopeShape<TEnrichedData>;
    }
    if (typeof value === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return extractGQLEnvelope(value);
    }
  }
  return null;
}

/**
 * New Error type for feedback errors
 */
export const ENVELOPE_FEEDBACK_ERROR = 'ENVELOPE_FEEDBACK_ERROR';

export class EnvelopeFeedbackError extends CustomGraphQLError<typeof ENVELOPE_FEEDBACK_ERROR, string> {
  public code: FeedbackWrapper['code'];
  public level: FeedbackWrapper['level'];
  constructor(feedback: FeedbackWrapper) {
    super(feedback.message);
    this.name = ENVELOPE_FEEDBACK_ERROR;
    this.code = feedback.code;
    this.message = feedback.message;
    this.level = feedback.level;
    this.extensions = {
      type: ENVELOPE_FEEDBACK_ERROR,
      code: feedback.code || ENVELOPE_FEEDBACK_ERROR,
      data: feedback.message
    };
  }
}
