import { COMMON_PLATFORM_NAME, EventsTransport, Plugin, Workspace } from '@valstro/workspace';
import { reactBrowserPopoverActor } from './popover.browser-modal.actor';
import { reactTauriPopoverActor } from './popover.tauri.actor';
import { PopoverWidgetDecoration } from './popover.decoration';
import {
  POPOVER_DEFAULT_BUFFER_SIZE,
  PopoverActorSchemaOptions,
  PopoverEvents,
  PopoverManager,
  TAURI_POPOVER_WIN_CTX,
  popoverEventsTransport
} from '@valstro/workspace-plugin-popover';

export interface ReactPopoverPluginOptions {
  bufferSize?: number;
  componentsMap: PopoverActorSchemaOptions['componentsMap'];
  eventsTransport?: EventsTransport<PopoverEvents>;
}

export const reactPopoverPlugin = <T extends Workspace>({
  bufferSize = POPOVER_DEFAULT_BUFFER_SIZE,
  componentsMap,
  eventsTransport
}: ReactPopoverPluginOptions) =>
  Plugin.create<T>({
    name: 'valstro-react-popover-plugin',
    pluginFn: async ({ workspace }) => {
      if (bufferSize < 1) {
        throw new Error('Buffer size must be greater than 1');
      }

      workspace.updateMeta({
        popoverEventsTransport: eventsTransport || popoverEventsTransport
      });

      const options: PopoverActorSchemaOptions = {
        defaultContext: TAURI_POPOVER_WIN_CTX,
        windowDecorationComponent: PopoverWidgetDecoration,
        componentsMap
      };

      // Register the popover window actors
      // TODO: Move this outside of the plugin to make tauri optional
      workspace.getActorRegistry().register(reactBrowserPopoverActor(options));
      workspace.getActorRegistry().register(reactTauriPopoverActor(options));

      let popoverManager: PopoverManager | null = null;

      // Spawn on leader only (before immediate children are spawned)
      workspace.addHook('preSpawnImmediateChildren', async () => {
        popoverManager = new PopoverManager(workspace, {
          bufferSize,
          leaderOnly: true,
          eventsTransport
        });
        await popoverManager.initializeSync();
      });

      // Spawn on child browser windows
      workspace.addHook('windowReady', async ({ platformName, isLeader }) => {
        if (!isLeader && platformName === COMMON_PLATFORM_NAME.BROWSER) {
          popoverManager = new PopoverManager(workspace, {
            bufferSize,
            leaderOnly: false // Browser can have tabs and we want to show popovers in all tabs
          });
          await popoverManager.initializeSync();
        }
      });

      return async function unsubscribe() {
        if (popoverManager) {
          popoverManager.destroy();
        }
      };
    }
  });
