import { FormContract, FieldDefinition } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';

import type { CreateOrderMutationFormOutput } from './example.contracts';
import { intrumentId, notes, quantity, side, tagIds } from './example.form-fields';

export const orderFormContract = FormContract.create<CreateOrderMutationFormOutput>()
  .fields({
    intrumentId,
    quantity,
    side,
    notes,
    tagIds
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box(
        'wrapper',
        [
          f.intrumentId,
          f.quantity,
          f.side,
          f.notes,
          f.tagIds,
          FieldDefinition.box('actions', [FieldDefinition.submit('submit'), FieldDefinition.reset('reset')], {
            sx: { display: 'flex', gap: 4 }
          })
        ],
        {
          sx: { padding: 4 }
        }
      )
    ]
  }));

export type OrderFormContractType = typeof orderFormContract;

// 👇 Note: You can use this handy type helper to get the "form values" type from a form contract
export type OrderContractFormValues = InferFormValuesFromFormContract<OrderFormContractType>;
