import { FC } from 'react';
import { Heading } from '@oms/shared-frontend/ui-design-system';
import clsx from 'clsx';

export type VGridTitleProps = {
  agGridClassName?: string;
  gridTitle?: string;
};

export const VGridTitle: FC<VGridTitleProps> = ({ gridTitle, agGridClassName }) => {
  if (!gridTitle) {
    return null;
  }

  return (
    <div className={clsx(agGridClassName, 'ag-grid-title')}>
      <Heading type="mediumB">{gridTitle}</Heading>
    </div>
  );
};
