import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import type { VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment } from '@oms/generated/frontend';
import { openViewInvestorOrder } from '@app/common/types/orders/investor-order/open.view.investor-order';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';

function isVisible(rowData: IOFragment[] | undefined) {
  return !!rowData && rowData.length === 1;
}

export const viewInvestorOrderOnChange = async (
  ctx: ActionContext<IOFragment, ActionComponentConfig<IOFragment>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];
  const isActionVisible = isVisible(data);

  ctx.notify({ isDisabled: !isActionVisible });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openViewInvestorOrder({
      windowId: getLeaderOrTabId(ctx.appContainer),
      investorOrderData: selectedRow
    });
  }
};

export const viewInvestorOrderAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('view_investor_order')
    .toolbar((t) =>
      t.component('action-button').id('view_investor_order_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'View'
      })
    )
    .customMenu((m) => m.name('View Order').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<IOFragment>>(viewInvestorOrderOnChange);
