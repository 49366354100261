import type { CurrencyFragment } from '@oms/generated/frontend';
import { crudWindowGridActionBuilder } from '@app/common/grids/actions/crud-window.actions';
import { CurrenciesService } from '@app/data-access/services/reference-data/currencies/currencies.service';
import { ValstroEntitlements } from '@oms/generated/frontend';

const currencyActionBuilder = crudWindowGridActionBuilder<CurrencyFragment>('Currency', [
  ValstroEntitlements.ReferenceDataManage
]);

export const createAction = currencyActionBuilder('CREATE', {
  formKey: 'CURRENCY_FORM',
  titleGetter: () => 'Create Currency'
});

export const updateAction = currencyActionBuilder('UPDATE', {
  formKey: 'CURRENCY_FORM',
  rowDataToFormInput: (rowData) => ({
    id: rowData.id
  }),
  titleGetter: (rows) => `Edit ${rows[0].id ?? ''}`
});

export const deleteAction = currencyActionBuilder('DELETE', {
  titleGetter: (rows) => `Edit ${rows[0].id ?? ''}`,
  onConfirmDelete: async (selectedRows, ctx) => {
    const currenciesService = ctx.appContainer.resolve(CurrenciesService);
    await currenciesService.delete(selectedRows.map((row) => row.id));
  }
});
