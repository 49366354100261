import { FormBuilder } from '@oms/frontend-foundation';
import montageFiltersFormContract from './montage-filters.form-contract';
import type {
  MontageFiltersFormContractType,
  MontageFiltersFormValues
} from './montage-filters.form-contract';
import type { MontageFilterFormInput, MontageFilterFormOutput } from './montage-filters.form-common';
import { MontageService } from '@app/data-access/services/trading/montage/montage.service';
import AccountsService from '@app/data-access/services/reference-data/accounts/accounts.service';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import type { InstrumentDetails } from '@oms/generated/frontend';
import type { Optional } from '@oms/shared/util-types';

export const montageFiltersFormBuilder = FormBuilder.create<MontageFilterFormInput, MontageFilterFormOutput>(
  'montage-filter-form'
)
  .contract<MontageFiltersFormContractType>(montageFiltersFormContract)
  .type('montage-filters')
  .sanitizer((s) =>
    s
      .input(
        async (
          { instrument: instrumentSymbol, investorAccount: investorAccountId },
          { container }
        ): Promise<MontageFiltersFormValues> => {
          const montageService = container.resolve(MontageService);
          const accountsService = container.resolve(AccountsService);

          let instrument: Optional<InstrumentDetails> = undefined;

          try {
            const [instrumentBySymbol] = instrumentSymbol
              ? await montageService.lookupInstrumentBySymbol(instrumentSymbol)
              : [];
            instrument = instrumentBySymbol;
          } catch (error) {
            console.error(error);
            openMessageDialog(`Instrument could not be found. Please try again.`).catch(console.error);
          }

          const investorAccount = investorAccountId
            ? await accountsService.getInvestorAccount(investorAccountId)
            : undefined;

          return {
            instrument: instrument
              ? {
                  id: instrument.id,
                  value: {}
                }
              : undefined,
            investorAccount: investorAccount
              ? {
                  id: investorAccount.id,
                  label: investorAccount.name
                }
              : undefined
          };
        }
      )
      .output(({ instrument, investorAccount }) => {
        const output: MontageFilterFormOutput = {
          instrumentId: instrument?.id,
          investorAccountId: investorAccount?.id
        };
        return output;
      })
  )
  .change((_) => {
    // Needs to have this method implemented or it will cause an error
    // Actual implementation is in the widget to have access to React
  });

export default montageFiltersFormBuilder;
