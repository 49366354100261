import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type {
  FieldContract,
  ModifiedFieldForOptions,
  INumberFormatField,
  AnyRecord
} from '@oms/frontend-foundation';

export const createStopPriceField = <
  TOutputContract extends AnyRecord,
  TStopPrice extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  stopPrice: TStopPrice,
  stopPriceOverrides: ModifiedFieldForOptions<INumberFormatField<TOutputContract[TStopPrice]>> = {}
) => {
  const stopPriceField = fc.field(stopPrice, 'number-format').options({
    wrapperSx: {
      width: 'full'
    },
    label: 'Stop Price',
    ...stopPriceOverrides,
    validate: [
      { type: 'min-number-value', value: 0.01, message: 'Min $0.01' },
      ...(stopPriceOverrides.validate || [])
    ]
  });

  return stopPriceField;
};
