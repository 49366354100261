import type {
  AlertBannerProps,
  AlertBannerStackItem,
  AlertLevel,
  AlertLevelMap,
  AlertLevelOverrides
} from './types';
import { type AlertBannerInternalProps } from './types.internal';
import { useMemo } from 'react';
import clsx from 'clsx';
import * as styles from './style.css';
import {
  AlertBannerClassName,
  defaultIcons,
  defaultTitles,
  defaultIconColors,
  defaultBackgroundColors
} from './constants';
import { asArray } from '@oms/shared/util';

export function getFromAlertLevelMap<T>(map: Partial<AlertLevelMap<T>>, level: AlertLevel): T {
  const value = map[level];
  if (typeof value !== 'undefined') return value;
  return map['default'] as T;
}

export const useInternalProps = ({
  messages,
  level,
  config,
  icon,
  title,
  iconColor,
  backgroundColor,
  sx = {}
}: AlertBannerProps) =>
  useMemo(
    (): AlertBannerInternalProps => ({
      messages: asArray(messages),
      icon: typeof icon !== 'undefined' ? icon : getFromAlertLevelMap(config?.icons ?? defaultIcons, level),
      title: typeof title === 'string' ? title : getFromAlertLevelMap(config?.titles ?? defaultTitles, level),
      iconColor:
        typeof iconColor !== 'undefined'
          ? iconColor
          : getFromAlertLevelMap(config?.iconColors ?? defaultIconColors, level),
      backgroundColor:
        typeof backgroundColor !== 'undefined'
          ? backgroundColor
          : getFromAlertLevelMap(config?.backgroundColors ?? defaultBackgroundColors, level),
      sx: { ...(typeof config?.sx !== 'undefined' ? getFromAlertLevelMap(config.sx, level) : {}), ...sx }
    }),
    [
      messages,
      icon,
      config?.icons,
      config?.titles,
      config?.iconColors,
      config?.backgroundColors,
      config?.sx,
      level,
      title,
      iconColor,
      backgroundColor,
      sx
    ]
  );

export const useClassName = (location: keyof typeof styles.alertBanner) =>
  useMemo(() => clsx(styles.alertBanner[location], AlertBannerClassName[location]), [location]);

export const combineAlertsByLevel = (
  items: AlertBannerStackItem[],
  overrides?: AlertLevelOverrides
): AlertBannerStackItem[] => {
  const combined: AlertBannerStackItem[] = [];
  const split: Record<AlertLevel, string[]> = { error: [], warning: [], info: [] };
  items.forEach(({ level, messages }) => {
    split[level].push(...asArray(messages));
  });
  const { error, warning, info } = overrides ?? {};
  if (split.error.length) combined.push({ level: 'error', messages: split.error, ...(error ?? {}) });
  if (split.warning.length) combined.push({ level: 'warning', messages: split.warning, ...(warning ?? {}) });
  if (split.info.length) combined.push({ level: 'info', messages: split.info, ...(info ?? {}) });
  return combined;
};
