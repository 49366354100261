import type {
  OrderSettleType,
  OrderType,
  TimeInForce,
  TradingOrderCategory,
  TradingOrderStatus,
  VisibilityReason
} from '@oms/generated/frontend';
import { mapTif } from '@app/common/mappers/map-tif';
import { mapSettleType } from '@app/common/mappers/map-settle-type';
import { mapTradingOrderStatus } from '@app/common/mappers/map-trading-order-status';
import { mapOrderVisibilityReason } from '@app/common/mappers/map-order-visibility-reason';
import { mapTradingOrderCategory } from '@app/common/mappers/map-trading-order-category';
import type { ValueFormatterFunc, ValueFormatterParams } from '@ag-grid-community/core';
import { mapOrderType } from '@app/common/mappers/map-order-type';

const customFormatters = [
  'orderType',
  'timeInForce',
  'orderSettleType',
  'tradingOrderStatus',
  'visibilityReason',
  'tradingOrderCategory'
] as const;

export type CustomFormatters = (typeof customFormatters)[number];

export const isCustomFormatter = (value: string): value is CustomFormatters => {
  return customFormatters.includes(value as CustomFormatters);
};

const timeInForce = ({ value }: ValueFormatterParams) => mapTif(value as TimeInForce, 'Unknown');

const orderSettleType = ({ value }: ValueFormatterParams) => mapSettleType(value as OrderSettleType);

const orderType = ({ value }: ValueFormatterParams) => mapOrderType(value as OrderType);

const tradingOrderStatus = ({ value }: ValueFormatterParams) =>
  mapTradingOrderStatus(value as TradingOrderStatus);

const visibilityReason = ({ value }: ValueFormatterParams) =>
  mapOrderVisibilityReason(value as VisibilityReason);

const tradingOrderCategory = ({ value }: { value: TradingOrderCategory }) =>
  mapTradingOrderCategory(value as TradingOrderCategory);

export const CUSTOM_FORMATTER_REGISTRY: Record<CustomFormatters, ValueFormatterFunc> = {
  timeInForce,
  orderType,
  orderSettleType,
  tradingOrderStatus,
  visibilityReason,
  tradingOrderCategory
};
