import { Flex, FlexProps } from '../flex/flex';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

export type CenterProps = {
  fillArea?: boolean;
} & FlexProps;

export const Center = polymorphicComponent<'div', CenterProps>((props, ref) => {
  const { sx = {}, fillArea = false, children, ...rest } = props;

  return (
    <Flex
      sx={{
        position: fillArea ? 'absolute' : 'relative',
        inset: fillArea ? 0 : undefined,
        height: 'full',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </Flex>
  );
});

if (__DEV__) {
  Center.displayName = 'Center';
}
