import { FormContract, FieldDefinition, FieldContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { CancelExecutionsFormSchemaInput } from './cancel-executions.form-builder.config';
import type { CancelExecutionsMutationVariables } from '@oms/generated/frontend';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { createAccountField } from '@app/forms/common/fields/account-field/account.field.common';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';

// Common
export type CancelExecutionsFormInput = CancelExecutionsFormSchemaInput;
// We ommit executionIds and redefining it since the backend defined the input as either an
// array or a single string and we want to allow only an array.
export type CancelExecutionsFormOutput = Omit<CancelExecutionsMutationVariables, 'executionIds'> & {
  executionIds: string[];
};

// Fields
const ofc = FieldContract.create<CancelExecutionsFormOutput, AdditionalFormFieldUnion>();

export const orderIdField = ofc.field('orderId', 'hidden-field');
export const executionIdsField = ofc.field('executionIds', 'hidden-field');

export const moveToAccountField = createAccountField(ofc, 'investorOrderAccountId', {
  ...makeCommonRequiredProps(),
  label: 'Move to Account',
  query: ADVANCED_SELECT_QUERY_ENUM.FIRM_ACCOUNTS_WATCH_QUERY
});

export const intermediaryAccountField = createAccountField(ofc, 'tradingOrderIntermediaryAccountId', {
  ...makeCommonRequiredProps(),
  label: 'Intermediary Account for Bound Trading Orders',
  query: ADVANCED_SELECT_QUERY_ENUM.INTERMEDIARY_ACCOUNTS_WATCH_QUERY
});

// Form contract
export const cancelExecutionsFormContract = FormContract.create<CancelExecutionsFormOutput>()
  .fields({
    orderId: orderIdField,
    executionIds: executionIdsField,
    investorOrderAccountId: moveToAccountField,
    tradingOrderIntermediaryAccountId: intermediaryAccountField
  })
  .schema((f) => ({
    fields: [FieldDefinition.box('wrapper', [f.investorOrderAccountId, f.tradingOrderIntermediaryAccountId])]
  }))
  .template('simple', {
    submitLabel: 'Confirm',
    formFieldsSx: { px: 4, pb: 2, marginTop: 4 }
  });

export type CancelExecutionsFormContractType = typeof cancelExecutionsFormContract;

export type CancelExecutionsFormValues = InferFormValuesFromFormContract<CancelExecutionsFormContractType>;
